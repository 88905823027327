import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CountriesResponseData } from '../../interfaces/businessDataResponse/countriesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import parseBoolean from '../../utils/parseBoolean';
import { CountryItemData } from '../../interfaces/businessDataItem/countryItem';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * countriesSlice manages all countries state, and contains countries actions as well as countries state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const countriesSlice = createSlice({
    name: 'countries',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<CountryItemData>,
    reducers: {
        setCountriesList: (state, action: PayloadAction<CountryItemData[]>) => {
            const byCountryName = action.payload.reduce(
                (
                    byCountryName: {
                        [key: string]: CountryItemData;
                    },
                    country: CountryItemData,
                ) => {
                    byCountryName[country.country] = {
                        ...country,
                        active: parseBoolean(country.active),
                    };
                    return byCountryName;
                },
                {},
            );
            state.entities = byCountryName;
            state.keysList = Object.keys(byCountryName);
        },
        setCountry: (state, action: PayloadAction<CountryItemData>) => {
            // this reducer may be used when adding a new country or updating an existing one.
            // only add to keysList and update count if adding a new country
            if (!state.entities[action.payload.country]) {
                state.keysList = [...state.keysList, action.payload.country];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.country] = action.payload;
        },
        setSelectedCountry: (state, action: PayloadAction<string | null>) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetCountriesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setCountriesList,
    setCountry,
    setSelectedCountry,
    setError,
    setCount,
    resetCountriesSlice,
} = countriesSlice.actions;

const handleCountriesListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { COUNTRY, total_count },
            }: HandleRequestData<CountriesResponseData> =
                await businessDataApi.getCountries({ active: 'all' });
            dispatch(setCountriesList(COUNTRY));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getCountries API error'));
        }
    };
};

export const getCountriesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleCountriesListRequest());
        if (!state.countries.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

export const selectAllCountries = (state: GlobalState) => {
    return state.countries.keysList.map(
        (countryName) => state.countries.entities[countryName],
    );
};

export const selectAllActiveCountries = (
    state: GlobalState,
): Array<CountryItemData> => {
    return getActiveBusinessDataValues(
        state.countries.keysList,
        state.countries.entities,
    );
};

export const selectActivePlusLocationSelection = (state: GlobalState) => {
    const locationSelectionCountries: string | null = state?.locations
        ?.selectedItemKey
        ? state?.locations?.entities[state?.locations?.selectedItemKey].country
        : null;

    return state.countries.keysList.reduce(
        (acc: Array<CountryItemData>, key: string) => {
            if (state.countries.entities[key].active) {
                acc.push(state.countries.entities[key]);
            } else if (locationSelectionCountries === key) {
                acc.push(state.countries.entities[key]);
            }
            return acc;
        },
        [],
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.countries.isLoading;

export const selectIsLoaded = (state: GlobalState) => state.countries.isLoaded;

export const selectSelectedCountry = (state: GlobalState) => {
    return state?.countries?.selectedItemKey
        ? state?.countries?.entities[state?.countries?.selectedItemKey]
        : null;
};

export const selectError = (state: GlobalState) => state.countries.error;

export const selectCount = (state: GlobalState) => state.countries.count;

export default countriesSlice.reducer;

import { HandleRequestData } from '../interfaces/handleRequest';
import { UserResponseData } from '../interfaces/userResponse';
import handleGrimsbyApiRequest from '../utils/handleGrimsbyApiRequest';
import { ResponseData } from '../interfaces/responseData';
import { UpdateUserPayloadData } from '../interfaces/updateUserPayload';
import { API_MAIN_PATH } from '../constants/grimsby';
import { Cache } from '@aws-amplify/cache';
export const USER_SYNC_PATH = 'user-management-v2/user/sync';

export interface UserSyncResponseData extends ResponseData {
    user_update: boolean;
    vias_update: boolean;
}

export const USER_PATH = API_MAIN_PATH.USER_MANAGEMENT;

/**
 * Method to request a user's profile
 * @return: A promise that resolves with an object containing the result or error of the profile request
 * @todo: descriptive failure growler, FE error logging(?)
 */
const getUserProfile = (): Promise<HandleRequestData<UserResponseData>> => {
    return handleGrimsbyApiRequest<UserResponseData>(`${USER_PATH}/user`, {
        method: 'GET',
    });
};

/**
 * Method to request a sync to the users profile that will trigger VIAS attribute updates
 * @return: A promise that resolves with an object containing the result or error of the sync request
 */
const syncUserProfile = (): Promise<HandleRequestData<UserResponseData>> => {
    return handleGrimsbyApiRequest<UserResponseData>(`${USER_PATH}/sync`, {
        method: 'PUT',
    });
};

export const syncNewUser = (): Promise<
    HandleRequestData<UserSyncResponseData>
> => {
    return handleGrimsbyApiRequest<UserSyncResponseData>(`${USER_SYNC_PATH}`, {
        method: 'PUT',
        body: JSON.stringify({
            token: Cache.getItem('federatedInfo').token,
        }),
    });
};

/**
 * Method to update a grimsby user's profile
 * @param {string} id - A selected user's uuid
 * @param {UpdateUserPayloadData} payload - an object containing the updated properties
 * @return: A promise that resolves with an object containing the result or error of the update request
 */
const updateUser = (
    id: string,
    payload: UpdateUserPayloadData,
): Promise<HandleRequestData<ResponseData>> => {
    return handleGrimsbyApiRequest<ResponseData>(`${USER_PATH}/user/${id}`, {
        method: 'PUT',
        body: JSON.stringify(payload),
    });
};

const userManagementApi = {
    getUserProfile,
    syncUserProfile,
    syncNewUser,
    updateUser,
};

export default userManagementApi;

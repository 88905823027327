const debounce = <T>(func: (val: T) => void, delay = 500) => {
    let timeout: any;

    return function (...args: [T]) {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            func.apply(null, args);
        }, delay);
    };
};

export default debounce;

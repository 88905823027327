import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Alert,
    Box,
    Button,
    Header,
    Pagination,
    PropertyFilterProps,
    Table,
    Grid,
    CollectionPreferencesProps,
} from '@amzn/awsui-components-react-v3';
import ActivityGroupListCalendarFilter from '../components/ActivityGroupListCalendarFilter';
import { columnDefinitions } from './ActivityGroupListTableColumns';
import ActivityGroupListPropertyFilter from '../components/ActivityGroupListPropertyFilter';
import ActivityGroupListCollectionPreferences from '../components/ActivityGroupListCollectionPreferences';
import { ActivityGroupListColumnLabels } from '../../../../interfaces/activityGroup';
import { updateQueryTokens } from '../query-utils';
import { useActivityGroupList } from '../hooks/useActivityGroupList';
import {
    fetchActivityGroups,
    selectActivityGroups,
    selectError,
    selectIsLoading,
    selectTotalActivityGroups,
} from '../../../../store/slices/activityGroupListSlice';

interface PreferencesState extends CollectionPreferencesProps.Preferences<any> {
    pageSize?: number;
    wrapLines?: boolean;
    contentDisplay?: ReadonlyArray<CollectionPreferencesProps.ContentDisplayItem>;
}

const ActivityGroupList: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const error = useSelector(selectError);
    const activityGroups = useSelector(selectActivityGroups);
    const isLoading = useSelector(selectIsLoading);
    const totalCount = useSelector(selectTotalActivityGroups);
    const [combinedQuery, setCombinedQuery] =
        useState<PropertyFilterProps.Query>({
            tokens: [],
            operation: 'and',
        });

    const {
        currentPageIndex,
        pagesCount,
        pageSize,
        buildUpdatedParams,
        setCurrentPageIndex,
        setPageSize,
        resetPagination,
    } = useActivityGroupList({
        combinedQuery,
        dispatch,
        totalCount,
    });

    const [preferences, setPreferences] = useState<PreferencesState>({
        pageSize,
        wrapLines: false,
        contentDisplay: ActivityGroupListColumnLabels.map((label) => ({
            id: label,
            visible: true,
        })),
    });

    useEffect(() => {
        dispatch(fetchActivityGroups(buildUpdatedParams()));
    }, [dispatch, combinedQuery, pageSize, currentPageIndex]);

    const handlePropertyFilterChange = useCallback(
        (query: PropertyFilterProps.Query) => {
            setCombinedQuery({
                ...combinedQuery,
                tokens: updateQueryTokens({
                    combinedQueryTokens: combinedQuery.tokens,
                    newTokens: [...query.tokens],
                    shouldIncludeDateRange: true,
                }),
            });
            resetPagination();
        },
        [combinedQuery],
    );

    const handlePageChange = (e: { detail: { currentPageIndex: number } }) => {
        setCurrentPageIndex(e.detail.currentPageIndex);
    };

    return (
        <>
            {error && (
                <Alert header="Error" type="error">
                    {error}
                </Alert>
            )}
            <Table
                items={activityGroups}
                loading={isLoading}
                stripedRows={preferences.stripedRows}
                contentDensity={preferences.contentDensity}
                wrapLines={preferences.wrapLines}
                resizableColumns={true}
                columnDefinitions={columnDefinitions}
                sortingDisabled
                columnDisplay={preferences.contentDisplay}
                data-testid="ActivityGroupListTable"
                loadingText="Loading activity groups"
                preferences={
                    <ActivityGroupListCollectionPreferences
                        preferences={preferences}
                        onConfirm={(detail) => {
                            setPreferences(detail);
                            setPageSize(detail.pageSize);
                        }}
                    />
                }
                pagination={
                    <Pagination
                        currentPageIndex={currentPageIndex}
                        pagesCount={pagesCount}
                        onChange={handlePageChange}
                    />
                }
                header={
                    <Header
                        counter={`(${totalCount})`}
                        actions={
                            <Button
                                onClick={() => {
                                    history.push('/activities/group/create');
                                }}
                                variant="primary"
                            >
                                Create Activity Group
                            </Button>
                        }
                    >
                        Activity Groups
                    </Header>
                }
                filter={
                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 7 }]}>
                        <div>
                            <ActivityGroupListPropertyFilter
                                onChange={handlePropertyFilterChange}
                            />
                        </div>
                        <ActivityGroupListCalendarFilter
                            setCombinedQuery={setCombinedQuery}
                            combinedQuery={combinedQuery}
                        />
                    </Grid>
                }
                empty={
                    <Box textAlign="center" color="inherit">
                        <Box padding={{ bottom: 's' }}>
                            No activity groups to display.
                        </Box>
                    </Box>
                }
            />
        </>
    );
};

export default ActivityGroupList;

import { ValidationType } from '../../../common/utils/formValidation';
import { CourseFormData } from '../../interfaces/courseForm';

export const courseSkillLevelOptions = [
    'Fundamental(100)',
    'Intermediate(200)',
    'Advanced(300)',
];
export const courseContentOptions = ['labs', 'books', 'quizs', 'jams'];
export const courseLocales = ['en-US', 'zh-CN'];

export const courseFormValidationConfig: {
    [key in ValidationType]?: Array<keyof CourseFormData>;
} = {
    required: [
        'course',
        'course_days',
        'course_domain',
        'course_category',
        'course_skill_level',
        'locales',
        'course_content',
    ],
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LocationsResponseData } from '../../interfaces/businessDataResponse/locationsResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { LocationItemData } from '../../interfaces/businessDataItem/locationItem';
import parseBoolean from '../../utils/parseBoolean';
import { AdminCreateLocationPayloadData } from '../../interfaces/adminCreateBusinessDataPayload/locationPayload';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';
import { MAX_RETRY_TIME, RETRY_DELAY } from '../../constants/grimsby';
import { REACH_MAX_RETRY_ATTEMPTS } from '../../constants/errorMessage';

/**
 * locationsSlice manages all locations state, and contains locations actions as well as locations state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const locationsSlice = createSlice({
    name: 'locations',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
        region: [],
    } as ExtendedBusinessDataStoreState<LocationItemData> & {
        region: Array<string>;
    },
    reducers: {
        setLocationsList: (
            state,
            action: PayloadAction<LocationItemData[]>,
        ) => {
            const byPk = action.payload.reduce(
                (
                    byLocationName: {
                        [key: string]: LocationItemData;
                    },
                    location: LocationItemData,
                ) => {
                    byLocationName[location.pk] = {
                        ...location,
                        active: parseBoolean(location.active),
                    };
                    return byLocationName;
                },
                {},
            );
            state.entities = byPk;
            state.keysList = Object.keys(byPk);
        },
        setLocation: (state, action: PayloadAction<LocationItemData>) => {
            // this reducer may be used when adding a new location or updating an existing one.
            // only add to keysList and update count if adding a new item
            if (!state.entities[action.payload.pk]) {
                state.keysList = [...state.keysList, action.payload.pk];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.pk] = action.payload;
        },
        setSelectedLocation: (state, action: PayloadAction<string | null>) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        setRegion: (state, action: PayloadAction<Array<string>>) => {
            state.region = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        resetPartialLocationsSlice: resetBasicBusinessDataStoreState,
        resetLocationsSlice: resetExtendedBusinessDataStoreState,
        initializeLocationsListQueryParams:
            initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setLocationsList,
    setLocation,
    setSelectedLocation,
    setError,
    setCount,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    setRegion,
    setSearchText,
    setActive,
    resetPartialLocationsSlice,
    resetLocationsSlice,
    initializeLocationsListQueryParams,
} = locationsSlice.actions;

const handleLocationsListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: city,
            active,
            region,
        } = state.locations;

        const params: BusinessDataApiQueryParams.GetLocations = {
            active,
        };

        if (region.length) {
            params.region = region;
        }
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (city) {
            params.city = city;
        }

        try {
            const {
                result: { CITY, total_count },
            }: HandleRequestData<LocationsResponseData> =
                await businessDataApi.getLocations(params);
            dispatch(setLocationsList(CITY));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getLocations API error'));
        }
    };
};

export const getLocationsList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleLocationsListRequest());
        if (!state.locations.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update location
 * @param {LocationItemData} data - the Location item containing updated fields
 */
export const updateLocation = (data: LocationItemData) => {
    return async (dispatch: AppDispatch): Promise<boolean> => {
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        try {
            // for now we only allow updating the status, because the API does not allow updating the state field
            // so it wouldn't make sense to allow updating country but not state
            await businessDataApi.updateLocation(data.pk, data.active);
            await dispatch(handleLocationsListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(setLocation(data));
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

/**
 * action to add location
 * @param {AdminCreateLocationPayloadData} newLocation - the new Location item
 */
export const addLocation = (newLocation: AdminCreateLocationPayloadData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        dispatch(setIsLoading(true));
        // add the new location
        try {
            await businessDataApi.addLocation(newLocation);
        } catch (error: any) {
            // if there was an error adding the new location
            // don't bother refetching the locations list, just return false
            dispatch(setIsLoading(false));
            return false;
        }
        let count = MAX_RETRY_TIME;
        let success: boolean = false;
        while (count) {
            try {
                const state = getState();
                // now refresh the locations list
                await dispatch(handleLocationsListRequest());
                // shove the item into the keysList, but only if it's not there already
                const match = Object.entries(state.locations.entities).find(
                    ([, val]) => {
                        return (
                            val.city === newLocation.city &&
                            val.country === newLocation.country &&
                            val.geo === newLocation.geo &&
                            val.region === newLocation.region &&
                            val?.state === newLocation?.state
                        );
                    },
                );
                // match was returned in the list; break and return true
                if (match) {
                    success = true;
                    break;
                }
            } catch (error: any) {
                // if we encounter any error from the getLocation api break and return false
                // success = false;
                break;
            }
            // no match found yet, no api error, keep looping
            count--;
            // add a time delay to refetch the data
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }

        if (!count && !success) {
            // this will show an error alert in place of the locations list
            dispatch(setError(REACH_MAX_RETRY_ATTEMPTS));
            // success = false;
        }
        dispatch(setIsLoading(false));
        return success;
    };
};

export const selectAllLocations = (state: GlobalState) => {
    // keysList is sorted in order
    return state.locations.keysList.map(
        (locationName) => state.locations.entities[locationName],
    );
};

export const selectAllActiveLocations = (
    state: GlobalState,
): Array<LocationItemData> => {
    return getActiveBusinessDataValues<LocationItemData>(
        state.locations.keysList,
        state.locations.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.locations.isLoading;

export const selectIsLoaded = (state: GlobalState) => state.locations.isLoaded;

export const selectSelectedLocation = (state: GlobalState) => {
    return state?.locations?.selectedItemKey
        ? state?.locations?.entities[state?.locations?.selectedItemKey]
        : null;
};

export const selectError = (state: GlobalState) => state.locations.error;

export const selectCount = (state: GlobalState) => state.locations.count;

export const selectSearchText = (state: GlobalState) =>
    state.locations.searchText;

export default locationsSlice.reducer;

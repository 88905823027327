import { Hub } from '@aws-amplify/core';
import { Cache } from '@aws-amplify/cache';
import { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import {
    getStageName,
    hasValidToken,
    redirectToGandalfEmailVerification,
    redirectToLoginPage,
} from '../../common/utils/amplify';
import useAuthorization from '../../common/hooks/useAuthorization';
import { ViasNamespaceRoleProps } from '../../common/interfaces/jwt';
import { Auth } from '@aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { LocalStorageUserProfile } from '../../common/interfaces/localStorageUserProfile';
import {
    setIsLoaded,
    setIsLoading,
    setRoles,
    setUser,
} from '../../common/store/slices/userSlice';
import userManagementApi from '../../common/api/userManagementApi';
import { HandleRequestData } from '../../common/interfaces/handleRequest';
import { UserResponseData } from '../../common/interfaces/userResponse';
import useAuthentication from '../../common/hooks/useAuthentication';
import Onboard from '../../common/components/User/Onboard';
import { getFeatureFlags } from '../../common/store/slices/featureSlice';

const Authenticator = ({
    children,
    globals,
}: {
    children: JSX.Element;
    globals: Window;
}) => {
    const [signedIn, setSignedIn] = useState(false);
    const [redirectToOnboard, setRedirectToOnboard] = useState(false);
    const { setRoles: setAuthorizationRoles } = useAuthorization();
    const { setIsOnboarded } = useAuthentication();
    const [authenticated, setAuthenticated] = useState(false);
    const dispatch = useDispatch();

    const setSignedInUserToLocalStorage = async () => {
        const STAGE = getStageName();
        const { payload } = await (
            await Auth.currentAuthenticatedUser({ bypassCache: true })
        )
            .getSignInUserSession()
            .getIdToken();
        const { family_name, given_name, email, name, email_verified } =
            payload;

        let roles = [];

        for (const key in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, key)) {
                if (
                    key ===
                    `${
                        ViasNamespaceRoleProps[
                            STAGE as keyof typeof ViasNamespaceRoleProps
                        ]
                    }`
                ) {
                    roles = payload[key]
                        .split(':')
                        .filter(
                            (role: any) =>
                                !role.startsWith('ATP') &&
                                !['', 'X'].includes(role),
                        );
                }
            }
        }
        const fullName =
            given_name && family_name ? `${given_name} ${family_name}` : name;
        Cache.setItem('userProfile', {
            fullName,
            email,
            roles,
        } as LocalStorageUserProfile);

        if (roles.length === 0) {
            setIsOnboarded(false);
            if (email_verified) {
                // User has no roles but verified their email
                setRedirectToOnboard(true);
            } else {
                // User has no roles and email is not verified. First time login
                return redirectToGandalfEmailVerification();
            }
        } else {
            dispatch(setIsLoading(true));
            try {
                const { result }: HandleRequestData<UserResponseData> =
                    await userManagementApi.getUserProfile();
                dispatch(setUser(result));
            } catch (error) {
                // show error
            } finally {
                dispatch(setIsLoading(false));
                dispatch(setIsLoaded(true));
            }
            dispatch(getFeatureFlags());
            setAuthorizationRoles(roles);
            dispatch(setRoles(roles));
            setAuthenticated(true);
        }
    };

    const goLogin = async () => {
        await redirectToLoginPage();
    };

    useEffect(() => {
        hasValidToken().then((hasToken) => {
            if (hasToken) {
                setSignedInUserToLocalStorage();
                setSignedIn(true);
            } else {
                goLogin();
            }
        });

        Hub.listen('auth', ({ payload: { data, event } }) => {
            if (event === 'signIn') {
                const token = data
                    .getSignInUserSession()
                    .getIdToken()
                    .getJwtToken();
                Cache.setItem('federatedInfo', { token });
                setSignedIn(true);
                window.history.replaceState(
                    {},
                    '',
                    `${window.location.origin}${Cache.getItem(
                        'grimsby_redirect_pathname',
                    )}`,
                );
            } else if (event === 'oAuthSignOut') {
                Cache.clear();
            } else if (event === 'session_expired') {
                Cache.removeItem('federatedInfo');
                goLogin();
            }
        });
    }, [globals]);

    if (redirectToOnboard) {
        return <Onboard />;
    }

    return (
        <Loader isLoading={!signedIn && !authenticated}>
            {authenticated ? children : <></>}
        </Loader>
    );
};

export default Authenticator;

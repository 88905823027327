import React from 'react';
import { Link } from 'react-router-dom';
import { TableProps } from '@amzn/awsui-components-react-v3';
import { formatStatus } from '../../AdminBusinessData/AdminBusinessData.Status';
import { CourseItemData } from '../../../../common/interfaces/businessDataItem/courseItem';
import { formatStringArray } from '../../../../common/utils/formatStringArray';
import { formatString } from '../../../../common/utils/formatString';

export const formatCourseDays = (value?: string | number) => {
    if (!value) {
        return '-';
    }

    if (typeof value === 'string') {
        try {
            value = parseFloat(value);
        } catch {
            // eslint-disable-next-line no-console
            console.warn('cannot parse number', value);
            value = 1;
        }
    }

    return value === 1 ? `${value} day` : `${value} days`;
};

export const columnDefinitions: ReadonlyArray<
    TableProps.ColumnDefinition<CourseItemData>
> = [
    {
        id: 'name',
        header: 'Course',
        cell: (course: CourseItemData) => (
            <Link to={`/admin/business-data/courses/${course.pk}`}>
                {course.course}
            </Link>
        ),
    },
    {
        id: 'active',
        header: 'Status',
        cell: (course: CourseItemData) =>
            formatStatus(course.active.toString()),
    },
    {
        id: 'associated_programs',
        header: 'Associated programs',
        cell: (course: CourseItemData) =>
            formatStringArray(course.associated_programs),
    },
    {
        id: 'associated_certifications',
        header: 'Associated certifications',
        cell: (course: CourseItemData) =>
            formatStringArray(course.associated_certifications),
    },
    {
        id: 'course_days',
        header: 'Course duration',
        cell: (course: CourseItemData) => formatCourseDays(course.course_days),
    },
    {
        id: 'short_name',
        header: 'Short name',
        cell: (course: CourseItemData) => formatString(course.short_name),
    },
    {
        id: 'sku_id',
        header: 'SKU Id',
        cell: (course: CourseItemData) => formatString(course.sku_id),
    },
];

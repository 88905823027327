import { CourseFormData } from '../../../interfaces/courseForm';
import AdminCourseCreateForm from './AdminCoursesCreateForm';

const createCourseFormState: CourseFormData = {
    course: '',
    associated_programs: [],
    associated_certifications: [],
    course_days: '',
    short_name: '',
    sku_id: '',
    active: true,
    course_category: null,
    course_modality: 'ILT',
    course_domain: null,
    course_skill_level: null,
    course_content: null,
    locales: null,
};

const AdminCoursesCreate = () => {
    return (
        <AdminCourseCreateForm createCourseFormState={createCourseFormState} />
    );
};

export default AdminCoursesCreate;

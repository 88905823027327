import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    AttributeEditor,
    ColumnLayout,
    CustomDetailEvent,
    DatePicker,
    FormField,
    FormSection,
    Input,
    Select,
    TimeInput,
} from '@amzn/awsui-components-react';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    getActivityStatusesList,
    resetActivityStatusesSlice,
    selectAllActiveActivityStatuses,
    selectError as selectActivityStatusListError,
    selectIsLoaded as selectIsActivityStatusListLoaded,
    selectIsLoading as selectIsActivityStatusListLoading,
} from '../../../../common/store/slices/activityStatusesSlice';
import {
    getActivityTypesList,
    resetActivityTypesSlice,
    selectAllActiveActivityTypes,
    selectError as selectActivityTypeListError,
    selectIsLoaded as selectIsActivityTypeListLoaded,
    selectIsLoading as selectIsActivityTypeListLoading,
} from '../../../../common/store/slices/activityTypesSlice';
import {
    getActivityAudiencesList,
    resetActivityAudiencesSlice,
    selectAllActiveActivityAudiences,
    selectError as selectActivityAudienceListError,
    selectIsLoaded as selectIsActivityAudienceListLoaded,
    selectIsLoading as selectIsActivityAudienceListLoading,
} from '../../../../common/store/slices/activityAudiencesSlice';
import {
    getActivityModalitiesList,
    resetActivityModalitiesSlice,
    selectAllActiveActivityModalities,
    selectError as selectActivityModalityListError,
    selectIsLoaded as selectIsActivityModalityListLoaded,
    selectIsLoading as selectIsActivityModalityListLoading,
} from '../../../../common/store/slices/activityModalitiesSlice';
import {
    getPartnerInitiativesList,
    resetPartnerInitiativesSlice,
    selectAllActivePartnerInitiatives,
    selectError as selectPartnerInitiativeListError,
    selectIsLoaded as selectIsPartnerInitiativeListLoaded,
    selectIsLoading as selectIsPartnerInitiativeListLoading,
} from '../../../../common/store/slices/partnerInitiativesSlice';
import {
    getLocationsList,
    initializeLocationsListQueryParams,
    resetLocationsSlice,
    resetPartialLocationsSlice,
    selectAllActiveLocations,
    selectError as selectLocationListError,
    selectIsLoaded as selectIsLocationListLoaded,
    selectIsLoading as selectIsLocationListLoading,
    selectSearchText as selectLocationListSearchText,
    setSearchText as setLocationListSearchText,
    setRegion as setLocationListRegion,
} from '../../../../common/store/slices/locationsSlice';
import {
    getCoursesList,
    resetCoursesSlice,
    selectAllActiveCourses,
    selectError as selectCourseListError,
    selectIsLoaded as selectIsCourseListLoaded,
    selectIsLoading as selectIsCourseListLoading,
    selectSearchText as selectCourseListSearchText,
    setSearchText as setCourseListSearchText,
} from '../../../../common/store/slices/coursesSlice';
import {
    getUserList,
    resetUserListSlice,
    selectError as selectUserListError,
    selectIsLoaded as selectIsUserListLoaded,
    selectIsLoading as selectIsUserListLoading,
    selectSearchText as selectUserListSearchText,
    selectUserList,
    setSearchText as setUserListSearchText,
    setViasRoles,
} from '../../../../common/store/slices/userListSlice';
import {
    getDeliveryLanguagesList,
    resetDeliveryLanguagesSlice,
    selectAllActiveDeliveryLanguages,
    selectError as selectDeliveryLanguageListError,
    selectIsLoaded as selectIsDeliveryLanguageLoaded,
    selectIsLoading as selectIsDeliveryLanguageLoading,
} from '../../../../common/store/slices/deliveryLanguagesSlice';
import { selectUser } from '../../../../common/store/slices/userSlice';
import { LocationItemData } from '../../../../common/interfaces/businessDataItem/locationItem';
import { ProgramTypeItemData } from '../../../../common/interfaces/businessDataItem/programTypeItem';
import { ActivityStatusItemData } from '../../../../common/interfaces/businessDataItem/activityStatusItem';
import { ActivityTypeItemData } from '../../../../common/interfaces/businessDataItem/activityTypeItem';
import { ActivityAudienceItemData } from '../../../../common/interfaces/businessDataItem/activityAudienceItem';
import { ActivityModalityItemData } from '../../../../common/interfaces/businessDataItem/activityModalityItem';
import { CourseItemData } from '../../../../common/interfaces/businessDataItem/courseItem';
import { CreateActivityData } from '../../../interfaces/activity';
import {
    FormSectionMode,
    LocationFormFieldText,
    ProgramFormFieldText,
} from '../../../../common/constants/forms';
import {
    GRIMSBY_FILTER_RESULTS_COUNT,
    GANDALF_SCHEDULE_ADMIN_GROUP,
    GANDALF_SYS_ADMIN_GROUP,
    GANDALF_SCHEDULE_READ_ONLY,
} from '../../../../common/constants/grimsby';
import handlePreSelectedValue from '../../../../common/utils/handlePreSelectedValue';
import { getCityString } from '../../../../imt/components/Instructor/FormSections/BasicInfoFormSection';
import { UserProfileData } from '../../../../common/interfaces/userProfile';
import './ActivityFormSection.scss';
import {
    ActivityFormSectionProps,
    AudienceType,
    SessionAttributeEditorItem,
} from '../Common/Common';
import {
    meridiemFieldOptionLookup,
    meridiemFieldOptions,
    MeridiemFieldValue,
} from '../../../interfaces/meridiem';
import { DeliveryLanguageItemData } from '../../../../common/interfaces/businessDataItem/deliveryLanguageItem';
import Checkbox from '@amzn/awsui-components-react-v3/polaris/checkbox';
import './CloneActivityFormSection.scss';
import { Toggle } from '@amzn/awsui-components-react-v3';
import { PartnerInitiativeItemData } from '../../../../common/interfaces/businessDataItem/partnerInitiativeItem';
import AudienceListSelect from './CommonFormFields/AudienceListSelect';

export const getActivityCityString = (cityData: {
    delivery_city: string | null;
    delivery_state?: string;
    delivery_country: string;
}) => {
    const {
        delivery_city: city,
        delivery_country: country,
        delivery_state: state,
    } = cityData;
    return getCityString({
        city,
        country,
        state,
    });
};

// Session constants
const ONE_DAY = 8; // one day is 8 hours
const DAY_START_TIME = 9; // default start time for session is 9:00 AM

const getPaddedDateValue = (val: number) => val.toString().padStart(2, '0');

export const getDefaultSessionsForCourse = (
    startDateString: string,
    course: string,
    courseList: Array<CourseItemData>,
): Array<SessionAttributeEditorItem> => {
    const sessions = [] as Array<SessionAttributeEditorItem>;
    const [year, month, day] = startDateString
        .split('-')
        .map((val) => parseInt(val, 10));
    // create a local date object so that we are not impacted by Timezone offsets and manually set the year, month, and date values for date calculations
    let startDate = new Date();
    startDate.setFullYear(year, month - 1, day);

    let courseDays = 1;

    if (course) {
        courseDays = parseFloat(
            courseList.find((courseItem) => courseItem.course === course)
                ?.course_days ?? '1',
        );
    }

    const fractionalDays = courseDays % 1;
    let fullDays = courseDays - fractionalDays;

    while (fullDays > 0) {
        const endTime = DAY_START_TIME + ONE_DAY - 12;
        sessions.push({
            dateString: `${startDate.getFullYear()}-${getPaddedDateValue(
                startDate.getMonth() + 1, // increment month by 1 as to not mess up session calculations in getDeliverySession
            )}-${getPaddedDateValue(startDate.getDate())}`, // pad left with zeroes if single digit to not mess up session calculations in getDeliverySession
            startTime: `${getPaddedDateValue(DAY_START_TIME)}:00`, // pad left with zeroes if single digit to not mess up session calculations in getDeliverySession
            endTime: `${getPaddedDateValue(endTime)}:00`, // pad left with zeroes if single digit to not mess up session calculations in getDeliverySession
            startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
            endTimeMeridiemFieldOption: MeridiemFieldValue.Pm,
        });

        // increment start date
        startDate.setDate(startDate.getDate() + 1);

        fullDays--;
    }

    if (fractionalDays) {
        const sessionHours = Math.floor(fractionalDays * ONE_DAY);
        const sessionMinutes = Math.floor(
            ((fractionalDays * ONE_DAY) % 1) * 60,
        );
        const endTime =
            DAY_START_TIME + sessionHours > 12
                ? DAY_START_TIME + sessionHours - 12
                : DAY_START_TIME + sessionHours;
        sessions.push({
            dateString: `${startDate.getFullYear()}-${getPaddedDateValue(
                startDate.getMonth() + 1, // increment month by 1 as to not mess up session calculations in getDeliverySession
            )}-${getPaddedDateValue(startDate.getDate())}`, // pad left with zeroes if single digit to not mess up session calculations in getDeliverySession
            startTime: `${getPaddedDateValue(DAY_START_TIME)}:00`, // pad left with zeroes if single digit to not mess up session calculations in getDeliverySession
            endTime: `${getPaddedDateValue(endTime)}:${getPaddedDateValue(
                sessionMinutes,
            )}`, // pad left with zeroes if single digit to not mess up session calculations in getDeliverySession
            startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
            endTimeMeridiemFieldOption:
                sessionHours >= 4
                    ? MeridiemFieldValue.Pm
                    : MeridiemFieldValue.Am,
        });
    }

    return sessions;
};

const ActivityFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    mode,
    handleSessionItemEvent,
    sessionAttributeEditorItems,
    controlArrayErrors,
    handleShortNameChange,
    handleCourseDaysChange,
    handleCustomersChange,
    handleInvoicesChange,
    handleCountryCodeChange,
    handleAutoAssignInstructorChange,
    handlePartnerInitiativeChange,
}: ActivityFormSectionProps<Omit<CreateActivityData, 'delivery_sessions'>> & {
    handleShortNameChange?: (shortName: string) => void;
    handleCourseDaysChange: (courseDays: number) => void;
    handleCountryCodeChange?: (countryCode: string | undefined) => void;
    handleAutoAssignInstructorChange: (
        autoAssignInstructor: boolean | undefined,
    ) => void;
    handlePartnerInitiativeChange: (
        partnerInitiative: string | undefined,
    ) => void;
}) => {
    const citySearchText = useSelector(selectLocationListSearchText);
    const courseSearchText = useSelector(selectCourseListSearchText);
    const userSearchText = useSelector(selectUserListSearchText);
    const isLocationListLoading = useSelector(selectIsLocationListLoading);
    const isLocationListLoaded = useSelector(selectIsLocationListLoaded);
    const locationListError = useSelector(selectLocationListError);
    const isActivityStatusListLoading = useSelector(
        selectIsActivityStatusListLoading,
    );
    const isActivityStatusListLoaded = useSelector(
        selectIsActivityStatusListLoaded,
    );
    const activityStatusListError = useSelector(selectActivityStatusListError);
    const isActivityTypeListLoading = useSelector(
        selectIsActivityTypeListLoading,
    );
    const isActivityTypeListLoaded = useSelector(
        selectIsActivityTypeListLoaded,
    );
    const activityTypeListError = useSelector(selectActivityTypeListError);
    const isActivityAudienceListLoading = useSelector(
        selectIsActivityAudienceListLoading,
    );
    const isActivityAudienceListLoaded = useSelector(
        selectIsActivityAudienceListLoaded,
    );
    const activityAudienceListError = useSelector(
        selectActivityAudienceListError,
    );
    const isActivityModalityListLoading = useSelector(
        selectIsActivityModalityListLoading,
    );
    const isActivityModalityListLoaded = useSelector(
        selectIsActivityModalityListLoaded,
    );
    const activityModalityListError = useSelector(
        selectActivityModalityListError,
    );
    const isPartnerInitiativeListLoading = useSelector(
        selectIsPartnerInitiativeListLoading,
    );
    const isPartnerInitiativeListLoaded = useSelector(
        selectIsPartnerInitiativeListLoaded,
    );
    const partnerInitiativeListError = useSelector(
        selectPartnerInitiativeListError,
    );
    const isCourseListLoading = useSelector(selectIsCourseListLoading);
    const isCourseListLoaded = useSelector(selectIsCourseListLoaded);
    const courseListError = useSelector(selectCourseListError);
    const isDeliveryLanguageListLoading = useSelector(
        selectIsDeliveryLanguageLoading,
    );
    const isDeliveryLanguageListLoaded = useSelector(
        selectIsDeliveryLanguageLoaded,
    );
    const deliveryLanguageListError = useSelector(
        selectDeliveryLanguageListError,
    );
    const isUserListLoading = useSelector(selectIsUserListLoading);
    const isUserListLoaded = useSelector(selectIsUserListLoaded);
    const userListError = useSelector(selectUserListError);
    const originalLocationList = useSelector(selectAllActiveLocations);
    const preselectedCityId = getActivityCityString(formValues);
    const shouldCheckForValue = !!(
        formValues.delivery_city &&
        formValues.delivery_country &&
        formValues.delivery_geo &&
        formValues.delivery_region
    );
    const locationList = handlePreSelectedValue(
        {
            city: formValues.delivery_city as string,
            state: formValues.delivery_state as string,
            country: formValues.delivery_country,
            geo: formValues.delivery_geo,
            region: formValues.delivery_region,
            active: true,
            pk: `location-${Date.now()}`,
            city_timezone: formValues.delivery_timezone,
        },
        shouldCheckForValue && isLocationListLoaded,
        originalLocationList,
        (location) => getCityString(location) === preselectedCityId,
    );
    const activityStatusList = useSelector(selectAllActiveActivityStatuses);
    const activityTypeList = useSelector(selectAllActiveActivityTypes);
    const activityAudienceList = useSelector(selectAllActiveActivityAudiences);
    const activityModalityList = useSelector(selectAllActiveActivityModalities);
    const partnerInitiativeList = useSelector(
        selectAllActivePartnerInitiatives,
    );
    const courseList = useSelector(selectAllActiveCourses);
    const deliveryLanguageList = useSelector(selectAllActiveDeliveryLanguages);
    const userProfile = useSelector(selectUser);
    const originalUserList = useSelector(selectUserList);
    const userList = userProfile?.profile
        ? handlePreSelectedValue(
              userProfile.profile,
              !!formValues.operations_owner && isUserListLoaded,
              originalUserList,
              (user) => user.full_name === formValues.operations_owner,
          )
        : originalUserList;
    const [filteredCourseList, setFilteredCourseList] = useState(
        [] as Array<CourseItemData>,
    );
    const [programSelected, setProgramSelected] = useState(false);
    const [customers, setCustomers] = useState<Array<number>>(
        formValues.customers?.map((c, i) => i) ?? [],
    );
    const [invoices, setInvoices] = useState<Array<number>>(
        formValues.billing_invoices?.map((inv, i) => i) ?? [],
    );
    const dispatch = useDispatch();
    const [isAutoAssignInstructorToggled, setIsAutoAssignInstructorToggled] =
        useState(true);
    const [
        isAutoAssignInstructorToggleVisible,
        setIsAutoAssignInstructorToggleVisible,
    ] = useState(false);

    const [isPartnerInitiativeVisible, setIsPartnerInitiativeVisible] =
        useState(false);

    useEffect(() => {
        const isVilt = formValues.activity_modality === 'vILT';
        const isCommercial = formValues.program === 'Commercial';
        const isPartnerProgram = formValues.program === 'Partner Program';
        const isViltCommercialOrPartnerProgram =
            isVilt && (isCommercial || isPartnerProgram);
        const partnerInitiativeIsVisible = isPartnerProgram;
        setIsPartnerInitiativeVisible(partnerInitiativeIsVisible);

        setIsAutoAssignInstructorToggleVisible(
            isViltCommercialOrPartnerProgram,
        );
    }, [formValues]);

    const [isTopicNameVisible, setIsTopicNameVisible] = useState(false);

    useEffect(() => {
        handleAutoAssignInstructorChange(
            isAutoAssignInstructorToggled &&
                isAutoAssignInstructorToggleVisible,
        );
    }, [isAutoAssignInstructorToggled, isAutoAssignInstructorToggleVisible]);

    useEffect(() => {
        // initialize query params for business data slices
        // this code block should only run once
        dispatch(
            initializeLocationsListQueryParams({
                active: null,
                size: GRIMSBY_FILTER_RESULTS_COUNT,
            }),
        );
        dispatch(setLocationListRegion(userProfile?.profile.regions || []));
        dispatch(
            setViasRoles([
                GANDALF_SCHEDULE_ADMIN_GROUP,
                GANDALF_SYS_ADMIN_GROUP,
            ]),
        );

        return () => {
            // reset business data slices
            // this code block should only run once
            [
                resetLocationsSlice,
                resetActivityStatusesSlice,
                resetActivityTypesSlice,
                resetActivityAudiencesSlice,
                resetActivityModalitiesSlice,
                resetPartnerInitiativesSlice,
                resetCoursesSlice,
                resetUserListSlice,
            ].forEach((resetFunction) => dispatch(resetFunction()));
        };
    }, [dispatch, userProfile]);

    // lifecycle method to fetch (and re- fetch) business data
    useEffect(() => {
        (
            [
                [
                    !isLocationListLoaded && !isLocationListLoading,
                    getLocationsList,
                ],
                [
                    !isActivityStatusListLoaded && !isActivityStatusListLoading,
                    getActivityStatusesList,
                ],
                [
                    !isActivityTypeListLoaded && !isActivityTypeListLoading,
                    getActivityTypesList,
                ],
                [
                    !isActivityAudienceListLoaded &&
                        !isActivityAudienceListLoading,
                    getActivityAudiencesList,
                ],
                [
                    !isActivityModalityListLoaded &&
                        !isActivityModalityListLoading,
                    getActivityModalitiesList,
                ],
                [
                    !isPartnerInitiativeListLoaded &&
                        !isPartnerInitiativeListLoading,
                    getPartnerInitiativesList,
                ],
                [!isCourseListLoaded && !isCourseListLoading, getCoursesList],
                [!isUserListLoaded && !isUserListLoading, getUserList],
                [
                    !isDeliveryLanguageListLoaded &&
                        !isDeliveryLanguageListLoading,
                    getDeliveryLanguagesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    useEffect(() => {
        if (handleInvoicesChange && handleCustomersChange) {
            handleInvoicesChange(invoices);
            handleCustomersChange(customers);
        }
    }, [invoices, customers, handleInvoicesChange, handleCustomersChange]);

    useEffect(() => {
        if (courseList) {
            let availableCourses: Array<CourseItemData> =
                [] as Array<CourseItemData>;
            if (formValues.program) {
                availableCourses = courseList.filter((course: CourseItemData) =>
                    course.associated_programs.includes(formValues.program),
                );
            }
            setFilteredCourseList(availableCourses);
            setProgramSelected(true);
        }
    }, [formValues.program, programSelected, courseList]);

    // populate user profile programs as dropdown list
    const programList: Array<ProgramTypeItemData> = (
        userProfile?.profile.programs || []
    ).map(
        (val) =>
            ({
                pk: `${val}-${Date.now()}`,
                program_type: val,
                active: true,
            }) as ProgramTypeItemData,
    );

    const { valueLookup: programLookup, valueOptions: programOptions } =
        getOptionsAndLookupForSelectInput<ProgramTypeItemData>(
            programList,
            (program: ProgramTypeItemData) => ({
                label: program.program_type,
                id: program.pk as string,
            }),
        );

    const {
        valueLookup: activityStatusLookup,
        valueOptions: activityStatusOptions,
    } = getOptionsAndLookupForSelectInput<ActivityStatusItemData>(
        activityStatusList.filter((activityStatus) => {
            return ['Hold', 'Tentative'].includes(
                activityStatus.activity_status,
            );
        }),
        (activityStatus: ActivityStatusItemData) => ({
            label: activityStatus.activity_status,
            id: activityStatus.pk as string,
        }),
    );

    const {
        valueLookup: activityTypeLookup,
        valueOptions: activityTypeOptions,
    } = getOptionsAndLookupForSelectInput<ActivityTypeItemData>(
        activityTypeList,
        (activityType: ActivityTypeItemData) => ({
            label: activityType.activity_type,
            id: activityType.pk as string,
        }),
    );

    const {
        valueLookup: activityModalityLookup,
        valueOptions: activityModalityOptions,
    } = getOptionsAndLookupForSelectInput<ActivityModalityItemData>(
        activityModalityList,
        (activityModality: ActivityModalityItemData) => ({
            label: activityModality.activity_modality,
            id: activityModality.pk as string,
        }),
    );

    const {
        valueLookup: partnerInitiativeLookup,
        valueOptions: partnerInitiativeOptions,
    } = getOptionsAndLookupForSelectInput<PartnerInitiativeItemData>(
        partnerInitiativeList,
        (partnerInitiative: PartnerInitiativeItemData) => ({
            label: partnerInitiative.partner_initiative,
            id: partnerInitiative.pk as string,
        }),
    );

    const { valueLookup: courseLookup, valueOptions: courseOptions } =
        getOptionsAndLookupForSelectInput<CourseItemData>(
            filteredCourseList,
            (course: CourseItemData) => ({
                label: course.course,
                id: course.pk as string,
            }),
        );

    const { valueLookup: userLookup, valueOptions: userOptions } =
        getOptionsAndLookupForSelectInput<UserProfileData>(
            userList,
            (profile: UserProfileData) => ({
                label: profile.full_name,
                id: profile.email as string,
            }),
        );

    const { citiesOptions, citiesOptionLookup, citiesEntityLookup } =
        locationList.reduce(
            (acc, city) => {
                const option = {
                    label: getCityString(city),
                    id: getCityString(city),
                };
                acc.citiesEntityLookup[option.id] = city;
                acc.citiesOptionLookup[option.id] = option;

                const shouldAddOptionToList = !(
                    citySearchText && option.id === preselectedCityId
                );

                if (shouldAddOptionToList) {
                    acc.citiesOptions.push(option);
                }

                return acc;
            },
            {
                citiesOptions: [] as Array<Select.Option>,
                citiesOptionLookup: {} as {
                    [key: string]: Select.Option;
                },
                citiesEntityLookup: {} as {
                    [key: string]: LocationItemData;
                },
            },
        );

    const handleLocationDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
    ) => {
        if (e.detail.value !== citySearchText) {
            dispatch(setLocationListSearchText(e.detail.value));
            dispatch(getLocationsList());
        }
    };

    const handleLocationChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setLocationListSearchText(null));
            const cityLookupRecord =
                citiesEntityLookup[e.detail.selectedOption.id];
            const {
                city: delivery_city,
                state: delivery_state,
                country: delivery_country,
                geo: delivery_geo,
                region: delivery_region,
                city_timezone: delivery_timezone,
            } = cityLookupRecord;
            if (handleCountryCodeChange) {
                handleCountryCodeChange(cityLookupRecord.country_code);
            }
            handleFieldEvent({
                delivery_city,
                delivery_state,
                delivery_country,
                delivery_geo,
                delivery_region,
                ...(delivery_timezone ? { delivery_timezone } : {}),
            });
        }
    };

    const handleOperationsOwnerDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
    ) => {
        dispatch(setUserListSearchText(e.detail.value));
        dispatch(
            setViasRoles([
                GANDALF_SCHEDULE_ADMIN_GROUP,
                GANDALF_SYS_ADMIN_GROUP,
            ]),
        );
        dispatch(getUserList());
    };

    const handleCustomerSuccessManagerDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
    ) => {
        dispatch(setUserListSearchText(e.detail.value));
        dispatch(
            setViasRoles([
                GANDALF_SCHEDULE_READ_ONLY,
                GANDALF_SCHEDULE_ADMIN_GROUP,
                GANDALF_SYS_ADMIN_GROUP,
            ]),
        );
        dispatch(getUserList());
    };

    const handleOperationsOwnerChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setUserListSearchText(null));
            handleFieldEvent({
                operations_owner: e.detail.selectedOption.label,
                operations_owner_email: e.detail.selectedOption.id,
            });
        }
    };

    const handleSchedulerChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setUserListSearchText(null));
            handleFieldEvent({
                scheduler: e.detail.selectedOption.label,
                scheduler_email: e.detail.selectedOption.id,
            });
        }
    };

    const handleCustomerSuccessManagerChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setUserListSearchText(null));
            handleFieldEvent({
                customer_support_manager: e.detail.selectedOption.label,
                customer_support_manager_email: e.detail.selectedOption.id,
            });
        }
    };

    const getCustomerSuccessManagerSelectedOption = () => {
        return userLookup.hasOwnProperty(formValues.customer_support_manager)
            ? userLookup[formValues.customer_support_manager]
            : {
                  id: formValues.customer_support_manager_email,
                  label: formValues.customer_support_manager,
              };
    };

    const getSchedulerSelectedOption = () => {
        return userLookup.hasOwnProperty(formValues.scheduler)
            ? userLookup[formValues.scheduler]
            : { id: formValues.scheduler_email, label: formValues.scheduler };
    };

    const getOperationsOwnerSelectedOption = () => {
        return userLookup.hasOwnProperty(formValues.operations_owner)
            ? userLookup[formValues.operations_owner]
            : {
                  id: formValues.operations_owner_email,
                  label: formValues.operations_owner,
              };
    };

    const handleCourseDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
    ) => {
        if (e.detail.value !== courseSearchText) {
            dispatch(setCourseListSearchText(e.detail.value));
            dispatch(getCoursesList());
        }
    };

    const handleCourseChange = (e: CustomDetailEvent<Select.ChangeDetail>) => {
        if (e.detail.selectedOption) {
            dispatch(setCourseListSearchText(null));

            // change sessions if there is a start day already selected
            if (
                sessionAttributeEditorItems.length &&
                sessionAttributeEditorItems[0].dateString
            ) {
                const sessions = getDefaultSessionsForCourse(
                    sessionAttributeEditorItems[0].dateString,
                    e.detail.selectedOption.label,
                    courseList,
                );
                handleSessionItemEvent(sessions);
            }

            const courseName = e.detail.selectedOption.label;

            handleFieldEvent({
                course_name: courseName,
            });
            const courseItem = courseList.find(
                (course: CourseItemData) => course.course === courseName,
            );
            setIsTopicNameVisible(courseItem?.topic_name_required);
            const courseShortName = courseItem?.short_name as string;
            let courseDays = courseItem?.course_days as string;
            if (courseDays && courseDays !== '') {
                handleCourseDaysChange(parseFloat(courseDays));
            }
            if (handleShortNameChange) {
                handleShortNameChange(
                    courseShortName || courseName.substring(0, 10),
                );
            }
        }
    };
    const handleProgramChange = (programType: string) => {
        handleFieldEvent({
            program: programType,
        });
        let availableCourses: Array<CourseItemData> =
            [] as Array<CourseItemData>;
        if (programType) {
            availableCourses = courseList.filter((course: CourseItemData) =>
                course.associated_programs.includes(programType),
            );
        }
        setFilteredCourseList(availableCourses);
        handleFieldEvent({ course_name: '' });
        setProgramSelected(true);
        if (programType === 'Marketing') {
            handleFieldEvent({
                activity_audience: AudienceType.Public,
            });
        }
    };

    const handleSessionDateChange = (
        val: string,
        sessionItem: SessionAttributeEditorItem,
        index: number,
    ) => {
        if (index > 0) {
            const newItems = sessionAttributeEditorItems.slice();
            newItems.splice(index, 1, {
                ...sessionItem,
                dateString: val,
            });

            handleSessionItemEvent(newItems);
        } else {
            handleSessionItemEvent(
                getDefaultSessionsForCourse(
                    val,
                    formValues.course_name,
                    courseList,
                ),
            );
        }
    };

    const handleAutoAssignInstructorToggled = () => {
        setIsAutoAssignInstructorToggled(!isAutoAssignInstructorToggled);
    };

    const sessionAttributeEditorDefinition: Array<AttributeEditor.FieldDefinition> =
        [
            {
                label: 'Session dates',
                control: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) => (
                    <DatePicker
                        placeholder="YYYY/MM/DD"
                        todayLabel="Today"
                        nextMonthLabel="Next month"
                        previousMonthLabel="Previous month"
                        value={
                            sessionItem.dateString ? sessionItem.dateString : ''
                        }
                        onChange={(e) => {
                            handleSessionDateChange(
                                e.detail.value,
                                sessionItem,
                                index,
                            );
                        }}
                        data-testid={`${mode}ActivityDate${index}`}
                    />
                ),
                errorText: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.dateString,
            },
            {
                label: 'Start time',
                control: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) => (
                    <div className="time-selector">
                        <div>
                            <TimeInput
                                data-testid={`${mode}ActivityStartTime${index}`}
                                format="hh:mm"
                                placeholder="hh:mm"
                                use24Hour={false}
                                value={
                                    sessionItem.startTime
                                        ? sessionItem.startTime
                                        : ''
                                }
                                onChange={(e) => {
                                    const newItems =
                                        sessionAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...sessionItem,
                                        startTime: e.detail.value,
                                    });
                                    handleSessionItemEvent(newItems);
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                options={meridiemFieldOptions}
                                selectedOption={
                                    sessionItem.startTimeMeridiemFieldOption
                                        ? meridiemFieldOptionLookup[
                                              sessionItem
                                                  .startTimeMeridiemFieldOption
                                          ]
                                        : meridiemFieldOptionLookup[
                                              MeridiemFieldValue.Am
                                          ]
                                }
                                onChange={(e) => {
                                    const newItems =
                                        sessionAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...sessionItem,
                                        startTimeMeridiemFieldOption: e.detail
                                            .selectedOption
                                            .label as MeridiemFieldValue,
                                    });
                                    handleSessionItemEvent(newItems);
                                }}
                                data-testid={`${mode}ActivityEnd${index}`}
                            />
                        </div>
                    </div>
                ),
                errorText: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.startTime ||
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.startTimeMeridiemFieldOption,
            },
            {
                label: 'End time',
                control: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) => (
                    <div className="time-selector">
                        <div>
                            <TimeInput
                                data-testid={`${mode}ActivityEndTime${index}`}
                                format="hh:mm"
                                placeholder="hh:mm"
                                use24Hour={false}
                                value={
                                    sessionItem.endTime
                                        ? sessionItem.endTime
                                        : ''
                                }
                                onChange={(e) => {
                                    const newItems =
                                        sessionAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...sessionItem,
                                        endTime: e.detail.value,
                                    });
                                    handleSessionItemEvent(newItems);
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                options={meridiemFieldOptions}
                                selectedOption={
                                    sessionItem.endTimeMeridiemFieldOption
                                        ? meridiemFieldOptionLookup[
                                              sessionItem
                                                  .endTimeMeridiemFieldOption
                                          ]
                                        : meridiemFieldOptionLookup[
                                              MeridiemFieldValue.Am
                                          ]
                                }
                                onChange={(e) => {
                                    const newItems =
                                        sessionAttributeEditorItems.slice();
                                    newItems.splice(index, 1, {
                                        ...sessionItem,
                                        endTimeMeridiemFieldOption: e.detail
                                            .selectedOption
                                            .label as MeridiemFieldValue,
                                    });
                                    handleSessionItemEvent(newItems);
                                }}
                                data-testid={`${mode}ActivityEnd${index}`}
                            />
                        </div>
                    </div>
                ),
                errorText: (
                    sessionItem: SessionAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.endTime ||
                    controlArrayErrors.sessionAttributeEditorItems?.[index]
                        ?.endTimeMeridiemFieldOption,
            },
        ];

    const {
        valueLookup: deliveryLanguageLookup,
        valueOptions: deliveryLanguageOptions,
    } = getOptionsAndLookupForSelectInput<DeliveryLanguageItemData>(
        deliveryLanguageList,
        (deliveryLanguage: DeliveryLanguageItemData) => ({
            label: deliveryLanguage.delivery_language,
            id: deliveryLanguage.pk as string,
        }),
    );

    const handleCustomerSelected = (customer: any, index: number) => {
        let newCustomerArray = [...customers];
        let newInvoices = [...invoices];

        newCustomerArray.push(index);
        (formValues.billing_invoices ?? []).forEach((invoice, invoiceIndex) => {
            if (
                !invoices.includes(invoiceIndex) &&
                invoice.customer_name === customer.customer_name &&
                (invoice.sfdc_opportunity_id === customer.sfdc_opportunity_id ||
                    (!invoice.sfdc_opportunity_id &&
                        !customer.sfdc_opportunity_id))
            ) {
                newInvoices.push(invoiceIndex);
            }
        });

        setInvoices(newInvoices);
        setCustomers(newCustomerArray);
    };

    const handleCustomerUnselected = (customer: any, index: number) => {
        let newCustomerArray = [...customers];
        let newInvoices = [...invoices];

        newCustomerArray = newCustomerArray.filter((i) => {
            return i !== index;
        });

        (formValues.billing_invoices ?? []).forEach((invoice, invoiceIndex) => {
            if (
                invoices.includes(invoiceIndex) &&
                invoice.customer_name === customer.customer_name &&
                (invoice.sfdc_opportunity_id === customer.sfdc_opportunity_id ||
                    (!invoice.sfdc_opportunity_id &&
                        !customer.sfdc_opportunity_id))
            ) {
                newInvoices.splice(invoiceIndex, 1);
            }
        });

        setInvoices(newInvoices);
        setCustomers(newCustomerArray);
    };

    return (
        <FormSection
            data-testid="ActivityFormSection"
            header="Activity details"
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    {handleInvoicesChange && (
                        <FormField label="Name">
                            <Input
                                value={formValues.activity_name}
                                data-testid={'activity-name'}
                                onChange={(e) => {
                                    handleFieldEvent({
                                        activity_name: e.detail.value,
                                    });
                                }}
                            />
                        </FormField>
                    )}
                    <FormField
                        errorText={errors?.activity_status}
                        label="Activity status"
                    >
                        <Select
                            className={
                                errors?.activity_status && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isActivityStatusListLoading
                                    ? 'Loading activity statuses'
                                    : 'Select an activity status'
                            }
                            options={activityStatusOptions}
                            selectedOption={
                                activityStatusLookup[formValues.activity_status]
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    activity_status:
                                        e.detail.selectedOption.label,
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetActivityStatusesSlice());
                                dispatch(getActivityStatusesList());
                            }}
                            loadingText="Loading activity statuses"
                            errorText="An error occurred while loading activity statuses"
                            recoveryText="Retry"
                            empty="No activity statuses found"
                            statusType={getStatusType(
                                isActivityStatusListLoading,
                                isActivityStatusListLoaded,
                                activityStatusListError,
                            )}
                            data-testid={'activity-status'}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.activity_type}
                        label="Activity type"
                    >
                        <Select
                            className={
                                errors?.activity_type && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isActivityTypeListLoading
                                    ? 'Loading activity types'
                                    : 'Select an activity type'
                            }
                            options={activityTypeOptions}
                            selectedOption={
                                activityTypeLookup[formValues.activity_type]
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    activity_type:
                                        e.detail.selectedOption.label,
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetActivityTypesSlice());
                                dispatch(getActivityTypesList());
                            }}
                            loadingText="Loading activity types"
                            errorText="An error occurred while loading activity types"
                            recoveryText="Retry"
                            empty="No activity types found"
                            statusType={getStatusType(
                                isActivityTypeListLoading,
                                isActivityTypeListLoaded,
                                activityTypeListError,
                            )}
                            data-testid={'activity-type'}
                        />
                    </FormField>
                    <FormField errorText={errors?.program} label="Program">
                        <Select
                            className={errors?.program && FORM_ERROR_SELECTOR}
                            placeholder={
                                mode === FormSectionMode.Edit
                                    ? ProgramFormFieldText.Loading
                                    : ProgramFormFieldText.PlaceholderSingular
                            }
                            options={programOptions}
                            selectedOption={programLookup[formValues.program]}
                            onChange={(e) =>
                                handleProgramChange(
                                    e.detail.selectedOption.label,
                                )
                            }
                            loadingText={ProgramFormFieldText.Loading}
                            errorText={ProgramFormFieldText.Error}
                            recoveryText={ProgramFormFieldText.Recovery}
                            empty={ProgramFormFieldText.Empty}
                            disabled={mode === FormSectionMode.Edit}
                            data-testid={'activity-program'}
                        />
                    </FormField>
                    {isPartnerInitiativeVisible && (
                        <FormField
                            errorText={errors?.partner_initiative}
                            label="Partner initiative"
                        >
                            <Select
                                className={
                                    errors?.partner_initiative &&
                                    FORM_ERROR_SELECTOR
                                }
                                placeholder={
                                    mode === FormSectionMode.Edit &&
                                    isPartnerInitiativeListLoading
                                        ? 'Loading partner initiatives'
                                        : 'Select a partner initiative'
                                }
                                options={partnerInitiativeOptions}
                                selectedOption={
                                    partnerInitiativeLookup[
                                        formValues.partner_initiative
                                    ]
                                }
                                onChange={(e) =>
                                    handleFieldEvent({
                                        partner_initiative:
                                            e.detail.selectedOption.label,
                                    })
                                }
                                onRecoveryClick={() => {
                                    dispatch(resetPartnerInitiativesSlice());
                                    dispatch(getPartnerInitiativesList());
                                }}
                                loadingText="Loading partner initiatives"
                                errorText="An error occurred while loading partner initiatives"
                                recoveryText="Retry"
                                empty="No partner initiatives found"
                                statusType={getStatusType(
                                    isPartnerInitiativeListLoading,
                                    isPartnerInitiativeListLoaded,
                                    partnerInitiativeListError,
                                )}
                                data-testid={'activity-partner-initiative'}
                            />
                        </FormField>
                    )}

                    <AudienceListSelect
                        {...{
                            handleFieldEvent,
                            formValues: {
                                activity_audience: formValues.activity_audience,
                                activity_modality: formValues.activity_modality,
                                program: formValues.program,
                            },
                            errors,
                        }}
                    />

                    <FormField
                        errorText={errors?.activity_modality}
                        label="Modality"
                    >
                        <Select
                            className={
                                errors?.activity_modality && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isActivityModalityListLoading
                                    ? 'Loading modalities'
                                    : 'Select a modality'
                            }
                            options={activityModalityOptions}
                            selectedOption={
                                activityModalityLookup[
                                    formValues.activity_modality
                                ]
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    activity_modality:
                                        e.detail.selectedOption.label,
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetActivityModalitiesSlice());
                                dispatch(getActivityModalitiesList());
                            }}
                            loadingText="Loading modalities"
                            errorText="An error occurred while loading modalities"
                            recoveryText="Retry"
                            empty="No modalities found"
                            statusType={getStatusType(
                                isActivityModalityListLoading,
                                isActivityModalityListLoaded,
                                activityModalityListError,
                            )}
                            data-testid={'activity-modality'}
                        />
                    </FormField>
                    <FormField errorText={errors?.course_name} label="Course">
                        <Select
                            className={
                                errors?.course_name && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isCourseListLoading
                                    ? 'Loading courses'
                                    : 'Select a course'
                            }
                            disabled={!programSelected}
                            options={courseOptions}
                            selectedOption={
                                formValues.course_name
                                    ? courseLookup[formValues.course_name]
                                    : null
                            }
                            onChange={handleCourseChange}
                            onRecoveryClick={() => {
                                dispatch(setCourseListSearchText(''));
                                dispatch(resetCoursesSlice());
                                dispatch(getCoursesList());
                            }}
                            onDelayedFilteringChange={
                                handleCourseDelayedFilteringChange
                            }
                            loadingText="Loading courses"
                            errorText="An error occurred while loading courses"
                            recoveryText="Retry"
                            filteringType="manual"
                            filteringPlaceholder="Enter a course name"
                            empty="No courses found"
                            statusType={getStatusType(
                                isCourseListLoading,
                                isCourseListLoaded,
                                courseListError,
                            )}
                            data-testid={'activity-course'}
                        />
                    </FormField>
                    {isTopicNameVisible && (
                        <FormField
                            errorText={errors?.topic_name}
                            label={<span>Topic Name</span>}
                        >
                            <Input
                                className={
                                    errors?.topic_name && FORM_ERROR_SELECTOR
                                }
                                value={formValues.topic_name}
                                placeholder="Enter a description for the course topic"
                                onInput={(e) =>
                                    handleFieldEvent({
                                        topic_name: e.detail.value,
                                    })
                                }
                                data-testid={`EditTopicName`}
                            />
                        </FormField>
                    )}
                    <FormField
                        label="Operations owner"
                        errorText={errors?.operations_owner}
                    >
                        <Select
                            className={
                                errors?.operations_owner && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isUserListLoading
                                    ? 'Loading users'
                                    : 'Select user'
                            }
                            empty="No users found"
                            loadingText="Loading users"
                            filteringType="manual"
                            errorText="An error occurred while loading users"
                            recoveryText="Retry"
                            statusType={getStatusType(
                                isUserListLoading,
                                isUserListLoaded,
                                userListError,
                            )}
                            options={userOptions}
                            selectedOption={getOperationsOwnerSelectedOption()}
                            filteringPlaceholder="Enter a user name"
                            onDelayedFilteringChange={
                                handleOperationsOwnerDelayedFilteringChange
                            }
                            onChange={handleOperationsOwnerChange}
                            onRecoveryClick={() => {
                                dispatch(setUserListSearchText(''));
                                dispatch(resetUserListSlice());
                                dispatch(getUserList());
                            }}
                            data-testid={'activity-operations-owner'}
                            disabled={
                                mode === FormSectionMode.Edit &&
                                !isUserListLoaded
                            }
                        />
                    </FormField>
                    <FormField label="Scheduler" errorText={errors?.scheduler}>
                        <Select
                            className={errors?.scheduler && FORM_ERROR_SELECTOR}
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isUserListLoading
                                    ? 'Loading users'
                                    : 'Select user'
                            }
                            empty="No users found"
                            loadingText="Loading users"
                            filteringType="manual"
                            errorText="An error occurred while loading users"
                            recoveryText="Retry"
                            statusType={getStatusType(
                                isUserListLoading,
                                isUserListLoaded,
                                userListError,
                            )}
                            options={userOptions}
                            selectedOption={getSchedulerSelectedOption()}
                            filteringPlaceholder="Enter a user name"
                            onDelayedFilteringChange={
                                handleOperationsOwnerDelayedFilteringChange
                            }
                            onChange={handleSchedulerChange}
                            onRecoveryClick={() => {
                                dispatch(setUserListSearchText(''));
                                dispatch(resetUserListSlice());
                                dispatch(getUserList());
                            }}
                            data-testid={'activity-scheduler'}
                            disabled={
                                mode === FormSectionMode.Edit &&
                                !isUserListLoaded
                            }
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Customer success manager <i>- optional</i>
                            </span>
                        }
                        errorText={errors?.customer_support_manager}
                    >
                        <Select
                            className={
                                errors?.customer_support_manager &&
                                FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isUserListLoading
                                    ? 'Loading users'
                                    : 'Select user'
                            }
                            empty="No users found"
                            loadingText="Loading users"
                            filteringType="manual"
                            errorText="An error occurred while loading users"
                            recoveryText="Retry"
                            statusType={getStatusType(
                                isUserListLoading,
                                isUserListLoaded,
                                userListError,
                            )}
                            options={[{ id: null, label: '-' }, ...userOptions]}
                            selectedOption={getCustomerSuccessManagerSelectedOption()}
                            filteringPlaceholder="Enter a user name"
                            onDelayedFilteringChange={
                                handleCustomerSuccessManagerDelayedFilteringChange
                            }
                            onChange={handleCustomerSuccessManagerChange}
                            onRecoveryClick={() => {
                                dispatch(setUserListSearchText(''));
                                dispatch(resetUserListSlice());
                                dispatch(getUserList());
                            }}
                            data-testid={'activity-customer-support'}
                            disabled={
                                mode === FormSectionMode.Edit &&
                                !isUserListLoaded
                            }
                        />
                    </FormField>
                    {!handleInvoicesChange && (
                        <FormField label="Provider">
                            <Input
                                readonly={true}
                                value={formValues.provider}
                                data-testid={'activity-provider-name'}
                            />
                        </FormField>
                    )}
                    <FormField
                        label="Delivery location"
                        errorText={
                            errors?.delivery_city ||
                            errors?.delivery_region ||
                            errors?.delivery_geo ||
                            errors?.delivery_country
                        }
                    >
                        <Select
                            className={
                                (errors?.delivery_city ||
                                    errors?.delivery_region ||
                                    errors?.delivery_geo ||
                                    errors?.delivery_country) &&
                                FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isLocationListLoading
                                    ? LocationFormFieldText.Loading
                                    : LocationFormFieldText.Placeholder
                            }
                            empty={LocationFormFieldText.Empty}
                            loadingText={LocationFormFieldText.Loading}
                            filteringType="manual"
                            errorText={LocationFormFieldText.Error}
                            recoveryText={LocationFormFieldText.Recovery}
                            statusType={getStatusType(
                                isLocationListLoading,
                                isLocationListLoaded,
                                locationListError,
                            )}
                            options={citiesOptions}
                            selectedOption={(() => {
                                const cityString =
                                    getActivityCityString(formValues);
                                return cityString
                                    ? citiesOptionLookup[cityString]
                                    : null;
                            })()}
                            filteringPlaceholder={
                                LocationFormFieldText.FilteringPlaceholder
                            }
                            onDelayedFilteringChange={
                                handleLocationDelayedFilteringChange
                            }
                            onChange={handleLocationChange}
                            onRecoveryClick={() => {
                                dispatch(setLocationListSearchText(''));
                                dispatch(resetPartialLocationsSlice());
                                dispatch(getLocationsList());
                            }}
                            data-testid={'activity-delivery-location'}
                            disabled={
                                mode === FormSectionMode.Edit &&
                                !isLocationListLoaded
                            }
                        />
                    </FormField>
                    <FormField
                        label={
                            <div className="awsui-util-status-inactive">
                                Timezone
                            </div>
                        }
                    >
                        {formValues.delivery_timezone}
                    </FormField>
                    <FormField
                        label="Delivery language"
                        errorText={errors?.delivery_language}
                    >
                        <Select
                            className={
                                errors?.delivery_language && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                isDeliveryLanguageListLoading
                                    ? 'Loading delivery languages'
                                    : 'Select a delivery language'
                            }
                            options={deliveryLanguageOptions}
                            selectedOption={
                                deliveryLanguageLookup[
                                    formValues.delivery_language
                                ]
                            }
                            onChange={(e) => {
                                handleFieldEvent({
                                    delivery_language:
                                        e.detail.selectedOption.label,
                                });
                            }}
                            onRecoveryClick={() => {
                                dispatch(resetDeliveryLanguagesSlice());
                                dispatch(getDeliveryLanguagesList());
                            }}
                            loadingText="Loading delivery Languages"
                            errorText="An error occurred while loading delivery languages"
                            recoveryText="Retry"
                            empty="No delivery languages found"
                            statusType={getStatusType(
                                isDeliveryLanguageListLoading,
                                isDeliveryLanguageListLoaded,
                                deliveryLanguageListError,
                            )}
                            data-testid={'activity-delivery-language'}
                            disabled={
                                mode === FormSectionMode.Create &&
                                !isDeliveryLanguageListLoaded
                            }
                        />
                    </FormField>
                    <FormField>
                        <AttributeEditor
                            data-testid="SessionAttributeEditor"
                            empty="No session dates have been added"
                            addButtonText="Add date"
                            removeButtonText="Remove"
                            items={sessionAttributeEditorItems}
                            definition={sessionAttributeEditorDefinition}
                            onAddButtonClick={() =>
                                handleSessionItemEvent([
                                    ...sessionAttributeEditorItems,
                                    {
                                        dateString: '',
                                        startTime: '',
                                        endTime: '',
                                        endTimeMeridiemFieldOption:
                                            MeridiemFieldValue.Am,
                                        startTimeMeridiemFieldOption:
                                            MeridiemFieldValue.Am,
                                    },
                                ])
                            }
                            onRemoveButtonClick={({
                                detail: { itemIndex },
                            }) => {
                                const newList =
                                    sessionAttributeEditorItems.slice();
                                newList.splice(itemIndex, 1);
                                handleSessionItemEvent(newList);
                            }}
                            isItemRemovable={() =>
                                sessionAttributeEditorItems.length > 1
                            }
                        />
                    </FormField>
                    {isAutoAssignInstructorToggleVisible && (
                        <FormField
                            label="Instructors"
                            errorText={errors?.auto_assign_instructor}
                            description={
                                'The system will automatically assign a primary instructor when the activity is saved.'
                            }
                        >
                            <Toggle
                                onChange={handleAutoAssignInstructorToggled}
                                checked={isAutoAssignInstructorToggled}
                                data-testid="auto-assign-instructor-toggle"
                            >
                                Auto Assign Instructor
                            </Toggle>
                        </FormField>
                    )}
                    {handleInvoicesChange && !!formValues.customers?.length && (
                        <>
                            <div className="grimsby-sub-section-divider" />
                            <h3>Customer and invoice details</h3>
                            <FormField>
                                {(formValues.customers ?? []).map(
                                    (customer, index) => {
                                        return (
                                            <div
                                                key={`customer-${index}`}
                                                className="form-section-checkbox-group"
                                            >
                                                <Checkbox
                                                    key={`customer-checkbox-${index}`}
                                                    onChange={({ detail }) => {
                                                        if (detail.checked) {
                                                            handleCustomerSelected(
                                                                customer,
                                                                index,
                                                            );
                                                        } else {
                                                            handleCustomerUnselected(
                                                                customer,
                                                                index,
                                                            );
                                                        }
                                                    }}
                                                    checked={customers.includes(
                                                        index,
                                                    )}
                                                    data-testid={`customer-checkbox-${index}`}
                                                >
                                                    {customer.customer_name}
                                                </Checkbox>
                                                <div
                                                    className="sub-checkbox"
                                                    key={`invoice-${index}-list}`}
                                                >
                                                    {(
                                                        formValues.billing_invoices ??
                                                        []
                                                    ).map(
                                                        (
                                                            invoice,
                                                            invoiceIndex,
                                                        ) => {
                                                            if (
                                                                invoice.customer_name ===
                                                                    customer.customer_name &&
                                                                (invoice.sfdc_opportunity_id ===
                                                                    customer.sfdc_opportunity_id ||
                                                                    (!invoice.sfdc_opportunity_id &&
                                                                        !customer.sfdc_opportunity_id))
                                                            ) {
                                                                return (
                                                                    <Checkbox
                                                                        onChange={({
                                                                            detail,
                                                                        }) => {
                                                                            let newInvoiceArray =
                                                                                [
                                                                                    ...invoices,
                                                                                ];
                                                                            if (
                                                                                detail.checked
                                                                            ) {
                                                                                newInvoiceArray.push(
                                                                                    invoiceIndex,
                                                                                );
                                                                            } else {
                                                                                newInvoiceArray =
                                                                                    newInvoiceArray.filter(
                                                                                        (
                                                                                            i,
                                                                                        ) => {
                                                                                            return (
                                                                                                i !==
                                                                                                invoiceIndex
                                                                                            );
                                                                                        },
                                                                                    );
                                                                            }

                                                                            setInvoices(
                                                                                newInvoiceArray,
                                                                            );
                                                                        }}
                                                                        checked={invoices.includes(
                                                                            invoiceIndex,
                                                                        )}
                                                                        disabled={
                                                                            !customers.includes(
                                                                                index,
                                                                            )
                                                                        }
                                                                        key={`invoice-${index}-${invoiceIndex}`}
                                                                        data-testid={`customer-checkbox-${index}-invoice-${invoiceIndex}`}
                                                                    >
                                                                        Include
                                                                        invoice
                                                                    </Checkbox>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        },
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    },
                                )}
                            </FormField>
                        </>
                    )}
                </div>
            </ColumnLayout>
        </FormSection>
    );
};

ActivityFormSection.propTypes = {
    sessionAttributeEditorItems: PropTypes.array.isRequired,
};

export default ActivityFormSection;

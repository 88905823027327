export const getKikuURL = (lms_id: string) => {
    const hostname = window?.location?.hostname ?? 'localhost';
    if (hostname === 'grimsby.bots.aws.a2z.com') {
        return `https://kiku.aws.training/admin/session?id=${lms_id}&mode=0`;
    } else {
        return `https://kiku.np.aws.training/admin/session?id=${lms_id}&mode=0`;
    }
};

export default getKikuURL;

import React from 'react';
import Link from '@amzn/awsui-components-react-v3/polaris/link';

export const UpdateActivityErrorMessage = () => {
    return (
        <>
            An error occurred while updating activity details. Please visit our{' '}
            <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href="https://w.amazon.com/bin/view/AWS_Training_and_Certification/Product_Development/ILT/Grimsby/Contact/"
                color="inverted"
                data-testid="grimsby-contact-link"
            >
                wiki
            </Link>{' '}
            for more details or report this{' '}
            <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href="https://t.corp.amazon.com/create/templates/e26f9b95-0dae-4a61-8105-05e28c74d22f"
                color="inverted"
                data-testid="grimsby-sim-link"
            >
                issue
            </Link>
            .
        </>
    );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuditTrailResponseData } from '../../interfaces/auditTrailResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import auditApi from '../../api/auditApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { GenericStoreState } from '../../interfaces/genericStoreState';
import { AuditItemData } from '../../interfaces/auditItem';
import { AUDIT_PAGE_SIZE } from '../../constants/grimsby';
import { AuditManagementAPIQueryParams } from '../../interfaces/queryParams';

export interface AuditTrailState extends GenericStoreState {
    items: AuditItemData[];
    nextToken: string | null;
    isLoaded: boolean;
}

/**
 * auditTrailSlice manages all audit trail state, and contains audit trail actions as well as audit trail state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const auditTrailSlice = createSlice({
    name: 'auditTrail',
    initialState: {
        items: [],
        nextToken: null,
        error: null,
        isLoading: false,
        isLoaded: false,
    } as AuditTrailState,
    reducers: {
        setItems: (state, action: PayloadAction<AuditItemData[]>) => {
            state.items = action.payload;
        },
        setNextToken: (state, action: PayloadAction<string>) => {
            state.nextToken = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
    },
});

export const { setItems, setNextToken, setError, setIsLoading, setIsLoaded } =
    auditTrailSlice.actions;

/**
 * getAuditTrail is an async action used to fetch audit trail data.
 * There is no explicit inclusion of redux-thunk logic, as the redux toolkit takes care of this for us.
 */
export const getAuditTrail = (id: string) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        dispatch(setIsLoading(true));
        const state = getState();
        try {
            const {
                result: { next_token, items },
            }: HandleRequestData<AuditTrailResponseData> =
                await auditApi.getAuditTrail({
                    record_id: id,
                    next_token: state.auditTrail.nextToken,
                    page_size: `${AUDIT_PAGE_SIZE}`,
                } as AuditManagementAPIQueryParams.GetAuditTrailByIdOrAlias);

            dispatch(setItems(items));
            dispatch(setNextToken(next_token));
            if (!state.auditTrail.isLoaded) {
                dispatch(setIsLoaded(true));
            }
        } catch (error: any) {
            dispatch(setError(error.toString()));
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const selectItems = (state: GlobalState) => state.auditTrail.items;
export const selectNextToken = (state: GlobalState) =>
    state.auditTrail.nextToken;
export const selectError = (state: GlobalState) => state.auditTrail.error;
export const selectIsLoading = (state: GlobalState) =>
    state.auditTrail.isLoading;
export const selectIsLoaded = (state: GlobalState) => state.auditTrail.isLoaded;

export default auditTrailSlice.reducer;

import React from 'react';
import {
    Box,
    ColumnLayout,
    Link,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { formatString } from '../../../../../common/utils/formatString';
import { ActivityData } from '../../../../interfaces/activity';
import { formatStringArray } from '../../../../../common/utils/formatStringArray';
import {
    ActivityModality,
    formatDeliverySessionTime,
    formatGrimsbyDate,
} from '../../../Activity/Common/Common';

import './ActivityPopover.scss';

const ActivityPopoverTemplate = (activity: ActivityData) => {
    const location = formatStringArray([
        activity.delivery_city,
        activity.delivery_state,
        activity.delivery_country,
    ]);
    const isILT = activity.activity_modality === ActivityModality.ILT;
    const isHybrid = activity.activity_modality === ActivityModality.Hybrid;
    const isIltOrHybrid = isILT || isHybrid;

    return (
        <>
            <ColumnLayout columns={2} variant="text-grid">
                <SpaceBetween size="l">
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Activity status
                        </Box>
                        <div>{formatString(activity.activity_status)}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Course
                        </Box>
                        <div>{formatString(activity.course_name)}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Customers
                        </Box>
                        <div>
                            {activity.customers
                                ? formatStringArray(
                                      activity.customers.map(
                                          (customer) => customer.customer_name,
                                      ),
                                  )
                                : '-'}
                        </div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            LMS link
                        </Box>
                        <div>
                            {activity.lms_id ? (
                                <Link
                                    data-testid={
                                        'AssignInstructorResourceViewInstructorLink'
                                    }
                                    external
                                    externalIconAriaLabel="Opens in a new tab"
                                    href={`https://kiku.aws.training/admin/session?id=${activity.lms_id}&mode=0`}
                                >
                                    {activity.lms_id}
                                </Link>
                            ) : (
                                '-'
                            )}
                        </div>
                    </div>
                    {isIltOrHybrid && (
                        <div>
                            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                                Delivery location
                            </Box>
                            <div>
                                <>
                                    {!!activity.delivery_address_1 &&
                                        isIltOrHybrid && (
                                            <div>
                                                {activity.delivery_address_1}
                                            </div>
                                        )}
                                    {!!activity.delivery_address_2 &&
                                        isIltOrHybrid && (
                                            <div>
                                                {activity.delivery_address_2}
                                            </div>
                                        )}
                                    {!!activity.delivery_postal_code &&
                                        isIltOrHybrid && (
                                            <div>
                                                {activity.delivery_postal_code}
                                            </div>
                                        )}
                                    {!!activity.room && isIltOrHybrid && (
                                        <div>{activity.room}</div>
                                    )}
                                    {!!location && <div>{location}</div>}
                                </>
                            </div>
                        </div>
                    )}
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Delivery language
                        </Box>
                        <div>{formatString(activity.delivery_language)}</div>
                    </div>
                    {!isIltOrHybrid && (
                        <div>
                            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                                Class size
                            </Box>
                            <div>
                                {formatString(
                                    activity.class_size
                                        ? `${activity.class_size}`
                                        : null,
                                )}
                            </div>
                        </div>
                    )}
                </SpaceBetween>
                <SpaceBetween size="l">
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Modality
                        </Box>
                        <div>{formatString(activity.activity_modality)}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Audience
                        </Box>
                        <div>{formatString(activity.activity_audience)}</div>
                    </div>
                    {isIltOrHybrid && (
                        <div>
                            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                                Class size
                            </Box>
                            <div>
                                {formatString(
                                    activity.class_size
                                        ? `${activity.class_size}`
                                        : null,
                                )}
                            </div>
                        </div>
                    )}
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Time zone
                        </Box>
                        <div>{activity.delivery_timezone}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Instructors
                        </Box>
                        <div>
                            {activity.instructors
                                ? activity.instructors.map((instructor, i) => (
                                      <div
                                          key={`quick-instructor-${instructor.pk}`}
                                      >
                                          {instructor.name} - {instructor.role}{' '}
                                          {instructor.do_not_shuffle ? (
                                              <b>(Do not shuffle)</b>
                                          ) : (
                                              ''
                                          )}
                                      </div>
                                  ))
                                : '-'}
                        </div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Session dates
                        </Box>
                        <div>
                            {activity.delivery_sessions
                                ? activity.delivery_sessions.map(
                                      (deliverySession, i) => (
                                          <div
                                              key={`delivery-session-${i}-${activity.pk}`}
                                              className="quickview_session_row"
                                          >
                                              <div>
                                                  {formatGrimsbyDate(
                                                      deliverySession.start_timestamp,
                                                      activity.delivery_timezone,
                                                  )}
                                              </div>
                                              <div>
                                                  {formatDeliverySessionTime(
                                                      deliverySession,
                                                      activity.delivery_timezone,
                                                  )}
                                              </div>
                                          </div>
                                      ),
                                  )
                                : '-'}
                        </div>
                    </div>
                </SpaceBetween>
            </ColumnLayout>
        </>
    );
};

export default ActivityPopoverTemplate;

import { FormField, Select } from '@amzn/awsui-components-react-v3';
import { ActivityData } from '../../../../../interfaces/activity';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInputPolarisV3,
    getStatusType,
} from '../../../../../../imt/components/Instructor/FormSections/FormSections.common';
import { PartnerInitiativeItemData } from '../../../../../../common/interfaces/businessDataItem/partnerInitiativeItem';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPartnerInitiativesList,
    resetPartnerInitiativesSlice,
    selectAllActivePartnerInitiatives,
    selectError as selectPartnerInitiativeListError,
    selectIsLoaded as selectIsPartnerInitiativeListLoaded,
    selectIsLoading as selectIsPartnerInitiativeListLoading,
} from '../../../../../../common/store/slices/partnerInitiativesSlice';
import { useEffect } from 'react';

interface PartnerInitiativeSectionProps {
    formValues: Omit<ActivityData, 'delivery_sessions'>;
    handleFieldEvent: (
        values: Partial<Omit<ActivityData, 'delivery_sessions'>>,
    ) => void;
    errors: any;
}

const PartnerInitiativeSection = ({
    formValues,
    handleFieldEvent,
    errors,
}: PartnerInitiativeSectionProps) => {
    const dispatch = useDispatch();

    const partnerInitiativeList = useSelector(
        selectAllActivePartnerInitiatives,
    );

    const isPartnerInitiativeListLoading = useSelector(
        selectIsPartnerInitiativeListLoading,
    );
    const isPartnerInitiativeListLoaded = useSelector(
        selectIsPartnerInitiativeListLoaded,
    );
    const partnerInitiativeListError = useSelector(
        selectPartnerInitiativeListError,
    );

    const {
        valueLookup: partnerInitiativeLookup,
        valueOptions: partnerInitiativeOptions,
    } = getOptionsAndLookupForSelectInputPolarisV3<PartnerInitiativeItemData>(
        partnerInitiativeList,
        (partnerInitiative: PartnerInitiativeItemData) => ({
            label: partnerInitiative.partner_initiative,
            value: partnerInitiative.pk as string,
        }),
    );

    useEffect(() => {
        (
            [
                [
                    !isPartnerInitiativeListLoaded &&
                        !isPartnerInitiativeListLoading,
                    getPartnerInitiativesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    useEffect(() => {
        return () => {
            [resetPartnerInitiativesSlice].forEach((resetFunction) =>
                dispatch(resetFunction()),
            );
        };
    }, [dispatch]);
    return (
        <FormField
            errorText={errors?.partner_initiative}
            label="Partner initiative"
        >
            <Select
                className={errors?.partner_initiative && FORM_ERROR_SELECTOR}
                placeholder={
                    isPartnerInitiativeListLoading
                        ? 'Loading initiatives'
                        : 'Select an initiative'
                }
                options={partnerInitiativeOptions}
                selectedOption={
                    partnerInitiativeLookup[formValues.partner_initiative]
                }
                onChange={(e) =>
                    handleFieldEvent({
                        partner_initiative: e.detail.selectedOption.label,
                    })
                }
                loadingText="Loading initiatives"
                errorText="An error occurred while loading initiatives"
                empty="No initiatives found"
                statusType={getStatusType(
                    isPartnerInitiativeListLoading,
                    isPartnerInitiativeListLoaded,
                    partnerInitiativeListError,
                )}
                disabled={!isPartnerInitiativeListLoaded}
                data-testid={`EditBaseDetailsPartnerInitiative`}
            />
        </FormField>
    );
};

export default PartnerInitiativeSection;

import { Box, ColumnLayout } from '@amzn/awsui-components-react-v3';

import { SectionContentType } from '../../constants/grimsby';

export interface HeaderData {
    label: string;
    buttons?: JSX.Element | null;
    component?: any;
}

export interface KeyValueData {
    key: string;
    value: any;
}

export interface ContentData {
    type: SectionContentType;
}

export interface BaseColumnContentData {
    columnsCount: number;
    genericContent?: JSX.Element;
    columns: KeyValueData[][];
}

export interface ColumnContentData extends ContentData, BaseColumnContentData {}

export interface ParagraphContentData extends ContentData {
    paragraph: string;
}

export interface GenericContentData extends ContentData {
    children: JSX.Element;
}

export interface TieredColumnContentData extends ContentData {
    topContent: BaseColumnContentData & { tierTitle?: string };
    bottomContent: BaseColumnContentData & { tierTitle?: string };
}

export interface SectionProps {
    testId?: string;
    className?: string;
    header: HeaderData;
    content?: ContentData;
    synced?: boolean;
}

const getColumnLayout = (
    content: BaseColumnContentData | ColumnContentData,
) => (
    <ColumnLayout columns={content.columnsCount} variant="text-grid">
        {content.columns.map((column, colIndex) => (
            <div key={colIndex}>
                {column
                    .filter((row) => row !== null)
                    .map((row, rowIndex) => (
                        <Box key={rowIndex}>
                            <div className="awsui-util-label">{row.key}</div>
                            <div>{row.value}</div>
                        </Box>
                    ))}
            </div>
        ))}
    </ColumnLayout>
);

const generatorLookup = {
    [SectionContentType.Column]: (content: ContentData) => (
        <>
            {(content as ColumnContentData).genericContent ?? null}
            {getColumnLayout(content as ColumnContentData)}
        </>
    ),
    [SectionContentType.Paragraph]: (content: ContentData) => (
        <p>{(content as ParagraphContentData).paragraph}</p>
    ),
    [SectionContentType.Generic]: (content: ContentData) =>
        (content as GenericContentData).children,
    [SectionContentType.TieredColumn]: (content: ContentData) => {
        const tieredContent = content as TieredColumnContentData;
        return (
            <>
                {tieredContent.topContent.tierTitle && (
                    <div className="grimsby-sub-section-header">
                        {tieredContent.topContent.tierTitle}
                    </div>
                )}
                {getColumnLayout(tieredContent.topContent)}
                {tieredContent.bottomContent.tierTitle && (
                    <>
                        <div className="grimsby-sub-section-divider" />
                        <div className="grimsby-sub-section-header">
                            {tieredContent.bottomContent.tierTitle}
                        </div>
                    </>
                )}
                {getColumnLayout(tieredContent.bottomContent)}
            </>
        );
    },
};

const Section = ({ testId, className, header, content }: SectionProps) => (
    <section
        data-testid={testId}
        className={`awsui-util-container ${className ? className : ''}`}
    >
        <div className="awsui-util-container-header">
            <div className="awsui-util-action-stripe">
                <div className="awsui-util-action-stripe-title">
                    {!!header?.component ? (
                        header.component
                    ) : (
                        <h2>{header.label}</h2>
                    )}
                </div>
                {header.buttons}
            </div>
        </div>
        {content ? generatorLookup[content.type](content) : null}
    </section>
);

export default Section;

import React from 'react';
import { Icon } from '@amzn/awsui-components-react';

/**
 * Construct a formatting function whose input is a boolean while returning a component with icon
 * @param labels an object which contains labels for success (true) and failure (false) status
 */
export const formatBooleanHelper = (labels: {
    successLabel: string;
    failureLabel: string;
}): ((ok: boolean) => JSX.Element) => {
    const successComponent = (
        <span className="awsui-util-status-positive nowrap">
            <Icon name="status-positive" /> {labels.successLabel}
        </span>
    );

    const failureComponent = (
        <span className="awsui-util-status-inactive nowrap">
            <Icon name="status-stopped" /> {labels.failureLabel}
        </span>
    );

    return (ok: boolean): JSX.Element =>
        ok ? successComponent : failureComponent;
};

/**
 * Construct a formatting function whose input is a string while returning a component with icon
 * @param conditions an object which contains conditions data for each status
 */
export const formatStatusHelper = (
    conditions: {
        status: string;
        statusClass: string;
        iconClass: Icon.Name;
        label: string;
    }[],
): ((status: string) => JSX.Element) => {
    const componentsLookup = new Map<string, JSX.Element>(
        conditions.map((condition) => [
            condition.status,
            <span className={`${condition.statusClass} nowrap`}>
                <Icon name={condition.iconClass} /> {condition.label}
            </span>,
        ]),
    );

    if (componentsLookup.size !== conditions.length) {
        throw Error('Duplicate condition statuses');
    }

    return (status: string): JSX.Element => {
        if (componentsLookup.has(status)) {
            return componentsLookup.get(status) as JSX.Element;
        } else {
            return (
                <span className="awsui-util-status-inactive nowrap">
                    <Icon name="status-in-progress" /> {status}
                </span>
            );
        }
    };
};

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import {
    Alert,
    Button,
    Spinner,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { useRouteMatch } from 'react-router-dom';
import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import {
    selectError,
    selectIsLoading,
    getSelectedCourse,
    selectSelectedCourse,
    updateCourse,
} from '../../../../admin/store/slices/selectedCourseSlice';
import handleEditCourseNotification from '../../../../common/utils/handleEditCourseNotification';
import { CourseInfo } from './CourseInfo';
import Can from '../../../../common/components/Can';
import { getUserRoles } from '../../../../common/utils/auth';
import { Actions } from '../../../../common/constants/auth';

const AdminCoursesDetails = () => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const selectedCourse = useSelector(selectSelectedCourse);
    const match = useRouteMatch<{ id: string }>();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    useEffect(() => {
        if (selectedCourse?.COURSE?.pk !== match.params.id) {
            dispatch(getSelectedCourse(match.params.id));
        }
    }, [dispatch, match.params.id, selectedCourse]);

    if (error && selectedCourse?.COURSE === undefined) {
        return (
            <Alert
                data-testid="AdminCourseDetailsError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    }

    if (!isLoading && selectedCourse !== undefined && selectedCourse !== null) {
        return (
            <>
                <div className="awsui-util-mb-m">
                    <Header
                        variant="h1"
                        actions={
                            <Can
                                roles={getUserRoles()}
                                perform={Actions.SYSTEM_ADMINISTRATION}
                                yes={() => (
                                    <>
                                        <Button
                                            className="admin-course-toggle-active"
                                            data-testid="AdminCourseToggleActive"
                                            onClick={async () => {
                                                const dispatchPromise =
                                                    dispatch<any>(
                                                        updateCourse({
                                                            ...selectedCourse.COURSE,
                                                            active: !selectedCourse
                                                                .COURSE.active,
                                                        }),
                                                    );
                                                await handleEditCourseNotification(
                                                    dispatchPromise,
                                                    addNotification,
                                                );
                                            }}
                                        >
                                            {selectedCourse.COURSE.active
                                                ? 'Deactivate'
                                                : 'Activate'}
                                        </Button>
                                    </>
                                )}
                                no={() => undefined}
                            />
                        }
                    >
                        {selectedCourse.COURSE.course || 'Course'}
                    </Header>
                </div>
                <SpaceBetween direction="vertical" size="xl">
                    <CourseInfo selectedCourse={selectedCourse.COURSE} />
                </SpaceBetween>
            </>
        );
    } else if (!isLoading && selectedCourse === null) {
        return <>Can't find course {match.params.id}</>;
    } else {
        return <Spinner data-testid="AdminCourseDetailsSpinner" />;
    }
};

export default AdminCoursesDetails;

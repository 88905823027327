import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getDomainSkillsList,
    selectIsLoading,
    selectIsLoaded,
    selectAllDomainSkills,
    selectError,
    updateDomainSkill,
    selectSelectedDomainSkill,
    setSelectedDomainSkill,
    addDomainSkill,
    selectCount,
    resetDomainSkillsSlice,
} from '../../../common/store/slices/domainSkillsSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { DomainSkillItemData } from '../../../common/interfaces/businessDataItem/domainSkillItem';

const DOMAIN_SKILLS_TABLE_TITLE = 'Domain skills';
const DOMAIN_SKILLS_DISPLAY_SINGULAR = 'Domain skill';
export const DOMAIN_SKILLS_NAME_KEY = 'domain_skill';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (domainSkill: DomainSkillItemData) =>
            formatStatus(domainSkill.active.toString()),
    },
];

/**
 * Editable form fields for DomainSkill
 * The keys must map to DomainSkillItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<DomainSkillItemData> =
    {
        // the keys must match InstructorDataType properties
        domain_skill: {
            type: AdminBusinessDataFormInputType.Text,
            label: `${DOMAIN_SKILLS_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (val: string): Partial<DomainSkillItemData> => {
                return { domain_skill: val };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<DomainSkillItemData> => {
                return { active: parseBoolean(val) };
            },
        },
    };

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<DomainSkillItemData> =
    {
        ...createFormSchema,
        domain_skill: {
            ...createFormSchema.domain_skill,
            disabled: true,
        },
    };

const AdminDomainSkills = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useDomainSkills(): AdminBusinessDataSelectors<DomainSkillItemData> {
        const dispatch = useDispatch();

        const items: DomainSkillItemData[] = useSelector(selectAllDomainSkills);
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: DomainSkillItemData | null = useSelector(
            selectSelectedDomainSkill,
        );
        useEffect(() => {
            dispatch(getDomainSkillsList());
            dispatch(setSelectedDomainSkill(null));

            return () => {
                dispatch(resetDomainSkillsSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (domainSkill: DomainSkillItemData) => {
        if (domainSkill) {
            dispatch(setSelectedDomainSkill(domainSkill.domain_skill));
            await dispatch(getDomainSkillsList());
        }
    };

    const handleItemUpdate = async (data: DomainSkillItemData) => {
        dispatch(setSelectedDomainSkill(null));
        const dispatchPromise = dispatch<any>(updateDomainSkill(data));
        await handleBusinessDataNotification({
            businessDataType: DOMAIN_SKILLS_NAME_KEY,
            businessDataDisplayNameLower:
                DOMAIN_SKILLS_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.domain_skill,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        domain_skill,
        active,
    }: DomainSkillItemData) => {
        const dispatchPromise = dispatch<any>(
            addDomainSkill(active, domain_skill),
        );
        await handleBusinessDataNotification({
            businessDataType: DOMAIN_SKILLS_NAME_KEY,
            businessDataDisplayNameLower:
                DOMAIN_SKILLS_DISPLAY_SINGULAR.toLowerCase(),
            itemName: domain_skill,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementDomainSkills">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={DOMAIN_SKILLS_DISPLAY_SINGULAR}
                itemNameKey={DOMAIN_SKILLS_NAME_KEY}
                title={DOMAIN_SKILLS_TABLE_TITLE}
                useItemList={useDomainSkills}
            />
        </div>
    );
};

export default AdminDomainSkills;

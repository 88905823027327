import React, { Dispatch, SetStateAction } from 'react';
import {
    Button,
    Input,
    FormField,
    Modal,
    Box,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { SavedFilterForm } from '../ManageSavedFiltersModal/ManageSavedFiltersModal';
import { SmtInstructorFilters } from '../../../interfaces/smtInstructorFilters';

export interface SaveFilterModalProps {
    isSaveFilterModalActive: boolean;
    setIsSaveFilterModalActive: Dispatch<SetStateAction<boolean>>;
    isUserLoading: boolean;
    saveFilters: (updatedFilters?: SavedFilterForm) => void;
    savedFilterName: string;
    setSavedFilterName: Dispatch<SetStateAction<string>>;
    savedFilterLookup: {
        [key: string]: SmtInstructorFilters;
    } | null;
}

const SaveFilterModal = ({
    isSaveFilterModalActive,
    setIsSaveFilterModalActive,
    isUserLoading,
    saveFilters,
    savedFilterName,
    setSavedFilterName,
    savedFilterLookup,
}: SaveFilterModalProps) => {
    return (
        <Modal
            visible={isSaveFilterModalActive}
            header="Save filter settings"
            data-testid="ActivityListSaveFilterModal"
            footer={
                <Box float={'right'}>
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid="ActivityListSaveFilterModalCancel"
                            variant="link"
                            onClick={() => setIsSaveFilterModalActive(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid="ActivityListSaveFilterModalSave"
                            disabled={
                                !savedFilterName ||
                                !!savedFilterLookup?.[savedFilterName.trim()]
                            }
                            variant="primary"
                            loading={isUserLoading}
                            onClick={() => saveFilters()}
                        >
                            {isUserLoading ? 'Saving' : 'Save'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={() => setIsSaveFilterModalActive(false)}
        >
            <FormField
                label="List name"
                description="Create a name for this list's filter settings"
                errorText={
                    !!savedFilterLookup?.[savedFilterName.trim()]
                        ? 'A filter with this name already exists. Please select another name.'
                        : null
                }
            >
                <Input
                    data-testid="InstructorListSaveFilterModalInput"
                    value={savedFilterName}
                    onChange={(e) => setSavedFilterName(e.detail.value)}
                />
            </FormField>
        </Modal>
    );
};

export default SaveFilterModal;

import React, { Dispatch, SetStateAction } from 'react';
import { Alert, Button, Modal } from '@amzn/awsui-components-react';

export interface CancelModalProps {
    cancelModalVisible: boolean;
    setCancelModalVisible: Dispatch<SetStateAction<boolean>>;
    submitting?: boolean;
    onCancelConfirm: () => void;
    testPrefix: string;
}
const CancelModal = ({
    cancelModalVisible,
    setCancelModalVisible,
    submitting,
    onCancelConfirm,
    testPrefix,
}: CancelModalProps) => {
    return (
        <Modal
            data-testid={`${testPrefix}-CancelModal`}
            visible={cancelModalVisible}
            header="Discard changes?"
            footer={
                <span className="awsui-util-f-r">
                    <Button
                        data-testid={`${testPrefix}-CancelModalDiscardBtn`}
                        variant="link"
                        disabled={!!submitting}
                        onClick={() => {
                            onCancelConfirm();
                        }}
                    >
                        Discard changes
                    </Button>
                    <Button
                        data-testid={`${testPrefix}-CancelModalKeepChangesBtn`}
                        variant="primary"
                        disabled={!!submitting}
                        onClick={() => {
                            setCancelModalVisible(false);
                        }}
                    >
                        Keep changes
                    </Button>
                </span>
            }
            onDismiss={() => {
                setCancelModalVisible(false);
            }}
        >
            <Alert type="warning">
                Any changes made will not be saved. Do you want to proceed?
            </Alert>
        </Modal>
    );
};

export default CancelModal;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HandleRequestData } from '../../interfaces/handleRequest';
import { AppDispatch, GlobalState } from '../../../main/store';
import { GenericStoreState } from '../../interfaces/genericStoreState';
import { FeatureFlagResponseData } from '../../interfaces/featureFlags';
import featureFlagsApi from '../../api/featureFlagsApi';

export interface FeatureFlagState extends GenericStoreState {
    status: number | null;
    featureFlags: FeatureFlagResponseData | null;
    isLoaded: boolean;
}

/**
 * userSlice manages all user state, and contains user actions as well as user state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const featuresSlice = createSlice({
    name: 'user',
    initialState: {} as FeatureFlagState,
    reducers: {
        setStatus: (state: FeatureFlagState, action: PayloadAction<number>) => {
            state.status = action.payload;
        },
        setFeatureFlags: (
            state: FeatureFlagState,
            action: PayloadAction<FeatureFlagResponseData>,
        ) => {
            state.featureFlags = action.payload;
        },
        setError: (state: FeatureFlagState, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (
            state: FeatureFlagState,
            action: PayloadAction<boolean>,
        ) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (
            state: FeatureFlagState,
            action: PayloadAction<boolean>,
        ) => {
            state.isLoaded = action.payload;
        },
    },
});

export const {
    setStatus,
    setFeatureFlags,
    setError,
    setIsLoading,
    setIsLoaded,
} = featuresSlice.actions;

export const getFeatureFlags = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(setIsLoading(true));
        try {
            const {
                status,
                result,
            }: HandleRequestData<FeatureFlagResponseData> =
                await featureFlagsApi.getFeatures();
            // is 200 status even checked anywhere, or do we only care about the unsuccessful get user status codes?
            dispatch(setStatus(status));
            dispatch(setFeatureFlags(result));
        } catch (error: any) {
            dispatch(setStatus(error?.statusCode));
            dispatch(setError(error.toString()));
        } finally {
            dispatch(setIsLoading(false));
            dispatch(setIsLoaded(true));
        }
    };
};

export const selectFeatures = (state: GlobalState) =>
    state.features?.featureFlags;
export const selectError = (state: GlobalState) => state.features.error;
export const selectIsLoading = (state: GlobalState) => state.features.isLoading;
export const selectIsLoaded = (state: GlobalState) => state.features.isLoaded;

export default featuresSlice.reducer;

import { ActivityData } from '../../../interfaces/activity';

type ActivityValidationKeys =
    | 'ACTIVITY_COMPLETED_DETAILS'
    | 'ACTIVE_COMMERCIAL_ILT'
    | 'ACTIVE_PARTNER_PRIVATE_ILT'
    | 'DELIVERY_DETAILS_REQUIRED'
    | 'ACTIVE_COMMERCIAL_PRIVATE_VILT'
    | 'ACTIVE_PARTNER_PRIVATE_VILT'
    | 'ACTIVE_PARTNER_PRIVATE_ILT'
    | 'ACTIVE_COMMERCIAL_HYBRID_PUBLIC_PRIVATE'
    | 'NO_VALIDATIONS_REQUIRED';

export const ACTIVITY_VALIDATION_FIELDS: {
    [key in ActivityValidationKeys]: Array<keyof Partial<ActivityData>>;
} = {
    ACTIVITY_COMPLETED_DETAILS: ['attended'],
    ACTIVE_COMMERCIAL_ILT: [
        'delivery_address_1',
        'delivery_postal_code',
        'class_size',
    ],
    ACTIVE_PARTNER_PRIVATE_ILT: [
        'delivery_address_1',
        'delivery_postal_code',
        'class_size',
    ],
    DELIVERY_DETAILS_REQUIRED: ['delivery_address_1', 'delivery_postal_code'],
    ACTIVE_COMMERCIAL_PRIVATE_VILT: ['class_size', 'lms_type', 'v_ilt_type'],
    ACTIVE_PARTNER_PRIVATE_VILT: ['lms_type', 'v_ilt_type'],
    NO_VALIDATIONS_REQUIRED: [],
    ACTIVE_COMMERCIAL_HYBRID_PUBLIC_PRIVATE: [
        'delivery_address_1',
        'delivery_postal_code',
        'class_size',
    ],
};

/**
 * Method to parse a value as a boolean
 * e.g. "False" -> false, "true" -> true
 * @param {any}
 */

type Falsey = undefined | null;

const parseBoolean = (val: string | boolean | number | Falsey): boolean => {
    const s = val && val.toString().toLowerCase().trim();
    /* eslint-disable-next-line eqeqeq */
    return s == 'true' || s == '1';
};

export default parseBoolean;

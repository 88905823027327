import { HandleRequestData } from '../../common/interfaces/handleRequest';
import handleGrimsbyApiRequest from '../../common/utils/handleGrimsbyApiRequest';
import createQueryString from '../../common/utils/createQueryString';
import { InstructorListResponseData } from '../interfaces/instructorListResponse';
import { SelectedInstructorResponseData } from '../interfaces/selectedInstructorResponse';
import { ResponseData } from '../../common/interfaces/responseData';
import { API_MAIN_PATH } from '../../common/constants/grimsby';
import { InstructorProfileData } from '../interfaces/instructorProfile';
import { CreateInstructorResponseData } from '../interfaces/createInstructorResponse';
import { InstructorManagementAPIQueryParams } from '../interfaces/queryParams';
import { BlockedTime } from '../../common/interfaces/blockedTime';
import {
    BlockedTimeResponseData,
    FreelancerAvailabilityResponseData,
} from '../interfaces/blockedTimeResponse';

export const INSTRUCTOR_PATH = API_MAIN_PATH.INSTRUCTOR_MANAGEMENT;

const getInstructors = (
    queryParams?: InstructorManagementAPIQueryParams.GetInstructors,
): Promise<HandleRequestData<InstructorListResponseData>> => {
    let path = `${INSTRUCTOR_PATH}/instructors${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<InstructorListResponseData>(path, {
        method: 'GET',
    });
};

const getInstructorById = (
    queryParams: InstructorManagementAPIQueryParams.GetInstructorById,
): Promise<HandleRequestData<SelectedInstructorResponseData>> => {
    return handleGrimsbyApiRequest<SelectedInstructorResponseData>(
        `${INSTRUCTOR_PATH}/instructors/${queryParams.id}`,
        {
            method: 'GET',
        },
    );
};

const createInstructor = (profile: InstructorProfileData) => {
    return handleGrimsbyApiRequest<CreateInstructorResponseData>(
        `${INSTRUCTOR_PATH}/instructors`,
        {
            method: 'POST',
            body: JSON.stringify(profile),
        },
    );
};

const adminUpdateInstructorById = (
    queryParams: InstructorManagementAPIQueryParams.AdminUpdateInstructorById,
): Promise<HandleRequestData<ResponseData>> => {
    return handleGrimsbyApiRequest<ResponseData>(
        `${INSTRUCTOR_PATH}/instructors/${queryParams.id}`,
        {
            method: 'PUT',
            body: JSON.stringify(queryParams.instructor),
        },
    );
};

const addBlockedTime = ({
    instructorId,
    blockedTime,
}: {
    instructorId: string;
    blockedTime: Omit<BlockedTime, 'pk'>;
}): Promise<HandleRequestData<BlockedTimeResponseData>> =>
    handleGrimsbyApiRequest(
        `${INSTRUCTOR_PATH}/instructors/${instructorId}/blocked-times`,
        {
            method: 'POST',
            body: JSON.stringify(blockedTime),
        },
    );

const updateBlockedTime = ({
    instructorId,
    blockedTimeId,
    blockedTime,
}: {
    instructorId: string;
    blockedTimeId: string | undefined;
    blockedTime: Omit<BlockedTime, 'instructor_pk' | 'pk'>;
}): Promise<HandleRequestData<BlockedTimeResponseData>> =>
    handleGrimsbyApiRequest(
        `${INSTRUCTOR_PATH}/instructors/${instructorId}/blocked-times/${blockedTimeId}`,
        {
            method: 'PUT',
            body: JSON.stringify(blockedTime),
        },
    );

const deleteBlockedTime = ({
    instructorId,
    blockedTimeId = '',
}: {
    instructorId: string;
    blockedTimeId: string | undefined;
}): Promise<HandleRequestData<BlockedTimeResponseData>> =>
    handleGrimsbyApiRequest(
        `${INSTRUCTOR_PATH}/instructors/${instructorId}/blocked-times/${blockedTimeId}`,
        {
            method: 'DELETE',
        },
    );

const batchAddBlockedTimes = (
    freelancerSchedule,
): Promise<HandleRequestData<FreelancerAvailabilityResponseData>> =>
    handleGrimsbyApiRequest(
        `${INSTRUCTOR_PATH}/instructors/blocked-times/batch`,
        {
            method: 'POST',
            body: JSON.stringify(freelancerSchedule),
        },
    );

const instructorManagementApi = {
    getInstructors,
    getInstructorById,
    createInstructor,
    adminUpdateInstructorById,
    addBlockedTime,
    updateBlockedTime,
    deleteBlockedTime,
    batchAddBlockedTimes,
};

export default instructorManagementApi;

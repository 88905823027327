import { DateRangePickerProps } from '@amzn/awsui-components-react-v3';
import dayjs from 'dayjs';

export const formatRelativeRange = (range: any) => {
    const unit = Math.abs(range.amount) === 1 ? range.unit : `${range.unit}s`;
    if (range.amount > 0) {
        return `Next ${range.amount} ${unit}`;
    }

    return `Last ${Math.abs(range.amount)} ${unit}`;
};

export const differenceInDays = ({
    start,
    end,
}: {
    start: string;
    end: string;
}) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const diffInMinutes = endDate.diff(startDate, 'minutes');
    const diffInDays = diffInMinutes / (24 * 60);
    return diffInDays;
};

export const getDaysFromRelativeRange = (
    range: DateRangePickerProps.RelativeValue,
) => {
    const { amount, unit } = range;
    const currentDate = dayjs();
    const futureDate = currentDate.add(amount, unit);
    const diffInDays = futureDate.diff(currentDate, 'days');
    return diffInDays;
};

export const dateRangeFilterI18nStrings = {
    todayAriaLabel: 'Today',
    nextMonthAriaLabel: 'Next month',
    previousMonthAriaLabel: 'Previous month',
    customRelativeRangeDurationLabel: 'Duration',
    customRelativeRangeDurationPlaceholder: 'Enter duration',
    customRelativeRangeOptionLabel: 'Custom duration',
    customRelativeRangeOptionDescription: 'Set a custom range',
    customRelativeRangeUnitLabel: 'Unit of time',
    formatUnit: (unit: string, value: number) =>
        value === 1 ? unit : `${unit}s`,
    formatRelativeRange: formatRelativeRange,
    dateTimeConstraintText: '',
    relativeModeTitle: 'Date range',
    absoluteModeTitle: 'Exact dates',
    relativeRangeSelectionHeading: 'Choose a range',
    startDateLabel: 'Start date',
    endDateLabel: 'End date',
    startTimeLabel: 'Start time',
    endTimeLabel: 'End time',
    clearButtonLabel: 'Clear',
    cancelButtonLabel: 'Cancel',
    applyButtonLabel: 'Apply',
};

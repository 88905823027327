import {
    CollectionPreferences,
    CollectionPreferencesProps,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { formatHeader } from '../modes/ActivityGroupListTableColumns';
import { ActivityGroupListColumnLabels } from '../../../../interfaces/activityGroup';
import { collectionPreferencesModalI18nStrings } from '../../../../common/collectionPreferencesModalI18nStrings';

export const pageSizeOptions = [
    { value: 25, label: '25 results' },
    { value: 50, label: '50 results' },
    { value: 100, label: '100 results' },
    { value: 200, label: '200 results' },
];

const ActivityGroupListCollectionPreferences = ({
    preferences,
    onConfirm,
}: {
    preferences: any;
    onConfirm: (detail: any) => void;
}) => {
    const columnOptions = ActivityGroupListColumnLabels.map((label) => ({
        id: label,
        label: formatHeader(label),
    }));

    return (
        <CollectionPreferences
            onConfirm={({ detail }) => {
                onConfirm(detail);
            }}
            preferences={preferences}
            pageSizePreference={{
                title: 'Page size',
                options: pageSizeOptions,
            }}
            {...collectionPreferencesModalI18nStrings.base}
            wrapLinesPreference={{
                ...collectionPreferencesModalI18nStrings.wrapLinesPreference,
            }}
            stripedRowsPreference={{
                ...collectionPreferencesModalI18nStrings.stripedRowsPreference,
            }}
            contentDensityPreference={{
                ...collectionPreferencesModalI18nStrings.contentDensityPreference,
            }}
            contentDisplayPreference={{
                ...collectionPreferencesModalI18nStrings.contentDisplayPreference,
                options: columnOptions,
            }}
        />
    );
};

export default ActivityGroupListCollectionPreferences;

import {
    Button,
    FormField,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import React, { ChangeEvent, useCallback, useMemo, useRef } from 'react';

import { DismissDetail, FileUploadProps } from '../interfaces';
import { fireChangeEvent, getBaseButtonText } from '../internal';
import { SelectedFile } from './SelectedFile';
import { SelectedFileList } from './SelectedFileList';

const FileUpload: React.FC<FileUploadProps> = ({
    accept,
    ariaLabel,
    ariaRequired,
    buttonText,
    description,
    disabled,
    errorText,
    fileMetadata,
    constraintText,
    id,
    label,
    multiple = false,
    onChange,
    value,
}: FileUploadProps) => {
    const fileInput = useRef<HTMLInputElement>(null);

    const handleButtonClick = () =>
        fileInput.current && fileInput.current.click();

    const handleChange = useCallback(
        ({ target }: ChangeEvent<HTMLInputElement>) => {
            let newValue = null;
            if (target.files && target.files[0]) {
                newValue = multiple
                    ? value instanceof Array
                        ? value.concat(Array.from(target.files))
                        : [target.files]
                    : target.files[0];
            }
            if (onChange) {
                fireChangeEvent(onChange, { value: newValue });
            }
        },
        [value, multiple, onChange],
    );

    const handleDismiss = useCallback(
        ({ detail }: CustomEvent<DismissDetail>) => {
            const { index, file } = detail;
            let newValue = value;
            if (multiple && value instanceof Array && value[index]) {
                newValue = value.filter((f, i) => f !== file && i !== index);
            }
            if (onChange) {
                fireChangeEvent(onChange, { value: newValue });
            }
        },
        [value, multiple, onChange],
    );

    const baseButtonText = useMemo((): React.ReactNode => {
        return getBaseButtonText(multiple, buttonText);
    }, [multiple, buttonText]);

    const selectedFiles = useMemo((): React.ReactNode => {
        if (errorText) {
            return null;
        }
        if (!multiple && value instanceof File) {
            return (
                <SelectedFile
                    file={value}
                    metadata={fileMetadata}
                    multiple={false}
                />
            );
        }
        if (multiple && value instanceof Array) {
            return (
                <SelectedFileList
                    fileList={value}
                    metadata={fileMetadata}
                    onDismiss={handleDismiss}
                />
            );
        }
    }, [errorText, value, multiple, fileMetadata, handleDismiss]);

    return (
        <SpaceBetween size="xs">
            <FormField
                controlId={id}
                label={label}
                description={description}
                errorText={errorText}
                constraintText={constraintText}
            >
                <Button
                    iconName="upload"
                    formAction="none"
                    disabled={disabled}
                    onClick={handleButtonClick}
                >
                    <input
                        id={id}
                        ref={fileInput}
                        type="file"
                        data-testid="file-upload-input"
                        multiple={multiple}
                        disabled={disabled}
                        aria-label={ariaLabel}
                        aria-required={ariaRequired ? 'true' : 'false'}
                        accept={accept}
                        onChange={handleChange}
                        hidden
                    />
                    <span>{baseButtonText}</span>
                </Button>
            </FormField>
            {selectedFiles}
        </SpaceBetween>
    );
};

export default FileUpload;

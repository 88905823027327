import { FormField, Select } from '@amzn/awsui-components-react-v3';
import { ActivityLMSType, ActivityViltType } from '../../Common/Common';
import { ActivityData } from '../../../../interfaces/activity';
import { useEffect, useMemo, useState } from 'react';
import { checkFeature } from '../../../../../common/utils/featureFlag';
import { useSelector } from 'react-redux';
import {
    selectFeatures,
    selectIsLoaded as selectIsFeaturesLoaded,
} from '../../../../../common/store/slices/featureSlice';
import { SelectProps } from '@amzn/awsui-components-react-v3';

const OPTIONAL_DESCRIPTION =
    'This is a read only field. Selecting a LMS type will set this value.';

export type ViltTypeValues =
    | ActivityViltType.Necto
    | ActivityViltType.WebEx
    | ActivityViltType.Other;

interface ViltTypeOption extends SelectProps.Option {
    readonly label: ViltTypeValues;
    readonly value: string;
}

interface SelectVILTTypeProps {
    handleFieldEvent: (values: Partial<ActivityData>) => void;
    formValues: {
        lms_type: string;
        v_ilt_type?: string | null;
        classrooms_arn?: string | null;
        classrooms_status?: string | null;
    };
    errors: {
        lms_type: string;
        v_ilt_type: string;
        classrooms_arn?: string;
        classrooms_status?: string;
    };
    // TODO: Clean up feature flag: https://sim.amazon.com/issues/V1584942032
    canShowClassroomsComponents?: boolean;
}

const SelectVILTType = ({
    handleFieldEvent,
    formValues,
    errors,
    // TODO: Clean up feature flag: https://sim.amazon.com/issues/V1584942032
    canShowClassroomsComponents,
}: SelectVILTTypeProps) => {
    const featureFlags = useSelector(selectFeatures);
    const featuresLoaded = useSelector(selectIsFeaturesLoaded);

    const nectoForCustomerLmsFeatureFlag = useMemo(() => {
        return checkFeature(
            '',
            { featureName: 'necto-for-customer-lms' },
            featureFlags?.features,
        );
    }, [featuresLoaded, featureFlags]);

    const otherForCustomerLmsFeatureFlag = useMemo(() => {
        return checkFeature(
            '',
            { featureName: 'other-for-customer-lms' },
            featureFlags?.features,
        );
    }, [featuresLoaded, featureFlags]);

    const webexForMyClassFeatureFlag = useMemo(() => {
        return (
            featuresLoaded &&
            checkFeature(
                '',
                { featureName: 'webex-for-my-class' },
                featureFlags?.features,
            )
        );
    }, [featuresLoaded, featureFlags]);

    const disablevILTTypeEdit = (
        classrooms_arn: string,
        classrooms_status: string,
    ): boolean => {
        if (canShowClassroomsComponents) {
            return !!classrooms_arn && !!classrooms_status;
        }
        return false;
    };

    const getOptionsList = () => {
        let options: SelectProps.Option[] = [];
        const lmsType = formValues.lms_type;
        if (lmsType === ActivityLMSType.Kiku) {
            options = [
                {
                    value: ActivityViltType.WebEx,
                    label: ActivityViltType.WebEx,
                },
            ];
        }
        if (lmsType === ActivityLMSType.MyClass) {
            options = [
                {
                    value: ActivityViltType.Necto,
                    label: ActivityViltType.Necto,
                },
            ];
            if (webexForMyClassFeatureFlag) {
                options = [
                    ...options,
                    {
                        value: ActivityViltType.WebEx,
                        label: ActivityViltType.WebEx,
                    },
                ];
            }
        }
        if (lmsType === ActivityLMSType.CustomerLMS) {
            options = [
                {
                    value: ActivityViltType.WebEx,
                    label: ActivityViltType.WebEx,
                },
            ];
            if (nectoForCustomerLmsFeatureFlag) {
                options = [
                    ...options,
                    {
                        value: ActivityViltType.Necto,
                        label: ActivityViltType.Necto,
                    },
                ];
            }
            if (otherForCustomerLmsFeatureFlag) {
                options = [
                    ...options,
                    {
                        value: ActivityViltType.Other,
                        label: ActivityViltType.Other,
                    },
                ];
            }
        }
        return options;
    };

    const [viltTypeOptions, setViltOptions] = useState(getOptionsList());

    const viltTypeOptionsLookup = viltTypeOptions.reduce(
        (acc, opt) => {
            acc[opt.label] = opt;
            return acc;
        },
        {} as {
            [key in ViltTypeValues]: ViltTypeOption;
        },
    );

    useEffect(() => {
        setViltOptions(getOptionsList());
    }, [formValues.v_ilt_type, formValues.lms_type]);
    return (
        <FormField
            label="vILT System Type"
            errorText={errors?.v_ilt_type}
            description={viltTypeOptions.length < 2 ? OPTIONAL_DESCRIPTION : ''}
            data-testid="formField-EditVILTType"
        >
            <Select
                options={viltTypeOptions}
                placeholder="Select vILT System Type"
                selectedOption={
                    formValues.v_ilt_type
                        ? viltTypeOptionsLookup[
                              formValues.v_ilt_type as ViltTypeValues
                          ]
                        : null
                }
                onChange={(e) => {
                    handleFieldEvent({
                        v_ilt_type: e.detail.selectedOption.label,
                    });
                }}
                data-testid={`EditVILTType`}
                disabled={
                    viltTypeOptions.length < 2 ||
                    disablevILTTypeEdit(
                        formValues.classrooms_arn,
                        formValues.classrooms_status,
                    )
                }
            />
        </FormField>
    );
};

export default SelectVILTType;

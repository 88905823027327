export class ApiError extends Error {
    public statusCode: number;
    public url: string;
    public response: any;
    constructor(statusCode: number, url: string, m: any) {
        super(m);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiError.prototype);
        this.statusCode = statusCode;
        this.url = url;
        this.response = m;
    }
}

import { Roles, Actions } from '../../constants/auth';
import { check, getUserRoles } from '../../utils/auth';

const Can = ({
    no = () => null,
    perform,
    roles = getUserRoles(),
    yes = () => null,
}: {
    no?: Function;
    perform: Actions;
    roles?: Array<Roles>;
    yes?: Function;
}) => (check(roles, perform) ? yes() : no());

export default Can;

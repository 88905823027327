import { HandleRequestData } from '../interfaces/handleRequest';
import { ApiError } from '../classes/ApiError';
const handleRequest = async <T>(
    url: string,
    options: RequestInit,
): Promise<HandleRequestData<T>> => {
    let status = 0;
    let result = null;

    try {
        const response = await fetch(url, options);
        status = response.status;
        result = await response.json();
        // catch non-network errors like 403 custom errors from the APIs
        // noinspection ExceptionCaughtLocallyJS
        if (!response.ok) {
            throw new ApiError(response.status, url, result);
        }
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('ERROR: ', error);
        throw error;
    }

    return {
        status,
        result,
    };
};

export default handleRequest;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    initializeDeliveryCountriesListQueryParams,
    getDeliveryCountriesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllDeliveryCountries,
    selectError,
    updateDeliveryCountry,
    selectSelectedDeliveryCountry,
    setSelectedDeliveryCountry,
    addDeliveryCountry,
    selectCount,
} from '../../../common/store/slices/deliveryCountriesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { DeliveryCountryItemData } from '../../../common/interfaces/businessDataItem/deliveryCountryItem';
import { resetDeliveryLanguagesSlice } from '../../../common/store/slices/deliveryLanguagesSlice';

const DELIVERY_COUNTRIES_TABLE_TITLE = 'Delivery countries';
const DELIVERY_COUNTRIES_DISPLAY_SINGULAR = 'Delivery country';
export const DELIVERY_COUNTRIES_NAME_KEY = 'delivery_country';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (deliveryCountry: DeliveryCountryItemData) =>
            formatStatus(deliveryCountry.active.toString()),
    },
];

/**
 * Editable form fields for DeliveryCountry
 * The keys must map to DeliveryCountryItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<DeliveryCountryItemData> =
    {
        // the keys must match ProgramDataType properties
        delivery_country: {
            type: AdminBusinessDataFormInputType.Text,
            label: `${DELIVERY_COUNTRIES_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (
                val: string,
            ): Partial<DeliveryCountryItemData> => {
                return { delivery_country: val };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<DeliveryCountryItemData> => {
                return { active: parseBoolean(val) };
            },
        },
    };

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<DeliveryCountryItemData> =
    {
        ...createFormSchema,
        delivery_country: {
            ...createFormSchema.delivery_country,
            disabled: true,
        },
    };

const AdminDeliveryCountries = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useDeliveryCountries(): AdminBusinessDataSelectors<DeliveryCountryItemData> {
        const dispatch = useDispatch();

        const items: DeliveryCountryItemData[] = useSelector(
            selectAllDeliveryCountries,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: DeliveryCountryItemData | null = useSelector(
            selectSelectedDeliveryCountry,
        );
        useEffect(() => {
            dispatch(
                initializeDeliveryCountriesListQueryParams({
                    active: 'all',
                    size: 0,
                }),
            );
            dispatch(getDeliveryCountriesList());

            return () => {
                dispatch(resetDeliveryLanguagesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (
        deliveryCountry: DeliveryCountryItemData,
    ) => {
        if (deliveryCountry) {
            dispatch(
                setSelectedDeliveryCountry(deliveryCountry.delivery_country),
            );
            await dispatch(getDeliveryCountriesList());
        }
    };

    const handleItemUpdate = async (data: DeliveryCountryItemData) => {
        dispatch(setSelectedDeliveryCountry(null));
        const dispatchPromise = dispatch<any>(updateDeliveryCountry(data));
        await handleBusinessDataNotification({
            businessDataType: DELIVERY_COUNTRIES_NAME_KEY,
            businessDataDisplayNameLower:
                DELIVERY_COUNTRIES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.delivery_country,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        delivery_country,
        active,
    }: DeliveryCountryItemData) => {
        const dispatchPromise = dispatch<any>(
            addDeliveryCountry(active, delivery_country),
        );
        await handleBusinessDataNotification({
            businessDataType: DELIVERY_COUNTRIES_NAME_KEY,
            businessDataDisplayNameLower:
                DELIVERY_COUNTRIES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: delivery_country,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementDeliveryCountries">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={DELIVERY_COUNTRIES_DISPLAY_SINGULAR}
                itemNameKey={DELIVERY_COUNTRIES_NAME_KEY}
                title={DELIVERY_COUNTRIES_TABLE_TITLE}
                useItemList={useDeliveryCountries}
            />
        </div>
    );
};

export default AdminDeliveryCountries;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Button,
    ColumnLayout,
    Modal,
    Table,
} from '@amzn/awsui-components-react';

import {
    formatAddress,
    formatInstructorStatus,
    getInternalInstructorContent,
    getIsExternalInstructor,
    InstructorTypeValues,
    formatCourseStatus,
    formatCertificationExpiration,
    formatLeadBoolean,
    RAMPING_STATUS_VALUE,
} from '../../Common/Common';
import { selectSelectedInstructor } from '../../../../store/slices/selectedInstructorSlice';
import formatDate from '../../../../../common/utils/formatDate';
import Section, {
    SectionProps,
    ColumnContentData,
} from '../../../../../common/components/Section/Section';
import {
    EMPTY_STRING,
    SectionContentType,
} from '../../../../../common/constants/grimsby';
import { Actions } from '../../../../../common/constants/auth';
import Can from '../../../../../common/components/Can';
import { getUserRoles } from '../../../../../common/utils/auth';
import TableHeader from '../../../../../common/components/TableHeader/TableHeader';
import ExternalInstructorDetails from '../ExternalInstructorDetails/ExternalInstructorDetails';
import { formatStringArray } from '../../../../../common/utils/formatStringArray';
import { formatString } from '../../../../../common/utils/formatString';
import { formatBoolean } from '../../../../../common/utils/formatBoolean';
import { formatYesNoBoolean } from '../../../../../imt/components/Instructor/Common/Common';
import { formatSpeakerCertificationOptions } from '../../../../../common/utils/formatSpeakerCertificationOptions';

export interface CourseItem {
    name: string;
    status: string;
    certifications: string[];
    certificationExpirations: number[];
    regionalLead?: boolean | null;
    geoLead?: boolean | null;
    coTeach?: number | null;
    shadow?: number | null;
}

export interface CertificationItem {
    certification: string;
    certificationExpiration: string | JSX.Element;
}
export interface ProviderItem {
    providerName: string;
    awsClassroom: string;
    companyContact: string | null;
    isSponsoringCompany: boolean;
}

export const certificationsColumnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'AWSCertification',
        header: 'AWS certification',
        cell: (item: CertificationItem) => formatString(item.certification),
    },
    {
        id: 'certificationExpiration',
        header: 'Certification expiration',
        cell: (item: CertificationItem) => item.certificationExpiration,
    },
];
const speakerCertifiedList = [
    'Associate Speaker Certification',
    'Senior Speaker Certification',
    'Principal Speaker Certification',
];
const DetailsTab = () => {
    const history = useHistory();
    const instructor = useSelector(selectSelectedInstructor);
    const [selectedCourse, setSelectedCourse] = useState<CourseItem>();
    const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false);

    const coursesColumnDefinitions: Array<Table.ColumnDefinition> = [
        {
            id: 'courseName',
            header: 'Course name',
            cell: (item: CourseItem) => formatString(item.name),
        },
        {
            id: 'courseStatus',
            header: 'Course status',
            cell: (item: CourseItem) => formatCourseStatus(item.status),
        },
        {
            id: 'AWSCertification',
            header: 'AWS certification',
            cell: (item: CourseItem) =>
                item.certifications.map((certification) => (
                    <div className="awsui-row awsui-util-p-xs certification-cell--name">
                        {formatString(certification)}
                    </div>
                )),
        },
        {
            id: 'certificationExpiration',
            header: 'Certification expiration',
            cell: (item: CourseItem) =>
                item.certificationExpirations.map((certificationExpiration) => (
                    <div className="awsui-row awsui-util-p-xs">
                        {formatCertificationExpiration(certificationExpiration)}
                    </div>
                )),
        },
        {
            id: 'moreInfo',
            header: null,
            cell: (item: CourseItem) => {
                const handleMoreInfoButtonClick = () => {
                    setSelectedCourse(item);
                    setIsMoreInfoModalOpen(true);
                };
                return (
                    <Button
                        variant="link"
                        data-testid={`DetailsTabMoreInfoButton${item.name}`}
                        onClick={handleMoreInfoButtonClick}
                    >
                        More Info
                    </Button>
                );
            },
        },
    ];

    if (instructor) {
        const profile = instructor.instructor;

        const instructorDetailsProps: SectionProps = {
            testId: 'InstructorDetailsSection',
            header: {
                label: 'Basic information',
                buttons: (
                    <div className="awsui-util-action-stripe-group">
                        <Can
                            roles={getUserRoles()}
                            perform={Actions.INSTRUCTOR_MODIFY}
                            yes={() => (
                                <div className="awsui-util-action-stripe-group">
                                    <Button
                                        data-testid="BasicInfoSectionEditButton"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/instructors/${instructor.instructor.pk}/edit-basic-info`,
                                            });
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            )}
                            no={() => null}
                        />
                    </div>
                ),
            },
            content: {
                type: SectionContentType.Column,
                columnsCount: 4 as ColumnLayout.Columns,
                columns: [
                    [
                        {
                            key: 'Primary Email address',
                            value: formatString(profile.email),
                        },
                        {
                            key: 'City, State',
                            value: formatStringArray([
                                profile.city,
                                profile.state_province &&
                                profile.state_province.toLowerCase() !==
                                    'unknown'
                                    ? profile.state_province
                                    : '',
                            ]),
                        },
                        {
                            key: 'Instructor status',
                            value: formatInstructorStatus(
                                profile.instructor_status,
                            ),
                        },
                        profile.suspension_reason
                            ? {
                                  key: 'Suspension reason',
                                  value: profile.suspension_reason,
                              }
                            : null,
                        {
                            key: 'Final approval date',
                            value: profile.final_approval_date
                                ? formatString(
                                      formatDate(profile.final_approval_date),
                                  )
                                : EMPTY_STRING,
                        },
                    ],
                    [
                        {
                            key: 'Phone number',
                            value: formatString(profile.phone_number),
                        },
                        {
                            key: 'Country',
                            value: formatString(profile.country),
                        },
                        {
                            key: 'Programs',
                            value: formatStringArray(profile.programs),
                        },
                        {
                            key: 'Timezone',
                            value: formatString(profile.city_timezone),
                        },
                    ],
                    [
                        {
                            key: 'LMS email address',
                            value: formatString(profile.aws_lms_email),
                        },
                        {
                            key: 'Region',
                            value: formatString(profile.instructor_region),
                        },
                        {
                            key: 'Instructor type',
                            value: formatString(profile.instructor_type),
                        },
                        {
                            key: 'Willing to travel',
                            value: formatYesNoBoolean(profile.prefer_travel),
                        },
                    ],
                    [
                        {
                            key: 'Address',
                            value: formatAddress(
                                profile.address_line_1,
                                profile.address_line_2,
                                profile.city,
                                profile?.state_province,
                                profile.postal_code,
                            ),
                        },
                        {
                            key: 'Geo',
                            value: formatString(profile.geo),
                        },
                        {
                            key: 'Onboarding date',
                            value:
                                profile.onboarding_date !== null
                                    ? formatString(
                                          formatDate(profile.onboarding_date),
                                      )
                                    : EMPTY_STRING,
                        },
                        {
                            key: 'Preferred airport code',
                            value: formatString(profile.preferred_airport_code),
                        },
                    ],
                ],
            } as ColumnContentData,
        };

        const internalInstructorDetailsProps: SectionProps = {
            testId: 'InternalInstructorDetailsSection',
            header: {
                label: 'Internal instructor details',
                buttons: (
                    <Can
                        roles={getUserRoles()}
                        perform={Actions.INSTRUCTOR_MODIFY}
                        yes={() => (
                            <div className="awsui-util-action-stripe-group">
                                <Button
                                    data-testid="InternalInstructorDetailsSectionEditButton"
                                    onClick={() => {
                                        history.push({
                                            pathname: `/instructors/${instructor.instructor.pk}/edit-details`,
                                        });
                                    }}
                                >
                                    Edit
                                </Button>
                            </div>
                        )}
                        no={() => null}
                    />
                ),
            },
            content: getInternalInstructorContent(profile),
        };

        const skillsProps: SectionProps = {
            testId: 'SkillsSection',
            className: 'awsui-util-mb-n',
            header: {
                label: 'Skills',
                buttons: (
                    <Can
                        roles={getUserRoles()}
                        perform={Actions.INSTRUCTOR_MODIFY}
                        yes={() => (
                            <div className="awsui-util-action-stripe-group">
                                <Button
                                    data-testid="SkillsSectionEditButton"
                                    onClick={() => {
                                        history.push({
                                            pathname: `/instructors/${instructor.instructor.pk}/edit-skills-and-courses`,
                                        });
                                    }}
                                >
                                    Edit
                                </Button>
                            </div>
                        )}
                        no={() => null}
                    />
                ),
            },
            content: {
                type: SectionContentType.Column,
                columnsCount: 4 as ColumnLayout.Columns,
                columns: [
                    [
                        {
                            key: 'vILT',
                            value: formatBoolean(profile.vilt, 'vILT approved'),
                        },
                        {
                            key: 'Countries authorized to teach in',
                            value: formatStringArray(
                                profile.delivery_countries,
                            ),
                        },
                    ],
                    [
                        {
                            key: 'PR certified',
                            value: formatBoolean(
                                profile.pr_certified,
                                'PR certified',
                            ),
                        },
                        {
                            key: 'Speaker Certification',
                            value: formatSpeakerCertificationOptions(
                                speakerCertifiedList.includes(
                                    profile.speaker_certified,
                                ),
                                profile.speaker_certified,
                            ),
                        },
                    ],
                    [
                        {
                            key: 'Champion',
                            value: formatBoolean(profile.champion, 'Champion'),
                        },
                        {
                            key: 'Domain skills',
                            value: formatStringArray(profile.domain_skills),
                        },
                    ],
                    [
                        {
                            key: 'Mentor',
                            value: formatBoolean(profile.mentor, 'Mentor'),
                        },
                        {
                            key: 'Languages spoken',
                            value: formatStringArray(
                                profile.delivery_languages,
                            ),
                        },
                    ],
                ],
            } as ColumnContentData,
        };

        const getTableHeaderComponent = (titleStr: string) => (
            <TableHeader title={titleStr} actions={<React.Fragment />} />
        );

        const emptyTableComponent = (
            <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                    <b>No courses</b>
                </div>
                <p className="awsui-util-mb-s">Courses will display here.</p>
                <Can
                    roles={getUserRoles()}
                    perform={Actions.INSTRUCTOR_MODIFY}
                    yes={() => (
                        <div className="awsui-util-mb-m">
                            <Button
                                data-testid="SkillsSectionEmptyTableEditButton"
                                onClick={() => {
                                    history.push({
                                        pathname: `/instructors/${instructor.instructor.pk}/edit-skills-and-courses`,
                                    });
                                }}
                            >
                                Add a course
                            </Button>
                        </div>
                    )}
                    no={() => null}
                />
            </div>
        );

        const emptyCertificationsTableComponent = (
            <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                    <b>No certifications</b>
                </div>
                <p className="awsui-util-mb-s">
                    Certifications will display here.
                </p>
            </div>
        );

        const generateCourseItems = () => {
            const coursesMapping =
                instructor.instructor.course_certification_mapping;

            return Object.keys(coursesMapping).map((key) => {
                const value = coursesMapping[key];

                return {
                    name: key,
                    status: value.course_status,
                    certifications: value.certifications.map(
                        (cert) => cert.certification,
                    ),
                    certificationExpirations: value.certifications.map(
                        (certification) =>
                            certification.certification_expiration,
                    ),
                    regionalLead: value.regional_lead,
                    geoLead: value.geo_lead,
                    coTeach: value.co_teach,
                    shadow: value.shadow,
                } as CourseItem;
            });
        };

        const generateCertificationsItems = () => {
            const certifications = instructor.instructor.certifications;

            return (
                certifications?.map((certification) => {
                    return {
                        certification: certification.name,
                        certificationExpiration: formatCertificationExpiration(
                            certification.expiration_date,
                        ),
                    };
                }) ?? []
            );
        };

        return (
            <>
                <div data-testid="InstructorDetailsPageDetailsTab">
                    <Section {...instructorDetailsProps} />

                    {getIsExternalInstructor(
                        profile.instructor_type as InstructorTypeValues,
                    ) ? (
                        <ExternalInstructorDetails
                            profile={profile}
                            showEditButton={true}
                        />
                    ) : (
                        <Section {...internalInstructorDetailsProps} />
                    )}

                    <Section {...skillsProps} />
                    <Table
                        data-testid="SkillsSectionCourseTable"
                        columnDefinitions={coursesColumnDefinitions}
                        items={generateCourseItems()}
                        header={getTableHeaderComponent('Courses')}
                        empty={emptyTableComponent}
                    />

                    <section className="awsui-util-mt-l">
                        <Table
                            data-testid="CertificationsTable"
                            columnDefinitions={certificationsColumnDefinitions}
                            items={generateCertificationsItems()}
                            header={getTableHeaderComponent('Certifications')}
                            empty={emptyCertificationsTableComponent}
                        />
                    </section>
                </div>
                <Modal
                    visible={isMoreInfoModalOpen}
                    header={selectedCourse?.name}
                >
                    <ColumnLayout columns={2}>
                        {selectedCourse && (
                            <div
                                data-awsui-column-layout-root="true"
                                data-testid="DetailsTab-MoreInfoModal"
                            >
                                {selectedCourse?.status ===
                                    RAMPING_STATUS_VALUE && (
                                    <>
                                        <div data-testid="DetailsTab-MoreInfoModalShadow">
                                            <div className="awsui-util-label">
                                                Shadow date
                                            </div>
                                            <div>
                                                {formatString(
                                                    selectedCourse?.shadow
                                                        ? formatDate(
                                                              selectedCourse.shadow *
                                                                  1000,
                                                          )
                                                        : '',
                                                )}
                                            </div>
                                        </div>
                                        <div data-testid="DetailsTab-MoreInfoModalCoTeach">
                                            <div className="awsui-util-label">
                                                Co-teach date
                                            </div>
                                            <div>
                                                {formatString(
                                                    selectedCourse?.coTeach
                                                        ? formatDate(
                                                              selectedCourse.coTeach *
                                                                  1000,
                                                          )
                                                        : '',
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div data-testid="DetailsTab-MoreInfoModalGlobal">
                                    <div className="awsui-util-label">
                                        Global lead
                                    </div>
                                    <div>
                                        {formatLeadBoolean(
                                            selectedCourse?.geoLead,
                                        )}
                                    </div>
                                </div>
                                <div data-testid="DetailsTab-MoreInfoModalRegional">
                                    <div className="awsui-util-label">
                                        Regional lead
                                    </div>
                                    <div>
                                        {formatLeadBoolean(
                                            selectedCourse?.regionalLead,
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </ColumnLayout>
                </Modal>
            </>
        );
    }

    // The "instructor" must not be empty if its parent component is successfully loaded.
    // Having this logic is to comply with the definition of "instructor" from the global
    // state management, which can be "null".
    else {
        return null;
    }
};

export default DetailsTab;

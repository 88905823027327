import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateActivityStatusPayloadData } from '../../interfaces/adminCreateBusinessDataPayload/activityStatusPayload';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { ActivityStatusesResponseData } from '../../interfaces/businessDataResponse/activityStatusesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const ACTIVITY_STATUSES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/activity-status`;

/**
 * Method to request the full list of activity statuses
 * @param {BusinessDataApiQueryParams.GetActivityStatuses} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getActivityStatuses = (
    queryParams?: BusinessDataApiQueryParams.GetActivityStatuses,
): Promise<HandleRequestData<ActivityStatusesResponseData>> => {
    let path = `${ACTIVITY_STATUSES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<ActivityStatusesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an activity status.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateActivityStatus = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${ACTIVITY_STATUSES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

/**
 * Method to add an activity status.
 * @param {string} activityStatusName - the name of the new activity status
 * @param {boolean} active - the status of the new activity status
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addActivityStatus = (
    activityStatusName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateActivityStatusPayloadData = {
        activity_status: activityStatusName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${ACTIVITY_STATUSES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const activityStatusesApi = {
    getActivityStatuses,
    updateActivityStatus,
    addActivityStatus,
};

export default activityStatusesApi;

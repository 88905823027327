import React, { createContext, useState } from 'react';

interface AuthenticationProps {
    signedIn: boolean;
    setSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
    isOnboarded: boolean;
    setIsOnboarded: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthenticationContext = createContext<AuthenticationProps | undefined>(
    undefined,
);

export const AuthenticationProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const [signedIn, setSignedIn] = useState(false);
    const [isOnboarded, setIsOnboarded] = useState(true);

    return (
        <AuthenticationContext.Provider
            value={{ signedIn, setSignedIn, isOnboarded, setIsOnboarded }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationContext;

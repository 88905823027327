import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateCoursePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/coursePayload';
import { AdminUpdateCoursePayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { CoursesResponseData } from '../../interfaces/businessDataResponse/coursesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';
import { CourseResponseData } from '../../interfaces/businessDataResponse/courseResponse';
import { CourseCreateResponseData } from '../../interfaces/businessDataResponse/courseCreateResponse';

export const COURSES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/courses`;

/**
 * Method to request the full list of courses
 * @param {BusinessDataApiQueryParams.GetCourses} queryParams - query params
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getCourses = (
    queryParams?: BusinessDataApiQueryParams.GetCourses,
): Promise<HandleRequestData<CoursesResponseData>> => {
    const path = `${COURSES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<CoursesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to request a grimsby course
 * @param {string} id - A selected course pk
 * @return: A promise that resolves with an object containing the result or error of the course request
 */
const getCourseById = (
    id: string,
): Promise<HandleRequestData<CourseResponseData>> => {
    return handleGrimsbyApiRequest<CourseResponseData>(
        `${COURSES_PATH}/${id}`,
        {
            method: 'GET',
        },
    );
};

/**
 * Method to update a course.
 * @param {AdminUpdateCoursePayloadData} updatedCourse - the payload with the updated course data
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateCourse = (
    updatedCourse: AdminUpdateCoursePayloadData,
): Promise<HandleRequestData<ResponseData>> => {
    return handleGrimsbyApiRequest<ResponseData>(COURSES_PATH, {
        method: 'POST',
        body: JSON.stringify(updatedCourse),
    });
};

/**
 * Method to add a course.
 * @param {AdminCreateCoursePayloadData} newCourse - the new course data
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addCourse = (
    newCourse: AdminCreateCoursePayloadData,
): Promise<HandleRequestData<CourseCreateResponseData>> => {
    return handleGrimsbyApiRequest<CourseCreateResponseData>(COURSES_PATH, {
        method: 'POST',
        body: JSON.stringify(newCourse),
    });
};

export const coursesApi = {
    getCourses,
    getCourseById,
    updateCourse,
    addCourse,
};

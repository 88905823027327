import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { environment } from './amplify';

class CloudWatchRUM {
    rumClient: any;

    constructor() {
        this.init();
        this.rumClient = null;
    }

    async init() {
        const { RUM_APP_ID, RUM_GUEST_ROLE_ARN, RUM_IDENTITY_POOL } =
            await environment();

        const rumConfig: AwsRumConfig = {
            endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
            guestRoleArn:
                process.env.REACT_APP_RUM_GUEST_ROLE_ARN ?? RUM_GUEST_ROLE_ARN,
            identityPoolId:
                process.env.REACT_APP_RUM_IDENTITY_POOL ?? RUM_IDENTITY_POOL,
            allowCookies: true,
            telemetries: ['errors', 'performance', 'http'],
            enableXRay: true,
            disableAutoPageView: true,
        };
        const APPLICATION_ID = process.env.REACT_APP_RUM_APP_ID ?? RUM_APP_ID;
        const APPLICATION_VERSION = '1.0.0';
        const APPLICATION_REGION = 'us-east-1';

        if (!!APPLICATION_ID && APPLICATION_ID !== 'NONE') {
            this.rumClient = new AwsRum(
                APPLICATION_ID,
                APPLICATION_VERSION,
                APPLICATION_REGION,
                rumConfig,
            );
        }
    }

    recordError(error: Error | ErrorEvent | String) {
        if (this.rumClient) {
            this.rumClient.recordError(error);
        }
    }

    recordPageView(path: any) {
        if (this.rumClient) {
            this.rumClient.recordPageView(path);
        }
    }
}

const globalRumClient = new CloudWatchRUM();

export default globalRumClient;

import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';

export enum ActivityGroupPropertyFilterKeys {
    Status = 'statuses',
    ProgramName = 'program_names',
    StartsBefore = 'starts_before',
    StartsAfter = 'starts_after',
    EndsBefore = 'ends_before',
    EndsAfter = 'ends_after',
}

const ACTIVITY_GROUP_STATUSES = {
    Hold: 'Hold',
    Tentative: 'Tentative',
    Active: 'Active',
    Completed: 'Completed',
    Canceled: 'Canceled',
} as const;

const ACTIVITY_GROUP_PROGRAMS = {
    'ACI Onboarding': 'ACI Onboarding',
    'AWS Cloud Institute': 'AWS Cloud Institute',
} as const;

export const FILTERING_OPTIONS: PropertyFilterProps.FilteringOption[] = [
    ...Object.keys(ACTIVITY_GROUP_STATUSES).map((status) => ({
        propertyKey: ActivityGroupPropertyFilterKeys.Status,
        value: status,
    })),
    ...Object.keys(ACTIVITY_GROUP_PROGRAMS).map((program) => ({
        propertyKey: ActivityGroupPropertyFilterKeys.ProgramName,
        value: program,
    })),
];

export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
    {
        key: ActivityGroupPropertyFilterKeys.Status,
        operators: ['='],
        propertyLabel: 'Status',
        groupValuesLabel: 'Status values',
    },
    {
        key: ActivityGroupPropertyFilterKeys.ProgramName,
        operators: ['='],
        propertyLabel: 'Program Name',
        groupValuesLabel: 'Program names',
    },
];

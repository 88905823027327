import React, { Dispatch, SetStateAction } from 'react';
import {
    Button,
    ColumnLayout,
    FormField,
    Input,
    Modal,
    Select,
    SelectProps,
    Textarea,
    Toggle,
    SpaceBetween,
    Box,
} from '@amzn/awsui-components-react-v3';
import { MilestoneItemData } from '../../../../common/interfaces/businessDataItem/milestoneItem';
import {
    CertificationFormFieldText,
    MilestoneTypeFormFieldText,
} from '../../../../common/constants/forms';

export interface AdminCourseMilestoneModalFormProps<MilestoneItemData> {
    initialFormState: MilestoneItemData;
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    updateMilestones: (milestone: MilestoneItemData) => Promise<void>;
    milestoneTypesOptions: SelectProps.Option[];
    selectedMilestoneType: SelectProps.Option | null;
    setSelectedMilestoneType: Dispatch<SetStateAction<SelectProps.Option>>;
    isMilestoneTypesLoading: boolean;
    isMilestoneTypesLoaded: boolean;
    milestoneTypesError: boolean;
    certificationOptions: SelectProps.Option[];
    selectedCertification: SelectProps.Option | null;
    setSelectedCertification: Dispatch<SetStateAction<SelectProps.Option>>;
    certificationLookup: { [key: string]: SelectProps.Option };
    isCertificationListLoading: boolean;
    isCertificationListLoaded: boolean;
    certificationListError: boolean;
    creatingFlag?: boolean;
    updatingFlag?: boolean;
    milestone: MilestoneItemData;
    setMilestone: Dispatch<SetStateAction<MilestoneItemData>>;
    milestoneErrors: { [key in keyof Partial<MilestoneItemData>]: string };
    validateMilestoneForm: Function;
}

export const AdminCourseMilestoneModalForm = ({
    initialFormState,
    isModalOpen,
    milestoneTypesOptions,
    selectedMilestoneType,
    creatingFlag,
    updateMilestones,
    setIsModalOpen,
    setSelectedMilestoneType,
    certificationOptions,
    selectedCertification,
    setSelectedCertification,
    isCertificationListLoading,
    isCertificationListLoaded,
    milestone,
    setMilestone,
    milestoneErrors,
    validateMilestoneForm,
}: AdminCourseMilestoneModalFormProps<MilestoneItemData>) => {
    const header_title = (creatingFlag ? 'Creating' : 'Editing') + ' milestone';

    return (
        <Modal
            visible={isModalOpen}
            header={header_title}
            size="medium"
            onDismiss={() => {
                setMilestone(initialFormState);
                setIsModalOpen(false);
            }}
            footer={
                <Box float="right">
                    <SpaceBetween size="m" direction="horizontal">
                        <Button
                            variant="normal"
                            data-testid="AdminCourseMilestoneModalFormCancelButton"
                            onClick={() => {
                                setMilestone(initialFormState);
                                setIsModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid="AdminCourseMilestoneModalFormSaveButton"
                            onClick={() => {
                                const invalid = validateMilestoneForm();
                                if (!invalid) {
                                    updateMilestones(milestone);
                                    setIsModalOpen(false);
                                }
                            }}
                        >
                            Save milestone
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <ColumnLayout>
                <SpaceBetween size={'l'}>
                    <div>
                        <FormField
                            label="Milestone Type"
                            test-id="CourseMilestoneTypeField"
                            errorText={milestoneErrors?.milestone_type}
                        >
                            <Select
                                options={milestoneTypesOptions}
                                selectedOption={selectedMilestoneType}
                                onChange={(item) => {
                                    setSelectedMilestoneType(
                                        item.detail.selectedOption,
                                    );
                                    setMilestone((milestone) => {
                                        return {
                                            ...milestone,
                                            milestone_type:
                                                item.detail.selectedOption
                                                    .label,
                                        };
                                    });
                                }}
                                placeholder={
                                    isCertificationListLoading &&
                                    !isCertificationListLoaded
                                        ? MilestoneTypeFormFieldText.Loading
                                        : MilestoneTypeFormFieldText.PlaceholderSingular
                                }
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            label="Milestone Requirements"
                            data-testid="CourseMilestoneRequirementsField"
                            errorText={milestoneErrors?.milestone_requirement}
                        >
                            <Textarea
                                placeholder="Define the milestone requirements..."
                                data-testid="CourseMilestoneRequirementsTextArea"
                                onChange={({ detail }) =>
                                    setMilestone((milestone) => {
                                        return {
                                            ...milestone,
                                            milestone_requirement: detail.value,
                                        };
                                    })
                                }
                                value={milestone.milestone_requirement}
                            />
                        </FormField>
                    </div>
                    <div
                        hidden={
                            !selectedMilestoneType ||
                            selectedMilestoneType.label !== 'Certification'
                        }
                    >
                        <FormField
                            label="Certification"
                            errorText={milestoneErrors?.milestone_certification}
                        >
                            <Select
                                options={certificationOptions}
                                selectedOption={selectedCertification}
                                onChange={(cert) => {
                                    setMilestone(() => {
                                        return {
                                            ...milestone,
                                            milestone_certification:
                                                cert.detail.selectedOption
                                                    .label,
                                        };
                                    });
                                    setSelectedCertification(
                                        cert.detail.selectedOption,
                                    );
                                }}
                                placeholder={
                                    isCertificationListLoading &&
                                    !isCertificationListLoaded
                                        ? CertificationFormFieldText.Loading
                                        : CertificationFormFieldText.PlaceholderSingular
                                }
                            />
                        </FormField>
                    </div>
                    <div
                        hidden={
                            !selectedMilestoneType ||
                            selectedMilestoneType.label !== 'Digital'
                        }
                    >
                        <FormField
                            label="Digital course name"
                            errorText={
                                milestoneErrors?.milestone_digital_course
                            }
                        >
                            <Input
                                onChange={({ detail }) =>
                                    setMilestone((milestone) => {
                                        return {
                                            ...milestone,
                                            milestone_digital_course:
                                                detail.value,
                                        };
                                    })
                                }
                                value={milestone.milestone_digital_course}
                            />
                        </FormField>
                    </div>
                    <div
                        hidden={
                            !selectedMilestoneType ||
                            selectedMilestoneType.label !== 'Digital'
                        }
                    >
                        <FormField
                            label="Digital course link"
                            errorText={
                                milestoneErrors?.milestone_digital_course_link
                            }
                        >
                            <Input
                                onChange={({ detail }) =>
                                    setMilestone((milestone) => {
                                        return {
                                            ...milestone,
                                            milestone_digital_course_link:
                                                detail.value,
                                        };
                                    })
                                }
                                value={milestone.milestone_digital_course_link}
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField>
                            <Toggle
                                onChange={({ detail }) =>
                                    setMilestone((milestone) => {
                                        return {
                                            ...milestone,
                                            active: detail.checked,
                                        };
                                    })
                                }
                                checked={milestone.active}
                            >
                                Active
                            </Toggle>
                        </FormField>
                    </div>
                </SpaceBetween>
            </ColumnLayout>
        </Modal>
    );
};

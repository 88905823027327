import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getCourseDomainList,
    selectIsLoading,
    selectIsLoaded,
    selectAllCourseDomains,
    selectError,
    updateCourseDomain,
    selectSelectedCourseDomain,
    setSelectedCourseDomain,
    addCourseDomain,
    selectCount,
    resetCourseDomainsSlice,
} from '../../../common/store/slices/courseDomainsSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { CourseDomainItemData } from '../../../common/interfaces/businessDataItem/courseDomainItem';

const COURSE_DOMAINS_TABLE_TITLE = 'Course domains';
const COURSE_DOMAINS_DISPLAY_SINGULAR = 'Course domain';
export const COURSE_DOMAINS_NAME_KEY = 'course_domain';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (courseDomain: CourseDomainItemData) =>
            formatStatus(courseDomain.active.toString()),
    },
];

/**
 * Editable form fields for CourseDomain
 * The keys must map to CourseDomainItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<CourseDomainItemData> =
    {
        course_domain: {
            type: AdminBusinessDataFormInputType.Text,
            label: `${COURSE_DOMAINS_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (val: string): Partial<CourseDomainItemData> => {
                return { course_domain: val };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<CourseDomainItemData> => {
                return { active: parseBoolean(val) };
            },
        },
    };

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<CourseDomainItemData> =
    {
        ...createFormSchema,
        course_domain: {
            ...createFormSchema.course_domain,
            disabled: true,
        },
    };

const AdminCourseDomains = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useCourseDomains(): AdminBusinessDataSelectors<CourseDomainItemData> {
        const dispatch = useDispatch();

        const items: CourseDomainItemData[] = useSelector(
            selectAllCourseDomains,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: CourseDomainItemData | null = useSelector(
            selectSelectedCourseDomain,
        );
        useEffect(() => {
            dispatch(getCourseDomainList());
            dispatch(setSelectedCourseDomain(null));

            return () => {
                dispatch(resetCourseDomainsSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (courseDomain: CourseDomainItemData) => {
        if (courseDomain) {
            dispatch(setSelectedCourseDomain(courseDomain.course_domain));
            await dispatch(getCourseDomainList());
        }
    };

    const handleItemUpdate = async (data: CourseDomainItemData) => {
        dispatch(setSelectedCourseDomain(null));
        const dispatchPromise = dispatch<any>(updateCourseDomain(data));
        await handleBusinessDataNotification({
            businessDataType: COURSE_DOMAINS_NAME_KEY,
            businessDataDisplayNameLower:
                COURSE_DOMAINS_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.course_domain,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        course_domain,
        active,
    }: CourseDomainItemData) => {
        const dispatchPromise = dispatch<any>(
            addCourseDomain(active, course_domain),
        );
        await handleBusinessDataNotification({
            businessDataType: COURSE_DOMAINS_NAME_KEY,
            businessDataDisplayNameLower:
                COURSE_DOMAINS_DISPLAY_SINGULAR.toLowerCase(),
            itemName: course_domain,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementCourseDomains">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={COURSE_DOMAINS_DISPLAY_SINGULAR}
                itemNameKey={COURSE_DOMAINS_NAME_KEY}
                title={COURSE_DOMAINS_TABLE_TITLE}
                useItemList={useCourseDomains}
            />
        </div>
    );
};

export default AdminCourseDomains;

import { Select } from '@amzn/awsui-components-react';
import { SelectProps } from '@amzn/awsui-components-react-v3';

export enum MeridiemFieldValue {
    Am = 'AM',
    Pm = 'PM',
}

export interface MeridiemFieldOption extends Select.Option {
    readonly label: MeridiemFieldValue;
    readonly id: string;
}

export const meridiemFieldOptions: Array<MeridiemFieldOption> = [
    {
        label: MeridiemFieldValue.Am,
        id: '0',
    },
    {
        label: MeridiemFieldValue.Pm,
        id: '1',
    },
];

export const meridiemFieldOptionLookup = meridiemFieldOptions.reduce(
    (acc, opt) => {
        acc[opt.label] = opt;
        return acc;
    },
    {} as {
        [key in MeridiemFieldValue]: MeridiemFieldOption;
    },
);

export interface MeridiemFieldOptionV3 extends SelectProps.Option {
    readonly label: MeridiemFieldValue;
    readonly value: string;
}

export const meridiemFieldOptionsV3: Array<MeridiemFieldOptionV3> = [
    {
        label: MeridiemFieldValue.Am,
        value: '0',
    },
    {
        label: MeridiemFieldValue.Pm,
        value: '1',
    },
];

export const meridiemFieldOptionLookupV3 = meridiemFieldOptionsV3.reduce(
    (acc, opt) => {
        acc[opt.label] = opt;
        return acc;
    },
    {} as {
        [key in MeridiemFieldValue]: MeridiemFieldOptionV3;
    },
);

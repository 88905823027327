import {
    FormField,
    Input,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { BasicActivityFormSectionProps } from '../../../Common/Common';
import { FORM_ERROR_SELECTOR } from '../../../../../../imt/components/Instructor/FormSections/FormSections.common';

const DeliveryAddressSection = ({
    formValues,
    errors,
    handleFieldEvent,
}: BasicActivityFormSectionProps) => {
    return (
        <SpaceBetween direction="vertical" size="l">
            <div className="grimsby-sub-section-divider" />
            <div className="grimsby-sub-section-header">Delivery Address</div>
            <p>
                To save an activity as active, enter a delivery address and
                postal code.
            </p>
            <FormField
                label={<span>Delivery address 1</span>}
                errorText={errors?.delivery_address_1}
                data-testid="formField-DeliveryAddress1"
            >
                <Input
                    className={
                        errors?.delivery_address_1 && FORM_ERROR_SELECTOR
                    }
                    value={formValues.delivery_address_1}
                    onChange={(e) =>
                        handleFieldEvent({
                            delivery_address_1: e.detail.value,
                        })
                    }
                    data-testid={`DeliveryAddress1`}
                />
            </FormField>
            <FormField
                label={
                    <span>
                        Delivery address 2 <i>- optional</i>
                    </span>
                }
                errorText={errors?.delivery_address_2}
                data-testid="formField-DeliveryAddress2"
            >
                <Input
                    className={
                        errors?.delivery_address_2 && FORM_ERROR_SELECTOR
                    }
                    value={formValues.delivery_address_2}
                    onChange={(e) =>
                        handleFieldEvent({
                            delivery_address_2: e.detail.value,
                        })
                    }
                    data-testid={`DeliveryAddress2`}
                />
            </FormField>
            <FormField
                label={<span>Delivery postal code</span>}
                errorText={errors?.delivery_postal_code}
                data-testid="formField-DeliveryPostalCode"
            >
                <Input
                    className={
                        errors?.delivery_postal_code && FORM_ERROR_SELECTOR
                    }
                    value={formValues.delivery_postal_code}
                    onChange={(e) =>
                        handleFieldEvent({
                            delivery_postal_code: e.detail.value,
                        })
                    }
                    data-testid={`DeliveryPostalCode`}
                />
            </FormField>
            <FormField
                label={
                    <span>
                        Room <i>- optional</i>
                    </span>
                }
                errorText={errors?.room}
            >
                <Input
                    className={errors?.room && FORM_ERROR_SELECTOR}
                    value={formValues.room}
                    onChange={(e) =>
                        handleFieldEvent({
                            room: e.detail.value,
                        })
                    }
                    data-testid={`Room`}
                />
            </FormField>
            <div className="grimsby-sub-section-divider" />
        </SpaceBetween>
    );
};

export default DeliveryAddressSection;

import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
    Alert,
    Spinner,
    Table,
    TablePagination,
    Button,
    ButtonDropdown,
    TableContentSelector,
    TablePageSizeSelector,
    TablePreferences,
    TableSorting,
    TablePropertyFiltering,
    Modal,
    FormField,
    Input,
} from '@amzn/awsui-components-react';

import { InstructorProfileData } from '../../../interfaces/instructorProfile';
import Can from '../../../../common/components/Can';
import TableHeader from '../../../../common/components/TableHeader/TableHeader';
import { Actions } from '../../../../common/constants/auth';
import {
    AJAX_CALL_ERROR,
    INSTRUCTOR_TABLE_TITLE,
    CREATE_BUTTON,
    LOADING_TEXT,
    PAGE_SELECTOR_OPTIONS,
} from '../../../../common/constants/grimsby';
import { useSelector, useDispatch } from 'react-redux';
import {
    getInstructorList,
    selectInstructorList,
    selectError,
    selectIsLoading,
    selectIsLoaded,
    selectPagesCount,
    selectCurrentPageIndex,
    selectTotalInstructorsCount,
    selectSize,
    setFrom,
    setCurrentPageIndex,
    setTotalInstructorsCount,
    setIsLoaded,
    setSize,
    initializeUserPreference,
    selectVisibleColumns,
    setVisibleColumns,
    setSortFields,
    setSavedFilter,
    setSearchText,
    selectSearchText,
    selectSavedFilter,
} from '../../../store/slices/instructorListSlice';
import {
    getInstructorStatusesList,
    resetInstructorStatusesSlice,
    selectAllActiveInstructorStatuses,
    selectIsLoading as selectIsStatusListLoading,
    selectIsLoaded as selectIsStatusListLoaded,
} from '../../../../common/store/slices/instructorStatusesSlice';
import {
    resetLocationsSlice,
    initializeLocationsListQueryParams,
    getLocationsList,
    selectAllActiveLocations,
    selectIsLoading as selectIsLocationListLoading,
    selectIsLoaded as selectIsLocationListLoaded,
} from '../../../../common/store/slices/locationsSlice';
import {
    getInstructorTypesList,
    resetInstructorTypesSlice,
    selectAllActiveInstructorTypes,
    selectIsLoading as selectIsTypeListLoading,
    selectIsLoaded as selectIsTypeListLoaded,
} from '../../../../common/store/slices/instructorTypesSlice';
import {
    resetAtpCompaniesSlice,
    initializeAtpCompaniesListQueryParams,
    getAtpCompaniesList,
    selectAllActiveAtpCompanies,
    selectIsLoading as selectIsAtpListLoading,
    selectIsLoaded as selectIsAtpListLoaded,
} from '../../../../common/store/slices/atpCompaniesSlice';
import {
    resetCoursesSlice,
    initializeCoursesListQueryParams,
    getCoursesList,
    selectAllActiveCourses,
    selectIsLoading as selectIsCourseListLoading,
    selectIsLoaded as selectIsCourseListLoaded,
} from '../../../../common/store/slices/coursesSlice';
import {
    resetDeliveryCountriesSlice,
    initializeDeliveryCountriesListQueryParams,
    getDeliveryCountriesList,
    selectAllActiveDeliveryCountries,
    selectIsLoading as selectIsDeliveryCountryListLoading,
    selectIsLoaded as selectIsDeliveryCountryListLoaded,
} from '../../../../common/store/slices/deliveryCountriesSlice';
import {
    getDeliveryLanguagesList,
    resetDeliveryLanguagesSlice,
    selectAllActiveDeliveryLanguages,
    selectIsLoading as selectIsLanguageListLoading,
    selectIsLoaded as selectIsLanguageListLoaded,
} from '../../../../common/store/slices/deliveryLanguagesSlice';
import {
    getRegionsList,
    resetRegionsSlice,
    selectAllActiveRegions,
    selectIsLoading as selectIsRegionListLoading,
    selectIsLoaded as selectIsRegionListLoaded,
} from '../../../../common/store/slices/regionsSlice';
import {
    getGeographiesList,
    resetGeographiesSlice,
    selectAllActiveGeographies,
    selectIsLoading as selectIsGeographyListLoading,
    selectIsLoaded as selectIsGeographyListLoaded,
} from '../../../../common/store/slices/geographiesSlice';
import {
    getInstructorCourseStatusesList,
    resetInstructorCourseStatusesSlice,
    selectAllActiveInstructorCourseStatuses,
    selectIsLoading as selectIsCourseStatusListLoading,
    selectIsLoaded as selectIsCourseStatusListLoaded,
} from '../../../../common/store/slices/instructorCourseStatusesSlice';
import {
    getCountriesList,
    resetCountriesSlice,
    selectAllActiveCountries,
    selectIsLoading as selectIsCountryListLoading,
    selectIsLoaded as selectIsCountryListLoaded,
} from '../../../../common/store/slices/countriesSlice';
import {
    formatInstructorStatus,
    formatName,
    formatLocation,
    formatProviders,
} from '../Common/Common';
import {
    updatePreferencesAndSavedFilters,
    selectUserId,
    selectIsLoading as selectIsUserLoading,
    selectInstructorListSavedFilterLookup,
    selectInstructorListSavedFilters,
    selectUser,
} from '../../../../common/store/slices/userSlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import handleInstructorListNotification from '../../../../common/utils/handleInstructorListNotification';
import { PropertyFilterKeys } from '../../../enums/propertyFilterKeys';
import debounce from '../../../../common/utils/debounce';
import { InstructorFilters } from '../../../interfaces/instructorFilters';
import handleLocalStorageSetItem from '../../../../common/utils/handleLocalStorageSetItem';
import {
    FieldArray,
    Form,
    Formik,
    FormikProps,
    Field,
    FieldProps,
} from 'formik';
import { FieldMetaProps } from 'formik/dist/types';
import { InstructorManagementAPIQueryParams } from '../../../interfaces/queryParams';
import { formatStringArray } from '../../../../common/utils/formatStringArray';
import { formatString } from '../../../../common/utils/formatString';

interface SavedFilter {
    id: string;
    name: string;
    filter: InstructorFilters;
}
interface SavedFilterForm {
    filters: Array<SavedFilter>;
}

export const APPROVED_COURSE_STATUS = 'Approved';

enum InstructorTableColumnId {
    Name = 'name',
    Status = 'status',
    Email = 'email',
    Type = 'type',
    Region = 'instructor_region',
    Location = 'location',
    Manager = 'manager',
    Geo = 'geo',
    Programs = 'programs',
    Providers = 'providers',
}

export const CONTENT_SELECTOR_OPTIONS = {
    label: '',
    options: [
        {
            id: InstructorTableColumnId.Name,
            label: 'Name',
            editable: false,
            visible: true,
        },
        {
            id: InstructorTableColumnId.Status,
            label: 'Status',
            editable: true,
            visible: true,
        },
        {
            id: InstructorTableColumnId.Email,
            label: 'Email',
            editable: true,
            visible: true,
        },
        {
            id: InstructorTableColumnId.Type,
            label: 'Type',
            editable: true,
            visible: true,
        },
        {
            id: InstructorTableColumnId.Providers,
            label: 'Providers',
            editable: true,
            visible: true,
        },
        {
            id: InstructorTableColumnId.Region,
            label: 'Region',
            editable: true,
            visible: true,
        },
        {
            id: InstructorTableColumnId.Location,
            label: 'Location',
            editable: true,
            visible: true,
        },
        {
            id: InstructorTableColumnId.Manager,
            label: 'Manager',
            editable: true,
            visible: true,
        },
        {
            id: InstructorTableColumnId.Geo,
            label: 'Geo',
            editable: true,
            visible: false,
        },
        {
            id: InstructorTableColumnId.Programs,
            label: 'Programs',
            editable: true,
            visible: false,
        },
    ],
};

export const INSTRUCTOR_FILTER_STORAGE_KEY = 'instructor_filter';
const MANAGE_FILTER_CONFIG = {
    text: 'Manage filters',
    id: 'manage_filters',
} as const;

export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: InstructorTableColumnId.Name,
        header: 'Name',
        cell: (instructor: InstructorProfileData) => (
            <Link to={'/instructors/' + instructor.pk}>
                {formatName(instructor.first_name, instructor.last_name)}
            </Link>
        ),
    },
    {
        id: InstructorTableColumnId.Email,
        header: 'Email',
        cell: (instructor: InstructorProfileData) =>
            formatString(instructor.email),
    },
    {
        id: InstructorTableColumnId.Status,
        header: 'Status',
        cell: (instructor: InstructorProfileData) =>
            formatInstructorStatus(instructor.instructor_status),
    },
    {
        id: InstructorTableColumnId.Type,
        header: 'Type',
        cell: (instructor: InstructorProfileData) =>
            formatString(instructor.instructor_type),
    },
    {
        id: InstructorTableColumnId.Providers,
        header: 'Providers',
        cell: (instructor: InstructorProfileData) =>
            formatProviders(instructor.providers),
    },
    {
        id: InstructorTableColumnId.Region,
        header: 'Region',
        cell: (instructor: InstructorProfileData) =>
            formatString(instructor.instructor_region),
    },
    {
        id: InstructorTableColumnId.Location,
        header: 'Location',
        cell: (instructor: InstructorProfileData) =>
            formatLocation(instructor.city, instructor.country),
    },
    {
        id: InstructorTableColumnId.Manager,
        header: 'Manager',
        cell: (instructor: InstructorProfileData) =>
            formatString(instructor.amazon_manager_name),
    },
    {
        id: InstructorTableColumnId.Geo,
        header: 'Geo',
        cell: (instructor: InstructorProfileData) =>
            formatString(instructor.geo),
    },
    {
        id: InstructorTableColumnId.Programs,
        header: 'Programs',
        cell: (instructor: InstructorProfileData) =>
            formatStringArray(instructor.programs),
    },
];

export const sortableColumns = columnDefinitions.reduce((acc, val) => {
    if (
        val.id !== InstructorTableColumnId.Programs &&
        val.id !== InstructorTableColumnId.Providers
    ) {
        acc.push({
            id: val.id as string,
        });
    }
    return acc;
}, [] as Array<TableSorting.SortableColumn>);

export const columnDefinitionsAttributesLookup: {
    [key: string]: Array<string>;
} = {
    name: ['first_name', 'last_name'],
    email: ['email'],
    status: ['instructor_status'],
    type: ['instructor_type'],
    providers: ['providers'],
    instructor_region: ['instructor_region'],
    location: ['city', 'country'],
    manager: ['amazon_manager_name'],
    geo: ['geo'],
};

interface GrimsbyPropertyFilterOptions extends TablePropertyFiltering.Option {
    propertyKey: PropertyFilterKeys;
}

const SingleSelectionOptionKeySet = new Set([
    PropertyFilterKeys.Courses,
    PropertyFilterKeys.CourseStatus,
    PropertyFilterKeys.Freelancer,
]);

enum PropertyFilterLabels {
    Status = 'Status',
    Type = 'Type',
    Geography = 'Geo',
    Region = 'Region',
    City = 'City',
    Country = 'Country',
    Providers = 'Providers',
    DeliveryCountries = 'Delivery countries',
    DeliveryLanguages = 'Delivery languages',
    Courses = 'Courses',
    CourseStatus = 'Course Status',
    FreelanceAgency = 'Freelance Agency',
    Freelancer = 'Freelancer',
    Programs = 'Programs',
    Manager = 'Manager',
}

const PROPERTY_FILTER_TO_LABEL_MAP: ReadonlyMap<
    PropertyFilterKeys,
    PropertyFilterLabels
> = new Map([
    [PropertyFilterKeys.Status, PropertyFilterLabels.Status],
    [PropertyFilterKeys.Type, PropertyFilterLabels.Type],
    [PropertyFilterKeys.Geography, PropertyFilterLabels.Geography],
    [PropertyFilterKeys.Region, PropertyFilterLabels.Region],
    [PropertyFilterKeys.City, PropertyFilterLabels.City],
    [PropertyFilterKeys.Country, PropertyFilterLabels.Country],
    [PropertyFilterKeys.Providers, PropertyFilterLabels.Providers],
    [
        PropertyFilterKeys.DeliveryCountries,
        PropertyFilterLabels.DeliveryCountries,
    ],
    [
        PropertyFilterKeys.DeliveryLanguages,
        PropertyFilterLabels.DeliveryLanguages,
    ],
    [PropertyFilterKeys.Courses, PropertyFilterLabels.Courses],
    [PropertyFilterKeys.CourseStatus, PropertyFilterLabels.CourseStatus],
    [PropertyFilterKeys.Freelancer, PropertyFilterLabels.Freelancer],
    [PropertyFilterKeys.FreelanceAgency, PropertyFilterLabels.FreelanceAgency],
    [PropertyFilterKeys.Programs, PropertyFilterLabels.Programs],
]);

const parseFiltersIntoTokens = ({
    search_text,
    propertyFilters,
}: InstructorFilters): Array<TablePropertyFiltering.FilteringToken> => {
    const freeTextTokens: Array<TablePropertyFiltering.FilteringToken> =
        search_text
            ? search_text?.split(' ').map((textVal) => ({
                  isFreeText: true,
                  label: `"${textVal}"`,
                  negated: false,
                  propertyKey: null as any,
                  propertyLabel: null as any,
                  value: textVal,
              }))
            : [];
    const propertyTokens: Array<TablePropertyFiltering.FilteringToken> =
        Object.entries(propertyFilters).reduce(
            (acc, [propertyFilterKey, propertyFilters]) => {
                acc = [
                    ...acc,
                    ...(propertyFilters
                        ? propertyFilters?.map((propertyFilter) => ({
                              label: propertyFilter,
                              negated: false,
                              propertyKey: propertyFilterKey,
                              propertyLabel: PROPERTY_FILTER_TO_LABEL_MAP.get(
                                  propertyFilterKey as PropertyFilterKeys,
                              ),
                              value: propertyFilter,
                          }))
                        : []),
                ];
                return acc;
            },
            [] as Array<any>,
        );

    return [...freeTextTokens, ...propertyTokens];
};

const getCourseAndCourseStatusIndices = (
    tokens: Array<TablePropertyFiltering.FilteringToken>,
) =>
    tokens.reduce(
        (acc, token, index) => {
            if (token.propertyKey === PropertyFilterKeys.Courses) {
                acc.course = index;
            }
            if (token.propertyKey === PropertyFilterKeys.CourseStatus) {
                acc.courseStatus = index;
            }
            return acc;
        },
        {
            course: -1,
            courseStatus: -1,
        } as {
            course: number;
            courseStatus: number;
        },
    );

const getLastSelectedCourseStatusForInit = (
    tokens: Array<TablePropertyFiltering.FilteringToken>,
) => {
    const { course: courseIndex } = getCourseAndCourseStatusIndices(tokens);
    return courseIndex !== -1 ? tokens[courseIndex].value : '';
};

const InstructorList = () => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const isLoaded = useSelector(selectIsLoaded);
    const instructorList = useSelector(selectInstructorList);
    const pagesCount = useSelector(selectPagesCount);
    const currentPageIndex = useSelector(selectCurrentPageIndex);
    const totalInstructorsCount = useSelector(selectTotalInstructorsCount);
    const size = useSelector(selectSize);
    const userId = useSelector(selectUserId);
    const visibleColumns = useSelector(selectVisibleColumns);
    const isUserLoading = useSelector(selectIsUserLoading);
    const isStatusListLoading = useSelector(selectIsStatusListLoading);
    const isStatusListLoaded = useSelector(selectIsStatusListLoaded);
    const isLocationListLoading = useSelector(selectIsLocationListLoading);
    const isLocationListLoaded = useSelector(selectIsLocationListLoaded);
    const isTypeListLoading = useSelector(selectIsTypeListLoading);
    const isTypeListLoaded = useSelector(selectIsTypeListLoaded);
    const isAtpListLoading = useSelector(selectIsAtpListLoading);
    const isAtpListLoaded = useSelector(selectIsAtpListLoaded);
    const isCoursesListLoading = useSelector(selectIsCourseListLoading);
    const isCoursesListLoaded = useSelector(selectIsCourseListLoaded);
    const isDeliveryCountryListLoading = useSelector(
        selectIsDeliveryCountryListLoading,
    );
    const isDeliveryCountryListLoaded = useSelector(
        selectIsDeliveryCountryListLoaded,
    );
    const isLanguageListLoading = useSelector(selectIsLanguageListLoading);
    const isLanguageListLoaded = useSelector(selectIsLanguageListLoaded);
    const isRegionListLoading = useSelector(selectIsRegionListLoading);
    const isRegionListLoaded = useSelector(selectIsRegionListLoaded);
    const isGeographyListLoading = useSelector(selectIsGeographyListLoading);
    const isGeographyListLoaded = useSelector(selectIsGeographyListLoaded);
    const isCourseStatusListLoading = useSelector(
        selectIsCourseStatusListLoading,
    );
    const isCourseStatusListLoaded = useSelector(
        selectIsCourseStatusListLoaded,
    );
    const isCountryListLoading = useSelector(selectIsCountryListLoading);
    const isCountryListLoaded = useSelector(selectIsCountryListLoaded);
    const statusList = useSelector(selectAllActiveInstructorStatuses);
    const locationList = useSelector(selectAllActiveLocations);
    const typeList = useSelector(selectAllActiveInstructorTypes);
    const atpList = useSelector(selectAllActiveAtpCompanies);
    const coursesList = useSelector(selectAllActiveCourses);
    const deliveryCountryList = useSelector(selectAllActiveDeliveryCountries);
    const languageList = useSelector(selectAllActiveDeliveryLanguages);
    const regionList = useSelector(selectAllActiveRegions);
    const geographyList = useSelector(selectAllActiveGeographies);
    const courseStatusList = useSelector(
        selectAllActiveInstructorCourseStatuses,
    );
    const countryList = useSelector(selectAllActiveCountries);
    const searchText = useSelector(selectSearchText);
    const savedFilter = useSelector(selectSavedFilter);
    const hasFilters = !!(searchText || savedFilter);
    const savedFilters = useSelector(selectInstructorListSavedFilters);
    const savedFilterNames = savedFilters.map((item) => item.name);
    const savedFilterLookup = useSelector(
        selectInstructorListSavedFilterLookup,
    );
    const user = useSelector(selectUser);
    const programsLength = user?.profile?.programs?.length || 0;
    const history = useHistory();
    const { addNotification } = useNotifications();
    const dispatch = useDispatch();

    // lifecycle method to initialize and reset business data slices
    useEffect(() => {
        // initialize query params for business data slices
        // this code block should only run once
        [
            initializeLocationsListQueryParams,
            initializeCoursesListQueryParams,
            initializeAtpCompaniesListQueryParams,
            initializeDeliveryCountriesListQueryParams,
        ].forEach((initializeQueryParams) =>
            dispatch(
                initializeQueryParams({
                    active: null,
                    size: 0,
                }),
            ),
        );

        return () => {
            // reset business data slices
            // this code block should only run once
            [
                resetInstructorStatusesSlice,
                resetLocationsSlice,
                resetInstructorTypesSlice,
                resetAtpCompaniesSlice,
                resetCoursesSlice,
                resetDeliveryCountriesSlice,
                resetDeliveryLanguagesSlice,
                resetRegionsSlice,
                resetGeographiesSlice,
                resetInstructorCourseStatusesSlice,
                resetCountriesSlice,
            ].forEach((resetFunction) => dispatch(resetFunction()));
        };
    }, [dispatch]);

    // lifecycle method to fetch (and refetch) business data
    useEffect(() => {
        (
            [
                [
                    !isLocationListLoaded && !isLocationListLoading,
                    getLocationsList,
                ],
                [
                    !isTypeListLoaded && !isTypeListLoading,
                    getInstructorTypesList,
                ],
                [!isCoursesListLoaded && !isCoursesListLoading, getCoursesList],
                [!isAtpListLoaded && !isAtpListLoading, getAtpCompaniesList],
                [
                    !isDeliveryCountryListLoaded &&
                        !isDeliveryCountryListLoading,
                    getDeliveryCountriesList,
                ],
                [
                    !isStatusListLoaded && !isStatusListLoading,
                    getInstructorStatusesList,
                ],
                [
                    !isLanguageListLoaded && !isLanguageListLoading,
                    getDeliveryLanguagesList,
                ],
                [!isRegionListLoaded && !isRegionListLoading, getRegionsList],
                [
                    !isGeographyListLoading && !isGeographyListLoaded,
                    getGeographiesList,
                ],
                [
                    !isCourseStatusListLoading && !isCourseStatusListLoaded,
                    getInstructorCourseStatusesList,
                ],
                [
                    !isCountryListLoading && !isCountryListLoaded,
                    getCountriesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    // lifecycle method to initialize and reset instructor list slice
    useEffect(() => {
        dispatch(initializeUserPreference());

        let storedFilterString = localStorage.getItem(
            INSTRUCTOR_FILTER_STORAGE_KEY,
        );

        if (storedFilterString) {
            let { search_text, propertyFilters } = JSON.parse(
                storedFilterString,
            ) as InstructorFilters;

            const parsedTokens = parseFiltersIntoTokens({
                search_text,
                propertyFilters,
            });
            setFilterTokens(parsedTokens);
            setLastSelectedCourse(
                getLastSelectedCourseStatusForInit(parsedTokens),
            );
            updateSingleSelectionOptionKeys(propertyFilters);
            dispatch(setSearchText(search_text));
            dispatch(setSavedFilter(propertyFilters));
        }

        const [first_name, last_name] = columnDefinitionsAttributesLookup.name;
        dispatch(
            setSortFields([
                { [first_name]: { order: 'asc' } },
                { [last_name]: { order: 'asc' } },
            ]),
        );

        dispatch(getInstructorList());

        return () => {
            dispatch(setFrom(0));
            dispatch(setIsLoaded(false));
            dispatch(setTotalInstructorsCount(0));
        };
    }, [dispatch]);

    const [hiddenOptionKeys, setHiddenOptionKeys] = useState(
        new Set<PropertyFilterKeys>([PropertyFilterKeys.CourseStatus]),
    );
    const [isSaveFilterModalActive, setIsSaveFilterModalActive] =
        useState(false);

    const [isManageFilterModalActive, setIsManageFilterModalActive] =
        useState(false);
    const [savedFilterName, setSavedFilterName] = useState('');
    const [filterTokens, setFilterTokens] = useState(
        [] as Array<TablePropertyFiltering.FilteringToken>,
    );

    const [validateOnChange, setValidateOnChange] = useState(false);

    // initialize name column as current sorting column
    const [currentSortingColumn, setCurrentSortingColumn] = useState(
        columnDefinitions[0].id,
    );

    const [lastSelectedCourse, setLastSelectedCourse] = useState('');

    const getSavedFilterFormValues = (): SavedFilterForm => {
        return {
            filters: savedFilters.map((filter) => {
                return {
                    id: `${Date.now()}_${filter.name}`,
                    ...filter,
                };
            }),
        };
    };

    const validateFilterName = (
        { filters }: SavedFilterForm,
        filterItem: SavedFilter,
        meta: FieldMetaProps<any>,
    ) => {
        if (!filterItem.name) {
            return 'Filter name is required';
        }

        if (meta.initialValue === meta.value) {
            return '';
        }

        const restFilters = filters.filter((item) => item.id !== filterItem.id);

        const isDuplicate = restFilters.some((item: SavedFilter) => {
            return item.name === filterItem.name && item.id !== filterItem.id;
        });

        if (isDuplicate) {
            return 'A filter with this name already exists. Please select another name.';
        } else {
            return '';
        }
    };

    const updateSingleSelectionOptionKeys = (
        propertyFilters: InstructorManagementAPIQueryParams.PropertyFilterParams,
    ) => {
        // filter keys to be hidden
        const hiddenKeys = new Set<PropertyFilterKeys>(
            (Object.keys(propertyFilters) as Array<PropertyFilterKeys>).filter(
                (filterKey) => SingleSelectionOptionKeySet.has(filterKey),
            ),
        );

        // if course and course status are not selected, add course status into the hidden keys
        if (
            !hiddenKeys.has(PropertyFilterKeys.CourseStatus) &&
            !hiddenKeys.has(PropertyFilterKeys.Courses)
        ) {
            hiddenKeys.add(PropertyFilterKeys.CourseStatus);
        }

        // if course but course status is selected, the hidden keys already contains course but course status;
        // if course and course status are selected, the hidden keys already contains them;
        // it's not possible that course status but course is selected since its parent method
        // "handlePropertyFilteringChange()" should already process this scenario to remove them together.
        setHiddenOptionKeys(hiddenKeys);
    };

    const setSavedFilterAndGetInstructorList = debounce<InstructorFilters>(
        ({ search_text, propertyFilters }) => {
            dispatch(setSearchText(search_text));
            dispatch(setSavedFilter(propertyFilters));
            dispatch(getInstructorList());
        },
    );

    const filteringOptions: Array<GrimsbyPropertyFilterOptions> = [
        {
            groupValuesLabel: PropertyFilterLabels.City,
            propertyKey: PropertyFilterKeys.City,
            propertyLabel: PropertyFilterLabels.City,
            values: locationList.map((locationData) => locationData.city),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Country,
            propertyKey: PropertyFilterKeys.Country,
            propertyLabel: PropertyFilterLabels.Country,
            values: countryList.map((countryData) => countryData.country),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Courses,
            propertyKey: PropertyFilterKeys.Courses,
            propertyLabel: PropertyFilterLabels.Courses,
            values: coursesList.map((courseData) => courseData.course),
        },
        {
            groupValuesLabel: PropertyFilterLabels.CourseStatus,
            propertyKey: PropertyFilterKeys.CourseStatus,
            propertyLabel: PropertyFilterLabels.CourseStatus,
            values: courseStatusList.map(
                (courseStatusData) => courseStatusData.instructor_course_status,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.DeliveryCountries,
            propertyKey: PropertyFilterKeys.DeliveryCountries,
            propertyLabel: PropertyFilterLabels.DeliveryCountries,
            values: deliveryCountryList.map(
                (deliveryCountryData) => deliveryCountryData.delivery_country,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.DeliveryLanguages,
            propertyKey: PropertyFilterKeys.DeliveryLanguages,
            propertyLabel: PropertyFilterLabels.DeliveryLanguages,
            values: languageList.map(
                (languageData) => languageData.delivery_language,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.FreelanceAgency,
            propertyKey: PropertyFilterKeys.FreelanceAgency,
            propertyLabel: PropertyFilterLabels.FreelanceAgency,
            values: atpList
                .filter(
                    (atpData) => atpData.active && atpData.is_freelance_agency,
                )
                .map((atpData) => atpData.atp_company),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Freelancer,
            propertyKey: PropertyFilterKeys.Freelancer,
            propertyLabel: PropertyFilterLabels.Freelancer,
            values: ['Yes', 'No'],
        },
        {
            groupValuesLabel: PropertyFilterLabels.Geography,
            propertyKey: PropertyFilterKeys.Geography,
            propertyLabel: PropertyFilterLabels.Geography,
            values: geographyList.map((geographyData) => geographyData.geo),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Manager,
            propertyKey: PropertyFilterKeys.AWS_MANAGER,
            propertyLabel: PropertyFilterLabels.Manager,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.Providers,
            propertyKey: PropertyFilterKeys.Providers,
            propertyLabel: PropertyFilterLabels.Providers,
            values: atpList.map((atpData) => atpData.atp_company),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Region,
            propertyKey: PropertyFilterKeys.Region,
            propertyLabel: PropertyFilterLabels.Region,
            values: regionList.map((regionData) => regionData.region),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Status,
            propertyKey: PropertyFilterKeys.Status,
            propertyLabel: PropertyFilterLabels.Status,
            values: statusList.map(
                (statusData) => statusData.instructor_status,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Type,
            propertyKey: PropertyFilterKeys.Type,
            propertyLabel: PropertyFilterLabels.Type,
            values: typeList.map((typeData) => typeData.instructor_type),
        },
        ...(programsLength > 1
            ? ([
                  {
                      groupValuesLabel: PropertyFilterLabels.Programs,
                      propertyKey: PropertyFilterKeys.Programs,
                      propertyLabel: PropertyFilterLabels.Programs,
                      values: user?.profile.programs,
                  },
              ] as Array<GrimsbyPropertyFilterOptions>)
            : []),
    ].filter((opt) => !hiddenOptionKeys.has(opt.propertyKey));

    const getSavedFilterItems = (): Array<ButtonDropdown.Item> => {
        const manageItem = {
            text: MANAGE_FILTER_CONFIG.text,
            id: MANAGE_FILTER_CONFIG.id,
            disabled: false,
        } as ButtonDropdown.Item;

        return [
            {
                id: 'filter_items',
                text: '',
                items: savedFilterNames.map((name) => ({
                    text: name,
                    id: name,
                    disable: false,
                })),
            },
            {
                id: 'manage_items',
                text: '',
                items: [manageItem],
            },
        ];
    };

    const updateUserSavedFilters = (updatedFilters?: SavedFilterForm) => {
        if (userId) {
            if (!updatedFilters) {
                return dispatch<any>(
                    updatePreferencesAndSavedFilters(userId, {
                        saved_filters: [
                            ...savedFilters,
                            {
                                name: savedFilterName.trim(),
                                filter: {
                                    search_text: searchText,
                                    propertyFilters:
                                        savedFilter as InstructorManagementAPIQueryParams.PropertyFilterParams,
                                },
                            },
                        ],
                    }),
                );
            } else {
                const updatedFilterToSave: Array<{
                    name: string;
                    filter: InstructorFilters;
                }> = updatedFilters.filters.map((filterItem) => {
                    return {
                        name: filterItem.name,
                        filter: {
                            propertyFilters: filterItem.filter.propertyFilters,
                            search_text: filterItem.filter.search_text,
                        },
                    };
                });

                return dispatch<any>(
                    updatePreferencesAndSavedFilters(userId, {
                        saved_filters: updatedFilterToSave,
                    }),
                );
            }
        }
        return Promise.resolve(false);
    };

    const updateUserPreference = (data: {
        [key: string]: number | Array<string>;
    }): Promise<boolean> => {
        if (userId && data) {
            return dispatch<any>(
                updatePreferencesAndSavedFilters(userId, {
                    preferences: {
                        instructor_list: data,
                    },
                }),
            );
        }
        return Promise.resolve(false);
    };

    const generateContentSelectorOptions = (
        contentSelection: Array<string> | null,
    ): Array<TableContentSelector.ContentDescriptionGroup> => {
        if (!contentSelection) {
            return [CONTENT_SELECTOR_OPTIONS];
        }

        const contentSelectionSet = new Set(contentSelection);
        return [
            {
                label: CONTENT_SELECTOR_OPTIONS.label,
                options: CONTENT_SELECTOR_OPTIONS.options.map((opt) => ({
                    id: opt.id,
                    label: opt.label,
                    editable: opt.editable,
                    visible: contentSelectionSet.has(opt.id),
                })),
            },
        ];
    };

    const handlePaginationChange = async (
        event: CustomEvent<TablePagination.PaginationChangeDetail>,
    ) => {
        const { pageSize, currentPageIndex } = event.detail;
        let isSuccessful = true;

        // update the user's preferences of page size
        if (pageSize !== size) {
            const dispatchPromise = updateUserPreference({
                page_size: pageSize,
            });

            // add notification
            isSuccessful = await handleInstructorListNotification(
                dispatchPromise,
                addNotification,
            );
        }

        // if the BE update is successful or no BE update, update the global state
        if (isSuccessful) {
            // the following logic handles both pagination operations and
            // page size changing due to the current Polaris design
            let updatedPageIndex = currentPageIndex;
            let from = (updatedPageIndex - 1) * pageSize;

            // if the user's preference of page size is changed,
            // redirect to the first page
            if (pageSize !== size) {
                updatedPageIndex = 1;
                from = 0;
            }

            // Update global state
            dispatch(setCurrentPageIndex(updatedPageIndex));
            dispatch(setSize(pageSize));
            dispatch(setFrom(from));
            dispatch(getInstructorList());
        }
    };

    const handleContentSelectionChange = async (
        event: CustomEvent<Table.ContentSelectionChangeDetail>,
    ) => {
        const contentSelection = event.detail.contentSelection;

        if (contentSelection) {
            // update the user's preferences of visible columns
            const dispatchPromise = updateUserPreference({
                visible_columns: contentSelection,
            });

            // add notification
            await handleInstructorListNotification(
                dispatchPromise,
                addNotification,
            );

            // update the global state no matter whether BE update is successful
            // since the current Polaris will auto filter out the invisible columns
            // by CSS and we don't have full control on it by only JS.
            dispatch(setVisibleColumns(contentSelection));
        }
    };

    const handleSortingChange = async (
        event: CustomEvent<TableSorting.SortingChangeDetail>,
    ) => {
        const { sortingColumn, sortingDescending } = event.detail;
        const order = sortingDescending ? 'desc' : 'asc';

        setCurrentSortingColumn(sortingColumn);

        const sortFields: Array<InstructorManagementAPIQueryParams.SortFieldsItem> =
            columnDefinitionsAttributesLookup[sortingColumn].map((item) => ({
                [item]: {
                    order: order,
                },
            }));

        dispatch(setSortFields(sortFields));
        dispatch(getInstructorList());
    };

    const refreshPagination = () => {
        dispatch(setCurrentPageIndex(1));
        dispatch(setFrom(0));
    };

    const handleSavedFilterChange = (id: string) => {
        if (!savedFilterLookup) {
            return;
        }

        if (id === MANAGE_FILTER_CONFIG.id) {
            // Polaris guidelines: form with multiple fields
            // set validation on change to false when the form loads for the first time.
            setValidateOnChange(false);

            // show manage filters dialog
            setIsManageFilterModalActive(true);
            return;
        }

        handlePropertyFilteringChange(
            parseFiltersIntoTokens(savedFilterLookup[id]),
        );
    };

    const handlePropertyFilteringChange = (
        tokens: Array<TablePropertyFiltering.FilteringToken>,
    ) => {
        const indices = getCourseAndCourseStatusIndices(tokens);

        // if course is not selected but course status is, de-select (remove) the selected course status
        if (indices.course === -1 && indices.courseStatus !== -1) {
            tokens.splice(indices.courseStatus, 1);
        }

        // if course is selected, and course status is not selected,
        // set the course status filter to 'Approved' by default
        if (
            indices.course !== -1 &&
            indices.courseStatus === -1 &&
            tokens[indices.course].value !== lastSelectedCourse
        ) {
            tokens = [
                ...tokens,
                {
                    label: APPROVED_COURSE_STATUS,
                    negated: false,
                    propertyKey: PropertyFilterKeys.CourseStatus,
                    propertyLabel: PropertyFilterLabels.CourseStatus,
                    value: APPROVED_COURSE_STATUS,
                },
            ];
            setLastSelectedCourse(tokens[indices.course].value);
        }

        if (indices.course === -1 && !!lastSelectedCourse) {
            setLastSelectedCourse('');
        }

        setFilterTokens(tokens);
        if (tokens?.length) {
            const instructorFilters = tokens.reduce(
                (acc, opt) => {
                    if (opt.isFreeText && opt.propertyKey === null) {
                        acc.search_text = acc.search_text
                            ? `${acc.search_text} ${opt.value}`
                            : `${opt.value}`;
                    } else {
                        const key = opt.propertyKey as PropertyFilterKeys;
                        if (!acc.propertyFilters[key]) {
                            acc.propertyFilters[key] = [];
                        }

                        (acc.propertyFilters[key] as Array<string>).push(
                            opt.value,
                        );
                    }
                    return acc;
                },
                {
                    search_text: null,
                    propertyFilters: {},
                } as InstructorFilters,
            );

            updateSingleSelectionOptionKeys(instructorFilters.propertyFilters);
            refreshPagination();
            setSavedFilterAndGetInstructorList(instructorFilters);
            handleLocalStorageSetItem(
                INSTRUCTOR_FILTER_STORAGE_KEY,
                JSON.stringify(instructorFilters),
            );
        } else {
            setHiddenOptionKeys(
                new Set<PropertyFilterKeys>([PropertyFilterKeys.CourseStatus]),
            );
            localStorage.removeItem(INSTRUCTOR_FILTER_STORAGE_KEY);

            refreshPagination();
            dispatch(setSearchText(null));
            dispatch(setSavedFilter(null));
            dispatch(getInstructorList());
        }
    };

    if (error) {
        return (
            <Alert header="Error" type="error">
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (isLoaded) {
        const tableHeaderComponent = (
            <TableHeader
                title={INSTRUCTOR_TABLE_TITLE}
                actions={
                    <>
                        {hasFilters && (
                            <Button
                                variant="normal"
                                disabled={isLoading || isUserLoading}
                                data-testid="InstructorListActionsSaveFilters"
                                onClick={() => setIsSaveFilterModalActive(true)}
                            >
                                Save Filters
                            </Button>
                        )}
                        {!!savedFilterNames?.length && savedFilterLookup && (
                            <ButtonDropdown
                                data-testid="InstructorListActionsLoadFilters"
                                items={getSavedFilterItems()}
                                onItemClick={({ detail: { id } }) => {
                                    handleSavedFilterChange(id);
                                }}
                            >
                                Load filters
                            </ButtonDropdown>
                        )}
                        <Can
                            perform={Actions.INSTRUCTOR_MODIFY}
                            yes={() => (
                                <Button
                                    icon="add-plus"
                                    variant="primary"
                                    data-testid="InstructorListActionsCreate"
                                    onClick={() =>
                                        history.push({
                                            pathname: `/instructors/create`,
                                        })
                                    }
                                    label={CREATE_BUTTON}
                                >
                                    {CREATE_BUTTON}
                                </Button>
                            )}
                        />
                    </>
                }
                count={totalInstructorsCount ? totalInstructorsCount : ''}
            />
        );

        const emptyTableComponent = (
            <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                    <b>No instructors</b>
                </div>
                <p className="awsui-util-mb-s">No instructors to display.</p>
            </div>
        );

        const saveFilters = async (updatedFilters?: SavedFilterForm) => {
            const filterNameExists =
                !!savedFilterLookup?.[savedFilterName.trim()];

            // when saving a new filter
            if (!updatedFilters && filterNameExists) {
                return;
            }
            const isSuccessful: boolean =
                await updateUserSavedFilters(updatedFilters);
            addNotification({
                id: `update-user-instructor-list-saved-filters-${Date.now}`,
                ...(isSuccessful
                    ? {
                          type: 'success',
                          content:
                              'You have successfully saved the instructor filters.',
                      }
                    : {
                          type: 'error',
                          content:
                              'An error occurred while saving the instructor filters.',
                      }),
            });

            if (isSuccessful) {
                setSavedFilterName('');
                if (!updatedFilters) {
                    setIsSaveFilterModalActive(false);
                } else {
                    setIsManageFilterModalActive(false);
                }
            }
        };
        return (
            <>
                <Table
                    data-testid="InstructorListTable"
                    loading={isLoading || isUserLoading}
                    loadingText={LOADING_TEXT}
                    columnDefinitions={columnDefinitions}
                    items={instructorList}
                    header={tableHeaderComponent}
                    empty={emptyTableComponent}
                    onContentSelectionChange={handleContentSelectionChange}
                >
                    <TablePropertyFiltering
                        filteringLabel="Instructor table filter text input"
                        filteringFunction={null}
                        filteringOptions={filteringOptions}
                        placeholder="Search by instructor name, email, or property"
                        hideOperations={true}
                        groupPropertiesText="Properties"
                        groupValuesText="Values"
                        clearFiltersText="Clear filters"
                        allowFreeTextFiltering={true}
                        tokens={filterTokens}
                        onPropertyFilteringChange={({ detail: { tokens } }) =>
                            handlePropertyFilteringChange(tokens)
                        }
                        filteringEmpty={
                            <span className="awsui-util-status-inactive">
                                Enter to search
                            </span>
                        }
                    />
                    <TablePagination
                        pageSize={size}
                        pagesCount={pagesCount}
                        disabled={isLoading || isUserLoading}
                        currentPageIndex={currentPageIndex}
                        onPaginationChange={handlePaginationChange}
                    />
                    <TableSorting
                        sortingColumn={currentSortingColumn}
                        sortableColumns={sortableColumns}
                        disabled={isLoading || isUserLoading}
                        onSortingChange={handleSortingChange}
                    />
                    <TablePreferences
                        title="Preferences"
                        confirmLabel="Save preferences"
                        cancelLabel="Cancel"
                    >
                        <TablePageSizeSelector
                            title="Page size"
                            options={PAGE_SELECTOR_OPTIONS}
                        />
                        <TableContentSelector
                            title="Select visible columns"
                            options={generateContentSelectorOptions(
                                visibleColumns,
                            )}
                        />
                    </TablePreferences>
                </Table>
                <Modal
                    visible={isSaveFilterModalActive}
                    header="Save filter settings"
                    data-testid="InstructorListSaveFilterModal"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button
                                data-testid="InstructorListSaveFilterModalCancel"
                                variant="link"
                                onClick={() =>
                                    setIsSaveFilterModalActive(false)
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid="InstructorListSaveFilterModalSave"
                                disabled={
                                    !savedFilterName ||
                                    !!savedFilterLookup?.[
                                        savedFilterName.trim()
                                    ]
                                }
                                variant="primary"
                                loading={isUserLoading}
                                onClick={() => saveFilters()}
                            >
                                {isUserLoading ? 'Saving' : 'Save'}
                            </Button>
                        </span>
                    }
                    onDismiss={() => setIsSaveFilterModalActive(false)}
                >
                    <FormField
                        label="List name"
                        description="Create a name for this list's filter settings"
                        errorText={
                            !!savedFilterLookup?.[savedFilterName.trim()]
                                ? 'A filter with this name already exists. Please select another name.'
                                : null
                        }
                    >
                        <Input
                            data-testid="InstructorListSaveFilterModalInput"
                            value={savedFilterName}
                            onInput={(e) => setSavedFilterName(e.detail.value)}
                        />
                    </FormField>
                </Modal>
                {isManageFilterModalActive && (
                    <Formik
                        validateOnChange={validateOnChange}
                        validateOnBlur={validateOnChange}
                        initialValues={getSavedFilterFormValues()}
                        onSubmit={saveFilters}
                    >
                        {(formikValues: FormikProps<SavedFilterForm>) => (
                            <Modal
                                className="saved-filters"
                                header="Manage saved filters"
                                data-testid="InstructorListManageFilterModal"
                                visible={isManageFilterModalActive}
                                onDismiss={() =>
                                    setIsManageFilterModalActive(false)
                                }
                                footer={
                                    <span className="awsui-util-f-r">
                                        <Button
                                            data-testid="InstructorListManageFilterModalCancel"
                                            variant="link"
                                            onClick={() =>
                                                setIsManageFilterModalActive(
                                                    false,
                                                )
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            data-testid="InstructorListManageFilterUpdateButton"
                                            variant="primary"
                                            onClick={() => {
                                                if (!formikValues.dirty) {
                                                    setIsManageFilterModalActive(
                                                        false,
                                                    );
                                                    return;
                                                }

                                                // Polaris guidelines: form with multiple fields
                                                // set validation on change and blur after first submit
                                                if (!validateOnChange) {
                                                    setValidateOnChange(true);
                                                }

                                                formikValues.submitForm();
                                            }}
                                            loading={isUserLoading}
                                        >
                                            {isUserLoading ? 'Saving' : 'Save'}
                                        </Button>
                                    </span>
                                }
                            >
                                <Form className="awsui-grid">
                                    <div className="saved-filters--header awsui-util-mb-l awsui-util-pb-s">
                                        <h5 className="awsui-util-font-size-2">
                                            Saved filters
                                        </h5>
                                    </div>
                                    <FieldArray name="filters">
                                        {(arrayHelper) => (
                                            <>
                                                {formikValues.values.filters.map(
                                                    (filter, index) => (
                                                        <Field
                                                            key={`filter_${index}`}
                                                            name={`filters[${index}].name`}
                                                            validate={() =>
                                                                validateFilterName(
                                                                    formikValues.values,
                                                                    filter,
                                                                    formikValues.getFieldMeta(
                                                                        `filters[${index}].name`,
                                                                    ),
                                                                )
                                                            }
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                                meta,
                                                            }: FieldProps) => (
                                                                <FormField
                                                                    errorText={
                                                                        meta.error ??
                                                                        ''
                                                                    }
                                                                    secondaryControl={
                                                                        <Button
                                                                            data-testid={`InstructorListManageFilterModalDelete_${index}`}
                                                                            formAction="none"
                                                                            onClick={() => {
                                                                                arrayHelper.remove(
                                                                                    index,
                                                                                );

                                                                                form.validateForm();
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    }
                                                                >
                                                                    <Input
                                                                        data-testid={`InstructorListManageFilterModalInput_${index}`}
                                                                        name={
                                                                            field.name
                                                                        }
                                                                        value={
                                                                            field.value
                                                                        }
                                                                        onInput={({
                                                                            detail,
                                                                        }) => {
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                detail.value,
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormField>
                                                            )}
                                                        </Field>
                                                    ),
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </Form>
                            </Modal>
                        )}
                    </Formik>
                )}
            </>
        );
    } else {
        return <Spinner data-testid="InstructorListLoadingSpinner" />;
    }
};

export default InstructorList;

/*
    AdminBusinessData.tsx renders a list consisting of single values in a data table with pagination, sorting and Action Buttons
    It also renders a create/edit modal to add a list item / modify an existing list item.
*/
import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useState,
} from 'react';
import { Alert, Spinner, Table } from '@amzn/awsui-components-react';
import { Link, useLocation } from 'react-router-dom';

import {
    AJAX_CALL_ERROR,
    LOADING_TEXT,
} from '../../../common/constants/grimsby';
import { AdminBusinessDataFormSchema } from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import FormModal, { FormModalProps } from './AdminBusinessData.FormModal';
import BusinessDataTable, {
    AdminBusinessDataTableProps,
    AdminBusinessDataTableSSPaginationProps,
} from './AdminBusinessData.Table';

export interface AdminBusinessDataManagementProps<BusinessDataType> {
    columnDefinitions: Array<Table.ColumnDefinition>;
    createFormSchema?: AdminBusinessDataFormSchema<BusinessDataType>; // default create form schema.
    updateFormSchema?: AdminBusinessDataFormSchema<BusinessDataType>; // default update form schema.
    handleItemSelect: (data: BusinessDataType) => Promise<void>;
    handleItemUpdate: (data: BusinessDataType) => Promise<void>;
    handleCreateModalOpen?: () => Promise<void>;
    handleItemCreate: (data: BusinessDataType) => Promise<void>;
    itemDisplayNameSingular: string;
    itemNameKey: string;
    title: string;
    useItemList: () => AdminBusinessDataSelectors<BusinessDataType>;
    serverSidePaginationProps?: AdminBusinessDataTableSSPaginationProps;
    createForm?: JSX.Element; // override for default create form. `createFormSchema` will be ignored
    updateForm?: JSX.Element; // override for default update form `updateFormSchema` will be ignored
    creatingFlag?: boolean; // boolean to close modal when overriding create form
    updatingFlag?: boolean; // boolean to close modal when overriding update form
    setCreatingFlag?: Dispatch<SetStateAction<boolean>>; // dispatcher to set creating flag
    setUpdatingFlag?: Dispatch<SetStateAction<boolean>>; // dispatcher to set updating flag
    customValidator?: (data: BusinessDataType) => Promise<void | any>;
}

export interface InitialFormState {
    [key: string]: any;
}

const AdminBusinessData = <BusinessDataType,>({
    columnDefinitions,
    createFormSchema,
    updateFormSchema,
    handleCreateModalOpen,
    handleItemCreate,
    handleItemSelect,
    handleItemUpdate,
    itemDisplayNameSingular,
    itemNameKey,
    title,
    useItemList,
    serverSidePaginationProps,
    createForm,
    updateForm,
    creatingFlag,
    updatingFlag,
    setCreatingFlag,
    setUpdatingFlag,
    customValidator,
}: PropsWithChildren<AdminBusinessDataManagementProps<BusinessDataType>>) => {
    const location = useLocation();
    const {
        items,
        itemCount,
        isLoading,
        isLoaded,
        error,
        currentItem,
    }: AdminBusinessDataSelectors<BusinessDataType> = useItemList();
    const [editing, setEditing] = useState(false);
    const [loadingSelectedItem, setLoadingSelectedItem] = useState(false);
    const [loadingCreateModal, setLoadingCreateModal] = useState(false);
    const [creating, setCreating] = useState(false);

    const initialCreateFormState: BusinessDataType =
        createFormSchema &&
        Object.keys(createFormSchema).reduce((acc: any, key: string) => {
            acc[key] = createFormSchema[key].defaultValue;
            return acc;
        }, {});

    const tableColumnDefinitions: Array<Table.ColumnDefinition> = [
        {
            id: `${itemNameKey}`,
            header: `${itemDisplayNameSingular}`,
            cell: (item: any) => {
                return (
                    // this should actually be a button styled to look like a link since it doesn't go anywhere
                    <Link
                        to={location.pathname}
                        onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setUpdatingFlag
                                ? setUpdatingFlag(true)
                                : setEditing(true);
                            setLoadingSelectedItem(true);
                            await handleItemSelect(item);
                            setLoadingSelectedItem(false);
                        }}
                        data-testid={`AdminBusinessData-${itemNameKey}-${item[itemNameKey]}`}
                    >
                        {item[itemNameKey]}
                    </Link>
                );
            },
        },
        ...columnDefinitions,
    ];

    const tableProps: AdminBusinessDataTableProps<BusinessDataType> = {
        columnDefinitions: tableColumnDefinitions,
        items: items,
        itemCount: itemCount,
        itemDisplayNameSingular: itemDisplayNameSingular,
        itemNameKey: itemNameKey,
        loading: isLoading,
        loadingText: LOADING_TEXT,
        setCreating:
            setCreatingFlag !== undefined ? setCreatingFlag : setCreating,
        setLoadingCreateModal: setLoadingCreateModal,
        title: title,
        onCreateModalOpen: handleCreateModalOpen,
        serverSidePaginationProps: serverSidePaginationProps,
    };

    const createModalProps: any = {
        mode: 'Create',
        formSchema: createFormSchema,
        formElement: createForm,
        initialFormState: initialCreateFormState as BusinessDataType,
        itemDisplayNameSingular: itemDisplayNameSingular,
        itemNameKey: itemNameKey,
        isModalOpen: creatingFlag !== undefined ? creatingFlag : creating,
        loading: loadingCreateModal,
        customValidator,
        setIsModalOpen:
            setCreatingFlag !== undefined ? setCreatingFlag : setCreating,
        saveItem: handleItemCreate,
    };

    const updateModalProps: any = {
        mode: 'Update',
        formSchema: updateFormSchema,
        formElement: updateForm,
        initialFormState: currentItem as BusinessDataType,
        itemDisplayNameSingular: itemDisplayNameSingular,
        itemNameKey: itemNameKey,
        isModalOpen: updatingFlag !== undefined ? updatingFlag : editing,
        loading: loadingSelectedItem,
        setIsModalOpen:
            setUpdatingFlag !== undefined ? setUpdatingFlag : setEditing,
        saveItem: handleItemUpdate,
    };

    if (error) {
        return (
            <Alert
                data-testid={`AdminBusinessData-${itemNameKey}-Error`}
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (isLoaded) {
        return (
            <div data-testid={`AdminBusinessData-${itemNameKey}`}>
                <BusinessDataTable {...tableProps} />
                <FormModal {...createModalProps} />
                <FormModal {...updateModalProps} />
            </div>
        );
    } else {
        return (
            <div>
                <Spinner
                    data-testid={`AdminBusinessData-${itemNameKey}-Spinner`}
                />
            </div>
        );
    }
};

export default AdminBusinessData;

import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form } from '@amzn/awsui-components-react-v3';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
    selectIsLoading,
    updateSelectedActivity,
} from '../../../store/slices/selectedActivitySlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { ActivityData, CostItem } from '../../../interfaces/activity';
import { FORM_ERROR_SELECTOR } from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import { ActivityControlArrayErrors } from '../Common/Common';
import EditOperationsAndRegistrationDetailsFormSection from '../FormSections/EditOperationsAndRegistrationDetailsFormSection';
import CancelModal, {
    CancelModalProps,
} from '../../../../common/components/CancelModal/CancelModal';
import { FormSectionProps } from '../../../../common/interfaces/formSectionProps';

type ActivityDataKeys = Array<keyof Omit<ActivityData, 'cost_items'>>;

const getEditOperationsAndRegistrationValidationConfig = (
    hasCostItems: boolean,
): {
    [key in ValidationType]?: ActivityDataKeys;
} => ({
    required: [
        'operations_owner',
        ...((hasCostItems ? ['cost_currency'] : []) as ActivityDataKeys),
    ],
});

export interface CostItemAttributeEditorItem {
    costType: string;
    costAmount: number | null;
    paymentPo: string;
    paymentStatus: string;
}

export interface ActivityCostItemsControlArrayFormValues {
    costItemAttributeEditorItems: CostItemAttributeEditorItem;
}

export type EditOpsAndRegFormSectionProps<T> = Omit<
    FormSectionProps<T>,
    'mode' | 'validateAndHandleFieldEvent'
> & {
    handleCostItemEvent: (
        costItems: Array<CostItemAttributeEditorItem>,
    ) => void;
    costItemAttributeEditorItems: Array<CostItemAttributeEditorItem>;
    controlArrayErrors: ActivityControlArrayErrors<ActivityCostItemsControlArrayFormValues>;
};

export const costAttributeValidationConfig: {
    [activityControlArrayFormValuesKey in keyof ActivityCostItemsControlArrayFormValues]: {
        [key in ValidationType]?: Array<
            keyof ActivityCostItemsControlArrayFormValues[activityControlArrayFormValuesKey]
        >;
    };
} = {
    costItemAttributeEditorItems: {
        required: ['costAmount', 'costType', 'paymentPo', 'paymentStatus'],
    },
};

const getCostItemAttributeEditorItem = ({
    cost_type,
    cost_amount,
    payment_po_number_or_pcard,
    payment_status,
}: CostItem): CostItemAttributeEditorItem => ({
    costType: cost_type || '',
    costAmount: cost_amount || null,
    paymentPo: payment_po_number_or_pcard || '',
    paymentStatus: payment_status || '',
});

const getCostItem = ({
    costType: cost_type,
    costAmount: cost_amount,
    paymentPo: payment_po_number_or_pcard,
    paymentStatus: payment_status,
}: CostItemAttributeEditorItem): CostItem => ({
    cost_type,
    cost_amount,
    payment_po_number_or_pcard,
    payment_status,
});

const EditOperationsAndRegistrationDetailsForm = ({
    initialFormState,
}: {
    initialFormState: ActivityData;
}) => {
    const match = useRouteMatch<{ id: string }>();
    const isLoading = useSelector(selectIsLoading);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification, removeAllNotifications } = useNotifications();
    const [formValues, setFormValues] =
        useState<ActivityData>(initialFormState);
    const {
        isInvalid,
        isControlArrayInvalid,
        errors,
        controlArrayErrors,
        validateForm,
        validateFormControlArray,
    } = useFormValidation<
        Omit<ActivityData, 'cost_items'>,
        ActivityCostItemsControlArrayFormValues
    >();

    const [costItemAttributeEditorItems, setCostItemAttributeEditorItems] =
        useState(
            formValues.cost_items?.map(getCostItemAttributeEditorItem) || [],
        );
    const configRef = useRef(
        getEditOperationsAndRegistrationValidationConfig(
            !!costItemAttributeEditorItems?.length,
        ),
    );
    const [submitting, setSubmitting] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);

    const handleFieldEvent = useCallback((changes: Partial<ActivityData>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    }, []);

    useEffect(() => {
        removeAllNotifications();
    }, [removeAllNotifications]);

    const handleScrollToError = () => {
        // this may require attention later for consistent experience in all browsers
        const topMostError = document.querySelector(`.${FORM_ERROR_SELECTOR}`);

        topMostError?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const navigateToDetailPage = () => {
        history.push({
            pathname: `/activities/${match.params.id}`,
        });
    };

    const handleCostItemEvent = (
        costItems: Array<CostItemAttributeEditorItem>,
    ) => {
        configRef.current = getEditOperationsAndRegistrationValidationConfig(
            !!costItems.length,
        );
        setCostItemAttributeEditorItems(costItems);
    };

    const handleSubmitEvent = async () => {
        setSubmitting(true);
        const invalid = validateForm(formValues, configRef.current);
        const arrayInvalid = validateFormControlArray(
            { costItemAttributeEditorItems },
            costAttributeValidationConfig,
        );
        const isActivityFormInvalid = invalid || arrayInvalid;

        if (isActivityFormInvalid) {
            handleScrollToError();
            setSubmitting(false);
        } else {
            const activityData: ActivityData = {
                ...formValues,
                cost_items: costItemAttributeEditorItems.map(getCostItem),
                additional_owners: formValues.additional_owners
                    ? formValues.additional_owners.map((owner) => {
                          return {
                              additional_owner_email:
                                  owner.additional_owner_email,
                              additional_owner_name:
                                  owner.additional_owner_name,
                          };
                      })
                    : [],
            };
            const isSuccessful = await dispatch<any>(
                updateSelectedActivity(match.params.id, activityData),
            );

            addNotification({
                id: `update-activity`,
                ...(isSuccessful
                    ? {
                          type: 'success',
                          content: 'The activity has been updated.',
                      }
                    : {
                          type: 'error',
                          content:
                              'An error occurred while updating the activity.',
                      }),
            });

            if (isSuccessful) {
                navigateToDetailPage();
            }
        }
    };

    const editOperationsAndRegistrationFormSectionProps: EditOpsAndRegFormSectionProps<
        Omit<ActivityData, 'cost_items'>
    > = {
        formValues,
        errors,
        controlArrayErrors,
        handleFieldEvent,
        handleCostItemEvent,
        costItemAttributeEditorItems,
        initialFormState,
    };

    const cancelModalProps: CancelModalProps = {
        cancelModalVisible,
        setCancelModalVisible,
        submitting,
        onCancelConfirm: navigateToDetailPage,
        testPrefix: 'EditOperationsAndRegistrationDetails',
    };

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, configRef.current);
        }

        if (isControlArrayInvalid) {
            validateFormControlArray(
                { costItemAttributeEditorItems },
                costAttributeValidationConfig,
            );
        }
    }, [
        isInvalid,
        formValues,
        validateForm,
        isControlArrayInvalid,
        validateFormControlArray,
        costItemAttributeEditorItems,
    ]);

    return (
        <>
            <Form
                header={formValues.activity_name}
                data-testid="ActivityEditOperationsAndRegistrationDetailsForm"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-activity-cancel"
                                data-testid="EditOperationsAndRegistrationDetailsCancel"
                                disabled={submitting}
                                onClick={() => {
                                    setCancelModalVisible(true);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-activity-save"
                                data-testid="EditOperationsAndRegistrationDetailsSave"
                                disabled={submitting}
                                loading={isLoading}
                                onClick={handleSubmitEvent}
                            >
                                {submitting ? 'Saving' : 'Save'}
                            </Button>
                        </div>
                    </div>
                }
            >
                <EditOperationsAndRegistrationDetailsFormSection
                    {...editOperationsAndRegistrationFormSectionProps}
                />
            </Form>
            <CancelModal {...cancelModalProps} />
        </>
    );
};
export default EditOperationsAndRegistrationDetailsForm;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getActivityAudiencesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllActivityAudiences,
    selectError,
    updateActivityAudience,
    selectSelectedActivityAudience,
    setSelectedActivityAudience,
    addActivityAudience,
    selectCount,
    resetActivityAudiencesSlice,
} from '../../../common/store/slices/activityAudiencesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { ActivityAudienceItemData } from '../../../common/interfaces/businessDataItem/activityAudienceItem';

const ACTIVITY_AUDIENCE_TYPES_TABLE_TITLE = 'Activity audience types';
const ACTIVITY_AUDIENCE_TYPES_DISPLAY_SINGULAR = 'Activity audience type';
export const ACTIVITY_AUDIENCE_TYPE_NAME_KEY = 'activity_audience';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (activityAudience: ActivityAudienceItemData) =>
            formatStatus(activityAudience.active.toString()),
    },
];

/**
 * Editable form fields for ActivityAudience
 * The keys must map to ActivityAudienceItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<ActivityAudienceItemData> =
    {
        // the keys must match ActivityAudienceItem properties
        activity_audience: {
            type: AdminBusinessDataFormInputType.Text,
            label: `${ACTIVITY_AUDIENCE_TYPES_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (
                val: string,
            ): Partial<ActivityAudienceItemData> => {
                return { activity_audience: val };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<ActivityAudienceItemData> => {
                return { active: parseBoolean(val) };
            },
        },
    };

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<ActivityAudienceItemData> =
    {
        ...createFormSchema,
        activity_audience: {
            ...createFormSchema.activity_audience,
            disabled: true,
        },
    };

const AdminActivityAudienceTypes = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useActivityAudienceTypes(): AdminBusinessDataSelectors<ActivityAudienceItemData> {
        const dispatch = useDispatch();

        const items: ActivityAudienceItemData[] = useSelector(
            selectAllActivityAudiences,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: ActivityAudienceItemData | null = useSelector(
            selectSelectedActivityAudience,
        );
        useEffect(() => {
            dispatch(getActivityAudiencesList());
            dispatch(setSelectedActivityAudience(null));

            return () => {
                dispatch(resetActivityAudiencesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (
        activityAudience: ActivityAudienceItemData,
    ) => {
        if (activityAudience) {
            dispatch(
                setSelectedActivityAudience(activityAudience.activity_audience),
            );
            await dispatch(getActivityAudiencesList());
        }
    };

    const handleItemUpdate = async (data: ActivityAudienceItemData) => {
        dispatch(setSelectedActivityAudience(null));
        const dispatchPromise = dispatch<any>(updateActivityAudience(data));
        await handleBusinessDataNotification({
            businessDataType: ACTIVITY_AUDIENCE_TYPE_NAME_KEY,
            businessDataDisplayNameLower:
                ACTIVITY_AUDIENCE_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.activity_audience,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        activity_audience,
        active,
    }: ActivityAudienceItemData) => {
        const dispatchPromise = dispatch<any>(
            addActivityAudience(active, activity_audience),
        );
        await handleBusinessDataNotification({
            businessDataType: ACTIVITY_AUDIENCE_TYPE_NAME_KEY,
            businessDataDisplayNameLower:
                ACTIVITY_AUDIENCE_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: activity_audience,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementActivityAudienceTypes">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={
                    ACTIVITY_AUDIENCE_TYPES_DISPLAY_SINGULAR
                }
                itemNameKey={ACTIVITY_AUDIENCE_TYPE_NAME_KEY}
                title={ACTIVITY_AUDIENCE_TYPES_TABLE_TITLE}
                useItemList={useActivityAudienceTypes}
            />
        </div>
    );
};

export default AdminActivityAudienceTypes;

import React, { useState } from 'react';
import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    Container,
    Spinner,
} from '@amzn/awsui-components-react-v3';
import FullCalendar from '@fullcalendar/react';
import CalendarResourceView from '../../../Common/ResourceCalendar/CalendarView';
import {
    InstructorCalendarHeaderPropsData,
    AssignInstructorModalPropsData,
    CalendarViewPropsData,
} from '../../../../interfaces/resourceCalendarProps';
import { RecommendedInstructorProfileData } from '../../../../interfaces/recommendedInstructorProfile';
import CalendarHeader from '../../../Common/ResourceCalendar/Header';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';

import ValidateBackToBackActivitiesModal from '../../../Common/ValidationModals/ValidateBackToBackActivitiesModal';
import ValidateReassignInstructorModal from '../../../Common/ValidationModals/ValidateReassignInstructorModal';
import ValidateInstructorBusyModal from '../../../Common/ValidationModals/ValidateInstructorBusyModal';
import ValidateInstructorQualificationsModal from '../../../Common/ValidationModals/ValidateInstructorQualificationsModal';

import './AssignInstructorModal.scss';
import {
    validateInstructorActivityQualifications,
    validateInstructorsForActivity,
} from '../../../../services/activity-service';
import { InstructorAssignmentConflict } from '../../Common/Common';
import { formatUnixTimeInTimezone } from '../../../../../common/utils/date-time.utils';
import { useSelector } from 'react-redux';
import { selectCount } from '../../../../store/slices/recommendedInstructorsSlice';

const ASSIGN_INSTRUCTOR_TEXT = 'Assign instructor';

const AssignInstructorModal = ({
    isVisible,
    handleIsVisibleChange,
    deliverySessions,
    activityTimezone,
    handleAssignInstructorEvent,
    handleQueryChange,
    filteringOptions,
    instructorFilteringProperties,
    query,
    areRecommendedInstructorsLoading,
    initialDate,
    handleFieldEvent,
    formValues,
    paginationProps,
    handlePreferencesChange,
    ...assignInstructorProps
}: AssignInstructorModalPropsData) => {
    const [calendarRef, setCalendarRef] = useState<FullCalendar>(
        {} as FullCalendar,
    );
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNoShuffleModal, setShowNoShuffleModal] = useState(false);
    const [showQualificationsModal, setShowQualificationsModal] =
        useState(false);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const [reassignReason, setReassignReason] =
        useState<OptionDefinition | null>(null);
    const [selectedInstructors, setSelectedInstructors] = useState(
        new Set<RecommendedInstructorProfileData>(),
    );
    const [instructorConflict, setInstructorConflict] =
        useState<InstructorAssignmentConflict | null>(null);
    const [missingQualification, setMissingQualification] =
        useState<Array<string> | null>(null);
    const handleSelectedInstructorsChange = (
        instructors: Set<RecommendedInstructorProfileData>,
    ) => {
        setSelectedInstructors(new Set(instructors));
    };
    const count = useSelector(selectCount);

    const assignInstructorResourceViewProps: CalendarViewPropsData = {
        handleCalendarRefChange: (calendarRef: FullCalendar) =>
            setCalendarRef(calendarRef),
        selectedInstructors,
        handleSelectedInstructorsChange,
        deliverySessions,
        ...assignInstructorProps,
        initialDate,
        resourceLabelType: 'radio',
        calendarHeight: 500,
    };

    const handleConfirmAssignment = () => {
        handleAssignInstructorEvent(Array.from(selectedInstructors));
        if (reassignReason) {
            handleFieldEvent({
                instructor_assignment_override_reason: reassignReason.label,
            });
        }
        setSelectedInstructors(new Set<RecommendedInstructorProfileData>());
        handleIsVisibleChange(false);
        setShowConflictModal(false);
        setShowNoShuffleModal(false);
        setShowConfirmModal(false);
        setShowQualificationsModal(false);
    };

    const validateAssignInstructor = async () => {
        const instructors = Array.from(selectedInstructors);
        for (let instructor of instructors) {
            if (!instructor) continue;
            const { pk: instructorPk } = instructor;
            try {
                let validationResponse = await validateInstructorsForActivity({
                    instructors: [
                        {
                            pk: instructorPk,
                            name: null,
                            email: null,
                            role: null,
                            type: null,
                            location: null,
                            do_not_shuffle: null,
                        },
                    ],
                    delivery_sessions: deliverySessions,
                    id: assignInstructorProps.events[0].id,
                    delivery_country: formValues.delivery_country,
                    delivery_language: formValues.delivery_language,
                    course_name: formValues.course_name,
                    delivery_timezone: formValues.delivery_timezone,
                    auto_assign_instructor: formValues.auto_assign_instructor,
                });

                for (let instructorValidationResponse of validationResponse) {
                    // check for reserved or do not shuffle activity
                    const {
                        availability,
                        back_to_back_activities,
                        booked_activities,
                    } = instructorValidationResponse;

                    if (['do_not_shuffle', 'reserved'].includes(availability)) {
                        setShowNoShuffleModal(true);
                        setInstructorConflict({
                            instructor: instructorValidationResponse,
                            activity: null,
                        });
                        return;
                    }

                    // check for confirm / reassign
                    if (booked_activities.length > 0) {
                        setInstructorConflict({
                            instructor: instructorValidationResponse,
                            activity: booked_activities[0],
                        });
                        setShowConfirmModal(true);
                        return;
                    }

                    // check for adjacent activities
                    if (back_to_back_activities.length > 0) {
                        setInstructorConflict({
                            instructor: instructorValidationResponse,
                            activity: back_to_back_activities[0],
                        });
                        setShowConflictModal(true);
                        return;
                    }
                }

                // ensure instructor has the required language and
                const { missingQualifications, instructor } =
                    validateInstructorActivityQualifications(
                        validationResponse,
                    );

                if (missingQualifications.length > 0 && instructor) {
                    setMissingQualification(missingQualifications);
                    setInstructorConflict({
                        instructor,
                        activity: null,
                    });
                    setShowQualificationsModal(true);
                    return;
                }
            } catch (e) {}
        }

        handleConfirmAssignment();
    };

    const assignInstructorHeaderProps: InstructorCalendarHeaderPropsData = {
        calendarRef,
        handleQueryChange,
        filteringOptions,
        instructorFilteringProperties,
        query,
        instructorCountText: `${count} match${count > 1 ? 'es' : ''}`,
        filteringPlaceholder: 'Search',
        paginationProps,
        count,
        handlePreferencesChange,
    };

    return (
        <>
            <Modal
                className="assign-instructor-modal"
                onDismiss={() => handleIsVisibleChange(false)}
                visible={isVisible}
                closeAriaLabel="Close modal"
                size="max"
                data-testid={`AssignInstructorModal`}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                variant="link"
                                onClick={() => handleIsVisibleChange(false)}
                                data-testid={`AssignInstructorCancel`}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                data-testid={`AssignInstructorConfirm`}
                                onClick={() => {
                                    validateAssignInstructor();
                                }}
                            >
                                {ASSIGN_INSTRUCTOR_TEXT}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={ASSIGN_INSTRUCTOR_TEXT}
            >
                <Box variant="p">
                    Select an instructor from the list below to assign them to
                    this activity
                </Box>
                <Box
                    color="text-status-inactive"
                    fontSize="body-m"
                    variant="div"
                    margin={{ bottom: 'm' }}
                >
                    <SpaceBetween direction="horizontal" size="xl">
                        <span
                            data-testid={`AssignInstructorModalDeliverySessionDetails`}
                        >
                            Session date(s):{' '}
                            {deliverySessions
                                .filter((session) => {
                                    return (
                                        session.start_timestamp &&
                                        session.end_timestamp
                                    );
                                })
                                .map((session) =>
                                    formatUnixTimeInTimezone({
                                        timestamp:
                                            session.start_timestamp as number,
                                        timezone: activityTimezone,
                                        format: 'MMM D',
                                    }),
                                )
                                .join(', ')}
                        </span>
                        <span
                            data-testid={`AssignInstructorModalActivityTimezone`}
                        >
                            Timezone: {activityTimezone}
                        </span>
                    </SpaceBetween>
                </Box>
                <Container
                    disableContentPaddings
                    header={<CalendarHeader {...assignInstructorHeaderProps} />}
                >
                    {((loading: boolean) => {
                        if (loading) {
                            return (
                                <Box
                                    textAlign="center"
                                    padding={{ vertical: 'l' }}
                                >
                                    <Spinner data-testid="AssignInstructorModalSpinner" />
                                </Box>
                            );
                        } else if (!assignInstructorProps.resources.length) {
                            return (
                                <Box
                                    data-testid={`AssignInstructorModalEmptyState`}
                                    textAlign="center"
                                    padding={{ vertical: 'l' }}
                                >
                                    <Box
                                        color="text-status-inactive"
                                        fontSize="body-m"
                                        variant="strong"
                                        display="block"
                                        margin={{ bottom: 'xs' }}
                                    >
                                        Sorry, we didn't find any results.
                                    </Box>
                                    <Box
                                        color="text-status-inactive"
                                        margin={{ bottom: 's' }}
                                        fontSize="body-m"
                                    >
                                        Use fewer or different filters.
                                    </Box>
                                    <Button
                                        data-testid={`AssignInstructorModalClearFiltersButton`}
                                        variant="normal"
                                        onClick={() => {
                                            handleQueryChange({
                                                tokens: [],
                                                operation: 'and',
                                            });
                                        }}
                                    >
                                        Clear filters
                                    </Button>
                                </Box>
                            );
                        } else {
                            return (
                                <CalendarResourceView
                                    {...assignInstructorResourceViewProps}
                                />
                            );
                        }
                    })(areRecommendedInstructorsLoading)}
                </Container>
            </Modal>
            <ValidateBackToBackActivitiesModal
                instructorConflict={instructorConflict}
                visible={showConflictModal}
                onCancel={() => {
                    setShowConflictModal(false);
                }}
                onConfirm={() => {
                    handleConfirmAssignment();
                }}
            />
            <ValidateReassignInstructorModal
                instructorConflict={instructorConflict}
                visible={showConfirmModal}
                onCancel={() => {
                    setShowConfirmModal(false);
                }}
                onConfirm={() => {
                    handleConfirmAssignment();
                }}
                reassignReason={reassignReason}
                setReassignReason={setReassignReason}
            />
            <ValidateInstructorBusyModal
                instructorConflict={instructorConflict}
                onCancel={() => setShowNoShuffleModal(false)}
                visible={showNoShuffleModal}
            />
            <ValidateInstructorQualificationsModal
                instructorConflict={instructorConflict}
                onCancel={() => setShowQualificationsModal(false)}
                visible={showQualificationsModal}
                missingQualifications={missingQualification}
                onConfirm={() => {
                    handleConfirmAssignment();
                }}
                formValues={formValues}
            />
        </>
    );
};

export default AssignInstructorModal;

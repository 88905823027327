import { useState } from 'react';
import {
    Box,
    Button,
    FormField,
    Input,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { ActivityData } from '../../../../interfaces/activity';

interface RemoveSFDCCustomerModalProps {
    activeRemoveIndex: number;
    setActiveRemoveIndex: (value: React.SetStateAction<number>) => void;
    activity: ActivityData;
    handleRemoveCustomer: () => Promise<void>;
}

const RemoveSFDCCustomerModal = ({
    activeRemoveIndex,
    setActiveRemoveIndex,
    activity,
    handleRemoveCustomer,
}: RemoveSFDCCustomerModalProps) => {
    const [removeCustomerError, setRemoveCustomerError] =
        useState<boolean>(false);
    const [removeCustomerConfirmText, setRemoveCustomerConfirmText] =
        useState<string>('');

    const onRemoveCustomer = () => {
        if (removeCustomerConfirmText.toLowerCase() === `remove customer`) {
            setRemoveCustomerError(false);
            handleRemoveCustomer();
        } else {
            setRemoveCustomerError(true);
        }
    };

    return (
        <Modal
            size={'medium'}
            visible={activeRemoveIndex !== null}
            onDismiss={() => {
                setActiveRemoveIndex(null);
            }}
            header={'Remove this customer?'}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => {
                                setActiveRemoveIndex(null);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={onRemoveCustomer}>
                            Remove customer
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <p>
                Are you sure you want to remove{' '}
                {activity.customers[activeRemoveIndex].customer_name} from this
                activity? If you remove this customer, the product will be
                cancelled on SFDC. This cannot be undone.
            </p>
            <div className="grimsby-sub-section-divider" />
            <FormField
                label={`To continue, enter "REMOVE CUSTOMER" and press the remove customer button.`}
                errorText={
                    removeCustomerError
                        ? `This field is required to remove customer`
                        : null
                }
            >
                <Input
                    value={removeCustomerConfirmText}
                    placeholder="Enter confirmation message"
                    onChange={(e) => {
                        setRemoveCustomerConfirmText(e.detail.value);
                    }}
                />
            </FormField>
        </Modal>
    );
};

export default RemoveSFDCCustomerModal;

import { Spinner } from '@amzn/awsui-components-react-v3';
import React from 'react';
import './Loader.scss';

const Loader = ({
    isLoading,
    children,
    backgroundColor,
}: {
    isLoading: boolean;
    backgroundColor?: string;
    children: JSX.Element;
}) => {
    return isLoading ? (
        <div
            className="loading-wrapper"
            data-testid="loader"
            style={{ backgroundColor }}
        >
            <Spinner size="large" />
        </div>
    ) : (
        children
    );
};

export default Loader;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PartnerInitiativesResponseData } from '../../interfaces/businessDataResponse/partnerInitiativesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { PartnerInitiativeItemData } from '../../interfaces/businessDataItem/partnerInitiativeItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * partnerInitiativesSlice manages all partnerInitiatives state, and contains partnerInitiatives actions as well as partnerInitiatives state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const partnerInitiativesSlice = createSlice({
    name: 'partnerInitiatives',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<PartnerInitiativeItemData>,
    reducers: {
        setPartnerInitiativesList: (
            state,
            action: PayloadAction<PartnerInitiativeItemData[]>,
        ) => {
            const byPartnerInitiativeName = action.payload.reduce(
                (
                    byPartnerInitiativeName: {
                        [key: string]: PartnerInitiativeItemData;
                    },
                    partnerInitiative: PartnerInitiativeItemData,
                ) => {
                    byPartnerInitiativeName[
                        partnerInitiative.partner_initiative
                    ] = {
                        ...partnerInitiative,
                        active: parseBoolean(partnerInitiative.active),
                    };
                    return byPartnerInitiativeName;
                },
                {},
            );
            state.entities = byPartnerInitiativeName;
            state.keysList = Object.keys(byPartnerInitiativeName);
        },
        setPartnerInitiative: (
            state,
            action: PayloadAction<PartnerInitiativeItemData>,
        ) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.partner_initiative]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.partner_initiative,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.partner_initiative] = action.payload;
        },
        setSelectedPartnerInitiative: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPartialPartnerInitiativesSlice: resetBasicBusinessDataStoreState,
        resetPartnerInitiativesSlice: resetExtendedBusinessDataStoreState,
        initializePartnerInitiativesListQueryParams:
            initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setPartnerInitiativesList,
    setPartnerInitiative,
    setSelectedPartnerInitiative,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    resetPartialPartnerInitiativesSlice,
    resetPartnerInitiativesSlice,
    initializePartnerInitiativesListQueryParams,
} = partnerInitiativesSlice.actions;

const handlePartnerInitiativesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: partner_initiative,
            active,
        } = state.partnerInitiatives;

        const params: BusinessDataApiQueryParams.GetPartnerInitiatives = {
            active,
        };

        // if both "from" and "size" equal to 0, avoid adding them into the query parameters
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (partner_initiative) {
            params.partner_initiative = partner_initiative;
        }

        try {
            const {
                result: { PARTNER_INITIATIVE, total_count },
            }: HandleRequestData<PartnerInitiativesResponseData> =
                await businessDataApi.getPartnerInitiatives(params);

            dispatch(setPartnerInitiativesList(PARTNER_INITIATIVE));
            dispatch(setCount(total_count));
            dispatch(setPagesCount(Math.ceil(total_count / size)));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getPartnerInitiatives API error'),
            );
        }
    };
};

export const getPartnerInitiativesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handlePartnerInitiativesListRequest());
        if (!state.partnerInitiatives.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update initiative
 * before update api call, fetch program types and prepare payload containing only the changed fields
 * after update api call, fetch program types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updatePartnerInitiative = (data: PartnerInitiativeItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new initiative: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updatePartnerInitiative(data.pk, data.active);
            await dispatch(handlePartnerInitiativesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setPartnerInitiative({
                    ...state.partnerInitiatives.entities[
                        data.partner_initiative
                    ],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addPartnerInitiative = (
    active = true,
    partnerInitiativeName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addPartnerInitiative(
                partnerInitiativeName,
                active,
            );
            // now refresh the keysList
            await dispatch(handlePartnerInitiativesListRequest());
            if (!state.partnerInitiatives?.entities[partnerInitiativeName]) {
                // shove the item into the keysList, but only if it's not there already
                const newPartnerInitiativeData: PartnerInitiativeItemData = {
                    partner_initiative: partnerInitiativeName,
                    active: active,
                };
                dispatch(setPartnerInitiative(newPartnerInitiativeData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllPartnerInitiatives = (state: GlobalState) => {
    return state.partnerInitiatives.keysList.map(
        (partnerInitiativeName) =>
            state.partnerInitiatives.entities[partnerInitiativeName],
    );
};

export const selectAllActivePartnerInitiatives = (state: GlobalState) => {
    return getActiveBusinessDataValues(
        state.partnerInitiatives.keysList,
        state.partnerInitiatives.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.partnerInitiatives.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.partnerInitiatives.isLoaded;

export const selectSelectedPartnerInitiative = (state: GlobalState) => {
    return state?.partnerInitiatives?.selectedItemKey
        ? state?.partnerInitiatives?.entities[
              state?.partnerInitiatives?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) =>
    state.partnerInitiatives.error;

export const selectCount = (state: GlobalState) =>
    state.partnerInitiatives.count;

export const selectSearchText = (state: GlobalState) =>
    state.partnerInitiatives.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.partnerInitiatives.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.partnerInitiatives.currentPageIndex;

export default partnerInitiativesSlice.reducer;

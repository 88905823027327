import { useEffect } from 'react';
import {
    ColumnLayout,
    Container,
    FormField,
    SelectProps,
    Header,
    Input,
    Multiselect,
    Select,
    DatePicker,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import {
    ProgramFormFieldText,
    CertificationFormFieldText,
    DomainFormFieldText,
    CategoryFormFieldText,
    SkillLevelFieldText,
    CourseContentFieldText,
    CourseLocalization,
} from '../../../../common/constants/forms';
import { FormSectionProps } from '../../../../common/interfaces/formSectionProps';
import { CourseFormData } from '../../../interfaces/courseForm';
import { useDispatch, useSelector } from 'react-redux';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInputPolarisV3,
    getStatusType,
} from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import { ProgramTypeItemData } from '../../../../common/interfaces/businessDataItem/programTypeItem';
import { CourseDomainItemData } from '../../../../common/interfaces/businessDataItem/courseDomainItem';
import { CourseCategoryItemData } from '../../../../common/interfaces/businessDataItem/courseCategoryItem';
import {
    getProgramTypesList,
    selectActivePlusUserSelections as selectProgramList,
    selectError as selectProgramListError,
    selectIsLoaded as selectIsProgramListLoaded,
    selectIsLoading as selectIsProgramListLoading,
} from '../../../../common/store/slices/programTypesSlice';

import {
    getCourseDomainList,
    selectAllActiveCourseDomains as selectCourseDomains,
    selectError as selectDomainListError,
    selectIsLoaded as selectIsDomainListLoaded,
    selectIsLoading as selectIsDomainListLoading,
} from '../../../../common/store/slices/courseDomainsSlice';

import {
    getCourseCategoryList,
    selectAllActiveCourseCategories as selectCourseCategories,
    selectError as selectCategoryListError,
    selectIsLoaded as selectIsCategoryListLoaded,
    selectIsLoading as selectIsCategoryListLoading,
} from '../../../../common/store/slices/courseCategoriesSlice';

import { CertificationItemData } from '../../../../common/interfaces/businessDataItem/certificationItem';
import {
    getCertificationsList,
    selectActivePlusCourseSelection as selectCertificationList,
    selectError as selectCertificationsListError,
    selectIsLoading as selectIsCertificationsListLoading,
} from '../../../../common/store/slices/certificationsSlice';
import {
    courseContentOptions,
    courseLocales,
    courseSkillLevelOptions,
} from '../Common';

const parseValuesIntoSelectedOptions = (
    values: Array<string>,
    lookup: { [p: string]: SelectProps.Option },
) => {
    return values.reduce((acc: Array<SelectProps.Option>, program: string) => {
        if (lookup[program]) {
            acc.push(lookup[program]);
        }
        return acc;
    }, []);
};

export const AdminCoursesFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    name,
    mode,
}: Omit<FormSectionProps<CourseFormData>, 'validateAndHandleFieldEvent'> & {
    name: string;
}) => {
    const isCertificationListLoading = useSelector(
        selectIsCertificationsListLoading,
    );
    const isCertificationListLoaded = useSelector(selectIsProgramListLoaded);
    const certificationListError = useSelector(selectCertificationsListError);
    const certificationList = useSelector(selectCertificationList);

    const isProgramListLoading = useSelector(selectIsProgramListLoading);
    const isProgramListLoaded = useSelector(selectIsProgramListLoaded);
    const programListError = useSelector(selectProgramListError);
    const programList = useSelector(selectProgramList);

    const isCategoryListLoading = useSelector(selectIsCategoryListLoading);
    const isCategoryListLoaded = useSelector(selectIsCategoryListLoaded);
    const categoryListError = useSelector(selectCategoryListError);
    const categoryList = useSelector(selectCourseCategories);

    const isDomainListLoading = useSelector(selectIsDomainListLoading);
    const isDomainListLoaded = useSelector(selectIsDomainListLoaded);
    const domainListError = useSelector(selectDomainListError);
    const domainList = useSelector(selectCourseDomains);
    const dispatch = useDispatch();

    const businessDataInitList: ReadonlyArray<[boolean, () => Function]> = [
        [!isProgramListLoaded && !isProgramListLoading, getProgramTypesList],
        [!isDomainListLoaded && !isDomainListLoading, getCourseDomainList],
        [
            !isCategoryListLoaded && !isCategoryListLoading,
            getCourseCategoryList,
        ],
        [
            !isCertificationListLoaded && !isCertificationListLoading,
            getCertificationsList,
        ],
    ];

    useEffect(() => {
        businessDataInitList.forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    }, []);

    const { valueLookup: domainLookup, valueOptions: domainOptions } =
        getOptionsAndLookupForSelectInputPolarisV3<CourseDomainItemData>(
            domainList,
            (domain: CourseDomainItemData) => ({
                label: domain.course_domain,
                value: domain.pk as string,
            }),
        );

    const { valueLookup: categoryLookup, valueOptions: categoryOptions } =
        getOptionsAndLookupForSelectInputPolarisV3<CourseCategoryItemData>(
            categoryList,
            (category: CourseCategoryItemData) => ({
                label: category.course_category,
                value: category.pk as string,
            }),
        );

    const { valueLookup: programLookup, valueOptions: programOptions } =
        getOptionsAndLookupForSelectInputPolarisV3<ProgramTypeItemData>(
            programList,
            (program: ProgramTypeItemData) => ({
                label: program.program_type,
                value: program.pk as string,
            }),
        );

    const {
        valueLookup: certificationLookup,
        valueOptions: certificationOptions,
    } = getOptionsAndLookupForSelectInputPolarisV3<CertificationItemData>(
        certificationList,
        (certification: CertificationItemData) => ({
            label: certification.certification,
            value: certification.pk as string,
        }),
    );

    const skillLevelOptions = courseSkillLevelOptions.map((option) => ({
        label: option,
        value: option,
    }));

    const contentOptions = courseContentOptions.map((option) => ({
        label: option,
        value: option,
    }));

    const localesOptions = courseLocales.map((option) => ({
        label: option,
        value: option,
    }));

    return (
        <Container
            data-testid="AdminCoursesFormSection"
            header={<Header>{name}</Header>}
        >
            <SpaceBetween direction="vertical" size="xxl">
                <ColumnLayout columns={1}>
                    <FormField
                        label="Course name"
                        errorText={errors?.course}
                        data-testid={`AdminCourses${mode}CourseNameFormfield`}
                    >
                        <Input
                            className={errors?.course && FORM_ERROR_SELECTOR}
                            value={formValues.course}
                            onChange={(e) =>
                                handleFieldEvent({
                                    course: e.detail.value,
                                })
                            }
                            data-testid={`AdminCoursesEditCourseName`}
                        />
                    </FormField>
                    <FormField
                        label="Course short name"
                        errorText={errors?.short_name}
                    >
                        <Input
                            className={
                                errors?.short_name && FORM_ERROR_SELECTOR
                            }
                            value={formValues.short_name}
                            onChange={(e) =>
                                handleFieldEvent({
                                    short_name: e.detail.value,
                                })
                            }
                            data-testid={`AdminCourses${mode}ShortName`}
                        />
                    </FormField>
                    <FormField
                        label="Course Days"
                        errorText={errors?.course_days}
                    >
                        <Input
                            className={
                                errors?.course_days && FORM_ERROR_SELECTOR
                            }
                            value={formValues.course_days}
                            placeholder="Number of days"
                            onChange={(e) =>
                                handleFieldEvent({
                                    course_days: e.detail.value,
                                })
                            }
                            data-testid={`AdminCourses${mode}CourseDays`}
                        />
                    </FormField>
                    <FormField label="SKU" errorText={errors?.sku_id}>
                        <Input
                            className={errors?.sku_id && FORM_ERROR_SELECTOR}
                            placeholder="Course SKU Id"
                            value={formValues.sku_id}
                            onChange={(e) =>
                                handleFieldEvent({
                                    sku_id: e.detail.value,
                                })
                            }
                            data-testid={`AdminCourses${mode}SKU`}
                        />
                    </FormField>
                    <FormField
                        label="Expiry Date"
                        errorText={errors?.expiry_date}
                    >
                        <DatePicker
                            onChange={({ detail }) =>
                                handleFieldEvent({
                                    expiry_date: detail.value,
                                })
                            }
                            value={
                                formValues?.expiry_date
                                    ? formValues.expiry_date
                                    : ''
                            }
                            openCalendarAriaLabel={(selectedDate) =>
                                'Choose course expiry date' +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : '')
                            }
                            placeholder="YYYY/MM/DD"
                            data-testid={`AdminCourses${mode}ExpiryDate`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.course_domain}
                        label="Course Domain"
                    >
                        <Select
                            className={
                                errors?.course_domain && FORM_ERROR_SELECTOR
                            }
                            data-testid={`AdminCourses${mode}SelectCourseDomain`}
                            options={domainOptions}
                            placeholder={
                                isDomainListLoading
                                    ? DomainFormFieldText.Loading
                                    : DomainFormFieldText.Placeholder
                            }
                            selectedOption={
                                domainLookup[formValues.course_domain as string]
                            }
                            onChange={(event) =>
                                handleFieldEvent({
                                    course_domain:
                                        event.detail.selectedOption.label,
                                })
                            }
                            loadingText={DomainFormFieldText.Loading}
                            errorText={DomainFormFieldText.Error}
                            empty={DomainFormFieldText.Empty}
                            statusType={getStatusType(
                                isDomainListLoading,
                                isDomainListLoaded,
                                domainListError,
                            )}
                            disabled={isDomainListLoading}
                        />
                    </FormField>
                    <FormField label="Course Modality">
                        <Input
                            value={formValues.course_modality ?? 'ILT'}
                            disabled
                        ></Input>
                    </FormField>
                    <FormField
                        errorText={errors?.course_category}
                        label="Course Category"
                    >
                        <Select
                            className={
                                errors?.course_category && FORM_ERROR_SELECTOR
                            }
                            data-testid={`AdminCourses${mode}SelectCourseCategory`}
                            options={categoryOptions}
                            placeholder={
                                isCategoryListLoading
                                    ? CategoryFormFieldText.Loading
                                    : CategoryFormFieldText.Placeholder
                            }
                            selectedOption={
                                isCategoryListLoaded &&
                                !isCategoryListLoading &&
                                formValues?.course_category
                                    ? categoryLookup[formValues.course_category]
                                    : null
                            }
                            onChange={(event) =>
                                handleFieldEvent({
                                    course_category:
                                        event.detail.selectedOption.label,
                                })
                            }
                            loadingText={CategoryFormFieldText.Loading}
                            errorText={CategoryFormFieldText.Error}
                            empty={CategoryFormFieldText.Empty}
                            statusType={getStatusType(
                                isCategoryListLoading,
                                isCategoryListLoaded,
                                categoryListError,
                            )}
                            disabled={isCategoryListLoading}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.course_skill_level}
                        label="Course Skill Level"
                    >
                        <Select
                            className={
                                errors?.course_skill_level &&
                                FORM_ERROR_SELECTOR
                            }
                            data-testid={`AdminCourses${mode}SelectCourseSkillLevel`}
                            options={skillLevelOptions}
                            placeholder={SkillLevelFieldText.Placeholder}
                            selectedOption={
                                formValues.course_skill_level
                                    ? {
                                          value: formValues.course_skill_level,
                                          label: formValues.course_skill_level,
                                      }
                                    : null
                            }
                            onChange={(event) =>
                                handleFieldEvent({
                                    course_skill_level:
                                        event.detail.selectedOption.label,
                                })
                            }
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.course_content}
                        label="Course Content"
                    >
                        <Multiselect
                            className={
                                errors?.course_content && FORM_ERROR_SELECTOR
                            }
                            data-testid={`AdminCourses${mode}SelectCourseContent`}
                            options={contentOptions}
                            placeholder={CourseContentFieldText.Placeholder}
                            selectedOptions={
                                formValues.course_content
                                    ? formValues.course_content.map(
                                          (option) => ({
                                              value: option,
                                              label: option,
                                          }),
                                      )
                                    : []
                            }
                            onChange={(event) =>
                                handleFieldEvent({
                                    course_content:
                                        event.detail.selectedOptions.map(
                                            (option) => option.value,
                                        ),
                                })
                            }
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.locales}
                        label="Course Localization"
                    >
                        <Multiselect
                            className={errors?.locales && FORM_ERROR_SELECTOR}
                            data-testid={`AdminCourses${mode}SelectCourseLocalization`}
                            options={localesOptions}
                            placeholder={CourseLocalization.Placeholder}
                            selectedOptions={
                                !!formValues.locales
                                    ? formValues.locales.map((option) => ({
                                          value: option,
                                          label: option,
                                      }))
                                    : []
                            }
                            onChange={(event) =>
                                handleFieldEvent({
                                    locales: event.detail.selectedOptions.map(
                                        (option) => option.value,
                                    ),
                                })
                            }
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.associated_programs}
                        label="Associated Programs"
                    >
                        <Multiselect
                            className={
                                errors?.associated_programs &&
                                FORM_ERROR_SELECTOR
                            }
                            data-testid={`AdminCourses${mode}SelectPrograms`}
                            options={programOptions}
                            placeholder={
                                isProgramListLoading
                                    ? ProgramFormFieldText.Loading
                                    : ProgramFormFieldText.Placeholder
                            }
                            selectedOptions={
                                isProgramListLoaded && !isProgramListLoading
                                    ? parseValuesIntoSelectedOptions(
                                          formValues?.associated_programs || [],
                                          programLookup,
                                      )
                                    : []
                            }
                            selectedAriaLabel="Selected"
                            deselectAriaLabel={(programOption) =>
                                `Deselect ${programOption.label}`
                            }
                            onChange={(event) =>
                                handleFieldEvent({
                                    associated_programs:
                                        event.detail.selectedOptions.map(
                                            (program) => program.label,
                                        ),
                                })
                            }
                            loadingText={ProgramFormFieldText.Loading}
                            errorText={ProgramFormFieldText.Error}
                            empty={ProgramFormFieldText.Empty}
                            statusType={getStatusType(
                                isProgramListLoading,
                                isProgramListLoaded,
                                programListError,
                            )}
                            disabled={isProgramListLoading}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.associated_certifications}
                        label="Associated Certifications"
                    >
                        <Multiselect
                            className={
                                errors?.associated_certifications &&
                                FORM_ERROR_SELECTOR
                            }
                            data-testid={`AdminCourses${mode}SelectCertifications`}
                            options={certificationOptions}
                            placeholder={
                                isCertificationListLoading
                                    ? CertificationFormFieldText.Loading
                                    : CertificationFormFieldText.Placeholder
                            }
                            selectedOptions={
                                isCertificationListLoaded &&
                                !isCertificationListLoading
                                    ? parseValuesIntoSelectedOptions(
                                          formValues?.associated_certifications ||
                                              [],
                                          certificationLookup,
                                      )
                                    : []
                            }
                            selectedAriaLabel="Selected"
                            deselectAriaLabel={(certificationOption) =>
                                `Deselect ${certificationOption.label}`
                            }
                            onChange={(event) =>
                                handleFieldEvent({
                                    associated_certifications:
                                        event.detail.selectedOptions.map(
                                            (certification) =>
                                                certification.label,
                                        ),
                                })
                            }
                            loadingText={CertificationFormFieldText.Loading}
                            errorText={CertificationFormFieldText.Error}
                            empty={CertificationFormFieldText.Empty}
                            statusType={getStatusType(
                                isCertificationListLoading,
                                isCertificationListLoaded,
                                certificationListError,
                            )}
                            disabled={isCertificationListLoading}
                        />
                    </FormField>
                </ColumnLayout>
            </SpaceBetween>
        </Container>
    );
};

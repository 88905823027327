import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateActivityTypePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/activityTypePayload';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { ActivityTypesResponseData } from '../../interfaces/businessDataResponse/activityTypesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const ACTIVITY_TYPES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/activity-types`;

/**
 * Method to request the full list of activity types
 * @param {BusinessDataApiQueryParams.GetActivityTypes} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getActivityTypes = (
    queryParams?: BusinessDataApiQueryParams.GetActivityTypes,
): Promise<HandleRequestData<ActivityTypesResponseData>> => {
    let path = `${ACTIVITY_TYPES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<ActivityTypesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an activity type.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateActivityType = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${ACTIVITY_TYPES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

/**
 * Method to add an activity type.
 * @param {string} activityTypeName - the name of the new activity type
 * @param {boolean} active - the status of the new activity type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addActivityType = (
    activityTypeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateActivityTypePayloadData = {
        activity_type: activityTypeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${ACTIVITY_TYPES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const activityTypesApi = {
    getActivityTypes,
    updateActivityType,
    addActivityType,
};

export default activityTypesApi;

import { HandleRequestData } from '../interfaces/handleRequest';
import { AuditTrailResponseData } from '../interfaces/auditTrailResponse';
import handleGrimsbyApiRequest from '../utils/handleGrimsbyApiRequest';
import { API_MAIN_PATH } from '../constants/grimsby';
import { AuditManagementAPIQueryParams } from '../interfaces/queryParams';
import createQueryString from '../utils/createQueryString';

export const AUDIT_PATH = API_MAIN_PATH.AUDIT_MANAGEMENT;
/**
 * Method to request an instructor's audit trail
 * @return: A promise that resolves with an object containing the result or error of the profile request
 * @todo: descriptive failure growler, FE error logging(?)
 * @param queryParams
 */
const getAuditTrail = (
    queryParams?: AuditManagementAPIQueryParams.GetAuditTrailByIdOrAlias,
): Promise<HandleRequestData<AuditTrailResponseData>> => {
    let path = `${AUDIT_PATH}/audit-trail${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<AuditTrailResponseData>(path, {
        method: 'GET',
    });
};

const auditApi = {
    getAuditTrail,
};

export default auditApi;

import React, { useCallback, useState, useEffect } from 'react';
import {
    ActivityData,
    CommercialPrivateCustomer,
    Customer,
    Invoice,
    LineItem,
} from '../../../../interfaces/activity';
import {
    getSelectedActivity,
    selectIsLoading,
    selectSelectedActivity,
    updateSelectedActivity,
} from '../../../../store/slices/selectedActivitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';
import { useNotifications } from '../../../../../common/context/grimsbyNotifications';

import { CustomerFormSection } from './CustomerFormSection';
import useFormValidation from '../../../../../common/utils/formValidation';
import { saveCustomerValidationConfig } from '../../../../services/activity-service';
import { APNCustomerFormSection } from './APNCustomerFormSection';
import {
    isCommercialPrivateCustomer,
    isPartnerPrivateCustomer,
    isPublicCustomer,
} from '../../Common/Common';

const CustomerEditForm = () => {
    const isLoading = useSelector(selectIsLoading);
    const match = useRouteMatch<{ id: string; customerId: any }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const activity: ActivityData | undefined = useSelector(
        selectSelectedActivity,
    )?.activity;
    const { addNotification } = useNotifications();
    const initialFormState = {
        customer_name: '',
    } as Customer;

    const [formValues, setFormValues] = useState<Customer>(initialFormState);

    const { errors, validateForm } = useFormValidation<Customer>();

    const handleFieldEvent = useCallback((changes: Partial<Customer>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    }, []);

    useEffect(() => {
        if (activity && activity.customers) {
            setFormValues(activity.customers[match.params.customerId]);
        }
    }, [match.params.customerId, activity]);

    useEffect(() => {
        dispatch(getSelectedActivity(match.params.id));
    }, [dispatch, match.params.id]);

    const handleCreateCustomer = async () => {
        if (!!activity) {
            const invalid = validateForm(
                formValues,
                saveCustomerValidationConfig,
            );

            if (!invalid) {
                let updatedCustomers: Array<
                    Customer | CommercialPrivateCustomer
                > = [...activity.customers];

                const customerToEdit: Customer | CommercialPrivateCustomer = {
                    ...formValues,
                };

                updatedCustomers[match.params.customerId] = customerToEdit;

                const updatedInvoices = (activity.billing_invoices ?? []).map(
                    (invoiceObj) => {
                        let invoice = { ...invoiceObj } as Invoice;

                        const hasMatchingSFDCID =
                            !!(formValues as CommercialPrivateCustomer)
                                .sfdc_opportunity_id &&
                            invoice.sfdc_opportunity_id ===
                                (formValues as CommercialPrivateCustomer)
                                    .sfdc_opportunity_id;

                        const hasMatchingCustomerName =
                            !invoice.sfdc_opportunity_id &&
                            !formValues.sfdc_opportunity_id &&
                            invoice.customer_name ===
                                (formValues as CommercialPrivateCustomer)
                                    .customer_name;

                        if (hasMatchingSFDCID || hasMatchingCustomerName) {
                            let commercialPrivateCustomer = {
                                ...formValues,
                            } as CommercialPrivateCustomer;
                            if (commercialPrivateCustomer.sfdc_opportunity_id) {
                                invoice.sfdc_opportunity_id =
                                    commercialPrivateCustomer.sfdc_opportunity_id;
                            }
                            if (
                                commercialPrivateCustomer.number_of_students_attended
                            ) {
                                invoice.number_of_participants =
                                    commercialPrivateCustomer.number_of_students_attended;
                            }
                            if (
                                commercialPrivateCustomer.customer_aws_account_id
                            ) {
                                invoice.customer_aws_account_id =
                                    commercialPrivateCustomer.customer_aws_account_id;
                                if (!invoice.is_reseller) {
                                    invoice.bill_to_aws_account_id =
                                        commercialPrivateCustomer.customer_aws_account_id;
                                }
                            }
                            if (commercialPrivateCustomer.currency) {
                                invoice.currency =
                                    commercialPrivateCustomer.currency;
                            }
                            let revenues =
                                commercialPrivateCustomer.revenues ?? [];
                            let totalBilled = 0;
                            let lineItems: Array<LineItem> = revenues
                                .filter((revItem) =>
                                    ['Billed', 'TnE'].includes(revItem.type),
                                )
                                .map((revenue) => {
                                    totalBilled = totalBilled + revenue.amount;
                                    return {
                                        item_amount: revenue.amount,
                                        item_type:
                                            revenue.type === 'TnE'
                                                ? 'Travel & expense'
                                                : activity?.activity_audience ===
                                                    'Public'
                                                  ? 'Public ILT class'
                                                  : 'Private ILT class',
                                    };
                                });

                            return {
                                ...invoice,
                                line_items: lineItems,
                                billed_amount: totalBilled,
                            };
                        }

                        return invoice;
                    },
                );

                const isSuccessful = await dispatch<any>(
                    updateSelectedActivity(match.params.id, {
                        ...activity,
                        customers: updatedCustomers,
                        billing_invoices: updatedInvoices,
                    }),
                );

                addNotification({
                    id: `edit-activity-customer-${Date.now()}`,
                    ...(isSuccessful
                        ? {
                              type: 'success',
                              content: 'The customer has been saved.',
                          }
                        : {
                              type: 'error',
                              content:
                                  'An error occurred while saving the customer.',
                          }),
                });

                if (isSuccessful) {
                    history.push({
                        pathname: `/activities/${match.params.id}`,
                        search: 'tabId=customers',
                    });
                }
            }
        }
        return;
    };

    return (
        <section data-testid="CustomerCreateForm">
            <Form
                header="Edit customer"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-customer-cancel"
                                data-testid="CustomerCreateFormCancel"
                                onClick={() => {
                                    history.push({
                                        pathname: `/activities/${match.params.id}`,
                                        search: 'tabId=customers',
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-customer-save"
                                data-testid="CustomerCreateFormAddButton"
                                loading={isLoading}
                                onClick={() => {
                                    handleCreateCustomer();
                                }}
                            >
                                {`${isLoading ? 'Saving' : 'Save'}`}
                            </Button>
                        </div>
                    </div>
                }
            >
                {activity &&
                    (isCommercialPrivateCustomer(activity.activity_audience) ||
                        isPublicCustomer(activity.activity_audience)) && (
                        <CustomerFormSection
                            formValues={formValues}
                            errors={errors}
                            handleFieldEvent={handleFieldEvent}
                            activity={activity}
                        />
                    )}
                {activity &&
                    isPartnerPrivateCustomer(activity.activity_audience) && (
                        <APNCustomerFormSection
                            formValues={formValues}
                            errors={errors}
                            handleFieldEvent={handleFieldEvent}
                            activity={activity}
                        />
                    )}
            </Form>
        </section>
    );
};

export default CustomerEditForm;

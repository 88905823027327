import React, { useCallback, useEffect, useState } from 'react';
import {
    selectIsLoading,
    updateSelectedActivity,
} from '../../store/slices/selectedActivitySlice';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import useFormValidation from '../../../common/utils/formValidation';
import {
    ActivityData,
    CreateActivityData,
    DeliveryInstructor,
    getDeliverySession,
} from '../../interfaces/activity';
import { FormSectionMode } from '../../../common/constants/forms';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';
import { FORM_ERROR_SELECTOR } from '../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivitySessionControlArrayFormValues,
    createActivityValidationConfig,
    SessionAttributeEditorItem,
    getSessionAttributeEditorItem,
    ActivityFormSectionProps,
    InstructorAvailabilityData,
    ActivitySFDCDeliveryDetailsFormSectionProps,
    ActivityInstructorControlArrayFormValues,
} from '../Activity/Common/Common';
import { ACTIVITY_PATH } from '../../constants/path';
import { selectUser } from '../../../common/store/slices/userSlice';
import OpportunityCustomerFormSection from './OpportunityCustomerFormSection';
import {
    Box,
    Button as ButtonV3,
    Container,
    FormField,
    Header,
    Input,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import OpportunityDetailsFormSection from './OpportunityDetailsFormSection';
import OpportunityInstructorsFormSection from './OpportunityInstructorsFormSection';
import { deliveryDetailsAttributeValidationConfig } from '../Activity/Edit/EditDeliveryDetailsForm';
import {
    doesActivityHaveOnlyOnePrimaryInstructor,
    doesActivityHavePrimaryInstructor,
    generateActivityName,
    getActivities,
    getActivityById,
    mergeActivityIntoActivity,
} from '../../services/activity-service';
import MergeActivityList from '../Common/MergeActivity/MergeActivityList';
import ActivityCreateAutoAssignInstructorOptOutModalProps from '../Activity/Create/ActivityCreateModals/ActivityCreateAutoAssignInstructorOptOutModal';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';

const OpportunityForm = ({
    initialFormState,
}: {
    initialFormState: ActivityData;
}) => {
    const match = useRouteMatch<{ id: string }>();
    const isLoading = useSelector(selectIsLoading);
    const [customers, setCustomers] = useState<Array<number>>([]);
    const [invoices, setInvoices] = useState<Array<number>>([]);
    const [mergeActivities, setMergeActivities] = useState<Array<any>>([]);
    const [mergeActivity, setMergeActivity] = useState<ActivityData | null>(
        null,
    );

    const [showConfirmMerge, setShowConfirmMerge] = useState(false);
    const [confirmMergeValue, setConfirmMergeValue] = useState<string>('');
    const [confirmMergeError, setConfirmMergeError] = useState<string | null>(
        null,
    );
    const [showMustSelectActivityForMerge, setShowMustSelectActivityForMerge] =
        useState(false);
    const [courseShortName, setCourseShortName] = useState<string>('');
    const [mergeToggle, setMergeToggle] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const { addNotification } = useNotifications();

    const {
        isInvalid,
        isControlArrayInvalid,
        errors,
        controlArrayErrors,
        validateForm,
        validateFormControlArray,
    } = useFormValidation<
        Omit<ActivityData, 'delivery_sessions'>,
        ActivitySessionControlArrayFormValues &
            ActivityInstructorControlArrayFormValues
    >();

    const [formValues, setFormValues] = useState({
        ...initialFormState,
        delivery_timezone: initialFormState.delivery_timezone ?? 'UTC',
    });

    const [courseDays, setCourseDays] = useState<number>();
    const [countryCode, setCountryCode] = useState<string | undefined>('');
    const [autoAssignInstructor, setAutoAssignInstructor] =
        useState<boolean>(false);
    const [showOptOutReason, setShowOptOutReason] = useState(false);
    const [optOutReason, setOptOutReason] = useState<OptionDefinition | null>(
        null,
    );
    const [optOutReasonExplanation, setOptOutReasonExplanation] = useState<
        string | undefined
    >('');

    const [validatedInstructors, setValidatedInstructors] = useState<
        Array<any>
    >(
        initialFormState?.instructors?.map(
            (instructor: DeliveryInstructor) => instructor.pk,
        ) ?? [],
    );

    const handleCourseDaysChange = (courseDays: number) => {
        setCourseDays(courseDays);
    };

    const handleCountryCodeChange = (countryCode: string | undefined) => {
        setCountryCode(countryCode);
    };

    const handleAutoAssignInstructorChange = (
        autoAssignInstructor: boolean,
    ) => {
        setAutoAssignInstructor(autoAssignInstructor);
    };

    const [sessionAttributeEditorItems, setSessionAttributeEditorItems] =
        useState(
            formValues.delivery_sessions.map((item) =>
                getSessionAttributeEditorItem(
                    item,
                    formValues.delivery_timezone,
                ),
            ) || [],
        );

    useEffect(() => {
        if (initialFormState && user) {
            let operationsOwnerValues = !initialFormState.operations_owner
                ? {
                      operations_owner: user?.profile.full_name ?? null,
                      operations_owner_email: user?.profile.email ?? null,
                  }
                : {
                      operations_owner: initialFormState.operations_owner,
                      operations_owner_email:
                          initialFormState.operations_owner_email,
                  };

            let schedulerValues = !initialFormState.scheduler
                ? {
                      scheduler: user?.profile.full_name ?? null,
                      scheduler_email: user?.profile.email ?? null,
                  }
                : {
                      scheduler: initialFormState.scheduler,
                      scheduler_email: initialFormState.scheduler_email,
                  };

            setFormValues((values) => ({
                ...values,
                ...{
                    delivery_sessions: initialFormState
                        ? initialFormState.delivery_sessions
                        : [],
                    activity_status: 'Hold',
                },
                ...operationsOwnerValues,
                ...schedulerValues,
            }));
        }
    }, [initialFormState, user]);

    const handleFieldEvent = useCallback(
        (changes: Partial<Omit<CreateActivityData, 'delivery_sessions'>>) => {
            setFormValues((values) => ({
                ...values,
                ...changes,
            }));
        },
        [],
    );

    const handleCustomerFieldEvent = useCallback(
        (changes: Partial<Omit<CreateActivityData, 'delivery_sessions'>>) => {
            setFormValues((values) => ({
                ...values,
                customers: [
                    {
                        ...formValues.customers[0],
                        ...changes,
                    },
                ],
            }));
        },
        [formValues.customers],
    );

    const validateAndHandleFieldEvent = useCallback(
        (changes: Partial<Omit<CreateActivityData, 'delivery_sessions'>>) => {
            setFormValues((values) => ({
                ...values,
                ...changes,
            }));
        },
        [],
    );

    const handleSessionItemEvent = (
        sessionItems: Array<SessionAttributeEditorItem>,
    ) => {
        setSessionAttributeEditorItems(sessionItems);
    };

    const handleScrollToError = () => {
        // this may require attention later for consistent experience in all browsers
        const topMostError = document.querySelector(`.${FORM_ERROR_SELECTOR}`);

        topMostError?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const [instructorValidationResponse] = useState<
        Array<InstructorAvailabilityData>
    >([]);

    const [instructorAttributeEditorItems, setInstructorAttributeEditorItems] =
        useState<Array<DeliveryInstructor>>(
            formValues.instructors?.map((instructor) => ({ ...instructor })) ||
                [],
        );

    const handleSubmit = () => {
        if (mergeToggle && !mergeActivity) {
            setShowMustSelectActivityForMerge(true);
            return;
        }

        if (mergeToggle) {
            setShowConfirmMerge(true);
            return;
        }

        handleCreateActivity();
    };

    const confirmMerge = () => {
        if (confirmMergeValue !== 'CONFIRM MERGE') {
            setConfirmMergeError(`This field is required to merge.`);
            setShowConfirmMerge(true);
            return;
        }

        setShowConfirmMerge(false);

        handleCreateActivity();
    };

    const cancelModal = () => {
        setShowOptOutReason(false);
    };

    const handleAddOptOutReason = (e: any) => {
        handleFieldEvent({
            auto_assign_instructor_opt_out_reason: e.label,
        });
        setOptOutReason(e);
    };

    const getOptOutModal = async () => {
        const isVilt = formValues.activity_modality === 'vILT';
        const isCommercial = formValues.program === 'Commercial';
        const isPartnerProgram = formValues.program === 'Partner Program';
        const isAutoAssignInstructorDisabled = autoAssignInstructor === false;
        const isViltCommercialOrPartnerProgram =
            isVilt && (isCommercial || isPartnerProgram);

        const autoAssignInstructorOptOutIsVisible =
            isViltCommercialOrPartnerProgram && isAutoAssignInstructorDisabled;
        if (autoAssignInstructorOptOutIsVisible) {
            setShowOptOutReason(true);
        } else {
            handleSubmit();
        }
    };

    const handleCreateActivity = async () => {
        const invalid = validateForm(
            formValues,
            createActivityValidationConfig,
        );
        const arrayInvalid = validateFormControlArray(
            { sessionAttributeEditorItems, instructorAttributeEditorItems },
            deliveryDetailsAttributeValidationConfig,
        );

        const duplicatePrimaryKey = doesActivityHaveOnlyOnePrimaryInstructor(
            instructorAttributeEditorItems,
        );

        if (duplicatePrimaryKey) {
            setInstructorItemErrors({
                ...instructorItemErrors,
                [duplicatePrimaryKey as string]: {
                    role: 'Limit of one primary instructor.',
                },
            });
        }

        const isActivityFormInvalid =
            invalid || arrayInvalid || duplicatePrimaryKey !== null;
        formValues.course_days = courseDays ? courseDays : 1;

        if (!isActivityFormInvalid) {
            const activityData: ActivityData = {
                ...initialFormState,
                activity_name: generateActivityName(
                    formValues,
                    countryCode,
                    courseShortName,
                ),
                activity_status: 'Hold',
                program: formValues.program,
                partner_initiative: formValues.partner_initiative,
                activity_audience: formValues.activity_audience,
                activity_modality: formValues.activity_modality,
                course_name: formValues.course_name,
                delivery_timezone: formValues.delivery_timezone,
                delivery_city: formValues.delivery_city,
                delivery_state: formValues.delivery_state,
                delivery_country: formValues.delivery_country,
                delivery_region: formValues.delivery_region,
                delivery_geo: formValues.delivery_geo,
                delivery_language: formValues.delivery_language,
                course_days: formValues.course_days,
                operations_owner: formValues.operations_owner,
                operations_owner_email: formValues.operations_owner_email,
                scheduler: formValues.scheduler,
                scheduler_email: formValues.scheduler_email,
                customer_support_manager: formValues.customer_support_manager,
                customer_support_manager_email:
                    formValues.customer_support_manager_email,
                instructors: instructorAttributeEditorItems,
                delivery_sessions: sessionAttributeEditorItems
                    .filter(
                        (item) =>
                            item.startTime && item.endTime && item.dateString,
                    )
                    .map((item) =>
                        getDeliverySession(item, formValues.delivery_timezone),
                    ),
                billing_invoices: (formValues.billing_invoices ?? []).filter(
                    (invoice, index) => {
                        return invoices.includes(index);
                    },
                ),
                customers: (formValues.customers ?? [])
                    .filter((customer, index) => {
                        return customers.includes(index);
                    })
                    .map((customer) => {
                        return {
                            ...customer,
                            number_of_students_attended: undefined,
                        };
                    }),
                auto_assign_instructor: autoAssignInstructor,
            };
            if (mergeActivity) {
                // merge the activity
                const isSuccessful = await mergeActivityIntoActivity(
                    mergeActivity.pk,
                    activityData.pk,
                );

                addNotification({
                    id: `update-activity`,
                    ...(isSuccessful
                        ? {
                              type: 'success',
                              content: (
                                  <span>
                                      Opportunity merged.{' '}
                                      <Link to={'/activities/sfdc-queue'}>
                                          Return to SFDC queue
                                      </Link>
                                  </span>
                              ),
                          }
                        : {
                              type: 'error',
                              content:
                                  'An error occurred while merging the activity.',
                          }),
                });

                if (isSuccessful) {
                    history.push({
                        pathname: `/activities/${mergeActivity.pk}`,
                    });
                }
            } else {
                const isSuccessful = await dispatch<any>(
                    updateSelectedActivity(match.params.id, activityData),
                );

                if (autoAssignInstructor === true) {
                    const activityData = await getActivityById(match.params.id);
                    const deliveryInstructors =
                        activityData?.activity &&
                        activityData.activity?.instructors;
                    const isPrimaryInstructorAssigned =
                        doesActivityHavePrimaryInstructor(deliveryInstructors);
                    addNotification({
                        id: `update-activity`,
                        ...(isSuccessful
                            ? isPrimaryInstructorAssigned
                                ? {
                                      type: 'success',
                                      content: (
                                          <span>
                                              Activity added. An instructor was
                                              assigned.{' '}
                                              <Link
                                                  to={'/activities/sfdc-queue'}
                                              >
                                                  Return to SFDC queue
                                              </Link>
                                          </span>
                                      ),
                                  }
                                : {
                                      type: 'warning',
                                      content: (
                                          <span>
                                              Activity added. No instructors
                                              available for auto assignment.
                                              Please manually assign an
                                              instructor.{' '}
                                              <Link
                                                  to={'/activities/sfdc-queue'}
                                              >
                                                  Return to SFDC queue
                                              </Link>
                                          </span>
                                      ),
                                  }
                            : {
                                  type: 'error',
                                  content:
                                      'An error occurred while creating the activity.',
                              }),
                    });
                } else {
                    addNotification({
                        id: `update-activity`,
                        ...(isSuccessful
                            ? {
                                  type: 'success',
                                  content: (
                                      <span>
                                          Activity added.{' '}
                                          <Link to={'/activities/sfdc-queue'}>
                                              Return to SFDC queue
                                          </Link>
                                      </span>
                                  ),
                              }
                            : {
                                  type: 'error',
                                  content:
                                      'An error occurred while saving the activity.',
                              }),
                    });
                }

                if (isSuccessful) {
                    history.push({
                        pathname: `/activities/${match.params.id}`,
                    });
                }
            }
        } else {
            handleScrollToError();
        }
    };

    const handleCustomersChange = (customerIndexes: Array<number>) => {
        setCustomers(customerIndexes);
    };

    const handleInvoicesChange = (invoiceIndexes: Array<number>) => {
        setInvoices(invoiceIndexes);
    };

    const handleInstructorItemEvent = (
        instructorItems: Array<DeliveryInstructor>,
    ) => {
        setInstructorAttributeEditorItems(instructorItems);
    };

    const handleAddInstructor = (instructorPk: string) => {
        setValidatedInstructors([...validatedInstructors, instructorPk]);
    };

    const handleShortNameChange = (shortName: string) => {
        setCourseShortName(shortName);
    };

    const [instructorItemErrors, setInstructorItemErrors] = useState<any>({});

    const createActivityProps: ActivityFormSectionProps<
        Omit<ActivityData, 'delivery_sessions'>
    > & {
        handleCourseDaysChange: (courseDays: number) => void;
        handleCountryCodeChange: (countryCode: string | undefined) => void;
        handleShortNameChange?: (shortName: string) => void;
    } = {
        formValues,
        errors,
        handleFieldEvent,
        validateAndHandleFieldEvent,
        handleSessionItemEvent,
        sessionAttributeEditorItems,
        mode: FormSectionMode.Create,
        controlArrayErrors,
        handleCourseDaysChange,
        handleCustomersChange,
        handleInvoicesChange,
        handleShortNameChange,
        handleCountryCodeChange,
    };

    const activityCustomerProps: ActivityFormSectionProps<
        Omit<ActivityData, 'delivery_sessions'>
    > & {
        handleCourseDaysChange: (courseDays: number) => void;
    } = {
        formValues,
        errors,
        handleFieldEvent: handleCustomerFieldEvent,
        validateAndHandleFieldEvent,
        handleSessionItemEvent,
        sessionAttributeEditorItems,
        mode: FormSectionMode.Create,
        controlArrayErrors,
        handleCourseDaysChange,
        handleCustomersChange,
        handleInvoicesChange,
    };

    const editDeliveryDetailsFormProps: ActivitySFDCDeliveryDetailsFormSectionProps & {
        handleAutoAssignInstructorChange: (
            autoAssignInstructor: boolean,
        ) => void;
    } = {
        formValues,
        errors,
        controlArrayErrors,
        handleFieldEvent,
        mode: FormSectionMode.Edit,
        handleSessionItemEvent,
        sessionAttributeEditorItems,
        handleInstructorItemEvent,
        instructorAttributeEditorItems,
        instructorValidationResponse,
        handleAddInstructor,
        instructorItemErrors,
        handleAutoAssignInstructorChange,
    };

    const mergeActivityFormProps: any = {
        mergeActivities,
        setMergeActivity,
        mergeDescription: (
            <>
                <div className="grimsby-sub-section-header">Merge activity</div>
                <span>
                    Merging activities adds the customer from this opportunity
                    to an existing activity with the same course, language,
                    location, modality and start date.
                </span>
            </>
        ),
        showMustSelect: showMustSelectActivityForMerge,
        onMergeToggle: (v: any) => setMergeToggle(v),
    };

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, createActivityValidationConfig);
        }

        if (isControlArrayInvalid) {
            validateFormControlArray(
                { sessionAttributeEditorItems, instructorAttributeEditorItems },
                deliveryDetailsAttributeValidationConfig,
            );
        }
    }, [
        isInvalid,
        formValues,
        validateForm,
        validateFormControlArray,
        sessionAttributeEditorItems,
        isControlArrayInvalid,
        instructorAttributeEditorItems,
    ]);

    useEffect(() => {
        const getMergeActivities = async () => {
            const startDeliverySession = getDeliverySession(
                sessionAttributeEditorItems[0],
                formValues.delivery_timezone,
            );
            const endDeliverySession = getDeliverySession(
                sessionAttributeEditorItems[
                    sessionAttributeEditorItems.length - 1
                ],
                formValues.delivery_timezone,
            );

            const activityResponse = await getActivities({
                delivery_country: [formValues.delivery_country],
                delivery_city: [formValues.delivery_city],
                course_name: [formValues.course_name],
                activity_modality: [formValues.activity_modality],
                delivery_language: [formValues.delivery_language],
                start_timestamp: startDeliverySession
                    ? startDeliverySession.start_timestamp
                    : formValues.delivery_sessions[0].start_timestamp,
                end_timestamp: endDeliverySession
                    ? endDeliverySession.end_timestamp
                    : formValues.delivery_sessions[0].end_timestamp,
            });
            setMergeActivities(activityResponse.activities);
        };

        if (
            formValues?.delivery_sessions.length &&
            formValues?.delivery_country &&
            formValues?.delivery_city &&
            formValues?.course_name &&
            formValues?.activity_modality &&
            formValues?.delivery_language
        ) {
            getMergeActivities();
        }
    }, [formValues, sessionAttributeEditorItems]);

    return (
        <Form
            header="Edit opportunity"
            description="Review and edit the form below to add this opportunity to the activity list."
            data-testid="OpportunityForm"
            actions={
                <div className="awsui-util-action-stripe awsui-util-mb-m awsui-util-mt-l">
                    <div className="awsui-util-action-stripe-group">
                        <Button
                            variant="link"
                            className="admin-activity-cancel"
                            data-testid="OpportunityFormCancel"
                            onClick={() => {
                                history.push({
                                    pathname: ACTIVITY_PATH.SFDC_QUEUE,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            className="admin-activity-save"
                            data-testid="OpportunityFormAdd"
                            loading={isLoading}
                            onClick={getOptOutModal}
                            disabled={
                                initialFormState.activity_status !==
                                'Pending Review'
                            }
                        >
                            {`${isLoading ? 'Adding' : 'Add'} activity`}
                        </Button>
                    </div>
                </div>
            }
        >
            <Container
                header={<Header variant="h2">Opportunity details</Header>}
            >
                <SpaceBetween direction="vertical" size="m">
                    <OpportunityCustomerFormSection
                        {...activityCustomerProps}
                    />
                    <div className="grimsby-sub-section-divider" />
                    <OpportunityDetailsFormSection {...createActivityProps} />
                    <div className="grimsby-sub-section-divider" />
                    <MergeActivityList {...mergeActivityFormProps} />
                    <Modal
                        size={'medium'}
                        visible={showConfirmMerge}
                        onDismiss={() => {
                            setShowConfirmMerge(false);
                        }}
                        header={'Merge activity?'}
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <ButtonV3
                                        variant="link"
                                        onClick={() => {
                                            setShowConfirmMerge(false);
                                        }}
                                    >
                                        Cancel
                                    </ButtonV3>
                                    <ButtonV3
                                        variant="primary"
                                        onClick={() => {
                                            confirmMerge();
                                        }}
                                    >
                                        Merge
                                    </ButtonV3>
                                </SpaceBetween>
                            </Box>
                        }
                    >
                        <p>Merge this activity with the selected activity?</p>
                        <div className="grimsby-sub-section-divider" />
                        <FormField
                            label={`To continue, enter "CONFIRM MERGE" and press the merge button.`}
                            errorText={confirmMergeError}
                        >
                            <Input
                                value={confirmMergeValue}
                                placeholder="Enter confirmation message"
                                onChange={(e) => {
                                    setConfirmMergeValue(e.detail.value);
                                }}
                            />
                        </FormField>
                    </Modal>
                    <OpportunityInstructorsFormSection
                        {...editDeliveryDetailsFormProps}
                    />
                    <ActivityCreateAutoAssignInstructorOptOutModalProps
                        visible={showOptOutReason}
                        data-testid="ActivityCreateAutoAssignInstructorOptOutModalProps"
                        onCancel={cancelModal}
                        onConfirm={handleCreateActivity}
                        setOptOutReason={handleAddOptOutReason}
                        optOutReason={optOutReason}
                        setOptOutReasonExplanation={setOptOutReasonExplanation}
                        optOutReasonExplanation={optOutReasonExplanation}
                    />
                </SpaceBetween>
            </Container>
        </Form>
    );
};

export default OpportunityForm;

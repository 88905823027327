import { GrimsbyNotification } from '../context/grimsbyNotifications';

export interface BusinessDataNotificationProps {
    businessDataType: string;
    businessDataDisplayNameLower: string;
    itemName?: string;
    dispatchPromise: Promise<boolean>;
    addNotification: (notification: GrimsbyNotification) => void;
    action: 'create' | 'update';
}

const handleBusinessDataNotification = async ({
    businessDataType,
    businessDataDisplayNameLower,
    itemName,
    dispatchPromise,
    addNotification,
    action,
}: BusinessDataNotificationProps): Promise<boolean> => {
    const isSuccessful = await dispatchPromise;
    addNotification({
        id: `${action}-${businessDataType}-${Date.now()}`,
        ...(isSuccessful
            ? {
                  type: 'success',
                  content: `You successfully ${
                      action === 'create' ? 'created' : 'updated'
                  } the ${businessDataDisplayNameLower} "${itemName}"`,
              }
            : {
                  type: 'error',
                  content: `An error occurred while ${
                      action === 'create' ? 'creating' : 'updating'
                  } the ${businessDataDisplayNameLower} "${itemName}"`,
              }),
    });

    return isSuccessful;
};

export default handleBusinessDataNotification;

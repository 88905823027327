import React from 'react';

import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    Alert,
    ColumnLayout,
} from '@amzn/awsui-components-react-v3';
import { unix as dayjsUnix } from 'dayjs';
import { ActivityData } from '../../../../interfaces/activity';

interface EditDeliveryDetailsAdjacentActivitiesModalProps {
    visible: boolean;
    onConfirm: any;
    onCancel: any;
    instructorConflict: any;
}

const CONFIRM_ASSIGNMENT_TEXT = 'Instructor conflict';

const EditDeliveryDetailsAdjacentActivitiesModal = ({
    visible,
    onConfirm,
    onCancel,
    instructorConflict,
}: EditDeliveryDetailsAdjacentActivitiesModalProps) => {
    const getDisplayDate = (displayTimestamp: number | null | undefined) => {
        if (!displayTimestamp) {
            return '';
        }

        return dayjsUnix(displayTimestamp).format('dddd MMMM D');
    };

    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmConflictModal`}
            key={`adjacent-activities-modal`}
            footer={
                <Box float="right" key={`adjacent-container`}>
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmConflictCancel`}
                            key={`ConfirmConflictCancel`}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmConflictConfirm`}
                            onClick={() => {
                                onConfirm();
                            }}
                            key={`ConfirmConflictConfirm`}
                        >
                            Yes, continue
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={CONFIRM_ASSIGNMENT_TEXT}
        >
            <Alert
                onDismiss={() => {}}
                visible={true}
                dismissAriaLabel="Close alert"
                type="warning"
                key={`adjacent-warning`}
            >
                {`${instructorConflict?.instructor?.full_name}`} has an activity
                that may conflict with this one. Continue with this instructor
                anyway?
            </Alert>
            <ColumnLayout key={`reassign-adjacent-activity-container`}>
                <div
                    data-awsui-column-layout-root="true"
                    className="reassign-adjacent-activity"
                    key={`reassign-adjacent-activity`}
                >
                    <span
                        className="reassign-adjacent-activity-title"
                        key={`adjacent-activities-title`}
                    >
                        Adjacent{' '}
                        {instructorConflict.instructor.back_to_back_activities
                            .length > 1
                            ? 'activities'
                            : 'activity'}{' '}
                        for {instructorConflict.instructor.full_name}
                    </span>
                    {instructorConflict?.instructor.back_to_back_activities.map(
                        (activity: ActivityData, index: number) => {
                            return (
                                <Box
                                    key={`adjacent-activity-${instructorConflict.instructor.pk}-${activity.pk}-${index}`}
                                    className="reassign-adjacent-activity-item"
                                >
                                    <span
                                        className="reassign-adjacent-activity-name"
                                        key={`adjacent-activity-name-${index}`}
                                    >
                                        <a
                                            data-testid="reassign-adjacent-activity-name-link"
                                            href={`/activities/${activity?.pk}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="reassign-adjacent-activity-name"
                                        >
                                            {activity?.activity_name}
                                        </a>
                                    </span>
                                    <span key={`adjacent-session-${index}`}>
                                        {getDisplayDate(
                                            activity?.delivery_sessions[0]
                                                ?.start_timestamp,
                                        )}{' '}
                                        -{' '}
                                        {getDisplayDate(
                                            activity?.delivery_sessions[
                                                activity.delivery_sessions
                                                    .length - 1
                                            ]?.start_timestamp,
                                        )}
                                    </span>
                                    <span>
                                        {activity?.delivery_city},{' '}
                                        {activity?.delivery_state},{' '}
                                        {activity?.delivery_country}
                                    </span>
                                </Box>
                            );
                        },
                    )}
                </div>
            </ColumnLayout>
        </Modal>
    );
};

export default EditDeliveryDetailsAdjacentActivitiesModal;

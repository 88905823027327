import { useContext } from 'react';
import AuthenticationContext from '../context/AuthenticationProvider';

const useAuthentication = () => {
    const context = useContext(AuthenticationContext);
    if (context === undefined) {
        throw new Error(
            'useAuthentication must be used within AuthenticationProvider context; ' +
                'Wrap component with AuthenticationProvider',
        );
    }
    return context;
};

export default useAuthentication;

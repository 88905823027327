import React, { createContext, useContext, useMemo, useState } from 'react';
import { NavItem } from '../interfaces/navigation';
import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { LinkTypes, NavType } from '../constants/navigation';
import { Roles } from '../constants/auth';
import { check } from '../utils/auth';
import { FeatureFlags } from '../interfaces/featureFlags';
import { checkFeature } from '../utils/featureFlag';

export interface NavData {
    navItems: NavItem[];
    navItemsMap: Map<string, NavItem>;
}
export interface UseNav {
    navData: NavData;
    breadcrumbs: BreadcrumbGroup.Item[];
    sideNavEnabled: boolean;
    isNavOpened: boolean;
}

export interface UseNavActions {
    generateBreadcrumbs: (path: string, params: any) => void;
    toggleSideNav: (enable: boolean) => void;
    setNavItems: (navItems: NavItem[]) => void;
    getSideNavItems: (
        roles: Array<Roles>,
        features?: FeatureFlags | undefined,
        user?: string,
    ) => Omit<NavItem, 'href'>[];
    initializeNavigation: (
        navItems?: NavItem[],
        enable?: boolean,
        opened?: boolean,
    ) => void;
}

const NavContext = createContext<UseNav | undefined>(undefined);
const NavActionsContext = createContext<UseNavActions | undefined>(undefined);

NavContext.displayName = 'GrimsbyNavContext';

/**
 * SideNavProvider can be used to see if sideNav should be shown or not for a particular route location.
 * This was introduced to hide/show side nav based on route changes as sideNav is part of App.tsx and
 * it is not possible to listen to location changes there as history may not have been initialized
 * @param children
 * @constructor
 */
const GrimsbyNavProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const [sideNavEnabled, setEnabled] = useState(false);
    const [isNavOpened, setNavigationOpened] = useState(true);
    const [navData, setNavData] = useState<NavData>({
        navItems: [],
        navItemsMap: new Map<string, NavItem>(),
    });
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbGroup.Item[]>([]);

    const navigationData = {
        navData,
        breadcrumbs,
        sideNavEnabled,
        isNavOpened,
    };

    const navigationActions = useMemo(() => {
        const toggleSideNav = (enable: boolean) => {
            setEnabled(enable);
        };

        const initNavigationOpened = (open: boolean) => {
            setNavigationOpened(open);
        };

        const setNavItems = (items: NavItem[]) => {
            setNavData({ navItems: items, navItemsMap: setRouteTree(items) });
        };

        const setRouteTree = (items: NavItem[]) => {
            const routingMap = new Map<string, NavItem>();
            items.forEach((item: NavItem) => {
                if (!routingMap.has(item.id)) {
                    routingMap.set(item.id, item);
                }
            });

            return routingMap;
        };

        const generateCrumbs = (
            id: string,
            params: { [key: string]: string },
        ): BreadcrumbGroup.Item[] => {
            if (!id) {
                return [];
            }

            const item = navData.navItemsMap.get(id);
            const paramsKeys = Array.from(Object.keys(params));

            if (item) {
                let crumbHref = item.href;

                // replace param values in path string
                if (paramsKeys.length) {
                    paramsKeys.forEach((param) => {
                        crumbHref = crumbHref.replace(
                            `:${param}`,
                            params[param],
                        );
                    });
                }

                const crumb = {
                    text: item.text,
                    href: crumbHref,
                } as BreadcrumbGroup.Item;

                // generate crumb tree if current item is not root
                if (item.parent) {
                    return [...generateCrumbs(item.parent, params), crumb];
                }

                return [crumb];
            }

            return [];
        };

        const generateBreadcrumbs = (
            path: string,
            params: { [key: string]: string },
        ) => {
            const item = navData.navItems.find((item) => item.href === path);

            if (item) {
                setBreadcrumbs(generateCrumbs(item.id, params));
            } else {
                setBreadcrumbs([]);
            }
        };

        const getSideNavItems = (
            roles: Array<Roles>,
            features?: FeatureFlags | undefined,
            user: string = '',
        ): Omit<NavItem, 'href'>[] => {
            // filtered list of links that should appear in the side nav
            const sideNavLinks = navData.navItems.filter((item) => {
                return (
                    check(roles, item.requiredPermissionAction) &&
                    checkFeature(user, item, features) &&
                    item.type === LinkTypes.Link &&
                    item.navType === NavType.SideNav
                );
            });
            // filtered list of parent side nav items
            const collapsibleItems = navData.navItems
                .filter((item) => {
                    return (
                        check(roles, item.requiredPermissionAction) &&
                        item.navType === NavType.SideNav &&
                        item.type !== LinkTypes.Link &&
                        checkFeature(user, item, features)
                    );
                })
                .sort((a, b) => {
                    if (
                        typeof a.ordinal !== 'undefined' &&
                        typeof b.ordinal !== 'undefined'
                    ) {
                        return a.ordinal - b.ordinal;
                    }

                    return -1;
                })
                .map((item) => {
                    const { href, ...navItem } = item;
                    return navItem;
                }) as Omit<NavItem, 'href'>[];
            if (collapsibleItems.length > 0) {
                // fill the items for each parent
                collapsibleItems.forEach((item) => {
                    item.items = [] as NavItem[];

                    item.items = sideNavLinks.filter(
                        (child) => child.parent === item.id,
                    );

                    item.items.sort((a, b) => {
                        if (
                            item.useChildOrdinal &&
                            typeof a.ordinal !== 'undefined' &&
                            typeof b.ordinal !== 'undefined'
                        ) {
                            return a.ordinal - b.ordinal;
                        }
                        const aText = a.text.toLowerCase();
                        const bText = b.text.toLowerCase();

                        return aText < bText ? -1 : aText > bText ? 1 : 0;
                    });

                    item.redirectTo = item.items[0]?.href;
                });

                return collapsibleItems;
            }
            return sideNavLinks;
        };

        const initializeNavigation = (
            navItems: NavItem[] = [],
            enable: boolean = false,
            opened: boolean = true,
        ) => {
            setNavItems(navItems);
            toggleSideNav(enable);
            initNavigationOpened(opened);
        };

        return {
            toggleSideNav,
            initNavigationOpened,
            setNavItems,
            generateBreadcrumbs,
            getSideNavItems,
            initializeNavigation,
        };
    }, [navData.navItems, navData.navItemsMap]);

    return (
        <NavContext.Provider value={navigationData}>
            <NavActionsContext.Provider value={navigationActions}>
                {children}
            </NavActionsContext.Provider>
        </NavContext.Provider>
    );
};

/**
 * useGrimsbyNav hook allows components
 * enabled - a boolean to see if side nav is shown
 */
const useGrimsbyNav = (): UseNav => {
    const context = useContext(NavContext);

    if (typeof context === 'undefined') {
        throw new Error(
            'useGrimsbyNav should be used within a NavContext. Wrap component with SideNavProvider.',
        );
    }

    return context;
};

const useGrimsbyNavActions = (): UseNavActions => {
    const context = useContext(NavActionsContext);

    if (typeof context === 'undefined') {
        throw new Error(
            'useGrimsbyNavActions should be used within a NavContext. Wrap component with SideNavProvider.',
        );
    }

    return context;
};

export { GrimsbyNavProvider, useGrimsbyNav, useGrimsbyNavActions };

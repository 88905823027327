/*
    Navigation.tsx renders the side navigation bar
*/
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    CustomDetailEvent,
    SideNavigation,
} from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';
import {
    useGrimsbyNav,
    useGrimsbyNavActions,
} from '../../context/grimsbyNavigation';
import { NavItem } from '../../interfaces/navigation';
import { selectFeatures } from '../../store/slices/featureSlice';
import { selectRoles, selectUser } from '../../store/slices/userSlice';

export const headerData: SideNavigation.Header = { text: 'Home', href: '/' };

const Navigation = () => {
    const [active, setActive] = useState('');
    const { navData } = useGrimsbyNav();
    const { getSideNavItems } = useGrimsbyNavActions();
    const history = useHistory();
    const roles = useSelector(selectRoles);
    const featureFlags = useSelector(selectFeatures);
    const user = useSelector(selectUser);
    const [items, setItems] = useState<Omit<NavItem, 'href'>[]>([]);

    useEffect(() => {
        setActive(history.location.pathname);

        return history.listen((location) => {
            setActive(location.pathname);
        });
    }, [history]);

    useEffect(() => {
        if (featureFlags) {
            setItems(
                getSideNavItems(
                    roles,
                    featureFlags?.features,
                    user?.profile.alias,
                ),
            );
        }
    }, [navData.navItems, roles, getSideNavItems, featureFlags, user]);

    return (
        <>
            {navData.navItems?.length && (
                <SideNavigation
                    data-testid="side_nav"
                    header={headerData}
                    items={items}
                    activeHref={active}
                    onFollow={(
                        event: CustomDetailEvent<SideNavigation.FollowDetail>,
                    ) => {
                        if (event.detail.external) return;
                        event.preventDefault();
                        history.push(event.detail.href);
                    }}
                />
            )}
        </>
    );
};

export default Navigation;

import { Autosuggest, Select } from '@amzn/awsui-components-react';
import { SelectProps } from '@amzn/awsui-components-react-v3';

interface BooleanFieldOption extends Select.Option {
    readonly label: 'Yes' | 'No';
    readonly id: string;
}
export const booleanFieldOptions: Array<BooleanFieldOption> = [
    {
        label: 'No',
        id: '0',
    },
    {
        label: 'Yes',
        id: '1',
    },
];
export const booleanFieldOptionsBoolLookup = {
    No: false,
    Yes: true,
} as const;
export const booleanFieldOptionLookup = booleanFieldOptions.reduce(
    (acc, opt) => {
        acc[
            booleanFieldOptionsBoolLookup[opt.label].toString() as
                | 'true'
                | 'false'
        ] = opt;
        return acc;
    },
    {} as {
        [key in 'true' | 'false']: BooleanFieldOption;
    },
);

export const FORM_ERROR_SELECTOR = 'instructorFormError';

// We will be deprecating this method with polaris update.
export const getOptionsAndLookupForSelectInput = <T>(
    valueList: Array<T>,
    getOption: (value: T) => { label: string; id: string },
    nonSelectableOptions?: ReadonlySet<string>,
): {
    valueOptions: Array<Select.Option>;
    valueLookup: {
        [key: string]: Select.Option;
    };
} =>
    valueList.reduce(
        (acc, value) => {
            const option = getOption(value);

            acc.valueLookup[option.label] = option;

            if (!nonSelectableOptions?.has(option.label)) {
                acc.valueOptions.push(option);
            }
            return acc;
        },
        {
            valueOptions: [] as Array<Select.Option>,
            valueLookup: {} as {
                [key: string]: Select.Option;
            },
        },
    );

export const getOptionsAndLookupForSelectInputPolarisV3 = <T>(
    valueList: Array<T>,
    getOption: (value: T) => { label: string; value: string },
    nonSelectableOptions?: ReadonlySet<string>,
): {
    valueOptions: Array<SelectProps.Option>;
    valueLookup: {
        [key: string]: SelectProps.Option;
    };
} =>
    valueList.reduce(
        (acc, value) => {
            const option = getOption(value);

            acc.valueLookup[option.label] = option;

            if (!nonSelectableOptions?.has(option.label)) {
                acc.valueOptions.push(option);
            }
            return acc;
        },
        {
            valueOptions: [] as Array<SelectProps.Option>,
            valueLookup: {} as {
                [key: string]: SelectProps.Option;
            },
        },
    );

// might need to re-design getOptionsAndLookupForAutosuggestInput and
// getOptionsAndLookupForSelectInput to consolidate into one more
// generic method
export const getOptionsAndLookupForAutosuggestInput = <T>(
    valueList: Array<T>,
    getOption: (value: T) => { value: string },
): {
    valueOptions: Array<Autosuggest.Option>;
    valueLookup: {
        [key: string]: Autosuggest.Option;
    };
} =>
    valueList.reduce(
        (acc, value) => {
            const option = getOption(value);

            acc.valueOptions.push(option);
            acc.valueLookup[option.value] = option;

            return acc;
        },
        {
            valueOptions: [] as Array<Autosuggest.Option>,
            valueLookup: {} as {
                [key: string]: Autosuggest.Option;
            },
        },
    );

export enum MultiSelectStatusType {
    Pending = 'pending',
    Loading = 'loading',
    Finished = 'finished',
    Error = 'error',
}

export const getStatusType = (
    listLoading: boolean,
    listLoaded: boolean,
    err: any,
) => {
    if (listLoading) {
        return MultiSelectStatusType.Loading;
    } else if (listLoaded && !err) {
        return MultiSelectStatusType.Finished;
    } else if (err) {
        return MultiSelectStatusType.Error;
    }
    return MultiSelectStatusType.Finished;
};

export const generateSelectedOptions = (
    array: Array<string>,
    lookup: {
        [key: string]: Select.Option;
    },
): Array<Select.Option> =>
    array.reduce((acc, item: string) => {
        if (lookup[item]) {
            acc.push(lookup[item]);
        }
        return acc;
    }, [] as Array<Select.Option>);

export const handleStateUpdateHelper =
    <T>(setState: React.Dispatch<React.SetStateAction<T>>) =>
    (changes: Partial<T>) => {
        setState((state) => ({
            ...state,
            ...changes,
        }));
    };

import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

interface UseBilledDatePickerProps {
    formValues: {
        billed_date?: number;
    };
    handleFieldEvent: (values: any) => void;
}

export const isDateEnabled = (date: Date) => {
    const today = dayjs().startOf('day');
    const selectedDate = dayjs(date).startOf('day');
    const isFutureDate = selectedDate.isAfter(today);
    const isCurrentMonth = selectedDate.month() === today.month();

    return isFutureDate && isCurrentMonth;
};

export const validateBilledDate = (date: number | Date) => {
    const dateObj = dayjs.isDayjs(date)
        ? date
        : typeof date === 'number'
          ? dayjs.unix(date)
          : dayjs(date);
    const today = dayjs().startOf('day');
    const selectedDate = dateObj.startOf('day');

    if (!dateObj.isValid()) {
        return 'Invalid date.';
    }

    const isFutureDate = selectedDate.isAfter(today);
    const isCurrentMonth = selectedDate.month() === today.month();

    if (!isFutureDate) {
        return 'Select a future date.';
    }

    if (!isCurrentMonth) {
        return 'Date must be in the current month.';
    }

    return false;
};

export const useBilledDatePicker = ({
    formValues,
    handleFieldEvent,
}: UseBilledDatePickerProps) => {
    const [datePickerValue, setDatePickerValue] = useState(() => {
        return formValues.billed_date
            ? dayjs.unix(formValues.billed_date).format('YYYY-MM-DD')
            : dayjs().endOf('month').format('YYYY-MM-DD');
    });

    useEffect(() => {
        if (!formValues.billed_date) {
            const defaultDate = dayjs().endOf('month').format('YYYY-MM-DD');
            handleFieldEvent({
                billed_date: dayjs(defaultDate).unix(),
            });
        }
    }, []);

    useEffect(() => {
        const newDateValue = formValues.billed_date
            ? dayjs.unix(formValues.billed_date).format('YYYY-MM-DD')
            : dayjs().endOf('month').format('YYYY-MM-DD');
        setDatePickerValue(newDateValue);
    }, [formValues.billed_date]);

    const handleDateChange = (detail: { value: string | null }) => {
        const newValue = detail.value || '';
        setDatePickerValue(newValue);
        const isValidDate = dayjs(newValue, 'YYYY-MM-DD', true).isValid();

        if (isValidDate) {
            handleFieldEvent({
                billed_date: dayjs(newValue).unix(),
            });
        }
    };

    return {
        datePickerValue,
        handleDateChange,
    };
};

import React, { PropsWithChildren, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
    useGrimsbyNav,
    useGrimsbyNavActions,
} from '../../context/grimsbyNavigation';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const BreadcrumbsWrapper = ({ children }: PropsWithChildren<{}>) => {
    const { path, params } = useRouteMatch();
    const { navData } = useGrimsbyNav();
    const { generateBreadcrumbs } = useGrimsbyNavActions();

    useEffect(() => {
        if (navData.navItems.length) {
            generateBreadcrumbs(path, params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navData.navItems, path, generateBreadcrumbs]);

    return (
        <>
            <Breadcrumbs />
            {children}
        </>
    );
};

export default BreadcrumbsWrapper;

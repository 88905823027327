import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    SpaceBetween,
    Button,
    Icon,
    SegmentedControl,
    PropertyFilter,
    Pagination,
    Grid,
    CollectionPreferences,
} from '@amzn/awsui-components-react-v3';

import { InstructorCalendarHeaderPropsData } from '../../../interfaces/resourceCalendarProps';
import './Header.scss';
import { FCResourceViewTypes } from '../../../enums/fcResourceVIewTypes';
import { INITIAL_CALENDAR_VIEW_TYPE } from '../../../constants';
import { ACTIVITY_PATH } from '../../../constants/path';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    setEndTimeStamp,
    setStartTimeStamp,
} from '../../../store/slices/instructorListSlice';
import dayjs from 'dayjs';
import { isMatchingPath } from '../../../../common/utils/matchPath';
import { RecommendedInstructorProfileData } from '../../../interfaces/recommendedInstructorProfile';
import Can from '../../../../common/components/Can';
import { Actions } from '../../../../common/constants/auth';
import { createPropertyFilterI18nStrings } from '../../../../common/utils/createPropertyFilterI18nStrings';

interface HeaderChangeView {
    selectedViewId: string;
    changeView: Function;
}

interface HeaderControls extends HeaderChangeView {
    previous: Function;
    next: Function;
    title: string;
    location?: Pick<Location, 'pathname'>;
    handleHeaderAction?: (prop: any) => void;
    selectedInstructors?: Set<RecommendedInstructorProfileData>;
    savedFiltersComponent?: any;
}

const HeaderChangeView = ({ selectedViewId, changeView }: HeaderChangeView) => (
    <Box>
        <SegmentedControl
            data-testid={`AssignInstructorHeaderChangeView`}
            selectedId={selectedViewId}
            onChange={({ detail }) => {
                changeView(detail.selectedId as FCResourceViewTypes);
            }}
            label="Default segmented control"
            options={[
                {
                    text: 'Day',
                    id: FCResourceViewTypes.Day,
                },
                {
                    text: 'Week',
                    id: FCResourceViewTypes.Week,
                },
                {
                    text: 'Month',
                    id: FCResourceViewTypes.Month,
                },
            ]}
        />
    </Box>
);

const CalendarControls = ({
    previous,
    next,
}: Omit<HeaderControls, 'title' | 'changeView' | 'selectedViewId'>) => (
    <SpaceBetween size="xs" direction="horizontal">
        <Button
            variant="normal"
            onClick={() => previous()}
            data-testid={`AssignInstructorHeaderPrev`}
        >
            <Icon name="angle-left" size="normal" variant="normal" />
        </Button>
        <Button
            variant="normal"
            onClick={() => next()}
            data-testid={`AssignInstructorHeaderNext`}
        >
            <Icon name="angle-right" size="normal" variant="normal" />
        </Button>
    </SpaceBetween>
);

const InstructorListHeaderControls = ({
    selectedViewId,
    changeView,
    previous,
    next,
    title,
    handleHeaderAction = () => {},
    selectedInstructors,
    savedFiltersComponent,
}: HeaderControls) => {
    return (
        <>
            <Box>
                <SpaceBetween direction="horizontal" size="xs">
                    <HeaderChangeView {...{ selectedViewId, changeView }} />
                    <Box />
                    <CalendarControls {...{ previous, next }} />
                </SpaceBetween>
            </Box>
            <Box variant="h2">{title}</Box>
            <Box>
                <SpaceBetween direction="horizontal" size="xs">
                    {savedFiltersComponent}
                    <Can
                        perform={Actions.RESERVE_TIME_ADMIN}
                        yes={() => (
                            <Button
                                data-testid="reserve-time-btn"
                                onClick={() => handleHeaderAction(true)}
                                disabled={
                                    !(
                                        selectedInstructors &&
                                        selectedInstructors?.size > 0
                                    )
                                }
                            >
                                Block time
                            </Button>
                        )}
                        no={() => <div></div>}
                    />
                </SpaceBetween>
            </Box>
        </>
    );
};

const InstructorAssignmentHeaderControls = ({
    selectedViewId,
    next,
    previous,
    title,
    changeView,
}: HeaderControls) => {
    return (
        <>
            <Box>
                <SpaceBetween direction="horizontal" size="xs">
                    <CalendarControls {...{ previous, next }} />
                </SpaceBetween>
            </Box>
            <Box variant="h2">{title}</Box>
            <HeaderChangeView {...{ selectedViewId, changeView }} />
        </>
    );
};

const getHeaderControls = (headerControlProps: HeaderControls) => {
    const { location: { pathname = '' } = {} } = headerControlProps;
    if (isMatchingPath(pathname, ACTIVITY_PATH.INSTRUCTORS_LIST)) {
        return <InstructorListHeaderControls {...headerControlProps} />;
    }

    if (isMatchingPath(pathname, ACTIVITY_PATH.EDIT_DELIVERY_DETAILS)) {
        return <InstructorAssignmentHeaderControls {...headerControlProps} />;
    }
    return null;
};

const CalendarHeader = ({
    calendarRef,
    handleQueryChange,
    filteringOptions,
    instructorFilteringProperties,
    query,
    instructorCountText,
    filteringPlaceholder,
    handleHeaderAction,
    selectedInstructors,
    paginationProps,
    savedFiltersComponent,
    handlePreferencesChange,
}: InstructorCalendarHeaderPropsData) => {
    const [title, setTitle] = useState('');
    const [selectedViewId, setSelectedViewId] = useState(
        INITIAL_CALENDAR_VIEW_TYPE,
    );
    const dispatch = useDispatch();
    const calendarApi = useMemo(() => {
        if (calendarRef?.getApi) {
            return calendarRef?.getApi();
        }

        return undefined;
    }, [calendarRef]);

    const changeView = (viewType: FCResourceViewTypes) => {
        setSelectedViewId(viewType);
        calendarApi?.changeView(viewType);
        const startTimestamp = dayjs(calendarApi?.view.activeStart).unix();
        const endTimestamp = dayjs(calendarApi?.view.activeEnd).unix();
        dispatch(setStartTimeStamp(startTimestamp));
        dispatch(setEndTimeStamp(endTimestamp));
        updateTitle();
    };

    const updateTimestamp = () => {
        const startTimestamp = dayjs(calendarApi?.view.activeStart).unix();
        const endTimestamp = dayjs(calendarApi?.view.activeEnd).unix();
        dispatch(setStartTimeStamp(startTimestamp));
        dispatch(setEndTimeStamp(endTimestamp));
    };

    const next = () => {
        calendarApi?.next();
        updateTimestamp();
        updateTitle();
    };

    const previous = () => {
        const calendarApi = calendarRef?.getApi();
        calendarApi?.prev();
        updateTimestamp();
        updateTitle();
    };

    const updateTitle = useCallback(() => {
        setTitle(calendarApi?.view.title || '');
    }, [calendarApi]);

    const location = useLocation();

    useEffect(() => {
        updateTitle();
    }, [updateTitle]);

    const headerControlProps = {
        selectedViewId,
        changeView,
        title,
        next,
        previous,
        location,
        handleHeaderAction,
        selectedInstructors,
        savedFiltersComponent,
    };
    return (
        <SpaceBetween
            data-testid={'AssignInstructorHeader'}
            direction="vertical"
            size="m"
        >
            <div className={'calendar-controls'}>
                {getHeaderControls(headerControlProps)}
            </div>
            <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
                <PropertyFilter
                    data-testid={`AssignInstructorHeaderPropertyFilter`}
                    onChange={({ detail }) => {
                        handleQueryChange(detail);
                    }}
                    query={query}
                    i18nStrings={createPropertyFilterI18nStrings({
                        filteringAriaLabel: filteringPlaceholder,
                        filteringPlaceholder: filteringPlaceholder,
                    })}
                    countText={instructorCountText}
                    filteringOptions={filteringOptions}
                    hideOperations={true}
                    filteringProperties={instructorFilteringProperties}
                />

                <div className="assign-instructor-controls">
                    <div className="pagination-list">
                        <Pagination
                            data-testid={`AssignInstructorHeaderPagination`}
                            ariaLabels={{
                                nextPageLabel: 'Next page',
                                previousPageLabel: 'Previous page',
                                pageLabel: (pageNumber) =>
                                    `Page ${pageNumber} of all pages`,
                            }}
                            currentPageIndex={
                                paginationProps?.currentPageIndex
                                    ? paginationProps.currentPageIndex
                                    : 0
                            }
                            onChange={({ detail }) =>
                                paginationProps?.onPaginationChange(
                                    detail.currentPageIndex,
                                )
                            }
                            pagesCount={
                                paginationProps?.pagesCount
                                    ? paginationProps?.pagesCount
                                    : 0
                            }
                        />
                    </div>
                    {handlePreferencesChange && (
                        <div className="preferences-button">
                            <CollectionPreferences
                                title="Assign instructor list preferences"
                                cancelLabel="Cancel"
                                confirmLabel="Confirm"
                                onConfirm={(event) =>
                                    handlePreferencesChange(event.detail)
                                }
                                preferences={{
                                    pageSize: paginationProps.pageSize,
                                }}
                                pageSizePreference={{
                                    title: 'Select page size',
                                    options: [
                                        { value: 25, label: '25 instructors' },
                                        { value: 50, label: '50 instructors' },
                                        {
                                            value: 100,
                                            label: '100 instructors',
                                        },
                                        {
                                            value: 200,
                                            label: '200 instructors',
                                        },
                                    ],
                                }}
                            />
                        </div>
                    )}
                </div>
            </Grid>
        </SpaceBetween>
    );
};

export default CalendarHeader;

import React from 'react';

import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    Alert,
    ColumnLayout,
} from '@amzn/awsui-components-react-v3';

interface ValidateInstructorQualificationsModalProps {
    visible: boolean;
    onCancel: any;
    onConfirm: any;
    instructorConflict: any;
    formValues: any;
    missingQualifications: Array<string> | null;
}

const CONFIRM_ASSIGNMENT_TEXT = 'Confirm assignment';

const ValidateInstructorQualificationsModal = ({
    onCancel,
    onConfirm,
    instructorConflict,
    visible,
    formValues,
    missingQualifications,
}: ValidateInstructorQualificationsModalProps) => {
    return (
        <Modal
            onDismiss={() => onCancel}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmQualificationsModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmQualificationsCancel`}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmQualificationsConfirm`}
                            onClick={() => {
                                onConfirm();
                            }}
                        >
                            {CONFIRM_ASSIGNMENT_TEXT}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={CONFIRM_ASSIGNMENT_TEXT}
        >
            <Alert
                onDismiss={() => {}}
                visible={true}
                dismissAriaLabel="Close alert"
                type="warning"
            >
                {`${instructorConflict?.instructor?.full_name}`} does not have
                the recommended qualifications for this activity. Continue
                assigning them?
            </Alert>
            <ColumnLayout>
                <div
                    data-awsui-column-layout-root="true"
                    className="reassign-adjacent-activity"
                >
                    <span className="reassign-adjacent-activity-title">
                        Recommended qualification:
                    </span>
                    {missingQualifications &&
                        missingQualifications.map(
                            (missingQualification, index) => {
                                return (
                                    <span
                                        key={`missing-qualification-${index}`}
                                    >
                                        {missingQualification ===
                                            'wrong_language' && (
                                            <span>
                                                Delivery language:{' '}
                                                {formValues.delivery_language}
                                            </span>
                                        )}
                                        {missingQualification ===
                                            'wrong_country' && (
                                            <span>
                                                Delivery country:{' '}
                                                {formValues.delivery_country}
                                            </span>
                                        )}
                                        {missingQualification ===
                                            'wrong_course' && (
                                            <span>
                                                Approved course:{' '}
                                                {formValues.course_name}
                                            </span>
                                        )}
                                        {missingQualification ===
                                            'instructor_status_pending' && (
                                            <span>
                                                AAI status: Not authorized
                                            </span>
                                        )}
                                    </span>
                                );
                            },
                        )}
                </div>
            </ColumnLayout>
        </Modal>
    );
};

export default ValidateInstructorQualificationsModal;

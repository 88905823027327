import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { AppDispatch, GlobalState } from '../../../main/store';
import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { CourseResponseData } from '../../../common/interfaces/businessDataResponse/courseResponse';
import { MAX_RETRY_TIME, RETRY_DELAY } from '../../../common/constants/grimsby';
import { REACH_MAX_RETRY_ATTEMPTS } from '../../../common/constants/errorMessage';
import { ApiError } from '../../../common/classes/ApiError';
import businessDataApi from '../../../common/api/businessDataApi';
import { AdminCreateCoursePayloadData } from '../../../common/interfaces/adminCreateBusinessDataPayload/coursePayload';
import { AdminUpdateCoursePayloadData } from '../../../common/interfaces/adminUpdateBusinessDataPayload';
import { CourseItemData } from '../../../common/interfaces/businessDataItem/courseItem';
import { CourseCreateResponseData } from '../../../common/interfaces/businessDataResponse/courseCreateResponse';

export interface CourseState extends GenericStoreState {
    selectedCourse: CourseResponseData | null;
    showDeactivateModal: boolean;
    active: boolean | null;
}

/**
 * selectedCourseSlice manages all selectedCourse state, and contains selectedCourse actions as well as selectedCourse state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const selectedCourseSlice = createSlice({
    name: 'selectedCourse',
    initialState: {
        selectedCourse: null,
        error: null,
        isLoading: false,
        showDeactivateModal: false,
        active: null,
    } as CourseState,
    reducers: {
        setSelectedCourse: (
            state,
            action: PayloadAction<CourseResponseData | null>,
        ) => {
            state.selectedCourse = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setShowDeactivateModal: (state, action: PayloadAction<boolean>) => {
            state.showDeactivateModal = action.payload;
        },
        setActive: (state, action: PayloadAction<boolean>) => {
            state.active = action.payload;
        },
    },
});

export const {
    setError,
    setSelectedCourse,
    setIsLoading,
    setShowDeactivateModal,
} = selectedCourseSlice.actions;

export const getSelectedCourse = (id: string) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        dispatch(setIsLoading(true));
        const state = getState();
        let count = MAX_RETRY_TIME;
        while (count) {
            try {
                const result: HandleRequestData<CourseResponseData> =
                    await businessDataApi.getCourseById(id);
                if (result.result.COURSE) {
                    dispatch(setError(null));
                    dispatch(setSelectedCourse(result.result));
                    break;
                }
            } catch (error: any) {
                if (!(error instanceof ApiError && error?.statusCode === 404)) {
                    dispatch(setError(error!.toString()));
                    break;
                }
            }
            count--;
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }

        if (!count) {
            dispatch(setError(REACH_MAX_RETRY_ATTEMPTS));
        }

        dispatch(setIsLoading(false));
    };
};

export const addCourse = (newCourseData: AdminCreateCoursePayloadData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<{ isSuccessful: boolean; createdCourseId?: string }> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            const { result }: HandleRequestData<CourseCreateResponseData> =
                await businessDataApi.addCourse(newCourseData);
            dispatch(setIsLoading(false));
            return {
                isSuccessful: true,
                createdCourseId: result?.pk,
            };
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return {
                isSuccessful: false,
            };
        }
    };
};

export const updateCourse = (body: CourseItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        let isSuccessful: boolean = false;

        const {
            course,
            active,
            pk: uuid,
            associated_programs,
            associated_certifications,
            course_category,
            course_domain,
            course_days,
            short_name,
            sku_id,
            course_milestones,
            expiry_date,
            course_skill_level,
            course_modality,
            course_content,
            locales,
        } = body;

        try {
            await businessDataApi.updateCourse({
                course,
                active,
                uuid,
                course_category,
                course_domain,
                associated_programs,
                associated_certifications,
                course_days: `${course_days}`,
                short_name,
                sku_id,
                course_milestones,
                expiry_date,
                course_skill_level,
                course_modality,
                course_content,
                locales,
            } as AdminUpdateCoursePayloadData);
            const selectedCourse = state.selectedCourse
                .selectedCourse as CourseResponseData;
            const updatedSelectedCourse = {
                ...selectedCourse,
                COURSE: {
                    course,
                    active,
                    course_category,
                    course_domain,
                    associated_programs,
                    associated_certifications,
                    course_days: `${course_days}`,
                    short_name,
                    sku_id,
                    course_milestones,
                    expiry_date,
                    course_skill_level,
                    course_modality,
                    course_content,
                    locales,
                },
            };
            dispatch(setSelectedCourse(updatedSelectedCourse));
            isSuccessful = true;
        } catch (error: any) {
            dispatch(setError(error!.toString()));
        } finally {
            dispatch(setIsLoading(false));
            return isSuccessful;
        }
    };
};

export const selectSelectedCourse = (state: GlobalState) =>
    state.selectedCourse.selectedCourse;
export const selectIsLoading = (state: GlobalState) =>
    state.selectedCourse.isLoading;
export const selectShowDeactivateModal = (state: GlobalState) =>
    state.selectedCourse.showDeactivateModal;
export const selectError = (state: GlobalState) => state.selectedCourse.error;

export default selectedCourseSlice.reducer;

import React, { useCallback } from 'react';
import { Flashbar } from '@amzn/awsui-components-react';

export interface GrimsbyNotification extends Flashbar.MessageDefinition {
    id: string;
    type: 'success' | 'error' | 'warning' | 'info';
    content?: any;
}

export interface UseNotifications {
    notifications: GrimsbyNotification[];
    addNotification: (notification: GrimsbyNotification) => void;
    removeNotification: (id: string) => void;
    removeAllNotifications: () => void;
}

const NotificationsContext = React.createContext<UseNotifications | undefined>(
    undefined,
);

NotificationsContext.displayName = 'GrimsbyNotifications';

/**
 * NotificationsProvider was created to house global grimsby notification logic,
 * as notifications and polaris Flashbar didn't fit nicely with redux due to the fact we needed to store
 * non-serializable data, and we needed to be able to reference/trigger a reducer
 * function within another reducer function, which is frowned upon.
 * This allows us to provide NotificationsContext to the entire app, and utilize the
 * useNotifications hook from any component within the application
 */
const NotificationsProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const [notifications, setNotifications] = React.useState<
        GrimsbyNotification[]
    >([]);

    const removeAllNotifications = useCallback((): void => {
        setNotifications([]);
    }, []);

    const removeNotification = (id: string): void => {
        setNotifications((prev) =>
            prev.filter((notification) => id !== notification.id),
        );
    };

    const addNotification = ({
        dismissible = true,
        ...notification
    }: GrimsbyNotification): void => {
        const modified: GrimsbyNotification = {
            ...notification,
            dismiss: () => {
                notification.dismiss && notification.dismiss();
                removeNotification(notification.id);
            },
            dismissible,
        };
        setNotifications((prev) => [modified, ...prev]);
    };

    const notificationData = {
        notifications,
        addNotification,
        removeNotification,
        removeAllNotifications,
    };

    return (
        <NotificationsContext.Provider value={notificationData}>
            {children}
        </NotificationsContext.Provider>
    );
};

/**
 * useNotification is a hook that allows components within NotificationsProvider context to access the following:
 * addNotification - a method to add a notification to the app wide flashbar
 * removeNotification - a method to remove a notification from the app wide flashbar (taken care of in notification.dismiss)
 * notifications - a list of currently displayed notifications
 */
const useNotifications = (): UseNotifications => {
    const context = React.useContext(NotificationsContext);
    if (context === undefined) {
        throw new Error(
            'useNotifications must be used within NotificationsProvider context; ' +
                'Wrap component with NotificationsProvider',
        );
    }
    return context;
};

export { NotificationsProvider, useNotifications };

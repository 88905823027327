import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MilestoneTypesResponseData } from '../../interfaces/businessDataResponse/milestoneTypesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { MilestoneTypeItemData } from '../../interfaces/businessDataItem/milestoneTypeItem';
import parseBoolean from '../../utils/parseBoolean';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';

/**
 * milestoneTypesSlice manages all milestoneTypes state, and contains milestoneTypes actions as well as milestoneTypes state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const milestoneTypesSlice = createSlice({
    name: 'milestoneTypes',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<MilestoneTypeItemData>,
    reducers: {
        setMilestoneTypesList: (
            state,
            action: PayloadAction<MilestoneTypeItemData[]>,
        ) => {
            const byMilestoneTypeName = action.payload.reduce(
                (
                    byMilestoneTypeName: {
                        [key: string]: MilestoneTypeItemData;
                    },
                    milestoneType: MilestoneTypeItemData,
                ) => {
                    byMilestoneTypeName[milestoneType.milestone_type] = {
                        ...milestoneType,
                        active: parseBoolean(milestoneType.active),
                    };
                    return byMilestoneTypeName;
                },
                {},
            );
            state.entities = byMilestoneTypeName;
            state.keysList = Object.keys(byMilestoneTypeName);
        },
        setMilestoneType: (
            state,
            action: PayloadAction<MilestoneTypeItemData>,
        ) => {
            // this reducer may be used when adding a new milestone or updating an existing one.
            // only add to list and update count if adding a new milestone
            if (!state.entities[action.payload.milestone_type]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.milestone_type,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.milestone_type] = action.payload;
        },
        setSelectedMilestoneType: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetMilestoneTypesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setMilestoneTypesList,
    setMilestoneType,
    setSelectedMilestoneType,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    resetMilestoneTypesSlice,
} = milestoneTypesSlice.actions;

const handleMilestoneTypesListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { MILESTONE_TYPE, total_count },
            }: HandleRequestData<MilestoneTypesResponseData> =
                await businessDataApi.getMilestoneTypes({ active: 'all' });
            dispatch(setMilestoneTypesList(MILESTONE_TYPE));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getMilestoneTypes API error'));
        }
    };
};

export const getMilestoneTypesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleMilestoneTypesListRequest());
        if (!state.milestoneTypes.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update milestone
 * before update api call, fetch milestone types and prepare payload containing only the changed fields
 * after update api call, fetch milestone types and update the current item in that list with the item data we updated
 * @param data
 */
export const updateMilestoneType = (data: MilestoneTypeItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the list into loading while we finish updating the item/fetching the list
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new milestone: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateMilestoneType(data.pk, data.active);
            await dispatch(handleMilestoneTypesListRequest());
            // force-update the item in the list for now, since the list we fetched may not have this item's update yet
            dispatch(
                setMilestoneType({
                    ...state.milestoneTypes.entities[data.milestone_type],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addMilestoneType = (active = true, milestoneTypeName: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addMilestoneType(milestoneTypeName, active);
            // now refresh the list
            await dispatch(handleMilestoneTypesListRequest());
            if (!state.milestoneTypes?.entities[milestoneTypeName]) {
                // shove the item into the list, but only if it's not there already
                const newMilestoneData: MilestoneTypeItemData = {
                    milestone_type: milestoneTypeName,
                    active: active,
                };
                dispatch(setMilestoneType(newMilestoneData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllMilestoneTypes = (state: GlobalState) => {
    return state.milestoneTypes.keysList.map(
        (milestoneTypeName) => state.milestoneTypes.entities[milestoneTypeName],
    );
};

export const selectAllActiveMilestoneTypes = (
    state: GlobalState,
): Array<MilestoneTypeItemData> => {
    return getActiveBusinessDataValues(
        state.milestoneTypes.keysList,
        state.milestoneTypes.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.milestoneTypes.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.milestoneTypes.isLoaded;

export const selectSelectedMilestoneType = (state: GlobalState) => {
    return state?.milestoneTypes?.selectedItemKey
        ? state?.milestoneTypes?.entities[
              state?.milestoneTypes?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) => state.milestoneTypes.error;

export const selectCount = (state: GlobalState) => state.milestoneTypes.count;

export const selectSearchText = (state: GlobalState) =>
    state.milestoneTypes.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.milestoneTypes.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.milestoneTypes.currentPageIndex;

export default milestoneTypesSlice.reducer;

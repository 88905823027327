/**
 * Method to append api base url for the current domain
 * @return {string} Returns the api base url
 */
const getApiUrl = () => {
    const url = window.location.origin;
    if (url.startsWith('http://localhost'))
        return process.env.REACT_APP_LOCAL_API_URL;

    return url.replace('https://', 'https://api.');
};

export default getApiUrl;

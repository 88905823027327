import { BlockedTimeResponseData } from '../../imt/interfaces/blockedTimeResponse';
import { MeridiemFieldValue } from '../../smt/interfaces/meridiem';
import { RecommendedInstructorProfileData } from '../../smt/interfaces/recommendedInstructorProfile';

export enum BlockedTimeType {
    UNAVAILABLE = 'Unavailable',
    AVAILABLE = 'Available',
}

export interface BlockedTimeTimestamp {
    start_timestamp: number;
    end_timestamp: number;
    removeFlag?: boolean;
}

export interface BlockedTime {
    pk: string;
    blocked_time_type:
        | BlockedTimeType.AVAILABLE
        | BlockedTimeType.UNAVAILABLE
        | string;
    blocked_time_name: string;
    blocked_time_notes?: string;
    blocked_time_timestamps: Array<BlockedTimeTimestamp>;
    instructor_pk: string;
}

export interface BlockedTimeFormData extends BlockedTime {
    blockedTimes: Array<BlockTimeTimeAttributeEditorItem>;
}

export interface BlockTimeTimeAttributeEditorItem {
    id: string;
    startDateString: string;
    endDateString: string;
    startTime: string;
    endTime: string;
    endTimeMeridiemFieldOption: MeridiemFieldValue;
    startTimeMeridiemFieldOption: MeridiemFieldValue;
    removeFlag?: boolean;
}

export interface BlockedTimeAttributes
    extends BlockTimeTimeAttributeEditorItem {
    blocked_time_name: string;
}

export interface BlockedTimeSuccessResponseData
    extends BlockedTimeResponseData {
    blockedTime: BlockedTime;
    instructorId: string;
}

export interface BlockedTimeCalendarEvent {
    blockedTime: BlockedTime;
    instructor: RecommendedInstructorProfileData;
}

export interface BlockedTimeError {
    message: string;
}

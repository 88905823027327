import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import {
    FormField,
    Button,
    Box,
    Container,
    Input,
    Select,
    SpaceBetween,
    Header,
    TimeInput,
} from '@amzn/awsui-components-react-v3';
import { useDispatch } from 'react-redux';

import SessionDetails from './SessionDetails';
import { meridiemFieldOptionsV3 } from '../../../../interfaces/meridiem';
import { useActivityGroup } from '../hooks';
import {
    activityGroupSlice,
    cloneDeliveryOptionWithUser,
} from '../../../../store/slices/activityGroupSlice';
import { valueToOption } from '../../../../../common/utils/cloudscapeHelpers';
import { ActivityStatus } from '../../Common/Common';

interface DeliveryOptionProps {
    deliveryName: string;
    optionNumber: number;
    weekStartDate: string;
    isReadOnlyMode: boolean;
}

const DeliveryOption = ({
    optionNumber,
    deliveryName,
    weekStartDate,
    isReadOnlyMode,
}: DeliveryOptionProps) => {
    const activityGroup = useActivityGroup();
    const dispatch = useDispatch();
    let deliveryOptionName = `${deliveryName} (Option ${optionNumber})`;

    const weekStartDay = dayjs.tz(
        dayjs(weekStartDate, 'YYYY/MM/DD'),
        activityGroup.selected_timezone,
    );
    const weekEndDay = weekStartDay.add(1, 'week');

    const deliveryOptionActivity = activityGroup.child_activities.find(
        (activity) =>
            activity.delivery_name === deliveryName &&
            activity.option_number === optionNumber,
    );

    const isActivityCanceled =
        deliveryOptionActivity.activity_status === ActivityStatus.Canceled;
    if (isActivityCanceled) {
        deliveryOptionName = '[Canceled] ' + deliveryOptionName;
    }
    const isReadOnly = isReadOnlyMode || isActivityCanceled;

    const weekDeliverySessions =
        deliveryOptionActivity.delivery_sessions.filter((session) => {
            return (
                session.start_timestamp >= weekStartDay.unix() &&
                session.start_timestamp < weekEndDay.unix()
            );
        });

    const templateSession = useMemo(() => {
        if (weekDeliverySessions.length > 0) {
            return [...weekDeliverySessions]
                .sort((a, b) => a.start_timestamp - b.start_timestamp)
                .pop();
        }

        return null;
    }, [weekDeliverySessions]);

    const lowestSessionStart = deliveryOptionActivity.delivery_sessions.reduce(
        (acc, session) => {
            const [lower, _] = [session.start_timestamp, acc].sort();
            return acc === 0 ? session.start_timestamp : lower;
        },
        0,
    );
    const highestSessionEnd = deliveryOptionActivity.delivery_sessions.reduce(
        (acc, session) => {
            return session.end_timestamp > acc ? session.end_timestamp : acc;
        },
        0,
    );

    const getTimeFromDay = (day: dayjs.Dayjs) =>
        dayjs.tz(day, activityGroup.selected_timezone).format('hh:mm');
    const getMeridiemFromDay = (day: dayjs.Dayjs) =>
        dayjs.tz(day, activityGroup.selected_timezone).format('A');
    const getHrsMinsFromTime = (time: string) =>
        time.split(':').map((val) => parseInt(val));

    const optionStartDay = dayjs.unix(lowestSessionStart);
    const optionEndDay = dayjs.unix(highestSessionEnd);

    const [optionStartTime, setOptionStartTime] = useState(
        getTimeFromDay(optionStartDay),
    );
    const [optionEndTime, setOptionEndTime] = useState(
        getTimeFromDay(optionEndDay),
    );
    const [startMeridiem, setStartMeridiem] = useState<string>(
        getMeridiemFromDay(optionStartDay),
    );
    const [endMeridiem, setEndMeridiem] = useState<string>(
        getMeridiemFromDay(optionEndDay),
    );

    const removeOption = () =>
        dispatch(
            activityGroupSlice.actions.removeDeliveryOption({
                deliveryName,
                optionNumber,
            }),
        );
    const cloneOption = () =>
        dispatch(cloneDeliveryOptionWithUser(deliveryName, optionNumber));

    const handleOptionTimeUpdate = (
        time: string,
        timeField: 'start_timestamp' | 'end_timestamp',
        meridiem: string,
    ) => {
        const [hours, minutes] = getHrsMinsFromTime(time);

        const meridiemOffsetHours = meridiem === 'PM' && hours < 12 ? 12 : 0;

        deliveryOptionActivity.delivery_sessions.forEach((session) => {
            const sessionStartDay = dayjs.tz(
                dayjs.unix(session[timeField]),
                activityGroup.selected_timezone,
            );
            const sessionDayStart = sessionStartDay.startOf('day');
            const updatedSessionTime = sessionDayStart
                .set('hours', hours + meridiemOffsetHours)
                .set('minutes', minutes)
                .unix();

            if (updatedSessionTime) {
                dispatch(
                    activityGroupSlice.actions.setDeliverySession({
                        deliveryName,
                        optionNumber,
                        deliverySession: {
                            ...session,
                            [timeField]: updatedSessionTime,
                        },
                    }),
                );
            }
        });
    };

    const getDefaultSessionStartTime = () => {
        if (templateSession) {
            return templateSession.start_timestamp;
        }

        const [hours, minutes] = getHrsMinsFromTime(optionStartTime);
        return weekStartDay.set('hours', hours).set('minutes', minutes).unix();
    };

    const getDefaultSessionEndTime = () => {
        if (templateSession) {
            return templateSession.end_timestamp;
        }

        const [hours, minutes] = getHrsMinsFromTime(optionEndTime);
        return weekStartDay.set('hours', hours).set('minutes', minutes).unix();
    };

    const containerHeader = (
        <Header
            variant="h3"
            actions={
                !isReadOnly && (
                    <SpaceBetween size="s" direction="horizontal">
                        <Button
                            onClick={() => cloneOption()}
                            data-testid={`delivery-${deliveryName}-option-${optionNumber}-clone`}
                        >
                            Clone
                        </Button>

                        {optionNumber > 1 && (
                            <Button onClick={() => removeOption()}>
                                Delete
                            </Button>
                        )}
                    </SpaceBetween>
                )
            }
        >
            {deliveryOptionName}
        </Header>
    );

    return (
        <Container header={containerHeader}>
            <Box padding={{ bottom: 's' }}>
                <SpaceBetween size="s" direction="horizontal">
                    <FormField label="Start Time">
                        <SpaceBetween size="xs" direction="horizontal">
                            <TimeInput
                                value={optionStartTime}
                                format="hh:mm"
                                placeholder="hh:mm"
                                use24Hour={false}
                                disabled={isReadOnly}
                                onChange={({ detail }) => {
                                    handleOptionTimeUpdate(
                                        detail.value,
                                        'start_timestamp',
                                        startMeridiem,
                                    );
                                    setOptionStartTime(detail.value);
                                }}
                                data-testid={`option-${optionNumber}-start-time-input`}
                            ></TimeInput>
                            <Select
                                options={meridiemFieldOptionsV3}
                                selectedOption={valueToOption(startMeridiem)}
                                disabled={isReadOnly}
                                onChange={({ detail }) => {
                                    setStartMeridiem(
                                        detail.selectedOption.label,
                                    );
                                    handleOptionTimeUpdate(
                                        optionStartTime,
                                        'start_timestamp',
                                        detail.selectedOption.label,
                                    );
                                }}
                                data-testid={`option-${optionNumber}-start-time-meridiem-select`}
                            ></Select>
                        </SpaceBetween>
                    </FormField>

                    <FormField label="End Time">
                        <SpaceBetween size="xs" direction="horizontal">
                            <TimeInput
                                value={optionEndTime}
                                format="hh:mm"
                                placeholder="hh:mm"
                                use24Hour={false}
                                disabled={isReadOnly}
                                onChange={({ detail }) => {
                                    handleOptionTimeUpdate(
                                        detail.value,
                                        'end_timestamp',
                                        endMeridiem,
                                    );
                                    setOptionEndTime(detail.value);
                                }}
                                data-testid={`option-${optionNumber}-end-time-input`}
                            ></TimeInput>
                            <Select
                                options={meridiemFieldOptionsV3}
                                selectedOption={valueToOption(endMeridiem)}
                                disabled={isReadOnly}
                                onChange={({ detail }) => {
                                    setEndMeridiem(detail.selectedOption.label);
                                    handleOptionTimeUpdate(
                                        optionEndTime,
                                        'end_timestamp',
                                        detail.selectedOption.label,
                                    );
                                }}
                                data-testid={`option-${optionNumber}-end-time-meridiem-select`}
                            ></Select>
                        </SpaceBetween>
                    </FormField>

                    <FormField label="Class Size">
                        <Input
                            value={`${deliveryOptionActivity.class_size || ''}`}
                            disabled={isReadOnly}
                            onChange={({ detail }) => {
                                dispatch(
                                    activityGroupSlice.actions.setDeliveryOption(
                                        {
                                            deliveryName,
                                            optionNumber,
                                            class_size: detail.value
                                                ? parseInt(detail.value)
                                                : 0,
                                        },
                                    ),
                                );
                            }}
                            placeholder="0"
                            inputMode="numeric"
                            data-testid={`option-${optionNumber}-class-size-input`}
                        ></Input>
                    </FormField>
                </SpaceBetween>
            </Box>

            <SessionDetails
                sessions={weekDeliverySessions}
                deliveryName={deliveryName}
                optionNumber={optionNumber}
                optionStartTime={optionStartTime}
                optionStartTimeMeridiem={startMeridiem}
                optionEndTime={optionEndTime}
                optionEndTimeMeridiem={endMeridiem}
                defaultSessionStartTime={getDefaultSessionStartTime()}
                defaultSessionEndTime={getDefaultSessionEndTime()}
                isReadOnlyMode={isReadOnly}
                selectedWeekStartDay={weekStartDay}
                selectedWeekEndDay={weekEndDay}
            />
        </Container>
    );
};

export default DeliveryOption;

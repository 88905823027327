import { AJAX_CALL_ERROR } from '../constants/grimsby';
import policies, { Roles, Actions } from '../constants/auth';
import { USER_MANAGEMENT_API_ERROR_MESSAGE } from '../constants/errorMessage';

import { RedirectionData } from '../interfaces/redirection';
import { UserResponseData } from '../interfaces/userResponse';
import { Cache } from '@aws-amplify/cache';

/**
 * Method to read frontend role from Amplify Cache
 */
export const getUserRoles = (): Array<Roles> => {
    const roles = Cache.getItem('userProfile').roles;
    if (roles) {
        return roles;
    }
    return [];
};

/**
 * Check if given role can do given action
 */
export const check = (roles: Array<Roles>, action: Actions): boolean => {
    let isAllowed = false;
    roles.forEach((role) => {
        const permissions = policies[role];
        if (!isAllowed) {
            if (permissions && permissions.includes(action)) {
                isAllowed = true;
            }
        }
    });
    return isAllowed;
};

/**
 * Check if given user details are sufficient
 */
export const checkUserDetails = (
    userDetailsIsLoaded: boolean,
    userDetailsError: any,
    userDetailsStatusCode: number | null,
    userDetails: UserResponseData | null,
): RedirectionData => {
    if (!userDetailsIsLoaded) {
        return {
            redirect: false,
        };
    }
    // userDetailsError is truthy whenever the get user API returned a non-200 statusCode
    if (userDetailsError) {
        const { NOT_ACTIVE_USER, NO_USER } =
            USER_MANAGEMENT_API_ERROR_MESSAGE.LOAD_USER;
        switch (userDetailsStatusCode) {
            case NOT_ACTIVE_USER.STATUS_CODE:
                return {
                    redirect: true,
                    message: NOT_ACTIVE_USER.MESSAGE,
                };
            case NO_USER.STATUS_CODE:
                return {
                    redirect: true,
                    message: NO_USER.MESSAGE,
                };
            default:
                return {
                    redirect: true,
                    message: AJAX_CALL_ERROR,
                };
        }
    } else if (
        !(userDetails?.profile?.programs && userDetails?.profile?.regions)
    ) {
        return {
            redirect: true,
            message:
                USER_MANAGEMENT_API_ERROR_MESSAGE.LOAD_USER
                    .NO_PROGRAMS_OR_NO_REGIONS.MESSAGE,
            path: '/welcome',
        };
    } else {
        return {
            redirect: false,
        };
    }
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    AttributeEditor,
    ColumnLayout,
    CustomDetailEvent,
    DatePicker,
    FormField,
    Input,
    Select,
} from '@amzn/awsui-components-react';

import {
    booleanFieldOptionLookup,
    booleanFieldOptions,
    booleanFieldOptionsBoolLookup,
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from './FormSections.common';
import { FormSectionMode } from '../../../../common/constants/forms';
import {
    getAtpCompaniesList,
    initializeAtpCompaniesListQueryParams,
    resetAtpCompaniesSlice,
    resetPartialAtpCompaniesSlice,
    selectAllAtpCompanies,
    selectError as selectAtpError,
    selectIsLoaded as selectAtpLoaded,
    selectIsLoading as selectAtpLoading,
    selectSearchText,
    setSearchText,
} from '../../../../common/store/slices/atpCompaniesSlice';
import {
    getFreelancerStatusesList,
    resetFreelancerStatusesSlice,
    selectAllFreelancerStatuses,
    selectError,
    selectIsLoaded,
    selectIsLoading,
} from '../../../../common/store/slices/freelancerStatusesSlice';
import { useDispatch, useSelector } from 'react-redux';
import handlePreSelectedValue from '../../../../common/utils/handlePreSelectedValue';
import {
    getIsExternalInstructor,
    InstructorFormSectionProps,
    InstructorTypeValues,
    ProviderAttributeEditorItem,
} from '../Common/Common';
import { FreelancerStatusItemData } from '../../../../common/interfaces/businessDataItem/freelancerStatusItem';
import { AtpCompanyItemData } from '../../../../common/interfaces/businessDataItem/atpCompanyItem';
import { GRIMSBY_FILTER_RESULTS_COUNT } from '../../../../common/constants/grimsby';
import { InstructorProfileData } from '../../../interfaces/instructorProfile';

export type EmploymentStatusValues = 'Employed by ATP' | 'Freelancer';
const freelancerStatusLoadingText = 'Loading freelancer statuses';
const atpCompaniesLoadingText = 'Loading providers';

type AuthAgreementStatusValues = 'None' | 'Sent' | 'Yes' | 'No';
interface AuthAgreementStatusOption extends Select.Option {
    readonly label: AuthAgreementStatusValues;
    readonly id: string;
}
const authAgreementStatusOptions: Array<AuthAgreementStatusOption> = [
    {
        label: 'None',
        id: '0',
    },
    {
        label: 'Sent',
        id: '1',
    },
    {
        label: 'Yes',
        id: '2',
    },
    {
        label: 'No',
        id: '3',
    },
];

const authAgreementStatusOptionsLookup = authAgreementStatusOptions.reduce(
    (acc, opt) => {
        acc[opt.label] = opt;
        return acc;
    },
    {} as {
        [key in AuthAgreementStatusValues]: AuthAgreementStatusOption;
    },
);

const InstructorDetailFormFields = ({
    formValues,
    errors,
    handleFieldEvent,
    mode,
    handleProviderItemEvent,
    handleSponsoringCompanyItemEvent,
    providerAttributeEditorItems,
    sponsoringCompanyItem,
    controlArrayErrors,
}: InstructorFormSectionProps<InstructorProfileData>) => {
    const isFreelanceStatusListLoading = useSelector(selectIsLoading);
    const isFreelanceStatusListLoaded = useSelector(selectIsLoaded);
    const freelanceStatusListError = useSelector(selectError);
    const freelancerStatusList = useSelector(selectAllFreelancerStatuses);
    const isAtpCompaniesListLoading = useSelector(selectAtpLoading);
    const isAtpCompaniesListLoaded = useSelector(selectAtpLoaded);
    const atpCompaniesListError = useSelector(selectAtpError);
    const atpSearchText = useSelector(selectSearchText);
    const originalAtpCompaniesList = useSelector(selectAllAtpCompanies);
    const originalAtpBoolLookup = originalAtpCompaniesList.reduce(
        (acc, preselectedAtp) => {
            acc[preselectedAtp.atp_company] = true;
            return acc;
        },
        {} as { [key: string]: boolean },
    );
    const preselectedAtpValues = [
        {
            active: true,
            atp_company: sponsoringCompanyItem?.providerName as string,
            pk: `atp-${Date.now()}-${sponsoringCompanyItem?.providerName}`,
        },
        {
            active: true,
            atp_company: formValues.freelance_agency,
            pk: `atp-${Date.now()}-${formValues.freelance_agency}`,
        },
        ...providerAttributeEditorItems.map((provider) => ({
            active: true,
            atp_company: provider.providerName as string,
            pk: `atp-${Date.now()}-${provider.providerName}`,
        })),
    ].filter(
        (preselectedAtp) => !!preselectedAtp.atp_company,
    ) as Array<AtpCompanyItemData>;
    const atpPredicate = (atpCompany: AtpCompanyItemData) =>
        !originalAtpBoolLookup[atpCompany.atp_company];
    const atpCompaniesList = handlePreSelectedValue(
        preselectedAtpValues,
        !!preselectedAtpValues.length && isAtpCompaniesListLoaded,
        originalAtpCompaniesList,
        atpPredicate,
    );

    const dispatch = useDispatch();
    const isExternalInstructor = getIsExternalInstructor(
        formValues.instructor_type as InstructorTypeValues,
    );

    const {
        valueLookup: freelancerStatusLookup,
        valueOptions: freelancerStatusOptions,
    } = getOptionsAndLookupForSelectInput<FreelancerStatusItemData>(
        freelancerStatusList,
        (status: FreelancerStatusItemData) => ({
            label: status.freelancer_status,
            id: status.pk as string,
        }),
    );

    const { valueOptions: atpOptions, valueLookup: atpLookup } =
        getOptionsAndLookupForSelectInput<AtpCompanyItemData>(
            atpCompaniesList,
            (atpCompany: AtpCompanyItemData) => ({
                label: atpCompany.atp_company,
                id: atpCompany.pk as string,
            }),
            // for any preselected atp values that don't currently exist in the atp list,
            // we'll keep the lookup value so that any preselected value will appear in
            // selection box, but we'll remove the corresponding option
            new Set<string>([
                ...preselectedAtpValues.reduce((acc, atp) => {
                    const shouldAdd = atpPredicate(atp);
                    if (shouldAdd) {
                        acc.push(atp.atp_company);
                    }
                    return acc;
                }, [] as Array<string>),
            ]),
        );

    // lifecycle method to initialize and reset business data slices
    useEffect(() => {
        // initialize query params for business data slices
        // this code block should only run once
        dispatch(
            initializeAtpCompaniesListQueryParams({
                active: null,
                size: GRIMSBY_FILTER_RESULTS_COUNT,
            }),
        );

        return () => {
            // reset business data slices
            // this code block should only run once
            [resetFreelancerStatusesSlice, resetAtpCompaniesSlice].forEach(
                (resetFunction) => dispatch(resetFunction()),
            );
        };
    }, [dispatch]);

    // lifecycle method to fetch (and re-fetch) business data
    useEffect(() => {
        if (isExternalInstructor) {
            (
                [
                    [
                        !isFreelanceStatusListLoaded &&
                            !isFreelanceStatusListLoading &&
                            !!formValues?.is_freelancer,
                        getFreelancerStatusesList,
                    ],
                    [
                        !isAtpCompaniesListLoaded && !isAtpCompaniesListLoading,
                        getAtpCompaniesList,
                    ],
                ] as ReadonlyArray<[boolean, Function]>
            ).forEach(([shouldFetch, getList]) => {
                if (shouldFetch) {
                    dispatch(getList());
                }
            });
        }
    }, [
        formValues,
        isExternalInstructor,
        isFreelanceStatusListLoaded,
        isFreelanceStatusListLoading,
        isAtpCompaniesListLoaded,
        isAtpCompaniesListLoading,
        dispatch,
    ]);

    const handleAtpDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
    ) => {
        if (e.detail.value !== atpSearchText) {
            dispatch(setSearchText(e.detail.value));
            dispatch(getAtpCompaniesList());
        }
    };

    const providerAttributeEditorDefinition: Array<AttributeEditor.FieldDefinition> =
        [
            {
                label: 'Provider name',
                control: (
                    providerItem: ProviderAttributeEditorItem,
                    index: number,
                ) => (
                    <Select
                        className={
                            controlArrayErrors?.providerAttributeEditorItems?.[
                                index
                            ]?.providerName && FORM_ERROR_SELECTOR
                        }
                        placeholder={
                            mode === FormSectionMode.Edit &&
                            isAtpCompaniesListLoading
                                ? atpCompaniesLoadingText
                                : 'Select a provider name'
                        }
                        empty="No matching provider found"
                        loadingText={atpCompaniesLoadingText}
                        filteringType="manual"
                        errorText="An error occurred while loading providers"
                        recoveryText="Retry"
                        statusType={getStatusType(
                            isAtpCompaniesListLoading,
                            isAtpCompaniesListLoaded,
                            atpCompaniesListError,
                        )}
                        options={atpOptions}
                        selectedOption={
                            atpLookup[providerItem.providerName as string]
                        }
                        filteringPlaceholder="Enter a provider name"
                        onDelayedFilteringChange={
                            handleAtpDelayedFilteringChange
                        }
                        onChange={(
                            e: CustomDetailEvent<Select.ChangeDetail>,
                        ) => {
                            if (e.detail.selectedOption) {
                                if (atpSearchText) {
                                    dispatch(setSearchText(null));
                                    dispatch(getAtpCompaniesList());
                                }
                                const newItems =
                                    providerAttributeEditorItems.slice();
                                newItems.splice(index, 1, {
                                    ...providerItem,
                                    providerName: e.detail.selectedOption.label,
                                    pk: e.detail.selectedOption.id,
                                });
                                handleProviderItemEvent(newItems);
                            }
                        }}
                        onFocus={() => {
                            if (atpSearchText) {
                                dispatch(setSearchText(null));
                            }
                        }}
                        onRecoveryClick={() => {
                            dispatch(setSearchText(''));
                            dispatch(resetPartialAtpCompaniesSlice());
                            dispatch(getAtpCompaniesList());
                        }}
                        disabled={
                            mode === FormSectionMode.Edit &&
                            !isAtpCompaniesListLoaded
                        }
                        data-testid={`${mode}InstructorProvider${index}`}
                    />
                ),
                errorText: (
                    providerItem: ProviderAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors?.providerAttributeEditorItems?.[index]
                        ?.providerName,
            },
            {
                label: 'AWS Classrooms email',
                control: (
                    providerItem: ProviderAttributeEditorItem,
                    index: number,
                ) => (
                    <Input
                        className={
                            controlArrayErrors?.providerAttributeEditorItems?.[
                                index
                            ]?.awsClassroom && FORM_ERROR_SELECTOR
                        }
                        value={providerItem.awsClassroom}
                        onInput={(e) => {
                            const newItems =
                                providerAttributeEditorItems.slice();
                            newItems.splice(index, 1, {
                                ...providerItem,
                                awsClassroom: e.detail.value,
                            });
                            handleProviderItemEvent(newItems);
                        }}
                        data-testid={`${mode}InstructorExternalPoc`}
                    />
                ),
                errorText: (
                    providerItem: ProviderAttributeEditorItem,
                    index: number,
                ) =>
                    controlArrayErrors?.providerAttributeEditorItems?.[index]
                        ?.awsClassroom,
            },
        ];

    return (
        <ColumnLayout data-testid="InstructorDetailsFormFields">
            <div data-awsui-column-layout-root="true">
                {isExternalInstructor ? (
                    <>
                        <div className="grimsby-sub-section-header">
                            Providers
                        </div>
                        <FormField
                            label="Sponsoring company name"
                            errorText={
                                controlArrayErrors?.sponsoringCompanyItems?.[0]
                                    .providerName
                            }
                        >
                            <Select
                                className={
                                    controlArrayErrors
                                        ?.sponsoringCompanyItems?.[0]
                                        .providerName && FORM_ERROR_SELECTOR
                                }
                                placeholder={
                                    mode === FormSectionMode.Edit &&
                                    isAtpCompaniesListLoading
                                        ? atpCompaniesLoadingText
                                        : 'Select a provider name'
                                }
                                empty="No matching providers found"
                                loadingText={atpCompaniesLoadingText}
                                filteringType="manual"
                                errorText="An error occurred while loading providers"
                                recoveryText="Retry"
                                statusType={getStatusType(
                                    isAtpCompaniesListLoading,
                                    isAtpCompaniesListLoaded,
                                    atpCompaniesListError,
                                )}
                                options={atpOptions}
                                selectedOption={
                                    atpLookup[
                                        sponsoringCompanyItem.providerName as string
                                    ]
                                }
                                filteringPlaceholder="Enter a provider name"
                                onDelayedFilteringChange={
                                    handleAtpDelayedFilteringChange
                                }
                                onChange={(
                                    e: CustomDetailEvent<Select.ChangeDetail>,
                                ) => {
                                    if (e.detail.selectedOption) {
                                        if (atpSearchText) {
                                            dispatch(setSearchText(null));
                                            dispatch(getAtpCompaniesList());
                                        }

                                        handleSponsoringCompanyItemEvent({
                                            providerName:
                                                e.detail.selectedOption.label,
                                            pk: e.detail.selectedOption.id,
                                        });
                                    }
                                }}
                                onFocus={() => {
                                    if (atpSearchText) {
                                        dispatch(setSearchText(null));
                                    }
                                }}
                                onRecoveryClick={() => {
                                    dispatch(setSearchText(''));
                                    dispatch(resetPartialAtpCompaniesSlice());
                                    dispatch(getAtpCompaniesList());
                                }}
                                disabled={
                                    mode === FormSectionMode.Edit &&
                                    !isAtpCompaniesListLoaded
                                }
                                data-testid={`${mode}InstructorSponsoringCompanyName`}
                            />
                        </FormField>
                        <FormField
                            label="Sponsoring company contact email"
                            errorText={errors?.external_poc}
                        >
                            <Input
                                className={
                                    errors?.external_poc && FORM_ERROR_SELECTOR
                                }
                                value={formValues.external_poc}
                                onInput={(e) => {
                                    handleFieldEvent({
                                        external_poc: e.detail.value,
                                    });
                                }}
                                data-testid={`${mode}InstructorExternalPoc`}
                            />
                        </FormField>
                        <FormField
                            label="AWS Classrooms email"
                            errorText={
                                controlArrayErrors?.sponsoringCompanyItems?.[0]
                                    .awsClassroom
                            }
                        >
                            <Input
                                className={
                                    controlArrayErrors
                                        ?.sponsoringCompanyItems?.[0]
                                        .awsClassroom && FORM_ERROR_SELECTOR
                                }
                                value={sponsoringCompanyItem.awsClassroom}
                                onInput={(e) =>
                                    handleSponsoringCompanyItemEvent({
                                        awsClassroom: e.detail.value,
                                    })
                                }
                                data-testid={`${mode}InstructorAWSClassroomsEmail`}
                            />
                        </FormField>
                        <FormField>
                            <AttributeEditor
                                data-testid="ProviderAttributeEditor"
                                addButtonText="Add provider"
                                removeButtonText="Remove"
                                items={providerAttributeEditorItems}
                                definition={providerAttributeEditorDefinition}
                                onAddButtonClick={() =>
                                    handleProviderItemEvent([
                                        ...providerAttributeEditorItems,
                                        {
                                            awsClassroom: '',
                                            providerName: '',
                                            pk: '',
                                        },
                                    ])
                                }
                                onRemoveButtonClick={({
                                    detail: { itemIndex },
                                }) => {
                                    const newList =
                                        providerAttributeEditorItems.slice();
                                    newList.splice(itemIndex, 1);
                                    handleProviderItemEvent(newList);
                                }}
                            />
                        </FormField>
                        <div className="grimsby-sub-section-divider" />
                        <div className="grimsby-sub-section-header">
                            Authorizations
                        </div>
                        <FormField
                            label="Authorized instructor agreement status"
                            errorText={
                                errors?.authorized_instructor_agreement_status
                            }
                        >
                            <Select
                                className={
                                    errors?.authorized_instructor_agreement_status &&
                                    FORM_ERROR_SELECTOR
                                }
                                placeholder="Select agreement status"
                                options={authAgreementStatusOptions}
                                selectedOption={
                                    formValues.authorized_instructor_agreement_status
                                        ? authAgreementStatusOptionsLookup[
                                              formValues.authorized_instructor_agreement_status as AuthAgreementStatusValues
                                          ]
                                        : null
                                }
                                onChange={(e) =>
                                    handleFieldEvent({
                                        authorized_instructor_agreement_status:
                                            e.detail.selectedOption.label,
                                    })
                                }
                                data-testid={`${mode}InstructorAuthorizedInstructorAgreementStatus`}
                            />
                        </FormField>
                        <FormField
                            label="AWS authorized candidate fundamentals"
                            errorText={
                                errors?.aws_authorized_candidate_fundamentals
                            }
                        >
                            <Select
                                className={
                                    errors?.aws_authorized_candidate_fundamentals &&
                                    FORM_ERROR_SELECTOR
                                }
                                placeholder="Select authorized candidate fundamentals status"
                                options={booleanFieldOptions}
                                selectedOption={
                                    formValues.aws_authorized_candidate_fundamentals !==
                                        undefined &&
                                    formValues.aws_authorized_candidate_fundamentals !==
                                        null
                                        ? booleanFieldOptionLookup[
                                              formValues.aws_authorized_candidate_fundamentals.toString() as
                                                  | 'true'
                                                  | 'false'
                                          ]
                                        : null
                                }
                                onChange={(e) =>
                                    handleFieldEvent({
                                        aws_authorized_candidate_fundamentals:
                                            booleanFieldOptionsBoolLookup[
                                                e.detail.selectedOption
                                                    .label as 'Yes' | 'No'
                                            ],
                                    })
                                }
                                data-testid={`${mode}InstructorAwsAuthorizedCandidateFundamentals`}
                            />
                        </FormField>
                        <FormField
                            label={
                                <span>
                                    Nondisclosure agreement (NDA) signed{' '}
                                    <i>- optional</i>
                                </span>
                            }
                            errorText={errors?.signed_nda}
                        >
                            <Select
                                className={
                                    errors?.signed_nda && FORM_ERROR_SELECTOR
                                }
                                placeholder="Select NDA status"
                                options={booleanFieldOptions}
                                selectedOption={
                                    formValues.signed_nda !== undefined &&
                                    formValues.signed_nda !== null
                                        ? booleanFieldOptionLookup[
                                              formValues.signed_nda.toString() as
                                                  | 'true'
                                                  | 'false'
                                          ]
                                        : null
                                }
                                onChange={(e) =>
                                    handleFieldEvent({
                                        signed_nda:
                                            booleanFieldOptionsBoolLookup[
                                                e.detail.selectedOption
                                                    .label as 'Yes' | 'No'
                                            ],
                                    })
                                }
                                data-testid={`${mode}InstructorSignedNda`}
                            />
                        </FormField>
                        <div className="grimsby-sub-section-divider" />
                        <div className="grimsby-sub-section-header">
                            Freelance details
                        </div>
                        <FormField
                            label="Is in AWS freelancer program?"
                            errorText={errors?.is_freelancer}
                        >
                            <Select
                                className={
                                    errors?.is_freelancer && FORM_ERROR_SELECTOR
                                }
                                placeholder="Select a value"
                                options={booleanFieldOptions}
                                selectedOption={
                                    formValues.is_freelancer !== undefined &&
                                    formValues.is_freelancer !== null
                                        ? booleanFieldOptionLookup[
                                              formValues.is_freelancer.toString() as
                                                  | 'true'
                                                  | 'false'
                                          ]
                                        : null
                                }
                                onChange={(e) => {
                                    handleFieldEvent({
                                        is_freelancer:
                                            booleanFieldOptionsBoolLookup[
                                                e.detail.selectedOption
                                                    .label as 'Yes' | 'No'
                                            ],
                                    });
                                }}
                                data-testid={`${mode}InstructorIsFreelancer`}
                            />
                        </FormField>
                        {formValues.is_freelancer && (
                            <>
                                <FormField
                                    label="Freelancer status"
                                    errorText={errors?.freelancer_status}
                                >
                                    <Select
                                        className={
                                            errors?.freelancer_status &&
                                            FORM_ERROR_SELECTOR
                                        }
                                        placeholder={
                                            mode === FormSectionMode.Edit &&
                                            isFreelanceStatusListLoading
                                                ? freelancerStatusLoadingText
                                                : 'Select freelancer status'
                                        }
                                        options={freelancerStatusOptions}
                                        selectedOption={
                                            formValues.freelancer_status
                                                ? freelancerStatusLookup[
                                                      formValues
                                                          .freelancer_status
                                                  ]
                                                : null
                                        }
                                        onChange={(e) =>
                                            handleFieldEvent({
                                                freelancer_status:
                                                    e.detail.selectedOption
                                                        .label,
                                            })
                                        }
                                        onRecoveryClick={() => {
                                            dispatch(
                                                resetFreelancerStatusesSlice(),
                                            );
                                            dispatch(
                                                getFreelancerStatusesList(),
                                            );
                                        }}
                                        loadingText={
                                            freelancerStatusLoadingText
                                        }
                                        errorText="An error occurred while loading freelancer statuses"
                                        recoveryText="Retry"
                                        empty="No freelancer statuses found"
                                        disabled={
                                            mode === FormSectionMode.Edit &&
                                            isFreelanceStatusListLoading
                                        }
                                        statusType={getStatusType(
                                            isFreelanceStatusListLoading,
                                            isFreelanceStatusListLoaded,
                                            freelanceStatusListError,
                                        )}
                                        data-testid={`${mode}InstructorFreelancerStatus`}
                                    />
                                </FormField>
                                <FormField
                                    label="Freelance agency/ATP"
                                    errorText={errors?.freelance_agency}
                                >
                                    <Select
                                        placeholder={
                                            mode === FormSectionMode.Edit &&
                                            isAtpCompaniesListLoading
                                                ? atpCompaniesLoadingText
                                                : 'Select a provider name'
                                        }
                                        empty="No matching providers found"
                                        loadingText={atpCompaniesLoadingText}
                                        filteringType="manual"
                                        errorText="An error occurred while loading providers"
                                        recoveryText="Retry"
                                        statusType={getStatusType(
                                            isAtpCompaniesListLoading,
                                            isAtpCompaniesListLoaded,
                                            atpCompaniesListError,
                                        )}
                                        options={atpOptions}
                                        selectedOption={
                                            atpLookup[
                                                formValues.freelance_agency as string
                                            ]
                                        }
                                        filteringPlaceholder="Enter a provider name"
                                        onDelayedFilteringChange={
                                            handleAtpDelayedFilteringChange
                                        }
                                        onChange={(
                                            e: CustomDetailEvent<Select.ChangeDetail>,
                                        ) => {
                                            if (e.detail.selectedOption) {
                                                if (atpSearchText) {
                                                    dispatch(
                                                        setSearchText(null),
                                                    );
                                                    dispatch(
                                                        getAtpCompaniesList(),
                                                    );
                                                }

                                                handleFieldEvent({
                                                    freelance_agency:
                                                        e.detail.selectedOption
                                                            .label,
                                                });
                                            }
                                        }}
                                        onFocus={() => {
                                            if (atpSearchText) {
                                                dispatch(setSearchText(null));
                                            }
                                        }}
                                        onRecoveryClick={() => {
                                            dispatch(setSearchText(''));
                                            dispatch(
                                                resetPartialAtpCompaniesSlice(),
                                            );
                                            dispatch(getAtpCompaniesList());
                                        }}
                                        disabled={
                                            mode === FormSectionMode.Edit &&
                                            !isAtpCompaniesListLoaded
                                        }
                                        data-testid={`${mode}InstructorFreelanceAgencyATP`}
                                    />
                                </FormField>
                                <FormField
                                    label="Freelance onboarding date"
                                    errorText={
                                        errors?.freelance_onboarding_date
                                    }
                                    stretch={true}
                                >
                                    <DatePicker
                                        className={
                                            errors?.freelance_onboarding_date &&
                                            FORM_ERROR_SELECTOR
                                        }
                                        placeholder="YYYY/MM/DD"
                                        todayLabel="Today"
                                        nextMonthLabel="Next month"
                                        previousMonthLabel="Previous month"
                                        value={
                                            formValues.freelance_onboarding_date
                                                ? new Date(
                                                      formValues.freelance_onboarding_date *
                                                          1000,
                                                  )
                                                      .toISOString()
                                                      .split('T')[0]
                                                : ''
                                        }
                                        onChange={(e) =>
                                            handleFieldEvent({
                                                freelance_onboarding_date:
                                                    new Date(
                                                        e.detail.value,
                                                    ).getTime() / 1000,
                                            })
                                        }
                                        data-testid={`${mode}InstructorFreelanceOnboardingDate`}
                                    />
                                </FormField>
                                <FormField
                                    label={
                                        <span>
                                            Freelancer contact email{' '}
                                            <i>- optional</i>
                                        </span>
                                    }
                                    errorText={errors?.freelance_poc_email}
                                >
                                    <Input
                                        className={
                                            errors?.freelance_poc_email &&
                                            FORM_ERROR_SELECTOR
                                        }
                                        value={formValues.freelance_poc_email}
                                        onInput={(e) => {
                                            handleFieldEvent({
                                                freelance_poc_email:
                                                    e.detail.value,
                                            });
                                        }}
                                        data-testid={`${mode}InstructorFreelanceContactEmail`}
                                    />
                                </FormField>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <FormField
                            label="Amazon alias"
                            errorText={errors?.amazon_alias}
                        >
                            <Input
                                className={
                                    errors?.amazon_alias && FORM_ERROR_SELECTOR
                                }
                                value={formValues.amazon_alias}
                                onInput={(e) =>
                                    handleFieldEvent({
                                        amazon_alias: e.detail.value,
                                    })
                                }
                                data-testid={`${mode}InstructorAmazonAlias`}
                            />
                        </FormField>
                        <FormField
                            label="Amazon job title"
                            errorText={errors?.amazon_job_role}
                        >
                            <Input
                                className={
                                    errors?.amazon_job_role &&
                                    FORM_ERROR_SELECTOR
                                }
                                value={formValues.amazon_job_role}
                                onInput={(e) =>
                                    handleFieldEvent({
                                        amazon_job_role: e.detail.value,
                                    })
                                }
                                data-testid={`${mode}InstructorAmazonJobRole`}
                            />
                        </FormField>
                        <FormField
                            label="Manager"
                            errorText={errors?.amazon_manager_name}
                        >
                            <Input
                                className={
                                    errors?.amazon_manager_name &&
                                    FORM_ERROR_SELECTOR
                                }
                                value={formValues.amazon_manager_name}
                                onInput={(e) =>
                                    handleFieldEvent({
                                        amazon_manager_name: e.detail.value,
                                    })
                                }
                                data-testid={`${mode}InstructorAmazonManagerName`}
                            />
                        </FormField>
                        <FormField
                            label="Manager's email"
                            errorText={errors?.amazon_manager_email}
                        >
                            <Input
                                className={
                                    errors?.amazon_manager_email &&
                                    FORM_ERROR_SELECTOR
                                }
                                value={formValues.amazon_manager_email}
                                onInput={(e) =>
                                    handleFieldEvent({
                                        amazon_manager_email: e.detail.value,
                                    })
                                }
                                data-testid={`${mode}InstructorAmazonManagerEmail`}
                            />
                        </FormField>
                    </>
                )}
            </div>
        </ColumnLayout>
    );
};

InstructorDetailFormFields.propTypes = {
    providerAttributeEditorItems: PropTypes.array,
};

export default InstructorDetailFormFields;

import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { AdminCreateInstructorCourseStatusPayloadData } from '../../interfaces/adminCreateBusinessDataPayload/instructorCourseStatusPayload';
import { InstructorCourseStatusesResponseData } from '../../interfaces/businessDataResponse/instructorCourseStatusesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const INSTRUCTOR_COURSE_STATUSES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/instructor-course-status`;

/**
 * Method to request the full list of instructor course teaching status
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getInstructorCourseStatuses = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<InstructorCourseStatusesResponseData>> => {
    const path = `${INSTRUCTOR_COURSE_STATUSES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<InstructorCourseStatusesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an instructor course teaching status.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateInstructorCourseStatus = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        INSTRUCTOR_COURSE_STATUSES_PATH,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add an instructor course teaching status.
 * @param {string} instructorCourseStatus - the name of the new instructor course teaching status type
 * @param {boolean} active - the status of the new instructor course teaching status type
 * @return: A promise that resolves with an object containing the result or error of the request
 *
 */
const addInstructorCourseStatus = (
    instructorCourseStatus: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateInstructorCourseStatusPayloadData = {
        instructor_course_status: instructorCourseStatus,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        INSTRUCTOR_COURSE_STATUSES_PATH,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

const instructorCourseStatusesApi = {
    getInstructorCourseStatuses,
    updateInstructorCourseStatus,
    addInstructorCourseStatus,
};

export default instructorCourseStatusesApi;

import React, { ReactNode } from 'react';

export const SYSTEM_ADMIN_URL =
    'https://w.amazon.com/bin/view/LSO_Business_Operations_Tools/GRIMSBY/Business/getting-started/';

export interface WelcomePageData {
    sentence: string;
}

/**
 * function to create anchor tag with text and SYSTEM_ADMIN_URL as href
 * @param value anchor text
 * @param url anchor  href
 */
const formattingFunction = (value: string, url: string): ReactNode => {
    return (
        <a
            data-testid="welcome-anchor"
            key={url}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
        >
            {value}
        </a>
    );
};

/**
 * Component to generate welcome text with anchor tag to contact system admin
 * @param sentence sentence template, e.g: `It looks like you are new to the system. Please contact your [[System Administrator]] to give you access.`
 * @constructor
 */
const Welcome = ({ sentence = '' }: WelcomePageData) => {
    const tokenRegexp = new RegExp(/\{\{(?<token>.*)\}\}/);

    const matches = tokenRegexp.exec(sentence);

    if (!matches?.length) {
        // eslint-disable-next-line no-console
        console.error('No replacement token found in message');
        return <>{sentence}</>;
    }

    const tokenToReplace = matches[1];

    return (
        <span data-testid="WelcomeMessage">
            {sentence.split(tokenRegexp).map((word: string) => {
                if (word === tokenToReplace) {
                    return formattingFunction(word, SYSTEM_ADMIN_URL);
                }
                return word;
            })}
        </span>
    );
};

export default Welcome;

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Modal,
    ColumnLayout,
    FormField,
    DatePicker,
    AttributeEditor,
    TimeInput,
    Textarea,
    Alert,
    Box,
    Button,
    SpaceBetween,
    Toggle,
    AttributeEditorProps,
    Select as SelectV3,
    Select,
} from '@amzn/awsui-components-react-v3';
import FullCalendar from '@fullcalendar/react';
import {
    BlockedTimeFormData,
    BlockedTimeType,
    BlockedTime,
    BlockTimeTimeAttributeEditorItem,
    BlockedTimeSuccessResponseData,
} from '../../../../common/interfaces/blockedTime';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { LookupData } from '../../../interfaces/lookup';
import {
    meridiemFieldOptionLookupV3,
    meridiemFieldOptionsV3,
    MeridiemFieldValue,
} from '../../../interfaces/meridiem';
import { RecommendedInstructorProfileData } from '../../../interfaces/recommendedInstructorProfile';
import {
    buildEditTimesForInstructorType,
    formatFreelancerBlockedTimeAttributes,
    normalizeBlockedTimeAttributes,
    normalizeInstructorsWithBlockedTimeTimeData,
} from '../../../services/schedule-service';
import './BlockedTimeModal.scss';
import BlockedTimeModalFooter, {
    BlockedTimeModalFooterProps,
} from './BlockedTimeModalFooter';
import { checkTimesAsDateRange } from '../../../../common/utils/date-time.utils';
import { getOptionsAndLookupForSelectInputPolarisV3 } from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import { InstructorBlockedTimeTypeItemData } from '../../../../common/interfaces/businessDataItem/instructorBlockedTimeTypeItem';
import { useBusinessDatum } from '../../Common/hooks/useBusinessDatum';
import {
    addBlockedTimesForInstructor,
    addBlockedTimesTimeAsEvent,
    deleteBlockedTimeForInstructor,
    deleteCalendarInstructorReservedTime,
    updateBlockedTimeForInstructor,
    updateCalendarBlockedTime,
    setIsLoading,
} from '../../../store/slices/instructorListSlice';
import { useDispatch } from 'react-redux';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import {
    mapBlockedTimes,
    mapTimestampsToCalendarView,
} from '../../../services/calendar-service';

export const BLOCK_TIME_TEXT = 'Block time';
export const MIXED_INSTRUCTOR_ALERT =
    'Blocked times cannot be added to a mix of internal and freelancer instructors. Please select one group to continue.';
export const CONTINUE_WITH_INTERNAL_INSTRUCTORS_BTN =
    'Continue with internal instructors';
export const CONTINUE_WITH_FREELANCE_INSTRUCTORS_BTN =
    'Continue with freelancer instructors';

export interface BlockedTimeModalPropsData {
    isVisible: boolean;
    handleIsVisibleChange: (isVisible: boolean) => void;
    calendarRef: FullCalendar | undefined;
    selectedInstructors: Set<RecommendedInstructorProfileData>;
    handleSelectedInstructorsChange: (
        selectedInstructors: Set<RecommendedInstructorProfileData>,
    ) => void;
    setBlockedTime;
    blockedTime: BlockedTime;
    eventSource: BlockedTime;
    instructorLookup: LookupData<RecommendedInstructorProfileData>;
}

interface BlockedTimesControlArrayFormValues {
    blockedTimeAttributeEditorItems: BlockTimeTimeAttributeEditorItem;
}

const BlockedTimeModal = ({
    handleIsVisibleChange,
    handleSelectedInstructorsChange,
    isVisible,
    eventSource,
    setBlockedTime,
    blockedTime,
    instructorLookup,
    calendarRef,
    selectedInstructors,
}: BlockedTimeModalPropsData) => {
    const initialFormAttributeEditorState = {
        id: '',
        startTime: '',
        endTime: '',
        startDateString: '',
        endDateString: '',
        endTimeMeridiemFieldOption: MeridiemFieldValue.Pm,
        startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
        removeFlag: false,
    };

    const initialFormState = {
        pk: '',
        blocked_time_type: BlockedTimeType.UNAVAILABLE,
        blocked_time_name: '',
        blocked_time_notes: '',
        blocked_time_timestamps: [],
        instructor_pk: '',
        blockedTimes: [] as Array<BlockTimeTimeAttributeEditorItem>,
    };

    const [formValues, setFormValues] =
        useState<BlockedTimeFormData>(initialFormState);

    const [isEditForm, setIsEditForm] = useState(false);
    const [isFreelancer, setIsFreelancer] = useState(false);
    const [isDateRangeToggled, setDateRangeToggled] = useState(false);
    const [isDateRange, setIsDateRange] = useState(false);
    const [blockedTimeErrors, setBlockedTimeErrors] = useState<any>([]);
    const [
        blockedTimeAttributeEditorItems,
        setBlockedTimeAttributeEditorItems,
    ] = useState<Array<BlockTimeTimeAttributeEditorItem>>([
        initialFormAttributeEditorState,
    ]);

    const {
        validateForm,
        errors,
        controlArrayErrors,
        validateFormControlArray,
        isInvalid,
        isControlArrayInvalid,
    } = useFormValidation<Partial<BlockedTime>>();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    const clearBlockedTimeValues = () => {
        setBlockedTimeAttributeEditorItems([initialFormAttributeEditorState]);
        setBlockedTime(() => ({}) as BlockedTime);
        setFormValues((values) => ({
            ...values,
            ...initialFormState,
        }));
    };

    const resetBlockedTimeModal = () => {
        handleSelectedInstructorsChange(new Set([]));
        setBlockedTimeErrors([]);
        handleIsVisibleChange(false);
        clearBlockedTimeValues();
        setDateRangeToggled(false);
    };

    const handleFieldEvent = useCallback((changes: Partial<BlockedTime>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    }, []);

    const formatSelectedInstructors = () => {
        return Array.from(selectedInstructors)
            .map((item) => item.full_name)
            .join(', ');
    };

    const hasMixedInstructors = useMemo(() => {
        let hasInternal = false;
        let hasFreelancer = false;

        for (let instructor of Array.from(selectedInstructors)) {
            if (instructor.is_freelancer) {
                hasFreelancer = true;
            } else {
                hasInternal = true;
            }

            if (hasFreelancer && hasInternal) {
                break;
            }
        }

        return hasFreelancer && hasInternal;
    }, [selectedInstructors]);

    const handleBlockedTimeDateItemEvent = (
        blockedTimes: Array<BlockTimeTimeAttributeEditorItem>,
    ) => {
        setBlockedTimeAttributeEditorItems(blockedTimes);
        setFormValues((values) => ({
            ...values,
            blockedTimes,
        }));
    };

    const handleReserveTimeDateChange = (val: string, index: number) => {
        const blockedTimes = blockedTimeAttributeEditorItems.slice();
        blockedTimes.splice(index, 1, {
            id: '',
            startDateString: val,
            endDateString: val,
            startTime: '09:00',
            endTime: '05:00',
            startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
            endTimeMeridiemFieldOption: MeridiemFieldValue.Pm,
            removeFlag: false,
        });
        handleBlockedTimeDateItemEvent(blockedTimes);
    };

    const handleUnavailableTimeDateChange = (
        val: string,
        index: number,
        type: string,
    ) => {
        const blockedTimes = blockedTimeAttributeEditorItems.slice();
        const blockedTime = blockedTimes[index];
        const startTime = '12:00';
        const endTime = '11:59:59';
        const startDateString =
            type === 'startDateString' ? val : blockedTime.startDateString;
        let endDateString;
        if (isDateRange) {
            endDateString =
                type === 'endDateString' ? val : blockedTime.endDateString;
        } else {
            endDateString = val;
        }

        blockedTimes.splice(index, 1, {
            ...blockedTime,
            startDateString,
            endDateString,
            startTime: startTime,
            endTime: endTime,
            startTimeMeridiemFieldOption: MeridiemFieldValue.Am,
            endTimeMeridiemFieldOption: MeridiemFieldValue.Pm,
        });
        handleBlockedTimeDateItemEvent(blockedTimes);
    };

    const getValidationConfig = useCallback((): {
        [key in ValidationType]?: Array<keyof BlockedTime>;
    } => {
        return {
            required: ['blocked_time_name'],
        };
    }, []);

    const blockedTimeAttributeValidationConfig: {
        [blockedTimeControlArrayFormValuesKey in keyof BlockedTimesControlArrayFormValues]: {
            [key in ValidationType]?: Array<
                keyof BlockedTimesControlArrayFormValues[blockedTimeControlArrayFormValuesKey]
            >;
        };
    } = {
        blockedTimeAttributeEditorItems: {
            required: [
                'startDateString',
                'startTime',
                'endTime',
                'endTimeMeridiemFieldOption',
                'startTimeMeridiemFieldOption',
            ],
        },
    };

    // get instructor blocked time types
    let { instructorBlockedTimeTypeList } = useBusinessDatum();

    // sort instructorBlockedTimeTypeList based on blocked time type
    instructorBlockedTimeTypeList.sort((a, b) => {
        return a.instructor_blockedtime_type.localeCompare(
            b.instructor_blockedtime_type,
        );
    });

    if (isFreelancer) {
        instructorBlockedTimeTypeList = instructorBlockedTimeTypeList.filter(
            (blockedTime) =>
                blockedTime.instructor_blockedtime_type ===
                BlockedTimeType.UNAVAILABLE,
        );
    }

    const {
        valueLookup: instructorBlockedTimeTypeLookup,
        valueOptions: instructorBlockedTimeTypeOptions,
    } =
        getOptionsAndLookupForSelectInputPolarisV3<InstructorBlockedTimeTypeItemData>(
            instructorBlockedTimeTypeList,
            (instructorBlockedTimeType: InstructorBlockedTimeTypeItemData) => ({
                label: instructorBlockedTimeType.instructor_blockedtime_type,
                value: instructorBlockedTimeType.pk as string,
            }),
        );

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, getValidationConfig());
        }

        if (isControlArrayInvalid) {
            validateFormControlArray(
                { blockedTimeAttributeEditorItems },
                blockedTimeAttributeValidationConfig,
            );
        }
    }, [
        isInvalid,
        formValues,
        validateForm,
        validateFormControlArray,
        blockedTimeAttributeEditorItems,
        isControlArrayInvalid,
        getValidationConfig,
    ]);

    useEffect(() => {
        if (blockedTime?.pk) {
            setIsEditForm(true);
            const instructor = instructorLookup[blockedTime.instructor_pk];
            const { city_timezone, is_freelancer } = instructor;
            const freelancer = is_freelancer ? true : false;
            setIsFreelancer(freelancer);
            const editTimesAreDateRange = checkTimesAsDateRange(
                blockedTime.blocked_time_timestamps,
                instructor.city_timezone,
            );
            setIsDateRange(editTimesAreDateRange);

            if (editTimesAreDateRange) {
                const timestamps = mapTimestampsToCalendarView({
                    calendarRef,
                    timestamps: blockedTime.blocked_time_timestamps,
                    city_timezone,
                });
                const editTimes = timestamps.map((timestamp) =>
                    buildEditTimesForInstructorType({
                        timestamp,
                        city_timezone,
                        blockedTime,
                        isFreelancer: freelancer,
                    }),
                );

                setBlockedTimeAttributeEditorItems(editTimes);
                setFormValues((values) => {
                    return {
                        ...values,
                        blockedTimes: editTimes,
                        ...blockedTime,
                    };
                });
            } else {
                const editTimes = blockedTime.blocked_time_timestamps.map(
                    (timestamp) =>
                        buildEditTimesForInstructorType({
                            timestamp,
                            city_timezone,
                            blockedTime,
                            isFreelancer: freelancer,
                        }),
                );

                setBlockedTimeAttributeEditorItems(editTimes);
                setFormValues((values) => {
                    return {
                        ...values,
                        blockedTimes: editTimes,
                        ...blockedTime,
                    };
                });
            }
        } else {
            let hasFreelancer = false;
            selectedInstructors.forEach((item) =>
                item.is_freelancer ? (hasFreelancer = true) : false,
            );
            hasFreelancer ? setIsFreelancer(true) : setIsFreelancer(false);

            setIsEditForm(false);
        }
    }, [blockedTime, instructorLookup, calendarRef, selectedInstructors]);

    const deselectMixedInstructors = (deselectFreelancers: boolean): void => {
        // deselect freelancers
        handleSelectedInstructorsChange(
            new Set(
                Array.from(selectedInstructors).filter((instructor) => {
                    if (
                        instructor.is_freelancer === deselectFreelancers ||
                        (!deselectFreelancers && !instructor.is_freelancer)
                    ) {
                        return true;
                    }

                    return false;
                }),
            ),
        );
    };

    const freelancerUnavailableTimeAttributeEditorDefinition: Array<
        AttributeEditorProps.FieldDefinition<BlockTimeTimeAttributeEditorItem>
    > = [
        {
            label: 'Dates',
            control: (
                blockedTimeAllDayAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                index: number,
            ) => {
                if (blockedTimeAllDayAttributeEditorItem.removeFlag) {
                    return (
                        <Alert
                            visible={
                                blockedTimeAllDayAttributeEditorItem.removeFlag
                            }
                            type="success"
                        >
                            {
                                blockedTimeAllDayAttributeEditorItem.startDateString
                            }{' '}
                            removed.
                        </Alert>
                    );
                } else {
                    return (
                        <DatePicker
                            onChange={(e) => {
                                handleUnavailableTimeDateChange(
                                    e.detail.value,
                                    index,
                                    'startDateString',
                                );
                            }}
                            value={
                                blockedTimeAllDayAttributeEditorItem.startDateString
                                    ? blockedTimeAllDayAttributeEditorItem.startDateString
                                    : ''
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                            data-testid={`pickStartDateString-${index}`}
                            disabled={isEditForm ? true : false}
                        />
                    );
                }
            },
        },
    ];

    const dateRangeBlockAttributeEditorDefinition: Array<
        AttributeEditorProps.FieldDefinition<BlockTimeTimeAttributeEditorItem>
    > = [
        {
            label: 'Start Date',
            control: (
                blockedTimeAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                index: number,
            ) => {
                if (blockedTimeAttributeEditorItem.removeFlag) {
                    return (
                        <Alert
                            visible={blockedTimeAttributeEditorItem.removeFlag}
                            type="success"
                        >
                            {blockedTimeAttributeEditorItem.startDateString}{' '}
                            removed.
                        </Alert>
                    );
                } else {
                    return (
                        <DatePicker
                            onChange={(e) => {
                                handleUnavailableTimeDateChange(
                                    e.detail.value,
                                    index,
                                    'startDateString',
                                );
                            }}
                            value={
                                blockedTimeAttributeEditorItem.startDateString
                                    ? blockedTimeAttributeEditorItem.startDateString
                                    : ''
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                            data-testid={`pickDateString-${index}`}
                            disabled={isEditForm ? true : false}
                        />
                    );
                }
            },
            errorText: (_, index: number) =>
                controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                    ?.dateString,
        },
        {
            label: 'End time',
            control: (
                blockedTimeAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                index: number,
            ) => {
                if (!blockedTimeAttributeEditorItem.removeFlag) {
                    return (
                        <DatePicker
                            onChange={(e) => {
                                handleUnavailableTimeDateChange(
                                    e.detail.value,
                                    index,
                                    'endDateString',
                                );
                            }}
                            value={
                                blockedTimeAttributeEditorItem.endDateString
                                    ? blockedTimeAttributeEditorItem.endDateString
                                    : ''
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                            data-testid={`pickDateString-${index}`}
                            disabled={isEditForm ? true : false}
                        />
                    );
                }
            },
            errorText: (_, index: number) =>
                controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                    ?.dateString,
        },
    ];

    const reserveTimeAttributeEditorDefinition: Array<
        AttributeEditorProps.FieldDefinition<BlockTimeTimeAttributeEditorItem>
    > = [
        {
            label: 'Start Date',
            control: (
                blockedTimeAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                index: number,
            ) => (
                <DatePicker
                    onChange={(e) => {
                        handleReserveTimeDateChange(e.detail.value, index);
                    }}
                    value={
                        blockedTimeAttributeEditorItem.startDateString
                            ? blockedTimeAttributeEditorItem.startDateString
                            : ''
                    }
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                    data-testid={`pickDateString-${index}`}
                />
            ),
            errorText: (_, index: number) =>
                controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                    ?.dateString,
        },
        {
            label: 'Start time',
            control: (
                blockedTimeAttributeEditorItem: BlockTimeTimeAttributeEditorItem,
                index: number,
            ) => (
                <div className="time-selector">
                    <div>
                        <TimeInput
                            data-testid={`ReserveTimeStartTime`}
                            format="hh:mm"
                            placeholder="hh:mm"
                            use24Hour={false}
                            value={
                                blockedTimeAttributeEditorItem.startTime
                                    ? blockedTimeAttributeEditorItem.startTime
                                    : ''
                            }
                            onChange={(e) => {
                                const newItems =
                                    blockedTimeAttributeEditorItems.slice();
                                newItems.splice(index, 1, {
                                    ...blockedTimeAttributeEditorItem,
                                    startTime: e.detail.value,
                                });
                                handleBlockedTimeDateItemEvent(newItems);
                            }}
                        />
                    </div>
                    <div>
                        <Select
                            options={meridiemFieldOptionsV3}
                            selectedOption={
                                blockedTimeAttributeEditorItem.startTimeMeridiemFieldOption
                                    ? meridiemFieldOptionLookupV3[
                                          blockedTimeAttributeEditorItem
                                              .startTimeMeridiemFieldOption
                                      ]
                                    : meridiemFieldOptionLookupV3[
                                          MeridiemFieldValue.Am
                                      ]
                            }
                            onChange={(e) => {
                                const newItems =
                                    blockedTimeAttributeEditorItems.slice();
                                newItems.splice(index, 1, {
                                    ...blockedTimeAttributeEditorItem,
                                    startTimeMeridiemFieldOption: e.detail
                                        .selectedOption
                                        .label as MeridiemFieldValue,
                                });
                                handleBlockedTimeDateItemEvent(newItems);
                            }}
                            data-testid={`reserveTimeStart-${index}`}
                        />
                    </div>
                </div>
            ),
            errorText: (_, index: number) =>
                controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                    ?.startTime ||
                controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                    ?.startTimeMeridiemFieldOption,
        },
        {
            label: 'End time',
            control: (
                blockedTimeAtributeEditorItem: BlockTimeTimeAttributeEditorItem,
                index: number,
            ) => (
                <div className="time-selector">
                    <div>
                        <TimeInput
                            data-testid={`ReserveTimeEndTime`}
                            format="hh:mm"
                            placeholder="hh:mm"
                            use24Hour={false}
                            value={
                                blockedTimeAtributeEditorItem.endTime
                                    ? blockedTimeAtributeEditorItem.endTime
                                    : ''
                            }
                            onChange={(e) => {
                                const newItems =
                                    blockedTimeAttributeEditorItems.slice();
                                newItems.splice(index, 1, {
                                    ...blockedTimeAtributeEditorItem,
                                    endTime: e.detail.value,
                                });
                                handleBlockedTimeDateItemEvent(newItems);
                            }}
                        />
                    </div>
                    <div>
                        <Select
                            options={meridiemFieldOptionsV3}
                            selectedOption={
                                blockedTimeAtributeEditorItem.endTimeMeridiemFieldOption
                                    ? meridiemFieldOptionLookupV3[
                                          blockedTimeAtributeEditorItem
                                              .endTimeMeridiemFieldOption
                                      ]
                                    : meridiemFieldOptionLookupV3[
                                          MeridiemFieldValue.Am
                                      ]
                            }
                            onChange={(e) => {
                                const newItems =
                                    blockedTimeAttributeEditorItems.slice();
                                newItems.splice(index, 1, {
                                    ...blockedTimeAtributeEditorItem,
                                    endTimeMeridiemFieldOption: e.detail
                                        .selectedOption
                                        .label as MeridiemFieldValue,
                                });
                                handleBlockedTimeDateItemEvent(newItems);
                            }}
                            data-testid={`reserveTimeEnd-${index}`}
                        />
                    </div>
                </div>
            ),
            errorText: (_, index: number) =>
                controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                    ?.endTime ||
                controlArrayErrors.blockedTimeAttributeEditorItems?.[index]
                    ?.endTimeMeridiemFieldOption,
        },
    ];

    const getAttributeEditorDefinitions = () => {
        if (isDateRangeToggled || isDateRange) {
            return dateRangeBlockAttributeEditorDefinition;
        } else {
            if (isFreelancer) {
                return freelancerUnavailableTimeAttributeEditorDefinition;
            } else {
                return reserveTimeAttributeEditorDefinition;
            }
        }
    };

    const handleSubmitSuccess = (
        blockedTime: BlockedTime,
        instructorId: string,
    ) => {
        const instructor = instructorLookup[instructorId];
        dispatch(addBlockedTimesTimeAsEvent({ blockedTime, instructor }));
        resetBlockedTimeModal();
    };

    const handleEditSuccess = (successfulUpdates, instructor) => {
        successfulUpdates.forEach((success) => {
            if ('blockedTime' in success) {
                dispatch(
                    updateCalendarBlockedTime({
                        instructor,
                        blockedTime: {
                            ...success.blockedTime,
                            blocked_time_timestamps:
                                success.blockedTime.blocked_time_timestamps.map(
                                    (timestamp) => ({
                                        start_timestamp:
                                            timestamp.start_timestamp,
                                        end_timestamp: timestamp.end_timestamp,
                                    }),
                                ),
                        },
                    }),
                );
            }
        });
        resetBlockedTimeModal();
    };

    const handleDelete = (blockedTimeFormValues, deleteBlockedTime) => {
        dispatch(
            deleteCalendarInstructorReservedTime({
                instructor:
                    instructorLookup[blockedTimeFormValues.instructor_pk],
                blockedTime: blockedTimeFormValues,
            }),
        );

        handleIsVisibleChange(false);
        addNotification({
            id: `set-blocked-times-${Date.now()}`,
            ...(!!deleteBlockedTime
                ? {
                      type: 'success',
                      content: 'Block time deleted.',
                  }
                : {
                      type: 'error',
                      content:
                          'An error occurred while deleting the block time.',
                  }),
        });
        resetBlockedTimeModal();
    };

    const handleErrors = (blockTimeAdds) => {
        const checkForErrors = blockTimeAdds.filter(
            (response: any) => response.status !== 200,
        );
        const hasErrors = checkForErrors.length > 0;
        if (hasErrors) {
            setBlockedTimeErrors(checkForErrors);
        }
    };

    const handleDatespanBlockTimes = async ({
        polarisFormattedBlockedTimes,
        blockedTimeFormValues,
        instructor,
    }) => {
        const calendarViewBlockTimes = polarisFormattedBlockedTimes.map(
            (blockedTime) => {
                return formatFreelancerBlockedTimeAttributes({
                    id: blockedTime.id,
                    blockedTime,
                    timezone: instructor.city_timezone,
                    forceDelete: blockedTime.removeFlag,
                });
            },
        );

        const originalBlockedTimes =
            blockedTimeFormValues.blocked_time_timestamps.map(
                (blockedTime) => ({
                    id: blockedTimeFormValues.pk,
                    start_timestamp: blockedTime.start_timestamp,
                    end_timestamp: blockedTime.end_timestamp,
                    removeFlag: false,
                }),
            );

        const originalBlockedTimesAsBlockedTime = originalBlockedTimes.reduce(
            (acc, blockedTime) => {
                if (blockedTime.end_timestamp >= acc.end_timestamp) {
                    return {
                        ...acc,
                        end_timestamp: blockedTime.end_timestamp,
                    };
                }
                return acc;
            },
            originalBlockedTimes[0],
        );

        const timespanBlocks = mapBlockedTimes({
            calendarViewBlockTimes,
            originalBlockedTime: originalBlockedTimesAsBlockedTime,
        });

        const newBlockTimes = timespanBlocks.mappedBlockedTimes.map(
            (newBlockTimeTimestamps) => {
                return {
                    ...eventSource,
                    blocked_time_type: blockedTimeFormValues.blocked_time_type,
                    blocked_time_name: blockedTimeFormValues.blocked_time_name,
                    blocked_time_notes:
                        blockedTimeFormValues.blocked_time_notes,
                    blocked_time_timestamps: [newBlockTimeTimestamps],
                };
            },
        );

        const editFirstBlockTime = newBlockTimes.shift() || ({} as BlockedTime);
        await dispatch(updateBlockedTimeForInstructor(editFirstBlockTime));

        dispatch(
            updateCalendarBlockedTime({
                instructor,
                blockedTime: {
                    blocked_time_type: editFirstBlockTime.blocked_time_type,
                    blocked_time_name: editFirstBlockTime.blocked_time_name,
                    blocked_time_notes: editFirstBlockTime.blocked_time_notes,
                    pk: editFirstBlockTime.pk,
                    instructor_pk: instructor.pk,
                    blocked_time_timestamps:
                        editFirstBlockTime.blocked_time_timestamps,
                },
            }),
        );

        if (newBlockTimes.length > 0) {
            dispatch(setIsLoading(true));
            const addBlockTimePromises: any = newBlockTimes.map(
                (blockedTime) => {
                    return blockedTime.blocked_time_timestamps.map(
                        (timestamps) => {
                            return dispatch(
                                addBlockedTimesForInstructor({
                                    ...blockedTime,
                                    blocked_time_timestamps: [timestamps],
                                }),
                            );
                        },
                    );
                },
            );

            const blockTimeAddPromises = await Promise.all(
                addBlockTimePromises.flat(),
            );

            const successfulUpdates = blockTimeAddPromises.filter(
                (response: any) => response.status === 200,
            ) as unknown as Array<BlockedTimeSuccessResponseData>;

            if (successfulUpdates.length > 0) {
                handleEditSuccess(successfulUpdates, instructor);
                dispatch(setIsLoading(false));
                handleIsVisibleChange(false);
            } else {
                // return handleErrors(blockTimeAdds);
            }
        } else {
            handleIsVisibleChange(false);
            addNotification({
                id: `add-blocked-times-${Date.now()}`,
                type: 'success',
                content: 'Unavailable times updated.',
            });
        }
    };

    const handleBlockedTimeEdit = async (
        blockedTimeFormValues: BlockedTimeFormData,
    ) => {
        const instructor =
            instructorLookup[blockedTimeFormValues.instructor_pk];

        // Polaris formatted AttributeEditor Values
        const polarisFormattedBlockedTimes = blockedTimeFormValues.blockedTimes;
        if (polarisFormattedBlockedTimes.length === 0) {
            // Deleting Blocked time exist as user removed all start and end times
            const deleteBlockedTime = await dispatch(
                deleteBlockedTimeForInstructor(blockedTimeFormValues),
            );
            handleDelete(blockedTimeFormValues, deleteBlockedTime);
        }

        if (isDateRange) {
            return handleDatespanBlockTimes({
                polarisFormattedBlockedTimes,
                blockedTimeFormValues,
                instructor,
            });
        }

        const polarisBlockedTimesToGrimsbyBlockedTimes =
            normalizeBlockedTimeAttributes(instructor, blockedTimeFormValues);

        const addBlockTimePromises = dispatch(
            updateBlockedTimeForInstructor(
                polarisBlockedTimesToGrimsbyBlockedTimes,
            ),
        );

        const blockTimeAdds = await Promise.all([addBlockTimePromises]);
        const successfulUpdates = blockTimeAdds.filter(
            (response: any) => response.status === 200,
        );
        if (successfulUpdates.length > 0) {
            handleEditSuccess(successfulUpdates, instructor);
            handleIsVisibleChange(false);
        } else {
            return handleErrors(blockTimeAdds);
        }

        addNotification({
            id: `add-blocked-times-${Date.now()}`,
            type: 'success',
            content: 'Unavailable times updated.',
        });
    };

    const handleBlockedTimeSubmit = async ({
        blockedTimeFormValues,
    }: {
        blockedTimeFormValues: BlockedTimeFormData;
    }) => {
        dispatch(setIsLoading(true));
        const { blockedTimes } = normalizeInstructorsWithBlockedTimeTimeData({
            selectedInstructors: Array.from(selectedInstructors),
            blockedTimeFormValues,
            instructorLookup,
        });

        const blockTimeAdds = blockedTimes.map((blockedTime) => {
            return blockedTime.blocked_time_timestamps.map((timestamps) => {
                return dispatch(
                    addBlockedTimesForInstructor({
                        ...blockedTime,
                        blocked_time_timestamps: [timestamps],
                    }),
                );
            });
        });
        const blockTimeAddPromises = await Promise.all(blockTimeAdds.flat());
        const successfulUpdates = blockTimeAddPromises.filter(
            (response: any) => response.status === 200,
        ) as unknown as Array<BlockedTimeSuccessResponseData>;
        if (successfulUpdates.length > 0) {
            successfulUpdates.forEach((success) => {
                handleSubmitSuccess(success.blockedTime, success.instructorId);
            });
            dispatch(setIsLoading(false));
            handleIsVisibleChange(false);
        } else {
            dispatch(setIsLoading(false));
            return handleErrors(blockTimeAddPromises);
        }
        addNotification({
            id: `add-blocked-times-${Date.now()}`,
            type: 'success',
            content: 'Block time added.',
        });
    };

    const blockedTimeModalFooterProps: BlockedTimeModalFooterProps = {
        handleIsVisibleChange,
        resetBlockedTimeModal,
        hasMixedInstructors,
        isEditForm,
        isFreelancer,
        handleBlockedTimeEdit,
        formValues,
        eventSource,
        validateForm,
        getValidationConfig,
        validateFormControlArray,
        blockedTimeAttributeEditorItems,
        blockedTimeAttributeValidationConfig,
        handleBlockedTimeSubmit,
    };

    return (
        <Modal
            onDismiss={() => {
                handleIsVisibleChange(false);
                resetBlockedTimeModal();
            }}
            visible={isVisible}
            header={BLOCK_TIME_TEXT}
            footer={<BlockedTimeModalFooter {...blockedTimeModalFooterProps} />}
            size="large"
            data-testid="blockedTimeModal"
        >
            <ColumnLayout>
                <SpaceBetween size="l">
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            {isEditForm
                                ? 'Instructor'
                                : (selectedInstructors &&
                                        selectedInstructors.size) > 1
                                  ? 'Instructors'
                                  : 'Instructor'}
                        </Box>
                        {!isEditForm
                            ? formatSelectedInstructors()
                            : instructorLookup &&
                                instructorLookup[blockedTime.instructor_pk]
                              ? instructorLookup[blockedTime.instructor_pk]
                                    .full_name
                              : ''}
                    </div>
                    {hasMixedInstructors && (
                        <div style={{ flexDirection: 'column' }}>
                            <SpaceBetween size="l">
                                <Alert type="warning">
                                    {MIXED_INSTRUCTOR_ALERT}
                                </Alert>
                                <Button
                                    variant="normal"
                                    onClick={() => {
                                        deselectMixedInstructors(false);
                                    }}
                                >
                                    {CONTINUE_WITH_INTERNAL_INSTRUCTORS_BTN}
                                </Button>
                                <Button
                                    variant="normal"
                                    onClick={() => {
                                        deselectMixedInstructors(true);
                                    }}
                                >
                                    {CONTINUE_WITH_FREELANCE_INSTRUCTORS_BTN}
                                </Button>
                            </SpaceBetween>
                        </div>
                    )}
                    {!hasMixedInstructors && (
                        <>
                            <FormField
                                errorText={errors?.blocked_time_name}
                                stretch
                                label="Select a blocked time name"
                            >
                                <SelectV3
                                    placeholder={'Select a blocked time name'}
                                    options={instructorBlockedTimeTypeOptions}
                                    data-testid="select-blocked-time-name"
                                    onChange={(e) => {
                                        handleFieldEvent({
                                            blocked_time_name:
                                                e.detail.selectedOption.label,
                                        });
                                    }}
                                    className={errors?.blocked_time_name}
                                    selectedOption={
                                        formValues.blocked_time_name
                                            ? instructorBlockedTimeTypeLookup[
                                                  formValues.blocked_time_name
                                              ]
                                            : null
                                    }
                                />
                            </FormField>
                            {!isEditForm && (
                                <Toggle
                                    onChange={({ detail }) => {
                                        setDateRangeToggled(detail.checked);
                                        getAttributeEditorDefinitions();
                                    }}
                                    checked={isDateRangeToggled}
                                >
                                    Date range
                                </Toggle>
                            )}
                            <AttributeEditor
                                className="attribute-editor-bottom-spacing"
                                data-testid="reserve-time-attribute-editor"
                                addButtonText="Add date"
                                removeButtonText="Remove"
                                items={blockedTimeAttributeEditorItems}
                                definition={getAttributeEditorDefinitions()}
                                onAddButtonClick={() =>
                                    handleBlockedTimeDateItemEvent([
                                        ...blockedTimeAttributeEditorItems,
                                        {
                                            id: '',
                                            startDateString: '',
                                            endDateString: '',
                                            startTime: '',
                                            endTime: '',
                                            endTimeMeridiemFieldOption:
                                                MeridiemFieldValue.Am,
                                            startTimeMeridiemFieldOption:
                                                MeridiemFieldValue.Am,
                                            removeFlag: false,
                                        },
                                    ])
                                }
                                disableAddButton={
                                    isEditForm || isDateRangeToggled
                                }
                                onRemoveButtonClick={({
                                    detail: { itemIndex },
                                }) => {
                                    if (isFreelancer || isDateRange) {
                                        blockedTimeAttributeEditorItems[
                                            itemIndex
                                        ].removeFlag = true;
                                        handleBlockedTimeDateItemEvent(
                                            blockedTimeAttributeEditorItems,
                                        );
                                    } else {
                                        const newBlockedTimes =
                                            blockedTimeAttributeEditorItems.slice();
                                        newBlockedTimes.splice(itemIndex, 1);
                                        handleBlockedTimeDateItemEvent(
                                            newBlockedTimes,
                                        );
                                    }
                                }}
                                isItemRemovable={(
                                    item: BlockTimeTimeAttributeEditorItem,
                                ) => {
                                    if (!isDateRangeToggled) {
                                        return !item.removeFlag;
                                    } else {
                                        return (
                                            blockedTimeAttributeEditorItems.length >
                                            1
                                        );
                                    }
                                }}
                            />
                            {blockedTimeErrors.length > 0 &&
                                blockedTimeErrors.map(
                                    (error: any, i: number) => {
                                        if (error.message) {
                                            const pk =
                                                error.message.split(':')[1];
                                            const { full_name } =
                                                instructorLookup[pk as string];

                                            return (
                                                <Alert
                                                    key={pk}
                                                    className="attribute-editor-bottom-spacing"
                                                    onDismiss={() => false}
                                                    visible={true}
                                                    dismissAriaLabel="Close alert"
                                                    type="error"
                                                >
                                                    {`${full_name} is booked.`}
                                                </Alert>
                                            );
                                        } else {
                                            return (
                                                <Alert
                                                    key={`block-time-general-error-${i}`}
                                                    className="attribute-editor-bottom-spacing"
                                                    onDismiss={() => false}
                                                    visible={true}
                                                    dismissAriaLabel="Close alert"
                                                    type="error"
                                                >
                                                    {`There was an issue while updating block times.`}
                                                </Alert>
                                            );
                                        }
                                    },
                                )}
                            <FormField
                                stretch
                                label={
                                    <span>
                                        Block time notes <i>- optional</i>{' '}
                                    </span>
                                }
                            >
                                <Textarea
                                    data-testid="reserveTimeNotes"
                                    onChange={(e) => {
                                        handleFieldEvent({
                                            blocked_time_notes: e.detail.value,
                                        });
                                    }}
                                    value={(() => {
                                        return formValues.blocked_time_notes;
                                    })()}
                                />
                            </FormField>
                        </>
                    )}
                </SpaceBetween>
            </ColumnLayout>
        </Modal>
    );
};

export default BlockedTimeModal;

import React, { useCallback, useEffect } from 'react';
import { useGrimsbyNavActions } from '../../../common/context/grimsbyNavigation';
import { useLocation } from 'react-router-dom';
import InstructorRoutes from './InstructorRoutes';
import { INSTRUCTOR_NAV_ITEMS } from '../../../common/constants/navigation';

const Instructors = () => {
    const { initializeNavigation } = useGrimsbyNavActions();
    const location = useLocation();
    const initializeNavigationCb = useCallback(initializeNavigation, []);

    useEffect(() => {
        initializeNavigationCb(INSTRUCTOR_NAV_ITEMS, false);

        return () => {
            initializeNavigationCb([], false);
        };
    }, [location, initializeNavigationCb]);

    return <InstructorRoutes routes={INSTRUCTOR_NAV_ITEMS} />;
};

export default Instructors;

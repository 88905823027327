import { DateRangePickerProps } from '@amzn/awsui-components-react-v3';

export const relativeOptions: DateRangePickerProps.RelativeOption[] = [
    {
        type: 'relative',
        amount: -4,
        unit: 'week',
        key: 'last-four-weeks',
    },
    {
        type: 'relative',
        amount: -1,
        unit: 'week',
        key: 'last-one-weeks',
    },
    {
        type: 'relative',
        amount: 1,
        unit: 'week',
        key: 'next-one-week',
    },
    {
        type: 'relative',
        amount: 4,
        unit: 'week',
        key: 'next-four-weeks',
    },
    {
        type: 'relative',
        amount: 12,
        unit: 'week',
        key: 'next-twelve-weeks',
    },
];

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import scheduleManagementApi from '../../api/scheduleManagementApi';
import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';
import { ActivityGroupListResponseData } from '../../interfaces/ActivityGroupListResponseData';
import { ActivityGroupRecord } from '../../interfaces/activityGroup';

export interface ActivityGroupListState {
    activityGroups: ActivityGroupRecord[];
    totalActivityGroups: number;
    isLoading: boolean;
    error: string | null;
    nextToken: string | null;
}

export const fetchActivityGroups = createAsyncThunk<
    {
        activity_groups: ActivityGroupRecord[];
        total_activity_groups: number;
        next_token: string | null;
    },
    PropertyFilterProps.Query | undefined,
    { rejectValue: string }
>(
    'activityGroupList/fetchActivityGroups',
    async (query, { rejectWithValue }) => {
        if (query && query.tokens && query.tokens.length === 0) {
            query = undefined;
        }

        try {
            const response: HandleRequestData<ActivityGroupListResponseData> =
                await scheduleManagementApi.getActivityGroupList(query);
            if (response.status === 200) {
                return {
                    activity_groups: response.result.activity_groups,
                    total_activity_groups:
                        response.result.total_activity_groups,
                    next_token: response.result.next_token || null,
                };
            } else {
                return rejectWithValue(
                    `Failed to fetch activity groups. Status: ${response.status}`,
                );
            }
        } catch (error: any) {
            return rejectWithValue(
                `Failed to fetch activity groups with current Filter`,
            );
        }
    },
);

const activityGroupListSlice = createSlice({
    name: 'activityGroupList',
    initialState: {
        activityGroups: [],
        totalActivityGroups: 0,
        isLoading: false,
        error: null,
        nextToken: null,
    } as ActivityGroupListState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchActivityGroups.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(
                fetchActivityGroups.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        activity_groups: ActivityGroupRecord[];
                        total_activity_groups: number;
                        next_token: string | null;
                    }>,
                ) => {
                    state.isLoading = false;
                    state.activityGroups = action.payload.activity_groups;
                    state.totalActivityGroups =
                        action.payload.total_activity_groups;
                    state.nextToken = action.payload.next_token;
                },
            )
            .addCase(fetchActivityGroups.rejected, (state, action) => {
                state.isLoading = false;
                state.activityGroups = [];
                state.totalActivityGroups = 0;
                state.error = action.payload || 'An error occurred';
                state.nextToken = null;
            });
    },
});

export default activityGroupListSlice.reducer;
export const selectActivityGroups = (state: {
    activityGroupList: ActivityGroupListState;
}) => state.activityGroupList.activityGroups;
export const selectTotalActivityGroups = (state: {
    activityGroupList: ActivityGroupListState;
}) => state.activityGroupList.totalActivityGroups;
export const selectIsLoading = (state: {
    activityGroupList: ActivityGroupListState;
}) => state.activityGroupList.isLoading;
export const selectError = (state: {
    activityGroupList: ActivityGroupListState;
}) => state.activityGroupList.error;
export const selectNextToken = (state: {
    activityGroupList: ActivityGroupListState;
}) => state.activityGroupList.nextToken;

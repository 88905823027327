import { GrimsbyNotification } from '../context/grimsbyNotifications';

const handleEditUserNotification = async (
    dispatchPromise: Promise<boolean>,
    addNotification: (notification: GrimsbyNotification) => void,
): Promise<boolean> => {
    const isSuccessful = await dispatchPromise;

    addNotification({
        id: `update-user-${Date.now()}`,
        ...(isSuccessful
            ? {
                  type: 'success',
                  content: 'You have successfully edited this user.',
              }
            : {
                  type: 'error',
                  content: 'An error occurred while editing this user.',
              }),
    });

    return isSuccessful;
};

export default handleEditUserNotification;

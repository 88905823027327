import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RevenueTypesResponseData } from '../../interfaces/businessDataResponse/revenueTypesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { RevenueTypeItemData } from '../../interfaces/businessDataItem/revenueTypeItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * revenueTypesSlice manages all revenueTypes state, and contains revenueTypes actions as well as revenueTypes state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const revenueTypesSlice = createSlice({
    name: 'revenueTypes',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<RevenueTypeItemData>,
    reducers: {
        setRevenueTypesList: (
            state,
            action: PayloadAction<RevenueTypeItemData[]>,
        ) => {
            const byRevenueTypeName = action.payload.reduce(
                (
                    byRevenueTypeName: {
                        [key: string]: RevenueTypeItemData;
                    },
                    revenueType: RevenueTypeItemData,
                ) => {
                    byRevenueTypeName[revenueType.revenue_type] = {
                        ...revenueType,
                        active: parseBoolean(revenueType.active),
                    };
                    return byRevenueTypeName;
                },
                {},
            );
            state.entities = byRevenueTypeName;
            state.keysList = Object.keys(byRevenueTypeName);
        },
        setRevenueType: (state, action: PayloadAction<RevenueTypeItemData>) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.revenue_type]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.revenue_type,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.revenue_type] = action.payload;
        },
        setSelectedRevenueType: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPartialRevenueTypesSlice: resetBasicBusinessDataStoreState,
        resetRevenueTypesSlice: resetExtendedBusinessDataStoreState,
        initializeRevenueTypesListQueryParams:
            initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setRevenueTypesList,
    setRevenueType,
    setSelectedRevenueType,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    resetPartialRevenueTypesSlice,
    resetRevenueTypesSlice,
    initializeRevenueTypesListQueryParams,
} = revenueTypesSlice.actions;

const handleRevenueTypesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: revenue_type,
            active,
        } = state.revenueTypes;

        const params: BusinessDataApiQueryParams.GetRevenueTypes = {
            active,
        };

        // if both "from" and "size" equal to 0, avoid adding them into the query parameters
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (revenue_type) {
            params.revenue_type = revenue_type;
        }

        try {
            const {
                result: { REVENUE_TYPE, total_count },
            }: HandleRequestData<RevenueTypesResponseData> =
                await businessDataApi.getRevenueTypes(params);

            dispatch(setRevenueTypesList(REVENUE_TYPE));
            dispatch(setCount(total_count));
            dispatch(setPagesCount(Math.ceil(total_count / size)));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getRevenueTypes API error'));
        }
    };
};

export const getRevenueTypesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleRevenueTypesListRequest());
        if (!state.revenueTypes.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update revenue type
 * before update api call, fetch revenue types and prepare payload containing only the changed fields
 * after update api call, fetch revenue types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateRevenueType = (data: RevenueTypeItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating revenue type: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateRevenueType(data.pk, data.active);
            await dispatch(handleRevenueTypesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setRevenueType({
                    ...state.revenueTypes.entities[data.revenue_type],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addRevenueType = (active = true, revenueTypeName: string) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addRevenueType(revenueTypeName, active);
            // now refresh the keysList
            await dispatch(handleRevenueTypesListRequest());
            if (!state.revenueTypes?.entities[revenueTypeName]) {
                // shove the item into the keysList, but only if it's not there already
                const newRevenueTypeData: RevenueTypeItemData = {
                    revenue_type: revenueTypeName,
                    active: active,
                };
                dispatch(setRevenueType(newRevenueTypeData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllRevenueTypes = (state: GlobalState) => {
    return state.revenueTypes.keysList.map(
        (revenueTypeName) => state.revenueTypes.entities[revenueTypeName],
    );
};

export const selectAllActiveRevenueTypes = (state: GlobalState) => {
    return getActiveBusinessDataValues(
        state.revenueTypes.keysList,
        state.revenueTypes.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.revenueTypes.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.revenueTypes.isLoaded;

export const selectSelectedRevenueType = (state: GlobalState) => {
    return state?.revenueTypes?.selectedItemKey
        ? state?.revenueTypes?.entities[state?.revenueTypes?.selectedItemKey]
        : null;
};

export const selectError = (state: GlobalState) => state.revenueTypes.error;

export const selectCount = (state: GlobalState) => state.revenueTypes.count;

export const selectSearchText = (state: GlobalState) =>
    state.revenueTypes.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.revenueTypes.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.revenueTypes.currentPageIndex;

export default revenueTypesSlice.reducer;

import { useMemo } from 'react';
import { FormField, Select } from '@amzn/awsui-components-react-v3';
import { ActivityData } from '../../../../interfaces/activity';
import { ActivityAudienceItemData } from '../../../../../common/interfaces/businessDataItem/activityAudienceItem';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInputPolarisV3,
    getStatusType,
} from '../../../../../imt/components/Instructor/FormSections/FormSections.common';
import { useSelector } from 'react-redux';
import {
    selectAllActiveActivityAudiences,
    selectError as selectActivityAudienceListError,
    selectIsLoaded as selectIsActivityAudienceListLoaded,
    selectIsLoading as selectIsActivityAudienceListLoading,
} from '../../../../../common/store/slices/activityAudiencesSlice';

interface AudienceListSelectProps {
    handleFieldEvent: (values: Partial<ActivityData>) => void;
    formValues: Pick<
        Partial<ActivityData>,
        'activity_audience' | 'activity_modality' | 'program'
    >;
    errors: {
        [key in keyof Pick<
            Partial<ActivityData>,
            'activity_audience' | 'activity_modality' | 'program'
        >]: string;
    };
}
const AudienceListSelect = ({
    formValues,
    handleFieldEvent,
    errors,
}: AudienceListSelectProps) => {
    const activityAudienceList = useSelector(selectAllActiveActivityAudiences);
    const activityAudienceListError = useSelector(
        selectActivityAudienceListError,
    );
    const isActivityAudienceListLoading = useSelector(
        selectIsActivityAudienceListLoading,
    );
    const isActivityAudienceListLoaded = useSelector(
        selectIsActivityAudienceListLoaded,
    );

    const {
        valueLookup: activityAudienceLookup,
        valueOptions: activityAudienceOptions,
    } = useMemo(() => {
        return getOptionsAndLookupForSelectInputPolarisV3<ActivityAudienceItemData>(
            activityAudienceList.filter((activityAudience) => {
                if (
                    ![
                        'Commercial',
                        'Partner Program',
                        'Dedicated Cloud',
                        'Marketing',
                    ].includes(formValues.program)
                ) {
                    return activityAudience;
                }

                if (formValues.program === 'Commercial') {
                    return [
                        'Public',
                        'Commercial Private',
                        'Commercial Semi-Private',
                        'Internal',
                    ].includes(activityAudience.activity_audience)
                        ? activityAudience
                        : false;
                }

                if (formValues.program === 'Marketing') {
                    return ['Public'].includes(
                        activityAudience.activity_audience,
                    )
                        ? activityAudience
                        : false;
                }

                if (formValues.program === 'Partner Program') {
                    return [
                        'Partner Public',
                        'Partner Public Unlisted',
                        'Partner Private',
                        'Partner Semi-Private',
                        'Internal',
                    ].includes(activityAudience.activity_audience)
                        ? activityAudience
                        : false;
                }

                if (formValues.program === 'Dedicated Cloud') {
                    return [
                        'Commercial Private',
                        'Commercial Semi-Private',
                    ].includes(activityAudience.activity_audience)
                        ? activityAudience
                        : false;
                }

                return false;
            }),
            (activityAudience: ActivityAudienceItemData) => ({
                label: activityAudience.activity_audience,
                value: activityAudience.pk as string,
            }),
        );
    }, [activityAudienceList, formValues.program]);

    return (
        <FormField errorText={errors?.activity_audience} label="Audience">
            <Select
                className={errors?.activity_audience && FORM_ERROR_SELECTOR}
                placeholder={
                    isActivityAudienceListLoading
                        ? 'Loading audiences'
                        : 'Select an audience'
                }
                options={activityAudienceOptions}
                selectedOption={
                    activityAudienceLookup[formValues.activity_audience]
                }
                onChange={(e) =>
                    handleFieldEvent({
                        activity_audience: e.detail.selectedOption.label,
                    })
                }
                loadingText="Loading audiences"
                errorText="An error occurred while loading audiences"
                empty="No audiences found"
                statusType={getStatusType(
                    isActivityAudienceListLoading,
                    isActivityAudienceListLoaded,
                    activityAudienceListError,
                )}
                disabled={!isActivityAudienceListLoaded}
                data-testid={`EditBaseDetailsActivityAudience`}
            />
        </FormField>
    );
};

export default AudienceListSelect;

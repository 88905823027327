import { configureStore } from '@reduxjs/toolkit';

import selectedActivityReducer from '../smt/store/slices/selectedActivitySlice';
import recommendedInstructorsReducer from '../smt/store/slices/recommendedInstructorsSlice';
import selectedInstructorReducer from '../imt/store/slices/selectedInstructorSlice';
import instructorListReducer from '../imt/store/slices/instructorListSlice';
import selectedUserReducer from '../admin/store/slices/selectedUserSlice';
import auditTrailReducer from '../common/store/slices/auditTrailSlice';
import userListReducer from '../common/store/slices/userListSlice';
import instructorTypesReducer from '../common/store/slices/instructorTypesSlice';
import instructorStatusesReducer from '../common/store/slices/instructorStatusesSlice';
import programTypesReducer from '../common/store/slices/programTypesSlice';
import courseDomainsReducer from '../common/store/slices/courseDomainsSlice';
import partnerInitiativesReducer from '../common/store/slices/partnerInitiativesSlice';
import atpCompaniesReducer from '../common/store/slices/atpCompaniesSlice';
import deliveryCountriesReducer from '../common/store/slices/deliveryCountriesSlice';
import certificationsReducer from '../common/store/slices/certificationsSlice';
import locationsReducer from '../common/store/slices/locationsSlice';
import coursesReducer from '../common/store/slices/coursesSlice';
import courseListReducer from '../common/store/slices/courseListSlice';
import selectedCourseReducer from '../admin/store/slices/selectedCourseSlice';
import deliveryLanguagesReducer from '../common/store/slices/deliveryLanguagesSlice';
import domainSkillsReducer from '../common/store/slices/domainSkillsSlice';
import instructorCourseStatusesReducer from '../common/store/slices/instructorCourseStatusesSlice';
import userReducer from '../common/store/slices/userSlice';
import freelancerStatusesReducer from '../common/store/slices/freelancerStatusesSlice';
import regionsReducer from '../common/store/slices/regionsSlice';
import geographiesReducer from '../common/store/slices/geographiesSlice';
import countriesReducer from '../common/store/slices/countriesSlice';
import activityListReducer from '../smt/store/slices/activityListSlice';
import sfdcListReducer from '../smt/store/slices/sfdcListSlice';
import activityAudiencesReducer from '../common/store/slices/activityAudiencesSlice';
import activityStatusesReducer from '../common/store/slices/activityStatusesSlice';
import activityModalitiesReducer from '../common/store/slices/activityModalitiesSlice';
import activityTypesReducer from '../common/store/slices/activityTypesSlice';
import milestoneTypesReducer from '../common/store/slices/milestoneTypesSlice';
import revenueTypesReducer from '../common/store/slices/revenueTypesSlice';
import costTypesReducer from '../common/store/slices/costTypesSlice';
import instructorRoleTypesReducer from '../common/store/slices/instructorRoleTypesSlice';
import instructorBlockedTimeTypesReducer from '../common/store/slices/instructorBlockedTimeTypesSlice';
import featuresReducer from '../common/store/slices/featureSlice';
import smtInstructorListReducer from '../smt/store/slices/instructorListSlice';
import timezonesReducer from '../common/store/slices/timezonesSlice';
import courseCategoriesReducer from '../common/store/slices/courseCategoriesSlice';
import activityGroupReducer from '../smt/store/slices/activityGroupSlice';
import activityGroupListReducer from '../smt/store/slices/activityGroupListSlice';
// comment for hygen script to append new import above

const store = configureStore({
    reducer: {
        user: userReducer,
        auditTrail: auditTrailReducer,
        userList: userListReducer,
        instructorList: instructorListReducer,
        selectedUser: selectedUserReducer,
        selectedInstructor: selectedInstructorReducer,
        instructorTypes: instructorTypesReducer,
        instructorStatuses: instructorStatusesReducer,
        programTypes: programTypesReducer,
        courseDomains: courseDomainsReducer,
        courseCategories: courseCategoriesReducer,
        partnerInitiatives: partnerInitiativesReducer,
        atpCompanies: atpCompaniesReducer,
        deliveryCountries: deliveryCountriesReducer,
        certifications: certificationsReducer,
        locations: locationsReducer,
        courses: coursesReducer,
        courseList: courseListReducer,
        selectedCourse: selectedCourseReducer,
        deliveryLanguages: deliveryLanguagesReducer,
        domainSkills: domainSkillsReducer,
        instructorCourseStatuses: instructorCourseStatusesReducer,
        freelancerStatuses: freelancerStatusesReducer,
        regions: regionsReducer,
        geographies: geographiesReducer,
        countries: countriesReducer,
        activityList: activityListReducer,
        activityAudiences: activityAudiencesReducer,
        activityStatuses: activityStatusesReducer,
        activityModalities: activityModalitiesReducer,
        activityTypes: activityTypesReducer,
        milestoneTypes: milestoneTypesReducer,
        sfdcList: sfdcListReducer,
        revenueTypes: revenueTypesReducer,
        costTypes: costTypesReducer,
        instructorRoleTypes: instructorRoleTypesReducer,
        instructorBlockedTimeTypes: instructorBlockedTimeTypesReducer,
        features: featuresReducer,
        selectedActivity: selectedActivityReducer,
        recommendedInstructors: recommendedInstructorsReducer,
        smtInstructorList: smtInstructorListReducer,
        timezones: timezonesReducer,
        activityGroup: activityGroupReducer,
        activityGroupList: activityGroupListReducer,
    },
});

export default store;

export type AppDispatch = typeof store.dispatch;

export type GlobalState = ReturnType<typeof store.getState>;

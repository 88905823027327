import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Table } from '@amzn/awsui-components-react';
import cityTimezones from 'city-timezones';

import {
    resetLocationsSlice,
    initializeLocationsListQueryParams,
    getLocationsList,
    selectIsLoading,
    selectIsLoaded,
    selectAllLocations,
    selectError,
    updateLocation,
    selectSelectedLocation,
    setSelectedLocation,
    addLocation,
    selectCount,
} from '../../../common/store/slices/locationsSlice';
import { LocationItemData } from '../../../common/interfaces/businessDataItem/locationItem';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { CountryItemData } from '../../../common/interfaces/businessDataItem/countryItem';
import {
    getCountriesList,
    selectActivePlusLocationSelection as selectCountries,
} from '../../../common/store/slices/countriesSlice';
import { formatString } from '../../../common/utils/formatString';

const LOCATIONS_TABLE_TITLE = 'Locations';
const LOCATIONS_DISPLAY_SINGULAR = 'Location';
export const LOCATIONS_NAME_KEY = 'city';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'state',
        header: 'State',
        cell: (location: LocationItemData) => formatString(location.state),
    },
    {
        id: 'country',
        header: 'Country',
        cell: (location: LocationItemData) => formatString(location.country),
    },
    {
        id: 'region',
        header: 'Region',
        cell: (location: LocationItemData) => formatString(location.region),
    },
    {
        id: 'geo',
        header: 'Geo',
        cell: (location: LocationItemData) => formatString(location.geo),
    },
    {
        id: 'active',
        header: 'Status',
        cell: (location: LocationItemData) =>
            formatStatus(location.active.toString()),
    },
];

const AdminLocations = () => {
    const dispatch = useDispatch();
    const countries: CountryItemData[] = useSelector(selectCountries);
    const [cityFilter, setCityFilter] = useState<string | null>(null);
    const [cityOptions, setCityOptions] = useState<Array<Select.Option>>([]);
    const [cityLookup, setCityLookup] = useState<any>({});

    const { addNotification } = useNotifications();

    function useLocations(): AdminBusinessDataSelectors<LocationItemData> {
        const dispatch = useDispatch();

        const items: LocationItemData[] = useSelector(selectAllLocations);
        const itemCount: number = useSelector(selectCount);

        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: LocationItemData | null = useSelector(
            selectSelectedLocation,
        );

        useEffect(() => {
            dispatch(
                initializeLocationsListQueryParams({
                    active: 'all',
                    size: 0,
                }),
            );
            dispatch(getLocationsList());

            return () => {
                dispatch(resetLocationsSlice());
            };
        }, [dispatch]);
        return {
            items,
            itemCount,
            isLoading,
            isLoaded,
            error,
            currentItem,
        };
    }

    const { items: locations } = useLocations();

    const onSelectHandler = async (location: LocationItemData) => {
        if (location) {
            dispatch(setSelectedLocation(location.pk));
            await Promise.all([
                dispatch(getLocationsList()),
                dispatch(getCountriesList()),
            ]);
        }
    };

    const onUpdateHandler = async (data: LocationItemData) => {
        dispatch(setSelectedLocation(null));
        const dispatchPromise = dispatch<any>(updateLocation(data));
        await handleBusinessDataNotification({
            businessDataType: LOCATIONS_NAME_KEY,
            businessDataDisplayNameLower:
                LOCATIONS_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.city,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const onCreateModalOpenHandler = async () => {
        await dispatch(getCountriesList());
    };

    const onCreateHandler = async (newLocation: LocationItemData) => {
        let cityData = newLocation;

        if (!newLocation.city_longitude) {
            let cityInfo = cityTimezones.findFromCityStateProvince(
                `${newLocation.city} ${newLocation.state}`,
            );
            if (cityInfo.length) {
                cityData = cityInfo[0]
                    ? {
                          ...newLocation,
                          city_latitude: cityInfo[0].lat + '',
                          city_longitude: cityInfo[0].lng + '',
                          city_timezone: cityInfo[0].timezone,
                          country_code: cityInfo[0].iso2,
                          state_province: cityInfo[0].state_ansi,
                      }
                    : newLocation;
            }
        }
        const dispatchPromise = dispatch<any>(addLocation(cityData));
        await handleBusinessDataNotification({
            businessDataType: LOCATIONS_NAME_KEY,
            businessDataDisplayNameLower:
                LOCATIONS_DISPLAY_SINGULAR.toLowerCase(),
            itemName: newLocation.city,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    const onValidate = async (pendingLocation: LocationItemData) => {
        const cityData = pendingLocation;

        // don't allow duplicates
        const existingLocation = locations
            .filter((location) => {
                return (
                    location.city === cityData.city &&
                    location.country === cityData.country &&
                    location.state === cityData.state
                );
            })
            .pop();

        if (existingLocation) {
            return {
                city: 'This location already exists.',
            };
        }

        return;
    };

    const { countryOptions, countryLookup, countryEntityLookup } =
        countries.reduce(
            (acc, country) => {
                const opt = {
                    label: country.country,
                    id: country.country,
                } as Select.Option;
                if (!country.active) {
                    opt.description = 'inactive';
                }
                acc.countryLookup[country.country] = opt;
                acc.countryOptions.push(opt);
                acc.countryEntityLookup[country.country] = country;
                return acc;
            },
            {
                countryLookup: {} as {
                    [key: string]: Select.Option;
                },
                countryOptions: [] as Array<Select.Option>,
                countryEntityLookup: {} as {
                    [key: string]: CountryItemData;
                },
            },
        );

    useEffect(() => {
        if (!cityFilter) {
            setCityOptions([]);
            setCityLookup({});
        } else {
            let cityDataLookup: any = {};

            const cityData = cityTimezones
                .findFromCityStateProvince(cityFilter)
                .map((city) => {
                    const cityOption = {
                        description: city.province
                            ? `${city.city}, ${city.province}`
                            : `${city.city}, ${city.country}`,
                        id: city.city,
                        label: city.city,
                    };
                    cityDataLookup[city.city] = cityOption;
                    return cityOption;
                });

            setCityOptions(cityData);
            setCityLookup(cityDataLookup);
        }
    }, [cityFilter]);

    const handleCitySelected = (val: any): Partial<LocationItemData> => {
        if (val?.description) {
            const cityData = cityTimezones.findFromCityStateProvince(
                val.description,
            );
            if (cityData.length) {
                let country = countries
                    .filter(
                        (country) =>
                            country.country_code === cityData[0].iso2 ||
                            country.country === cityData[0].country,
                    )
                    .pop();
                if (country) {
                    return {
                        city: val.id,
                        state: cityData[0].province,
                        city_timezone: cityData[0].timezone,
                        city_latitude: cityData[0].lat + '',
                        city_longitude: cityData[0].lng + '',
                        state_province: cityData[0].state_ansi,
                        country_code: cityData[0].iso2,
                        country: country.country,
                        geo: country.geo,
                        region: country.region,
                    };
                } else {
                    return {
                        city: val.id,
                        state: cityData[0].province,
                        city_timezone: cityData[0].timezone,
                        city_latitude: cityData[0].lat + '',
                        city_longitude: cityData[0].lng + '',
                    };
                }
            }
            return { city: val.id };
        }
        return { city: val };
    };

    /**
     * Editable form fields for Location
     * The keys must map to LocationItemData properties
     */
    const createFormSchema: AdminBusinessDataFormSchema<LocationItemData> = {
        // the keys must match LocationItemData properties
        city: {
            type: AdminBusinessDataFormInputType.Select,
            label: `${LOCATIONS_DISPLAY_SINGULAR} name`,
            placeholder: `${LOCATIONS_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            options: cityOptions,
            lookup: cityLookup,
            filteringType: 'manual',
            formDataTransform: handleCitySelected,
            onDelayedFilteringChange: (e: any) => {
                setCityFilter(e.detail.value);
            },
        },
        state: {
            type: AdminBusinessDataFormInputType.Text,
            label: `State`,
            required: false,
            defaultValue: null,
            disabled: true,
            formDataTransform: (val: string): Partial<LocationItemData> => {
                return { state: val };
            },
        },
        country: {
            type: AdminBusinessDataFormInputType.Select,
            label: 'Country',
            placeholder: 'Select a country',
            required: true,
            defaultValue: null,
            disabled: true,
            options: countryOptions,
            lookup: countryLookup,
            formDataTransform: (
                val: Select.Option,
            ): Partial<LocationItemData> => {
                return {
                    country: val.label,
                    region: countryEntityLookup[val.label].region,
                    geo: countryEntityLookup[val.label].geo,
                };
            },
        },
        region: {
            type: AdminBusinessDataFormInputType.Text,
            label: `Region`,
            required: true,
            defaultValue: null,
            disabled: true,
            formDataTransform: (val: string): Partial<LocationItemData> => {
                return { region: val };
            },
        },
        geo: {
            type: AdminBusinessDataFormInputType.Text,
            label: `Geo`,
            required: true,
            defaultValue: null,
            disabled: true,
            formDataTransform: (val: string): Partial<LocationItemData> => {
                return { geo: val };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<LocationItemData> => {
                return { active: parseBoolean(val) };
            },
        },
    };

    // The update form schema is the same as the create form schema except only the 'active' field is enabled
    // since the API does not allow updating `state`, so it doesn't make sense to allow updating `country` either.
    // currently the API does not allow *creating* duplicate city names, even in other regions
    // but there may be existing duplicate city names in the data
    // later the API may allow updating state, and we may change to allow updating any field, including city name
    const updateFormSchema: AdminBusinessDataFormSchema<LocationItemData> = {
        ...createFormSchema,
        city: {
            type: AdminBusinessDataFormInputType.Text,
            label: `City`,
            required: false,
            defaultValue: null,
            formDataTransform: (val: string): Partial<LocationItemData> => {
                return { state: val };
            },
            disabled: true,
        },
        state: {
            ...createFormSchema.state,
            disabled: true,
        },
        country: {
            ...createFormSchema.country,
            disabled: true,
        },
    };

    return (
        <div data-testid="AdminBusinessDataManagementLocations">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleCreateModalOpen={onCreateModalOpenHandler}
                handleItemCreate={onCreateHandler}
                handleItemSelect={onSelectHandler}
                handleItemUpdate={onUpdateHandler}
                itemDisplayNameSingular={LOCATIONS_DISPLAY_SINGULAR}
                itemNameKey={LOCATIONS_NAME_KEY}
                title={LOCATIONS_TABLE_TITLE}
                useItemList={useLocations}
                customValidator={onValidate}
            />
        </div>
    );
};

export default AdminLocations;

/*
    UserProfile.tsx will render the logged in user's profile page
*/
import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Spinner } from '@amzn/awsui-components-react';

import UserInfo from './UserInfo';
import { AJAX_CALL_ERROR } from '../../constants/grimsby';
import {
    selectUser,
    selectError,
    selectIsLoading,
} from '../../store/slices/userSlice';

/**
 * UserProfile renders the logged in user`s profile page
 */
const UserProfile = () => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const user = useSelector(selectUser);

    if (error) {
        return (
            <Alert header="Error" type="error">
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (!isLoading && user !== null) {
        return <UserInfo profile={user.profile} />;
    } else {
        return <Spinner />;
    }
};

export default UserProfile;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectedInstructorResponseData } from '../../interfaces/selectedInstructorResponse';
import { InstructorProfileData } from '../../interfaces/instructorProfile';
import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import instructorManagementApi from '../../api/instructorManagementApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { AdminUpdateInstructorPayloadData } from '../../interfaces/adminUpdateInstructorPayload';
import { MAX_RETRY_TIME, RETRY_DELAY } from '../../../common/constants/grimsby';
import { REACH_MAX_RETRY_ATTEMPTS } from '../../../common/constants/errorMessage';
import { CreateInstructorResponseData } from '../../interfaces/createInstructorResponse';
import { ApiError } from '../../../common/classes/ApiError';
import {
    encodeDateNumber,
    decodeDateNumber,
} from '../../../common/utils/formatDate';
import { CreateInstructorActionReturnValue } from '../../interfaces/createInstructorActionReturnValue';
import { InstructorManagementAPIQueryParams } from '../../interfaces/queryParams';

export interface InstructorState extends GenericStoreState {
    selectedInstructor: SelectedInstructorResponseData | null;
    isLoaded: boolean;
    isNewInstructor: boolean;
    requireUpdate: boolean;
    previousTimeStamp: number | null;
}

export const initialState = {
    selectedInstructor: null,
    error: null,
    isLoading: false,
    isLoaded: false,
    isNewInstructor: false,
    requireUpdate: false,
    previousTimeStamp: null,
} as InstructorState;

/**
 * instructorSlice manages all instructor state, and contains instructor actions as well as instructor state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const selectedInstructorSlice = createSlice({
    name: 'selectedInstructor',
    initialState: initialState,
    reducers: {
        setSelectedInstructor: (
            state,
            action: PayloadAction<SelectedInstructorResponseData | null>,
        ) => {
            state.selectedInstructor = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setRequireUpdate: (state, action: PayloadAction<boolean>) => {
            state.requireUpdate = action.payload;
        },
        setIsNewInstructor: (state, action: PayloadAction<boolean>) => {
            state.isNewInstructor = action.payload;
        },
        setPreviousBETimeStamp: (
            state,
            action: PayloadAction<number | null>,
        ) => {
            state.previousTimeStamp = action.payload;
        },
    },
});

export const {
    setSelectedInstructor,
    setError,
    setIsLoading,
    setIsLoaded,
    setRequireUpdate,
    setIsNewInstructor,
    setPreviousBETimeStamp,
} = selectedInstructorSlice.actions;

const setInstructorAndTimestamp = (
    dispatch: AppDispatch,
    result: SelectedInstructorResponseData,
) => {
    dispatch(setSelectedInstructor(result));
    dispatch(setPreviousBETimeStamp(result.instructor?.modified_timestamp));
};

const encodeInstructorDateNumber = (
    instructorData:
        | InstructorProfileData
        | Partial<InstructorProfileData | AdminUpdateInstructorPayloadData>,
) => {
    if (instructorData.onboarding_date) {
        instructorData.onboarding_date = encodeDateNumber(
            instructorData.onboarding_date,
        );
    }
    if (instructorData.final_approval_date) {
        instructorData.final_approval_date = encodeDateNumber(
            instructorData.final_approval_date,
        );
    }
    return instructorData;
};

const decodeInstructorDateNumber = (instructorData: InstructorProfileData) => {
    if (instructorData.onboarding_date) {
        instructorData.onboarding_date = decodeDateNumber(
            instructorData.onboarding_date,
        );
    }
    if (instructorData.final_approval_date) {
        instructorData.final_approval_date = decodeDateNumber(
            instructorData.final_approval_date,
        );
    }
    return instructorData;
};

/**
 * getInstructor is an async action used to fetch instructor data.
 * There is no explicit inclusion of redux-thunk logic, as the redux toolkit takes care of this for us.
 */
export const getSelectedInstructor = (id: string) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        dispatch(setIsLoading(true));

        const state = getState();
        const requireUpdate = state.selectedInstructor.requireUpdate;
        const previousTimeStamp = state.selectedInstructor.previousTimeStamp;
        const isNewInstructor = state.selectedInstructor.isNewInstructor;

        // Make sure the data consistency. Will need to update this
        // when BE fixes high latency of the API calls
        let count = MAX_RETRY_TIME;
        while (count) {
            try {
                const {
                    result,
                }: HandleRequestData<SelectedInstructorResponseData> =
                    await instructorManagementApi.getInstructorById({
                        id,
                    } as InstructorManagementAPIQueryParams.GetInstructorById);
                // if we're requesting an instructor's profile directly after its creation,
                // we retry until result#instructor is set
                // else if the data update is not required, or it's required and the fetched data is up-to-date,
                // save the data and break the loop; otherwise, continue the loop
                if (isNewInstructor) {
                    if (result.instructor) {
                        decodeInstructorDateNumber(result.instructor);
                        setInstructorAndTimestamp(dispatch, result);
                        dispatch(setIsNewInstructor(false));
                        break;
                    }
                } else if (
                    !requireUpdate ||
                    !previousTimeStamp ||
                    (result?.instructor?.modified_timestamp !== null &&
                        result.instructor?.modified_timestamp >
                            previousTimeStamp)
                ) {
                    decodeInstructorDateNumber(result.instructor);
                    setInstructorAndTimestamp(dispatch, result);
                    if (state.selectedInstructor.requireUpdate) {
                        dispatch(setRequireUpdate(false));
                    }
                    break;
                }
            } catch (error: any) {
                // if we encounter any error other than a 404 from the getInstructor call when isNewInstructor is true, set error and break
                if (
                    !(
                        isNewInstructor &&
                        error instanceof ApiError &&
                        error?.statusCode === 404
                    )
                ) {
                    dispatch(setError(error.toString()));
                    break;
                }
            }
            count--;
            // add a time delay to refetch the data
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }

        // if count = 0, i.e. re-fetching data fails, print and set the error
        if (!count) {
            dispatch(setError(REACH_MAX_RETRY_ATTEMPTS));
        }

        if (!state.selectedInstructor.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

export const createInstructor = (formValues: InstructorProfileData) => {
    return async (
        dispatch: AppDispatch,
    ): Promise<CreateInstructorActionReturnValue> => {
        dispatch(setIsLoading(true));

        let newInstructorData = {
            ...formValues,
        } as InstructorProfileData;

        try {
            const {
                result: { instructor_id },
            }: HandleRequestData<CreateInstructorResponseData> =
                await instructorManagementApi.createInstructor(
                    encodeInstructorDateNumber(
                        newInstructorData,
                    ) as InstructorProfileData,
                );
            dispatch(setIsLoading(false));
            return {
                isSuccessful: true,
                createdInstructorId: instructor_id,
            };
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return {
                isSuccessful: false,
                statusCode: (error as ApiError)?.statusCode,
            };
        }
    };
};

export const updateSelectedInstructor =
    (id: string, instructor: Partial<InstructorProfileData>) =>
    async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        if (!id) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating instructor: required field id was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        // delete disallowed update fields pk, record_type
        const { pk, record_type, ...instructorData } = instructor;

        let newInstructorUpdateData =
            instructorData as Partial<AdminUpdateInstructorPayloadData>;

        // if is_freelancer was switched from true to false
        // set freelancer_status, atp_exclusive, freelance_program_manager to default "empty" values
        if (newInstructorUpdateData.is_freelancer === false) {
            newInstructorUpdateData.freelancer_status = null;
        }

        // remove course_certification_mapping
        if (newInstructorUpdateData.course_certification_mapping) {
            delete newInstructorUpdateData.course_certification_mapping;
        }

        try {
            await instructorManagementApi.adminUpdateInstructorById({
                id,
                instructor: encodeInstructorDateNumber(
                    newInstructorUpdateData,
                ) as Partial<AdminUpdateInstructorPayloadData>,
            });
            const newInstructor = {
                message: state.selectedInstructor.selectedInstructor?.message,
                instructor: {
                    ...state.selectedInstructor.selectedInstructor?.instructor,
                    ...instructor,
                },
            } as SelectedInstructorResponseData;
            dispatch(setSelectedInstructor(newInstructor));
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };

export const selectSelectedInstructor = (state: GlobalState) =>
    state.selectedInstructor.selectedInstructor;
export const selectError = (state: GlobalState) =>
    state.selectedInstructor.error;
export const selectIsLoading = (state: GlobalState) =>
    state.selectedInstructor.isLoading;
export const selectIsLoaded = (state: GlobalState) =>
    state.selectedInstructor.isLoaded;

export default selectedInstructorSlice.reducer;

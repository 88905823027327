import React from 'react';
import { Alert } from '@amzn/awsui-components-react';

import { ERROR_404 } from '../../constants/grimsby';

export interface AlertPageData {
    children?: string | JSX.Element;
    header?: string;
    alertType?: Alert.Type;
}

/**
 * Generic alert page component
 * @param children the main content of the Polaris Alert
 * @param header the header of the Polaris Alert
 * @param alertType the alert type of Polaris Alert which can be any of these values "success" | "error" | "warning" | "info"
 */
const AlertPage = ({
    children = ERROR_404,
    header = 'Error',
    alertType = 'error',
}: AlertPageData) => (
    <Alert header={header} type={alertType} data-testid="ErrorAlert">
        {children}
    </Alert>
);

export default AlertPage;

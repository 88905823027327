import { HandleRequestData } from '../interfaces/handleRequest';
import { getCurrentCredentials } from './amplify';
import { Signer } from '@aws-amplify/core';
import getApiUrl from './getApiUrl';
import handleRequest from './handleRequest';

const handleGrimsbyApiRequest = async <T>(
    path: string,
    options: RequestInit,
): Promise<HandleRequestData<T>> => {
    const {
        accessKeyId: access_key,
        secretAccessKey: secret_key,
        sessionToken: session_token,
    } = await getCurrentCredentials();

    const signedRequest = Signer.sign(
        {
            url: `${getApiUrl()}/${path}`,
            method: options.method,
            data: options.body,
        },
        {
            access_key,
            secret_key,
            session_token,
        },
        { region: 'us-east-1', service: 'execute-api' },
    );
    return handleRequest(signedRequest.url, {
        headers: signedRequest.headers,
        method: signedRequest.method,
        ...options,
    });
};

export default handleGrimsbyApiRequest;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryLanguagesResponseData } from '../../interfaces/businessDataResponse/deliveryLanguagesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { DeliveryLanguageItemData } from '../../interfaces/businessDataItem/deliveryLanguageItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';
import { AdminUpdateDeliveryLanguagePayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';

/**
 * deliveryLanguagesSlice manages all deliveryLanguages state, and contains deliveryLanguages actions as well as deliveryLanguages state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const deliveryLanguagesSlice = createSlice({
    name: 'deliveryLanguages',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<DeliveryLanguageItemData>,
    reducers: {
        setDeliveryLanguagesList: (
            state,
            action: PayloadAction<DeliveryLanguageItemData[]>,
        ) => {
            const byDeliveryLanguageName = action.payload.reduce(
                (
                    byDeliveryLanguageName: {
                        [key: string]: DeliveryLanguageItemData;
                    },
                    deliveryLanguage: DeliveryLanguageItemData,
                ) => {
                    byDeliveryLanguageName[deliveryLanguage.delivery_language] =
                        {
                            ...deliveryLanguage,
                            active: parseBoolean(deliveryLanguage.active),
                        };
                    return byDeliveryLanguageName;
                },
                {},
            );
            state.entities = byDeliveryLanguageName;
            state.keysList = Object.keys(byDeliveryLanguageName);
        },
        setDeliveryLanguage: (
            state,
            action: PayloadAction<DeliveryLanguageItemData>,
        ) => {
            // this reducer may be used when adding a new delivery language or updating an existing one.
            // only add to keysList and update count if adding a new delivery language
            if (!state.entities[action.payload.delivery_language]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.delivery_language,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.delivery_language] = action.payload;
        },
        setSelectedDeliveryLanguage: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetDeliveryLanguagesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setDeliveryLanguagesList,
    setDeliveryLanguage,
    setSelectedDeliveryLanguage,
    setError,
    setCount,
    resetDeliveryLanguagesSlice,
} = deliveryLanguagesSlice.actions;

const handleDeliveryLanguagesListRequest =
    () => async (dispatch: AppDispatch) => {
        try {
            const {
                result: { DELIVERY_LANGUAGE, total_count },
            }: HandleRequestData<DeliveryLanguagesResponseData> =
                await businessDataApi.getDeliveryLanguages({ active: 'all' });
            dispatch(setDeliveryLanguagesList(DELIVERY_LANGUAGE));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getDeliveryLanguages API error'),
            );
        }
    };

export const getDeliveryLanguagesList =
    () => async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleDeliveryLanguagesListRequest());
        if (!state.deliveryLanguages.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };

/**
 * action to update delivery language
 * before update api call, fetch delivery language types and prepare payload containing only the changed fields
 * after update api call, fetch delivery language types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateDeliveryLanguage =
    (data: DeliveryLanguageItemData) =>
    async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new delivery language: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        const { active, pk: uuid, iso_code } = data;
        try {
            await businessDataApi.updateDeliveryLanguage({
                active,
                uuid,
                iso_code,
            } as AdminUpdateDeliveryLanguagePayloadData);
            await dispatch(handleDeliveryLanguagesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setDeliveryLanguage({
                    ...state.deliveryLanguages.entities[data.delivery_language],
                    iso_code: data.iso_code,
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };

export const addDeliveryLanguage =
    (active = true, deliveryLanguageName: string, isoCode: string) =>
    async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addDeliveryLanguage(
                deliveryLanguageName,
                isoCode,
                active,
            );
            // now refresh the keysList
            await dispatch(handleDeliveryLanguagesListRequest());
            if (!state.deliveryLanguages?.entities[deliveryLanguageName]) {
                // shove the item into the keysList, but only if it's not there already
                const newDeliveryLanguageData: DeliveryLanguageItemData = {
                    delivery_language: deliveryLanguageName,
                    iso_code: isoCode,
                    active: active,
                };
                dispatch(setDeliveryLanguage(newDeliveryLanguageData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };

export const selectAllDeliveryLanguages = (state: GlobalState) =>
    state.deliveryLanguages.keysList.map(
        (deliveryLanguageName) =>
            state.deliveryLanguages.entities[deliveryLanguageName],
    );

export const selectAllActiveDeliveryLanguages = (
    state: GlobalState,
): Array<DeliveryLanguageItemData> => {
    return getActiveBusinessDataValues(
        state.deliveryLanguages.keysList,
        state.deliveryLanguages.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.deliveryLanguages.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.deliveryLanguages.isLoaded;

export const selectSelectedDeliveryLanguage = (state: GlobalState) =>
    state?.deliveryLanguages?.selectedItemKey
        ? state?.deliveryLanguages?.entities[
              state?.deliveryLanguages?.selectedItemKey
          ]
        : null;

export const selectError = (state: GlobalState) =>
    state.deliveryLanguages.error;

export const selectCount = (state: GlobalState) =>
    state.deliveryLanguages.count;

export default deliveryLanguagesSlice.reducer;

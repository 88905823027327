import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import AdminCourseEditForm from './AdminCoursesEditForm';
import { useRouteMatch } from 'react-router-dom';
import { Alert, Spinner } from '@amzn/awsui-components-react-v3';
import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';
import {
    getSelectedCourse,
    selectError,
    selectIsLoading,
    selectSelectedCourse,
} from '../../../store/slices/selectedCourseSlice';
import { CourseResponseData } from '../../../../common/interfaces/businessDataResponse/courseResponse';

const AdminCoursesEdit = () => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const selectedCourse = useSelector(
        selectSelectedCourse,
    ) as CourseResponseData;
    const match = useRouteMatch<{ id: string }>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedCourse?.COURSE?.pk !== match.params.id) {
            dispatch(getSelectedCourse(match.params.id));
        }
    }, []);

    if (error && selectedCourse?.COURSE === undefined) {
        return (
            <Alert
                data-testid="AdminCoursesEditError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else {
        if (!isLoading && selectedCourse?.COURSE) {
            return <AdminCourseEditForm course={selectedCourse.COURSE} />;
        } else {
            return <Spinner data-testid="AdminCoursesEditSpinner" />;
        }
    }
};

export default AdminCoursesEdit;

import { ClassroomStatus } from '../../../../interfaces/activity';

export const TICKET_TEMPLATE_LINK =
    'https://sim.amazon.com/issues/create?template=b5085b6b-5f50-4179-ab6f-2ff437482916';

export const classroomStatusAlertMap = {
    [ClassroomStatus.CLASSROOM_READY]: {
        type: 'success',
        message: 'Classroom is ready.',
    },
    [ClassroomStatus.CANCELLED]: {
        type: 'info',
        message: 'Classroom has been cancelled.',
    },
    [ClassroomStatus.READY_FOR_CLASSROOM_CREATION]: {
        type: 'info',
        message:
            'Creating a classroom. This process may take up to one minute to complete.',
    },
    [ClassroomStatus.ELIGIBLE_FOR_CLASSROOM_CREATION]: {
        type: 'info',
        message: 'This activity is eligible for classroom creation.',
    },
    [ClassroomStatus.CLASSROOM_UPDATE_FAILED]: {
        type: 'error',
        message: 'Classroom update failed.',
    },
    [ClassroomStatus.CLASSROOM_PROVISIONING_FAILED]: {
        type: 'error',
        message: 'Classroom creation failed.',
    },
};

import { HandleRequestData } from '../../interfaces/handleRequest';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { CountriesResponseData } from '../../interfaces/businessDataResponse/countriesResponse';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const COUNTRIES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/countries`;

/**
 * Method to request the full list of countries
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - query params
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getCountries = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<CountriesResponseData>> => {
    const path = `${COUNTRIES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<CountriesResponseData>(path, {
        method: 'GET',
    });
};

const countriesApi = {
    getCountries,
};

export default countriesApi;

import { getSelectedActivity } from '../../../store/slices/selectedActivitySlice';
import { ActivityData } from '../../../interfaces/activity';
import useInterval from '../../../../common/hooks/useInterval';

const useClassroomStatusPolling = (
    pollForClassroomUpdate: boolean,
    activity: ActivityData,
    dispatch,
    pollInterval: number,
) => {
    const activityId = activity?.pk;

    useInterval(
        async () => {
            if (pollForClassroomUpdate) {
                await dispatch(
                    getSelectedActivity(activityId, {
                        triggerLoadingState: false,
                    }),
                );
            }
        },
        pollForClassroomUpdate && activityId ? pollInterval : null,
    );
};

export default useClassroomStatusPolling;

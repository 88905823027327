export const USER_MANAGEMENT_API_ERROR_MESSAGE = {
    LOAD_USER: {
        NO_PROGRAMS_OR_NO_REGIONS: {
            STATUS_CODE: 200,
            MESSAGE:
                'It looks like you are new to the system because you don’t have any programs or regions set up. Please contact your {{System Administrator}} to give you access.',
        },
        NOT_ACTIVE_USER: {
            STATUS_CODE: 403,
            MESSAGE:
                'Inactive user. Please contact your System Administrator to activate or give you access',
        },
        NO_USER: {
            STATUS_CODE: 404,
            MESSAGE:
                'User does not exist in the system. Please contact your {{System Administrator}} to give you access.',
        },
    },
} as const;

export const REACH_MAX_RETRY_ATTEMPTS =
    "You've reached the maximum number of attempts to retry fetching the data";

import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { AdminCreateDomainSkillPayloadData } from '../../interfaces/adminCreateBusinessDataPayload/domainSkillPayload';
import { DomainSkillsResponseData } from '../../interfaces/businessDataResponse/domainSkillsResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const DOMAIN_SKILLS_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/domain-skills`;

/**
 * Method to request the full list of domain skills
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getDomainSkills = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<DomainSkillsResponseData>> => {
    const path = `${DOMAIN_SKILLS_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<DomainSkillsResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a domain skill.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateDomainSkill = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(DOMAIN_SKILLS_PATH, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

/**
 * Method to add a domain skill.
 * @param {string} domainSkill - the name of the new domain skill type
 * @param {boolean} active - the status of the new domain skill type
 * @return: A promise that resolves with an object containing the result or error of the request
 *
 */
const addDomainSkill = (
    domainSkill: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateDomainSkillPayloadData = {
        domain_skill: domainSkill,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(DOMAIN_SKILLS_PATH, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const domainSkillsApi = {
    getDomainSkills,
    updateDomainSkill,
    addDomainSkill,
};

export default domainSkillsApi;

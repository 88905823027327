import React, { useEffect } from 'react';
import {
    ColumnLayout,
    FormField,
    FormSection,
    Input,
} from '@amzn/awsui-components-react';
import { FORM_ERROR_SELECTOR } from '../../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivityData,
    APNPartnerPrivateCustomer,
} from '../../../../interfaces/activity';

import './CustomerForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerCorrespondenceEditSection } from './CommonEditFormElements';

interface CustomerFormSectionProps {
    formValues: APNPartnerPrivateCustomer;
    errors: { [key in keyof Partial<APNPartnerPrivateCustomer>]: string };
    handleFieldEvent: (values: Partial<APNPartnerPrivateCustomer>) => void;
    activity?: ActivityData;
}

export const APNCustomerFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    activity,
}: CustomerFormSectionProps) => {
    return (
        <FormSection
            data-testid="EditCustomerDetailsFormSection"
            header="APN customer details"
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField
                        errorText={errors?.customer_name}
                        label="Partner name"
                    >
                        <Input
                            className={
                                errors?.customer_name && FORM_ERROR_SELECTOR
                            }
                            value={formValues.customer_name}
                            placeholder="Customer name"
                            onInput={(e) =>
                                handleFieldEvent({
                                    customer_name: e.detail.value,
                                })
                            }
                            data-testid={`EditCustomerName`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.sfdc_opportunity_id}
                        label={
                            <span>
                                SFDC opportunity ID - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.sfdc_opportunity_id &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.sfdc_opportunity_id}
                            placeholder="SFDC opportunity ID"
                            onInput={(e) =>
                                handleFieldEvent({
                                    sfdc_opportunity_id: e.detail.value,
                                })
                            }
                            data-testid={`EditCSFDCID`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.number_of_students_committed}
                        label={
                            <span>
                                Number of students committed - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.number_of_students_committed &&
                                FORM_ERROR_SELECTOR
                            }
                            value={`${formValues.number_of_students_committed}`}
                            placeholder="Number of students committed"
                            type="number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    number_of_students_committed: !!e.detail
                                        .value
                                        ? parseInt(e.detail.value)
                                        : null,
                                })
                            }
                            data-testid={`EditNumberOfStudentsCommmitted`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.number_of_students_attended}
                        label={
                            <span>
                                Number of students attended - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.number_of_students_attended &&
                                FORM_ERROR_SELECTOR
                            }
                            value={`${formValues.number_of_students_attended}`}
                            placeholder="Number of students attended"
                            type="number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    number_of_students_attended: !!e.detail
                                        .value
                                        ? parseInt(e.detail.value)
                                        : null,
                                })
                            }
                            data-testid={`EditNumberOfStudentsAttended`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.internal_poc}
                        label={
                            <span>
                                Internal AWS point of contact -{' '}
                                <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.internal_poc && FORM_ERROR_SELECTOR
                            }
                            value={formValues.internal_poc}
                            placeholder="Name, email, or alias"
                            onInput={(e) =>
                                handleFieldEvent({
                                    internal_poc: e.detail.value,
                                })
                            }
                            data-testid={`EditDeliveryInternalPOC`}
                        />
                    </FormField>
                    <div className="grimsby-sub-section-divider"></div>
                    <h3>Delivery contact</h3>
                    <FormField
                        errorText={errors?.delivery_contact_name}
                        label={
                            <span>
                                Delivery contact name - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.delivery_contact_name &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.delivery_contact_name}
                            placeholder="Delivery contact name"
                            onInput={(e) =>
                                handleFieldEvent({
                                    delivery_contact_name: e.detail.value,
                                })
                            }
                            data-testid={`EditDeliveryContactName`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.delivery_contact_email}
                        label={
                            <span>
                                Delivery contact email - <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.delivery_contact_email &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.delivery_contact_email}
                            placeholder="Delivery contact email"
                            onInput={(e) =>
                                handleFieldEvent({
                                    delivery_contact_email: e.detail.value,
                                })
                            }
                            data-testid={`EditDeliveryContactEmail`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.delivery_contact_phone_number}
                        label={
                            <span>
                                Delivery contact phone number -{' '}
                                <em>optional</em>
                            </span>
                        }
                    >
                        <Input
                            className={
                                errors?.delivery_contact_phone_number &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.delivery_contact_phone_number}
                            placeholder="Delivery contact phone number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    delivery_contact_phone_number:
                                        e.detail.value,
                                })
                            }
                            data-testid={`EditDeliveryContactPhone`}
                        />
                    </FormField>
                    {CustomerCorrespondenceEditSection({
                        formValues,
                        errors,
                        handleFieldEvent,
                        activity,
                    })}
                </div>
            </ColumnLayout>
        </FormSection>
    );
};

import { Icon } from '@amzn/awsui-components-react';
import React from 'react';

export const formatSpeakerCertificationOptions = (
    isSpeakerCertified: boolean | null,
    successfulLabel: string,
): JSX.Element =>
    isSpeakerCertified ? (
        <span className="awsui-util-status-positive nowrap">
            <Icon name="status-positive" /> {successfulLabel}
        </span>
    ) : (
        <span>-</span>
    );

import { CatalogCourseItemData } from '../interfaces/businessDataItem/catalogCourseItem';
import businessDataApi from '../api/businessDataApi';
import { HandleRequestData } from '../interfaces/handleRequest';
import { CatalogCoursesResponseData } from '../interfaces/businessDataResponse/catalogCoursesResponse';
import { CourseItemData } from '../interfaces/businessDataItem/courseItem';

export const getCatalogCoursesByMultipleGroupIds = async (
    ids: string[],
): Promise<CatalogCourseItemData[]> => {
    const courses: CatalogCourseItemData[] = [];
    if (ids.length === 0) return courses;

    const promises = ids.map((id) => businessDataApi.getCatalogCourses(id));
    const responses: HandleRequestData<CatalogCoursesResponseData>[] =
        await Promise.all(promises);

    if (!(responses instanceof Array)) return courses;
    for (const response of responses) {
        const coursesByThisId =
            response.result?.data?.catalogGroupById?.items?.nodes;
        if (coursesByThisId) courses.push(...coursesByThisId);
    }

    return courses;
};

// This method is used for converting Catalog course data
// to course item data aligned with those from Grimsby DDB.
export const convertToCourseItemData = (
    catalogCourse: CatalogCourseItemData,
): CourseItemData => {
    return {
        course: catalogCourse.name,
        pk: '',
        active: true,
        associated_programs: null,
        associated_certifications: null,
        course_days: null,
        course_domain: null,
        course_category: null,
        course_modality: null,
        course_skill_level: null,
        course_content: null,
        locales: null,
    };
};

import FullCalendar from '@fullcalendar/react';
import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';

import { LookupData } from './lookup';
import { RecommendedInstructorProfileData } from './recommendedInstructorProfile';
import { ActivityData, DeliverySession } from './activity';
import { BlockedTime } from '../../common/interfaces/blockedTime';

export interface CalendarItem {
    id: string;
    title?: string;
}

export enum InstructorEventTypes {
    blockedTime = 'blocked_time',
    activity = 'activity',
}

export interface CalendarResource extends Required<CalendarItem> {}

export interface CalendarEvent extends CalendarItem {
    start: number;
    end: number;
    resourceId?: string;
    display?: string;
    backgroundColor: string;
    eventType: InstructorEventTypes;
}

export interface CalendarPropsData {
    events: Array<CalendarEvent>;
    resources: Array<CalendarResource>;
    eventLookup: LookupData<any>;
    resourceLookup: LookupData<RecommendedInstructorProfileData>;
    error?: any;
}

type ResourceLabelType = 'radio' | 'checkbox';

export interface CalendarViewPropsData extends CalendarPropsData {
    handleCalendarRefChange: (calendarRef: FullCalendar) => void;
    handleSelectedInstructorsChange: (
        instructors: Set<RecommendedInstructorProfileData>,
    ) => void;
    selectedInstructors: Set<RecommendedInstructorProfileData>;
    initialDate: number;
    deliverySessions?: Array<DeliverySession>;
    resourceLabelType: ResourceLabelType;
    calendarHeight: number;
    isBlockedTimeInfoModalVisible?: boolean;
    handleEventModalChange?: (isVisible: boolean, eventSource: any) => void;
    handleIsModalVisibleChange?: (isVisible: boolean) => void;
    handleEditBlockedTimeModal?: (blockedTimeItem: BlockedTime) => void;
    timezone?: string;
}

export interface InstructorCalendarFilterPropsData {
    filteringOptions: ReadonlyArray<PropertyFilterProps.FilteringOption>;
    instructorFilteringProperties: ReadonlyArray<PropertyFilterProps.FilteringProperty>;
    query: PropertyFilterProps.Query;
    handleQueryChange: (query: PropertyFilterProps.Query) => void;
}

export interface AssignInstructorModalPropsData
    extends CalendarPropsData,
        InstructorCalendarFilterPropsData {
    isVisible: boolean;
    handleIsVisibleChange: (isVisible: boolean) => void;
    handleAssignInstructorEvent: (
        instructors: Array<RecommendedInstructorProfileData>,
    ) => void;
    assignedInstructorIds: Set<string>;
    deliverySessions: Array<DeliverySession>;
    activityTimezone: string;
    areRecommendedInstructorsLoading: boolean;
    initialDate: number;
    handleFieldEvent: (values: Partial<any>) => void;
    formValues: Omit<ActivityData, 'delivery_sessions'>;
    paginationProps: InstructorPaginationProps;
    handlePreferencesChange?: (event: any) => void;
}

export interface InstructorCalendarHeaderPropsData
    extends InstructorCalendarFilterPropsData {
    calendarRef: FullCalendar | undefined;
    instructorCountText?: string;
    filteringPlaceholder: string;
    handleHeaderAction?: any;
    selectedInstructors?: Set<RecommendedInstructorProfileData>;
    handleTablePaginationChange?: (event: any) => void;
    paginationProps: InstructorPaginationProps;
    savedFiltersComponent?: any;
    count?: number;
    handlePreferencesChange?: (event: any) => void;
}

export interface InstructorPaginationProps {
    onPaginationChange: (event: any) => Promise<void>;
    pagesCount: number;
    currentPageIndex: number;
    pageSize: number;
}

import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { AdminCreateFreelancerStatusPayloadData } from '../../interfaces/adminCreateBusinessDataPayload/freelancerStatusPayload';
import { FreelancerStatusesResponseData } from '../../interfaces/businessDataResponse/freelancerStatusesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const FREELANCER_STATUS_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/freelancer-status`;

/**
 * Method to request the full list of freelancer statuses
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getFreelancerStatuses = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<FreelancerStatusesResponseData>> => {
    const path = `${FREELANCER_STATUS_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<FreelancerStatusesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a freelancer status.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateFreelancerStatus = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(FREELANCER_STATUS_PATH, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

/**
 * Method to add a freelancer status.
 * @param {string} freelancerStatus - the name of the new freelancer status
 * @param {boolean} active - the status of the new freelancer status
 * @return: A promise that resolves with an object containing the result or error of the request
 *
 */
const addFreelancerStatus = (
    freelancerStatus: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateFreelancerStatusPayloadData = {
        freelancer_status: freelancerStatus,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(FREELANCER_STATUS_PATH, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const freelancerStatusApi = {
    getFreelancerStatuses,
    updateFreelancerStatus,
    addFreelancerStatus,
};

export default freelancerStatusApi;

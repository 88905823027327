import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateActivityModalityPayloadData } from '../../interfaces/adminCreateBusinessDataPayload/activityModalityPayload';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { ActivityModalitiesResponseData } from '../../interfaces/businessDataResponse/activityModalitiesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const ACTIVITY_MODALITIES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/activity-modality`;

/**
 * Method to request the full list of activity modalities
 * @param {BusinessDataApiQueryParams.GetActivityModalities} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getActivityModalities = (
    queryParams?: BusinessDataApiQueryParams.GetActivityModalities,
): Promise<HandleRequestData<ActivityModalitiesResponseData>> => {
    let path = `${ACTIVITY_MODALITIES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<ActivityModalitiesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an activity modality.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateActivityModality = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${ACTIVITY_MODALITIES_PATH}`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add an activity modality.
 * @param {string} activityModalityName - the name of the new activity modality
 * @param {boolean} active - the status of the new activity modality
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addActivityModality = (
    activityModalityName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateActivityModalityPayloadData = {
        activity_modality: activityModalityName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${ACTIVITY_MODALITIES_PATH}`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

const activityModalitiesApi = {
    getActivityModalities,
    updateActivityModality,
    addActivityModality,
};

export default activityModalitiesApi;

import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateActivityAudiencePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/activityAudiencePayload';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { ActivityAudiencesResponseData } from '../../interfaces/businessDataResponse/activityAudiencesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const ACTIVITY_AUDIENCES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/activity-audience`;

/**
 * Method to request the full list of activity audiences
 * @param {BusinessDataApiQueryParams.GetActivityAudiences} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getActivityAudiences = (
    queryParams?: BusinessDataApiQueryParams.GetActivityAudiences,
): Promise<HandleRequestData<ActivityAudiencesResponseData>> => {
    let path = `${ACTIVITY_AUDIENCES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<ActivityAudiencesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an activity audience.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateActivityAudience = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${ACTIVITY_AUDIENCES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

/**
 * Method to add an activity audience.
 * @param {string} activityAudienceName - the name of the new activity audience
 * @param {boolean} active - the status of the new activity audience
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addActivityAudience = (
    activityAudienceName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateActivityAudiencePayloadData = {
        activity_audience: activityAudienceName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${ACTIVITY_AUDIENCES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const activityAudiencesApi = {
    getActivityAudiences,
    updateActivityAudience,
    addActivityAudience,
};

export default activityAudiencesApi;

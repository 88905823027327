const handleLocalStorageSetItem = (key: string, value: string): void => {
    try {
        localStorage.setItem(key, value);
    } catch (e) {
        // should maybe log this
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

export default handleLocalStorageSetItem;

import React from 'react';
import { NavItem } from '../interfaces/navigation';
import { Actions } from './auth';
import InstructorList from '../../imt/components/Instructor/List/InstructorList';
import CreateInstructor from '../../imt/components/Instructor/Create/CreateInstructor';
import InstructorDetails from '../../imt/components/Instructor/Details/InstructorDetails';
import EditBasicInfo from '../../imt/components/Instructor/Edit/EditBasicInfo';
import EditInstructorDetails from '../../imt/components/Instructor/Edit/EditInstructorDetails';
import EditSkillsAndCourses from '../../imt/components/Instructor/Edit/EditSkillsAndCourses';
import AdminUserManagementList from '../../admin/components/AdminUserManagement/List/AdminUserManagementList';
import AdminUserManagementCreate from '../../admin/components/AdminUserManagement/Create/AdminUserManagementCreate';
import AdminUserManagementDetails from '../../admin/components/AdminUserManagement/Details/AdminUserManagementDetails';
import AdminUserManagementEdit from '../../admin/components/AdminUserManagement/Edit/AdminUserManagementEdit';
import AdminInstructorTypes from '../../admin/components/AdminInstructorTypes/AdminInstructorTypes';
import AdminInstructorStatuses from '../../admin/components/AdminInstructorStatuses/AdminInstructorStatuses';
import AdminInstructorCourseStatuses from '../../admin/components/AdminInstructorCourseStatuses/AdminInstructorCourseStatuses';
import AdminProgramTypes from '../../admin/components/AdminProgramTypes/AdminProgramTypes';
import AdminAtpCompanies from '../../admin/components/AdminAtpCompanies/AdminAtpCompanies';
import AdminDeliveryCountries from '../../admin/components/AdminDeliveryCountries/AdminDeliveryCountries';
import AdminCertifications from '../../admin/components/AdminCertifications/AdminCertifications';
import AdminCoursesList from '../../admin/components/AdminCourses/List/AdminCoursesList';
import AdminCoursesDetails from '../../admin/components/AdminCourses/Details/AdminCoursesDetails';
import AdminCoursesCreate from '../../admin/components/AdminCourses/Create/AdminCoursesCreate';
import AdminCoursesEdit from '../../admin/components/AdminCourses/Edit/AdminCoursesEdit';
import AdminFreelancerStatuses from '../../admin/components/AdminFreelancerStatuses/AdminFreelancerStatuses';
import AdminDomainSkills from '../../admin/components/AdminDomainSkills/AdminDomainSkills';
import AdminDeliveryLanguages from '../../admin/components/AdminDeliveryLanguages/AdminDeliveryLanguages';
import AdminLocations from '../../admin/components/AdminLocations/AdminLocations';
import AdminActivityAudienceTypes from '../../admin/components/AdminActivityAudienceTypes/AdminActivityAudienceTypes';
import AdminActivityStatuses from '../../admin/components/AdminActivityStatuses/AdminActivityStatuses';
import AdminActivityModalities from '../../admin/components/AdminActivityModalities/AdminActivityModalities';
import AdminActivityTypes from '../../admin/components/AdminActivityTypes/AdminActivityTypes';
import AdminMilestoneTypes from '../../admin/components/AdminMilestoneTypes/AdminMilestoneTypes';
import AdminCourseDomains from '../../admin/components/AdminCourseDomains/AdminCourseDomains';
import AdminCourseCategories from '../../admin/components/AdminCourseCategories/AdminCourseCategories';
import AdminRevenueTypes from '../../admin/components/AdminRevenueTypes/AdminRevenueTypes';
import AdminCostTypes from '../../admin/components/AdminCostTypes/AdminCostTypes';
import AdminInstructorRoleTypes from '../../admin/components/AdminInstructorRoleTypes/AdminInstructorRoleTypes';
import ActivityCreate from '../../smt/components/Activity/Create/ActivityCreate';
import ActivityDetails from '../../smt/components/Activity/Details/ActivityDetails';
import ActivityList from '../../smt/components/Activity/List/ActivityList';
import ActivityGroupCreate from '../../smt/components/Activity/ActivityGroup/modes/ActivityGroupCreate';
import ActivityGroupView from '../../smt/components/Activity/ActivityGroup/modes/ActivityGroupView';
import ActivityGroupList from '../../smt/components/Activity/ActivityGroup/modes/ActivityGroupList';
import ActivityGroupEdit from '../../smt/components/Activity/ActivityGroup/modes/ActivityGroupEdit';
import { ACTIVITY_PATH } from '../../smt/constants/path';
import { INSTRUCTOR_PATH } from '../../imt/constants/path';
import { ADMIN_PATH } from '../../admin/constants/path';
import EditDeliveryDetails from '../../smt/components/Activity/Edit/EditDeliveryDetails';
import EditBaseDetails from '../../smt/components/Activity/Edit/EditBaseDetails';
import EditOperationsAndRegistrationDetails from '../../smt/components/Activity/Edit/EditOperationsAndRegistrationDetails';
import InvoiceCreateForm from '../../smt/components/Activity/Details/InvoicesTab/InvoiceCreateForm';
import InvoiceEditForm from '../../smt/components/Activity/Details/InvoicesTab/InvoiceEditForm';
import SmtInstructorList from '../../smt/components/Instructors/Instructors';
import CustomerCreateForm from '../../smt/components/Activity/Details/CustomersTab/CustomerCreateForm';
import CustomerEditForm from '../../smt/components/Activity/Details/CustomersTab/CustomerEditForm';
import APNCustomerCreateForm from '../../smt/components/Activity/Details/CustomersTab/APNCustomerCreateForm';
import APNCustomerEditForm from '../../smt/components/Activity/Details/CustomersTab/APNCustomerEditForm';
import ExportInvoiceData from '../../smt/components/Clipper/ExportInvoiceData';
import ActivityClone from '../../smt/components/Activity/Clone/ActivityClone';
import SFDCQueue from '../../smt/components/SFDC/SFDCQueue';
import OpportunityDetails from '../../smt/components/SFDC/OpportunityDetails';
import ImportActivity from '../../smt/components/Activity/Import/ActivityImport';
import ImportFreelancer from '../../smt/components/Instructors/FreelancerImport/Import';
import ImportBlockedTimes from '../../smt/components/Instructors/ImportBlockedTimes/ImportBlockedTimes';
import AdminPartnerInitiatives from '../../admin/components/AdminPartnerInitiatives/AdminPartnerInitiatives';
import AdminInstructorBlockedTimeTypes from '../../admin/components/AdminInstructorBlockedTimeTypes/AdminInstructorBlockedTimeTypes';

// comment for hygen script to append new import above

export enum NavType {
    HeaderNav = 'header',
    SideNav = 'sideNav',
}

export enum LinkTypes {
    Link = 'link',
    Section = 'section',
    ExpandableLink = 'expandable-link-group',
}

export const HEADER_NAV_ITEMS: Array<NavItem> = [
    {
        id: 'instructor_header_link',
        type: LinkTypes.Link,
        text: 'Instructor management',
        href: INSTRUCTOR_PATH.LIST,
        requiredPermissionAction: Actions.INSTRUCTOR_VIEW,
        navType: NavType.HeaderNav,
        featureName: 'instructor_mgmt',
    },
    {
        id: 'activity_header_link',
        type: LinkTypes.Link,
        text: 'Schedule management',
        href: ACTIVITY_PATH.LIST,
        requiredPermissionAction: Actions.ACTIVITY_VIEW,
        navType: NavType.HeaderNav,
        featureName: 'schedule_mgmt',
    },
    {
        id: 'admin_header_link',
        type: LinkTypes.Link,
        text: 'System administration',
        href: ADMIN_PATH.BASE,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.HeaderNav,
        featureName: 'admin',
    },
];

export const INSTRUCTOR_NAV_ITEMS: Array<NavItem> = [
    {
        id: 'instructor_root',
        type: LinkTypes.Link,
        text: 'Instructor Management',
        href: INSTRUCTOR_PATH.LIST,
        requiredPermissionAction: Actions.INSTRUCTOR_VIEW,
        navType: NavType.HeaderNav,
        component: <InstructorList />,
    },
    {
        id: 'instructor_create',
        parent: 'instructor_root',
        type: LinkTypes.Link,
        text: 'Add Instructor',
        href: INSTRUCTOR_PATH.CREATE,
        requiredPermissionAction: Actions.INSTRUCTOR_CREATE,
        component: <CreateInstructor />,
    },
    {
        id: 'instructor_detail',
        parent: 'instructor_root',
        type: LinkTypes.Link,
        text: 'Instructor Information',
        href: INSTRUCTOR_PATH.DETAIL,
        requiredPermissionAction: Actions.INSTRUCTOR_VIEW,
        component: <InstructorDetails />,
    },
    {
        id: 'instructor_edit_basic_info',
        parent: 'instructor_detail',
        type: LinkTypes.Link,
        text: 'Basic Info',
        href: INSTRUCTOR_PATH.EDIT_BASIC_INFO,
        requiredPermissionAction: Actions.INSTRUCTOR_MODIFY,
        component: <EditBasicInfo />,
    },
    {
        id: 'instructor_edit_details',
        parent: 'instructor_detail',
        type: LinkTypes.Link,
        text: 'Details',
        href: INSTRUCTOR_PATH.EDIT_INSTRUCTOR_DETAILS,
        requiredPermissionAction: Actions.INSTRUCTOR_MODIFY,
        component: <EditInstructorDetails />,
    },
    {
        id: 'instructor_edit_skills_courses',
        parent: 'instructor_detail',
        type: LinkTypes.Link,
        text: 'Skills and courses',
        href: INSTRUCTOR_PATH.EDIT_SKILLS_AND_COURSES,
        requiredPermissionAction: Actions.INSTRUCTOR_MODIFY,
        component: <EditSkillsAndCourses />,
    },
];

export const ADMIN_NAV_ITEMS: Array<NavItem> = [
    {
        id: 'schedule_mgmt_business_data',
        parent: 'admin_root',
        type: LinkTypes.Section,
        text: 'Schedule management',
        href: '#',
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 2,
        redirect: true,
        redirectTo: ADMIN_PATH.ACTIVITY_MODALITIES,
        featureName: 'schedule_mgmt',
    },
    {
        id: 'schedule_mgmt_business_data_instructor_role_types',
        parent: 'schedule_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Instructor Role Types',
        href: ADMIN_PATH.INSTRUCTOR_ROLE_TYPES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminInstructorRoleTypes />,
    },
    {
        id: 'schedule_mgmt_business_data_cost_types',
        parent: 'schedule_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Cost Types',
        href: ADMIN_PATH.COST_TYPES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminCostTypes />,
    },
    {
        id: 'schedule_mgmt_business_data_revenue_types',
        parent: 'schedule_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Revenue Types',
        href: ADMIN_PATH.REVENUE_TYPES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminRevenueTypes />,
    },
    {
        id: 'admin_root',
        type: LinkTypes.Link,
        text: 'System Administration',
        href: ADMIN_PATH.BASE,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.HeaderNav,
        redirect: true,
        redirectTo: '/admin/user-management/users',
    },
    {
        id: 'general',
        parent: 'admin_root',
        type: LinkTypes.Section,
        text: 'General',
        href: ADMIN_PATH.USER_MANAGEMENT,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 0,
        redirect: true,
        redirectTo: '/admin/user-management/users',
        useChildOrdinal: true,
    },
    {
        id: 'general_users',
        parent: 'general',
        type: LinkTypes.Link,
        text: 'Users',
        href: ADMIN_PATH.USER_LIST,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 0,
        component: <AdminUserManagementList />,
    },
    {
        id: 'general_users_create',
        parent: 'general_users',
        type: LinkTypes.Link,
        text: 'Create New',
        href: ADMIN_PATH.USER_CREATE,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        component: <AdminUserManagementCreate />,
    },
    {
        id: 'general_users_detail',
        parent: 'general_users',
        type: LinkTypes.Link,
        text: 'User Details',
        href: ADMIN_PATH.USER_DETAIL,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        component: <AdminUserManagementDetails />,
    },
    {
        id: 'general_users_edit',
        parent: 'general_users_detail',
        type: LinkTypes.Link,
        text: 'Edit',
        href: ADMIN_PATH.USER_EDIT,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        component: <AdminUserManagementEdit />,
    },
    {
        id: 'general_business_data_program_types',
        parent: 'general',
        type: LinkTypes.Link,
        text: 'Program Types',
        href: ADMIN_PATH.PROGRAM_TYPES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 2,
        component: <AdminProgramTypes />,
    },
    {
        id: 'general_business_data_atp_companies',
        parent: 'general',
        type: LinkTypes.Link,
        text: 'Providers',
        href: ADMIN_PATH.ATP_COMPANIES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 3,
        component: <AdminAtpCompanies />,
    },
    {
        id: 'general_business_data_courses_list',
        parent: 'general',
        type: LinkTypes.Link,
        text: 'Courses',
        href: ADMIN_PATH.COURSES_LIST,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 4,
        component: <AdminCoursesList />,
    },
    {
        id: 'general_business_data_courses_create',
        parent: 'general_business_data_courses_list',
        type: LinkTypes.Link,
        text: 'Course Create',
        href: ADMIN_PATH.COURSES_CREATE,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        component: <AdminCoursesCreate />,
    },
    {
        id: 'general_business_data_courses_details',
        parent: 'general_business_data_courses_list',
        type: LinkTypes.Link,
        text: 'Course Details',
        href: ADMIN_PATH.COURSES_DETAIL,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        component: <AdminCoursesDetails />,
    },
    {
        id: 'general_business_data_course_details_edit',
        parent: 'general_business_data_courses_list',
        type: LinkTypes.Link,
        text: 'Edit Course',
        href: ADMIN_PATH.COURSE_DETAILS_EDIT,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        component: <AdminCoursesEdit />,
    },
    {
        id: 'general_business_data_instructor_locations',
        parent: 'general',
        type: LinkTypes.Link,
        text: 'Locations',
        href: '/admin/business-data/locations',
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 1,
        component: <AdminLocations />,
    },
    {
        id: 'instructor_mgmt_business_data',
        parent: 'admin_root',
        type: LinkTypes.Section,
        text: 'Instructor Management',
        href: '#',
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 1,
        redirect: true,
        redirectTo: ADMIN_PATH.CERTIFICATIONS,
    },
    {
        id: 'general_business_data_milestone_types',
        parent: 'general',
        type: LinkTypes.Link,
        text: 'Milestone Types',
        href: ADMIN_PATH.MILESTONE_TYPES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 1,
        component: <AdminMilestoneTypes />,
    },
    {
        id: 'general_business_data_course_domains',
        parent: 'general',
        type: LinkTypes.Link,
        text: 'Course Domains',
        href: ADMIN_PATH.COURSE_DOMAINS,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 1,
        component: <AdminCourseDomains />,
    },
    {
        id: 'general_business_data_course_categories',
        parent: 'general',
        type: LinkTypes.Link,
        text: 'Course Categories',
        href: ADMIN_PATH.COURSE_CATEGORIES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        ordinal: 1,
        component: <AdminCourseCategories />,
    },
    {
        id: 'instructor_mgmt_business_data_instructor_types',
        parent: 'instructor_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Instructor Types',
        href: ADMIN_PATH.INSTRUCTOR_TYPES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminInstructorTypes />,
    },
    {
        id: 'instructor_mgmt_business_data_instructor_statuses',
        parent: 'instructor_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Instructor Statuses',
        href: ADMIN_PATH.INSTRUCTOR_STATUSES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminInstructorStatuses />,
    },
    {
        id: 'instructor_mgmt_business_data_instructor_course_statuses',
        parent: 'instructor_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Instructor Course Statuses',
        href: ADMIN_PATH.INSTRUCTOR_COURSE_STATUSES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminInstructorCourseStatuses />,
    },
    {
        id: 'instructor_mgmt_business_data_delivery_countries',
        parent: 'instructor_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Delivery Countries',
        href: ADMIN_PATH.DELIVERY_COUNTRIES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminDeliveryCountries />,
    },
    {
        id: 'instructor_mgmt_business_data_instructor_certifications',
        parent: 'instructor_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Certifications',
        href: ADMIN_PATH.CERTIFICATIONS,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminCertifications />,
    },
    {
        id: 'instructor_mgmt_business_data_instructor_freelancer_statuses',
        parent: 'instructor_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Freelancer Statuses',
        href: ADMIN_PATH.FREELANCER_STATUSES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminFreelancerStatuses />,
    },
    {
        id: 'instructor_mgmt_business_data_instructor_domain_skills',
        parent: 'instructor_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Domain Skills',
        href: ADMIN_PATH.DOMAIN_SKILLS,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminDomainSkills />,
    },
    {
        id: 'instructor_mgmt_business_data_instructor_delivery_languages',
        parent: 'instructor_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Delivery Languages',
        href: ADMIN_PATH.DELIVERY_LANGUAGES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminDeliveryLanguages />,
    },
    {
        id: 'schedule_mgmt_business_data_activity_audience_types',
        parent: 'schedule_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Activity Audience Types',
        href: ADMIN_PATH.ACTIVITY_AUDIENCES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminActivityAudienceTypes />,
    },
    {
        id: 'schedule_mgmt_business_data_activity_statuses',
        parent: 'schedule_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Activity Statuses',
        href: ADMIN_PATH.ACTIVITY_STATUSES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminActivityStatuses />,
    },
    {
        id: 'schedule_mgmt_business_data_activity_modalities',
        parent: 'schedule_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Activity Modalities',
        href: ADMIN_PATH.ACTIVITY_MODALITIES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminActivityModalities />,
    },
    {
        id: 'schedule_mgmt_business_data_activity_types',
        parent: 'schedule_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Activity Types',
        href: ADMIN_PATH.ACTIVITY_TYPES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminActivityTypes />,
    },
    {
        id: 'schedule_mgmt_business_data_partner_initiatives',
        parent: 'schedule_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Partner Initiative Types',
        href: ADMIN_PATH.PARTNER_INITIATIVES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminPartnerInitiatives />,
    },
    {
        id: 'schedule_mgmt_instructor_blockedtime_types',
        parent: 'schedule_mgmt_business_data',
        type: LinkTypes.Link,
        text: 'Instructor Blocked Time Types',
        href: ADMIN_PATH.INSTRUCTOR_BLOCKEDTIME_TYPES,
        requiredPermissionAction: Actions.SYSTEM_ADMINISTRATION,
        navType: NavType.SideNav,
        component: <AdminInstructorBlockedTimeTypes />,
    },
];

export const ACTIVITY_NAV_ITEMS: Array<NavItem> = [
    {
        id: 'activity_group_list',
        parent: 'activity_group_root',
        type: LinkTypes.Link,
        text: 'Activity Groups',
        href: ACTIVITY_PATH.GROUP_LIST,
        navType: NavType.SideNav,
        requiredPermissionAction: Actions.ACTIVITY_GROUP_VIEW,
        component: <ActivityGroupList />,
        featureName: 'aci_support',
    },
    {
        id: 'activities_root',
        parent: 'schedule_management_root',
        type: LinkTypes.Link,
        text: 'Schedule management',
        href: ACTIVITY_PATH.LIST,
        requiredPermissionAction: Actions.ACTIVITY_VIEW,
        navType: NavType.HeaderNav,
        component: <ActivityList />,
    },
    {
        id: 'activity_create',
        type: LinkTypes.Link,
        parent: 'activities_root',
        text: 'Add activity',
        href: ACTIVITY_PATH.CREATE,
        requiredPermissionAction: Actions.ACTIVITY_CREATE,
        component: <ActivityCreate />,
    },
    {
        id: 'activity_clone',
        type: LinkTypes.Link,
        parent: 'activities_root',
        text: 'Activity information',
        href: ACTIVITY_PATH.CLONE,
        requiredPermissionAction: Actions.ACTIVITY_CREATE,
        component: <ActivityClone />,
    },
    {
        id: 'activities_sidenav_activities_list',
        type: LinkTypes.Link,
        text: 'Activities',
        href: ACTIVITY_PATH.LIST,
        requiredPermissionAction: Actions.ACTIVITY_VIEW,
        navType: NavType.SideNav,
    },
    {
        id: 'activities_sidenav_clipper_invoice',
        type: LinkTypes.Link,
        text: 'Export invoice data',
        href: ACTIVITY_PATH.CLIPPER_EXPORT,
        parent: 'activities_root',
        requiredPermissionAction: Actions.EXPORT_INVOICE,
        navType: NavType.SideNav,
        component: <ExportInvoiceData />,
    },
    {
        id: 'activities_sidenav_instructors_list',
        type: LinkTypes.Link,
        text: 'Instructor schedule',
        href: ACTIVITY_PATH.INSTRUCTORS_LIST,
        requiredPermissionAction: Actions.ACTIVITY_VIEW,
        navType: NavType.SideNav,
        component: <SmtInstructorList />,
    },
    {
        id: 'activities_sidenav_sfdc_queue',
        type: LinkTypes.Link,
        parent: 'activities_root',
        text: 'SFDC queue',
        href: ACTIVITY_PATH.SFDC_QUEUE,
        requiredPermissionAction: Actions.ACTIVITY_CREATE,
        navType: NavType.SideNav,
        component: <SFDCQueue />,
    },
    {
        id: 'activities_sidenav_activity_import',
        type: LinkTypes.Link,
        parent: 'activities_root',
        text: 'Import activities',
        href: ACTIVITY_PATH.ACTIVITY_IMPORT,
        requiredPermissionAction: Actions.ACTIVITY_CREATE,
        component: <ImportActivity />,
    },
    {
        id: 'freelancer-schedule-import',
        type: LinkTypes.Link,
        parent: 'activities_root',
        text: 'Import freelancer schedue',
        href: ACTIVITY_PATH.FREELANCER_IMPORT,
        requiredPermissionAction: Actions.ACTIVITY_CREATE,
        component: <ImportFreelancer />,
    },
    {
        id: 'blocked-times-import',
        type: LinkTypes.Link,
        parent: 'activities_root',
        text: 'Import instructors blocked times',
        href: ACTIVITY_PATH.BLOCKED_TIMES_IMPORT,
        requiredPermissionAction: Actions.ACTIVITY_CREATE,
        component: <ImportBlockedTimes />,
    },
    {
        id: 'activities_sidenav_sfdc_opportunity',
        type: LinkTypes.Link,
        parent: 'activities_sidenav_sfdc_queue',
        text: 'Edit opportunity',
        href: ACTIVITY_PATH.SFSDC_OPPORTUNITY,
        requiredPermissionAction: Actions.ACTIVITY_CREATE,
        featureName: 'sfdcQueue',
        component: <OpportunityDetails />,
    },
    {
        id: 'activity_detail',
        type: LinkTypes.Link,
        parent: 'activities_root',
        text: 'Activity information',
        href: ACTIVITY_PATH.DETAIL,
        requiredPermissionAction: Actions.ACTIVITY_VIEW,
        component: <ActivityDetails />,
    },
    {
        id: 'activity_edit_delivery_details',
        parent: 'activity_detail',
        type: LinkTypes.Link,
        text: 'Edit delivery details',
        href: ACTIVITY_PATH.EDIT_DELIVERY_DETAILS,
        requiredPermissionAction: Actions.ACTIVITY_MODIFY,
        component: <EditDeliveryDetails />,
    },
    {
        id: 'activity_edit_base_details',
        parent: 'activity_detail',
        type: LinkTypes.Link,
        text: 'Edit activity details',
        href: ACTIVITY_PATH.EDIT_BASE_DETAILS,
        requiredPermissionAction: Actions.ACTIVITY_MODIFY,
        component: <EditBaseDetails />,
    },
    {
        id: 'activity_edit_operations_and_registration_details',
        parent: 'activity_detail',
        type: LinkTypes.Link,
        text: 'Edit details',
        href: ACTIVITY_PATH.EDIT_OPERATIONS_AND_REGISTRATION_DETAILS,
        requiredPermissionAction: Actions.ACTIVITY_MODIFY,
        component: <EditOperationsAndRegistrationDetails />,
    },
    {
        id: 'activity_create_invoice',
        parent: 'activity_detail',
        type: LinkTypes.Link,
        text: 'Edit invoice details',
        href: ACTIVITY_PATH.CREATE_INVOICE,
        requiredPermissionAction: Actions.ACTIVITY_MODIFY,
        component: <InvoiceCreateForm />,
    },
    {
        id: 'activity_edit_invoice',
        parent: 'activity_detail',
        type: LinkTypes.Link,
        text: 'Edit invoice details',
        href: ACTIVITY_PATH.EDIT_INVOICE,
        requiredPermissionAction: Actions.ACTIVITY_MODIFY,
        component: <InvoiceEditForm />,
    },
    {
        id: 'activity_create_customer',
        parent: 'activity_detail',
        type: LinkTypes.Link,
        text: 'Add customer',
        href: ACTIVITY_PATH.CREATE_CUSTOMER,
        requiredPermissionAction: Actions.ACTIVITY_MODIFY,
        component: <CustomerCreateForm />,
    },
    {
        id: 'activity_edit_customer',
        parent: 'activity_detail',
        type: LinkTypes.Link,
        text: 'Edit customer',
        href: ACTIVITY_PATH.EDIT_CUSTOMER,
        requiredPermissionAction: Actions.ACTIVITY_MODIFY,
        component: <CustomerEditForm />,
    },
    {
        id: 'activity_create_apn_customer',
        parent: 'activity_detail',
        type: LinkTypes.Link,
        text: 'Add APN customer',
        href: ACTIVITY_PATH.CREATE_APN_CUSTOMER,
        requiredPermissionAction: Actions.ACTIVITY_MODIFY,
        component: <APNCustomerCreateForm />,
    },
    {
        id: 'activity_edit_apn_customer',
        parent: 'activity_detail',
        type: LinkTypes.Link,
        text: 'Edit APN customer',
        href: ACTIVITY_PATH.EDIT_APN_CUSTOMER,
        requiredPermissionAction: Actions.ACTIVITY_MODIFY,
        component: <APNCustomerEditForm />,
    },
    {
        id: 'activity_group_create',
        parent: 'activity_group_root',
        type: LinkTypes.Link,
        text: 'Create Activity Group',
        href: ACTIVITY_PATH.CREATE_GROUP,
        requiredPermissionAction: Actions.ACTIVITY_GROUP_CREATE,
        component: <ActivityGroupCreate />,
        featureName: 'aci_support',
    },
    {
        id: 'activity_group_edit',
        parent: 'activity_group_root',
        type: LinkTypes.Link,
        text: 'Edit Activity Group',
        href: ACTIVITY_PATH.EDIT_GROUP,
        requiredPermissionAction: Actions.ACTIVITY_GROUP_EDIT,
        component: <ActivityGroupEdit />,
        featureName: 'aci_support',
    },
    {
        id: 'activity_group_view',
        parent: 'activity_group_root',
        type: LinkTypes.Link,
        text: 'Activity Group Details',
        href: ACTIVITY_PATH.GROUP_VIEW,
        requiredPermissionAction: Actions.ACTIVITY_GROUP_VIEW,
        component: <ActivityGroupView />,
        featureName: 'aci_support',
    },
];

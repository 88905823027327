/**
 * Method to convert key value pairs into a query string
 * @param {Object} params - key value pairs to be converted into a query string
 * @param {Object} options - options to configure the behavior of the function
 * @param {boolean} options.handleArraysAsSeparateParams - whether to handle array values as separate query parameters (default: false)
 * @return: A query string with URI encode values
 */
const createQueryString = (
    params: { [key: string]: any },
    options: { handleArraysAsSeparateParams?: boolean } = {},
) => {
    const { handleArraysAsSeparateParams = false } = options;
    const encodeURIComponentForObject = (val: any) =>
        encodeURIComponent(JSON.stringify(val));

    return `?${Object.entries(params)
        .reduce((acc: Array<string>, [key, val]) => {
            if (val !== null) {
                if (Array.isArray(val) && handleArraysAsSeparateParams) {
                    const uniqueValues = Array.from(new Set(val));
                    uniqueValues.forEach((item) => {
                        acc.push(`${key}=${encodeURIComponent(item)}`);
                    });
                } else {
                    acc.push(
                        key +
                            '=' +
                            (typeof val === 'object'
                                ? encodeURIComponentForObject(val)
                                : encodeURIComponent(val)),
                    );
                }
            }

            return acc;
        }, [])
        .join('&')}`;
};

export default createQueryString;

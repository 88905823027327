import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { Cache } from '@aws-amplify/cache';

const DOMAINS = {
    login: 'login.us-east-1.auth.skillbuilder.aws',
    logout: 'us-east-1.auth.skillbuilder.aws',
    issuer: 'gandalf-prod.auth.us-east-1.amazoncognito.com',
};

export const getStageName = () => {
    return window.__CONFIGURATION__.STAGE.toUpperCase();
};

// See docs, https://aws-amplify.github.io/amplify-js/api/interfaces/awscognitooauthopts.html
export const createUrlInterceptor =
    ({
        issuer,
        login,
        logout,
    }: {
        issuer: string;
        login?: string;
        logout?: string;
    }) =>
    (url: string) => {
        const SELF = '_self';
        const loginRequestUrl = `https://${issuer}/oauth2/authorize`;
        const logoutRequestUrl = `https://${issuer}/logout`;
        let windowProxy;

        if (login && url.startsWith(loginRequestUrl)) {
            const signInUrl = url.replace(
                loginRequestUrl,
                `https://${login}/login`,
            );
            windowProxy = window.open(signInUrl, SELF);
        } else if (logout && url.startsWith(logoutRequestUrl)) {
            const signOutUrl = url.replace(
                logoutRequestUrl,
                `https://${logout}/logout`,
            );
            windowProxy = window.open(signOutUrl, SELF);
        } else {
            windowProxy = window.open(url, SELF);
        }
        return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
    };

/**
 * Get configuration settings
 */
export const environment = async () => {
    if (window.__CONFIGURATION__) {
        return window.__CONFIGURATION__;
    }
    const url = '/settings.json';
    const response = await fetch(url, { method: 'GET' });
    const result = await response.json();
    window.__CONFIGURATION__ = result;
    return window.__CONFIGURATION__;
};

export const configureAmplify = async () => {
    try {
        const result = await environment();
        const {
            USER_POOL_REGION,
            APP_CLIENT_ID,
            USER_POOL_ID,
            IDENTITY_POOL_ID,
        } = result;

        const AMPLIFY_CONFIG = {
            Auth: {
                // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
                identityPoolId: IDENTITY_POOL_ID,

                // REQUIRED - Amazon Cognito Region
                region: USER_POOL_REGION,

                // OPTIONAL - Amazon Cognito User Pool ID
                userPoolId: USER_POOL_ID,

                // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                userPoolWebClientId: APP_CLIENT_ID,

                provider: 'COGNITO',

                // OPTIONAL - Hosted UI configuration
                oauth: {
                    domain: DOMAINS.issuer,
                    scope: ['openid'],
                    redirectSignIn: `${window.location.origin}/signin`,
                    redirectSignOut: `${window.location.origin}/signout`,
                    responseType: 'code',
                    codeChallengeMethod: 'S256',
                    codeChallenge: encodeURI(btoa(Math.random().toString())),
                    provider: 'COGNITO',
                    urlOpener: createUrlInterceptor(DOMAINS),
                },
            },
        };
        Amplify.configure(AMPLIFY_CONFIG);
        return AMPLIFY_CONFIG;
    } catch (err) {
        throw new Error('Failed to fetch configuration, please reload page!!');
    }
};

/**
 * Checks if there is a currrent authenticated user.
 * Due to Amplify expecting OIDC to be set by authenticating with identity pools we must
 * manage federatedInfo here. Auth.currentAuthenticatedUser returns a valid jwtToken and if different
 * from Cache federatedInfo, update the cache with the valid token
 */
export const hasValidToken = async () => {
    await configureAmplify();
    try {
        const cognitoIdToken = (
            await Auth.currentAuthenticatedUser({ bypassCache: true })
        )
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken();

        const currentToken = Cache.getItem('federatedInfo').token;

        if (currentToken !== cognitoIdToken) {
            Cache.setItem('federatedInfo', { token: cognitoIdToken });
        }
    } catch (e) {
        return false;
    }
    return true;
};

export const getCurrentUser = async () => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });

        return currentUser;
    } catch (e) {
        return false;
    }
};

const rememberPath = () => {
    const path = window.location.pathname;
    if (path !== '/signout') {
        Cache.setItem(
            'grimsby_redirect_pathname',
            `${window.location.pathname}${window.location.search}`,
        );
    }
};

export const redirectToLoginPage = async () => {
    rememberPath();
    await configureAmplify();
    Auth.federatedSignIn();
};

export const redirectToGandalfEmailVerification = async () => {
    rememberPath();
    const { Auth } = await configureAmplify();
    const { userPoolWebClientId } = Auth;
    const {
        oauth: {
            redirectSignIn,
            responseType,
            provider,
            scope,
            codeChallenge,
            codeChallengeMethod,
        },
    } = Auth;
    const params = [
        `redirect_uri=${redirectSignIn}`,
        `response_type=${responseType}`,
        `client_id=${userPoolWebClientId}`,
        `identity_provider=${provider}`,
        `scope=${scope}`,
        `code_challenge=${codeChallenge}`,
        `code_challenge_method=${codeChallengeMethod}`,
    ].join('&');
    window.location.replace(
        `https://${DOMAINS.login}/login?require_email_verification=true&${params}`,
    );
};

export const getCurrentCredentials = () => Auth.currentCredentials();

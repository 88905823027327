import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getProgramTypesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllProgramTypes,
    selectError,
    updateProgramType,
    selectSelectedProgramType,
    setSelectedProgramType,
    addProgramType,
    selectCount,
    resetProgramTypesSlice,
} from '../../../common/store/slices/programTypesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { ProgramTypeItemData } from '../../../common/interfaces/businessDataItem/programTypeItem';

const PROGRAM_TYPES_TABLE_TITLE = 'Program types';
const PROGRAM_TYPES_DISPLAY_SINGULAR = 'Program type';
export const PROGRAM_TYPES_NAME_KEY = 'program_type';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (programType: ProgramTypeItemData) =>
            formatStatus(programType.active.toString()),
    },
];

/**
 * Editable form fields for ProgramType
 * The keys must map to ProgramTypeItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<ProgramTypeItemData> =
    {
        // the keys must match ProgramDataType properties
        program_type: {
            type: AdminBusinessDataFormInputType.Text,
            label: `${PROGRAM_TYPES_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (val: string): Partial<ProgramTypeItemData> => {
                return { program_type: val };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<ProgramTypeItemData> => {
                return { active: parseBoolean(val) };
            },
        },
        catalog_group_ids: {
            type: AdminBusinessDataFormInputType.Text,
            label: 'Catalog Group IDs (comma separated)',
            required: false,
            disabled: false,
            defaultValue: '',
            formDataTransform: (val: string): Partial<ProgramTypeItemData> => {
                return { catalog_group_ids: val ? val.split(',') : [] };
            },
        },
    };

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<ProgramTypeItemData> =
    {
        ...createFormSchema,
        program_type: {
            ...createFormSchema.program_type,
            disabled: true,
        },
    };

const AdminProgramTypes = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useProgramTypes(): AdminBusinessDataSelectors<ProgramTypeItemData> {
        const dispatch = useDispatch();

        const items: ProgramTypeItemData[] = useSelector(selectAllProgramTypes);
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: ProgramTypeItemData | null = useSelector(
            selectSelectedProgramType,
        );
        useEffect(() => {
            dispatch(getProgramTypesList());
            dispatch(setSelectedProgramType(null));

            return () => {
                dispatch(resetProgramTypesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (programType: ProgramTypeItemData) => {
        if (programType) {
            dispatch(setSelectedProgramType(programType.program_type));
            await dispatch(getProgramTypesList());
        }
    };

    const handleItemUpdate = async (data: ProgramTypeItemData) => {
        dispatch(setSelectedProgramType(null));
        const dispatchPromise = dispatch<any>(updateProgramType(data));
        await handleBusinessDataNotification({
            businessDataType: PROGRAM_TYPES_NAME_KEY,
            businessDataDisplayNameLower:
                PROGRAM_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.program_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        program_type,
        active,
        catalog_group_ids,
    }: ProgramTypeItemData) => {
        const dispatchPromise = dispatch<any>(
            addProgramType(active, program_type, catalog_group_ids),
        );
        await handleBusinessDataNotification({
            businessDataType: PROGRAM_TYPES_NAME_KEY,
            businessDataDisplayNameLower:
                PROGRAM_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: program_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementProgramTypes">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={PROGRAM_TYPES_DISPLAY_SINGULAR}
                itemNameKey={PROGRAM_TYPES_NAME_KEY}
                title={PROGRAM_TYPES_TABLE_TITLE}
                useItemList={useProgramTypes}
            />
        </div>
    );
};

export default AdminProgramTypes;

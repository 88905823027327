import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Button,
    ColumnLayout,
    Container,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import formatDate, {
    decodeDateNumber,
} from '../../../../common/utils/formatDate';
import { selectFeatures } from '../../../../common/store/slices/featureSlice';
import Can from '../../../../common/components/Can';
import { Actions } from '../../../../common/constants/auth';
import { getUserRoles } from '../../../../common/utils/auth';
import { CourseItemData } from '../../../../common/interfaces/businessDataItem/courseItem';
import { formatStatus } from '../../AdminBusinessData/AdminBusinessData.Status';
import AdminCourseMilestonesList from './AdminCourseMilestonesList';

/**
 * Renders Course details
 */
export const CourseInfo = ({
    selectedCourse,
}: {
    selectedCourse: CourseItemData;
}) => {
    const {
        active,
        course,
        course_days,
        created_by,
        created_timestamp,
        modified_by,
        modified_timestamp,
        short_name,
        sku_id,
        course_domain,
        course_category,
        associated_programs,
        associated_certifications,
        expiry_date,
        course_modality,
        course_skill_level,
        course_content,
        locales,
    } = selectedCourse;

    const history = useHistory();
    return (
        <section>
            <Container
                variant="default"
                header={
                    <Header
                        actions={
                            <Can
                                roles={getUserRoles()}
                                perform={Actions.SYSTEM_ADMINISTRATION}
                                yes={() => (
                                    <Button
                                        className="admin-course-edit"
                                        data-testid="AdminCoursesEdit"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/admin/business-data/courses/${selectedCourse.pk}/edit`,
                                            });
                                        }}
                                    >
                                        Edit
                                    </Button>
                                )}
                                no={() => undefined}
                            />
                        }
                    >
                        Course Information
                    </Header>
                }
            >
                <ColumnLayout columns={4} variant="text-grid">
                    <div>
                        <SpaceBetween size="s" direction="vertical">
                            <div>
                                <div className="awsui-util-label">Course</div>
                                <div>{course}</div>
                            </div>
                            <div>
                                <div className="awsui-util-label">
                                    Short Name
                                </div>
                                <div>{short_name || '-'}</div>
                            </div>
                            <div>
                                <div className="awsui-util-label">Status</div>
                                <div>{formatStatus(active.toString())}</div>
                            </div>
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween size="s" direction="vertical">
                            <div>
                                <div className="awsui-util-label">SKU</div>
                                <div>{sku_id ? sku_id : '-'}</div>
                            </div>
                            <div>
                                <div className="awsui-util-label">
                                    Duration (Days)
                                </div>
                                <div>{course_days}</div>
                            </div>
                            <div>
                                <div className="awsui-util-label">
                                    End of life
                                </div>
                                <div>{expiry_date ? expiry_date : '-'}</div>
                            </div>
                            <div>
                                <div className="awsui-util-label">
                                    Course Content
                                </div>
                                <div>
                                    {course_content && course_content.length > 0
                                        ? course_content.join(', ')
                                        : '-'}
                                </div>
                            </div>
                            <div>
                                <div className="awsui-util-label">
                                    Course Locale
                                </div>
                                <div>
                                    {locales && locales.length > 0
                                        ? locales.join(', ')
                                        : '-'}
                                </div>
                            </div>
                        </SpaceBetween>
                    </div>

                    <div>
                        <SpaceBetween size="s" direction="vertical">
                            <div>
                                <div className="awsui-util-label">
                                    Course Domain
                                </div>
                                <div>{course_domain ? course_domain : '-'}</div>
                            </div>

                            <div>
                                <div className="awsui-util-label">
                                    Course Modality
                                </div>
                                <div>
                                    {course_modality ? course_modality : 'ILT'}
                                </div>
                            </div>

                            <div>
                                <div className="awsui-util-label">
                                    Associated Programs
                                </div>
                                <div>
                                    {associated_programs.length > 0
                                        ? associated_programs.join(', ')
                                        : '-'}
                                </div>
                            </div>
                            <div>
                                <div className="awsui-util-label">
                                    Created By
                                </div>
                                <div>
                                    {created_by} (
                                    {formatDate(
                                        decodeDateNumber(created_timestamp),
                                    )}
                                    )
                                </div>
                            </div>
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween size="s" direction="vertical">
                            <div>
                                <div className="awsui-util-label">
                                    Course Category
                                </div>
                                <div>
                                    {course_category ? course_category : '-'}
                                </div>
                            </div>
                            <div>
                                <div className="awsui-util-label">
                                    Course Skill Level
                                </div>
                                <div>
                                    {course_skill_level
                                        ? course_skill_level
                                        : '-'}
                                </div>
                            </div>
                            <div>
                                <div className="awsui-util-label">
                                    Associated Certificates
                                </div>
                                <div>
                                    {associated_certifications.length > 0
                                        ? associated_certifications.join(', ')
                                        : '-'}
                                </div>
                            </div>
                            <div>
                                <div className="awsui-util-label">
                                    Modified By
                                </div>
                                <div>
                                    {modified_by} (
                                    {formatDate(
                                        decodeDateNumber(modified_timestamp),
                                    )}
                                    )
                                </div>
                            </div>
                        </SpaceBetween>
                    </div>
                </ColumnLayout>
            </Container>
            <AdminCourseMilestonesList selectedCourse={selectedCourse} />
        </section>
    );
};
export default CourseInfo;

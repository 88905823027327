import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryCountriesResponseData } from '../../interfaces/businessDataResponse/deliveryCountriesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { DeliveryCountryItemData } from '../../interfaces/businessDataItem/deliveryCountryItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * deliveryCountriesSlice manages all deliveryCountries state, and contains deliveryCountries actions as well as deliveryCountries state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const deliveryCountriesSlice = createSlice({
    name: 'deliveryCountries',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<DeliveryCountryItemData>,
    reducers: {
        setDeliveryCountriesList: (
            state,
            action: PayloadAction<DeliveryCountryItemData[]>,
        ) => {
            const byDeliveryCountryName = action.payload.reduce(
                (
                    byDeliveryCountryName: {
                        [key: string]: DeliveryCountryItemData;
                    },
                    deliveryCountry: DeliveryCountryItemData,
                ) => {
                    byDeliveryCountryName[deliveryCountry.delivery_country] = {
                        ...deliveryCountry,
                        active: parseBoolean(deliveryCountry.active),
                    };
                    return byDeliveryCountryName;
                },
                {},
            );
            state.entities = byDeliveryCountryName;
            state.keysList = Object.keys(byDeliveryCountryName);
        },
        setDeliveryCountry: (
            state,
            action: PayloadAction<DeliveryCountryItemData>,
        ) => {
            // this reducer may be used when adding a new program or updating an existing one.
            // only add to keysList and update count if adding a new program
            if (!state.entities[action.payload.delivery_country]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.delivery_country,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.delivery_country] = action.payload;
        },
        setSelectedDeliveryCountry: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        resetPartialDeliveryCountriesSlice: resetBasicBusinessDataStoreState,
        resetDeliveryCountriesSlice: resetExtendedBusinessDataStoreState,
        initializeDeliveryCountriesListQueryParams:
            initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setDeliveryCountriesList,
    setDeliveryCountry,
    setSelectedDeliveryCountry,
    setError,
    setCount,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    setSearchText,
    setActive,
    resetPartialDeliveryCountriesSlice,
    resetDeliveryCountriesSlice,
    initializeDeliveryCountriesListQueryParams,
} = deliveryCountriesSlice.actions;

const handleDeliveryCountriesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: delivery_country,
            active,
        } = state.deliveryCountries;

        const params: BusinessDataApiQueryParams.GetDeliveryCountries = {
            active,
        };

        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (delivery_country) {
            params.delivery_country = delivery_country;
        }

        try {
            const {
                result: { DELIVERY_COUNTRY, total_count },
            }: HandleRequestData<DeliveryCountriesResponseData> =
                await businessDataApi.getDeliveryCountries(params);
            dispatch(setDeliveryCountriesList(DELIVERY_COUNTRY));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getDeliveryCountries API error'),
            );
        }
    };
};

export const getDeliveryCountriesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleDeliveryCountriesListRequest());
        if (!state.deliveryCountries.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update program
 * before update api call, fetch program types and prepare payload containing only the changed fields
 * after update api call, fetch program types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateDeliveryCountry = (data: DeliveryCountryItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new program: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateDeliveryCountry(data.pk, data.active);
            await dispatch(handleDeliveryCountriesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setDeliveryCountry({
                    ...state.deliveryCountries.entities[data.delivery_country],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addDeliveryCountry = (
    active = true,
    deliveryCountryName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addDeliveryCountry(
                deliveryCountryName,
                active,
            );
            // now refresh the keysList
            await dispatch(handleDeliveryCountriesListRequest());
            if (!state.deliveryCountries?.entities[deliveryCountryName]) {
                // shove the item into the keysList, but only if it's not there already
                const newProgramData: DeliveryCountryItemData = {
                    delivery_country: deliveryCountryName,
                    active: active,
                };
                dispatch(setDeliveryCountry(newProgramData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllDeliveryCountries = (state: GlobalState) => {
    return state.deliveryCountries.keysList.map(
        (deliveryCountryName) =>
            state.deliveryCountries.entities[deliveryCountryName],
    );
};

export const selectAllActiveDeliveryCountries = (
    state: GlobalState,
): Array<DeliveryCountryItemData> => {
    return getActiveBusinessDataValues(
        state.deliveryCountries.keysList,
        state.deliveryCountries.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.deliveryCountries.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.deliveryCountries.isLoaded;

export const selectSelectedDeliveryCountry = (state: GlobalState) => {
    return state?.deliveryCountries?.selectedItemKey
        ? state?.deliveryCountries?.entities[
              state?.deliveryCountries?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) =>
    state.deliveryCountries.error;

export const selectCount = (state: GlobalState) =>
    state.deliveryCountries.count;

export const selectSearchText = (state: GlobalState) =>
    state.deliveryCountries.searchText;

export default deliveryCountriesSlice.reducer;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Can from '../../../common/components/Can';
import { getUserRoles } from '../../../common/utils/auth';
import AlertPage from '../../../common/components/AlertPage';
import BreadcrumbsWrapper from '../../../common/components/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { ChildRouteProps } from '../../../common/interfaces/navigation';

const AdminRoutes = ({ routes }: ChildRouteProps) => {
    return (
        <Switch>
            {routes &&
                routes.map((route) => {
                    if (route.redirect && route.redirectTo) {
                        return (
                            <Route exact path={route.href} key={route.id}>
                                <Can
                                    roles={getUserRoles()}
                                    perform={route.requiredPermissionAction}
                                    yes={() => (
                                        <BreadcrumbsWrapper>
                                            <Redirect
                                                to={`${route.redirectTo}`}
                                            />
                                        </BreadcrumbsWrapper>
                                    )}
                                    no={() => <Redirect to="/error" />}
                                />
                            </Route>
                        );
                    }

                    return (
                        <Route exact path={`${route.href}`} key={route.id}>
                            <Can
                                roles={getUserRoles()}
                                perform={route.requiredPermissionAction}
                                yes={() => (
                                    <BreadcrumbsWrapper>
                                        {route.component}
                                    </BreadcrumbsWrapper>
                                )}
                                no={() => <Redirect to="/error" />}
                            />
                        </Route>
                    );
                })}
            <Route>
                <AlertPage />
            </Route>
        </Switch>
    );
};

export default AdminRoutes;

import React, { useState } from 'react';
import Section, { GenericContentData, SectionProps } from '../Section/Section';
import { SectionContentType } from '../../constants/grimsby';
import Can from '../Can';
import { getUserRoles } from '../../utils/auth';
import { Button } from '@amzn/awsui-components-react';
import { NotesProps } from '../../interfaces/notesProps';
import NotesForm from './NotesForm';
import './Notes.scss';

const Notes = ({
    action,
    testId,
    label,
    initialValue,
    emptyNotesValue,
    getNotesClickHandler,
    footerProps,
    enableEdit = true,
}: NotesProps) => {
    const [editing, setEditing] = useState(false);

    const notesFormProps = {
        testId,
        initialValue: initialValue ?? '',
        setEditing,
        getNotesClickHandler,
    };

    const paragraphContent: GenericContentData = {
        type: SectionContentType.Generic,
        children: (
            <>
                {initialValue ? (
                    <div>
                        {initialValue
                            .trim()
                            .split('\n')
                            .map((str, idx) => (
                                <p
                                    key={idx}
                                    className="awsui-util-pv-n grimsby-note-min-height"
                                >
                                    {str || ' '}
                                </p>
                            ))}
                    </div>
                ) : (
                    <p className={'awsui-util-status-inactive'}>
                        {emptyNotesValue}
                    </p>
                )}
                {!!(footerProps?.value && footerProps?.key) && (
                    <div data-testid="NotesFooter">
                        <div className="awsui-util-label">
                            {footerProps.key}
                        </div>
                        <div>{footerProps.value}</div>
                    </div>
                )}
            </>
        ),
    };

    const formContent: GenericContentData = {
        type: SectionContentType.Generic,
        children: <NotesForm {...notesFormProps} />,
    };

    const notesProps: SectionProps = {
        testId: 'NotesSection',
        header: {
            label,
            buttons: enableEdit ? (
                <div className="awsui-util-action-stripe-group">
                    <Can
                        roles={getUserRoles()}
                        perform={action}
                        yes={() =>
                            !editing && (
                                <div className="awsui-util-action-stripe-group">
                                    <Button
                                        data-testid={`${testId}NotesComponentEditButton`}
                                        onClick={() => {
                                            setEditing(true);
                                        }}
                                    >
                                        Edit Notes
                                    </Button>
                                </div>
                            )
                        }
                        no={() => null}
                    />
                </div>
            ) : null,
        },
        content: editing ? formContent : paragraphContent,
    };

    return (
        <div data-testid="NotesComponent">
            <Section {...notesProps} />
        </div>
    );
};

export default Notes;

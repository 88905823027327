import {
    Box,
    ColumnLayout,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { formatString } from '../../../../../common/utils/formatString';
import {
    BlockedTime,
    BlockedTimeTimestamp,
} from '../../../../../common/interfaces/blockedTime';
import { selectInstructorLookup } from '../../../../store/slices/instructorListSlice';
import { useSelector } from 'react-redux';
import { RecommendedInstructorProfileData } from '../../../../interfaces/recommendedInstructorProfile';
import FullCalendar from '@fullcalendar/react';
import { formatUnixTimeInTimezone } from '../../../../../common/utils/date-time.utils';
import { mapTimestampsToCalendarView } from '../../../../services/calendar-service';
import { useEffect, useState } from 'react';

const FreelancerDateString = ({
    orderedTimestamps,
    instructor,
}: {
    orderedTimestamps: BlockedTimeTimestamp[];
    instructor: RecommendedInstructorProfileData;
}) => {
    const startTime = orderedTimestamps[0].start_timestamp;
    const endTime =
        orderedTimestamps[orderedTimestamps.length - 1].end_timestamp;

    const starting_timestamp = formatUnixTimeInTimezone({
        timestamp: startTime,
        timezone: instructor.city_timezone,
        format: 'MMMM D, YYYY',
    });
    const ending_timestamp = formatUnixTimeInTimezone({
        timestamp: endTime,
        timezone: instructor.city_timezone,
        format: 'MMMM D, YYYY',
    });

    return (
        <Box variant="p" data-testid="freelancer-date-string">
            {starting_timestamp}
            {starting_timestamp !== ending_timestamp && (
                <>
                    {` - `}
                    {ending_timestamp}
                </>
            )}
        </Box>
    );
};

const InstructorDateString = ({
    timestamp,
    instructor,
}: {
    timestamp: BlockedTimeTimestamp;
    instructor: RecommendedInstructorProfileData;
}) => {
    const startTime = timestamp.start_timestamp;
    const endTime = timestamp.end_timestamp;

    const starting_timestamp = formatUnixTimeInTimezone({
        timestamp: startTime,
        timezone: instructor.city_timezone,
        format: 'MMMM D, YYYY',
    });
    const ending_timestamp = formatUnixTimeInTimezone({
        timestamp: endTime,
        timezone: instructor.city_timezone,
        format: 'MMMM D, YYYY',
    });
    const startTimeString =
        starting_timestamp === ending_timestamp
            ? formatUnixTimeInTimezone({
                  timestamp: startTime,
                  timezone: instructor.city_timezone,
                  format: 'MMMM D, YYYY h:mm a',
              })
            : formatUnixTimeInTimezone({
                  timestamp: startTime,
                  timezone: instructor.city_timezone,
                  format: 'MMMM D, YYYY',
              });
    const endTimeString =
        starting_timestamp === ending_timestamp
            ? formatUnixTimeInTimezone({
                  timestamp: endTime,
                  timezone: instructor.city_timezone,
                  format: 'h:mm a',
              })
            : formatUnixTimeInTimezone({
                  timestamp: endTime,
                  timezone: instructor.city_timezone,
                  format: 'MMMM D, YYYY',
              });
    return (
        <Box variant="p" data-testid="instructor-date-string">
            {startTimeString}
            {` - `}
            {endTimeString}
        </Box>
    );
};

export interface BlockTimeDatesProps {
    timestamp: BlockedTimeTimestamp;
    instructor: RecommendedInstructorProfileData;
}

interface BlockedTimePopoverTemplateProps {
    blockedTime: BlockedTime;
    calendarRef: FullCalendar | undefined;
}

const BlockedTimePopoverTemplate = ({
    blockedTime,
    calendarRef,
}: BlockedTimePopoverTemplateProps) => {
    const instructorLookup = useSelector(selectInstructorLookup);
    const [instructor, setInstructor] =
        useState<RecommendedInstructorProfileData>();
    const [orderedTimestamps, setOrderedTimestamps] =
        useState<BlockedTimeTimestamp[]>();

    useEffect(() => {
        if (blockedTime && blockedTime.instructor_pk) {
            const instructor = instructorLookup[blockedTime.instructor_pk];
            setInstructor(instructor);
        }
    }, [blockedTime]);

    useEffect(() => {
        if (blockedTime && blockedTime.blocked_time_timestamps.length) {
            const timestamps = blockedTime.blocked_time_timestamps;
            let orderedTimestamps = [] as BlockedTimeTimestamp[];
            if (instructor?.is_freelancer) {
                orderedTimestamps = mapTimestampsToCalendarView({
                    calendarRef,
                    timestamps,
                    city_timezone: instructor.city_timezone,
                });
            } else {
                orderedTimestamps = [...timestamps].sort(
                    (x, y) => x.start_timestamp - y.start_timestamp,
                );
            }
            setOrderedTimestamps(orderedTimestamps);
        }
    }, [blockedTime, instructor]);

    if (!instructor || !blockedTime) {
        return <></>;
    }

    return (
        <ColumnLayout columns={1} variant="text-grid">
            <SpaceBetween size="l">
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Instructor name
                    </Box>
                    {instructor?.full_name && (
                        <div>{formatString(instructor.full_name)}</div>
                    )}
                </div>
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Block time name
                    </Box>
                    <div>{formatString(blockedTime.blocked_time_name)}</div>
                </div>
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Block time dates
                    </Box>
                    {instructor?.is_freelancer &&
                        orderedTimestamps.length > 0 && (
                            <FreelancerDateString
                                {...{ orderedTimestamps, instructor }}
                            />
                        )}
                    {!instructor.is_freelancer &&
                        orderedTimestamps.map((timestamp, i) => {
                            return (
                                <InstructorDateString
                                    {...{ timestamp, instructor, key: i }}
                                />
                            );
                        })}
                </div>
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Timezone
                    </Box>
                    <Box variant="p">
                        {instructor && !!instructor.city_timezone
                            ? instructor.city_timezone
                            : ''}
                    </Box>
                </div>
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Block notes
                    </Box>
                    <Box variant="p">
                        {!!blockedTime.blocked_time_notes
                            ? blockedTime.blocked_time_notes
                            : ''}
                    </Box>
                </div>
            </SpaceBetween>
        </ColumnLayout>
    );
};

export default BlockedTimePopoverTemplate;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InstructorStatusesResponseData } from '../../interfaces/businessDataResponse/instructorStatusesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { InstructorStatusItemData } from '../../interfaces/businessDataItem/instructorStatusItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * instructorStatusesSlice manages all instructorStatuses state, and contains instructorStatuses actions as well as instructorStatuses state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const instructorStatusesSlice = createSlice({
    name: 'instructorStatuses',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<InstructorStatusItemData>,
    reducers: {
        setInstructorStatusesList: (
            state,
            action: PayloadAction<InstructorStatusItemData[]>,
        ) => {
            const byInstructorStatusName = action.payload.reduce(
                (
                    byInstructorStatusName: {
                        [key: string]: InstructorStatusItemData;
                    },
                    instructorStatus: InstructorStatusItemData,
                ) => {
                    byInstructorStatusName[instructorStatus.instructor_status] =
                        {
                            ...instructorStatus,
                            active: parseBoolean(instructorStatus.active),
                        };
                    return byInstructorStatusName;
                },
                {},
            );
            state.entities = byInstructorStatusName;
            state.keysList = Object.keys(byInstructorStatusName);
        },
        setInstructorStatus: (
            state,
            action: PayloadAction<InstructorStatusItemData>,
        ) => {
            // this reducer may be used when adding a new instructor or updating an existing one.
            // only add to keysList and update count if adding a new instructor
            if (!state.entities[action.payload.instructor_status]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.instructor_status,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.instructor_status] = action.payload;
        },
        setSelectedInstructorStatus: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetInstructorStatusesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setInstructorStatusesList,
    setInstructorStatus,
    setSelectedInstructorStatus,
    setError,
    setCount,
    resetInstructorStatusesSlice,
} = instructorStatusesSlice.actions;

const handleInstructorStatusesListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { INSTRUCTOR_STATUS, total_count },
            }: HandleRequestData<InstructorStatusesResponseData> =
                await businessDataApi.getInstructorStatuses({ active: 'all' });
            dispatch(setInstructorStatusesList(INSTRUCTOR_STATUS));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getInstructorStatuses API error'),
            );
        }
    };
};

export const getInstructorStatusesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleInstructorStatusesListRequest());
        if (!state.instructorStatuses.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update instructor
 * before update api call, fetch instructor statuses and prepare payload containing only the changed fields
 * after update api call, fetch instructor statuses and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateInstructorStatus = (data: InstructorStatusItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new instructor: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateInstructorStatus(data.pk, data.active);
            await dispatch(handleInstructorStatusesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setInstructorStatus({
                    ...state.instructorStatuses.entities[
                        data.instructor_status
                    ],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addInstructorStatus = (
    active = true,
    instructorStatusName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addInstructorStatus(
                instructorStatusName,
                active,
            );
            // now refresh the keysList
            await dispatch(handleInstructorStatusesListRequest());
            if (!state.instructorStatuses?.entities[instructorStatusName]) {
                // shove the item into the keysList, but only if it's not there already
                const newInstructorData: InstructorStatusItemData = {
                    instructor_status: instructorStatusName,
                    active: active,
                };
                dispatch(setInstructorStatus(newInstructorData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllInstructorStatuses = (state: GlobalState) => {
    return state.instructorStatuses.keysList.map(
        (instructorStatusName) =>
            state.instructorStatuses.entities[instructorStatusName],
    );
};
export const selectAllActiveInstructorStatuses = (
    state: GlobalState,
): Array<InstructorStatusItemData> => {
    return getActiveBusinessDataValues(
        state.instructorStatuses.keysList,
        state.instructorStatuses.entities,
    );
};
export const selectIsLoading = (state: GlobalState) =>
    state.instructorStatuses.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.instructorStatuses.isLoaded;

export const selectSelectedInstructorStatus = (state: GlobalState) => {
    return state?.instructorStatuses?.selectedItemKey
        ? state?.instructorStatuses?.entities[
              state?.instructorStatuses?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) =>
    state.instructorStatuses.error;

export const selectCount = (state: GlobalState) =>
    state.instructorStatuses.count;

export default instructorStatusesSlice.reducer;

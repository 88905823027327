import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Alert, Button, Modal, Spinner } from '@amzn/awsui-components-react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Can from '../../../../common/components/Can';
import UserInfo from '../../../../common/components/User/UserInfo';
import {
    adminGetUserById,
    adminUpdateUser,
    selectError,
    selectIsLoading,
    selectSelectedUser,
    selectShowDeactivateModal,
    setShowDeactivateModal,
} from '../../../store/slices/selectedUserSlice';
import { getUserRoles } from '../../../../common/utils/auth';
import { Actions } from '../../../../common/constants/auth';
import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import handleEditUserNotification from '../../../../common/utils/handleEditUserNotification';

const AdminUserManagementDetails = () => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const selectedUser = useSelector(selectSelectedUser);
    const showModal = useSelector(selectShowDeactivateModal);
    const match = useRouteMatch<{ id: string }>();
    const dispatch = useDispatch();
    const history = useHistory();
    const { addNotification } = useNotifications();

    useEffect(() => {
        if (!error && selectedUser?.user?.pk !== match.params.id) {
            dispatch(adminGetUserById(match.params.id));
        }
    }, [dispatch, match.params.id, error, selectedUser]);

    if (error) {
        return (
            <Alert
                data-testid="AdminUserManagementDetailsError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (!isLoading && selectedUser !== null) {
        return (
            <React.Fragment>
                <UserInfo
                    profile={selectedUser.user}
                    actions={
                        <Can
                            roles={getUserRoles()}
                            perform={Actions.SYSTEM_ADMINISTRATION}
                            yes={() => (
                                <React.Fragment>
                                    <Button
                                        className="admin-user-edit"
                                        data-testid="AdminUserEdit"
                                        onClick={() => {
                                            history.push({
                                                pathname: `/admin/user-management/users/${selectedUser.user.pk}/edit`,
                                            });
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        className="admin-user-toggle-active"
                                        data-testid="AdminUserToggleActive"
                                        onClick={async () => {
                                            if (selectedUser.user.active) {
                                                dispatch(
                                                    setShowDeactivateModal(
                                                        true,
                                                    ),
                                                );
                                            } else {
                                                const dispatchPromise =
                                                    dispatch<any>(
                                                        adminUpdateUser(
                                                            selectedUser.user
                                                                .pk,
                                                            {
                                                                active: true,
                                                            },
                                                        ),
                                                    );
                                                await handleEditUserNotification(
                                                    dispatchPromise,
                                                    addNotification,
                                                );
                                            }
                                        }}
                                    >
                                        {selectedUser.user.active
                                            ? 'Deactivate'
                                            : 'Activate'}
                                    </Button>
                                </React.Fragment>
                            )}
                            no={() => undefined}
                        />
                    }
                />
                <Modal
                    visible={showModal}
                    header="Deactivate user?"
                    onDismiss={() => dispatch(setShowDeactivateModal(false))}
                    footer={
                        <span className="awsui-util-f-r admin-modal-footer">
                            <Button
                                variant="link"
                                className="admin-user-modal-cancel"
                                data-testid="AdminUserModalCancel"
                                onClick={() =>
                                    dispatch(setShowDeactivateModal(false))
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-user-modal-deactivate"
                                data-testid="AdminUserModalDeactivate"
                                onClick={async () => {
                                    let dispatchPromise = dispatch<any>(
                                        adminUpdateUser(selectedUser.user.pk, {
                                            active: false,
                                        }),
                                    );
                                    await handleEditUserNotification(
                                        dispatchPromise,
                                        addNotification,
                                    );
                                    dispatch(setShowDeactivateModal(false));
                                }}
                            >
                                Deactivate
                            </Button>
                        </span>
                    }
                >
                    <Alert type="warning">
                        Are you sure you want to deactivate this user? The user
                        will not be able to sign in to Grimsby after
                        deactivating.
                    </Alert>
                </Modal>
            </React.Fragment>
        );
    } else {
        return <Spinner data-testid="AdminUserManagementDetailsSpinner" />;
    }
};

export default AdminUserManagementDetails;

import { Button } from '@amzn/awsui-components-react-v3';
import { getUserRoles } from '../../../../common/utils/auth';
import { ActivityData } from '../../../interfaces/activity';
import Can from '../../../../common/components/Can';
import { Actions } from '../../../../common/constants/auth';
import { isACI, showEditButton } from './Common';
import { useHistory } from 'react-router-dom';

const DisplayEditButton = ({
    activityStatus,
    testId,
    activityGroupName,
    path,
    isACIFeatureEnabled,
}: {
    activityStatus: string;
    testId: string;
    activityGroupName: string;
    path: string;
    isACIFeatureEnabled: boolean;
}) => {
    const history = useHistory();
    const userRoles = getUserRoles();
    const displayEditButton = showEditButton({
        activity_status: activityStatus,
    });

    const isAciActivity = isACI(activityGroupName, isACIFeatureEnabled);

    return displayEditButton && !isAciActivity ? (
        <Can
            roles={userRoles}
            perform={Actions.ACTIVITY_MODIFY}
            yes={() => (
                <Button
                    data-testid={testId}
                    onClick={() => {
                        history.push({
                            pathname: `/activities/${path}`,
                        });
                    }}
                >
                    Edit
                </Button>
            )}
            no={() => null}
        />
    ) : null;
};

export default DisplayEditButton;

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { SelectProps } from '@amzn/awsui-components-react-v3';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DATE_PICKER_VALUE_FORMAT = 'YYYY-MM-DD';
export const DATE_PICKER_DISPLAY_FORMAT = 'YYYY/MM/DD';
export const unixTimestampToDatePickerValue = (
    unixTime: number,
    timezone: string,
) => dayjs.unix(unixTime).tz(timezone).format(DATE_PICKER_VALUE_FORMAT);

export const valueToOption = (value: string): SelectProps.Option => ({
    value,
    label: value,
});
export const valuesToOptions = (values: string[]): SelectProps.Options =>
    values.map((value) => ({ value, label: value }));
export const getSelectedOptionValue = (stateValue: string) =>
    stateValue ? valueToOption(stateValue) : null;

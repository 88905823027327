import React, { useState } from 'react';
import {
    Header,
    Link,
    Table,
    TableProps,
} from '@amzn/awsui-components-react-v3';
import { useHistory } from 'react-router-dom';
import { formatStatus } from '../../AdminBusinessData/AdminBusinessData.Status';
import { CourseItemData } from '../../../../common/interfaces/businessDataItem/courseItem';
import { MilestoneItemData } from '../../../../common/interfaces/businessDataItem/milestoneItem';

function formatResource(milestone: MilestoneItemData) {
    switch (milestone.milestone_type) {
        case 'Digital':
            return (
                <Link
                    external={true}
                    href={milestone.milestone_digital_course_link}
                >
                    {milestone.milestone_digital_course}
                </Link>
            );
        case 'Certification':
            return milestone.milestone_certification;
        default:
            return '-';
    }
}

export const columnDefinitions: Array<
    TableProps.ColumnDefinition<MilestoneItemData>
> = [
    {
        id: 'type',
        header: 'Type',
        cell: (milestone: MilestoneItemData) => milestone.milestone_type,
    },
    {
        id: 'requirement',
        header: 'Requirement',
        cell: (milestone: MilestoneItemData) => milestone.milestone_requirement,
    },
    {
        id: 'resources',
        header: 'Resources',
        cell: (milestone: MilestoneItemData) => formatResource(milestone),
    },
    {
        id: 'active',
        header: 'Status',
        cell: (milestone: MilestoneItemData) =>
            formatStatus(milestone.active.toString()),
    },
];

export const AdminCourseMilestonesList = ({
    selectedCourse,
}: {
    selectedCourse: CourseItemData;
}) => {
    const [items, setItems] = useState(
        selectedCourse?.course_milestones
            ? selectedCourse?.course_milestones
            : [],
    );
    const history = useHistory();
    return (
        <Table
            columnDefinitions={columnDefinitions}
            items={items}
            header={<Header>Course Milestones ({items.length})</Header>}
            empty={
                <div className="awsui-util-t-c">
                    <p className="awsui-util-mb-s">No milestones</p>
                </div>
            }
        ></Table>
    );
};

export default AdminCourseMilestonesList;

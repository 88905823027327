import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InstructorBlockedTimeTypesResponseData } from '../../interfaces/businessDataResponse/instructorBlockedTimeTypesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { InstructorBlockedTimeTypeItemData } from '../../interfaces/businessDataItem/instructorBlockedTimeTypeItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * instructorBlockedTimeTypesSlice manages all instructorBlockedTimeTypes state, and contains instructorBlockedTimeTypes actions as well as instructorBlockedTimeTypes state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const instructorBlockedTimeTypesSlice = createSlice({
    name: 'instructorBlockedTimeTypes',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<InstructorBlockedTimeTypeItemData>,
    reducers: {
        setInstructorBlockedTimeTypesList: (
            state,
            action: PayloadAction<InstructorBlockedTimeTypeItemData[]>,
        ) => {
            const byInstructorBlockedTimeTypeName = action.payload.reduce(
                (
                    byInstructorBlockedTimeTypeName: {
                        [key: string]: InstructorBlockedTimeTypeItemData;
                    },
                    instructorBlockedTimeType: InstructorBlockedTimeTypeItemData,
                ) => {
                    byInstructorBlockedTimeTypeName[
                        instructorBlockedTimeType.instructor_blockedtime_type
                    ] = {
                        ...instructorBlockedTimeType,
                        active: parseBoolean(instructorBlockedTimeType.active),
                    };
                    return byInstructorBlockedTimeTypeName;
                },
                {},
            );
            state.entities = byInstructorBlockedTimeTypeName;
            state.keysList = Object.keys(byInstructorBlockedTimeTypeName);
        },
        setInstructorBlockedTimeType: (
            state,
            action: PayloadAction<InstructorBlockedTimeTypeItemData>,
        ) => {
            // if the item is new, add a new key for it and update the count.
            if (!state.entities[action.payload.instructor_blockedtime_type]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.instructor_blockedtime_type,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.instructor_blockedtime_type] =
                action.payload;
        },
        setSelectedInstructorBlockedTimeType: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPartialInstructorBlockedTimeTypesSlice:
            resetBasicBusinessDataStoreState,
        resetInstructorBlockedTimeTypesSlice:
            resetExtendedBusinessDataStoreState,
        initializeInstructorBlockedTimeTypesListQueryParams:
            initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setInstructorBlockedTimeTypesList,
    setInstructorBlockedTimeType,
    setSelectedInstructorBlockedTimeType,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setSize,
    resetPartialInstructorBlockedTimeTypesSlice,
    resetInstructorBlockedTimeTypesSlice,
    initializeInstructorBlockedTimeTypesListQueryParams,
} = instructorBlockedTimeTypesSlice.actions;

const handleInstructorBlockedTimeTypesListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const {
            from,
            size,
            searchText: instructor_blockedtime_type,
            active,
        } = state.instructorBlockedTimeTypes;

        const params: BusinessDataApiQueryParams.GetInstructorBlockedTimeTypes =
            {
                active,
            };

        // if both "from" and "size" equal to 0, avoid adding them into the query parameters
        if (from !== 0 || size !== 0) {
            params.from = from;
            params.size = size;
        }
        if (instructor_blockedtime_type) {
            params.instructor_blockedtime_type = instructor_blockedtime_type;
        }

        try {
            const {
                result: { INSTRUCTOR_BLOCKEDTIME_TYPE, total_count },
            }: HandleRequestData<InstructorBlockedTimeTypesResponseData> =
                await businessDataApi.getInstructorBlockedTimeTypes(params);

            dispatch(
                setInstructorBlockedTimeTypesList(INSTRUCTOR_BLOCKEDTIME_TYPE),
            );
            dispatch(setCount(total_count));
            dispatch(setPagesCount(Math.ceil(total_count / size)));
        } catch (error: any) {
            dispatch(
                setError(
                    error?.message || 'getInstructorBlockedTimeTypes API error',
                ),
            );
        }
    };
};

export const getInstructorBlockedTimeTypesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleInstructorBlockedTimeTypesListRequest());
        if (!state.instructorBlockedTimeTypes.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

/**
 * action to update instructor blocked time type
 * before update api call, fetch instructor blocked time types and prepare payload containing only the changed fields
 * after update api call, fetch instructor blocked time types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateInstructorBlockedTimeType = (
    data: InstructorBlockedTimeTypeItemData,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating instructor blocked time type: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateInstructorBlockedTimeType(
                data.pk,
                data.active,
            );
            await dispatch(handleInstructorBlockedTimeTypesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setInstructorBlockedTimeType({
                    ...state.instructorBlockedTimeTypes.entities[
                        data.instructor_blockedtime_type
                    ],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addInstructorBlockedTimeType = (
    active = true,
    instructorBlockedTimeTypeName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addInstructorBlockedTimeType(
                instructorBlockedTimeTypeName,
                active,
            );
            // now refresh the keysList
            await dispatch(handleInstructorBlockedTimeTypesListRequest());
            if (
                !state.instructorBlockedTimeTypes?.entities[
                    instructorBlockedTimeTypeName
                ]
            ) {
                // shove the item into the keysList, but only if it's not there already
                const newInstructorBlockedTimeTypeData: InstructorBlockedTimeTypeItemData =
                    {
                        instructor_blockedtime_type:
                            instructorBlockedTimeTypeName,
                        active: active,
                    };
                dispatch(
                    setInstructorBlockedTimeType(
                        newInstructorBlockedTimeTypeData,
                    ),
                );
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllInstructorBlockedTimeTypes = (state: GlobalState) => {
    return state.instructorBlockedTimeTypes.keysList.map(
        (instructorBlockedTimeTypeName) =>
            state.instructorBlockedTimeTypes.entities[
                instructorBlockedTimeTypeName
            ],
    );
};

export const selectAllActiveInstructorBlockedTimeTypes = (
    state: GlobalState,
) => {
    return getActiveBusinessDataValues(
        state.instructorBlockedTimeTypes.keysList,
        state.instructorBlockedTimeTypes.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.instructorBlockedTimeTypes.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.instructorBlockedTimeTypes.isLoaded;

export const selectSelectedInstructorBlockedTimeType = (state: GlobalState) => {
    return state?.instructorBlockedTimeTypes?.selectedItemKey
        ? state?.instructorBlockedTimeTypes?.entities[
              state?.instructorBlockedTimeTypes?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) =>
    state.instructorBlockedTimeTypes.error;

export const selectCount = (state: GlobalState) =>
    state.instructorBlockedTimeTypes.count;

export const selectSearchText = (state: GlobalState) =>
    state.instructorBlockedTimeTypes.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.instructorBlockedTimeTypes.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.instructorBlockedTimeTypes.currentPageIndex;

export default instructorBlockedTimeTypesSlice.reducer;

import { Box, Icon, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React, { LegacyRef, useEffect, useMemo, useRef } from 'react';
import { createUseStyles } from 'react-jss';

import { SelectedFileProps } from '../interfaces';
import {
    formatFileLastModified,
    formatFileSize,
    getBaseMetadata,
    isImageFile,
} from '../internal';

export const SelectedFile: React.FC<SelectedFileProps> = ({
    metadata,
    file,
    multiple = false,
}: SelectedFileProps) => {
    const styles = createUseStyles({
        file: {
            minWidth: 0,
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'flex-start',
            gap: '8px',
        },
        fileThumb: {
            maxWidth: '60px',
        },
        fileThumbImg: {
            width: '100%',
            height: 'auto',
        },
        fileMetadata: {
            overflow: 'hidden',
        },
        fileName: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        fileType: {
            marginLeft: '5px',
        },
    })();

    const thumbnail: LegacyRef<HTMLImageElement> = useRef(null);
    const baseMetadata = getBaseMetadata(metadata);

    const isImg = useMemo(() => isImageFile(file), [file]);

    useEffect(() => {
        if (multiple && baseMetadata.thumbnail && isImg) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (thumbnail.current && thumbnail.current.src) {
                    thumbnail.current.src = reader.result as string;
                }
            };
            reader.readAsDataURL(file);
        }
    }, [multiple, file, baseMetadata.thumbnail, isImg]);

    return (
        <Box className={styles.file}>
            <Icon variant="success" name="status-positive" />
            {baseMetadata.thumbnail && multiple && isImg && (
                <Box className={styles.fileThumb}>
                    <img
                        className={styles.fileThumbImg}
                        alt={file.name}
                        ref={thumbnail}
                        src=""
                    />
                </Box>
            )}
            <Box className={styles.fileMetadata}>
                <SpaceBetween direction="vertical" size="xxxs">
                    {baseMetadata.name && file.name && (
                        <Box className={styles.fileName}>
                            <span title={file.name}>{file.name}</span>
                        </Box>
                    )}
                    {baseMetadata.type && file.type && (
                        <Box fontSize="body-s" color="text-body-secondary">
                            {file.type}
                        </Box>
                    )}
                    {baseMetadata.size && file.size && (
                        <Box fontSize="body-s" color="text-body-secondary">
                            {formatFileSize(file.size, baseMetadata)}
                        </Box>
                    )}
                    {baseMetadata.lastModified && file.lastModified && (
                        <Box fontSize="body-s" color="text-body-secondary">
                            {formatFileLastModified(
                                file.lastModified,
                                baseMetadata,
                            )}
                        </Box>
                    )}
                </SpaceBetween>
            </Box>
        </Box>
    );
};

import { HandleRequestData } from '../interfaces/handleRequest';
import { FeatureFlagResponseData } from '../interfaces/featureFlags';
import handleRequest from '../utils/handleRequest';
export const FEATURE_FLAGS_PATH = '/features.json';
const getFeatures = (): Promise<HandleRequestData<FeatureFlagResponseData>> => {
    return handleRequest<FeatureFlagResponseData>(FEATURE_FLAGS_PATH, {
        method: 'GET',
    });
};

const featureFlagsApi = {
    getFeatures,
};

export default featureFlagsApi;

import {
    Alert,
    Box,
    Button,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { ActivityData } from '../../../../interfaces/activity';

interface DeleteCustomerModalProps {
    activeRemoveIndex: number;
    setActiveRemoveIndex: (value: React.SetStateAction<number>) => void;
    activity: ActivityData;
    handleRemoveCustomer: () => Promise<void>;
}

const DeleteCustomerModal = ({
    activeRemoveIndex,
    setActiveRemoveIndex,
    activity,
    handleRemoveCustomer,
}: DeleteCustomerModalProps) => {
    return (
        <Modal
            data-testid="delete-customer-modal"
            onDismiss={() => setActiveRemoveIndex(null)}
            visible={activeRemoveIndex !== null}
            closeAriaLabel="Close modal"
            size="medium"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid="ActivityDetailsCancelDelete"
                            onClick={() => setActiveRemoveIndex(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ActivityDetailsDeleteCustomer`}
                            onClick={() => handleRemoveCustomer()}
                        >
                            Delete customer
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Delete this customer?"
        >
            {activeRemoveIndex !== null && (
                <Alert type="warning">
                    Are you sure you want to delete{' '}
                    {activity.customers[activeRemoveIndex].customer_name}{' '}
                    customer from this activity?
                </Alert>
            )}
        </Modal>
    );
};

export default DeleteCustomerModal;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HandleRequestData } from '../../interfaces/handleRequest';
import { AppDispatch, GlobalState } from '../../../main/store';
import { CourseItemData } from '../../interfaces/businessDataItem/courseItem';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { CoursesResponseData } from '../../interfaces/businessDataResponse/coursesResponse';
import parseBoolean from '../../utils/parseBoolean';
import {
    getActiveBusinessDataValues,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import businessDataApi from '../../api/businessDataApi';
import { GRIMSBY_PAGE_COUNT } from '../../constants/grimsby';
import { GenericStoreState } from '../../interfaces/genericStoreState';

/**
 * courseListSlice manages all app Course list state, and contains Course list actions as well as Course list state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */

export interface CourseListState
    extends ExtendedBusinessDataStoreState<CourseItemData> {
    totalCoursesCount: number;
}

export interface CourseListState extends GenericStoreState {
    courseList: CourseItemData[];
    from: number;
    pagesCount: number;
    currentPageIndex: number;
    totalUsersCount: number;
    size: number;
    active: boolean | null;
    searchText: string | null;
    isLoaded: boolean;
}

export const courseListSlice = createSlice({
    name: 'courseList',
    initialState: {
        active: null,
        from: 0,
        searchText: null,
        pagesCount: 0,
        currentPageIndex: 1,
        size: GRIMSBY_PAGE_COUNT,
        totalCoursesCount: 0,
        keysList: [],
        entities: {},
        isLoading: false,
        isLoaded: false,
        error: null,
        selectedItemKey: null,
        count: 0,
    } as CourseListState,
    reducers: {
        setCoursesList: (state, action: PayloadAction<CourseItemData[]>) => {
            const byCourseName = action.payload.reduce(
                (
                    byCourseName: {
                        [key: string]: CourseItemData;
                    },
                    course: CourseItemData,
                ) => {
                    byCourseName[course.course] = {
                        ...course,
                        active: parseBoolean(course.active),
                    };
                    return byCourseName;
                },
                {},
            );
            state.entities = byCourseName;
            state.keysList = Object.keys(byCourseName);
        },
        resetCourseListSlice: (state) => {
            state.from = 0;
            state.searchText = null;
            state.totalUsersCount = 0;
            state.error = null;
            state.courseList = [];
            state.isLoaded = false;
            state.currentPageIndex = 1;
            state.pagesCount = 0;
            state.active = null;
            state.size = GRIMSBY_PAGE_COUNT;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setTotalCoursesCount: (state, action: PayloadAction<number>) => {
            state.totalCoursesCount = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (state, action: PayloadAction<boolean>) => {
            state.active = action.payload;
        },
        resetCoursesListSlice: (state) => {
            state.from = 0;
            state.searchText = null;
            state.totalUsersCount = 0;
            state.error = null;
            state.courseList = [];
            state.isLoaded = false;
            state.currentPageIndex = 1;
            state.pagesCount = 0;
            state.active = null;
        },
        initializeCoursesListQueryParams:
            initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setCoursesList,
    setError,
    setCount,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setTotalCoursesCount,
    setSize,
    setSearchText,
    setActive,
    resetCoursesListSlice,
    initializeCoursesListQueryParams,
} = courseListSlice.actions;

export const getCoursesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        const { from, size, searchText: course, active } = state.courseList;

        const params: BusinessDataApiQueryParams.GetCourses = {
            from,
            size,
            active,
        };

        if (size === 0) {
            params.size = size;
        }
        if (from !== 0) {
            params.from = from;
        }
        if (course) {
            params.course = course;
        }

        try {
            const {
                result: { COURSE, total_count },
            }: HandleRequestData<CoursesResponseData> =
                await businessDataApi.getCourses(params);
            dispatch(setCoursesList(COURSE));
            dispatch(setTotalCoursesCount(total_count));
            dispatch(
                setPagesCount(Math.ceil(total_count / GRIMSBY_PAGE_COUNT)),
            );
        } catch (error: any) {
            dispatch(setError(error?.message || 'getCourses API error'));
        }
        if (!state.courseList.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

export const selectAllCourses = (state: GlobalState) => {
    return state.courseList?.keysList.map(
        (courseName) => state.courseList?.entities[courseName],
    );
};

export const selectAllActiveCourses = (
    state: GlobalState,
): Array<CourseItemData> => {
    return getActiveBusinessDataValues(
        state.courseList?.keysList,
        state.courseList?.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.courseList?.isLoading;

export const selectPagesCount = (state: GlobalState) =>
    state.courseList?.pagesCount;

export const selectIsLoaded = (state: GlobalState) =>
    state.courseList?.isLoaded;

export const selectSelectedCourse = (state: GlobalState) => {
    return state?.courseList?.selectedItemKey
        ? state?.courseList?.entities[state?.courseList?.selectedItemKey]
        : null;
};

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.courseList?.currentPageIndex;

export const selectTotalCoursesCount = (state: GlobalState) =>
    state.courseList?.totalCoursesCount;

export const selectError = (state: GlobalState) => state.courseList?.error;

export const selectCount = (state: GlobalState) => state.courseList?.count;

export const selectSearchText = (state: GlobalState) =>
    state.courseList?.searchText;

export default courseListSlice.reducer;

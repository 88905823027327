import dayjs from 'dayjs';
import {
    Checkbox,
    DatePicker,
    FormField,
    Select,
} from '@amzn/awsui-components-react';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from '../../../../../imt/components/Instructor/FormSections/FormSections.common';
import { DEFAULT_LANGUAGE } from '../../../../constants';
import { checkFeature } from '../../../../../common/utils/featureFlag';
import { selectFeatures } from '../../../../../common/store/slices/featureSlice';
import {
    getDeliveryLanguagesList as getLanguagesList,
    resetDeliveryLanguagesSlice as resetLanguagesSlice,
    selectAllActiveDeliveryLanguages as selectAllActiveLanguages,
    selectError as selectLanguageListError,
    selectIsLoaded as selectIsLanguageListLoaded,
    selectIsLoading as selectIsLanguageListLoading,
} from '../../../../../common/store/slices/deliveryLanguagesSlice';
import React, { useEffect, useState } from 'react';
import {
    ActivityData,
    APNPartnerPrivateCustomer,
    CommercialPrivateCustomer,
    Customer,
} from '../../../../interfaces/activity';
import { useDispatch, useSelector } from 'react-redux';
import { DeliveryLanguageItemData as LanguageItemData } from '../../../../../common/interfaces/businessDataItem/deliveryLanguageItem';
import { ActivityType } from '../../Common/Common';

interface CustomerCorrespondenceEditSectionProps {
    formValues:
        | Customer
        | CommercialPrivateCustomer
        | APNPartnerPrivateCustomer;
    errors: {
        [key in keyof Partial<
            Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer
        >]: string;
    };
    handleFieldEvent: (
        values: Partial<
            Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer
        >,
    ) => void;
    activity: ActivityData;
}

export const CustomerCorrespondenceEditSection = ({
    formValues,
    errors,
    handleFieldEvent,
    activity,
}: CustomerCorrespondenceEditSectionProps) => {
    const dispatch = useDispatch();
    const [customerNotificationDate, setCustomerNotificationDate] =
        useState<string>(undefined);
    const languageList = useSelector(selectAllActiveLanguages);
    const isLanguageListLoading = useSelector(selectIsLanguageListLoading);
    const isLanguageListLoaded = useSelector(selectIsLanguageListLoaded);
    const languageListError = useSelector(selectLanguageListError);

    const { valueLookup: languageLookup, valueOptions: languageOptions } =
        getOptionsAndLookupForSelectInput<LanguageItemData>(
            languageList,
            (language: LanguageItemData) => ({
                label: language.delivery_language,
                id: language.pk as string,
            }),
        );

    useEffect(() => {
        (
            [
                [
                    !isLanguageListLoaded && !isLanguageListLoading,
                    getLanguagesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    useEffect(() => {
        if (customerNotificationDate !== undefined) {
            // we'll set by the hour to 7AM so that emails are sent in the morning instead of at midnight
            let nextNotifyDate = customerNotificationDate
                ? dayjs(customerNotificationDate).hour(7).unix()
                : null;
            if (nextNotifyDate && nextNotifyDate < dayjs().unix()) {
                // ensure next notification date is not sent to a date in the past, for auditing purposes
                nextNotifyDate = dayjs().unix();
            }
            // ensure notify_customer_date can be set back to null if no date is selected
            handleFieldEvent({
                notify_customer_date: nextNotifyDate,
            });
            // set email_sent based on whether there's a date selected for notify_customer_date
            handleFieldEvent({
                email_sent: !!!nextNotifyDate,
            });
        }
    }, [customerNotificationDate, handleFieldEvent]);

    useEffect(() => {
        if (
            formValues.notify_customer_date &&
            customerNotificationDate === undefined
        ) {
            setCustomerNotificationDate(
                dayjs
                    .unix(formValues.notify_customer_date)
                    .format('YYYY/MM/DD'),
            );
        }
    }, [formValues, customerNotificationDate]);

    const getLastNotificationDescription = () => {
        return formValues.most_recent_email_date
            ? `The customer last received an email for this activity on ${dayjs
                  .unix(formValues.most_recent_email_date)
                  .format('YYYY/MM/DD')}.`
            : 'No notifications have been sent to the customer yet for this activity.';
    };

    const featureFlags = useSelector(selectFeatures);
    const isCustomerCorrespondenceEnabled = checkFeature(
        '',
        { featureName: 'customer-correspondence' },
        featureFlags?.features,
    );
    const isClass = activity?.activity_type
        ? activity.activity_type === ActivityType.Class
        : false;
    return (
        <>
            {isCustomerCorrespondenceEnabled && isClass && (
                <>
                    <FormField
                        errorText={errors?.notify_customer_date}
                        label={
                            <span>
                                Next customer notification date -{' '}
                                <em>optional</em>
                            </span>
                        }
                        description={`If a date is selected, an email will be sent on that date containing a reminder for the customer about their upcoming training(s). ${getLastNotificationDescription()}`}
                    >
                        <DatePicker
                            isDateEnabled={(date: Date) => {
                                // Disables dates in the past from being selectable
                                const today = new Date();
                                return (
                                    today.setHours(0, 0, 0, 0) <=
                                    date.setHours(0, 0, 0, 0)
                                );
                            }}
                            className={
                                `date-picker-fullwidth` &&
                                errors?.notify_customer_date &&
                                FORM_ERROR_SELECTOR
                            }
                            value={
                                customerNotificationDate
                                    ? customerNotificationDate
                                    : ''
                            }
                            placeholder="YYYY/MM/DD"
                            onChange={(e) => {
                                setCustomerNotificationDate(e.detail.value);
                            }}
                            data-testid={`EditCustomerNotificationDate`}
                        />
                    </FormField>
                    <FormField errorText={errors?.do_not_group_email}>
                        <Checkbox
                            description="If checked, this activity will be grouped into a single email with other activities containing the same customer if there are other activities which are configured to trigger an email at the same time."
                            label="Group with other activities"
                            checked={!!!formValues.do_not_group_email}
                            onChange={(e) => {
                                handleFieldEvent({
                                    do_not_group_email: !e.detail.checked,
                                });
                            }}
                            data-testid="do_not_group_email_checkbox"
                        ></Checkbox>
                    </FormField>
                    <FormField errorText={errors?.send_to_customer_poc}>
                        <Checkbox
                            description="If checked, emails will be sent directly to the delivery contact email address provided above. If not checked, or if no delivery contact email is provided, emails will be sent to the Operations Owner."
                            label="Notify customer directly"
                            checked={formValues.send_to_customer_poc !== false}
                            onChange={(e) => {
                                handleFieldEvent({
                                    send_to_customer_poc: e.detail.checked,
                                });
                            }}
                            data-testid="send_to_customer_poc_checkbox"
                        ></Checkbox>
                    </FormField>
                </>
            )}
            <FormField
                label="Customer communication language"
                errorText={errors?.customer_communication_language}
            >
                <Select
                    className={
                        errors?.customer_communication_language &&
                        FORM_ERROR_SELECTOR
                    }
                    placeholder={
                        isLanguageListLoading
                            ? 'Loading languages'
                            : 'Select a language'
                    }
                    options={languageOptions}
                    selectedOption={
                        languageLookup[
                            formValues.customer_communication_language ||
                                DEFAULT_LANGUAGE
                        ]
                    }
                    onChange={(e) => {
                        handleFieldEvent({
                            customer_communication_language:
                                e.detail.selectedOption.label,
                        });
                    }}
                    onRecoveryClick={() => {
                        dispatch(resetLanguagesSlice());
                        dispatch(getLanguagesList());
                    }}
                    loadingText="Loading languages"
                    errorText="An error occurred while loading languages"
                    recoveryText="Retry"
                    empty="No languages found"
                    statusType={getStatusType(
                        isLanguageListLoading,
                        isLanguageListLoaded,
                        languageListError,
                    )}
                    data-testid={`CustomerCommunicationLanguage`}
                    disabled={!isLanguageListLoaded}
                />
            </FormField>
        </>
    );
};

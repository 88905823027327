import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateLocationPayloadData } from '../../interfaces/adminCreateBusinessDataPayload/locationPayload';
import { LocationsResponseData } from '../../interfaces/businessDataResponse/locationsResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const LOCATIONS_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/cities`;

/**
 * Method to request the full list of cities
 * @param {BusinessDataApiQueryParams.GetLocations} queryParams - query params
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getLocations = (
    queryParams?: BusinessDataApiQueryParams.GetLocations,
): Promise<HandleRequestData<LocationsResponseData>> => {
    const path = `${LOCATIONS_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<LocationsResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a city.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateLocation = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(LOCATIONS_PATH, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

/**
 * Method to add a city.
 * @param {AdminCreateLocationPayloadData} newLocationPayload - the new location data
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addLocation = (
    newLocationPayload: AdminCreateLocationPayloadData,
): Promise<HandleRequestData<ResponseData>> => {
    return handleGrimsbyApiRequest<ResponseData>(LOCATIONS_PATH, {
        method: 'POST',
        body: JSON.stringify(newLocationPayload),
    });
};

export const locationsApi = {
    getLocations,
    updateLocation,
    addLocation,
};

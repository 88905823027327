import { EMPTY_STRING } from '../constants/grimsby';

/**
 * Method to format seconds since epoch to UTC string
 */
const formatDate = (timestamp: number | Date): string => {
    if (timestamp instanceof Date) {
        return timestamp.toUTCString().slice(5, -13);
    } else if (typeof timestamp === 'number') {
        return new Date(timestamp).toUTCString().slice(5, -13);
    } else {
        return EMPTY_STRING;
    }
};

export const encodeDateNumber = (dateNumber: number) =>
    Math.floor(dateNumber / 1000);

export const decodeDateNumber = (dateNumber: number) => dateNumber * 1000;

export default formatDate;

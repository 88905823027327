import React from 'react';
import {
    getIsExternalInstructor,
    InstructorFormSectionProps,
    InstructorTypeValues,
} from '../Common/Common';
import { InstructorProfileData } from '../../../interfaces/instructorProfile';
import { FormSectionMode } from '../../../../common/constants/forms';
import { FormSection } from '@amzn/awsui-components-react';
import InstructorDetailFormFields from './InstructorDetailFormFields';
import { INSTRUCTOR_TYPE_DETAILS_DESC } from '../../../../common/constants/grimsby';

const InstructorDetailsFormSection = (
    instructorFormSectionProps: InstructorFormSectionProps<InstructorProfileData>,
) => {
    const { formValues, mode } = instructorFormSectionProps;

    const isExternalInstructor = getIsExternalInstructor(
        formValues.instructor_type as InstructorTypeValues,
    );

    const inlineInstructorFormSectionProps = {
        ...instructorFormSectionProps,
        mode: FormSectionMode.Edit,
    };

    const getHeaderTitle = () =>
        `${isExternalInstructor ? 'External' : 'Internal'} instructor details`;

    return (
        <>
            {mode !== FormSectionMode.InlineEdit ? (
                <FormSection
                    data-testid="InstructorDetailsFormSection"
                    header={getHeaderTitle()}
                >
                    <InstructorDetailFormFields
                        {...instructorFormSectionProps}
                    />
                </FormSection>
            ) : (
                <section
                    className="grimsby-form-section"
                    data-testid="InlineInstructorDetailsFormSection"
                >
                    <div className="grimsby-form-section-header">
                        <div>{getHeaderTitle()}</div>
                        <div className="grimsby-form-section-description">
                            {INSTRUCTOR_TYPE_DETAILS_DESC}
                        </div>
                    </div>
                    <InstructorDetailFormFields
                        {...inlineInstructorFormSectionProps}
                    />
                </section>
            )}
        </>
    );
};

export default InstructorDetailsFormSection;

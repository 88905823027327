import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreatePartnerInitiativePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/partnerInitiativePayload';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { PartnerInitiativesResponseData } from '../../interfaces/businessDataResponse/partnerInitiativesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const PARTNER_INITIATIVES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/partner-initiative`;

/**
 * Method to request the full list of partner initiatives
 * @param {BusinessDataApiQueryParams.GetPartnerInitiatives} queryParams - which initiative(s) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getPartnerInitiatives = (
    queryParams?: BusinessDataApiQueryParams.GetPartnerInitiatives,
): Promise<HandleRequestData<PartnerInitiativesResponseData>> => {
    let path = `${PARTNER_INITIATIVES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<PartnerInitiativesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a partner initiative.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updatePartnerInitiative = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${PARTNER_INITIATIVES_PATH}`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add a partner initiative.
 * @param {string} partnerInitiativeName - the name of the new partner initiative
 * @param {boolean} active - the status of the new partner initiative
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addPartnerInitiative = (
    partnerInitiativeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreatePartnerInitiativePayloadData = {
        partner_initiative: partnerInitiativeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${PARTNER_INITIATIVES_PATH}`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

const partnerInitiativesApi = {
    getPartnerInitiatives,
    updatePartnerInitiative,
    addPartnerInitiative,
};

export default partnerInitiativesApi;

import React from 'react';

import { ERROR_404 } from '../../constants/grimsby';
import AlertPage, { AlertPageData } from './AlertPage';
import Welcome from '../Welcome';

interface RedirectedAlertPageData {
    location?: {
        pathname?: string;
        state?: AlertPageData;
    };
}

const REDIRECT_PATHS = {
    ERROR: 'error',
    WELCOME: 'welcome',
} as const;

/**
 * Alert page component for redirection
 * @param location the parameter passed by redirection. Reference: https://reactrouter.com/web/api/Redirect/to-object
 */
const RedirectedAlertPage = ({ location }: RedirectedAlertPageData) => {
    const state = location?.state;
    const path = location?.pathname;
    const {
        children = ERROR_404,
        header = 'Error',
        alertType = 'error',
    } = state ? state : {};

    if (path && path?.includes(REDIRECT_PATHS.WELCOME)) {
        return (
            <AlertPage
                children={<Welcome sentence={children as string} />}
                header="Welcome"
                alertType="info"
            />
        );
    }

    return (
        <AlertPage children={children} header={header} alertType={alertType} />
    );
};

export default RedirectedAlertPage;

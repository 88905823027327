import * as React from 'react';
import { useState } from 'react';
import {
    Alert,
    Badge,
    Modal,
    SpaceBetween,
    Table,
    Toggle,
} from '@amzn/awsui-components-react-v3';
import { ActivityData, Customer } from '../../../interfaces/activity';
import { formatStringArray } from '../../../../common/utils/formatStringArray';
import { formatString } from '../../../../common/utils/formatString';
import { formatGrimsbyDate } from '../../Activity/Common/Common';

import './MergeActivityList.scss';
import ActivityPopoverTemplate from '../ResourceCalendar/Popovers/ActivityPopover';

enum ActivityTableColumnId {
    ActivityName = 'activity_name',
    StartDate = 'start_date',
    EndDate = 'end_date',
    Status = 'activity_status',
    Type = 'activity_type',
    Audience = 'activity_audience',
    CourseName = 'course_name',
    Modality = 'activity_modality',
    Customer = 'customer',
    Location = 'location',
    DeliveryLanguage = 'delivery_language',
    OperationsOwner = 'operations_owner',
    Scheduler = 'scheduler',
    CustomerSuccessManager = 'customer_support_manager',
    Program = 'program',
    Providers = 'provider',
    Region = 'delivery_region',
    Instructors = 'instructors',
    Timezone = 'delivery_timezone',
    Country = 'delivery_country',
}

interface MergeActivityListProps {
    setMergeActivity: any;
    mergeActivities: Array<any>;
    autoMergeOn?: boolean;
    mergeDescription: any;
    activityPk?: string;
    showMustSelect?: boolean;
    onMergeToggle?: any;
    isLoadingMergeList?: boolean;
}

const MergeActivityList = ({
    setMergeActivity,
    mergeActivities,
    autoMergeOn,
    mergeDescription,
    activityPk,
    showMustSelect,
    onMergeToggle,
    isLoadingMergeList,
}: MergeActivityListProps) => {
    const [showMerged, setShowMerged] = useState<boolean>(
        autoMergeOn ? true : false,
    );
    const [activeQuickViewActivity, setActiveQuickViewActivity] =
        useState<ActivityData | null>(null);

    const columnDefinitions: Array<any> = [
        {
            id: 'ActivitySelect',
            header: '',
            minWidth: 60,
            cell: (activity: ActivityData) => (
                <input
                    type="radio"
                    name="mergeActivity"
                    onChange={(e) => setMergeActivity(activity)}
                />
            ),
        },
        {
            id: ActivityTableColumnId.ActivityName,
            header: 'Activity name',
            minWidth: 250,
            sortingField: 'activity_name',
            cell: (activity: ActivityData) => (
                <span
                    className="merge-activity-name"
                    onClick={() => {
                        setActiveQuickViewActivity(activity);
                    }}
                >
                    {formatString(activity.activity_name)}
                </span>
            ),
        },
        {
            id: ActivityTableColumnId.Customer,
            header: 'Customers',
            sortingField: 'customer',
            minWidth: 200,
            width: 400,
            maxWidth: 600,
            cell: (activity: ActivityData) =>
                formatStringArray(
                    (activity.customers as Array<Customer>)?.map(
                        (c) => c.customer_name,
                    ),
                ),
        },
        {
            id: ActivityTableColumnId.Instructors,
            header: 'Instructors',
            minWidth: 320,
            width: 320,
            cell: (activity: ActivityData) =>
                activity.instructors?.map((i) => {
                    if (i.type === 'External') {
                        return (
                            <p key={`${i.pk}-name`}>
                                {i.name} <Badge color="blue">FL</Badge>
                            </p>
                        );
                    } else {
                        return <p key={`${i.pk}-name`}>{i.name}</p>;
                    }
                }),
        },
        {
            id: ActivityTableColumnId.Location,
            header: 'Location',
            minWidth: 320,
            cell: (activity: ActivityData) =>
                formatStringArray([
                    activity.delivery_city,
                    activity.delivery_state,
                    activity.delivery_country,
                ]),
        },
        {
            id: ActivityTableColumnId.Country,
            header: 'Country',
            minWidth: 320,
            cell: (activity: ActivityData) =>
                formatString(activity.delivery_country),
        },
        {
            id: ActivityTableColumnId.StartDate,
            header: 'Start date',
            minWidth: 180,
            sortingField: 'delivery_sessions.start_timestamp',
            cell: (activity: ActivityData) =>
                activity?.delivery_sessions[0]?.start_timestamp
                    ? formatGrimsbyDate(
                          activity.delivery_sessions[0].start_timestamp,
                          activity.delivery_timezone ?? 'UTC',
                      )
                    : '',
        },
        {
            id: ActivityTableColumnId.EndDate,
            header: 'End date',
            minWidth: 180,
            sortingField: 'delivery_sessions.end_timestamp',
            cell: (activity: ActivityData) =>
                activity?.delivery_sessions[
                    activity.delivery_sessions.length - 1
                ]?.end_timestamp
                    ? formatGrimsbyDate(
                          activity.delivery_sessions[
                              activity.delivery_sessions.length - 1
                          ].end_timestamp,
                          activity.delivery_timezone ?? 'UTC',
                      )
                    : '',
        },
        {
            id: ActivityTableColumnId.Status,
            header: 'Activity status',
            minWidth: 120,
            sortingField: 'activity_status',
            cell: (activity: ActivityData) =>
                formatString(activity.activity_status),
        },
        {
            id: ActivityTableColumnId.Type,
            header: 'Activity type',
            minWidth: 120,
            sortingField: 'activity_type',
            cell: (activity: ActivityData) =>
                formatString(activity.activity_type),
        },
        {
            id: ActivityTableColumnId.Audience,
            header: 'Audience',
            minWidth: 250,
            cell: (activity: ActivityData) =>
                formatString(activity.activity_audience),
        },
        {
            id: ActivityTableColumnId.CourseName,
            header: 'Course name',
            minWidth: 250,
            sortingField: 'course_name',
            cell: (activity: ActivityData) =>
                formatString(activity.course_name),
        },
        {
            id: ActivityTableColumnId.Modality,
            header: 'Modality',
            minWidth: 120,
            sortingField: 'activity_modality',
            cell: (activity: ActivityData) =>
                formatString(activity.activity_modality),
        },
        {
            id: ActivityTableColumnId.Timezone,
            header: 'Timezone',
            minWidth: 200,
            sortingField: 'delivery_timezone',
            cell: (activity: ActivityData) =>
                formatString(activity.delivery_timezone),
        },
    ];

    React.useEffect(() => {
        if (!showMerged) {
            setMergeActivity(null);
        }
    }, [showMerged, setMergeActivity]);

    return (
        <SpaceBetween size={'s'}>
            {(mergeActivities.length > 0 || !!isLoadingMergeList) &&
                mergeDescription}
            {!autoMergeOn && (
                <Toggle
                    onChange={({ detail }) => {
                        setShowMerged(detail.checked);
                        onMergeToggle(detail.checked);
                    }}
                    checked={showMerged}
                >
                    Merge with an existing activity
                </Toggle>
            )}
            {showMustSelect && (
                <Alert type="error">Select an activity for merging.</Alert>
            )}
            {showMerged && (
                <>
                    {(mergeActivities.length > 0 || !!isLoadingMergeList) && (
                        <>
                            <p>Select activity to merge</p>
                            <Table
                                columnDefinitions={columnDefinitions}
                                empty={'No activities'}
                                items={mergeActivities}
                                key={`merge-list-${activityPk}`}
                                loading={isLoadingMergeList}
                            />
                        </>
                    )}
                    {mergeActivities.length === 0 && !isLoadingMergeList && (
                        <>
                            <Alert header="No activities to merge">
                                Merging activities requires matching the course,
                                language, location, modality and start date of
                                another activity.
                            </Alert>
                        </>
                    )}
                </>
            )}
            <Modal
                visible={!!activeQuickViewActivity}
                onDismiss={() => {
                    setActiveQuickViewActivity(null);
                }}
                header={
                    activeQuickViewActivity
                        ? activeQuickViewActivity.activity_name
                        : 'Activity info'
                }
            >
                {activeQuickViewActivity !== null && (
                    <>
                        <ActivityPopoverTemplate {...activeQuickViewActivity} />
                    </>
                )}
            </Modal>
        </SpaceBetween>
    );
};

export default MergeActivityList;

import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateInstructorRoleTypePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/instructorRoleTypePayload';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { InstructorRoleTypesResponseData } from '../../interfaces/businessDataResponse/instructorRoleTypesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const INSTRUCTOR_ROLE_TYPES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/instructor-role-types`;

/**
 * Method to request the full list of instructor role types
 * @param {BusinessDataApiQueryParams.GetInstructorRoleTypes} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getInstructorRoleTypes = (
    queryParams?: BusinessDataApiQueryParams.GetInstructorRoleTypes,
): Promise<HandleRequestData<InstructorRoleTypesResponseData>> => {
    let path = `${INSTRUCTOR_ROLE_TYPES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<InstructorRoleTypesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an instructor role type.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateInstructorRoleType = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${INSTRUCTOR_ROLE_TYPES_PATH}`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add an instructor role type.
 * @param {string} instructorRoleTypeName - the name of the new instructor role type
 * @param {boolean} active - the status of the new instructor role type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addInstructorRoleType = (
    instructorRoleTypeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateInstructorRoleTypePayloadData = {
        instructor_role_type: instructorRoleTypeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${INSTRUCTOR_ROLE_TYPES_PATH}`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

const instructorRoleTypesApi = {
    getInstructorRoleTypes,
    updateInstructorRoleType,
    addInstructorRoleType,
};

export default instructorRoleTypesApi;

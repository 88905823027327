import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormField,
    Input,
    Container,
    Header,
    SpaceBetween,
    Select,
} from '@amzn/awsui-components-react-v3';
import {
    ActivityModality,
    ActivityProgram,
    ActivityStatus,
    AudienceType,
    BasicActivityFormSectionProps,
} from '../../Common/Common';
import {
    getActivityStatusesList,
    resetActivityStatusesSlice,
    selectAllActiveActivityStatuses,
    selectError as selectActivityStatusListError,
    selectIsLoaded as selectIsActivityStatusListLoaded,
    selectIsLoading as selectIsActivityStatusListLoading,
} from '../../../../../common/store/slices/activityStatusesSlice';
import {
    getActivityAudiencesList,
    resetActivityAudiencesSlice,
    selectIsLoaded as selectIsActivityAudienceListLoaded,
    selectIsLoading as selectIsActivityAudienceListLoading,
} from '../../../../../common/store/slices/activityAudiencesSlice';
import {
    getActivityModalitiesList,
    resetActivityModalitiesSlice,
    selectAllActiveActivityModalities,
    selectError as selectActivityModalityListError,
    selectIsLoaded as selectIsActivityModalityListLoaded,
    selectIsLoading as selectIsActivityModalityListLoading,
} from '../../../../../common/store/slices/activityModalitiesSlice';
import {
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInputPolarisV3,
    getStatusType,
} from '../../../../../imt/components/Instructor/FormSections/FormSections.common';
import { ActivityStatusItemData } from '../../../../../common/interfaces/businessDataItem/activityStatusItem';
import { ActivityModalityItemData } from '../../../../../common/interfaces/businessDataItem/activityModalityItem';
import DeliveryAddressSection from './Sections/DeliveryAddressSection';
import StudentCountSection from './Sections/StudentCountSection';
import { defaultShowAdditionalFields } from '../../Edit/EditBaseDetailsForm';
import PartnerInitiativeSection from './Sections/PartnerInitiativeSection';
import SelectLMSType from '../CommonFormFields/SelectLMSType';
import ClassSizeInput from '../CommonFormFields/ClassSizeInput';
import SelectVILTType from '../CommonFormFields/SelectVILTType';
import AudienceListSelect from '../CommonFormFields/AudienceListSelect';

const EditBaseDetailsFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    setShowAdditionalFields,
    showAdditionalFields,
    initialFormState,
}: BasicActivityFormSectionProps) => {
    const isActivityStatusListLoading = useSelector(
        selectIsActivityStatusListLoading,
    );
    const isActivityStatusListLoaded = useSelector(
        selectIsActivityStatusListLoaded,
    );
    const activityStatusListError = useSelector(selectActivityStatusListError);
    const isActivityAudienceListLoading = useSelector(
        selectIsActivityAudienceListLoading,
    );
    const isActivityAudienceListLoaded = useSelector(
        selectIsActivityAudienceListLoaded,
    );

    const isActivityModalityListLoading = useSelector(
        selectIsActivityModalityListLoading,
    );
    const isActivityModalityListLoaded = useSelector(
        selectIsActivityModalityListLoaded,
    );
    const activityModalityListError = useSelector(
        selectActivityModalityListError,
    );
    const activityStatusList = useSelector(selectAllActiveActivityStatuses);
    const activityModalityList = useSelector(selectAllActiveActivityModalities);

    const [isPartnerInitiativeVisible, setIsPartnerInitiativeVisible] =
        useState(false);

    useEffect(() => {
        const isPartnerProgram = formValues.program === ActivityProgram.Partner;
        setIsPartnerInitiativeVisible(isPartnerProgram);
    }, [formValues]);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            // reset business data slices
            // this code block should only run once
            [
                resetActivityStatusesSlice,
                resetActivityAudiencesSlice,
                resetActivityModalitiesSlice,
            ].forEach((resetFunction) => dispatch(resetFunction()));
        };
    }, [dispatch]);

    // lifecycle method to fetch (and re- fetch) business data
    useEffect(() => {
        (
            [
                [
                    !isActivityStatusListLoaded && !isActivityStatusListLoading,
                    getActivityStatusesList,
                ],
                [
                    !isActivityAudienceListLoaded &&
                        !isActivityAudienceListLoading,
                    getActivityAudiencesList,
                ],
                [
                    !isActivityModalityListLoaded &&
                        !isActivityModalityListLoading,
                    getActivityModalitiesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    // https://quip-amazon.com/fLHPAp8B8bUU/Activity-Status-Validations
    useEffect(() => {
        const {
            activity_status,
            activity_modality,
            activity_audience,
            program,
        } = formValues;
        const isPartnerProgram = program === ActivityProgram.Partner;
        const isCommercialProgram = program === ActivityProgram.Commercial;
        const isCompleted = activity_status === ActivityStatus.Completed;
        const isILT = activity_modality === ActivityModality.ILT;
        const isvILT = activity_modality === ActivityModality.vILT;
        const isHybrid = activity_modality === ActivityModality.Hybrid;
        const isActive = activity_status === ActivityStatus.Active;
        const isPartnerPrivate =
            activity_audience === AudienceType.PartnerPrivate;
        const isCommercialPrivate =
            activity_audience === AudienceType.CommercialPrivate;
        const isCommercialPublic =
            isCommercialProgram && activity_audience === AudienceType.Public;
        // Activity is set to `Completed` with no attended value
        if (isCompleted && !initialFormState.attended) {
            setShowAdditionalFields({
                ...defaultShowAdditionalFields,
                showNumberOfStudentsAttended: true,
            });
            return;
        }

        if (isPartnerProgram) {
            setShowAdditionalFields({
                ...defaultShowAdditionalFields,
                showPartnerInitiative: true,
            });
        }

        if (isActive) {
            if (isILT) {
                if (isCommercialProgram) {
                    return setShowAdditionalFields({
                        ...defaultShowAdditionalFields,
                        showDeliveryAddress:
                            !initialFormState.delivery_address_1 ||
                            !initialFormState.delivery_postal_code,
                        showClassSize: true,
                    });
                }

                if (isPartnerProgram) {
                    return setShowAdditionalFields({
                        ...defaultShowAdditionalFields,
                        showDeliveryAddress:
                            !initialFormState.delivery_address_1 ||
                            !initialFormState.delivery_postal_code,
                        showClassSize: isPartnerPrivate,
                    });
                }
                return setShowAdditionalFields({
                    ...defaultShowAdditionalFields,
                    showDeliveryAddress:
                        !initialFormState.delivery_address_1 ||
                        !initialFormState.delivery_postal_code,
                });
            }

            if (isvILT) {
                if (isCommercialProgram) {
                    return setShowAdditionalFields({
                        ...defaultShowAdditionalFields,
                        showLMSType: isCommercialPrivate,
                        showVILTType: isCommercialPrivate,
                        showClassSize: isCommercialPrivate,
                    });
                }

                if (isPartnerProgram) {
                    return setShowAdditionalFields({
                        ...defaultShowAdditionalFields,
                        showLMSType: isPartnerPrivate,
                        showVILTType: isPartnerPrivate,
                    });
                }
                return setShowAdditionalFields(defaultShowAdditionalFields);
            }

            if (isHybrid) {
                if (isCommercialProgram) {
                    return setShowAdditionalFields({
                        ...defaultShowAdditionalFields,
                        showDeliveryAddress:
                            !initialFormState.delivery_address_1 ||
                            !initialFormState.delivery_postal_code,
                        showClassSize:
                            isCommercialPrivate || isCommercialPublic,
                    });
                }
                if (isPartnerProgram) {
                    return setShowAdditionalFields({
                        ...defaultShowAdditionalFields,
                        showDeliveryAddress:
                            !initialFormState.delivery_address_1 ||
                            !initialFormState.delivery_postal_code,
                    });
                }
                return setShowAdditionalFields({
                    ...defaultShowAdditionalFields,
                    showDeliveryAddress:
                        !initialFormState.delivery_address_1 ||
                        !initialFormState.delivery_postal_code,
                });
            }
        }
        setShowAdditionalFields(defaultShowAdditionalFields);
    }, [formValues]);

    const {
        valueLookup: activityStatusLookup,
        valueOptions: activityStatusOptions,
    } = getOptionsAndLookupForSelectInputPolarisV3<ActivityStatusItemData>(
        activityStatusList,
        (activityStatus: ActivityStatusItemData) => ({
            label: activityStatus.activity_status,
            value: activityStatus.pk as string,
        }),
    );

    const {
        valueLookup: activityModalityLookup,
        valueOptions: activityModalityOptions,
    } = getOptionsAndLookupForSelectInputPolarisV3<ActivityModalityItemData>(
        activityModalityList,
        (activityModality: ActivityModalityItemData) => ({
            label: activityModality.activity_modality,
            value: activityModality.pk as string,
        }),
    );

    return (
        <>
            <Container
                data-testid="EditBaseDetailsFormSection"
                header={<Header variant="h2">Activity details</Header>}
            >
                <SpaceBetween direction="vertical" size="l">
                    <FormField
                        label="Activity name"
                        errorText={errors?.activity_name}
                    >
                        <Input
                            value={formValues.activity_name}
                            placeholder="Enter an activity name"
                            onChange={(e) =>
                                handleFieldEvent({
                                    activity_name: e.detail.value,
                                })
                            }
                            data-testid={`EditActivityName`}
                        />
                    </FormField>
                    <FormField label="Activity type">
                        {formValues.activity_type}
                    </FormField>
                    <FormField label="Program">{formValues.program}</FormField>

                    <FormField label="Provider">
                        {formValues.provider}
                    </FormField>
                    <FormField
                        errorText={errors?.activity_status}
                        label="Activity status"
                    >
                        <Select
                            className={
                                errors?.activity_status && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                isActivityStatusListLoading
                                    ? 'Loading activity statuses'
                                    : 'Select an activity status'
                            }
                            options={activityStatusOptions}
                            selectedOption={
                                activityStatusLookup[formValues.activity_status]
                            }
                            onChange={(e) => {
                                handleFieldEvent({
                                    activity_status:
                                        e.detail.selectedOption.label,
                                });
                            }}
                            loadingText="Loading activity statuses"
                            errorText="An error occurred while loading activity statuses"
                            empty="No activity statuses found"
                            statusType={getStatusType(
                                isActivityStatusListLoading,
                                isActivityStatusListLoaded,
                                activityStatusListError,
                            )}
                            disabled={!isActivityStatusListLoaded}
                            data-testid={`EditBaseDetailsActivityStatus`}
                        />
                    </FormField>
                    {showAdditionalFields?.showDeliveryAddress && (
                        <DeliveryAddressSection
                            {...{
                                handleFieldEvent,
                                formValues,
                                errors,
                            }}
                        />
                    )}

                    {showAdditionalFields?.showNumberOfStudentsAttended && (
                        <StudentCountSection
                            {...{
                                handleFieldEvent,
                                formValues,
                                errors,
                            }}
                        />
                    )}

                    {showAdditionalFields?.showLMSType && (
                        <SelectLMSType
                            {...{
                                handleFieldEvent,
                                formValues: {
                                    activity_modality:
                                        formValues.activity_modality,
                                    lms_type: formValues.lms_type,
                                    v_ilt_type: formValues?.v_ilt_type,
                                },
                                errors: {
                                    activity_modality: errors.activity_modality,
                                    lms_type: errors.lms_type,
                                    v_ilt_type: errors.v_ilt_type,
                                },
                            }}
                        />
                    )}

                    {showAdditionalFields?.showVILTType && (
                        <SelectVILTType
                            {...{
                                handleFieldEvent,
                                formValues: {
                                    lms_type: formValues.lms_type,
                                    v_ilt_type: formValues?.v_ilt_type,
                                },
                                errors: {
                                    lms_type: errors.lms_type,
                                    v_ilt_type: errors.v_ilt_type,
                                },
                            }}
                        />
                    )}

                    {showAdditionalFields?.showClassSize && (
                        <ClassSizeInput
                            {...{
                                setClassSize: handleFieldEvent,
                                errors,
                                classSize: formValues.class_size,
                            }}
                        />
                    )}

                    {isPartnerInitiativeVisible && (
                        <PartnerInitiativeSection
                            {...{
                                handleFieldEvent,
                                formValues,
                                errors,
                            }}
                        />
                    )}

                    <AudienceListSelect
                        {...{
                            handleFieldEvent,
                            formValues: {
                                activity_audience: formValues.activity_audience,
                                activity_modality: formValues.activity_modality,
                                program: formValues.program,
                            },
                            errors,
                        }}
                    />

                    <FormField
                        errorText={errors?.activity_modality}
                        label="Modality"
                    >
                        <Select
                            className={
                                errors?.activity_modality && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                isActivityModalityListLoading
                                    ? 'Loading modalities'
                                    : 'Select a modality'
                            }
                            options={activityModalityOptions}
                            selectedOption={
                                activityModalityLookup[
                                    formValues.activity_modality
                                ]
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    activity_modality:
                                        e.detail.selectedOption.label,
                                })
                            }
                            loadingText="Loading modalities"
                            errorText="An error occurred while loading modalities"
                            empty="No modalities found"
                            statusType={getStatusType(
                                isActivityModalityListLoading,
                                isActivityModalityListLoaded,
                                activityModalityListError,
                            )}
                            disabled={!isActivityModalityListLoaded}
                            data-testid={`EditBaseDetailsActivityModality`}
                        />
                    </FormField>
                </SpaceBetween>
            </Container>
        </>
    );
};

export default EditBaseDetailsFormSection;

import { PayloadAction } from '@reduxjs/toolkit';
import { BusinessDataItemData } from '../interfaces/businessDataItem';
import {
    ExtendedBusinessDataStoreState,
    BusinessDataStoreState,
} from '../interfaces/businessDataStoreState';
import { BusinessDataApiQueryParams } from '../interfaces/queryParams';

export const BusinessDataStoreInitialState = {
    keysList: [],
    entities: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    selectedItemKey: null,
    count: 0,
};

export const BusinessDataQueryParamsStoreInitialState = {
    active: null,
    from: 0,
    size: 0,
    searchText: null,
    pagesCount: 0,
    currentPageIndex: 0,
};

export const ExtendedBusinessDataStoreInitialState = {
    ...BusinessDataStoreInitialState,
    ...BusinessDataQueryParamsStoreInitialState,
};

export const getActiveBusinessDataValues = <T extends BusinessDataItemData>(
    keysList: Array<string>,
    entities: {
        [key: string]: T;
    },
) =>
    keysList.reduce((acc, businessDataName) => {
        const businessData = entities[businessDataName];
        if (businessData.active) {
            acc.push(businessData);
        }
        return acc;
    }, [] as Array<T>);

export const resetBasicBusinessDataStoreState = <T>(
    state: BusinessDataStoreState<T> | ExtendedBusinessDataStoreState<T>,
) => {
    state.keysList = BusinessDataStoreInitialState.keysList;
    state.entities = BusinessDataStoreInitialState.entities;
    state.isLoading = BusinessDataStoreInitialState.isLoading;
    state.isLoaded = BusinessDataStoreInitialState.isLoaded;
    state.error = BusinessDataStoreInitialState.error;
    state.selectedItemKey = BusinessDataStoreInitialState.selectedItemKey;
    state.count = BusinessDataStoreInitialState.count;
};

export const resetBusinessDataQueryParamsStoreState = <T>(
    state: ExtendedBusinessDataStoreState<T>,
) => {
    state.active = ExtendedBusinessDataStoreInitialState.active;
    state.from = ExtendedBusinessDataStoreInitialState.from;
    state.size = ExtendedBusinessDataStoreInitialState.size;
    state.searchText = ExtendedBusinessDataStoreInitialState.searchText;
    state.pagesCount = ExtendedBusinessDataStoreInitialState.pagesCount;
    state.currentPageIndex =
        ExtendedBusinessDataStoreInitialState.currentPageIndex;
};

export const resetExtendedBusinessDataStoreState = <T>(
    state: ExtendedBusinessDataStoreState<T>,
) => {
    resetBasicBusinessDataStoreState(state);
    resetBusinessDataQueryParamsStoreState(state);
};

export const initializeBusinessDataListQueryParamsStoreState = <T>(
    state: ExtendedBusinessDataStoreState<T>,
    action: PayloadAction<{
        active: BusinessDataApiQueryParams.Active;
        size: number;
    }>,
) => {
    const { active, size } = action.payload;

    state.searchText = '';
    state.active = active;
    state.from = 0;
    state.size = size;
    state.pagesCount = 0;
    state.currentPageIndex = 1;
};

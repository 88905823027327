import React, { createContext, useState } from 'react';
import { Roles } from '../constants/auth';

export interface UseAuthorizationProps {
    roles: Roles[];
    setRoles: React.Dispatch<React.SetStateAction<Roles[]>>;
}

const AuthorizationContext = createContext<UseAuthorizationProps | undefined>(
    undefined,
);

export const AuthorizationProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const [roles, setRoles] = useState<Roles[]>([]);

    return (
        <AuthorizationContext.Provider value={{ roles, setRoles }}>
            {children}
        </AuthorizationContext.Provider>
    );
};

export default AuthorizationContext;

import 'regenerator-runtime';
import { Workbook } from 'exceljs';
import React, { useState } from 'react';
import {
    Box,
    Button,
    DateRangePicker,
    DateRangePickerProps,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { dateRangeFilterI18nStrings } from '../../../../common/utils/date-range-picker';
import { ExcelUtil } from '../../../../common/utils/excel';
import dayjs from 'dayjs';
import { getValidRangeFunctionWithMaxDays } from '../../../common/getValidRangeFunctionWithMaxDays';

export interface TemplateExportProps {
    showTemplateExportModal: boolean;
    setShowTemplateExportModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TemplateExportModal = ({
    showTemplateExportModal,
    setShowTemplateExportModal,
}: TemplateExportProps) => {
    const [dateRangeFilter, setDateRangeFilter] = useState(null);

    const generateWorksheetDates = (worksheet, startDate, endDate) => {
        while (startDate <= endDate) {
            let newDate = new Date(startDate);
            worksheet.addRow([dayjs(newDate.getTime()).format('MM/DD/YYYY')]);
            startDate.setDate(startDate.getDate() + 1);
        }
    };

    const generateTemplate = async (dateRangeFilter: any) => {
        const workbook = new Workbook();
        const excelUtil = new ExcelUtil(workbook);
        const worksheet = excelUtil.workbook.addWorksheet('Sheet1');
        worksheet.columns = [
            { header: 'Date (mm/dd/yyyy)' },
            { header: 'email@company.com' },
        ];

        if (dateRangeFilter.type === 'relative') {
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            let offsets: any = {
                day: 0,
                week: 0,
                month: 0,
                year: 0,
            };

            offsets[dateRangeFilter.unit] = dateRangeFilter.amount;

            let endDate = new Date(
                currentDate.getFullYear() + offsets.year,
                currentDate.getMonth() + offsets.month,
                currentDate.getDate() + offsets.week * 7 + offsets.day,
            );

            generateWorksheetDates(worksheet, currentDate, endDate);
        } else {
            if (dateRangeFilter.type === 'absolute') {
                const startDate = new Date(dateRangeFilter.startDate);
                const endDate = new Date(dateRangeFilter.endDate);
                generateWorksheetDates(worksheet, startDate, endDate);
            }
        }
        excelUtil.writeWorkbook();
    };

    const handleDateSelected = (event: any) => {
        let selectedDateRange = event.detail?.value;
        setDateRangeFilter(selectedDateRange);
    };

    const handleGenerateTemplate = () => {
        generateTemplate(dateRangeFilter);
        setShowTemplateExportModal(false);
        setDateRangeFilter(null);
    };

    return (
        <Modal
            size={'medium'}
            visible={showTemplateExportModal}
            onDismiss={() => setShowTemplateExportModal(false)}
            header={'Select a date range'}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Box float="right">
                            <Button
                                onClick={() =>
                                    setShowTemplateExportModal(false)
                                }
                            >
                                Close
                            </Button>
                        </Box>
                        <Button
                            variant="primary"
                            disabled={!dateRangeFilter}
                            onClick={() => handleGenerateTemplate()}
                        >
                            Generate template
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Box variant="p">
                Select a date that will be used to generate an excel file to
                update freelancer availability.
            </Box>
            <DateRangePicker
                data-testid="ActivityListDateRangePicker"
                i18nStrings={dateRangeFilterI18nStrings}
                value={dateRangeFilter}
                onChange={handleDateSelected}
                placeholder="Filter by a date and time range"
                className="date-range-filter"
                isValidRange={getValidRangeFunctionWithMaxDays({
                    maxAllowedDays: 178,
                })}
                timeInputFormat={`hh:mm`}
                relativeOptions={[
                    {
                        type: 'relative',
                        amount: 6,
                        unit: 'month',
                        key: 'next-six-months',
                    },
                ]}
            />
        </Modal>
    );
};

export default TemplateExportModal;

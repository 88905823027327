import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateCostTypePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/costTypePayload';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { CostTypesResponseData } from '../../interfaces/businessDataResponse/costTypesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const COST_TYPES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/cost-types`;

/**
 * Method to request the full list of cost types
 * @param {BusinessDataApiQueryParams.GetCostTypes} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getCostTypes = (
    queryParams?: BusinessDataApiQueryParams.GetCostTypes,
): Promise<HandleRequestData<CostTypesResponseData>> => {
    let path = `${COST_TYPES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<CostTypesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an cost type.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateCostType = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${COST_TYPES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

/**
 * Method to add an cost type.
 * @param {string} costTypeName - the name of the new cost type
 * @param {boolean} active - the status of the new cost type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addCostType = (
    costTypeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateCostTypePayloadData = {
        cost_type: costTypeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${COST_TYPES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const costTypesApi = {
    getCostTypes,
    updateCostType,
    addCostType,
};

export default costTypesApi;

import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Spinner } from '@amzn/awsui-components-react-v3';

import {
    getSelectedActivity,
    selectError as selectActivityError,
    selectIsLoading as selectIsActivityLoading,
    selectSelectedActivity,
} from '../../../store/slices/selectedActivitySlice';
import { ActivityData } from '../../../interfaces/activity';
import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';
import EditOperationsAndRegistrationDetailsForm from './EditOperationsAndRegistrationDetailsForm';

const EditOperationsAndRegistrationDetails = () => {
    const match = useRouteMatch<{ id: string }>();
    const error: any = useSelector(selectActivityError);
    const isLoading: boolean = useSelector(selectIsActivityLoading);
    const activity: ActivityData | undefined = useSelector(
        selectSelectedActivity,
    )?.activity;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSelectedActivity(match.params.id));
    }, [dispatch, match.params.id]);

    if (error) {
        return (
            <Alert
                data-testid="EditOperationsAndRegistrationDetailsError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (!isLoading && !!activity) {
        return (
            <EditOperationsAndRegistrationDetailsForm
                initialFormState={activity}
            />
        );
    } else {
        return (
            <Spinner data-testid="EditOperationsAndRegistrationDetailsSpinner" />
        );
    }
};

export default EditOperationsAndRegistrationDetails;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectSelectedInstructor,
    setRequireUpdate,
    updateSelectedInstructor,
} from '../../../../store/slices/selectedInstructorSlice';
import { useRouteMatch } from 'react-router-dom';
import { NotesProps } from '../../../../../common/interfaces/notesProps';
import { InstructorProfileData } from '../../../../interfaces/instructorProfile';
import { useNotifications } from '../../../../../common/context/grimsbyNotifications';
import Notes from '../../../../../common/components/Notes/Notes';
import { Actions } from '../../../../../common/constants/auth';
import { InstructorUpdateError } from '../../../../../common/constants/flashContent';

export const DEFAULT_NOTES = 'There are no notes.';

const NotesTab = () => {
    const { addNotification } = useNotifications();
    const instructor = useSelector(selectSelectedInstructor);
    const match = useRouteMatch<{ id: string }>();
    const dispatch = useDispatch();

    if (instructor) {
        const profile = instructor.instructor;
        const notesProps: NotesProps = {
            action: Actions.INSTRUCTOR_MODIFY,
            testId: 'Instructor',
            label: 'Notes',
            initialValue: profile.notes,
            emptyNotesValue: 'There are no notes.',
            getNotesClickHandler: (notes) => async () => {
                const isSuccessful = await dispatch<any>(
                    updateSelectedInstructor(match.params.id, {
                        notes: notes,
                    } as Partial<InstructorProfileData>),
                );
                if (isSuccessful) {
                    dispatch(setRequireUpdate(true));
                }
                addNotification({
                    id: `edit-instructor-notes-${Date.now()}`,
                    ...(isSuccessful
                        ? {
                              type: 'success',
                              content:
                                  'Instructor notes have been successfully updated.',
                          }
                        : {
                              type: 'error',
                              content: <InstructorUpdateError />,
                          }),
                });
            },
        };

        // The "instructor" must not be empty if its parent component is successfully loaded.
        // Having this logic is to comply with the definition of "instructor" from the global
        // state management, which can be "null".
        return <Notes {...notesProps} />;
    } else {
        return null;
    }
};

export default NotesTab;

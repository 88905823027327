import React from 'react';

import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    Alert,
    ColumnLayout,
} from '@amzn/awsui-components-react-v3';
import { unix as dayjsUnix } from 'dayjs';

interface ValidateBackToBackActivitiesModalProps {
    visible: boolean;
    onConfirm: any;
    onCancel: any;
    instructorConflict: any;
}

const CONFIRM_ASSIGNMENT_TEXT = 'Confirm assignment';

const ValidateBackToBackActivitiesModal = ({
    visible,
    onConfirm,
    onCancel,
    instructorConflict,
}: ValidateBackToBackActivitiesModalProps) => {
    const getDisplayDate = (displayTimestamp: number | null | undefined) => {
        if (!displayTimestamp) {
            return '';
        }

        return dayjsUnix(displayTimestamp).format('dddd MMMM D');
    };

    const getDeliveryDestination = () => {
        const deliveryCity = instructorConflict?.activity?.delivery_city;
        const deliveryState = instructorConflict?.activity?.delivery_state;
        const deliveryCountry = instructorConflict?.activity?.delivery_country;
        return [deliveryCity, deliveryState, deliveryCountry]
            .filter(Boolean)
            .join(', ');
    };

    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmConflictModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmConflictCancel`}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmConflictConfirm`}
                            onClick={() => {
                                onConfirm();
                            }}
                        >
                            Yes, assign instructor
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={CONFIRM_ASSIGNMENT_TEXT}
        >
            <Alert
                onDismiss={() => {}}
                visible={true}
                dismissAriaLabel="Close alert"
                type="warning"
            >
                {`${instructorConflict?.instructor?.full_name}`} has an activity
                that may conflict with this one. Continue assigning them?
            </Alert>
            <ColumnLayout>
                <div
                    data-awsui-column-layout-root="true"
                    className="reassign-adjacent-activity"
                >
                    <span className="reassign-adjacent-activity-title">
                        Adjacent activity
                    </span>
                    <span className="reassign-adjacent-activity-name">
                        <a
                            href={`/activities/${instructorConflict?.activity?.pk}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="reassign-adjacent-activity-name"
                            data-testid="reassign-adjacent-activity-link"
                        >
                            {instructorConflict?.activity?.activity_name}
                        </a>
                    </span>
                    <span>
                        {getDisplayDate(
                            instructorConflict?.activity?.delivery_sessions[0]
                                ?.start_timestamp,
                        )}{' '}
                        -{' '}
                        {getDisplayDate(
                            instructorConflict?.activity?.delivery_sessions[
                                instructorConflict?.activity.delivery_sessions
                                    .length - 1
                            ]?.start_timestamp,
                        )}
                    </span>
                    <span>{getDeliveryDestination()}</span>
                </div>
            </ColumnLayout>
        </Modal>
    );
};

export default ValidateBackToBackActivitiesModal;

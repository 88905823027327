import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InstructorCourseStatusesResponseData } from '../../interfaces/businessDataResponse/instructorCourseStatusesResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { BusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { InstructorCourseStatusItemData } from '../../interfaces/businessDataItem/instructorCourseStatusItem';
import parseBoolean from '../../utils/parseBoolean';
import {
    BusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';

/**
 * instructorCourseStatusesSlice manages all instructorCourseStatuses state, and contains instructorCourseStatuses actions as well as instructorCourseStatuses state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const instructorCourseStatusesSlice = createSlice({
    name: 'instructorCourseStatuses',
    initialState: {
        ...BusinessDataStoreInitialState,
    } as BusinessDataStoreState<InstructorCourseStatusItemData>,
    reducers: {
        setInstructorCourseStatusesList: (
            state,
            action: PayloadAction<InstructorCourseStatusItemData[]>,
        ) => {
            const byInstructorCourseStatusName = action.payload.reduce(
                (
                    byInstructorCourseStatusName: {
                        [key: string]: InstructorCourseStatusItemData;
                    },
                    instructorCourseStatus: InstructorCourseStatusItemData,
                ) => {
                    byInstructorCourseStatusName[
                        instructorCourseStatus.instructor_course_status
                    ] = {
                        ...instructorCourseStatus,
                        active: parseBoolean(instructorCourseStatus.active),
                    };
                    return byInstructorCourseStatusName;
                },
                {},
            );
            state.entities = byInstructorCourseStatusName;
            state.keysList = Object.keys(byInstructorCourseStatusName);
        },
        setInstructorCourseStatus: (
            state,
            action: PayloadAction<InstructorCourseStatusItemData>,
        ) => {
            // this reducer may be used when adding a new domain skill or updating an existing one.
            // only add to keysList and update count if adding a new domain skill
            if (!state.entities[action.payload.instructor_course_status]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.instructor_course_status,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.instructor_course_status] =
                action.payload;
        },
        setSelectedInstructorCourseStatus: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        resetInstructorCourseStatusesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setInstructorCourseStatusesList,
    setInstructorCourseStatus,
    setSelectedInstructorCourseStatus,
    setError,
    setCount,
    resetInstructorCourseStatusesSlice,
} = instructorCourseStatusesSlice.actions;

const handleInstructorCourseStatusesListRequest =
    () => async (dispatch: AppDispatch) => {
        try {
            const {
                result: { INSTRUCTOR_COURSE_STATUS, total_count },
            }: HandleRequestData<InstructorCourseStatusesResponseData> =
                await businessDataApi.getInstructorCourseStatuses({
                    active: 'all',
                });
            dispatch(setInstructorCourseStatusesList(INSTRUCTOR_COURSE_STATUS));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(
                setError(
                    error?.message || 'getInstructorCourseStatuses API error',
                ),
            );
        }
    };

export const getInstructorCourseStatusesList =
    () => async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleInstructorCourseStatusesListRequest());
        if (!state.instructorCourseStatuses.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };

/**
 * action to update instructor course teaching status
 * before update api call, fetch instructor course teaching status types and prepare payload containing only the changed fields
 * after update api call, fetch instructor course teaching status types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateInstructorCourseStatus =
    (data: InstructorCourseStatusItemData) =>
    async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new instructor course teaching status: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateInstructorCourseStatus(
                data.pk,
                data.active,
            );
            await dispatch(handleInstructorCourseStatusesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setInstructorCourseStatus({
                    ...state.instructorCourseStatuses.entities[
                        data.instructor_course_status
                    ],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };

export const addInstructorCourseStatus =
    (active = true, instructorCourseStatusName: string) =>
    async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addInstructorCourseStatus(
                instructorCourseStatusName,
                active,
            );
            // now refresh the keysList
            await dispatch(handleInstructorCourseStatusesListRequest());
            if (
                !state.instructorCourseStatuses?.entities[
                    instructorCourseStatusName
                ]
            ) {
                // shove the item into the keysList, but only if it's not there already
                const newInstructorCourseStatusData: InstructorCourseStatusItemData =
                    {
                        instructor_course_status: instructorCourseStatusName,
                        active: active,
                    };
                dispatch(
                    setInstructorCourseStatus(newInstructorCourseStatusData),
                );
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };

export const selectAllInstructorCourseStatuses = (state: GlobalState) =>
    state.instructorCourseStatuses.keysList.map(
        (instructorCourseStatusName) =>
            state.instructorCourseStatuses.entities[instructorCourseStatusName],
    );

export const selectAllActiveInstructorCourseStatuses = (
    state: GlobalState,
): Array<InstructorCourseStatusItemData> => {
    return getActiveBusinessDataValues(
        state.instructorCourseStatuses.keysList,
        state.instructorCourseStatuses.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.instructorCourseStatuses.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.instructorCourseStatuses.isLoaded;

export const selectSelectedInstructorCourseStatus = (state: GlobalState) =>
    state?.instructorCourseStatuses?.selectedItemKey
        ? state?.instructorCourseStatuses?.entities[
              state?.instructorCourseStatuses?.selectedItemKey
          ]
        : null;

export const selectError = (state: GlobalState) =>
    state.instructorCourseStatuses.error;

export const selectCount = (state: GlobalState) =>
    state.instructorCourseStatuses.count;

export default instructorCourseStatusesSlice.reducer;

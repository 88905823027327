import React, { useCallback, useState, useEffect } from 'react';
import {
    ActivityData,
    APNPartnerPrivateCustomer,
} from '../../../../interfaces/activity';
import {
    getSelectedActivity,
    selectIsLoading,
    selectSelectedActivity,
    updateSelectedActivity,
} from '../../../../store/slices/selectedActivitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';
import { useNotifications } from '../../../../../common/context/grimsbyNotifications';

import { APNCustomerFormSection } from './APNCustomerFormSection';
import useFormValidation from '../../../../../common/utils/formValidation';
import { saveCustomerValidationConfig } from '../../../../services/activity-service';
import { DEFAULT_LANGUAGE } from '../../../../constants';

const APNCustomerCreateForm = () => {
    const isLoading = useSelector(selectIsLoading);
    const match = useRouteMatch<{ id: string }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const activity: ActivityData | undefined = useSelector(
        selectSelectedActivity,
    )?.activity;
    const { addNotification } = useNotifications();
    const initialFormState = {
        customer_name: '',
        internal_poc: null,
        delivery_contact_name: null,
        delivery_contact_email: null,
        delivery_contact_phone_number: null,
        sfdc_opportunity_id: null,
        customer_communication_language: DEFAULT_LANGUAGE,
        email_sent: false,
        notify_customer_date: null,
        most_recent_email_date: null,
        do_not_group_email: false,
        send_to_customer_poc: true,
    } as APNPartnerPrivateCustomer;

    const [formValues, setFormValues] =
        useState<APNPartnerPrivateCustomer>(initialFormState);

    const { errors, validateForm } =
        useFormValidation<APNPartnerPrivateCustomer>();

    const handleFieldEvent = useCallback(
        (changes: Partial<APNPartnerPrivateCustomer>) => {
            setFormValues((values) => ({
                ...values,
                ...changes,
            }));
        },
        [],
    );

    useEffect(() => {
        dispatch(getSelectedActivity(match.params.id));
    }, [dispatch, match.params.id]);

    const handleCreateCustomer = async () => {
        if (!!activity) {
            const invalid = validateForm(
                formValues,
                saveCustomerValidationConfig,
            );

            if (!invalid) {
                let updatedCustomers: Array<APNPartnerPrivateCustomer> = [];

                const newCustomer: APNPartnerPrivateCustomer = {
                    ...formValues,
                };

                if (!activity.customers) {
                    updatedCustomers = [newCustomer];
                } else {
                    updatedCustomers = [
                        ...activity.customers,
                        ...[newCustomer],
                    ];
                }

                const isSuccessful = await dispatch<any>(
                    updateSelectedActivity(match.params.id, {
                        ...activity,
                        customers: updatedCustomers,
                    }),
                );

                addNotification({
                    id: `edit-activity-customer-${Date.now()}`,
                    ...(isSuccessful
                        ? {
                              type: 'success',
                              content: 'The customer has been saved.',
                          }
                        : {
                              type: 'error',
                              content:
                                  'An error occurred while saving the customer.',
                          }),
                });

                if (isSuccessful) {
                    history.push({
                        pathname: `/activities/${match.params.id}`,
                        search: 'tabId=customers',
                    });
                }
            }
        }
        return;
    };

    return (
        <section data-testid="CustomerCreateForm">
            <Form
                header="Add customer"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-customer-cancel"
                                data-testid="CustomerCreateFormCancel"
                                onClick={() => {
                                    history.push({
                                        pathname: `/activities/${match.params.id}`,
                                        search: 'tabId=customers',
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-customer-save"
                                data-testid="CustomerCreateFormAddButton"
                                loading={isLoading}
                                onClick={() => {
                                    handleCreateCustomer();
                                }}
                            >
                                {`${isLoading ? 'Saving' : 'Save'}`}
                            </Button>
                        </div>
                    </div>
                }
            >
                <APNCustomerFormSection
                    formValues={formValues}
                    errors={errors}
                    handleFieldEvent={handleFieldEvent}
                    activity={activity}
                />
            </Form>
        </section>
    );
};

export default APNCustomerCreateForm;

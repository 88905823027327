import { useState, useEffect } from 'react';
import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';
import handleLocalStorageSetItem from '../utils/handleLocalStorageSetItem';
import { InstructorPropertyFilterKeys } from '../../smt/enums/propertyFilterKeys';

const DEFAULT_QUERY: PropertyFilterProps.Query = {
    tokens: [
        {
            propertyKey: InstructorPropertyFilterKeys.InstructorStatus,
            operator: '=',
            value: 'Authorized',
        },
    ],
    operation: 'and',
};

type UseQueryFilterOptions = {
    setCurrentPageIndex: (pageIndex: number) => void;
    setFrom: (from: number) => void;
    dispatch: React.Dispatch<any>;
    storageKey?: string;
    defaultQuery?: PropertyFilterProps.Query;
};

export const useInstructorQueryFilter = ({
    setCurrentPageIndex,
    setFrom,
    dispatch,
    storageKey,
    defaultQuery = DEFAULT_QUERY,
}: UseQueryFilterOptions) => {
    const getInitialQuery = () => {
        if (storageKey) {
            const storedQuery = localStorage.getItem(storageKey);
            return storedQuery ? JSON.parse(storedQuery) : defaultQuery;
        }
        return defaultQuery;
    };

    const removeUndefinedPropertyKey = (token: PropertyFilterProps.Token) => {
        const { propertyKey, ...rest } = token;
        return propertyKey ? token : rest;
    };

    const [query, setQueryState] =
        useState<PropertyFilterProps.Query>(getInitialQuery());

    useEffect(() => {
        if (storageKey) {
            const storedFilter = localStorage.getItem(storageKey);
            if (storedFilter) {
                setQueryState(JSON.parse(storedFilter));
            }
        }
    }, [storageKey]);

    useEffect(() => {
        if (storageKey) {
            if (query.tokens.length > 0) {
                handleLocalStorageSetItem(storageKey, JSON.stringify(query));
            } else {
                localStorage.removeItem(storageKey);
            }
        }
    }, [query, storageKey]);

    const setQuery = (query: PropertyFilterProps.Query) => {
        const tokensWithoutUndefinedKeys = query.tokens.map(
            removeUndefinedPropertyKey,
        );

        const cleanedQuery: PropertyFilterProps.Query = {
            operation: query.operation,
            tokens: tokensWithoutUndefinedKeys,
        };

        setQueryState(cleanedQuery);
    };

    const handleQueryChange = (newQuery: PropertyFilterProps.Query) => {
        dispatch(setCurrentPageIndex(1));
        dispatch(setFrom(0));
        setQuery({ ...newQuery, tokens: [...newQuery.tokens] });
    };

    return { query, setQuery, handleQueryChange };
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ActivityGroupForm } from '../components/ActivityGroupForm';
import { activityGroupSlice } from '../../../../store/slices/activityGroupSlice';

export const ActivityGroupCreate = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(activityGroupSlice.actions.clearActivityGroup());
    }, []);

    return <ActivityGroupForm mode="CREATE"></ActivityGroupForm>;
};

export default ActivityGroupCreate;

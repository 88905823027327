export enum Stage {
    SAM = 'SAM',
    BETA = 'BETA',
    GAMMA = 'GAMMA',
    PROD = 'PROD',
}

export enum ViasNamespaceRoleProps {
    SAM = 'aws/grimsby/dev:roles',
    BETA = 'aws/grimsby/beta:roles',
    GAMMA = 'aws/grimsby/gamma:roles',
    PROD = 'aws/grimsby/prod:roles',
}

export interface GandalfJwt {
    [ViasNamespaceRoleProps.SAM]?: Stage.SAM;
    [ViasNamespaceRoleProps.BETA]?: Stage.BETA;
    [ViasNamespaceRoleProps.GAMMA]?: Stage.GAMMA;
    [ViasNamespaceRoleProps.PROD]?: Stage.PROD;
}

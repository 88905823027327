// third-party
import React, { ReactElement, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    Box,
    SpaceBetween,
    Table,
    Button,
    Link,
    Icon,
    Popover,
} from '@amzn/awsui-components-react-v3';

// common
import {
    EMPTY_STRING,
    SectionContentType,
} from '../../../../../common/constants/grimsby';
import { checkFeature } from '../../../../../common/utils/featureFlag';
import { formatString } from '../../../../../common/utils/formatString';
import { formatStatusHelper } from '../../../../../common/utils/formatHelper';
import TableHeader from '../../../../../common/components/TableHeader/TableHeader';
import Can from '../../../../../common/components/Can';
import { Actions } from '../../../../../common/constants/auth';
import { useNotifications } from '../../../../../common/context/grimsbyNotifications';

// sections
import Section, {
    ColumnContentData,
    GenericContentData,
    HeaderData,
    KeyValueData,
    SectionProps,
} from '../../../../../common/components/Section/Section';

// slices
import { selectFeatures } from '../../../../../common/store/slices/featureSlice';
import { updateSelectedActivity } from '../../../../store/slices/selectedActivitySlice';

// interfaces
import {
    ActivityData,
    APNPartnerPrivateCustomer,
    CommercialPrivateCustomer,
    Customer,
    LineItem,
    Revenue,
} from '../../../../interfaces/activity';

// activity common
import {
    ActivityType,
    AudienceType,
    currencyFormatter,
    formatGrimsbyDate,
    formatNumber,
    isCommercialPrivateCustomer,
    isPartnerPrivateCustomer,
    isPublicCustomer,
} from '../../Common/Common';

// modals
import RemoveSFDCCustomerModal from './RemoveSFDCCustomerModal';
import DeleteCustomerModal from './DeleteCustomerModal';

// Customers tab CSS
import './CustomersTab.scss';

// helpers

enum TOFStatus {
    NotStarted = 'Not Started',
    WithCustomer = 'With Customer',
    SignatureReceived = 'Signature Received',
    Expired = 'Expired',
}

const getTOFStatusComponent = formatStatusHelper([
    {
        status: TOFStatus.NotStarted,
        statusClass: 'awsui-util-status-inactive',
        iconClass: 'status-stopped',
        label: TOFStatus.NotStarted,
    },
    {
        status: TOFStatus.WithCustomer,
        statusClass: 'awsui-util-status-inactive',
        iconClass: 'status-pending',
        label: TOFStatus.WithCustomer,
    },
    {
        status: TOFStatus.SignatureReceived,
        statusClass: 'awsui-util-status-positive',
        iconClass: 'status-positive',
        label: TOFStatus.SignatureReceived,
    },
    {
        status: TOFStatus.Expired,
        statusClass: 'awsui-util-status-negative',
        iconClass: 'status-negative',
        label: TOFStatus.Expired,
    },
]);

const isCustomerSFDCSynced = (
    customer: Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer,
) => !!customer.sfdc_scheduling_request_id;

enum EmailStatus {
    ToBeSent = 'To be sent',
    Sent = 'Sent',
    NothingToSend = 'Not enabled',
}

const getEmailStatusComponent = formatStatusHelper([
    {
        status: EmailStatus.ToBeSent,
        statusClass: 'awsui-util-status-inactive',
        iconClass: 'status-pending',
        label: EmailStatus.ToBeSent,
    },
    {
        status: EmailStatus.Sent,
        statusClass: 'awsui-util-status-positive',
        iconClass: 'status-positive',
        label: EmailStatus.Sent,
    },
    {
        status: EmailStatus.NothingToSend,
        statusClass: 'awsui-util-status-inactive',
        iconClass: 'status-stopped',
        label: EmailStatus.NothingToSend,
    },
]);

const isEmailConfigurationSet = (
    customer: Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer,
) => {
    return customer.most_recent_email_date || customer.notify_customer_date;
};

const customerEmailStatus = (
    customer: Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer,
) => {
    if (isEmailConfigurationSet(customer)) {
        return customer.email_sent ? EmailStatus.Sent : EmailStatus.ToBeSent;
    }
    return EmailStatus.NothingToSend;
};

const emptyRevenueTableComponent = (
    <div className="awsui-util-t-c awsui-util-status-inactive">
        <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No revenue items</b>
        </div>
        <p className="awsui-util-mb-s">Revenue items will display here.</p>
    </div>
);

const emptyLineItemTableComponent = (
    <div className="awsui-util-t-c awsui-util-status-inactive">
        <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No line items</b>
        </div>
        <p className="awsui-util-mb-s">Line items will display here.</p>
    </div>
);

const getCustomerEditButton = (
    index: number,
    onClick: () => void,
    onRemove: () => void,
): Pick<HeaderData, 'buttons'> => ({
    buttons: (
        <Can
            perform={Actions.ACTIVITY_MODIFY}
            yes={() => (
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid={`DeleteCustomer${index}`}
                            onClick={onRemove}
                        >
                            Delete
                        </Button>
                        <Button
                            data-testid={`EditCustomer${index}`}
                            onClick={onClick}
                        >
                            Edit
                        </Button>
                    </SpaceBetween>
                </Box>
            )}
            no={() => null}
        />
    ),
});

const revenueColumnDefinitions = (customer: CommercialPrivateCustomer) => {
    return [
        {
            id: 'revenueType',
            header: 'Non-billed revenue',
            cell: (item: Revenue) => formatString(item.type),
            width: window.innerWidth / 2,
        },
        {
            id: 'amount',
            header: 'Amount',
            cell: (item: Revenue) =>
                item.amount === undefined
                    ? EMPTY_STRING
                    : currencyFormatter(
                          item.amount,
                          customer.currency ?? 'USD',
                      ),
        },
        {
            id: 'investmentRequestID',
            header: 'Investment request ID',
            cell: (item: Revenue) => formatString(item?.investment_request_id),
        },
    ];
};

const invoiceColumnDefinitions = (customer: CommercialPrivateCustomer) => {
    return [
        {
            id: 'lineItemType',
            header: 'Invoiced revenue',
            cell: (item: LineItem) => formatString(item.item_type),
            width: window.innerWidth / 2,
        },
        {
            id: 'amount',
            header: 'Amount',
            cell: (item: LineItem) =>
                item.item_amount === undefined
                    ? EMPTY_STRING
                    : currencyFormatter(
                          item.item_amount,
                          customer.currency ?? 'USD',
                      ),
        },
    ];
};

const getCustomerCommunicationProps = (
    index: number,
    customer: CommercialPrivateCustomer | APNPartnerPrivateCustomer,
    activity: ActivityData,
    isCustomerCorrespondenceEnabled: boolean,
) => {
    const fields = {
        delivery_contact_name: {
            key: 'Delivery contact name',
            value: formatString(customer.delivery_contact_name),
        },
        delivery_contact_email: {
            key: 'Delivery contact e-mail',
            value: formatString(customer.delivery_contact_email),
        },
        delivery_contact_phone_number: {
            key: 'Delivery contact phone',
            value: formatString(customer.delivery_contact_phone_number),
        },
        customer_communication_language: {
            key: 'Customer communication language',
            value: formatString(customer.customer_communication_language),
        },
        customer_notification_status: {
            key: 'Customer notification status',
            value: getEmailStatusComponent(customerEmailStatus(customer)),
        },
        most_recent_notification_date: {
            key: 'Most recent notification date',
            value: customer.most_recent_email_date
                ? formatGrimsbyDate(customer.most_recent_email_date)
                : '-',
        },
        next_notification_send_date: {
            key: 'Next notification send date',
            value: customer.notify_customer_date
                ? formatGrimsbyDate(customer.notify_customer_date)
                : '-',
        },
        group_customer_notifications: {
            key: 'Group customer notifications',
            value: isEmailConfigurationSet(customer)
                ? customer.do_not_group_email
                    ? 'No'
                    : 'Yes'
                : '-',
        },
        notifiy_customer_directly: {
            key: 'Notify customer directly',
            value: isEmailConfigurationSet(customer)
                ? customer.send_to_customer_poc === false
                    ? 'No'
                    : 'Yes'
                : '-',
        },
        accounts_payable_email: {
            key: 'Accounts payable email',
            value: formatString(customer.accounts_payable_email),
        },
    };
    return {
        testId: `CustomerCommunicationItems${index}`,
        className: 'awsui-util-mb-n',
        header: {
            label: 'Customer Communication',
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4,
            columns:
                isCustomerCorrespondenceEnabled && isClass(activity)
                    ? [
                          [
                              fields.delivery_contact_name,
                              fields.delivery_contact_email,
                              fields.delivery_contact_phone_number,
                              fields.accounts_payable_email,
                          ],
                          [
                              fields.customer_notification_status,
                              fields.most_recent_notification_date,
                              fields.next_notification_send_date,
                          ],
                          [
                              fields.group_customer_notifications,
                              fields.notifiy_customer_directly,
                          ],
                          [fields.customer_communication_language],
                      ]
                    : [
                          [fields.delivery_contact_name],
                          [
                              fields.delivery_contact_email,
                              fields.accounts_payable_email,
                          ],
                          [fields.delivery_contact_phone_number],
                          [fields.customer_communication_language],
                      ],
        },
    };
};

const isClass = (activity: ActivityData) =>
    activity?.activity_type
        ? activity.activity_type === ActivityType.Class
        : false;

// SFDC Training Briefing Details helpers

interface CustomerTrainingBriefingItem {
    key: string;
    value: string | ReactElement[] | null;
}

const getTrainingBriefingItems = (
    customer: Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer,
) => [
    {
        key: 'What the customer does?',
        value: customer.training_briefing_what_the_customer_does,
    },
    {
        key: 'Where are they in their cloud journey?',
        value: customer.training_briefing_where_are_they_in_their_cloud_journey,
    },
    {
        key: 'Motivations for using AWS?',
        value: customer.training_briefing_motivations_for_using_aws,
    },
    {
        key: 'Special needs for training delivery?',
        value: customer.training_briefing_special_needs_for_training_delivery,
    },
];

const hasTrainingBriefingItems = (
    customer: Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer,
) => getTrainingBriefingItems(customer).some((v) => v.value != null);

const formatTrainingBriefingItem = (item: CustomerTrainingBriefingItem) => {
    if (!item.value) {
        item.value = EMPTY_STRING;
    } else {
        item.value = item.value
            .toString()
            .trim()
            .split('\n')
            .map((str, ix) => (
                <p
                    key={ix}
                    className="awsui-util-pv-n customer-training-briefing-item"
                >
                    {formatString(str) || ' '}
                </p>
            ));
    }
    return item;
};

const getTrainingBriefingProps = (
    customer: Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer,
    index: number,
) => ({
    testId: `CustomerTrainingBriefingItems${index}`,
    className: 'awsui-util-mb-n',
    header: {
        label: 'Customer Training Briefing',
    },
    content: {
        type: SectionContentType.Column,
        columnsCount: 4,
        columns: getTrainingBriefingItems(customer).map((item, ix) => [
            formatTrainingBriefingItem(item),
        ]),
    },
});

// views

const getApnCustomerView = (
    activity: ActivityData,
    customer: APNPartnerPrivateCustomer,
    isCustomerCorrespondenceEnabled: boolean,
    index: number,
    onEdit: () => void,
    onRemove: () => void,
) => {
    const apnCustomerSectionProps: SectionProps = {
        testId: `ActivityDetailsApnCustomer${index}`,
        className: 'awsui-util-mb-n',
        header: {
            label: customer.customer_name,
            ...getCustomerEditButton(index, onEdit, onRemove),
            component: getCustomerHeaderComponent(customer),
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4,
            columns: [
                [
                    {
                        key: 'SFDC opportunity ID',
                        value: customer.sfdc_opportunity_id ? (
                            <Link
                                href={`https://aws-crm.my.salesforce.com/${customer.sfdc_opportunity_id}`}
                                target="_blank"
                                data-testid="sfdc-link"
                            >
                                {formatString(customer.sfdc_opportunity_id)}
                            </Link>
                        ) : (
                            EMPTY_STRING
                        ),
                    },
                    {
                        key: 'Customer notes',
                        value: formatString(customer.customer_notes),
                    },
                ],
                [
                    {
                        key: 'Internal contact person',
                        value: formatString(customer.internal_poc),
                    },
                ],
                [
                    {
                        key: 'Number of students committed',
                        value: formatNumber(
                            customer.number_of_students_committed,
                        ),
                    },
                ],
                [
                    {
                        key: 'Number of students attended',
                        value: formatNumber(
                            customer.number_of_students_attended,
                        ),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    return (
        <div className="awsui-util-mb-l" key={index}>
            <Section {...apnCustomerSectionProps} />
            <Section
                {...getCustomerCommunicationProps(
                    index,
                    customer,
                    activity,
                    isCustomerCorrespondenceEnabled,
                )}
            />
            {hasTrainingBriefingItems(customer) && (
                <Section {...getTrainingBriefingProps(customer, index)} />
            )}
        </div>
    );
};

const getCustomerHeaderComponent = (customer: any) => {
    return isCustomerSFDCSynced(customer) ? (
        <h2>
            {customer.sfdc_opportunity_id
                ? `${customer.customer_name} (${customer.sfdc_opportunity_id})`
                : customer.customer_name}
            <Popover
                dismissButton={true}
                position="top"
                size="large"
                triggerType="custom"
                content={`This customer is syncing data with SFDC.`}
            >
                <span
                    className={`awsui-util-status-positive nowrap synced-customer`}
                >
                    <Icon name={'status-positive'} />
                    &nbsp;Synced
                </span>
            </Popover>
        </h2>
    ) : null;
};

const getCommercialPrivateCustomerView = (
    activity: ActivityData,
    customer: CommercialPrivateCustomer,
    index: number,
    isCustomerCorrespondenceEnabled: boolean,
    onEdit: () => void,
    onRemove: () => void,
) => {
    const commercialPrivateCustomerSectionProps: SectionProps = {
        testId: `ActivityDetailsCommercialPrivateCustomer${index}`,
        className: 'awsui-util-mb-n',
        header: {
            label: customer.sfdc_opportunity_id
                ? `${customer.customer_name} (${customer.sfdc_opportunity_id})`
                : customer.customer_name,
            ...getCustomerEditButton(index, onEdit, onRemove),
            component: getCustomerHeaderComponent(customer),
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4,
            columns: [
                [
                    {
                        key: 'SFDC opportunity ID',
                        value: customer.sfdc_opportunity_id ? (
                            <Link
                                href={`https://aws-crm.my.salesforce.com/${customer.sfdc_opportunity_id}`}
                                target="_blank"
                                data-testid="sfdc-link"
                            >
                                {formatString(customer.sfdc_opportunity_id)}
                            </Link>
                        ) : (
                            EMPTY_STRING
                        ),
                    },
                    {
                        key: 'Customer AWS Account ID',
                        value: formatString(customer.customer_aws_account_id),
                    },
                    {
                        key: 'Customer notes',
                        value: formatString(customer.customer_notes),
                    },
                ],
                [
                    {
                        key: 'TOF status',
                        value: customer.tof_status
                            ? getTOFStatusComponent(customer.tof_status)
                            : '-',
                    },
                    {
                        key: 'TOF expiration date',
                        value: customer.tof_expiration_date
                            ? formatGrimsbyDate(customer.tof_expiration_date)
                            : '-',
                    },
                ],
                [
                    {
                        key: 'Currency',
                        value: formatString(customer.currency),
                    },
                    {
                        key: 'Pricing Model',
                        value: formatString(
                            customer.pricing_model || 'Old Pricing Model',
                        ),
                    },
                ],
                [
                    {
                        key: 'Number of students committed',
                        value: formatNumber(
                            customer.number_of_students_committed,
                        ),
                    },
                    {
                        key: 'Number of students attended',
                        value: formatNumber(
                            customer.number_of_students_attended,
                        ),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const nonBilledItems =
        customer.revenues?.filter(
            (revItem) => !['Billed', 'TnE'].includes(revItem.type),
        ) ?? [];
    const billedItems: Array<LineItem> =
        customer.revenues
            ?.filter((revItem) => ['Billed', 'TnE'].includes(revItem.type))
            .map((revItem) => {
                return {
                    item_amount: revItem.amount,
                    item_type:
                        revItem.type === 'TnE'
                            ? 'Travel & expense'
                            : activity?.activity_audience === 'Public'
                              ? 'Public ILT class'
                              : 'Private ILT class',
                };
            }) ?? [];

    return (
        <div className="awsui-util-mb-l" key={index}>
            <Section {...commercialPrivateCustomerSectionProps} />
            <Section
                {...getCustomerCommunicationProps(
                    index,
                    customer,
                    activity,
                    isCustomerCorrespondenceEnabled,
                )}
            />
            {hasTrainingBriefingItems(customer) && (
                <Section {...getTrainingBriefingProps(customer, index)} />
            )}
            <Table
                data-testid={`CommercialPrivateCustomerRevenueItemsTable${index}`}
                columnDefinitions={revenueColumnDefinitions(customer)}
                items={[
                    ...nonBilledItems,
                    nonBilledItems.reduce(
                        (p, n) => {
                            return {
                                type: 'Total',
                                amount: p.amount + n.amount,
                            };
                        },
                        {
                            type: 'Total',
                            amount: 0,
                        },
                    ),
                ]}
                header={
                    <TableHeader
                        title="Revenue Items"
                        actions={<React.Fragment />}
                        count={
                            customer.revenues?.length
                                ? customer.revenues?.length
                                : ''
                        }
                    />
                }
                empty={emptyRevenueTableComponent}
            />
            <Table
                data-testid={`CommercialPrivateCustomerInvoiceItemsTable${index}`}
                columnDefinitions={invoiceColumnDefinitions(customer)}
                items={[
                    ...billedItems,
                    billedItems.reduce(
                        (p, n) => {
                            return {
                                item_type: 'Total',
                                item_amount: p.item_amount + n.item_amount,
                            };
                        },
                        {
                            item_type: 'Total',
                            item_amount: 0,
                        },
                    ),
                ]}
                empty={emptyLineItemTableComponent}
            />
        </div>
    );
};

const getPublicCustomerView = (
    activity: ActivityData,
    customer: CommercialPrivateCustomer,
    index: number,
    onEdit: () => void,
    onRemove: () => void,
) => {
    const publicCustomerSectionProps: SectionProps = {
        testId: `ActivityDetailsPublicCustomer${index}`,
        className: 'awsui-util-mb-n',
        header: {
            label: customer.customer_name,
            ...getCustomerEditButton(index, onEdit, onRemove),
            component: getCustomerHeaderComponent(customer),
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 4,
            columns: [
                [
                    {
                        key: 'SFDC opportunity ID',
                        value: customer.sfdc_opportunity_id ? (
                            <Link
                                href={`https://aws-crm.my.salesforce.com/${customer.sfdc_opportunity_id}`}
                                target="_blank"
                                data-testid="sfdc-link"
                            >
                                {formatString(customer.sfdc_opportunity_id)}
                            </Link>
                        ) : (
                            EMPTY_STRING
                        ),
                    },
                ],
                [
                    {
                        key: 'Customer AWS Account ID',
                        value: formatString(customer.customer_aws_account_id),
                    },
                    {
                        key: 'Accounts payable email',
                        value: formatString(customer.accounts_payable_email),
                    },
                ],
                [
                    {
                        key: 'Currency',
                        value: formatString(customer.currency),
                    },
                    {
                        key: 'Pricing Model',
                        value: formatString(
                            customer.pricing_model || 'Old Pricing Model',
                        ),
                    },
                ],
                [
                    {
                        key: 'Number of students attended',
                        value: formatNumber(
                            customer.number_of_students_attended,
                        ),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const nonBilledItems =
        customer.revenues?.filter(
            (revItem) => !['Billed', 'TnE'].includes(revItem.type),
        ) ?? [];
    const billedItems: Array<LineItem> =
        customer.revenues
            ?.filter((revItem) => ['Billed', 'TnE'].includes(revItem.type))
            .map((revItem) => {
                return {
                    item_amount: revItem.amount,
                    item_type:
                        revItem.type === 'TnE'
                            ? 'Travel & expense'
                            : activity?.activity_audience === 'Public'
                              ? 'Public ILT class'
                              : 'Private ILT class',
                };
            }) ?? [];

    return (
        <div className="awsui-util-mb-l" key={index}>
            <Section {...publicCustomerSectionProps} />
            {hasTrainingBriefingItems(customer) && (
                <Section {...getTrainingBriefingProps(customer, index)} />
            )}
            <Table
                data-testid={`PublicCustomerRevenueItemsTable${index}`}
                columnDefinitions={revenueColumnDefinitions(customer)}
                items={[
                    ...nonBilledItems,
                    nonBilledItems.reduce(
                        (p: any, n: any) => {
                            return {
                                type: 'Total',
                                amount: p.amount + n.amount,
                            };
                        },
                        {
                            type: 'Total',
                            amount: 0,
                        },
                    ),
                ]}
                header={
                    <TableHeader
                        title="Revenue Items"
                        actions={<React.Fragment />}
                        count={
                            customer.revenues?.length
                                ? customer.revenues?.length
                                : ''
                        }
                    />
                }
                empty={emptyRevenueTableComponent}
            />
            <Table
                data-testid={`PublicCustomerInvoiceItemsTable${index}`}
                columnDefinitions={invoiceColumnDefinitions(customer)}
                items={[
                    ...billedItems,
                    billedItems.reduce(
                        (p, n) => {
                            return {
                                item_type: 'Total',
                                item_amount: p.item_amount + n.item_amount,
                            };
                        },
                        {
                            item_type: 'Total',
                            item_amount: 0,
                        },
                    ),
                ]}
                empty={emptyLineItemTableComponent}
            />
        </div>
    );
};

// Customers tab

const CustomersTab = memo(({ activity }: { activity: ActivityData }) => {
    const [activeRemoveIndex, setActiveRemoveIndex] = React.useState<
        number | null
    >(null);

    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    const featureFlags = useSelector(selectFeatures);
    const isCustomerCorrespondenceEnabled = checkFeature(
        '',
        { featureName: 'customer-correspondence' },
        featureFlags?.features,
    );

    const handleRemoveCustomer = async () => {
        // remove from array and save
        if (activeRemoveIndex === null || !activity?.pk) {
            setActiveRemoveIndex(null);
            return;
        }

        let updatedCustomers = [...activity.customers];

        updatedCustomers.splice(activeRemoveIndex, 1);

        const isSuccessful = await dispatch<any>(
            updateSelectedActivity(activity.pk, {
                ...activity,
                customers: updatedCustomers,
            }),
        );

        addNotification({
            id: `customer-tab-${Date.now()}`,
            ...(isSuccessful
                ? {
                      type: 'success',
                      content: 'The customer has been removed.',
                  }
                : {
                      type: 'error',
                      content: 'An error occurred while removing the customer.',
                  }),
        });

        if (isSuccessful) {
            history.push({
                pathname: `/activities/${activity.pk}`,
                search: 'tabId=customers',
            });
        }
    };

    const emptyCustomersSection = () => {
        const sectionProps: SectionProps = {
            testId: 'ActivityDetailsEmptyCustomersSection',
            header: {
                label: 'Customers',
            },
            content: {
                type: SectionContentType.Generic,
                children: (
                    <div className="awsui-util-t-c awsui-util-status-inactive">
                        <div className="awsui-util-pt-s awsui-util-mb-xs">
                            <b>No customers</b>
                        </div>
                        <p className="awsui-util-mb-s">
                            Customers will display here.
                        </p>
                        {addCustomerButtonComponent()}
                    </div>
                ),
            } as GenericContentData,
        };
        return <Section {...sectionProps} />;
    };

    const addCustomerButtonComponent = () => {
        if (isPartnerPrivateCustomer(activity?.activity_audience)) {
            return (
                <Can
                    perform={Actions.ACTIVITY_MODIFY}
                    yes={() => (
                        <Button
                            data-testid="ActivityDetailsAddAPNCustomer"
                            onClick={() =>
                                history.push({
                                    pathname: `/activities/${activity?.pk}/create-apn-customer`,
                                })
                            }
                        >
                            Add customer
                        </Button>
                    )}
                    no={() => null}
                />
            );
        } else {
            if (
                activity?.activity_audience ===
                    AudienceType.CommercialPrivate &&
                (activity?.customers?.length ?? 0) >= 1
            ) {
                return <></>;
            }

            return (
                <Can
                    perform={Actions.ACTIVITY_MODIFY}
                    yes={() => (
                        <Button
                            data-testid="ActivityDetailsAddCustomer"
                            onClick={() =>
                                history.push({
                                    pathname: `/activities/${activity?.pk}/create-customer`,
                                })
                            }
                        >
                            Add customer
                        </Button>
                    )}
                    no={() => null}
                />
            );
        }
    };

    return (
        <div data-testid="ActivityDetailsCustomersTab">
            {(() => {
                if (!activity.customers?.length) {
                    return emptyCustomersSection();
                }

                if (isCommercialPrivateCustomer(activity.activity_audience)) {
                    return (
                        <SpaceBetween direction="vertical" size="xs">
                            <Box textAlign="center">
                                {addCustomerButtonComponent()}
                            </Box>
                            {(
                                activity.customers as Array<CommercialPrivateCustomer>
                            ).map((customer, index) =>
                                getCommercialPrivateCustomerView(
                                    activity,
                                    customer,
                                    index,
                                    isCustomerCorrespondenceEnabled,
                                    () =>
                                        history.push({
                                            pathname: `/activities/${activity?.pk}/edit-customer/${index}`,
                                        }),
                                    () => setActiveRemoveIndex(index),
                                ),
                            )}
                        </SpaceBetween>
                    );
                } else if (
                    isPartnerPrivateCustomer(activity.activity_audience)
                ) {
                    return (
                        <SpaceBetween direction="vertical" size="xs">
                            <Box textAlign="center">
                                {addCustomerButtonComponent()}
                            </Box>
                            {(
                                activity.customers as Array<APNPartnerPrivateCustomer>
                            ).map((customer, index) =>
                                getApnCustomerView(
                                    activity,
                                    customer,
                                    isCustomerCorrespondenceEnabled,
                                    index,
                                    () =>
                                        history.push({
                                            pathname: `/activities/${activity?.pk}/edit-apn-customer/${index}`,
                                        }),
                                    () => setActiveRemoveIndex(index),
                                ),
                            )}
                        </SpaceBetween>
                    );
                } else if (isPublicCustomer(activity.activity_audience)) {
                    return (
                        <SpaceBetween size="xs">
                            <Box textAlign="center">
                                {addCustomerButtonComponent()}
                            </Box>

                            {(
                                activity.customers as Array<CommercialPrivateCustomer>
                            ).map((customer, index) => {
                                return getPublicCustomerView(
                                    activity,
                                    customer,
                                    index,
                                    () =>
                                        history.push({
                                            pathname: `/activities/${activity?.pk}/edit-customer/${index}`,
                                        }),
                                    () => setActiveRemoveIndex(index),
                                );
                            })}
                        </SpaceBetween>
                    );
                }
                return null;
            })()}
            <Can
                perform={Actions.ACTIVITY_MODIFY}
                yes={() => {
                    if (
                        activeRemoveIndex &&
                        isCustomerSFDCSynced(
                            activity.customers[activeRemoveIndex],
                        )
                    ) {
                        return (
                            <RemoveSFDCCustomerModal
                                {...{
                                    activeRemoveIndex,
                                    setActiveRemoveIndex,
                                    activity,
                                    handleRemoveCustomer,
                                }}
                            />
                        );
                    }

                    return (
                        <DeleteCustomerModal
                            {...{
                                activeRemoveIndex,
                                setActiveRemoveIndex,
                                activity,
                                handleRemoveCustomer,
                            }}
                        />
                    );
                }}
                no={() => null}
            />
        </div>
    );
});

export default CustomersTab;

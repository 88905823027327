import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Alert, Spinner } from '@amzn/awsui-components-react';
import { useRouteMatch } from 'react-router-dom';

import AuditTab from '../../../../common/components/AuditTrail/AuditTab';
import Can from '../../../../common/components/Can';
import { Actions } from '../../../../common/constants/auth';
import DetailsTab from './DetailsTab/DetailsTab';
import { formatName } from '../Common/Common';
import NotesTab from './NotesTab/NotesTab';
import {
    getSelectedInstructor,
    selectError,
    selectSelectedInstructor,
    selectIsLoading,
} from '../../../store/slices/selectedInstructorSlice';
import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';

const InstructorDetails = () => {
    const match = useRouteMatch<{ id: string }>();
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const instructor = useSelector(selectSelectedInstructor);
    const dispatch = useDispatch();

    const [activeTabId, setActiveTabId] = useState('details');

    useEffect(() => {
        // need to refetch the instructor profile data every time, since its entry
        // course_certification_mapping is dynamically generated by BE whenever
        // the instructor profile data is changed
        dispatch(getSelectedInstructor(match.params.id));
    }, [dispatch, match.params.id]);

    if (error) {
        return (
            <Alert
                data-testid="InstructorDetailsError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (!isLoading && instructor?.instructor) {
        const profile = instructor.instructor;
        return (
            <div data-testid="InstructorDetailsPage">
                <div className="awsui-util-action-stripe-large">
                    <div className="awsui-util-action-stripe-title awsui-util-mb-m">
                        <h1>
                            {formatName(profile.first_name, profile.last_name)}
                        </h1>
                    </div>
                </div>

                <Tabs
                    data-testid="InstructorDetailsPageTabSection"
                    tabs={[
                        {
                            label: 'Details',
                            id: 'details',
                            content: <DetailsTab />,
                        },
                        {
                            label: 'Notes',
                            id: 'notes',
                            content: <NotesTab />,
                        },
                        {
                            label: 'Audit Log',
                            id: 'auditLog',
                            content: (
                                <Can
                                    perform={Actions.AUDIT_VIEW}
                                    yes={() => (
                                        <AuditTab id={match.params.id} />
                                    )}
                                    no={() => (
                                        <Alert
                                            header="Access Denied"
                                            type="error"
                                        >
                                            No permission to view this.
                                        </Alert>
                                    )}
                                />
                            ),
                        },
                    ]}
                    activeTabId={activeTabId}
                    onChange={(e) => {
                        setActiveTabId(e.detail.activeTabId);
                    }}
                />
            </div>
        );
    } else {
        return <Spinner data-testid="InstructorDetailsSpinner" />;
    }
};

export default InstructorDetails;

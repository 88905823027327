import createQueryString from '../../common/utils/createQueryString';
import { API_MAIN_PATH } from '../../common/constants/grimsby';
import handleGrimsbyApiRequest from '../../common/utils/handleGrimsbyApiRequest';

export const LMS_MANAGEMENT_PATH = `${API_MAIN_PATH.LMS_MANAGEMENT}/activities`;

interface LMSServiceRequestParams {
    lms_system: string;
    activity_uuid: string;
    class_size: number;
    lms_id?: string;
}

const createLMSClass = (
    activityPk: string,
    lmsSystem: string,
    classSize: number,
    lmsId?: string,
) => {
    let params: LMSServiceRequestParams = {
        lms_system: lmsSystem,
        activity_uuid: activityPk,
        class_size: classSize,
    };

    if (lmsId) {
        params.lms_id = lmsId;
    }

    return handleGrimsbyApiRequest<any>(
        `${LMS_MANAGEMENT_PATH}${createQueryString(params)}`,
        {
            method: 'POST',
        },
    );
};

const lmsManagementApi = {
    createLMSClass,
};

export default lmsManagementApi;

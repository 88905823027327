import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { JSONTree } from 'react-json-tree';
import { Link } from 'react-router-dom';
import { Table, Alert, Button, Spinner } from '@amzn/awsui-components-react';

import {
    AJAX_CALL_ERROR,
    LOADING_TEXT,
    LOAD_MORE_TEXT,
    AUDIT_EMPTY,
} from '../../constants/grimsby';
import formatDate, { decodeDateNumber } from '../../utils/formatDate';
import getPhoneToolUrl from '../../utils/getPhoneToolUrl';

import {
    selectItems,
    selectNextToken,
    selectError,
    selectIsLoading,
    selectIsLoaded,
    getAuditTrail,
    setIsLoaded,
} from '../../store/slices/auditTrailSlice';
import { AuditItemData } from '../../interfaces/auditItem';

const auditJsonTheme = {
    scheme: 'auditTheme',
    base00: 'transparent', // this is the only way to force the background color to white, or set to 'black' to get white
    base01: '#3C444F',
    base02: '#4F5A65',
    base03: '#BEBEBE',
    base04: '#b0b0b0',
    base05: '#d0d0d0',
    base06: '#FFFFFF',
    base07: '#f5f5f5',
    base08: '#A1C659', // green - used for null
    base09: '#6FB3D2', // blue - used for timestamp, booleans
    base0A: '#ddb26f', // unknown use
    base0B: '#fd971f', // orange use for strings
    base0C: '#12cfc0', // unknown use
    base0D: '#fafafa', // grey - used for keys
    base0E: '#D381C3', // unknown use
    base0F: '#deaf8f', // unknown use
};

export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'modified_by',
        header: 'Modified By',
        cell: (item: AuditItemData) => (
            <Link
                to={{
                    pathname: getPhoneToolUrl(item.resource_modified_by),
                }}
                target="_blank"
            >
                {item.resource_modified_by}
            </Link>
        ),
    },
    {
        id: 'timestamp',
        header: 'Timestamp',
        cell: (item: AuditItemData) =>
            formatDate(decodeDateNumber(item.resource_modified_timestamp)),
    },
    {
        id: 'new_value',
        header: 'New Value',
        cell: (item: AuditItemData) => (
            <JSONTree
                data={item.new_value}
                theme={auditJsonTheme}
                shouldExpandNode={() => false}
            />
        ),
    },
    {
        id: 'old_value',
        header: 'Old Value',
        cell: (item: AuditItemData) => (
            <JSONTree
                data={item.old_value}
                theme={auditJsonTheme}
                shouldExpandNode={() => false}
            />
        ),
    },
];

const AuditTab = ({ id }: { id: string }) => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const items = useSelector(selectItems);
    const nextToken = useSelector(selectNextToken);
    const isLoaded = useSelector(selectIsLoaded);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAuditTrail(id));

        return () => {
            dispatch(setIsLoaded(false));
        };
    }, [id, dispatch]);

    if (error) {
        return (
            <Alert data-testid="AuditTabError" header="Error" type="error">
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (isLoaded) {
        return (
            <div data-testid="AuditTabDetails" className="audit-table">
                <Table
                    columnDefinitions={columnDefinitions}
                    loadingText={LOADING_TEXT}
                    loading={isLoading}
                    empty={
                        <div className="awsui-util-t-c">
                            <div className="awsui-util-pt-s awsui-util-mb-xs">
                                <b>{AUDIT_EMPTY}</b>
                            </div>
                        </div>
                    }
                    items={items}
                />
                {nextToken !== null ? (
                    <div className="load-more">
                        <Button
                            data-testid="AuditTabLoadMoreButton"
                            onClick={(event) => {
                                event.preventDefault();
                                dispatch(getAuditTrail(id));
                            }}
                        >
                            {LOAD_MORE_TEXT}
                        </Button>
                    </div>
                ) : null}
            </div>
        );
    } else {
        return <Spinner data-testid="AuditTabLoadingSpinner" />;
    }
};

export default AuditTab;

import { HandleRequestData } from '../../interfaces/handleRequest';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { RegionsResponseData } from '../../interfaces/businessDataResponse/regionsResponse';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const REGIONS_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/regions`;

/**
 * Method to request the full list of regions
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - query params
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getRegions = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<RegionsResponseData>> => {
    const path = `${REGIONS_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<RegionsResponseData>(path, {
        method: 'GET',
    });
};

const regionsApi = {
    getRegions,
};

export default regionsApi;

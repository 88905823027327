import React, { Component, ReactNode } from 'react';
import CloudWatchRum from '../../utils/CloudWatchRUM';

interface ErrorBoundaryProps {
    children: ReactNode;
    showFallback?: boolean;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

export interface LogRequestBody {
    log_level: string;
    log_message: string;
    timestamp: string;
    logger: string;
}

// ErrorBoundary components can only be class components
class GrimsbyGlobalErrorBoundary extends Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    rumClient: any;

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
        };
        this.rumClient = CloudWatchRum;
    }

    componentDidMount() {
        window.addEventListener('error', this.handleGlobalError.bind(this));
        window.addEventListener(
            'unhandledrejection',
            this.handleRejection.bind(this),
        );
    }

    static getDerivedStateFromError(_: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        this.logError(error);

        this.setState({ hasError: true });
    }

    handleGlobalError(e: ErrorEvent) {
        this.logError(e);
    }

    handleRejection(e: PromiseRejectionEvent) {
        this.logError(e.reason);
    }

    // eslint-disable-next-line class-methods-use-this
    async logError(error: Error | ErrorEvent | String) {
        this.rumClient.recordError(error);
    }

    render() {
        const { children, showFallback } = this.props;
        const { hasError } = this.state;
        // if there is an error can render a common error page here.
        if (hasError && showFallback) {
            return <h1>Oops something went wrong!</h1>;
        }
        return children;
    }
}

export default GrimsbyGlobalErrorBoundary;

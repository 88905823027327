import React from 'react';

import { Button, FormField } from '@amzn/awsui-components-react';
import { RowDefinition } from '../../interfaces/rowDefinition';
import './MultiRowAttributeEditor.scss';

export interface MultiRowAttributeEditorProps<ItemType, RowIdType> {
    rowDefinitions: Array<RowDefinition<ItemType, RowIdType>>;
    items: Array<ItemType>;
    empty: string;
    addButtonText: string;
    removeButtonText: string;
    onAddButtonClick: () => void;
    onRemoveButtonClick: (index: number) => void;
    hideRow: (
        editorRow: RowDefinition<ItemType, RowIdType>,
        item: ItemType,
    ) => boolean;
}

const MultiRowAttributeEditor = <ItemType, RowIdType>({
    rowDefinitions,
    items,
    empty,
    addButtonText,
    removeButtonText,
    onAddButtonClick,
    onRemoveButtonClick,
    hideRow,
}: MultiRowAttributeEditorProps<ItemType, RowIdType>) => {
    const getRemoveFunction = (index: number) =>
        onRemoveButtonClick.bind(null, index);

    return (
        <div
            className="awsui-util-spacing-v-s"
            data-testid="MultiRowAttributeEditor"
        >
            {items?.length ? (
                items.map((item, index) => (
                    <React.Fragment key={index}>
                        <div className="awsui-attribute-editor__row multi-row-attribute-editor__row">
                            <FormField>
                                <div className="awsui-grid">
                                    {rowDefinitions.map(
                                        (editorRow, definitionIndex) => {
                                            if (hideRow(editorRow, item)) {
                                                return null;
                                            }

                                            return (
                                                <div
                                                    key={definitionIndex}
                                                    className="awsui-row"
                                                    data-testid="MultiRowAttributeEditor-Row"
                                                >
                                                    {editorRow.fieldDefinitions.map(
                                                        (
                                                            fieldDefinition,
                                                            rowIndex,
                                                        ) => (
                                                            <div
                                                                key={rowIndex}
                                                                className={`col-xxxs-12 col-xs-${fieldDefinition.colspan}`}
                                                            >
                                                                <FormField
                                                                    label={
                                                                        fieldDefinition?.label
                                                                    }
                                                                    stretch={
                                                                        true
                                                                    }
                                                                    errorText={
                                                                        fieldDefinition.errorText
                                                                            ? fieldDefinition.errorText(
                                                                                  item,
                                                                                  index,
                                                                              )
                                                                            : null
                                                                    }
                                                                >
                                                                    {fieldDefinition?.control(
                                                                        item,
                                                                        index,
                                                                    ) || null}
                                                                </FormField>
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            </FormField>
                            <div className="awsui-util-mt-l">
                                <Button
                                    data-testid="MultiRowAttributeEditorRemove"
                                    onClick={getRemoveFunction(index)}
                                >
                                    {removeButtonText}
                                </Button>
                            </div>
                        </div>
                        <div className="awsui-attribute-editor__divider multi-row-attribute-editor__divider multi-row-attribute-editor__divider--always-visible">
                            <div />
                        </div>
                    </React.Fragment>
                ))
            ) : (
                <div className="multi-row-attribute-editor__empty-text">
                    {empty}
                </div>
            )}
            <div className="awsui-attribute-editor__add-row">
                <Button
                    data-testid="MultiRowAttributeEditorAdd"
                    onClick={onAddButtonClick}
                >
                    {addButtonText}
                </Button>
            </div>
        </div>
    );
};

export default MultiRowAttributeEditor;

import {
    FormField,
    Input,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { FORM_ERROR_SELECTOR } from '../../../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivityProgram,
    AudienceType,
    BasicActivityFormSectionProps,
} from '../../../Common/Common';

const StudentCountSection = ({
    formValues,
    errors,
    handleFieldEvent,
}: BasicActivityFormSectionProps) => {
    const isAttendedOptional =
        formValues.program === ActivityProgram.Commercial &&
        formValues.activity_audience === AudienceType.Public;

    const attendedLabel = isAttendedOptional ? (
        <span>
            Number of students attended <em>- optional</em>
        </span>
    ) : (
        <span>Number of students attended</span>
    );

    return (
        <SpaceBetween direction="vertical" size="l">
            <div className="grimsby-sub-section-header">
                Registration details
            </div>
            <p>
                To save a completed activity, enter the number of students
                attended.
            </p>
            <FormField
                data-testid="formField-attended"
                label={attendedLabel}
                errorText={errors?.attended}
            >
                <Input
                    className={errors?.attended && FORM_ERROR_SELECTOR}
                    value={`${formValues.attended}`}
                    type="number"
                    onChange={(e) =>
                        handleFieldEvent({
                            attended: !!e.detail.value
                                ? parseFloat(e.detail.value)
                                : null,
                        })
                    }
                    data-testid={`EditAttended`}
                />
            </FormField>
            <div className="grimsby-sub-section-divider" />
        </SpaceBetween>
    );
};

export default StudentCountSection;

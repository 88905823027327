import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import { AppDispatch, GlobalState } from '../../../main/store';
import scheduleManagementApi from '../../api/scheduleManagementApi';
import { CALENDAR_EVENT_COLOR, UNAVAILABLE_EVENT_COLOR } from '../../constants';
import { ActivityData } from '../../interfaces/activity';
import { InstructorListResponseData } from '../../interfaces/instructorsResponse';
import { LookupData } from '../../interfaces/lookup';
import { ScheduleManagementAPIQueryParams } from '../../interfaces/queryParams';
import { RecommendedInstructorProfileData } from '../../interfaces/recommendedInstructorProfile';
import {
    CalendarEvent,
    CalendarResource,
    InstructorEventTypes,
} from '../../interfaces/resourceCalendarProps';
import { buildCalendarEventsFromDeliverySessions } from '../../services/schedule-service';
import {
    BlockedTimeCalendarEvent,
    BlockedTime,
    BlockedTimeTimestamp,
} from '../../../common/interfaces/blockedTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getBlockedTimeStartAndEndTimesByInstructorType } from '../../services/calendar-service';
import instructorManagementApi from '../../../imt/api/instructorManagementApi';

dayjs.extend(utc);
dayjs.extend(timezone);

interface InstructorPayloadState {
    instructorLookup: LookupData<RecommendedInstructorProfileData>;
}

export interface SMTInstructorListState extends GenericStoreState {
    isLoaded: boolean;
    instructorListAsResources: Array<CalendarResource>;
    instructorActivitiesAsEvents: Array<CalendarEvent>;
    instructorActivityLookup: LookupData<ActivityData>;
    blockedTimeLookup: LookupData<BlockedTime>;
    blockedTimesAsEvents: Array<CalendarEvent>;
    instructorLookup: LookupData<RecommendedInstructorProfileData>;
    start_timestamp: number;
    end_timestamp: number;
    addblockedTimesAsEvents: Array<CalendarEvent>;
    pagesCount: number;
    currentPageIndex: number;
    count: number;
    from: number;
    size: number;
    isDeleting: boolean;
}

export const RESERVED_TEXT = 'Reserved';
export const instructorListState = (
    smtInstructorsList: Array<RecommendedInstructorProfileData>,
) =>
    smtInstructorsList.reduce(
        (acc, instructor) => {
            const { pk, full_name, activities, blocked_times, is_freelancer } =
                instructor;
            acc.instructorListAsResources.push({
                id: pk as string,
                title: `${full_name}`,
            });
            acc.instructorLookup[pk as string] = instructor;

            for (const [activity] of activities) {
                const {
                    delivery_sessions,
                    delivery_timezone,
                    activity_name,
                    pk: activityPk,
                } = activity;
                let events = buildCalendarEventsFromDeliverySessions(
                    {
                        sessions: delivery_sessions,
                        timezone: delivery_timezone,
                    },
                    () => ({
                        id: activityPk as string,
                        title: activity_name,
                        resourceId: pk as string,
                    }),
                );
                acc.activitiesAsEvents = [...acc.activitiesAsEvents, ...events];
                acc.activityLookup[activityPk as string] = activity;
            }

            if (blocked_times && blocked_times.length > 0) {
                let instructorBlockedTimesWithId = [];
                for (const blockedTimeObj of blocked_times) {
                    for (const blockedTimestamps of blockedTimeObj.blocked_time_timestamps) {
                        const backgroundColor = is_freelancer
                            ? UNAVAILABLE_EVENT_COLOR
                            : CALENDAR_EVENT_COLOR;
                        const { start, end } =
                            getBlockedTimeStartAndEndTimesByInstructorType(
                                blockedTimestamps,
                                instructor,
                            );
                        acc.blockedTimesAsEvents.push({
                            id: blockedTimeObj.pk,
                            start,
                            end,
                            resourceId: pk as string,
                            backgroundColor,
                            title: `${RESERVED_TEXT} - ${blockedTimeObj.blocked_time_name}`,
                            eventType: InstructorEventTypes.blockedTime,
                        });
                        acc.blockedTimeLookup[blockedTimeObj.pk as string] = {
                            ...blockedTimeObj,
                            instructor_pk: pk,
                        };
                        instructorBlockedTimesWithId.push({
                            id: blockedTimeObj.pk,
                            ...blockedTimestamps,
                        });
                    }
                }
            }
            return acc;
        },
        {
            instructorListAsResources: [] as Array<CalendarResource>,
            activitiesAsEvents: [] as Array<CalendarEvent>,
            activityLookup: {} as LookupData<ActivityData>,
            instructorLookup:
                {} as LookupData<RecommendedInstructorProfileData>,
            blockedTimesAsEvents: [] as Array<CalendarEvent>,
            blockedTimeLookup: {} as LookupData<BlockedTime>,
        },
    );
const browserTimezone = dayjs.tz.guess();
const initialDateTime = dayjs
    .tz(dayjs(), browserTimezone)
    .startOf('week')
    .hour(0)
    .minute(0)
    .second(0);
const start_timestamp = initialDateTime.unix();
const end_timestamp = initialDateTime.add(7, 'day').unix();

export const smtInstructorListSlice = createSlice({
    name: 'smtInstructorList',
    initialState: {
        instructorListAsResources: [],
        instructorActivitiesAsEvents: [],
        instructorActivityLookup: {},
        instructorLookup: {},
        error: null,
        isLoaded: false,
        isLoading: false,
        isDeleting: false,
        start_timestamp,
        end_timestamp,
        blockedTimesAsEvents: [],
        blockedTimeLookup: {},
        addblockedTimesAsEvents: [],
        pagesCount: 1,
        currentPageIndex: 1,
        count: 0,
        from: 0,
        size: 25,
    } as SMTInstructorListState,
    reducers: {
        setInstructorList: (
            state: SMTInstructorListState,
            action: PayloadAction<RecommendedInstructorProfileData[]>,
        ) => {
            const {
                instructorListAsResources,
                activitiesAsEvents,
                activityLookup,
                instructorLookup,
                blockedTimeLookup,
                blockedTimesAsEvents,
            } = instructorListState(action.payload);
            state.instructorListAsResources = instructorListAsResources;
            state.instructorActivitiesAsEvents = activitiesAsEvents;
            state.instructorActivityLookup = activityLookup;
            state.instructorLookup = instructorLookup;
            state.blockedTimeLookup = blockedTimeLookup;
            state.blockedTimesAsEvents = blockedTimesAsEvents;
        },
        setIsLoading: (
            state: SMTInstructorListState,
            action: PayloadAction<any>,
        ) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (
            state: SMTInstructorListState,
            action: PayloadAction<boolean>,
        ) => {
            state.isLoaded = action.payload;
        },
        setIsDeleting: (
            state: SMTInstructorListState,
            action: PayloadAction<boolean>,
        ) => {
            state.isDeleting = action.payload;
        },
        setEndTimeStamp: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.end_timestamp = action.payload;
        },
        setStartTimeStamp: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.start_timestamp = action.payload;
        },
        setFrom: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.from = action.payload;
        },
        setCount: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.count = action.payload;
        },
        setPagesCount: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.currentPageIndex = action.payload;
        },
        setSize: (
            state: SMTInstructorListState,
            action: PayloadAction<number>,
        ) => {
            state.size = action.payload;
        },
        getInstructors: (
            state: InstructorPayloadState,
            action: PayloadAction<any>,
        ) => {
            return action.payload.instructors.reduce(
                (
                    acc: Array<RecommendedInstructorProfileData>,
                    instructor: RecommendedInstructorProfileData,
                ) => {
                    if (
                        instructor.pk &&
                        state.instructorLookup[instructor.pk]
                    ) {
                        acc.push(instructor);
                    }
                    return acc;
                },
                [],
            );
        },
        setBlockedTimesAsEvents: (
            state: SMTInstructorListState,
            action: PayloadAction<CalendarEvent[]>,
        ) => {
            state.blockedTimesAsEvents = action.payload;
        },
        setBlockedTimeLookup: (
            state: SMTInstructorListState,
            action: PayloadAction<BlockedTime>,
        ) => {
            state.blockedTimeLookup[action.payload.pk as string] =
                action.payload;
        },
        pushToInstructorList: (
            state: SMTInstructorListState,
            action: PayloadAction<BlockedTime>,
        ) => {
            state.instructorLookup[
                action.payload.pk as string
            ].blocked_times.push(action.payload);
        },
        pushToBlockedTimeAsEvents: (
            state: SMTInstructorListState,
            action: PayloadAction<CalendarEvent>,
        ) => {
            state.blockedTimesAsEvents.push(action.payload);
        },
        setInstructorLookup: (
            state: SMTInstructorListState,
            action: PayloadAction<RecommendedInstructorProfileData>,
        ) => {
            state.instructorLookup[action.payload.pk as string] =
                action.payload;
        },
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setIsDeleting,
    setInstructorList,
    setEndTimeStamp,
    setStartTimeStamp,
    getInstructors,
    setCurrentPageIndex,
    setPagesCount,
    setCount,
    setFrom,
    setSize,
    setBlockedTimesAsEvents,
    setBlockedTimeLookup,
    pushToInstructorList,
    pushToBlockedTimeAsEvents,
    setInstructorLookup,
} = smtInstructorListSlice.actions;

export const getInstructorList = (
    params: ScheduleManagementAPIQueryParams.InstructorWithActivitesParams,
) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const { size, from } = state.smtInstructorList;
        dispatch(setIsLoading(true));
        try {
            const {
                result: { instructors, total_instructors },
            }: HandleRequestData<InstructorListResponseData> =
                await scheduleManagementApi.getInstructorsWithActivitiesList({
                    ...params,
                    from,
                    size,
                } as ScheduleManagementAPIQueryParams.InstructorWithActivitesParams);
            dispatch(setInstructorList(instructors));
            dispatch(setCount(total_instructors));
            dispatch(setPagesCount(Math.ceil(total_instructors / size)));
            dispatch(setIsLoading(false));
        } catch (error: any) {}
    };
};

export const addBlockedTimesTimeAsEvent = (body: BlockedTimeCalendarEvent) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            for (const blockedTime of body.blockedTime
                .blocked_time_timestamps) {
                const backgroundColor = body.instructor.is_freelancer
                    ? UNAVAILABLE_EVENT_COLOR
                    : CALENDAR_EVENT_COLOR;
                const { start, end } =
                    getBlockedTimeStartAndEndTimesByInstructorType(
                        blockedTime,
                        body.instructor,
                    );
                dispatch(
                    pushToBlockedTimeAsEvents({
                        id: body.blockedTime.pk as string,
                        start,
                        end,
                        resourceId: body.instructor.pk as string,
                        title: `${RESERVED_TEXT} - ${body.blockedTime.blocked_time_name}`,
                        backgroundColor,
                        eventType: InstructorEventTypes.blockedTime,
                    }),
                );

                dispatch(setBlockedTimeLookup(body.blockedTime));
                dispatch(pushToInstructorList(body.blockedTime));
            }
        } catch (error) {}
        dispatch(setIsLoading(false));
    };
};

export const updateCalendarBlockedTime = (body: BlockedTimeCalendarEvent) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            const filteredBlockedTimeState =
                state.smtInstructorList.blockedTimesAsEvents.filter(
                    (time) => time.id !== body.blockedTime.pk,
                );
            for (const blockedTime of body.blockedTime
                .blocked_time_timestamps) {
                const backgroundColor = body.instructor.is_freelancer
                    ? UNAVAILABLE_EVENT_COLOR
                    : CALENDAR_EVENT_COLOR;
                const { start, end } =
                    getBlockedTimeStartAndEndTimesByInstructorType(
                        blockedTime,
                        body.instructor,
                    );
                filteredBlockedTimeState.push({
                    id: body.blockedTime.pk as string,
                    start,
                    end,
                    resourceId: body.instructor.pk as string,
                    title: `${RESERVED_TEXT} - ${body.blockedTime.blocked_time_name}`,
                    backgroundColor,
                    eventType: InstructorEventTypes.blockedTime,
                });

                dispatch(setBlockedTimeLookup(body.blockedTime));
            }
            dispatch(setBlockedTimesAsEvents(filteredBlockedTimeState));
        } catch (error) {}
        dispatch(setIsLoading(false));
    };
};

export const deleteCalendarInstructorReservedTime = (
    body: BlockedTimeCalendarEvent,
) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsDeleting(true));
        try {
            const nonDeleteEvents =
                state.smtInstructorList.blockedTimesAsEvents.filter(
                    (reservedTimeEvent) =>
                        reservedTimeEvent.id !== body.blockedTime.pk,
                );
            dispatch(setBlockedTimesAsEvents(nonDeleteEvents));
            dispatch(setIsDeleting(false));
        } catch (error) {
            dispatch(setIsDeleting(false));
        }
    };
};

export const updateBlockedTimeForInstructor = (blockedTimeObj: BlockedTime) => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            let timestamps = [] as BlockedTimeTimestamp[];
            timestamps = blockedTimeObj.blocked_time_timestamps;
            const blockedTime = {
                blocked_time_name: blockedTimeObj.blocked_time_name,
                blocked_time_type: blockedTimeObj.blocked_time_type,
                blocked_time_notes: blockedTimeObj.blocked_time_notes,
                instructor_pk: blockedTimeObj.instructor_pk,
                blocked_time_timestamps: timestamps.map((timestamp) => ({
                    start_timestamp: timestamp.start_timestamp,
                    end_timestamp: timestamp.end_timestamp,
                })),
            };
            const response = await instructorManagementApi.updateBlockedTime({
                instructorId: blockedTimeObj.instructor_pk,
                blockedTimeId: blockedTimeObj.pk,
                blockedTime,
            });
            if (response.status === 200) {
                dispatch(setIsLoading(false));
                return {
                    status: response.status,
                    blockedTime: blockedTimeObj,
                    blocked_time_id: blockedTimeObj.pk,
                };
            }
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return new Error(`Error updating:${blockedTimeObj.instructor_pk}`);
        }
    };
};

export const addBlockedTimesForInstructor = (blockedTime: BlockedTime) => {
    return async () => {
        try {
            const instructorId = blockedTime.instructor_pk;
            const {
                blocked_time_name,
                blocked_time_timestamps,
                blocked_time_type,
                blocked_time_notes,
                instructor_pk,
            } = blockedTime;
            const response = await instructorManagementApi.addBlockedTime({
                instructorId,
                blockedTime: {
                    blocked_time_name,
                    blocked_time_timestamps,
                    blocked_time_type,
                    blocked_time_notes,
                    instructor_pk,
                },
            });
            if (response.status === 200) {
                return {
                    status: response.status,
                    instructorId: instructor_pk,
                    blockedTime: {
                        ...blockedTime,
                        pk: response.result.blocked_time_pks[0],
                    },
                };
            }
        } catch (error: any) {
            return new Error(`Error updating:${blockedTime.instructor_pk}`);
        }
    };
};

export const deleteBlockedTimeForInstructor = (blockedTime: BlockedTime) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setIsDeleting(true));
        try {
            const { pk } = blockedTime;
            if (pk && blockedTime.instructor_pk) {
                const response =
                    await instructorManagementApi.deleteBlockedTime({
                        instructorId: blockedTime.instructor_pk,
                        blockedTimeId: blockedTime.pk,
                    });
                if (response.status === 200) {
                    return {
                        status: response.status,
                        blockedTime,
                    };
                }
            }
            dispatch(setIsDeleting(false));
        } catch (error: any) {
            dispatch(setIsDeleting(false));
            return new Error(`Error deleting:${blockedTime.instructor_pk}`);
        }
    };
};

export const selectInstructorList = (state: GlobalState) =>
    state.smtInstructorList.instructorListAsResources;
export const selectInstructorActivitiesAsEvents = (state: GlobalState) =>
    state.smtInstructorList.instructorActivitiesAsEvents;
export const selectInstructorActivityLookup = (state: GlobalState) =>
    state.smtInstructorList.instructorActivityLookup;
export const selectInstructorLookup = (state: GlobalState) =>
    state.smtInstructorList.instructorLookup;
export const selectIsLoading = (state: GlobalState) =>
    state.smtInstructorList.isLoading;
export const selectIsLoaded = (state: GlobalState) =>
    state.smtInstructorList.isLoaded;
export const selectIsDeleting = (state: GlobalState) =>
    state.smtInstructorList.isDeleting;
export const selectStartTimeStamp = (state: GlobalState) =>
    state.smtInstructorList.start_timestamp;
export const selectEndTimeStamp = (state: GlobalState) =>
    state.smtInstructorList.end_timestamp;
export const selectBlockedTimeLookup = (state: GlobalState) =>
    state.smtInstructorList.blockedTimeLookup;
export const selectBlockedTimesAsEvents = (state: GlobalState) =>
    state.smtInstructorList.blockedTimesAsEvents;
export const selectPagesCount = (state: GlobalState) =>
    state.smtInstructorList.pagesCount;
export const selectCurrentPageIndex = (state: GlobalState) =>
    state.smtInstructorList.currentPageIndex;
export const selectSize = (state: GlobalState) => state.smtInstructorList.size;
export default smtInstructorListSlice.reducer;

export enum PropertyFilterKeys {
    Status = 'instructor_status',
    Type = 'instructor_type',
    Geography = 'geo',
    Region = 'instructor_region',
    City = 'city',
    Country = 'country',
    Atp = 'sponsoring_company_name',
    DeliveryCountries = 'delivery_countries',
    DeliveryLanguages = 'delivery_languages',
    Courses = 'courses',
    CourseStatus = 'course_status',
    Certifications = 'certifications',
    Freelancer = 'is_freelancer',
    FreelanceAgency = 'freelance_agency',
    Programs = 'programs',
    Providers = 'providers',
    AWS_MANAGER = 'amazon_manager_name',
}

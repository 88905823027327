import { Select, Autosuggest } from '@amzn/awsui-components-react';

export enum AdminBusinessDataFormInputType {
    Text = 'text',
    Number = 'number',
    StatusRadio = 'statusRadio',
    Multiselect = 'multiselect',
    Select = 'select',
    Autosuggest = 'Autosuggest',
    YesNoRadio = 'YesNo',
}

interface AdminBusinessDataFormSchemaBase<BusinessDataType> {
    label: string;
    required: boolean;
    disabled: boolean;
    defaultValue: any;
    formDataTransform: (val: any) => Partial<BusinessDataType>;
}

export interface AdminBusinessDataFormSchemaItem<BusinessDataType>
    extends AdminBusinessDataFormSchemaBase<BusinessDataType> {
    type:
        | AdminBusinessDataFormInputType.Text
        | AdminBusinessDataFormInputType.StatusRadio
        | AdminBusinessDataFormInputType.YesNoRadio;
}

export interface AdminBusinessDataFormSchemaMultiselect<BusinessDataType>
    extends AdminBusinessDataFormSchemaBase<BusinessDataType> {
    type: AdminBusinessDataFormInputType.Multiselect;
    placeholder: string;
    options: Array<Select.IOption | Select.Option | Select.OptionsGroup>;
    lookup: { [key: string]: Select.Option };
}

export interface AdminBusinessDataFormSchemaSelect<BusinessDataType>
    extends AdminBusinessDataFormSchemaBase<BusinessDataType> {
    type: AdminBusinessDataFormInputType.Select;
    placeholder: string;
    options: Array<Select.IOption | Select.Option | Select.OptionsGroup>;
    lookup: { [key: string]: Select.Option };
    filteringType?: string;
    onDelayedFilteringChange?: any;
}

export interface AdminBusinessDataFormSchemaAutosuggest<BusinessDataType>
    extends AdminBusinessDataFormSchemaBase<BusinessDataType> {
    type: AdminBusinessDataFormInputType.Autosuggest;
    placeholder: string;
    options: Array<
        Autosuggest.IOption | Autosuggest.Option | Autosuggest.OptionsGroup
    >;
    lookup: { [key: string]: Autosuggest.Option };
}

export interface AdminBusinessDataFormSchemaNumber<BusinessDataType>
    extends AdminBusinessDataFormSchemaBase<BusinessDataType> {
    type: AdminBusinessDataFormInputType.Number;
    step?: number;
    min?: number;
    precision?: number;
}

export interface AdminBusinessDataFormSchema<BusinessDataType> {
    [key: string]:
        | AdminBusinessDataFormSchemaItem<BusinessDataType>
        | AdminBusinessDataFormSchemaMultiselect<BusinessDataType>
        | AdminBusinessDataFormSchemaSelect<BusinessDataType>
        | AdminBusinessDataFormSchemaNumber<BusinessDataType>
        | AdminBusinessDataFormSchemaAutosuggest<BusinessDataType>;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LocationsResponseData } from '../../interfaces/businessDataResponse/locationsResponse';
import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import { LocationItemData } from '../../interfaces/businessDataItem/locationItem';
import {
    ExtendedBusinessDataStoreInitialState,
    resetBasicBusinessDataStoreState,
    resetExtendedBusinessDataStoreState,
    initializeBusinessDataListQueryParamsStoreState,
} from '../store.common';

/**
 * timezonesSlice manages the list of timezones as retrieved from the locations BDC
 */
export const timezonesSlice = createSlice({
    name: 'timezones',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
        timezoneList: [],
    } as ExtendedBusinessDataStoreState<LocationItemData> & {
        timezoneList: Array<string>;
    },
    reducers: {
        setError: (
            state: ExtendedBusinessDataStoreState<LocationItemData>,
            action: PayloadAction<any>,
        ) => {
            state.error = action.payload;
        },
        setIsLoading: (
            state: ExtendedBusinessDataStoreState<LocationItemData>,
            action: PayloadAction<boolean>,
        ) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (
            state: ExtendedBusinessDataStoreState<LocationItemData>,
            action: PayloadAction<boolean>,
        ) => {
            state.isLoaded = action.payload;
        },
        setCount: (
            state: ExtendedBusinessDataStoreState<LocationItemData>,
            action: PayloadAction<number>,
        ) => {
            state.count = action.payload;
        },
        setTimezoneList: (
            state: ExtendedBusinessDataStoreState<LocationItemData> & {
                timezoneList: Array<string>;
            },
            action: PayloadAction<LocationItemData[]>,
        ) => {
            let timezones: Array<string> = [];
            for (let locationItem of action.payload) {
                if (!timezones.includes(locationItem.city_timezone)) {
                    timezones.push(locationItem.city_timezone);
                }
            }

            state.timezoneList = timezones.sort();
        },
        resetPartialLocationsSlice: resetBasicBusinessDataStoreState,
        resetLocationsSlice: resetExtendedBusinessDataStoreState,
        initializeLocationsListQueryParams:
            initializeBusinessDataListQueryParamsStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setError,
    setCount,
    setTimezoneList,
    resetPartialLocationsSlice,
    resetLocationsSlice,
    initializeLocationsListQueryParams,
} = timezonesSlice.actions;

const handleTimezoneLocationListRequest = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const params: BusinessDataApiQueryParams.GetLocations = {
            active: true,
        };

        try {
            const {
                result: { CITY, total_count },
            }: HandleRequestData<LocationsResponseData> =
                await businessDataApi.getLocations(params);
            dispatch(setTimezoneList(CITY));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(setError(error?.message || 'getLocations API error'));
        }
    };
};

export const getTimezonesList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleTimezoneLocationListRequest());
        if (!state.locations.isLoaded) {
            dispatch(setIsLoaded(true));
        }
        dispatch(setIsLoading(false));
    };
};

export const selectAllTimezones = (state: GlobalState) => {
    return state.timezones.timezoneList;
};

export const selectIsLoading = (state: GlobalState) =>
    state.timezones.isLoading;

export const selectIsLoaded = (state: GlobalState) => state.timezones.isLoaded;

export const selectError = (state: GlobalState) => state.timezones.error;

export const selectCount = (state: GlobalState) => state.timezones.count;

export const selectSearchText = (state: GlobalState) =>
    state.timezones.searchText;

export default timezonesSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppDispatch, GlobalState } from '../../../main/store';
import adminUserManagementApi from '../../../common/api/adminUserManagementApi';
import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { SelectedUserResponseData } from '../../interfaces/selectedUserResponse';
import { AdminUpdateUserPayloadData } from '../../interfaces/adminUpdateUserPayload';
import { UserProfileData } from '../../../common/interfaces/userProfile';
import { AdminCreateUserResponseData } from '../../interfaces/adminCreateUserResponse';
import { MAX_RETRY_TIME, RETRY_DELAY } from '../../../common/constants/grimsby';
import { REACH_MAX_RETRY_ATTEMPTS } from '../../../common/constants/errorMessage';
import { ApiError } from '../../../common/classes/ApiError';

export interface UserState extends GenericStoreState {
    selectedUser: SelectedUserResponseData | null;
    showDeactivateModal: boolean;
    active: boolean | null;
}

/**
 * selectedUserSlice manages all selectedUser state, and contains selectedUser actions as well as selectedUser state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const selectedUserSlice = createSlice({
    name: 'selectedUser',
    initialState: {
        selectedUser: null,
        error: null,
        isLoading: false,
        showDeactivateModal: false,
        active: null,
    } as UserState,
    reducers: {
        setSelectedUser: (
            state,
            action: PayloadAction<SelectedUserResponseData | null>,
        ) => {
            state.selectedUser = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setShowDeactivateModal: (state, action: PayloadAction<boolean>) => {
            state.showDeactivateModal = action.payload;
        },
        setActive: (state, action: PayloadAction<boolean>) => {
            state.active = action.payload;
        },
    },
});

export const {
    setSelectedUser,
    setError,
    setIsLoading,
    setShowDeactivateModal,
} = selectedUserSlice.actions;

/**
 * getUser is an async action used to fetch selectedUser data.
 * There is no explicit inclusion of redux-thunk logic, as the redux toolkit takes care of this for us.
 */
export const adminGetUserById = (id: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setIsLoading(true));

        let count = MAX_RETRY_TIME;

        while (count) {
            try {
                const { result }: HandleRequestData<SelectedUserResponseData> =
                    await adminUserManagementApi.adminGetUserById(id);

                if (result.user) {
                    dispatch(setSelectedUser(result));
                    break;
                }
            } catch (error: any) {
                if (!(error instanceof ApiError && error?.statusCode === 404)) {
                    dispatch(setError(error!.toString()));
                    break;
                }
            }
            count--;

            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }

        if (!count) {
            dispatch(setError(REACH_MAX_RETRY_ATTEMPTS));
        }

        dispatch(setIsLoading(false));
    };
};

export const adminCreateUser = (
    payload: Pick<UserProfileData, 'programs' | 'regions' | 'email'>,
) => {
    return async (
        dispatch: AppDispatch,
    ): Promise<{ isSuccessful: boolean; createdUserId?: string }> => {
        dispatch(setIsLoading(true));
        try {
            const { result }: HandleRequestData<AdminCreateUserResponseData> =
                await adminUserManagementApi.adminCreateUser(payload);
            return {
                isSuccessful: true,
                createdUserId: result.user_id,
            };
        } catch {
            return {
                isSuccessful: false,
            };
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const adminUpdateUser = (
    id: string,
    body: AdminUpdateUserPayloadData,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();

        dispatch(setIsLoading(true));
        let isSuccessful: boolean = false;
        try {
            await adminUserManagementApi.adminUpdateUser(id, body);
            // manually update the user since requesting the updated user right after successful
            // update returns non updated user
            const selectedUser = state.selectedUser
                .selectedUser as SelectedUserResponseData;
            const {
                programs = selectedUser.user.programs,
                active = selectedUser.user.active,
                regions = selectedUser.user.regions,
                vias_roles = selectedUser.user.vias_roles,
            } = body;
            const updatedSelectedUser = {
                ...selectedUser,
                user: {
                    ...selectedUser.user,
                    programs,
                    active,
                    regions,
                    vias_roles,
                },
            };
            dispatch(setSelectedUser(updatedSelectedUser));
            isSuccessful = true;
        } catch (error: any) {
            dispatch(setError(error!.toString()));
        } finally {
            dispatch(setIsLoading(false));
            return isSuccessful;
        }
    };
};

export const selectSelectedUser = (state: GlobalState) =>
    state.selectedUser.selectedUser;
export const selectIsLoading = (state: GlobalState) =>
    state.selectedUser.isLoading;
export const selectShowDeactivateModal = (state: GlobalState) =>
    state.selectedUser.showDeactivateModal;
export const selectError = (state: GlobalState) => state.selectedUser.error;

export default selectedUserSlice.reducer;

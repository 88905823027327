import { ActivityGroupData } from '../../../interfaces/activityGroup';

type activityGroupDataValidations = 'location' | 'groupName';

export const validations: {
    [validationName in activityGroupDataValidations]: {
        isValid: (data: ActivityGroupData) => boolean;
        invalidMessage: string;
    };
} = {
    groupName: {
        isValid: (data) => /^[a-zA-Z0-9-_:.\s]{10,100}$/.test(data.name),
        invalidMessage:
            'Activity group name must be between 10-100 supported characters (supported: a-zA-Z, 0-9, spaces, :_-)',
    },
    location: {
        isValid: (data) =>
            !!(
                data.child_activities.length &&
                data.child_activities[0]?.delivery_city
            ),
        invalidMessage: 'Delivery location must be set before saving',
    },
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form, SpaceBetween } from '@amzn/awsui-components-react-v3';
import {
    addCourse,
    selectIsLoading,
} from '../../../store/slices/selectedCourseSlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { FormSectionProps } from '../../../../common/interfaces/formSectionProps';
import { AdminCoursesFormSection } from '../FormSections/AdminCoursesFormSection';
import { FormSectionMode } from '../../../../common/constants/forms';
import { CourseFormData } from '../../../interfaces/courseForm';
import { AdminCreateCoursePayloadData } from '../../../../common/interfaces/adminCreateBusinessDataPayload/coursePayload';
import { AdminCoursesFormMilestonesSection } from '../FormSections/AdminCoursesFormMilestonesSection';
import { courseFormValidationConfig } from '../Common';

const AdminCoursesCreateForm = ({
    createCourseFormState,
}: {
    createCourseFormState: CourseFormData;
}) => {
    const isLoading = useSelector(selectIsLoading);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    const { isInvalid, errors, validateForm } =
        useFormValidation<CourseFormData>();

    const [formValues, setFormValues] = useState<CourseFormData>(
        createCourseFormState,
    );

    const handleFieldEvent = (changes: Partial<CourseFormData>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    };

    const createCourseProps: Omit<
        FormSectionProps<CourseFormData>,
        'validateAndHandleFieldEvent'
    > & {
        name: string;
    } = {
        formValues,
        errors,
        handleFieldEvent,
        name: 'New Course',
        mode: FormSectionMode.Create,
    };

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, courseFormValidationConfig);
        }
    }, [isInvalid, formValues, validateForm]);

    return (
        <section data-testid="AdminCoursesCreateForm">
            <Form
                actions={
                    <SpaceBetween size="m" direction="horizontal">
                        <Button
                            variant="link"
                            className="admin-user-cancel"
                            data-testid="AdminCoursesCreateFormCancel"
                            onClick={() => {
                                history.push({
                                    pathname: `/admin/business-data/courses`,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            className="admin-course-save"
                            data-testid="AdminCoursesCreateFormAdd"
                            loading={isLoading}
                            onClick={async () => {
                                const invalid = validateForm(
                                    formValues,
                                    courseFormValidationConfig,
                                );

                                if (!invalid) {
                                    const { isSuccessful, createdCourseId } =
                                        await dispatch<any>(
                                            addCourse(
                                                formValues as AdminCreateCoursePayloadData,
                                            ),
                                        );
                                    addNotification({
                                        id: `create-course-${Date.now()}`,
                                        ...(isSuccessful
                                            ? {
                                                  type: 'success',
                                                  content:
                                                      'You have successfully created a course.',
                                              }
                                            : {
                                                  type: 'error',
                                                  content:
                                                      'An error occurred while creating the course.',
                                              }),
                                    });
                                    if (isSuccessful) {
                                        history.push({
                                            pathname: `/admin/business-data/courses/${createdCourseId}`,
                                        });
                                    }
                                }
                            }}
                        >
                            {`${isLoading ? 'Creating' : 'Create'} course`}
                        </Button>
                    </SpaceBetween>
                }
            >
                <SpaceBetween size={'m'}>
                    <AdminCoursesFormSection {...createCourseProps} />
                    <AdminCoursesFormMilestonesSection {...createCourseProps} />
                </SpaceBetween>
            </Form>
        </section>
    );
};

export default AdminCoursesCreateForm;

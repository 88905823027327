import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getActivityModalitiesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllActivityModalities,
    selectError,
    updateActivityModality,
    selectSelectedActivityModality,
    setSelectedActivityModality,
    addActivityModality,
    selectCount,
    resetActivityModalitiesSlice,
} from '../../../common/store/slices/activityModalitiesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { ActivityModalityItemData } from '../../../common/interfaces/businessDataItem/activityModalityItem';

const ACTIVITY_MODALITIES_TABLE_TITLE = 'Activity modalities';
const ACTIVITY_MODALITIES_DISPLAY_SINGULAR = 'Activity modality';
export const ACTIVITY_MODALITY_NAME_KEY = 'activity_modality';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (activityModality: ActivityModalityItemData) =>
            formatStatus(activityModality.active.toString()),
    },
];

/**
 * Editable form fields for ActivityModality
 * The keys must map to ActivityModalityItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<ActivityModalityItemData> =
    {
        // the keys must match ActivityModalityItem properties
        activity_modality: {
            type: AdminBusinessDataFormInputType.Text,
            label: `${ACTIVITY_MODALITIES_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (
                val: string,
            ): Partial<ActivityModalityItemData> => {
                return { activity_modality: val };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<ActivityModalityItemData> => {
                return { active: parseBoolean(val) };
            },
        },
    };

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<ActivityModalityItemData> =
    {
        ...createFormSchema,
        activity_modality: {
            ...createFormSchema.activity_modality,
            disabled: true,
        },
    };

const AdminActivityModalities = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useActivityModalities(): AdminBusinessDataSelectors<ActivityModalityItemData> {
        const dispatch = useDispatch();

        const items: ActivityModalityItemData[] = useSelector(
            selectAllActivityModalities,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: ActivityModalityItemData | null = useSelector(
            selectSelectedActivityModality,
        );
        useEffect(() => {
            dispatch(getActivityModalitiesList());
            dispatch(setSelectedActivityModality(null));

            return () => {
                dispatch(resetActivityModalitiesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (
        activityModality: ActivityModalityItemData,
    ) => {
        if (activityModality) {
            dispatch(
                setSelectedActivityModality(activityModality.activity_modality),
            );
            await dispatch(getActivityModalitiesList());
        }
    };

    const handleItemUpdate = async (data: ActivityModalityItemData) => {
        dispatch(setSelectedActivityModality(null));
        const dispatchPromise = dispatch<any>(updateActivityModality(data));
        await handleBusinessDataNotification({
            businessDataType: ACTIVITY_MODALITY_NAME_KEY,
            businessDataDisplayNameLower:
                ACTIVITY_MODALITIES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.activity_modality,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        activity_modality,
        active,
    }: ActivityModalityItemData) => {
        const dispatchPromise = dispatch<any>(
            addActivityModality(active, activity_modality),
        );
        await handleBusinessDataNotification({
            businessDataType: ACTIVITY_MODALITY_NAME_KEY,
            businessDataDisplayNameLower:
                ACTIVITY_MODALITIES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: activity_modality,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementActivityModalities">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={ACTIVITY_MODALITIES_DISPLAY_SINGULAR}
                itemNameKey={ACTIVITY_MODALITY_NAME_KEY}
                title={ACTIVITY_MODALITIES_TABLE_TITLE}
                useItemList={useActivityModalities}
            />
        </div>
    );
};

export default AdminActivityModalities;

import { Button, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { BlockedTimeFormData } from '../../../../common/interfaces/blockedTime';
import {
    deleteCalendarInstructorReservedTime,
    selectInstructorLookup,
    selectIsLoading,
    selectIsDeleting,
    deleteBlockedTimeForInstructor,
} from '../../../store/slices/instructorListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';

const EDIT_BLOCK_TIME_TEXT = 'Save block time';

interface EditModalFooterProps {
    handleEdit: (reserveTimeFormValues: BlockedTimeFormData) => void;
    formValues: BlockedTimeFormData;
    eventSource: any;
    handleIsVisibleChange: (isVisible: boolean) => void;
    resetBlockedTimeModal: () => void;
}
const EditModalFooter = ({
    handleEdit,
    formValues,
    handleIsVisibleChange,
    resetBlockedTimeModal,
}: EditModalFooterProps) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoading);
    const isDeleting = useSelector(selectIsDeleting);

    const { addNotification } = useNotifications();
    const instructorLookup = useSelector(selectInstructorLookup);

    const handleBlockedTimeDelete = async (
        blockedTimeFormValues: BlockedTimeFormData,
    ) => {
        const deleteBlockedTime = await dispatch(
            deleteBlockedTimeForInstructor(blockedTimeFormValues),
        );
        dispatch(
            deleteCalendarInstructorReservedTime({
                instructor:
                    instructorLookup[blockedTimeFormValues.instructor_pk],
                blockedTime: blockedTimeFormValues,
            }),
        );
        handleIsVisibleChange(false);
        resetBlockedTimeModal();
        addNotification({
            id: `set-blocked-times-${Date.now()}`,
            ...(!!deleteBlockedTime
                ? {
                      type: 'success',
                      content: 'Block time deleted.',
                  }
                : {
                      type: 'error',
                      content:
                          'An error occurred while deleting the block time.',
                  }),
        });
    };

    return (
        <SpaceBetween direction="horizontal" size="xs">
            <Button
                data-testid={'DeleteReserveTime'}
                onClick={() => handleBlockedTimeDelete(formValues)}
                disabled={isDeleting}
            >
                {isDeleting ? 'Deleting' : 'Delete block time'}
            </Button>
            <Button
                variant="primary"
                data-testid={'ReserveTimeConfirm'}
                onClick={() => handleEdit(formValues)}
                disabled={isLoading}
            >
                {isLoading ? 'Saving' : EDIT_BLOCK_TIME_TEXT}
            </Button>
        </SpaceBetween>
    );
};

export default EditModalFooter;

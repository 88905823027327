import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../common/store/slices/userSlice';
import {
    getCoursesList,
    selectAllActiveCourses,
    selectIsLoaded as selectIsCourseListLoaded,
    selectIsLoading as selectIsCourseListLoading,
} from '../../../../common/store/slices/coursesSlice';
import {
    getLocationsList,
    selectAllActiveLocations,
    selectIsLoaded as selectIsLocationListLoaded,
    selectIsLoading as selectIsLocationListLoading,
} from '../../../../common/store/slices/locationsSlice';
import {
    getDeliveryCountriesList,
    selectAllActiveDeliveryCountries,
    selectIsLoading as selectIsDeliveryCountryListLoading,
    selectIsLoaded as selectIsDeliveryCountryListLoaded,
} from '../../../../common/store/slices/deliveryCountriesSlice';
import {
    getDeliveryLanguagesList,
    selectAllActiveDeliveryLanguages,
    selectError as selectDeliveryLanguageListError,
    selectIsLoaded as selectIsDeliveryLanguageLoaded,
    selectIsLoading as selectIsDeliveryLanguageLoading,
} from '../../../../common/store/slices/deliveryLanguagesSlice';
import {
    getInstructorRoleTypesList,
    selectAllActiveInstructorRoleTypes,
    selectError as selectInstructorRoleTypeListError,
    selectIsLoaded as selectIsInstructorRoleTypeListLoaded,
    selectIsLoading as selectIsInstructorRoleTypeListLoading,
} from '../../../../common/store/slices/instructorRoleTypesSlice';
import {
    getInstructorBlockedTimeTypesList,
    selectAllActiveInstructorBlockedTimeTypes,
    selectError as selectInstructorBlockedTimeTypeListError,
    selectIsLoaded as selectIsInstructorBlockedTimeTypeListLoaded,
    selectIsLoading as selectIsInstructorBlockedTimeTypeListLoading,
} from '../../../../common/store/slices/instructorBlockedTimeTypesSlice';
import {
    getGeographiesList,
    selectAllActiveGeographies,
    selectIsLoading as selectIsGeographyListLoading,
    selectIsLoaded as selectIsGeographyListLoaded,
} from '../../../../common/store/slices/geographiesSlice';
import {
    getProgramTypesList,
    selectIsLoading as selectIsProgramTypeListLoading,
    selectIsLoaded as selectIsProgramTypeListLoaded,
    selectAllActiveProgramTypes,
} from '../../../../common/store/slices/programTypesSlice';
import {
    getRegionsList,
    selectAllActiveRegions,
    selectIsLoading as selectIsRegionListLoading,
    selectIsLoaded as selectIsRegionListLoaded,
} from '../../../../common/store/slices/regionsSlice';
import {
    getInstructorTypesList,
    selectAllActiveInstructorTypes,
    selectIsLoading as selectIsTypeListLoading,
    selectIsLoaded as selectIsTypeListLoaded,
} from '../../../../common/store/slices/instructorTypesSlice';
import {
    getInstructorCourseStatusesList,
    selectAllActiveInstructorCourseStatuses,
    selectIsLoading as selectIsCourseStatusListLoading,
    selectIsLoaded as selectIsCourseStatusListLoaded,
} from '../../../../common/store/slices/instructorCourseStatusesSlice';
import { getActivityCityString } from '../../Activity/FormSections/ActivityFormSection';
import { ActivityData } from '../../../interfaces/activity';
import {
    getTimezonesList,
    selectAllTimezones,
    selectIsLoaded as selectIsTimezonesListLoaded,
    selectIsLoading as selectIsTimezonesListLoading,
} from '../../../../common/store/slices/timezonesSlice';

export const useBusinessDatum = () => {
    const [formValues] = useState({} as ActivityData);
    const preselectedCityId = getActivityCityString(formValues);

    // User Profile Slice
    const userProfile = useSelector(selectUser);
    // Course List Slice
    const courseList = useSelector(selectAllActiveCourses);
    const isCourseListLoading = useSelector(selectIsCourseListLoading);
    const isCourseListLoaded = useSelector(selectIsCourseListLoaded);
    // Location List Slice
    const isLocationListLoaded = useSelector(selectIsLocationListLoaded);
    const isLocationListLoading = useSelector(selectIsLocationListLoading);
    const originalLocationList = useSelector(selectAllActiveLocations);
    // Delivery Country List
    const deliveryCountryList = useSelector(selectAllActiveDeliveryCountries);
    const isDeliveryCountryListLoading = useSelector(
        selectIsDeliveryCountryListLoading,
    );
    const isDeliveryCountryListLoaded = useSelector(
        selectIsDeliveryCountryListLoaded,
    );
    // Delivery Language Slice
    const deliveryLanguageList = useSelector(selectAllActiveDeliveryLanguages);
    const isDeliveryLanguageListLoading = useSelector(
        selectIsDeliveryLanguageLoading,
    );
    const isDeliveryLanguageListLoaded = useSelector(
        selectIsDeliveryLanguageLoaded,
    );
    const deliveryLanguageListError = useSelector(
        selectDeliveryLanguageListError,
    );
    // Program Type Slice
    const programTypeList = useSelector(selectAllActiveProgramTypes);
    const isProgramTypeListLoaded = useSelector(selectIsProgramTypeListLoaded);
    const isProgramTypeListLoading = useSelector(
        selectIsProgramTypeListLoading,
    );
    // Region List Slice
    const regionList = useSelector(selectAllActiveRegions);
    const isRegionListLoaded = useSelector(selectIsRegionListLoaded);
    const isRegionListLoading = useSelector(selectIsRegionListLoading);
    // Geography Slice
    const geographyList = useSelector(selectAllActiveGeographies);
    const isGeographyListLoaded = useSelector(selectIsGeographyListLoaded);
    const isGeographyListLoading = useSelector(selectIsGeographyListLoading);
    // Instructor Type Slice
    const instructorTypeList = useSelector(selectAllActiveInstructorTypes);
    const isInstructorTypeListLoaded = useSelector(selectIsTypeListLoaded);
    const isInstructorTypeListLoading = useSelector(selectIsTypeListLoading);
    // Instructor Course Status
    const instructorCourseStatusList = useSelector(
        selectAllActiveInstructorCourseStatuses,
    );
    const isInstructorCourseStatusListLoaded = useSelector(
        selectIsCourseStatusListLoaded,
    );
    const isInstructorCourseStatusListLoading = useSelector(
        selectIsCourseStatusListLoading,
    );
    // Instructor Role Slice
    const isInstructorRoleTypeListLoading = useSelector(
        selectIsInstructorRoleTypeListLoading,
    );
    const isInstructorRoleTypeListLoaded = useSelector(
        selectIsInstructorRoleTypeListLoaded,
    );
    const instructorRoleTypeList = useSelector(
        selectAllActiveInstructorRoleTypes,
    );
    const instructorRoleTypeListError = useSelector(
        selectInstructorRoleTypeListError,
    );

    // Instructor Blocked Time Type Slice
    const isInstructorBlockedTimeTypeListLoading = useSelector(
        selectIsInstructorBlockedTimeTypeListLoading,
    );
    const isInstructorBlockedTimeTypeListLoaded = useSelector(
        selectIsInstructorBlockedTimeTypeListLoaded,
    );
    const instructorBlockedTimeTypeList = useSelector(
        selectAllActiveInstructorBlockedTimeTypes,
    );
    const instructorBlockedTimeTypeListError = useSelector(
        selectInstructorBlockedTimeTypeListError,
    );

    // timezones list
    const timezonesList = useSelector(selectAllTimezones);
    const isTimezonesListLoaded = useSelector(selectIsTimezonesListLoaded);
    const isTimezonesListLoading = useSelector(selectIsTimezonesListLoading);

    const lifeCycleMethods = [
        [!isLocationListLoaded && !isLocationListLoading, getLocationsList],
        [
            !isDeliveryLanguageListLoaded && !isDeliveryLanguageListLoading,
            getProgramTypesList,
        ],
        [
            !isDeliveryLanguageListLoaded && !isDeliveryLanguageListLoading,
            getDeliveryLanguagesList,
        ],
        [
            !isDeliveryCountryListLoaded && !isDeliveryCountryListLoading,
            getDeliveryCountriesList,
        ],
        [
            !isProgramTypeListLoaded && !isProgramTypeListLoading,
            getProgramTypesList,
        ],
        [!isRegionListLoaded && !isRegionListLoading, getRegionsList],
        [!isGeographyListLoaded && !isGeographyListLoading, getGeographiesList],
        [
            !isInstructorTypeListLoaded && !isInstructorTypeListLoading,
            getInstructorTypesList,
        ],
        [
            !isInstructorCourseStatusListLoaded &&
                !isInstructorCourseStatusListLoading,
            getInstructorCourseStatusesList,
        ],
        [!isCourseListLoaded && !isCourseListLoading, getCoursesList],
        [
            !isInstructorRoleTypeListLoaded && !isInstructorRoleTypeListLoading,
            getInstructorRoleTypesList,
        ],
        [
            !isInstructorBlockedTimeTypeListLoaded &&
                !isInstructorBlockedTimeTypeListLoading,
            getInstructorBlockedTimeTypesList,
        ],
        [!isTimezonesListLoaded && !isTimezonesListLoading, getTimezonesList],
    ] as ReadonlyArray<[boolean, Function]>;

    return {
        formValues,
        userProfile,
        courseList,
        isLocationListLoaded,
        originalLocationList,
        deliveryCountryList,
        preselectedCityId,
        deliveryLanguageList,
        lifeCycleMethods,
        instructorTypeList,
        programTypeList,
        geographyList,
        regionList,
        instructorCourseStatusList,
        instructorRoleTypeList,
        isInstructorRoleTypeListLoading,
        isInstructorRoleTypeListLoaded,
        instructorRoleTypeListError,
        instructorBlockedTimeTypeList,
        isInstructorBlockedTimeTypeListLoading,
        isInstructorBlockedTimeTypeListLoaded,
        instructorBlockedTimeTypeListError,
        isDeliveryLanguageListLoading,
        isDeliveryLanguageListLoaded,
        deliveryLanguageListError,
        timezonesList,
    };
};

import React from 'react';

import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    Alert,
} from '@amzn/awsui-components-react-v3';

interface EditDeliveryDetailsNoPrimaryModalProps {
    visible: boolean;
    onConfirm: any;
    onCancel: any;
    instructorConflict: any;
    autoAssignInstructor: boolean;
}

const EditDeliveryDetailsNoPrimaryModal = ({
    onCancel,
    onConfirm,
    visible,
    autoAssignInstructor,
}: EditDeliveryDetailsNoPrimaryModalProps) => {
    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmAssignModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmAssignInstructorCancel`}
                            key={`ConfirmAssignInstructorCancel`}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmAssignInstructorConfirm`}
                            onClick={() => {
                                onConfirm();
                            }}
                            key={`ConfirmAssignInstructorConfirm`}
                        >
                            {autoAssignInstructor
                                ? 'Continue with auto assignment'
                                : 'Continue without primary instructor'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Confirm reschedule activity`}
        >
            <Alert
                onDismiss={() => {}}
                visible={true}
                dismissAriaLabel="Close alert"
                type="warning"
            >
                You're saving this activity without a primary instructor. Are
                you sure you want to continue?
            </Alert>
        </Modal>
    );
};

export default EditDeliveryDetailsNoPrimaryModal;

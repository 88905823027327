import 'regenerator-runtime';
import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { emailRegEx } from './formValidation';
import { LookupData } from '../../smt/interfaces/lookup';

const UNAVAILABLE = 'unavailable';
export const FILE_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const FILE_EXTENSION = '.xlsx';

interface FreelanceAvailabilityDict {
    instructor_email: string;
    available_dates: string[];
}

export class ExcelUtil {
    public readonly workbook: Workbook;
    public readonly errors: string[];
    public readonly headerValidations: boolean[];
    public readonly freelancerAvailability: {
        instructors_availability: LookupData<FreelanceAvailabilityDict>;
        start_date: string;
        end_date: string;
    };
    public rowCount: number;
    private worksheet: Worksheet;

    constructor(workbook: Workbook) {
        this.workbook = workbook;
        this.headerValidations = [];
        this.errors = [];
        this.freelancerAvailability = {
            instructors_availability: {},
            start_date: '',
            end_date: '',
        };
    }

    public async writeWorkbook() {
        const buffer = await this.workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: FILE_TYPE });
        saveAs(blob, `freelancer-import-template${FILE_EXTENSION}`);
    }

    public async saveAsUrl() {
        const buffer = await this.workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: FILE_TYPE });
        // create file url
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
            'download',
            `errors-freelance-schedule${FILE_EXTENSION}`,
        );
        fileLink.setAttribute('type', FILE_TYPE);
        fileLink.innerText = 'Excel file';
        fileLink.id = 'error-file-download';
        const tempAnchor = document.getElementById('error-file-download');
        tempAnchor!.parentNode!.replaceChild(fileLink, tempAnchor as Node);
    }

    public generateErrorReport(conflictingAssignments) {
        const worksheet = this.workbook.addWorksheet('Sheet1');
        const columns = [];
        for (const [index, [email, values]] of Object.entries(
            Object.entries(conflictingAssignments),
        )) {
            const conflicts = values as string[];
            columns.push({
                header: email,
                key: index,
                values: conflicts.map((conflict) => ({
                    text: `${window.location.origin}/activities/${conflict}`,
                    hyperlink: `${window.location.origin}/activities/${conflict}`,
                })),
            });
        }
        worksheet.columns = columns;
        columns.forEach(({ key, values, header }) => {
            worksheet.getColumn(key).values = [header, ...values];
        });

        return this;
    }

    public async readWorkbook(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.readAsArrayBuffer(file);
            reader.onload = () => {
                const buffer = reader.result;
                //@ts-ignore
                this.workbook.xlsx.load(buffer).then((workbook) => {
                    this.worksheet = workbook.getWorksheet(1);
                    this.rowCount = this.worksheet.rowCount;
                    const headerRow = this.worksheet.getRow(1);
                    this.validateHeaders(headerRow);
                    // Check headers validated
                    if (this.headerValidations.every(Boolean)) {
                        this.freelancerAvailability.start_date = this.worksheet
                            .getRow(2)
                            .getCell(1).text;
                        this.freelancerAvailability.end_date =
                            this.worksheet.lastRow.getCell(1).text;
                        this.buildFreelancerDict().populateFreelancerDict();
                    } else {
                        this.errors.push('header-validation');
                    }
                    resolve(true);
                });
            };
        });
    }

    public buildFreelancerDict() {
        this.worksheet.getRow(1).eachCell((cell, colNumb) => {
            if (emailRegEx.test(cell.text)) {
                this.freelancerAvailability.instructors_availability[colNumb] =
                    {
                        instructor_email: cell.text,
                        available_dates: [],
                    };
            }
        });
        return this;
    }

    public populateFreelancerDict() {
        this.worksheet.eachRow((row, rowIndex) => {
            if (rowIndex !== 1) {
                row.eachCell((cell, cellIndex) => {
                    const instructorAvailability =
                        !!cell.text && cell.text.toLowerCase() !== UNAVAILABLE;
                    if (
                        this.freelancerAvailability.instructors_availability.hasOwnProperty(
                            cellIndex,
                        ) &&
                        instructorAvailability
                    ) {
                        this.freelancerAvailability.instructors_availability[
                            cellIndex
                        ].available_dates.push(row.getCell(1).text);
                    }
                });
            }
        });
    }

    private validateHeaders(row) {
        const headerRow = row.values.filter((x) => x !== undefined);
        for (let i = 0; i < Number(headerRow.length); i++) {
            const cellValue = headerRow[i];
            if (typeof cellValue === 'object') {
                this.headerValidations.push(emailRegEx.test(cellValue.text));
            } else if (i !== 0 && emailRegEx.test(cellValue)) {
                this.headerValidations.push(emailRegEx.test(cellValue));
            }
        }
    }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HandleRequestData } from '../../interfaces/handleRequest';
import businessDataApi from '../../api/businessDataApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { ExtendedBusinessDataStoreState } from '../../interfaces/businessDataStoreState';
import parseBoolean from '../../utils/parseBoolean';
import { CourseCategoryItemData } from '../../interfaces/businessDataItem/courseCategoryItem';
import { CourseCategoryResponseData } from '../../interfaces/businessDataResponse/courseCategoryResponse';
import {
    ExtendedBusinessDataStoreInitialState,
    getActiveBusinessDataValues,
    resetBasicBusinessDataStoreState,
} from '../store.common';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';

/**
 * courseCategorysSlice manages all courseCategories state, and contains courseCategories actions as well as courseCategories state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const courseCategoriesSlice = createSlice({
    name: 'courseCategories',
    initialState: {
        ...ExtendedBusinessDataStoreInitialState,
    } as ExtendedBusinessDataStoreState<CourseCategoryItemData>,
    reducers: {
        setCourseCategoriesList: (
            state,
            action: PayloadAction<CourseCategoryItemData[]>,
        ) => {
            const byCourseCategoryName = action.payload.reduce(
                (
                    byCourseCategoryName: {
                        [key: string]: CourseCategoryItemData;
                    },
                    courseCategory: CourseCategoryItemData,
                ) => {
                    byCourseCategoryName[courseCategory.course_category] = {
                        ...courseCategory,
                        active: parseBoolean(courseCategory.active),
                    };
                    return byCourseCategoryName;
                },
                {},
            );
            state.entities = byCourseCategoryName;
            state.keysList = Object.keys(byCourseCategoryName);
        },
        setCourseCategory: (
            state,
            action: PayloadAction<CourseCategoryItemData>,
        ) => {
            // this reducer may be used when adding a new program or updating an existing one.
            // only add to keysList and update count if adding a new program
            if (!state.entities[action.payload.course_category]) {
                state.keysList = [
                    ...state.keysList,
                    action.payload.course_category,
                ];
                state.count = state.keysList.length;
            }
            state.entities[action.payload.course_category] = action.payload;
        },
        setSelectedCourseCategory: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedItemKey = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setActive: (
            state,
            action: PayloadAction<BusinessDataApiQueryParams.Active>,
        ) => {
            state.active = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        resetCourseCategoriesSlice: resetBasicBusinessDataStoreState,
    },
});

export const {
    setIsLoading,
    setIsLoaded,
    setCourseCategoriesList,
    setCourseCategory,
    setSelectedCourseCategory,
    setError,
    setCount,
    setSearchText,
    setActive,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    resetCourseCategoriesSlice,
} = courseCategoriesSlice.actions;

const handleCourseCategoriesListRequest = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const {
                result: { COURSE_CATEGORY, total_count },
            }: HandleRequestData<CourseCategoryResponseData> =
                await businessDataApi.getCourseCategories({ active: 'all' });
            dispatch(setCourseCategoriesList(COURSE_CATEGORY));
            dispatch(setCount(total_count));
        } catch (error: any) {
            dispatch(
                setError(error?.message || 'getCourseCategories API error'),
            );
        }
    };
};

export const getCourseCategoryList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));
        await dispatch(handleCourseCategoriesListRequest());
        dispatch(setIsLoading(false));
        if (!state.courseCategories.isLoaded) {
            dispatch(setIsLoaded(true));
        }
    };
};

/**
 * action to update program
 * before update api call, fetch program types and prepare payload containing only the changed fields
 * after update api call, fetch program types and update the current item in that keysList with the item data we updated
 * @param data
 */
export const updateCourseCategory = (data: CourseCategoryItemData) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        // immediately put the keysList into loading while we finish updating the item/fetching the keysList
        dispatch(setIsLoading(true));
        if (!data.pk || data?.pk.length <= 0) {
            // eslint-disable-next-line no-console
            console.error(
                `Error updating new program: required field pk was missing`,
            );
            dispatch(setIsLoading(false));
            return false;
        }
        try {
            await businessDataApi.updateCourseCategory(data.pk, data.active);
            await dispatch(handleCourseCategoriesListRequest());
            // force-update the item in the keysList for now, since the keysList we fetched may not have this item's update yet
            dispatch(
                setCourseCategory({
                    ...state.courseCategories.entities[data.course_category],
                    active: data.active,
                }),
            );
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const addCourseCategory = (
    active = true,
    courseCategoryName: string,
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => GlobalState,
    ): Promise<boolean> => {
        const state = getState();
        dispatch(setIsLoading(true));
        try {
            await businessDataApi.addCourseCategory(courseCategoryName, active);
            // now refresh the keysList
            await dispatch(handleCourseCategoriesListRequest());
            if (!state.courseCategories?.entities[courseCategoryName]) {
                // shove the item into the keysList, but only if it's not there already
                const newCourseCategoryData: CourseCategoryItemData = {
                    course_category: courseCategoryName,
                    active: active,
                };
                dispatch(setCourseCategory(newCourseCategoryData));
            }
            dispatch(setIsLoading(false));
            return true;
        } catch (error: any) {
            dispatch(setIsLoading(false));
            return false;
        }
    };
};

export const selectAllCourseCategories = (state: GlobalState) => {
    return state.courseCategories.keysList.map(
        (courseCategoryName) =>
            state.courseCategories.entities[courseCategoryName],
    );
};

export const selectAllActiveCourseCategories = (
    state: GlobalState,
): Array<CourseCategoryItemData> => {
    return getActiveBusinessDataValues(
        state.courseCategories.keysList,
        state.courseCategories.entities,
    );
};

export const selectIsLoading = (state: GlobalState) =>
    state.courseCategories.isLoading;

export const selectIsLoaded = (state: GlobalState) =>
    state.courseCategories.isLoaded;

export const selectSelectedCourseCategory = (state: GlobalState) => {
    return state?.courseCategories?.selectedItemKey
        ? state?.courseCategories?.entities[
              state?.courseCategories?.selectedItemKey
          ]
        : null;
};

export const selectError = (state: GlobalState) => state.courseCategories.error;

export const selectCount = (state: GlobalState) => state.courseCategories.count;

export const selectSearchText = (state: GlobalState) =>
    state.courseCategories.searchText;

export const selectPagesCount = (state: GlobalState) =>
    state.courseCategories.pagesCount;

export const selectCurrentPageIndex = (state: GlobalState) =>
    state.courseCategories.currentPageIndex;

export default courseCategoriesSlice.reducer;

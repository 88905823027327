import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Alert, Spinner } from '@amzn/awsui-components-react';

import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';
import { SelectedUserResponseData } from '../../../interfaces/selectedUserResponse';
import {
    adminGetUserById,
    selectError,
    selectIsLoading,
    selectSelectedUser,
} from '../../../store/slices/selectedUserSlice';
import AdminUserManagementEditForm from './AdminUserManagementEditForm';

const AdminUserManagementEdit = () => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const selectedUser = useSelector(
        selectSelectedUser,
    ) as SelectedUserResponseData;
    const match = useRouteMatch<{ id: string }>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!error && selectedUser?.user?.pk !== match.params.id) {
            dispatch(adminGetUserById(match.params.id));
        }
    }, [dispatch, match.params.id, error, selectedUser]);

    if (error) {
        return (
            <Alert
                data-testid="AdminUserManagementEditError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (!isLoading && selectedUser?.user) {
        return <AdminUserManagementEditForm user={selectedUser.user} />;
    } else {
        return <Spinner data-testid="AdminUserManagementEditSpinner" />;
    }
};

export default AdminUserManagementEdit;

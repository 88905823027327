import { HandleRequestData } from '../../interfaces/handleRequest';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { GeographiesResponseData } from '../../interfaces/businessDataResponse/geographiesResponse';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const GEOGRAPHIES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/geos`;

/**
 * Method to request the full list of geographies
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - query params
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getGeographies = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<GeographiesResponseData>> => {
    const path = `${GEOGRAPHIES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<GeographiesResponseData>(path, {
        method: 'GET',
    });
};

const geographiesApi = {
    getGeographies,
};

export default geographiesApi;

import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    Box,
    Button,
    Form,
    FormField,
    Header,
    Input,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import {
    Field,
    FieldArray,
    FieldMetaProps,
    FieldProps,
    Formik,
    FormikProps,
} from 'formik';

import { SmtInstructorFilters } from '../../../interfaces/smtInstructorFilters';

export interface SavedFilter {
    id: string;
    name: string;
    filter: SmtInstructorFilters;
}
export interface SavedFilterForm {
    filters: Array<SavedFilter>;
}

export interface ManageSavedFiltersModalProps {
    savedFilters: any;
    saveFilters: (updatedFilters?: SavedFilterForm) => void;
    isManageFilterModalActive: boolean;
    setIsManageFilterModalActive: Dispatch<SetStateAction<boolean>>;
    isUserLoading: boolean;
}

const ManageSavedFiltersModal = ({
    savedFilters,
    saveFilters,
    isManageFilterModalActive,
    setIsManageFilterModalActive,
    isUserLoading,
}: ManageSavedFiltersModalProps) => {
    const [validateOnChange, setValidateOnChange] = useState(false);
    const getSavedFilterFormValues = (): SavedFilterForm => {
        return {
            filters: savedFilters.map((filter: any) => {
                return {
                    id: `${Date.now()}_${filter.name}`,
                    ...filter,
                };
            }),
        };
    };

    const validateFilterName = (
        { filters }: SavedFilterForm,
        filterItem: SavedFilter,
        meta: FieldMetaProps<any>,
    ) => {
        if (!filterItem.name) {
            return 'Filter name is required';
        }

        if (meta.initialValue === meta.value) {
            return '';
        }

        const restFilters = filters.filter((item) => item.id !== filterItem.id);

        const isDuplicate = restFilters.some((item: SavedFilter) => {
            return item.name === filterItem.name && item.id !== filterItem.id;
        });

        if (isDuplicate) {
            return 'A filter with this name already exists. Please select another name.';
        } else {
            return '';
        }
    };

    return (
        <Formik
            validateOnChange={validateOnChange}
            validateOnBlur={validateOnChange}
            initialValues={getSavedFilterFormValues()}
            onSubmit={saveFilters}
        >
            {(formikValues: FormikProps<SavedFilterForm>) => (
                <Modal
                    className="saved-filters"
                    header="Manage saved filters"
                    data-testid="ActivityListManageFilterModal"
                    visible={isManageFilterModalActive}
                    size="large"
                    onDismiss={() => setIsManageFilterModalActive(false)}
                    footer={
                        <Box float={'right'}>
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    data-testid="ActivityListManageFilterModalCancel"
                                    variant="link"
                                    onClick={() =>
                                        setIsManageFilterModalActive(false)
                                    }
                                >
                                    Cancel
                                </Button>
                                <Button
                                    data-testid="ActivityListManageFilterUpdateButton"
                                    variant="primary"
                                    onClick={() => {
                                        if (!formikValues.dirty) {
                                            setIsManageFilterModalActive(false);
                                            return;
                                        }

                                        // Polaris guidelines: form with multiple fields
                                        // set validation on change and blur after first submit
                                        if (!validateOnChange) {
                                            setValidateOnChange(true);
                                        }

                                        formikValues.submitForm();
                                    }}
                                    loading={isUserLoading}
                                >
                                    {isUserLoading ? 'Saving' : 'Save'}
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <Form className="awsui-grid">
                        <Header variant="h3">Saved filters</Header>
                        <FieldArray name="filters">
                            {(arrayHelper) => (
                                <>
                                    {formikValues.values.filters.map(
                                        (filter, index) => (
                                            <Field
                                                key={`filter_${index}`}
                                                name={`filters[${index}].name`}
                                                validate={() =>
                                                    validateFilterName(
                                                        formikValues.values,
                                                        filter,
                                                        formikValues.getFieldMeta(
                                                            `filters[${index}].name`,
                                                        ),
                                                    )
                                                }
                                            >
                                                {({
                                                    field,
                                                    form,
                                                    meta,
                                                }: FieldProps) => (
                                                    <FormField
                                                        errorText={
                                                            meta.error ?? ''
                                                        }
                                                        stretch={false}
                                                        secondaryControl={
                                                            <Button
                                                                data-testid={`ActivityInstructorListManageFilterModalDelete_${index}`}
                                                                formAction="none"
                                                                onClick={() => {
                                                                    arrayHelper.remove(
                                                                        index,
                                                                    );

                                                                    form.validateForm();
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        }
                                                        className="smt-instructor-filter-field"
                                                    >
                                                        <Input
                                                            data-testid={`ActivityInstructorListManageFilterModalInput_${index}`}
                                                            name={field.name}
                                                            value={field.value}
                                                            onChange={({
                                                                detail,
                                                            }) => {
                                                                form.setFieldValue(
                                                                    field.name,
                                                                    detail.value,
                                                                );
                                                            }}
                                                        />
                                                    </FormField>
                                                )}
                                            </Field>
                                        ),
                                    )}
                                </>
                            )}
                        </FieldArray>
                    </Form>
                </Modal>
            )}
        </Formik>
    );
};

export default ManageSavedFiltersModal;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import { ClassroomStatus } from '../../../interfaces/activity';
import {
    updateSelectedActivity,
    selectSelectedActivity,
} from '../../../store/slices/selectedActivitySlice';
import useClassroomStatusPolling from './useClassroomStatusPolling';

const useCreateClassroomManagement = () => {
    const dispatch = useDispatch();
    const activity = useSelector(selectSelectedActivity)?.activity;
    const { addNotification } = useNotifications();
    const [submitting, setSubmitting] = useState(false);
    const [pollForClassroomUpdate, setPollForClassroomUpdate] = useState(false);

    const isPollingStopped = useClassroomStatusPolling(
        pollForClassroomUpdate,
        activity,
        dispatch,
        2000,
    );

    const handleSubmitEvent = async () => {
        setSubmitting(true);

        const activityData = {
            ...activity,
            classrooms_status: ClassroomStatus.READY_FOR_CLASSROOM_CREATION,
        };

        const isSuccessful = await dispatch(
            updateSelectedActivity(activity.pk, activityData),
        );
        if (isSuccessful) {
            setPollForClassroomUpdate(true);
            addNotification({
                id: `update-activity-with-class-update`,
                type: 'success',
                content: 'Class Creation initiated.',
            });
        } else {
            addNotification({
                id: `update-activity-with-class-update`,
                type: 'error',
                content: 'An error occurred while updating the activity.',
            });
        }

        setSubmitting(false);
    };

    useEffect(() => {
        if (
            activity?.classrooms_status ===
            ClassroomStatus.READY_FOR_CLASSROOM_CREATION
        ) {
            setPollForClassroomUpdate(true);
        } else {
            setPollForClassroomUpdate(false);
        }

        // Cleanup
        return () => {
            setPollForClassroomUpdate(false);
        };
    }, [activity?.classrooms_status]);

    return {
        submitting,
        handleSubmitEvent,
        isPollingStopped,
    };
};

export default useCreateClassroomManagement;

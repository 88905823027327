import { useState, useCallback, useMemo } from 'react';
import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';
import { pageSizeOptions } from '../components/ActivityGroupListCollectionPreferences';
import { AppDispatch } from '../../../../../main/store';

export function useActivityGroupList({
    combinedQuery,
    totalCount,
}: {
    combinedQuery: PropertyFilterProps.Query;
    dispatch: AppDispatch;
    totalCount: number;
}) {
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);

    const pagesCount = useMemo(
        () => Math.max(1, Math.ceil(totalCount / pageSize)),
        [totalCount, pageSize],
    );

    const nextToken = useMemo(
        () => (currentPageIndex > 1 ? (currentPageIndex - 1) * pageSize : 0),
        [currentPageIndex, pageSize],
    );

    const setPageSizeAndResetPage = useCallback((newPageSize: number) => {
        setPageSize(newPageSize);
        setCurrentPageIndex(1);
    }, []);

    const removeMaxResultsAndNextToken = useCallback(
        (tokens: ReadonlyArray<PropertyFilterProps.Token>) =>
            tokens.filter(
                (token) =>
                    token.propertyKey !== 'max_results' &&
                    token.propertyKey !== 'next_token',
            ),
        [],
    );

    const addPaginationTokens = useCallback(
        (tokens: PropertyFilterProps.Token[]) => [
            ...tokens,
            { propertyKey: 'max_results', value: pageSize, operator: '' },
            { propertyKey: 'next_token', value: nextToken, operator: '' },
        ],
        [pageSize, nextToken],
    );

    const buildUpdatedParams = useCallback(
        () => ({
            ...combinedQuery,
            tokens: addPaginationTokens(
                removeMaxResultsAndNextToken(combinedQuery.tokens),
            ),
        }),
        [combinedQuery, removeMaxResultsAndNextToken, addPaginationTokens],
    );

    const resetPagination = useCallback(() => {
        setCurrentPageIndex(1);
    }, []);

    return {
        currentPageIndex,
        pageSize,
        pagesCount,
        buildUpdatedParams,
        setCurrentPageIndex,
        setPageSize: setPageSizeAndResetPage,
        resetPagination,
    };
}

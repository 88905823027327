import { InstructorProfileData } from '../../../interfaces/instructorProfile';

type InstructorValidationKeys =
    | 'BASIC_REQUIRED'
    | 'BASIC_EMAIL'
    | 'INTERNAL_REQUIRED'
    | 'INTERNAL_EMAIL'
    | 'EXTERNAL_REQUIRED'
    | 'EXTERNAL_EMAIL'
    | 'EXTERNAL_FREELANCER_REQUIRED'
    | 'EXTERNAL_FREELANCER_EMAIL';

export const INSTRUCTOR_VALIDATION_FIELDS: {
    [key in InstructorValidationKeys]: Array<
        keyof Partial<InstructorProfileData>
    >;
} = {
    BASIC_REQUIRED: [
        'first_name',
        'last_name',
        'email',
        'city',
        'instructor_region',
        'geo',
        'country',
        'instructor_status',
        'instructor_type',
        'onboarding_date',
        'programs',
    ],
    BASIC_EMAIL: ['email', 'aws_lms_email'],
    INTERNAL_REQUIRED: [
        'amazon_alias',
        'amazon_job_role',
        'amazon_manager_name',
        'amazon_manager_email',
    ],
    INTERNAL_EMAIL: ['amazon_manager_email'],
    EXTERNAL_REQUIRED: [
        'authorized_instructor_agreement_status',
        'aws_authorized_candidate_fundamentals',
        'is_freelancer',
        'external_poc',
    ],
    EXTERNAL_EMAIL: ['external_poc'],
    EXTERNAL_FREELANCER_REQUIRED: [
        'freelancer_status',
        'freelance_onboarding_date',
        'freelance_agency',
    ],
    EXTERNAL_FREELANCER_EMAIL: ['freelance_poc_email'],
};

import {
    Checkbox,
    SpaceBetween,
    Badge,
    Modal,
    Box,
    Button,
    Link,
} from '@amzn/awsui-components-react-v3';
import React, { useEffect } from 'react';
import { INSTRUCTOR_PATH } from '../../../../../imt/constants/path';
import { RecommendedInstructorProfileData } from '../../../../interfaces/recommendedInstructorProfile';
import ResourceLabelPopover from '../Popovers/ResourcePopover';
import './Badge.scss';

type ResourceLabelComponentProps = {
    resourceSource: RecommendedInstructorProfileData;
    resourceData: any;
    selectedInstructors: Set<RecommendedInstructorProfileData>;
    handleSelectedInstructorsChange: (
        instructors: Set<RecommendedInstructorProfileData>,
    ) => void;
};

const CheckboxResourceLabel = ({
    selectedInstructors,
    resourceData,
    resourceSource,
    handleSelectedInstructorsChange,
}: ResourceLabelComponentProps) => {
    const [checked, setChecked] = React.useState(false);
    const [showQuickview, setShowQuickview] = React.useState(false);

    const handleCheckbox = (checked: boolean) => {
        if (selectedInstructors.has(resourceSource)) {
            selectedInstructors.delete(resourceSource);
        } else {
            selectedInstructors.add(resourceSource);
        }
        handleSelectedInstructorsChange(selectedInstructors);
        setChecked(!checked);
    };

    useEffect(() => {
        if (selectedInstructors.has(resourceSource)) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [selectedInstructors, resourceSource]);

    return (
        <SpaceBetween direction="horizontal" size="l">
            <Checkbox
                data-testid={`AssignInstructorResourceViewInstructorRadioGroup${resourceData.resource.id}`}
                onChange={() => handleCheckbox(checked)}
                checked={checked}
            />
            <div>
                <Modal
                    visible={showQuickview}
                    header={resourceSource.full_name}
                    size="large"
                    onDismiss={() => {
                        setShowQuickview(false);
                    }}
                    footer={
                        <div className="resource-quickview-footer">
                            <div className="full-details">
                                <Link
                                    data-testid={
                                        'AssignInstructorResourceViewInstructorLink'
                                    }
                                    external
                                    externalIconAriaLabel="Opens in a new tab"
                                    href={`${window.location.origin}${INSTRUCTOR_PATH.LIST}/${resourceSource.pk}`}
                                >
                                    Full instructor details
                                </Link>
                            </div>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            setShowQuickview(false);
                                        }}
                                    >
                                        Close
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                >
                    <ResourceLabelPopover {...resourceSource} />
                </Modal>
                <span
                    onClick={() => {
                        setShowQuickview(true);
                    }}
                >
                    <span className="resource-title">
                        {resourceData.resource.title}
                    </span>
                    {resourceSource.is_freelancer && (
                        <Badge className="fl-badge" color="blue">
                            FL
                        </Badge>
                    )}
                </span>
            </div>
        </SpaceBetween>
    );
};

export default CheckboxResourceLabel;

import { useEffect, useRef, useCallback } from 'react';

function useInterval(callback: () => void, delay: number | null) {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay !== null) {
            callbackRef.current();
        }
    }, [delay]);

    useEffect(() => {
        if (delay === null) return;

        const interval = setInterval(() => {
            callbackRef.current();
        }, delay);

        return () => clearInterval(interval);
    }, [delay]);
}

export default useInterval;

import { HandleRequestData } from '../../interfaces/handleRequest';
import {
    AdminCreateAtpCompanyPayloadData,
    AdminUpdateAtpCompanyPayloadData,
} from '../../interfaces/adminCreateBusinessDataPayload/atpCompany';
import { AtpCompaniesResponseData } from '../../interfaces/businessDataResponse/atpCompaniesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const ATP_COMPANIES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/atp-companies`;

/**
 * Method to request the full list of ATP companies
 * @param {BusinessDataApiQueryParams.GetAtpCompanies} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getAtpCompanies = (
    queryParams?: BusinessDataApiQueryParams.GetAtpCompanies,
): Promise<HandleRequestData<AtpCompaniesResponseData>> => {
    let path = `${ATP_COMPANIES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<AtpCompaniesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an ATP company.
 * @param {AdminUpdateAtpCompanyPayloadData} data - atp company values that can be updated
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateAtpCompany = (
    data: AdminUpdateAtpCompanyPayloadData,
): Promise<HandleRequestData<ResponseData>> => {
    return handleGrimsbyApiRequest<ResponseData>(`${ATP_COMPANIES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
};

/**
 * Method to add an ATP company.
 * @param {AdminUpdateAtpCompanyPayloadData} data - atp company values
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addAtpCompany = (
    data: AdminCreateAtpCompanyPayloadData,
): Promise<HandleRequestData<ResponseData>> => {
    return handleGrimsbyApiRequest<ResponseData>(`${ATP_COMPANIES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
};

const atpCompaniesApi = {
    getAtpCompanies,
    updateAtpCompany,
    addAtpCompany,
};

export default atpCompaniesApi;

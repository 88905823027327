import { useEffect, useState } from 'react';

export const inputTestIdsToDisable = [
    'EditInvoiceTaxID',
    'EditInvoiceBillingFirstName',
    'EditInvoiceBillingLastName',
    'EditInvoiceBillingEmail',
    'EditInvoiceBillingAddress1',
    'EditInvoiceBillingAddress2',
    'EditInvoiceCity',
    'EditInvoiceState',
    'EditInvoiceBillingPostalCode',
];

export const selectTestIdsToDisable = [
    'EditInvoiceCountry',
    'EditInvoicePaymentTerms',
];

export const testIdsToDisable = [
    ...inputTestIdsToDisable,
    ...selectTestIdsToDisable,
];

type DisabledInputsType = {
    [x: string]: boolean;
};

const useDisabledInputsHook = (shouldDisable: boolean) => {
    const inputTestIdsToDisableObject =
        testIdsToDisable.reduce<DisabledInputsType>((acc, id) => {
            acc[id] = shouldDisable;
            return acc;
        }, {});

    return inputTestIdsToDisableObject;
};

export default useDisabledInputsHook;

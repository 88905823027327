import React from 'react';

import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    ColumnLayout,
    FormField,
    Select,
} from '@amzn/awsui-components-react-v3';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';

interface EditDeliveryDetailsOverrideAutoAssignmentModel {
    visible: boolean;
    onConfirm: any;
    onCancel: any;
    setOverrideReason: any;
    overrideReason: OptionDefinition | null;
}

const CONFIRM_OVERRIDE_AUTO_ASSIGNMENT_DESCRIPTION_TEXT =
    'Please provide a reason for overriding auto assigned instructor for this activity to proceed.';

const EditDeliveryDetailsOverrideAutoAssignmentModel = ({
    onCancel,
    onConfirm,
    visible,
    setOverrideReason,
    overrideReason,
}: EditDeliveryDetailsOverrideAutoAssignmentModel) => {
    const [error, setError] = React.useState<boolean>(false);
    const overrideReasons = [
        {
            label: 'A specific trainer was requested for this class',
            id: 'A specific trainer was requested for this class',
        },
        {
            label: 'Automation assigned an instructor that does not meet class requirements',
            id: 'Automation assigned an instructor that does not meet class requirements',
        },
        {
            label: 'Shuffling instructor to accommodate new class',
            id: 'Shuffling instructor to accommodate new class ',
        },
        {
            label: 'TDM requesting change to balance trainer utilization',
            id: 'TDM requesting change to balance trainer utilization',
        },
        {
            label: 'TDM requesting change due to non-standard delivery timing or content',
            id: 'TDM requesting change due to non-standard delivery timing or content ',
        },
        {
            label: 'Trainers preference',
            id: 'Trainers preference',
        },
        {
            label: 'Other',
            id: 'Other',
        },
    ];

    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmAssignModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmAssignInstructorCancel`}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmAssignInstructorConfirm`}
                            onClick={() => {
                                if (!overrideReason) {
                                    setError(true);
                                } else {
                                    onConfirm();
                                }
                            }}
                        >
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Confirm override auto assignment`}
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField
                        label="Override reason"
                        description={
                            CONFIRM_OVERRIDE_AUTO_ASSIGNMENT_DESCRIPTION_TEXT
                        }
                        className="override-reason-form-field"
                        errorText={
                            error
                                ? 'You must select a reason for overriding auto assigned instructor.'
                                : null
                        }
                    >
                        <Select
                            placeholder={`Select a override auto assignment reason`}
                            options={overrideReasons}
                            selectedOption={overrideReason}
                            onChange={(e) => {
                                setOverrideReason(e?.detail?.selectedOption);
                            }}
                            loadingText="Loading activity statuses"
                            errorText="An error occurred while loading activity statuses"
                            recoveryText="Retry"
                            data-testid={`DisableAutoAssignmentReason`}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </Modal>
    );
};

export default EditDeliveryDetailsOverrideAutoAssignmentModel;

import { Button } from '@amzn/awsui-components-react-v3';
import { ClassroomStatus } from '../../../../interfaces/activity';
import useClassroomManagement from '../../Common/useClassroomManagement';

export const CreateClassroomButton = ({
    classroomStatus,
    classroomArnExists,
}: {
    classroomStatus: string;
    classroomArnExists: boolean;
}) => {
    const { handleSubmitEvent, submitting } = useClassroomManagement();

    let isEligibleForClassroomCreation = false;
    let hasSuccessfullyCreatedClassroom = false;
    let isCreatingClassroom = submitting;
    let shouldHideButton = false;

    switch (classroomStatus) {
        case ClassroomStatus.ELIGIBLE_FOR_CLASSROOM_CREATION:
        case ClassroomStatus.CLASSROOM_PROVISIONING_FAILED:
            isEligibleForClassroomCreation = true;
            break;
        case ClassroomStatus.CLASSROOM_READY:
            hasSuccessfullyCreatedClassroom = true;
            break;
        case ClassroomStatus.READY_FOR_CLASSROOM_CREATION:
            isCreatingClassroom = true;
            break;
        case ClassroomStatus.CLASSROOM_UPDATE_FAILED:
        case ClassroomStatus.CANCELLED:
            shouldHideButton = true;
            break;
        default:
            break;
    }

    return shouldHideButton ? null : (
        <Button
            data-testid="create-classroom-button"
            disabled={
                !isEligibleForClassroomCreation ||
                hasSuccessfullyCreatedClassroom ||
                classroomArnExists
            }
            iconName={
                hasSuccessfullyCreatedClassroom ? 'status-positive' : null
            }
            onClick={() => {
                handleSubmitEvent();
            }}
            loading={isCreatingClassroom}
        >
            {isCreatingClassroom
                ? 'Creating Classroom'
                : hasSuccessfullyCreatedClassroom
                  ? 'Classroom Created'
                  : 'Create Classroom'}
        </Button>
    );
};

import { useContext } from 'react';
import AuthorizationContext, {
    UseAuthorizationProps,
} from '../context/AuthorizationProvider';

const useAuthorization = (): UseAuthorizationProps => {
    const context = useContext(AuthorizationContext);
    if (context === undefined) {
        throw new Error(
            'useAuthorization must be used within AuthorizationProvider context; ' +
                'Wrap component with AuthorizationProvider',
        );
    }
    return context;
};

export default useAuthorization;

const handlePreSelectedValue = <T>(
    preSelected: T | Array<T>,
    shouldCheckForValue: boolean,
    valueList: ReadonlyArray<T>,
    predicate: (value: T) => boolean,
) => {
    if (shouldCheckForValue) {
        if (Array.isArray(preSelected)) {
            const processedPreselected = preSelected.filter(predicate);
            return [...valueList, ...processedPreselected];
        } else {
            const insertValue = !valueList.some(predicate);
            return [...valueList, ...(insertValue ? [preSelected] : [])];
        }
    } else {
        return valueList as Array<T>;
    }
};

export default handlePreSelectedValue;

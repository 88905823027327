import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react-v3';
import {
    colorBackgroundItemSelected,
    colorBorderItemSelected,
    colorTextBodyDefault,
    colorTextBodySecondary,
} from '@amzn/awsui-design-tokens';
import React from 'react';
import { createUseStyles } from 'react-jss';

import { SelectedFileListProps } from '../interfaces';
import { fireDismissEvent, getBaseMetadata } from '../internal';
import { SelectedFile } from './SelectedFile';

export const SelectedFileList: React.FC<SelectedFileListProps> = ({
    metadata,
    fileList,
    onDismiss,
}: SelectedFileListProps) => {
    const styles = createUseStyles({
        token: {
            alignItems: 'flex-start',
            backgroundColor: colorBackgroundItemSelected,
            borderRadius: '2px',
            border: `1px solid ${colorBorderItemSelected}`,
            boxSizing: 'border-box',
            color: colorTextBodyDefault,
            display: 'flex',
            height: '100%',
            padding: '4px 4px 4px 8px',
        },
        dismissButton: {
            backgroundColor: 'initial',
            border: '1px solid #0000',
            color: colorTextBodySecondary,
            marginLeft: 'auto',
            padding: '0 4px',
        },
    })();

    const baseMetadata = getBaseMetadata(metadata);

    const handleClick = (index: number, file: File) => () =>
        onDismiss && fireDismissEvent(onDismiss, { index, file });

    const items = fileList.map((file: File, idx: number) => {
        return (
            <Box className={styles.token} key={idx}>
                <SelectedFile
                    key={idx}
                    file={file}
                    metadata={baseMetadata}
                    multiple={true}
                />
                <Button
                    variant="icon"
                    iconName="close"
                    className={styles.dismissButton}
                    onClick={handleClick(idx, file)}
                />
            </Box>
        );
    });

    return (
        <SpaceBetween direction="vertical" size="xs">
            {items}
        </SpaceBetween>
    );
};

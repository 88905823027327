import { FormField, Input } from '@amzn/awsui-components-react-v3';

interface ClassSizeInputProps {
    setClassSize: any;
    errors: any;
    classSize: number | null;
}

const ClassSizeInput = ({
    setClassSize,
    errors,
    classSize,
}: ClassSizeInputProps) => (
    <FormField
        data-testid="formField-class-input-size"
        label="Class size"
        errorText={errors?.class_size}
    >
        <Input
            onChange={({ detail }) =>
                setClassSize({ class_size: Number(detail.value) })
            }
            type="number"
            value={`${classSize ?? ''}`}
            data-testid="class-size-input"
        />
    </FormField>
);

export default ClassSizeInput;

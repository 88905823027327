import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { BlockedTimeTimestamp } from '../interfaces/blockedTime';
import { DateRangePickerProps } from '@amzn/awsui-components-react-v3';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getUnixTimeInTimezoneFromDatestring = ({
    datestring,
    timezone = dayjs.tz.guess(),
}: {
    datestring: string;
    timezone?: string;
}) => dayjs.tz(datestring, timezone).toDate().getTime() / 1000;

export const formatUnixTimeInTimezone = ({
    timestamp,
    timezone,
    format,
}: {
    timestamp: number;
    timezone: string;
    format: string;
}) => dayjs.tz(dayjs.unix(timestamp), timezone).format(format);

export const unixToHourMinutes = (timestamp: number) =>
    dayjs.unix(timestamp).utc().format('hh:mm');

export const unixToDateString = (timestamp: number) =>
    dayjs.unix(timestamp).format('YYYY-MM-DD');

export const isSameDayInTimezone = (a: number, b: number) =>
    dayjs.unix(a).isSame(dayjs.unix(b), 'day');

export const startOfDayInTimezone = (timestamp: number, timezone: string) => {
    const datestring = formatUnixTimeInTimezone({
        timestamp,
        timezone,
        format: 'YYYY-MM-DDTHH:mm:ss',
    });
    const startOfDayInTimezone = dayjs(datestring)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
    return getUnixTimeInTimezoneFromDatestring({
        datestring: startOfDayInTimezone,
        timezone,
    });
};
export const getRelativeDateRange = ({
    relativeValue,
    timezone = dayjs.tz.guess(),
}: {
    relativeValue: DateRangePickerProps.RelativeValue;
    timezone?: string;
}): { starts_after?: number; ends_before?: number; starts_before?: number } => {
    const { amount, unit } = relativeValue;
    const dayJsObjectWithCurrentTimezone = dayjs().tz(timezone);
    const now = getUnixTimeInTimezoneFromDatestring({
        datestring: dayJsObjectWithCurrentTimezone.format(
            'YYYY-MM-DDTHH:mm:ssZ',
        ),
    });
    const userSelection = dayJsObjectWithCurrentTimezone
        .add(amount, unit)
        .format('YYYY-MM-DDTHH:mm:ssZ');

    if (amount >= 0) {
        return {
            starts_before: getUnixTimeInTimezoneFromDatestring({
                datestring: userSelection,
            }),
            starts_after: now,
        };
    } else {
        return {
            starts_before: now,
            starts_after: getUnixTimeInTimezoneFromDatestring({
                datestring: userSelection,
            }),
        };
    }
};

export const endOfDayInTimezone = (timestamp: number, timezone: string) => {
    const datestring = formatUnixTimeInTimezone({
        timestamp,
        timezone,
        format: 'YYYY-MM-DDTHH:mm:ss',
    });
    const startOfDayInTimezone = dayjs(datestring)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
    return getUnixTimeInTimezoneFromDatestring({
        datestring: startOfDayInTimezone,
        timezone,
    });
};

export const convertTimeStampToLocalUnix = ({
    timestamp,
    timezone,
}: {
    timestamp: number;
    timezone: string;
}) => {
    const instructorTimeString = dayjs
        .tz(dayjs.unix(timestamp), timezone)
        .format('YYYY-MM-DDTHH:mm:ss');
    return dayjs(instructorTimeString).unix();
};

export const checkTimesAsDateRange = (
    timestamps: BlockedTimeTimestamp[],
    timezone: string,
) => {
    if (timestamps.length === 1) {
        const timestamp = timestamps[0];
        const startTime = formatUnixTimeInTimezone({
            timestamp: timestamp.start_timestamp,
            timezone,
            format: 'MM-DD-YYY',
        });
        const endTime = formatUnixTimeInTimezone({
            timestamp: timestamp.end_timestamp,
            timezone,
            format: 'MM-DD-YYY',
        });
        return startTime !== endTime;
    } else {
        return false;
    }
};

import React from 'react';
import { ActivityData, Reschedule } from '../../../../interfaces/activity';
import { Table, TablePagination } from '@amzn/awsui-components-react';
import formatDate, {
    decodeDateNumber,
} from '../../../../../common/utils/formatDate';
import { GRIMSBY_PAGE_COUNT } from '../../../../../common/constants/grimsby';
import { formatString } from '../../../../../common/utils/formatString';

export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'previous_start_timestamp',
        header: 'Previous Start Date',
        cell: (item: Reschedule) =>
            formatDate(decodeDateNumber(item.previous_start_timestamp)),
    },
    {
        id: 'previous_end_timestamp',
        header: 'Previous End Date',
        cell: (item: Reschedule) =>
            formatDate(decodeDateNumber(item.previous_end_timestamp)),
    },
    {
        id: 'reschedule_reason',
        header: 'Reschedule Reason',
        cell: (item: Reschedule) => formatString(item.reschedule_reason),
    },
    {
        id: 'reschedule_action_date',
        header: 'Reschedule Action Date',
        cell: (item: Reschedule) =>
            formatDate(decodeDateNumber(item.reschedule_action_date)),
    },
    {
        id: 'rescheduled_by',
        header: 'Reschedule By',
        cell: (item: Reschedule) => formatString(item.rescheduled_by),
    },
];

const HistoryTab = ({ activity }: { activity: ActivityData }) => {
    const paginationProps: TablePagination.Props = {
        pageSize: GRIMSBY_PAGE_COUNT,
    };

    const reschedules = React.useMemo(() => {
        if (!activity?.reschedules) return [];
        let activity_reschedules = [...activity.reschedules];
        activity_reschedules.sort((r1, r2) => {
            return r1.reschedule_action_date > r2.reschedule_action_date
                ? -1
                : 1;
        });

        return activity_reschedules;
    }, [activity]);

    return (
        <div data-testid="ActivityDetailsHistoryTab">
            <Table
                columnDefinitions={columnDefinitions}
                empty={
                    <div className="awsui-util-t-c awsui-util-status-inactive">
                        <b>No Reschedules</b>
                        <p className="awsui-util-mb-s">
                            Reschedule history will display here.
                        </p>
                    </div>
                }
                items={reschedules}
            >
                {reschedules.length > 0 && (
                    <TablePagination {...paginationProps} />
                )}
            </Table>
        </div>
    );
};

export default HistoryTab;

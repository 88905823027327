import React from 'react';

import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    ColumnLayout,
    FormField,
    Select,
} from '@amzn/awsui-components-react-v3';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';

interface EditDeliveryDetailsRescheduleModalProps {
    visible: boolean;
    onConfirm: any;
    onCancel: any;
    instructorConflict: any;
    setRescheduleReason: any;
    rescheduleReason: OptionDefinition | null;
}

const CONFIRM_RESCHEDULE_DESCRIPTION_TEXT =
    'Please provide a reason for rescheduling this activity to proceed.';

const EditDeliveryDetailsRescheduleModal = ({
    onCancel,
    onConfirm,
    visible,
    setRescheduleReason,
    rescheduleReason,
}: EditDeliveryDetailsRescheduleModalProps) => {
    const [error, setError] = React.useState<boolean>(false);
    const reassignReasons = [
        {
            label: 'Customer requested - scheduling change',
            id: 'Customer requested - scheduling change',
        },
        {
            label: 'Customer requested - low registration',
            id: 'Customer requested - low registration',
        },
        {
            label: 'Low registration - triggered by AWS',
            id: 'Low registration - triggered by AWS',
        },
        {
            label: 'Data entry error',
            id: 'Data entry error',
        },
        {
            label: 'No instructors available',
            id: 'No instructors available',
        },
        {
            label: 'Venue/facility issue',
            id: 'Venue/facility issue',
        },
    ];

    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmAssignModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmAssignInstructorCancel`}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmAssignInstructorConfirm`}
                            onClick={() => {
                                if (!rescheduleReason) {
                                    setError(true);
                                } else {
                                    onConfirm();
                                }
                            }}
                        >
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Confirm reschedule activity`}
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField
                        label="Reschedule reason"
                        description={CONFIRM_RESCHEDULE_DESCRIPTION_TEXT}
                        className="reschedule-reason-form-field"
                        errorText={
                            error
                                ? 'You must select a reschedule reason.'
                                : null
                        }
                    >
                        <Select
                            placeholder={`Select a reschedule reason`}
                            options={reassignReasons}
                            selectedOption={rescheduleReason}
                            onChange={(e) => {
                                setRescheduleReason(e?.detail?.selectedOption);
                            }}
                            loadingText="Loading activity statuses"
                            errorText="An error occurred while loading activity statuses"
                            recoveryText="Retry"
                            data-testid={`ReassignInstructorReason`}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </Modal>
    );
};

export default EditDeliveryDetailsRescheduleModal;

import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';
import { DATE_RANGE_FILTER_KEY } from '../Common/Common';

interface UpdateQueryTokensParams {
    combinedQueryTokens: readonly PropertyFilterProps.Token[];
    newTokens: readonly PropertyFilterProps.Token[];
    shouldIncludeDateRange: boolean;
}

const didUserClickClear = (
    tokens: readonly PropertyFilterProps.Token[] | null | undefined,
): boolean => !tokens || tokens.length === 0;

const filterTokensByDateRange = (
    tokens: readonly PropertyFilterProps.Token[] | null | undefined,
    shouldIncludeDateRange: boolean,
): PropertyFilterProps.Token[] => {
    if (!tokens) {
        return [];
    }

    return tokens.filter((token) => {
        const isDateRangeToken = token.propertyKey === DATE_RANGE_FILTER_KEY;
        return shouldIncludeDateRange ? isDateRangeToken : !isDateRangeToken;
    });
};

export const updateQueryTokens = ({
    combinedQueryTokens,
    newTokens,
    shouldIncludeDateRange,
}: UpdateQueryTokensParams): readonly PropertyFilterProps.Token[] => {
    const dateRangeTokens = filterTokensByDateRange(
        combinedQueryTokens,
        shouldIncludeDateRange,
    );

    return didUserClickClear(newTokens)
        ? dateRangeTokens
        : [...dateRangeTokens, ...newTokens];
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getInstructorCourseStatusesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllInstructorCourseStatuses,
    selectError,
    updateInstructorCourseStatus,
    selectSelectedInstructorCourseStatus,
    setSelectedInstructorCourseStatus,
    addInstructorCourseStatus,
    selectCount,
    resetInstructorCourseStatusesSlice,
} from '../../../common/store/slices/instructorCourseStatusesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { InstructorCourseStatusItemData } from '../../../common/interfaces/businessDataItem/instructorCourseStatusItem';

const INSTRUCTOR_COURSE_STATUSES_TABLE_TITLE = 'Instructor course statuses';
const INSTRUCTOR_COURSE_STATUSES_DISPLAY_SINGULAR = 'Instructor course status';
export const INSTRUCTOR_COURSE_STATUSES_NAME_KEY = 'instructor_course_status';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (instructorCourseStatus: InstructorCourseStatusItemData) =>
            formatStatus(instructorCourseStatus.active.toString()),
    },
];

/**
 * Editable form fields for InstructorCourseStatus
 * The keys must map to InstructorCourseStatusItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<InstructorCourseStatusItemData> =
    {
        // the keys must match InstructorDataStatus properties
        instructor_course_status: {
            type: AdminBusinessDataFormInputType.Text,
            label: `${INSTRUCTOR_COURSE_STATUSES_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (
                val: string,
            ): Partial<InstructorCourseStatusItemData> => {
                return { instructor_course_status: val };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<InstructorCourseStatusItemData> => {
                return { active: parseBoolean(val) };
            },
        },
    };

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<InstructorCourseStatusItemData> =
    {
        ...createFormSchema,
        instructor_course_status: {
            ...createFormSchema.instructor_course_status,
            disabled: true,
        },
    };

const AdminInstructorCourseStatuses = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useInstructorCourseStatuses(): AdminBusinessDataSelectors<InstructorCourseStatusItemData> {
        const dispatch = useDispatch();

        const items: InstructorCourseStatusItemData[] = useSelector(
            selectAllInstructorCourseStatuses,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: InstructorCourseStatusItemData | null = useSelector(
            selectSelectedInstructorCourseStatus,
        );
        useEffect(() => {
            dispatch(getInstructorCourseStatusesList());
            dispatch(setSelectedInstructorCourseStatus(null));

            return () => {
                // if there was an error, reset the whole list so it will be refetched
                // the next time any component needs the list
                dispatch(resetInstructorCourseStatusesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (
        instructorCourseStatus: InstructorCourseStatusItemData,
    ) => {
        if (instructorCourseStatus) {
            dispatch(
                setSelectedInstructorCourseStatus(
                    instructorCourseStatus.instructor_course_status,
                ),
            );
            await dispatch(getInstructorCourseStatusesList());
        }
    };

    const handleItemUpdate = async (data: InstructorCourseStatusItemData) => {
        dispatch(setSelectedInstructorCourseStatus(null));
        const dispatchPromise = dispatch<any>(
            updateInstructorCourseStatus(data),
        );
        await handleBusinessDataNotification({
            businessDataType: INSTRUCTOR_COURSE_STATUSES_NAME_KEY,
            businessDataDisplayNameLower:
                INSTRUCTOR_COURSE_STATUSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.instructor_course_status,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        instructor_course_status,
        active,
    }: InstructorCourseStatusItemData) => {
        const dispatchPromise = dispatch<any>(
            addInstructorCourseStatus(active, instructor_course_status),
        );
        await handleBusinessDataNotification({
            businessDataType: INSTRUCTOR_COURSE_STATUSES_NAME_KEY,
            businessDataDisplayNameLower:
                INSTRUCTOR_COURSE_STATUSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: instructor_course_status,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementInstructorCourseStatuses">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={
                    INSTRUCTOR_COURSE_STATUSES_DISPLAY_SINGULAR
                }
                itemNameKey={INSTRUCTOR_COURSE_STATUSES_NAME_KEY}
                title={INSTRUCTOR_COURSE_STATUSES_TABLE_TITLE}
                useItemList={useInstructorCourseStatuses}
            />
        </div>
    );
};

export default AdminInstructorCourseStatuses;

import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Header } from '@amzn/awsui-components-react-v3';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
    selectIsLoading,
    updateSelectedActivity,
} from '../../../store/slices/selectedActivitySlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { ActivityData } from '../../../interfaces/activity';
import { FORM_ERROR_SELECTOR } from '../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivityModality,
    ActivityProgram,
    ActivitySessionControlArrayFormValues,
    ActivityStatus,
    AudienceType,
    BasicActivityFormSectionProps,
} from '../Common/Common';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import EditBaseDetailsFormSection from '../FormSections/EditBaseDetailsFormSection/EditBaseDetailsFormSection';
import EditBaseDetailsCancellationModal from './EditBaseDetailsModals/EditBaseDetailsCancellationModal';
import CancelModal, {
    CancelModalProps,
} from '../../../../common/components/CancelModal/CancelModal';
import { ACTIVITY_VALIDATION_FIELDS } from '../Common/Validations';
import { UpdateActivityErrorMessage } from '../../Common/constants/flashContent';

const editBaseDetailsValidationConfig: {
    [key in ValidationType]?: Array<keyof ActivityData>;
} = {
    required: [
        'activity_status',
        'activity_audience',
        'activity_modality',
        'activity_name',
    ],
};

export const defaultShowAdditionalFields = {
    showDeliveryAddress: false,
    showNumberOfStudentsAttended: false,
    showPartnerInitiative: false,
    showLMSType: false,
    showClassSize: false,
    showVILTType: false,
};

const EditBaseDetailsForm = ({
    initialFormState,
}: {
    initialFormState: ActivityData;
}) => {
    const match = useRouteMatch<{ id: string }>();
    const isLoading = useSelector(selectIsLoading);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification, removeAllNotifications } = useNotifications();

    const { isInvalid, errors, validateForm } = useFormValidation<
        ActivityData,
        ActivitySessionControlArrayFormValues
    >();

    const [formValues, setFormValues] =
        useState<ActivityData>(initialFormState);
    const [submitting, setSubmitting] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [cancellationReason, setCancellationReason] =
        useState<OptionDefinition | null>(null);
    const [showCancelationReason, setShowCancelationReason] = useState(false);
    const [showAdditionalFields, setShowAdditionalFields] = useState(
        defaultShowAdditionalFields,
    );
    const [dontRemoveData, setDontRemoveData] = useState(false);
    const [cancelFeeType, setCancelFeeType] = useState<string | null>(null);
    const showRemoveDataCheckbox =
        initialFormState.billing_invoices?.length > 0 ||
        initialFormState.customers?.length > 0;

    const navigateToDetailPage = () => {
        history.push({
            pathname: `/activities/${match.params.id}`,
        });
    };

    const getSuccessfulUpdateText = () => {
        let updateText = 'The activity has been updated.';

        if (formValues.activity_status === ActivityStatus.Canceled) {
            if (!dontRemoveData) {
                updateText =
                    'Activity has been canceled and assigned instructors have been released, and customer and invoice data has been removed.';
            } else {
                updateText =
                    'Activity has been canceled and assigned instructors have been released.';
            }
        }

        return updateText;
    };

    useEffect(() => {
        removeAllNotifications();
    }, [removeAllNotifications]);

    useEffect(() => {
        const {
            activity_modality,
            activity_status,
            delivery_address_1,
            attended,
            program,
        } = initialFormState;
        const iltOrHybridModality =
            activity_modality === ActivityModality.ILT ||
            activity_modality === ActivityModality.Hybrid;
        const needsDeliveryAddress =
            activity_status === ActivityStatus.Active && !delivery_address_1;
        const needsNumberOfStudents =
            activity_status === ActivityStatus.Completed && !attended;
        const isPartnerProgram = program === ActivityProgram.Partner;

        setShowAdditionalFields({
            ...defaultShowAdditionalFields,
            showDeliveryAddress: iltOrHybridModality && needsDeliveryAddress,
            showNumberOfStudentsAttended: needsNumberOfStudents,
            showPartnerInitiative: isPartnerProgram,
        });
    }, [
        initialFormState.activity_status,
        initialFormState.activity_modality,
        initialFormState.attended,
        initialFormState.delivery_address_1,
        initialFormState.partner_initiative,
    ]);

    const handleFieldEvent = useCallback((changes: Partial<ActivityData>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    }, []);

    // https://quip-amazon.com/fLHPAp8B8bUU/Activity-Status-Validations
    const getValidationConfig = useCallback(
        (
            formValues,
        ): {
            [key in ValidationType]?: Array<keyof ActivityData>;
        } => {
            const {
                program,
                activity_audience,
                activity_status,
                activity_modality,
            } = formValues;
            const isCompleted = activity_status === ActivityStatus.Completed;
            const isCommercialProgram = program === ActivityProgram.Commercial;
            const isPartnerProgram = program === ActivityProgram.Partner;
            const isActive = activity_status === ActivityStatus.Active;
            const isILT = activity_modality === ActivityModality.ILT;
            const isVILT = activity_modality === ActivityModality.vILT;
            const isHybrid = activity_modality === ActivityModality.Hybrid;
            const isPrivate =
                activity_audience === AudienceType.CommercialPrivate ||
                activity_audience === AudienceType.PartnerPrivate;
            const isCommercialPrivate =
                activity_audience === AudienceType.CommercialPrivate;
            const isCommercialPublic =
                isCommercialProgram &&
                activity_audience === AudienceType.Public;
            if (isCompleted) {
                return {
                    // attended
                    required:
                        ACTIVITY_VALIDATION_FIELDS.ACTIVITY_COMPLETED_DETAILS,
                };
            }

            if (isActive) {
                if (isILT) {
                    if (isCommercialProgram) {
                        return {
                            // delivery_address_1 delivery_postal_code class_size
                            required:
                                ACTIVITY_VALIDATION_FIELDS.ACTIVE_COMMERCIAL_ILT,
                        };
                    }

                    if (isPartnerProgram) {
                        if (!isPrivate) {
                            return {
                                // delivery_address_1 delivery_postal_code
                                required:
                                    ACTIVITY_VALIDATION_FIELDS.DELIVERY_DETAILS_REQUIRED,
                            };
                        }

                        if (isPrivate) {
                            return {
                                // delivery_address_1 delivery_postal_code class_size
                                required:
                                    ACTIVITY_VALIDATION_FIELDS.ACTIVE_PARTNER_PRIVATE_ILT,
                            };
                        }
                    }
                    return {
                        // delivery_address_1 delivery_postal_code
                        required:
                            ACTIVITY_VALIDATION_FIELDS.DELIVERY_DETAILS_REQUIRED,
                    };
                }

                if (isVILT) {
                    if (isCommercialProgram) {
                        if (isPrivate) {
                            return {
                                // class_size lms_type v_ilt_type
                                required:
                                    ACTIVITY_VALIDATION_FIELDS.ACTIVE_COMMERCIAL_PRIVATE_VILT,
                            };
                        }

                        return {
                            required:
                                ACTIVITY_VALIDATION_FIELDS.NO_VALIDATIONS_REQUIRED,
                        };
                    }

                    if (isPartnerProgram) {
                        if (isPrivate) {
                            return {
                                // lms_type v_ilt_type
                                required:
                                    ACTIVITY_VALIDATION_FIELDS.ACTIVE_PARTNER_PRIVATE_VILT,
                            };
                        }

                        return {
                            required:
                                ACTIVITY_VALIDATION_FIELDS.NO_VALIDATIONS_REQUIRED,
                        };
                    }

                    return {
                        required:
                            ACTIVITY_VALIDATION_FIELDS.NO_VALIDATIONS_REQUIRED,
                    };
                }

                if (isHybrid) {
                    if (isCommercialProgram) {
                        if (isCommercialPrivate || isCommercialPublic) {
                            return {
                                // delivery_address_1 delivery_postal_code class_size
                                required:
                                    ACTIVITY_VALIDATION_FIELDS.ACTIVE_COMMERCIAL_HYBRID_PUBLIC_PRIVATE,
                            };
                        }

                        return {
                            // delivery_address_1 delivery_postal_code
                            required:
                                ACTIVITY_VALIDATION_FIELDS.DELIVERY_DETAILS_REQUIRED,
                        };
                    }

                    if (isPartnerProgram) {
                        return {
                            // delivery_address_1 delivery_postal_code
                            required:
                                ACTIVITY_VALIDATION_FIELDS.DELIVERY_DETAILS_REQUIRED,
                        };
                    }
                    return {
                        // delivery_address_1 delivery_postal_code
                        required:
                            ACTIVITY_VALIDATION_FIELDS.DELIVERY_DETAILS_REQUIRED,
                    };
                }
            }

            return {
                required: ACTIVITY_VALIDATION_FIELDS.NO_VALIDATIONS_REQUIRED,
            };
        },
        [],
    );

    const handleScrollToError = () => {
        // this may require attention later for consistent experience in all browsers
        const topMostError = document.querySelector(`.${FORM_ERROR_SELECTOR}`);

        topMostError?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const handleAddCancellationReason = (e: any) => {
        handleFieldEvent({
            cancellation_reason: e.label,
        });
        setCancellationReason(e);
    };

    const handleSubmitEvent = async () => {
        setSubmitting(true);

        const invalid = validateForm(
            formValues,
            getValidationConfig(formValues),
        );

        if (invalid) {
            handleScrollToError();
            setSubmitting(false);
        } else {
            const shouldRemoveRevenues =
                !dontRemoveData &&
                formValues.activity_status === ActivityStatus.Canceled;

            if (cancelFeeType !== null) {
                formValues.is_late_cancellation_fee =
                    cancelFeeType === 'late_cancellation_fee' ? true : false;
                formValues.is_late_reschedule_fee =
                    cancelFeeType === 'late_reschedule_fee' ? true : false;
            }

            if (shouldRemoveRevenues) {
                formValues.customers = formValues.customers
                    ? formValues.customers.map((customer) => {
                          return {
                              ...customer,
                              revenues: [],
                          };
                      })
                    : [];
                formValues.billing_invoices = [];
            }
            const isSuccessful = await dispatch<any>(
                updateSelectedActivity(match.params.id, formValues),
            );

            const updateText = getSuccessfulUpdateText();

            addNotification({
                id: `update-activity`,
                ...(isSuccessful
                    ? {
                          type: 'success',
                          content: updateText,
                      }
                    : {
                          type: 'error',
                          content: <UpdateActivityErrorMessage />,
                      }),
            });

            if (isSuccessful) {
                navigateToDetailPage();
            }
        }
    };

    const getCancellationModal = async () => {
        if (
            formValues.activity_status === ActivityStatus.Canceled &&
            initialFormState.activity_status !== ActivityStatus.Canceled
        ) {
            setShowCancelationReason(true);
        } else {
            handleSubmitEvent();
        }
    };

    const editBaseDetailsFormSectionProps: BasicActivityFormSectionProps = {
        initialFormState,
        formValues,
        errors,
        handleFieldEvent,
        setShowAdditionalFields,
        showAdditionalFields,
        handleSubmitEvent,
    };

    const cancelModalProps: CancelModalProps = {
        cancelModalVisible,
        setCancelModalVisible,
        submitting,
        onCancelConfirm: navigateToDetailPage,
        testPrefix: 'EditBaseDetails',
    };

    const cancelCancellationModal = () => {
        setShowCancelationReason(false);
        setCancelFeeType(null);
    };

    return (
        <>
            <Form
                header={
                    <Header variant="h1">{formValues.activity_name}</Header>
                }
                data-testid="ActivityEditBaseDetailsForm"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-activity-cancel"
                                data-testid="EditBaseDetailsCancel"
                                disabled={submitting}
                                onClick={() => {
                                    setCancelModalVisible(true);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-activity-save"
                                data-testid="EditBaseDetailsSave"
                                disabled={submitting}
                                loading={isLoading}
                                onClick={getCancellationModal}
                            >
                                {submitting ? 'Saving' : 'Save'}
                            </Button>
                        </div>
                    </div>
                }
            >
                <EditBaseDetailsFormSection
                    {...editBaseDetailsFormSectionProps}
                />
            </Form>
            <CancelModal {...cancelModalProps} />
            <EditBaseDetailsCancellationModal
                visible={showCancelationReason}
                data-testid="EditBaseDetailsCancellationModal"
                onCancel={cancelCancellationModal}
                onConfirm={handleSubmitEvent}
                setCancellationReason={handleAddCancellationReason}
                cancellationReason={cancellationReason}
                dontRemoveData={dontRemoveData}
                showRemoveDataCheckbox={showRemoveDataCheckbox}
                setDontRemoveData={setDontRemoveData}
                cancelFeeType={cancelFeeType}
                setCancelFeeType={setCancelFeeType}
            />
        </>
    );
};

export default EditBaseDetailsForm;

import { Box, Button, SpaceBetween } from '@amzn/awsui-components-react-v3';
import {
    BlockedTime,
    BlockedTimeFormData,
    BlockTimeTimeAttributeEditorItem,
} from '../../../../common/interfaces/blockedTime';
import { BLOCK_TIME_TEXT } from './BlockedTimeModal';
import EditModalFooter from './EditModalFooter';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../../../store/slices/instructorListSlice';

export interface BlockedTimeModalFooterProps {
    handleIsVisibleChange: (isVisible: boolean) => void;
    resetBlockedTimeModal: () => void;
    hasMixedInstructors: boolean;
    isEditForm: boolean;
    isFreelancer: boolean;
    handleBlockedTimeEdit: (
        blockedTimeFormValues: BlockedTimeFormData,
        forceDelete: boolean,
    ) => Promise<void | Error>;
    formValues: BlockedTimeFormData;
    eventSource: BlockedTime;
    validateForm: (
        formValues: Partial<BlockedTime>,
        validationConfig: {
            email?: (keyof BlockedTime)[] | undefined;
            required?: (keyof BlockedTime)[] | undefined;
        },
    ) => boolean;
    getValidationConfig: () => {
        email?: (keyof BlockedTime)[] | undefined;
        required?: (keyof BlockedTime)[] | undefined;
    };
    validateFormControlArray: (
        formValues: {
            [x: string]: any[];
        },
        validationConfig: {
            [x: string]: {
                email?: (string | number | symbol)[] | undefined;
                required?: (string | number | symbol)[] | undefined;
            };
        },
    ) => boolean;
    blockedTimeAttributeEditorItems: BlockTimeTimeAttributeEditorItem[];
    blockedTimeAttributeValidationConfig: {
        blockedTimeAttributeEditorItems: {
            email?: (keyof BlockTimeTimeAttributeEditorItem)[] | undefined;
            required?: (keyof BlockTimeTimeAttributeEditorItem)[] | undefined;
        };
    };
    handleBlockedTimeSubmit: ({
        blockedTimeFormValues,
    }: {
        blockedTimeFormValues: BlockedTimeFormData;
    }) => void;
}

const BlockedTimeModalFooter = ({
    handleIsVisibleChange,
    resetBlockedTimeModal,
    hasMixedInstructors,
    isEditForm,
    handleBlockedTimeEdit,
    formValues,
    eventSource,
    validateForm,
    getValidationConfig,
    validateFormControlArray,
    blockedTimeAttributeEditorItems,
    blockedTimeAttributeValidationConfig,
    handleBlockedTimeSubmit,
}: BlockedTimeModalFooterProps) => {
    const isLoading = useSelector(selectIsLoading);
    const handleEdit = (formValues: BlockedTimeFormData) => {
        handleBlockedTimeEdit(formValues, false);
    };

    const handleSubmit = () => {
        const invalid = validateForm(formValues, getValidationConfig());
        const isFormControlArrayInvalid = validateFormControlArray(
            { blockedTimeAttributeEditorItems },
            blockedTimeAttributeValidationConfig,
        );
        if (invalid || isFormControlArrayInvalid) {
            return;
        } else {
            handleBlockedTimeSubmit({
                blockedTimeFormValues: formValues,
            });
        }
    };

    return (
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button
                    variant="link"
                    onClick={() => {
                        resetBlockedTimeModal();
                    }}
                    data-testid={'ReserveTimeCancel'}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
                {!hasMixedInstructors &&
                    (() => {
                        if (!!isEditForm) {
                            return (
                                <EditModalFooter
                                    resetBlockedTimeModal={
                                        resetBlockedTimeModal
                                    }
                                    handleEdit={handleEdit}
                                    formValues={formValues}
                                    eventSource={eventSource}
                                    handleIsVisibleChange={
                                        handleIsVisibleChange
                                    }
                                />
                            );
                        } else {
                            return (
                                <Button
                                    variant="primary"
                                    data-testid={'ReserveTimeSave'}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Saving' : BLOCK_TIME_TEXT}
                                </Button>
                            );
                        }
                    })()}
            </SpaceBetween>
        </Box>
    );
};

export default BlockedTimeModalFooter;

export const collectionPreferencesModalI18nStrings = {
    base: {
        title: 'Preferences',
        confirmLabel: 'Confirm',
        cancelLabel: 'Cancel',
    },
    wrapLinesPreference: {
        label: 'Wrap lines',
        description: 'Wrap text in cells to the next line',
    },
    stripedRowsPreference: {
        label: 'Striped rows',
        description: 'Display alternating row colors',
    },
    contentDensityPreference: {
        label: 'Content density',
        description: 'Choose between comfortable and compact density',
    },
    contentDisplayPreference: {
        title: 'Select visible columns and order',
        description: 'Customize the visibility and order of the columns',
        dragHandleAriaLabel: 'Reorder column',
        dragHandleAriaDescription: 'Press space bar to move the column',
    },
};

import { API_MAIN_PATH } from '../constants/grimsby';
import { HandleRequestData } from '../interfaces/handleRequest';
import { UserListResponseData } from '../../admin/interfaces/userListResponse';
import createQueryString from '../utils/createQueryString';
import handleGrimsbyApiRequest from '../utils/handleGrimsbyApiRequest';
import { SelectedUserResponseData } from '../../admin/interfaces/selectedUserResponse';
import { UserManagementAPIQueryParams } from '../interfaces/queryParams';
import { AdminUpdateUserPayloadData } from '../../admin/interfaces/adminUpdateUserPayload';
import { ResponseData } from '../interfaces/responseData';
import { UserProfileData } from '../interfaces/userProfile';
import { AdminCreateUserResponseData } from '../../admin/interfaces/adminCreateUserResponse';

export const ADMIN_USER_MANAGEMENT_PATH = `${API_MAIN_PATH.USER_MANAGEMENT}/admin`;

/**
 * Method to request a list of grimsby users
 * @param {UserManagementQueryParams} queryParams - An object to be converted into a query string
 * @return: A promise that resolves with an object containing the result or error of the user list request
 */
const adminGetUsers = (
    queryParams?: UserManagementAPIQueryParams.AdminGetUsers,
): Promise<HandleRequestData<UserListResponseData>> => {
    let path = `${ADMIN_USER_MANAGEMENT_PATH}/users${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<any>(path, { method: 'GET' });
};

/**
 * Method to request a grimsby user's profile
 * @param {string} id - A selected user's uuid
 * @return: A promise that resolves with an object containing the result or error of the profile request
 */
const adminGetUserById = (
    id: string,
): Promise<HandleRequestData<SelectedUserResponseData>> => {
    return handleGrimsbyApiRequest<SelectedUserResponseData>(
        `${ADMIN_USER_MANAGEMENT_PATH}/users/${id}`,
        {
            method: 'GET',
        },
    );
};

/**
 * Method to update a grimsby user's profile
 * @param {string} id - A selected user's uuid
 * @param {AdminUpdateUserPayloadData} payload - an object containing the updated properties
 * @return: A promise that resolves with an object containing the result or error of the update request
 */
const adminUpdateUser = (
    id: string,
    payload: AdminUpdateUserPayloadData,
): Promise<HandleRequestData<ResponseData>> => {
    return handleGrimsbyApiRequest<ResponseData>(
        `${ADMIN_USER_MANAGEMENT_PATH}/users/${id}`,
        {
            method: 'PUT',
            body: JSON.stringify(payload),
        },
    );
};

const adminCreateUser = (
    payload: Pick<UserProfileData, 'programs' | 'regions' | 'email'>,
): Promise<HandleRequestData<AdminCreateUserResponseData>> => {
    return handleGrimsbyApiRequest<AdminCreateUserResponseData>(
        `${ADMIN_USER_MANAGEMENT_PATH}/users`,
        {
            method: 'POST',
            body: JSON.stringify(payload),
        },
    );
};

const adminUserManagementApi = {
    adminGetUsers,
    adminGetUserById,
    adminUpdateUser,
    adminCreateUser,
};

export default adminUserManagementApi;

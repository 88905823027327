import React from 'react';

import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    Alert,
} from '@amzn/awsui-components-react-v3';

interface ValidateInstructorBusyModalProps {
    visible: boolean;
    onCancel: any;
    instructorConflict: any;
}

const ValidateInstructorBusyModal = ({
    onCancel,
    instructorConflict,
    visible,
}: ValidateInstructorBusyModalProps) => {
    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`NoShuffleModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="primary"
                            data-testid={`NoShuffleModalConfirm`}
                            onClick={() => {
                                onCancel();
                            }}
                        >
                            Close
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Instructor unavailable`}
        >
            <Alert
                onDismiss={() => {}}
                visible={true}
                dismissAriaLabel="Close alert"
                type="warning"
            >
                {`${instructorConflict?.instructor?.full_name}`} is not
                available during this time. Please select another instructor.
            </Alert>
        </Modal>
    );
};

export default ValidateInstructorBusyModal;

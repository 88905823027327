/**
 * Header App-switcher component
 */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ButtonDropdown } from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';
import { check } from '../../utils/auth';
import { checkFeature } from '../../utils/featureFlag';
import { Roles } from '../../constants/auth';
import { HEADER_NAV_ITEMS, NavType } from '../../constants/navigation';
import { NavItem } from '../../interfaces/navigation';
import { useSelector } from 'react-redux';
import { selectRoles, selectUser } from '../../store/slices/userSlice';
import { selectFeatures } from '../../store/slices/featureSlice';
import { FeatureFlags } from '../../interfaces/featureFlags';

const DEFAULT_NAV_TEXT = 'Instructor management';

const getAppName = (path: string): string => {
    const baseRouteRegexp = new RegExp(/^\/\w+/g);
    const basePathMatches = baseRouteRegexp.exec(path);

    // when the path is the root path `/`
    if (!basePathMatches || !basePathMatches.length) {
        return DEFAULT_NAV_TEXT;
    }

    const navItem = HEADER_NAV_ITEMS.find((item: NavItem) => {
        return item.href === basePathMatches[0];
    });

    return !navItem ? DEFAULT_NAV_TEXT : navItem.text;
};

export const getNavItems = (
    roles: Array<Roles>,
    user: string = '',
    featureFlags?: FeatureFlags,
): Array<ButtonDropdown.Item> =>
    HEADER_NAV_ITEMS.reduce(
        (acc: Array<ButtonDropdown.Item>, item: NavItem) => {
            if (
                item.navType !== NavType.HeaderNav ||
                !item.requiredPermissionAction
            ) {
                return acc;
            }
            if (
                check(roles, item.requiredPermissionAction) &&
                checkFeature(user, item, featureFlags)
            ) {
                acc.push({
                    text: item.text,
                    id: item.href,
                });
            }

            return acc;
        },
        [],
    );
//const roles = getUserRoles();
const AppSwitcher = () => {
    const [navText, setNavText] = useState(DEFAULT_NAV_TEXT);
    const [navItems, setNavItems] = useState<Array<ButtonDropdown.Item>>([]);
    const history = useHistory();
    const user = useSelector(selectUser);
    const featureFlags = useSelector(selectFeatures);
    const roles = useSelector(selectRoles);

    const handleAppSelection = (evt: CustomEvent<ButtonDropdown.ItemClick>) => {
        history.push(`${evt.detail.id}`);
    };

    useEffect(() => {
        if (featureFlags) {
            setNavItems(
                getNavItems(
                    roles,
                    user?.profile?.alias,
                    featureFlags?.features,
                ),
            );
        }
    }, [featureFlags, user, roles]);

    useEffect(() => {
        setNavText(getAppName(history.location.pathname));

        return history.listen((location) => {
            setNavText(getAppName(location.pathname));
        });
    }, [history]);

    return (
        <ButtonDropdown
            data-testid="AppSwitcher_buttonDropdown"
            className="app-switcher"
            items={navItems}
            onItemClick={handleAppSelection}
        >
            {navText}
        </ButtonDropdown>
    );
};

export default AppSwitcher;

export const ADMIN_PATH = {
    INSTRUCTOR_ROLE_TYPES: '/admin/business-data/instructor-role-types',
    INSTRUCTOR_BLOCKEDTIME_TYPES:
        '/admin/business-data-management/instructor-blockedtime-type',
    COST_TYPES: '/admin/business-data/cost-types',
    REVENUE_TYPES: '/admin/business-data/revenue-types',
    BASE: '/admin',
    USER_MANAGEMENT: '/admin/user-management',
    USER_LIST: '/admin/user-management/users',
    USER_CREATE: '/admin/user-management/users/create',
    USER_DETAIL: '/admin/user-management/users/:id',
    USER_EDIT: '/admin/user-management/users/:id/edit',
    BUSINESS_DATA: '/admin/business-data',
    INSTRUCTOR_TYPES: '/admin/business-data/instructor-types',
    INSTRUCTOR_STATUSES: '/admin/business-data/instructor-statuses',
    INSTRUCTOR_COURSE_STATUSES:
        '/admin/business-data/instructor-course-statuses',
    PROGRAM_TYPES: '/admin/business-data/program-types',
    ATP_COMPANIES: '/admin/business-data/atp-companies',
    DELIVERY_COUNTRIES: '/admin/business-data/delivery-countries',
    CERTIFICATIONS: '/admin/business-data/certifications',
    COURSES_LIST: '/admin/business-data/courses',
    COURSES_CREATE: '/admin/business-data/courses/create',
    COURSES_DETAIL: '/admin/business-data/courses/:id',
    COURSE_DETAILS_EDIT: '/admin/business-data/courses/:id/edit',
    COURSE_MILESTONES_EDIT: '/admin/business-data/courses/:id/milestones/edit',
    FREELANCER_STATUSES: '/admin/business-data/freelancer-statuses',
    DOMAIN_SKILLS: '/admin/business-data/domain-skills',
    DELIVERY_LANGUAGES: '/admin/business-data/delivery-languages',
    LOCATIONS: '/admin/business-data/locations',
    ACTIVITY_AUDIENCES: '/admin/business-data/activity-audiences',
    ACTIVITY_STATUSES: '/admin/business-data/activity-statuses',
    ACTIVITY_MODALITIES: '/admin/business-data/activity-modalities',
    ACTIVITY_TYPES: '/admin/business-data/activity-types',
    PARTNER_INITIATIVES: '/admin/business-data/partner-initiative',
    MILESTONE_TYPES: '/admin/business-data/milestone-types',
    COURSE_DOMAINS: '/admin/business-data/course-domains',
    COURSE_CATEGORIES: '/admin/business-data/course-categories',
} as const;

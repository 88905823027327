import {
    ColumnLayout,
    FormField,
    Input,
    Link,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import React from 'react';

const OpportunityCustomerFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
}: any) => {
    return (
        <>
            <ColumnLayout>
                <SpaceBetween direction="vertical" size="m">
                    <h3>Customer details</h3>
                    <FormField
                        errorText={errors?.customer_name}
                        label={
                            <div className="awsui-util-status-inactive">
                                Customer name
                            </div>
                        }
                    >
                        <Input
                            onChange={(e) => {
                                handleFieldEvent({
                                    customer_name: e.detail.value,
                                });
                            }}
                            readOnly
                            value={formValues.customers[0].customer_name}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.customer_name}
                        label={
                            <div className="awsui-util-status-inactive">
                                Number of students
                            </div>
                        }
                    >
                        <Input
                            onChange={(e) => {
                                handleFieldEvent({
                                    number_of_students_committed:
                                        e.detail.value,
                                });
                            }}
                            readOnly
                            value={
                                formValues.customers[0]
                                    .number_of_students_committed
                            }
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.customer_name}
                        label={
                            <div className="awsui-util-status-inactive">
                                TOF status
                            </div>
                        }
                    >
                        <Input
                            onChange={(e) => {
                                handleFieldEvent({
                                    tof_status: e.detail.value,
                                });
                            }}
                            readOnly
                            value={formValues.customers[0].tof_status}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.customer_name}
                        label={
                            <div className="awsui-util-status-inactive">
                                SFDC Opportunity ID
                            </div>
                        }
                    >
                        <Link
                            href={`https://aws-crm.my.salesforce.com/${formValues.customers[0].sfdc_opportunity_id}`}
                        >{`https://aws-crm.my.salesforce.com/${formValues.customers[0].sfdc_opportunity_id}`}</Link>
                    </FormField>
                </SpaceBetween>
            </ColumnLayout>
        </>
    );
};

export default OpportunityCustomerFormSection;

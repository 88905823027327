import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminUpdateDeliveryLanguagePayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { AdminCreateDeliveryLanguagePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/deliveryLanguagePayload';
import { DeliveryLanguagesResponseData } from '../../interfaces/businessDataResponse/deliveryLanguagesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const DELIVERY_LANGUAGES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/delivery-languages`;

/**
 * Method to request the full list of delivery languages
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getDeliveryLanguages = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<DeliveryLanguagesResponseData>> => {
    const path = `${DELIVERY_LANGUAGES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<DeliveryLanguagesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a delivery language.
 * @param {string} updatedDeliveryLanguage - delivery language values that can be updated
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateDeliveryLanguage = (
    updatedDeliveryLanguage: AdminUpdateDeliveryLanguagePayloadData,
): Promise<HandleRequestData<ResponseData>> => {
    return handleGrimsbyApiRequest<ResponseData>(DELIVERY_LANGUAGES_PATH, {
        method: 'POST',
        body: JSON.stringify(updatedDeliveryLanguage),
    });
};

/**
 * Method to add a delivery language.
 * @param {string} deliveryLanguage - the name of the new delivery language type
 * @param {string} isoCode - the iso code of the new delivery language type
 * @param {boolean} active - the status of the new delivery language type
 * @return: A promise that resolves with an object containing the result or error of the request
 *
 */
const addDeliveryLanguage = (
    deliveryLanguage: string,
    isoCode: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateDeliveryLanguagePayloadData = {
        delivery_language: deliveryLanguage,
        iso_code: isoCode,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(DELIVERY_LANGUAGES_PATH, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const deliveryLanguagesApi = {
    getDeliveryLanguages,
    updateDeliveryLanguage,
    addDeliveryLanguage,
};

export default deliveryLanguagesApi;

import React from 'react';

import { GrimsbyNotification } from '../context/grimsbyNotifications';
import { InstructorUpdateError } from '../constants/flashContent';

const handleInstructorDetailNotification = async (
    dispatchPromise: Promise<boolean>,
    addNotification: (notification: GrimsbyNotification) => void,
    sectionLabel: string,
): Promise<boolean> => {
    const isSuccessful = await dispatchPromise;
    addNotification({
        id: `edit-instructor-${sectionLabel}-${Date.now()}`,
        ...(isSuccessful
            ? {
                  type: 'success',
                  content: `You have successfully edited the instructor.`,
              }
            : {
                  type: 'error',
                  content: <InstructorUpdateError />,
              }),
    });

    return isSuccessful;
};

export default handleInstructorDetailNotification;

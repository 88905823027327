import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateRevenueTypePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/revenueTypePayload';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { RevenueTypesResponseData } from '../../interfaces/businessDataResponse/revenueTypesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const REVENUE_TYPES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/revenue-types`;

/**
 * Method to request the full list of revenue types
 * @param {BusinessDataApiQueryParams.GetRevenueTypes} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getRevenueTypes = (
    queryParams?: BusinessDataApiQueryParams.GetRevenueTypes,
): Promise<HandleRequestData<RevenueTypesResponseData>> => {
    let path = `${REVENUE_TYPES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<RevenueTypesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an revenue type.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateRevenueType = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${REVENUE_TYPES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

/**
 * Method to add an revenue type.
 * @param {string} revenueTypeName - the name of the new revenue type
 * @param {boolean} active - the status of the new revenue type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addRevenueType = (
    revenueTypeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateRevenueTypePayloadData = {
        revenue_type: revenueTypeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(`${REVENUE_TYPES_PATH}`, {
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const revenueTypesApi = {
    getRevenueTypes,
    updateRevenueType,
    addRevenueType,
};

export default revenueTypesApi;

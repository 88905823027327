import { useEffect } from 'react';
import userManagementApi from '../../api/userManagementApi';
import { Auth } from '@aws-amplify/auth';

const Onboard = () => {
    const timeout = (ms: number) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const doSyncUser = async () => {
        await userManagementApi.syncNewUser();

        await timeout(2000);

        Auth.signOut();
    };

    useEffect(() => {
        doSyncUser();
    }, [doSyncUser]);

    return (
        <div>
            <h1>Please wait one moment while we sync your user details...</h1>
            <p>
                This process should only happen once. Once finished, you will be
                directed to login again.
            </p>
        </div>
    );
};

export default Onboard;

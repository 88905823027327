import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    Form,
    FormField,
    Textarea,
} from '@amzn/awsui-components-react';

import CancelModal, {
    CancelModalProps,
} from '../../../common/components/CancelModal/CancelModal';

const NotesForm = ({
    testId,
    initialValue,
    setEditing,
    getNotesClickHandler,
}: {
    testId: string;
    initialValue: string | null;
    setEditing: Dispatch<SetStateAction<boolean>>;
    getNotesClickHandler: (notes: string | null) => () => void;
}) => {
    const [notesValue, setNotesValue] = useState(initialValue);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);

    const cancelModalProps: CancelModalProps = {
        cancelModalVisible,
        setCancelModalVisible,
        onCancelConfirm: () => {
            setEditing(false);
        },
        testPrefix: 'EditNotes',
    };

    useEffect(() => {
        setNotesValue(initialValue);
    }, [initialValue, setNotesValue]);

    return (
        <div>
            <Form
                data-testid="NotesForm"
                actions={
                    <div className="awsui-util-pt-xl">
                        <Button
                            variant="link"
                            data-testid={`${testId}NotesCancelBtn`}
                            onClick={() => {
                                setCancelModalVisible(true);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`${testId}NotesSubmitBtn`}
                            onClick={getNotesClickHandler(notesValue)}
                        >
                            Save
                        </Button>
                    </div>
                }
            >
                <FormField>
                    <Textarea
                        placeholder="Enter a note"
                        value={notesValue}
                        autofocus={true}
                        data-testid={`${testId}NotesTextarea`}
                        onChange={(e) => {
                            setNotesValue(e.detail.value);
                        }}
                    />
                </FormField>
            </Form>
            <CancelModal {...cancelModalProps} />
        </div>
    );
};

export default NotesForm;

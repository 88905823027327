import React from 'react';
import { Link as HTTPLink } from '@amzn/awsui-components-react-v3';
import {
    TICKET_TEMPLATE_LINK,
    classroomStatusAlertMap,
} from './ClassroomsHelper';
import { EMPTY_STRING } from '../../../../../common/constants/grimsby';

const renderClassroomStatusMessage = (status: string) => {
    if (!status || !classroomStatusAlertMap[status]) {
        return EMPTY_STRING;
    }

    return (
        <>
            {classroomStatusAlertMap[status].message}
            {classroomStatusAlertMap[status].type === 'error' && (
                <>
                    <span> </span>
                    <HTTPLink
                        external
                        href={TICKET_TEMPLATE_LINK}
                        variant="secondary"
                    >
                        Click here to report the issue.
                    </HTTPLink>
                </>
            )}
        </>
    );
};

export default renderClassroomStatusMessage;

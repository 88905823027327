import React from 'react';

import {
    USER_GUIDE_TEXT,
    USER_GUIDE_URL,
    CHANGE_LOG_TEXT,
    CHANGE_LOG_URL,
    FEEDBACK_TEXT,
    FEEDBACK_URL,
} from '../../constants/grimsby';

const Footer = () => (
    <div
        className="grimsby-footer awsui-util-container-footer"
        data-testid="AppFooter"
    >
        <div className="awsui-row">
            <a
                data-testid="AppFooterFeedback"
                href={FEEDBACK_URL}
                target="_blank"
                className="awsui-util-font-size-2"
                rel="noopener noreferrer"
            >
                {FEEDBACK_TEXT}
            </a>
            <div className="awsui-util-f-r">
                <a
                    data-testid="AppFooterUserGuide"
                    href={USER_GUIDE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {USER_GUIDE_TEXT}
                </a>
                <a
                    data-testid="AppFooterChangeLog"
                    href={CHANGE_LOG_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {CHANGE_LOG_TEXT}
                </a>
            </div>
        </div>
    </div>
);

export default Footer;

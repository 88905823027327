import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    selectFeatures,
    selectIsLoaded as selectFeaturesIsLoaded,
} from '../../../common/store/slices/featureSlice';
import { Redirect, Route, Switch } from 'react-router-dom';
import Can from '../../../common/components/Can';
import { getUserRoles } from '../../../common/utils/auth';
import AlertPage from '../../../common/components/AlertPage';
import BreadcrumbsWrapper from '../../../common/components/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { ChildRouteProps } from '../../../common/interfaces/navigation';
import { checkFeature } from '../../../common/utils/featureFlag';

const ActivityRoutes = ({ routes }: ChildRouteProps) => {
    const featureFlags = useSelector(selectFeatures);
    const featuresLoaded = useSelector(selectFeaturesIsLoaded);
    const aciSupportEnabled = useMemo(() => {
        return checkFeature(
            '',
            { featureName: 'aci_support' },
            featureFlags?.features,
        );
    }, [featuresLoaded, featureFlags]);

    const filteredRoutes = routes.filter((route) => {
        if (!aciSupportEnabled && route.featureName === 'aci_support') {
            return false;
        }

        return true;
    });

    return (
        <Switch>
            {filteredRoutes &&
                filteredRoutes.map((route) => {
                    if (route.redirect && route.redirectTo) {
                        return (
                            <Route exact path={route.href} key={route.id}>
                                <Can
                                    roles={getUserRoles()}
                                    perform={route.requiredPermissionAction}
                                    yes={() => (
                                        <BreadcrumbsWrapper>
                                            <Redirect
                                                to={`${route.redirectTo}`}
                                            />
                                        </BreadcrumbsWrapper>
                                    )}
                                    no={() => <Redirect to="/error" />}
                                />
                            </Route>
                        );
                    }

                    return (
                        <Route exact path={`${route.href}`} key={route.id}>
                            <Can
                                roles={getUserRoles()}
                                perform={route.requiredPermissionAction}
                                yes={() => (
                                    <BreadcrumbsWrapper>
                                        {route.component}
                                    </BreadcrumbsWrapper>
                                )}
                                no={() => <Redirect to="/error" />}
                            />
                        </Route>
                    );
                })}
            <Route>
                <AlertPage />
            </Route>
        </Switch>
    );
};

export default ActivityRoutes;

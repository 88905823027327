import React, { useState } from 'react';

import {
    Alert,
    Modal,
    Button,
    Box,
    SpaceBetween,
    ColumnLayout,
    FormField,
    Select,
} from '@amzn/awsui-components-react-v3';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import './EditBasicInfoSuspensionReasonModal.scss';

interface EditBasicInfoSuspensionReasonModalProps {
    visible: boolean;
    onConfirm: any;
    onCancel: any;
    setSuspensionReason: any;
    suspensionReason: OptionDefinition | null;
}

const CONFIRM_SUSPENSION_REASON_TEXT =
    'Please provide a reason for suspending this instructor to proceed.';

const EditBasicInfoSuspensionReasonModal = ({
    onCancel,
    onConfirm,
    visible,
    suspensionReason,
    setSuspensionReason,
}: EditBasicInfoSuspensionReasonModalProps) => {
    let suspensionReasons = [
        {
            label: 'Onboarding incomplete - No ICA',
            id: 'Onboarding incomplete - No ICA',
        },
        {
            label: 'Onboarding incomplete – failed ICA(s)',
            id: 'Onboarding incomplete – failed ICA(s)',
        },
        {
            label: 'Approved for <2 Y – changed role',
            id: 'Approved for <2 Y – changed role',
        },
        {
            label: 'Approved for <2 Y – compliance fail',
            id: 'Approved for <2 Y – compliance fail',
        },
        {
            label: 'Approved for >2 Y – changed role',
            id: 'Approved for >2 Y – changed role',
        },
        {
            label: 'Approved for >2 Y – compliance fail',
            id: 'Approved for >2 Y – compliance fail',
        },
    ];

    const [submitDisabled, setSubmitDisabled] = useState(true);

    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmSuspensionReasonModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmInstructorSuspensionReasonCancel`}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmInstructorSuspensionReasonConfirm`}
                            disabled={submitDisabled}
                            onClick={() => {
                                onConfirm();
                            }}
                        >
                            Suspend Instructor
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Confirm suspension reason`}
        >
            <Alert
                onDismiss={() => {}}
                visible={true}
                dismissAriaLabel="Close alert"
                type="warning"
                data-testid={`SuspensionReasonModalAlert`}
            >
                Suspending the instructor will not allow them to be assigned to
                future activities. Are you sure you want to continue?
            </Alert>
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <SpaceBetween size="m" direction="vertical">
                        <FormField
                            label="Suspension reason"
                            description={CONFIRM_SUSPENSION_REASON_TEXT}
                            className="suspension-reason-form-field"
                            data-testid={`SuspensionReasonModalFormField`}
                        >
                            <Select
                                placeholder={`Please provide a reason for suspending this instructor`}
                                options={suspensionReasons}
                                selectedOption={suspensionReason}
                                onChange={(e) => {
                                    setSuspensionReason(
                                        e?.detail?.selectedOption,
                                    );
                                    setSubmitDisabled(
                                        !e?.detail?.selectedOption,
                                    );
                                }}
                                loadingText="Loading suspension reasons"
                                errorText="An error occurred while loading suspension reasons"
                                recoveryText="Retry"
                                data-testid={`InstructorSuspensionReason`}
                            />
                        </FormField>
                    </SpaceBetween>
                </div>
            </ColumnLayout>
        </Modal>
    );
};

export default EditBasicInfoSuspensionReasonModal;

import { HandleRequestData } from '../../interfaces/handleRequest';
import { AdminCreateInstructorBlockedTimeTypePayloadData } from '../../interfaces/adminCreateBusinessDataPayload/instructorBlockedTimeTypePayload';
import { AdminUpdateBusinessDataPayloadData } from '../../interfaces/adminUpdateBusinessDataPayload';
import { InstructorBlockedTimeTypesResponseData } from '../../interfaces/businessDataResponse/instructorBlockedTimeTypesResponse';
import { ResponseData } from '../../interfaces/responseData';
import createQueryString from '../../utils/createQueryString';
import handleGrimsbyApiRequest from '../../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../../interfaces/queryParams';
import { API_MAIN_PATH } from '../../constants/grimsby';

export const INSTRUCTOR_BLOCKEDTIME_TYPES_PATH = `${API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT}/instructor-blockedtime-type`;

/**
 * Method to request the full list of instructor blocked time types
 * @param {BusinessDataApiQueryParams.GetInstructorBlockedTimeTypes} queryParams - which type(s) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getInstructorBlockedTimeTypes = (
    queryParams?: BusinessDataApiQueryParams.GetInstructorBlockedTimeTypes,
): Promise<HandleRequestData<InstructorBlockedTimeTypesResponseData>> => {
    let path = `${INSTRUCTOR_BLOCKEDTIME_TYPES_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<InstructorBlockedTimeTypesResponseData>(
        path,
        {
            method: 'GET',
        },
    );
};

/**
 * Method to update an instructor blocked time type.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateInstructorBlockedTimeType = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${INSTRUCTOR_BLOCKEDTIME_TYPES_PATH}`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add an instructor blocked time type.
 * @param {string} instructorBlockedTimeTypeName - the name of the new instructor blocked time type
 * @param {boolean} active - the status of the new instructor blocked time type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addInstructorBlockedTimeType = (
    instructorBlockedTimeTypeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateInstructorBlockedTimeTypePayloadData = {
        instructor_blockedtime_type: instructorBlockedTimeTypeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${INSTRUCTOR_BLOCKEDTIME_TYPES_PATH}`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

const instructorBlockedTimeTypesApi = {
    getInstructorBlockedTimeTypes,
    updateInstructorBlockedTimeType,
    addInstructorBlockedTimeType,
};

export default instructorBlockedTimeTypesApi;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { useGrimsbyNav } from '../../context/grimsbyNavigation';

const Breadcrumbs = () => {
    const { breadcrumbs } = useGrimsbyNav();
    const history = useHistory();

    const onClickHandler = (evt: CustomEvent<BreadcrumbGroup.ClickDetail>) => {
        evt.preventDefault();
        history.push(evt.detail.href);
    };
    return (
        <div className="breadcrumbs">
            <BreadcrumbGroup
                data-testid="GrimsbyBreadcrumbGroup"
                items={breadcrumbs}
                onClick={onClickHandler}
                label="Breadcrumb"
            />
        </div>
    );
};

export default Breadcrumbs;

export enum ProgramFormFieldText {
    Placeholder = 'Select programs',
    PlaceholderSingular = 'Select program',
    Loading = 'Loading programs',
    Recovery = 'Retry',
    Empty = 'No programs found',
    Error = 'An error occurred while loading programs',
}

export enum DomainFormFieldText {
    Placeholder = 'Select domain',
    PlaceholderSingular = 'Select domain',
    Loading = 'Loading domains',
    Recovery = 'Retry',
    Empty = 'No domains found',
    Error = 'An error occurred while loading domains',
}

export enum CategoryFormFieldText {
    Placeholder = 'Select category',
    PlaceholderSingular = 'Select category',
    Loading = 'Loading categories',
    Recovery = 'Retry',
    Empty = 'No categories found',
    Error = 'An error occurred while loading categories',
}

export enum CertificationFormFieldText {
    Placeholder = 'Select certifications',
    PlaceholderSingular = 'Select certification',
    Loading = 'Loading certifications',
    Recovery = 'Retry',
    Empty = 'No certifications found',
    Error = 'An error occurred while loading certifications',
}

export enum MilestoneTypeFormFieldText {
    Placeholder = 'Select Milestones',
    PlaceholderSingular = 'Select Milestone',
    Loading = 'Loading milestones',
    Recovery = 'Retry',
    Empty = 'No milestones found',
    Error = 'An error occurred while loading milestones',
}

export enum FormSectionMode {
    Create = 'Create',
    Edit = 'Edit',
    InlineEdit = 'InlineEdit',
}

export enum LocationFormFieldText {
    Placeholder = 'Select a city name',
    FilteringPlaceholder = 'Enter a city name',
    Recovery = 'Retry',
    Loading = 'Loading cities',
    Empty = 'No matching city or cities found',
    Error = 'An error occurred while loading cities',
}

export enum SkillLevelFieldText {
    Placeholder = 'Select skill level',
}

export enum CourseContentFieldText {
    Placeholder = 'Select course content',
}

export enum CourseLocalization {
    Placeholder = 'Select course localization',
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Form,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import {
    updateCourse,
    selectIsLoading,
} from '../../../store/slices/selectedCourseSlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { CourseItemData } from '../../../../common/interfaces/businessDataItem/courseItem';
import { FormSectionProps } from '../../../../common/interfaces/formSectionProps';
import { AdminCoursesFormSection } from '../FormSections/AdminCoursesFormSection';
import { FormSectionMode } from '../../../../common/constants/forms';
import { CourseFormData } from '../../../interfaces/courseForm';
import handleEditCourseNotification from '../../../../common/utils/handleEditCourseNotification';
import { AdminCoursesFormMilestonesSection } from '../FormSections/AdminCoursesFormMilestonesSection';
import { courseFormValidationConfig } from '../Common';

const AdminCoursesEditForm = ({ course }: { course: CourseItemData }) => {
    const isLoading = useSelector(selectIsLoading);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    const { isInvalid, errors, validateForm } =
        useFormValidation<CourseItemData>();
    const [formValues, setFormValues] = useState<CourseItemData>(course);

    const handleFieldEvent = (changes: Partial<CourseItemData>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    };

    const editCourseProps: Omit<
        FormSectionProps<CourseFormData>,
        'validateAndHandleFieldEvent'
    > & {
        name: string;
    } = {
        formValues,
        errors,
        handleFieldEvent,
        name: 'Edit Course',
        mode: FormSectionMode.Edit,
    };

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, courseFormValidationConfig);
        }
    }, [isInvalid, formValues, validateForm]);

    return (
        <section data-testid="AdminCoursesEditForm">
            <Form
                header={<Header variant="h1">Edit: {course.course}</Header>}
                actions={
                    <SpaceBetween size="m" direction="horizontal">
                        <Button
                            variant="link"
                            className="admin-user-cancel"
                            data-testid="AdminCoursesCreateFormCancel"
                            onClick={() => {
                                history.push({
                                    pathname: `/admin/business-data/courses/${course.pk}`,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            className="admin-course-save"
                            data-testid="AdminCoursesEditFormAdd"
                            loading={isLoading}
                            onClick={async () => {
                                const invalid = validateForm(
                                    formValues,
                                    courseFormValidationConfig,
                                );

                                if (!invalid) {
                                    const dispatchPromise = dispatch<any>(
                                        updateCourse(formValues),
                                    );

                                    const isSuccessful =
                                        await handleEditCourseNotification(
                                            dispatchPromise,
                                            addNotification,
                                        );

                                    if (isSuccessful) {
                                        history.push({
                                            pathname: `/admin/business-data/courses/${course.pk}`,
                                        });
                                    }
                                }
                            }}
                        >
                            {`${isLoading ? 'Updating' : 'Update'} course`}
                        </Button>
                    </SpaceBetween>
                }
            >
                <SpaceBetween size="m" direction="vertical">
                    <AdminCoursesFormSection {...editCourseProps} />
                    <AdminCoursesFormMilestonesSection {...editCourseProps} />
                </SpaceBetween>
            </Form>
        </section>
    );
};

export default AdminCoursesEditForm;

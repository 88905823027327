import { useState } from 'react';

import {
    Alert,
    Modal,
    Button,
    Box,
    SpaceBetween,
    ColumnLayout,
    FormField,
    Select,
    RadioGroup,
} from '@amzn/awsui-components-react-v3';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import './EditBaseDetailsCancellationModal.scss';

export interface EditBaseDetailsCancellationModalProps {
    visible: boolean;
    onConfirm: any;
    onCancel: any;
    setCancellationReason: any;
    cancellationReason: OptionDefinition | null;
    dontRemoveData: boolean;
    setDontRemoveData: any;
    showRemoveDataCheckbox: boolean;
    cancelFeeType: any;
    setCancelFeeType: any;
}

const CONFIRM_CANCELLATION_REASON_TEXT =
    'Please provide a reason for cancelling this activity to proceed.';

const EditBaseDetailsCancellationModal = ({
    onCancel,
    onConfirm,
    visible,
    cancellationReason,
    setCancellationReason,
    setDontRemoveData,
    showRemoveDataCheckbox,
    cancelFeeType,
    setCancelFeeType,
}: EditBaseDetailsCancellationModalProps) => {
    let cancellationReasons = [
        {
            label: 'Customer requested - course change',
            value: 'Customer requested - course change',
        },
        {
            label: 'Data entry error',
            value: 'Data entry error',
        },
        {
            label: 'Customer requested - insufficient funds',
            value: 'Customer requested - insufficient funds',
        },
        {
            label: 'Low registration - triggered by AWS',
            value: 'Low registration - triggered by AWS',
        },
        {
            label: 'Customer requested - low registration',
            value: 'Customer requested - low registration',
        },
        {
            label: 'Customer requested - migrated to digital',
            value: 'Customer requested - migrated to digital',
        },
        {
            label: 'Never contracted',
            value: 'Never contracted',
        },
        {
            label: 'No instructors available',
            value: 'No instructors available',
        },
        {
            label: 'Customer requested - plans to reschedule in the future',
            value: 'Customer requested - plans to reschedule in the future',
        },
        {
            label: 'Customer requested - AWS satisfaction issues',
            value: 'Customer requested - AWS satisfaction issues',
        },
        {
            label: 'System issue',
            value: 'System issue',
        },
        {
            label: 'Undelivered service fee',
            value: 'Undelivered service fee',
        },
        {
            label: 'Customer requested - no longer interested',
            value: 'Customer requested - no longer interested',
        },
        {
            label: 'Venue/facility issue',
            value: 'Venue/facility issue',
        },
        {
            label: 'Low attendance - triggered by AWS',
            value: 'Low attendance - triggered by AWS',
        },
        {
            label: 'Course change - triggered by AWS',
            value: 'Course change - triggered by AWS',
        },
    ];

    // sorting alphabetically
    cancellationReasons.sort((a, b) => (a.label < b.label ? -1 : 1));

    const [submitDisabled, setSubmitDisabled] = useState(true);

    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmCancellationModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmActivityCancellationCancel`}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmActivityCancellationConfirm`}
                            disabled={submitDisabled}
                            onClick={() => {
                                onConfirm();
                            }}
                        >
                            Cancel activity
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Confirm cancellation activity`}
        >
            <Alert
                onDismiss={() => {}}
                visible={true}
                dismissAriaLabel="Close alert"
                type="warning"
                data-testid={`CancellationModalAlert`}
            >
                Cancelling this activity will release the assigned instructors
                and the SFDC product status will change to Cancelled. If there
                is a course in Kiku linked to this activity, the Kiku course
                will be Cancelled and the associated learners will receive
                notifications regarding the cancellation. THIS CANNOT BE UNDONE.
                Are you sure you want to continue?
            </Alert>
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <SpaceBetween size="m" direction="vertical">
                        <FormField
                            label="Cancellation reason"
                            description={CONFIRM_CANCELLATION_REASON_TEXT}
                            className="cancellation-reason-form-field"
                            data-testid={`CancellationModalFormField`}
                        >
                            <Select
                                placeholder={`Please provide a reason for cancelling this activity`}
                                options={cancellationReasons}
                                selectedOption={cancellationReason}
                                onChange={(e) => {
                                    setCancellationReason(
                                        e?.detail?.selectedOption,
                                    );
                                    setSubmitDisabled(
                                        !e?.detail?.selectedOption,
                                    );
                                }}
                                loadingText="Loading cancellation reasons"
                                errorText="An error occurred while loading cancellationreasons"
                                data-testid={`ActivityCancellationReason`}
                            />
                        </FormField>
                        {showRemoveDataCheckbox && (
                            <FormField>
                                {
                                    <>
                                        <RadioGroup
                                            onChange={(e) => {
                                                setCancelFeeType(
                                                    e.detail.value,
                                                );
                                                setDontRemoveData(
                                                    !!e.detail.value,
                                                );
                                            }}
                                            value={cancelFeeType}
                                            data-testid={`CancellationModalRemoToggle`}
                                            items={[
                                                {
                                                    value: 'late_reschedule_fee',
                                                    label: 'Late Reschedule: < 14 days from date of delivery. Retain customer revenue and invoice data',
                                                },
                                                {
                                                    value: 'late_cancellation_fee',
                                                    label: 'Late Cancellation: < 14 days from date of delivery. Retain customer revenue and invoice data',
                                                },
                                            ]}
                                        />
                                        <p>
                                            AWS Training Policies state that
                                            Customers are to be charged for
                                            cancellations or reschedules
                                            requested less than 14 calendar days
                                            from the start date of the class
                                            unless approved by exception
                                        </p>
                                    </>
                                }
                            </FormField>
                        )}
                    </SpaceBetween>
                </div>
            </ColumnLayout>
        </Modal>
    );
};

export default EditBaseDetailsCancellationModal;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Alert, Spinner } from '@amzn/awsui-components-react';

import {
    getSelectedInstructor,
    selectError as selectInstructorError,
    selectIsLoading as selectInstructorIsLoading,
    selectSelectedInstructor,
} from '../../../store/slices/selectedInstructorSlice';
import { InstructorProfileData } from '../../../interfaces/instructorProfile';
import { AJAX_CALL_ERROR } from '../../../../common/constants/grimsby';
import EditInstructorDetailsForm from './EditInstructorDetails.form';

const EditInstructorDetails = () => {
    const match = useRouteMatch<{ id: string }>();
    const error: any = useSelector(selectInstructorError);
    const isLoading: boolean = useSelector(selectInstructorIsLoading);
    const instructor: InstructorProfileData | undefined = useSelector(
        selectSelectedInstructor,
    )?.instructor;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSelectedInstructor(match.params.id));
    }, [dispatch, match.params.id]);

    if (error) {
        return (
            <Alert
                data-testid="EditInstructorDetailsError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (!isLoading && instructor) {
        return <EditInstructorDetailsForm initialFormState={instructor} />;
    } else {
        return <Spinner data-testid="InstructorEditBasicInfoSpinner" />;
    }
};

export default EditInstructorDetails;

import { GrimsbyNotification } from '../context/grimsbyNotifications';

const handleActivityListNotification = async (
    dispatchPromise: Promise<boolean>,
    addNotification: (notification: GrimsbyNotification) => void,
): Promise<boolean> => {
    const isSuccessful = await dispatchPromise;

    if (!isSuccessful) {
        addNotification({
            id: `update-user-preferences-of-activity-list-${Date.now()}`,
            type: 'error',
            content:
                'An error occurred while updating the activities table preferences. Please retry after a while.',
        });
    }

    return isSuccessful;
};

export default handleActivityListNotification;

import { DateRangePickerProps } from '@amzn/awsui-components-react-v3';
import {
    differenceInDays,
    getDaysFromRelativeRange,
} from '../../common/utils/date-range-picker';
import dayjs from 'dayjs';

export const getValidRangeFunctionWithMaxDays = ({
    maxAllowedDays,
}: {
    maxAllowedDays: number;
}) => {
    return (
        range?: DateRangePickerProps.Value | null,
    ):
        | DateRangePickerProps.ValidRangeResult
        | DateRangePickerProps.InvalidRangeResult
        | null => {
        if (!range) {
            return {
                valid: false,
                errorMessage: 'The selected date range is incomplete.',
            };
        }
        if (range.type === 'absolute') {
            const startDate = dayjs(range.startDate);
            const endDate = dayjs(range.endDate);

            if (!startDate.isValid() || !endDate.isValid()) {
                return {
                    valid: false,
                    errorMessage:
                        'The selected date range is incomplete. Select a start and end date for the date range.',
                };
            }

            if (startDate.isAfter(endDate)) {
                return {
                    valid: false,
                    errorMessage:
                        'The selected date range is invalid. The start date must be before the end date.',
                };
            }

            const daysDiff = differenceInDays({
                start: range.startDate,
                end: range.endDate,
            });

            return (
                handleRangeTooSmall(daysDiff) ||
                handleMaxAllowedDaysExceeded(daysDiff, maxAllowedDays)
            );
        } else if (range.type === 'relative') {
            if (isNaN(range.amount)) {
                return {
                    valid: false,
                    errorMessage:
                        'The selected date range is incomplete. Specify a duration for the date range.',
                };
            }

            const relativeRangeInDays = getDaysFromRelativeRange(range);
            const absoluteRelativeRangeInDays = Math.abs(relativeRangeInDays);

            return (
                handleRangeTooSmall(absoluteRelativeRangeInDays) ||
                handleMaxAllowedDaysExceeded(
                    absoluteRelativeRangeInDays,
                    maxAllowedDays,
                )
            );
        }
    };
};

function handleRangeTooSmall(
    daysInRange: number,
): DateRangePickerProps.InvalidRangeResult {
    if (daysInRange <= 1) {
        return {
            valid: false,
            errorMessage:
                'The selected date range is too small. Select a range larger than one day.',
        };
    }
    return null;
}

function handleMaxAllowedDaysExceeded(
    daysInRange: number,
    maxAllowedDays: number,
):
    | DateRangePickerProps.ValidRangeResult
    | DateRangePickerProps.InvalidRangeResult {
    if (daysInRange >= maxAllowedDays) {
        const maxMonths = Math.round(maxAllowedDays / 30);
        return {
            valid: false,
            errorMessage: `The selected date range is too large. Select a range up to ${maxMonths} months.`,
        };
    }
    return { valid: true };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HandleRequestData } from '../../interfaces/handleRequest';
import adminUserManagementApi from '../../api/adminUserManagementApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { GenericStoreState } from '../../interfaces/genericStoreState';
import { UserListResponseData } from '../../../admin/interfaces/userListResponse';
import { UserProfileData } from '../../interfaces/userProfile';
import { GRIMSBY_PAGE_COUNT, PermissionGroups } from '../../constants/grimsby';

export interface UserListState extends GenericStoreState {
    userList: UserProfileData[];
    from: number;
    pagesCount: number;
    currentPageIndex: number;
    totalUsersCount: number;
    size: number;
    active: boolean | null;
    searchText: string | null;
    isLoaded: boolean;
    viasRoles: Array<PermissionGroups> | null;
}

/**
 * userListSlice manages all app user list state, and contains user list actions as well as user list state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const userListSlice = createSlice({
    name: 'userList',
    initialState: {
        userList: [],
        error: null,
        isLoading: false,
        from: 0,
        pagesCount: 0,
        currentPageIndex: 1,
        totalUsersCount: 0,
        size: GRIMSBY_PAGE_COUNT,
        active: null,
        searchText: null,
        isLoaded: false,
        viasRoles: null,
    } as UserListState,
    reducers: {
        setUserList: (state, action: PayloadAction<UserProfileData[]>) => {
            state.userList = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setActive: (state, action: PayloadAction<boolean>) => {
            state.active = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setTotalUsersCount: (state, action: PayloadAction<number>) => {
            state.totalUsersCount = action.payload;
        },
        resetUserListSlice: (state) => {
            state.from = 0;
            state.searchText = null;
            state.totalUsersCount = 0;
            state.error = null;
            state.userList = [];
            state.isLoaded = false;
            state.currentPageIndex = 1;
            state.pagesCount = 0;
            state.active = null;
            state.viasRoles = null;
        },
        setViasRoles: (
            state,
            action: PayloadAction<Array<PermissionGroups>>,
        ) => {
            state.viasRoles = action.payload;
        },
    },
});

export const {
    setUserList,
    setError,
    setIsLoading,
    setFrom,
    setActive,
    setSearchText,
    setIsLoaded,
    setPagesCount,
    setCurrentPageIndex,
    setTotalUsersCount,
    resetUserListSlice,
    setViasRoles,
} = userListSlice.actions;

/**
 * getUserList is an async action used to fetch user list data.
 * There is no explicit inclusion of redux-thunk logic, as the redux toolkit takes care of this for us.
 */
export const getUserList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));

        const {
            from,
            size,
            active,
            searchText: search_text,
            viasRoles: vias_roles,
        } = state.userList;
        try {
            const {
                result: { users = [], total_users = 0 },
            }: HandleRequestData<UserListResponseData> =
                await adminUserManagementApi.adminGetUsers({
                    from,
                    size,
                    active,
                    search_text,
                    vias_roles,
                });
            dispatch(setUserList(users));
            dispatch(setTotalUsersCount(total_users));
            dispatch(
                setPagesCount(Math.ceil(total_users / GRIMSBY_PAGE_COUNT)),
            );
        } catch (error: any) {
            dispatch(setError(error.toString()));
        } finally {
            dispatch(setIsLoading(false));

            if (!state.userList.isLoaded) {
                dispatch(setIsLoaded(true));
            }
        }
    };
};

export const selectUserList = (state: GlobalState) => state.userList?.userList;
export const selectError = (state: GlobalState) => state.userList?.error;
export const selectIsLoading = (state: GlobalState) =>
    state.userList?.isLoading;
export const selectIsLoaded = (state: GlobalState) => state.userList?.isLoaded;
export const selectPagesCount = (state: GlobalState) =>
    state.userList?.pagesCount;
export const selectCurrentPageIndex = (state: GlobalState) =>
    state.userList?.currentPageIndex;
export const selectTotalUsersCount = (state: GlobalState) =>
    state.userList?.totalUsersCount;
export const selectSearchText = (state: GlobalState) =>
    state.userList?.searchText;

export default userListSlice.reducer;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getDeliveryLanguagesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllDeliveryLanguages,
    selectError,
    updateDeliveryLanguage,
    selectSelectedDeliveryLanguage,
    setSelectedDeliveryLanguage,
    addDeliveryLanguage,
    selectCount,
    resetDeliveryLanguagesSlice,
} from '../../../common/store/slices/deliveryLanguagesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { DeliveryLanguageItemData } from '../../../common/interfaces/businessDataItem/deliveryLanguageItem';
import { formatString } from '../../../common/utils/formatString';

const DELIVERY_LANGUAGES_TABLE_TITLE = 'Delivery languages';
const DELIVERY_LANGUAGES_DISPLAY_SINGULAR = 'Delivery language';
export const DELIVERY_LANGUAGES_NAME_KEY = 'delivery_language';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'iso_code',
        header: 'ISO code',
        cell: (deliveryLanguage: DeliveryLanguageItemData) =>
            formatString(deliveryLanguage.iso_code),
    },
    {
        id: 'active',
        header: 'Status',
        cell: (deliveryLanguage: DeliveryLanguageItemData) =>
            formatStatus(deliveryLanguage.active.toString()),
    },
];

/**
 * Editable form fields for DeliveryLanguage
 * The keys must map to DeliveryLanguageItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<DeliveryLanguageItemData> =
    {
        // the keys must match DeliveryLanguageData properties
        delivery_language: {
            type: AdminBusinessDataFormInputType.Text,
            label: `${DELIVERY_LANGUAGES_DISPLAY_SINGULAR} name`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (
                val: string,
            ): Partial<DeliveryLanguageItemData> => {
                return { delivery_language: val };
            },
        },
        iso_code: {
            type: AdminBusinessDataFormInputType.Text,
            label: `ISO code`,
            required: true,
            defaultValue: '',
            disabled: false,
            formDataTransform: (
                val: string,
            ): Partial<DeliveryLanguageItemData> => {
                return { iso_code: val };
            },
        },
        active: {
            type: AdminBusinessDataFormInputType.StatusRadio,
            label: 'Status',
            required: true,
            defaultValue: true,
            disabled: false,
            formDataTransform: (
                val: 'true' | 'false',
            ): Partial<DeliveryLanguageItemData> => {
                return { active: parseBoolean(val) };
            },
        },
    };

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<DeliveryLanguageItemData> =
    {
        ...createFormSchema,
        delivery_language: {
            ...createFormSchema.delivery_language,
            disabled: true,
        },
    };

const AdminDeliveryLanguages = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useDeliveryLanguages(): AdminBusinessDataSelectors<DeliveryLanguageItemData> {
        const dispatch = useDispatch();

        const items: DeliveryLanguageItemData[] = useSelector(
            selectAllDeliveryLanguages,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: DeliveryLanguageItemData | null = useSelector(
            selectSelectedDeliveryLanguage,
        );
        useEffect(() => {
            dispatch(getDeliveryLanguagesList());
            dispatch(setSelectedDeliveryLanguage(null));

            return () => {
                dispatch(resetDeliveryLanguagesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (
        deliveryLanguage: DeliveryLanguageItemData,
    ) => {
        if (deliveryLanguage) {
            dispatch(
                setSelectedDeliveryLanguage(deliveryLanguage.delivery_language),
            );
            await dispatch(getDeliveryLanguagesList());
        }
    };

    const handleItemUpdate = async (data: DeliveryLanguageItemData) => {
        dispatch(setSelectedDeliveryLanguage(null));
        const dispatchPromise = dispatch<any>(updateDeliveryLanguage(data));
        await handleBusinessDataNotification({
            businessDataType: DELIVERY_LANGUAGES_NAME_KEY,
            businessDataDisplayNameLower:
                DELIVERY_LANGUAGES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.delivery_language,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        delivery_language,
        active,
        iso_code,
    }: DeliveryLanguageItemData) => {
        const dispatchPromise = dispatch<any>(
            addDeliveryLanguage(active, delivery_language, iso_code),
        );
        await handleBusinessDataNotification({
            businessDataType: DELIVERY_LANGUAGES_NAME_KEY,
            businessDataDisplayNameLower:
                DELIVERY_LANGUAGES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: delivery_language,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementDeliveryLanguages">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={DELIVERY_LANGUAGES_DISPLAY_SINGULAR}
                itemNameKey={DELIVERY_LANGUAGES_NAME_KEY}
                title={DELIVERY_LANGUAGES_TABLE_TITLE}
                useItemList={useDeliveryLanguages}
            />
        </div>
    );
};

export default AdminDeliveryLanguages;

import { Icon } from '@amzn/awsui-components-react';
import { formatStatusHelper } from '../../../common/utils/formatHelper';
export const formatStatus = (businessDataStatus: string): JSX.Element => {
    return formatStatusHelper([
        {
            status: 'true',
            statusClass: 'awsui-util-status-positive',
            iconClass: 'status-positive' as Icon.Name,
            label: 'Active',
        },
        {
            status: 'false',
            statusClass: 'awsui-util-status-inactive',
            iconClass: 'status-stopped' as Icon.Name,
            label: 'Inactive',
        },
    ])(businessDataStatus);
};
